import {
    GET_ALLOCATION_SUGGESTIONS,
    GET_ALLOCATION_SUGGESTIONS_SUCCESS,
    GET_ALLOCATION_SUGGESTIONS_FAIL
} from '../../actions/clients'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface ClientsModel extends InitialState {
    allocationSuggestions: []
}
const initialState: ClientsModel = {
    isLoading: null,
    allocationSuggestions: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_ALLOCATION_SUGGESTIONS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_ALLOCATION_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                allocationSuggestions: Object.values(action.response.data),
                isLoading: false,
                pagination: {
                    pages: action.response.pages,
                    page: action.response.page,
                    total: action.response.total,
                    limit: action.response.limit,
                }
            }
        case GET_ALLOCATION_SUGGESTIONS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
