import React, { memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import ControlLabel from '../../text/MetaLabel'
import styled from 'styled-components'
import { borderColor } from '../../../../utils/variables'
import { WrappedFieldProps } from 'redux-form'

const Textarea = styled.textarea<{customHeight?: number}>`
    font-size: 16px;
    line-height: 27px;
    border: 1px solid ${borderColor};
    border-radius: 3px;
    resize: vertical;
    transition: height 200ms ease;
    padding: 5px 11px;
    width: 100%;
    ${props => `
        height: ${props.customHeight ? props.customHeight : 40}px;
        ${props.customHeight ? '' : '&:focus {height: 200px;}'}
    `}
`

interface TextareaFormGroupModel extends WrappedFieldProps {
    removeClassFormGroup?: boolean
    shouldMatch?: string
    className?: string
    label?: string
}
const TextareaFormGroup = memo(({
    removeClassFormGroup,
    className,
    input,
    label,
    meta: {
        touched,
        error,
        warning,
    },
    ...props
}: TextareaFormGroupModel) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={touched && (error || warning) ? 'error' : null}
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        <Textarea
            {...input}
            {...props}
            className="form-control"
        />
    </FormGroup>
)

export const TextareaGroup = styled(TextareaFormGroup)`
  width: 100%;
`
