import React, { PureComponent } from 'react'
import { BtnLink } from './Links'

interface ActionButtonModel<T> {
    hiddenData: any
    label?: string
    icon?: string
    modifiers?: T
    loadingText?: string
    allowReClick?: boolean
    action: () => Promise<T>
}
export default class ActionButton<T> extends PureComponent<ActionButtonModel<T>> {
    state = {
        loading: false,
        actionCompleted: false,
    }

    onClick = () => {
        const {
            loading,
            actionCompleted,
        } = this.state

        if(loading || actionCompleted && !this.props.allowReClick) return

        this.setState({ loading: true })
        this.props.action()
            .then(() => {
                this.setState({
                    loading: false,
                    actionCompleted: true,
                })
            }, () => {
                this.setState({
                    loading: false,
                    actionCompleted: false,
                })
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    actionCompleted: false,
                })
            })
    }

    render() {
        const {
            hiddenData,
            label,
            icon,
            modifiers,
            loadingText,
        } = this.props

        const {
            loading,
            actionCompleted,
        } = this.state

        return (
            <BtnLink
                modifiers={modifiers}
                onClick={this.onClick}
            >
                {icon && <i className={icon} />}
&nbsp;
                {loading ?
                    <span>
                        {loadingText} 
                        {' '}
                        <i className="fa fa-spin fa-spinner" />
                    </span> :
                    (actionCompleted && hiddenData) || label
                }
            </BtnLink>
        )
    }
}
