import React, { memo } from 'react'
import { Card } from '../../../blocks/Card'
import styled from 'styled-components'
import { dangerColor, successColor } from '../../../../utils/variables'
import { Capitalize } from '../../../elements/text/Capitalize'
import { QuotesSetupFields } from './QuotesSetupFields'
import { Flex } from '../../../elements/Flex'
import { Quote } from '../../../../reducers/types/entities/Quote'
import FormattedDate from '../../../elements/FormattedDate'
import moment from 'moment'
import { AmountCell, Cell, Column, Header, Rows, Table } from '../../../elements/table'
import { Auth } from '../../../../reducers/auth'

interface MiddleDotPropsModel {
   status: string
}
const MiddleDot = styled.span<MiddleDotPropsModel>`
  font-size: 32px;
  color: ${props => (props.status as string) === 'rejected' ? dangerColor : successColor};
  margin-right: 5px;
`

interface QuotesOverviewPropsModel {
    createQuoteFeePermission: string
    disableQuoteAmount: boolean
    adjustAmount: boolean
    isServiceFee: boolean
    quotes: Quote[]
    wantedGeoCode: Auth['wantedGeoCode']
    handleMarketplaceToggle: () => void,
    showForMarketplace: boolean
}

const QuoteRow = memo((props: {rowData?: Quote}) => {
    const quote = props.rowData
    const lastItem = quote.items && quote.items[quote.items.length - 1]

    const updatedAt = (lastItem && moment(lastItem.updatedAt).isAfter(quote.updatedAt))
        ? lastItem.updatedAt
        : quote.updatedAt
    return <tr key={`quote_${quote.id}`}>
        <Cell>
            <a href={'/partners/' + quote.partner.id} target="_blank" rel="noreferrer">{quote.partner.companyName}</a>
        </Cell>
        <Cell>
            {quote.partner.subscriptionType ? quote.partner.subscriptionType : ''}
        </Cell>
        {quote.publishedAt
            ? <Cell>
                <FormattedDate date={quote.publishedAt} format="datetime" />
                {' '}
&nbsp;
                {updatedAt !== quote.publishedAt &&
                <b>
                    (
                    <FormattedDate date={updatedAt} format="datetime" />
                    )
                </b>}
            </Cell>
            : <Cell>Not published</Cell>
        }
        <Cell>
            <Flex>
                {quote.status === 'new' || <MiddleDot status={quote.status}>•</MiddleDot>}
                <Capitalize>{quote.status}</Capitalize>
            </Flex>
        </Cell>
        <AmountCell amount={quote.totalAmountExclVat} />
    </tr>})

export const QuotesOverview = memo(({
    createQuoteFeePermission,
    disableQuoteAmount,
    adjustAmount,
    isServiceFee,
    quotes,
    wantedGeoCode,
    handleMarketplaceToggle,
    showForMarketplace
}: QuotesOverviewPropsModel) =>
    <div>
        <Card.Content>
            <QuotesSetupFields
                createQuoteFeePermission={createQuoteFeePermission}
                disableQuoteAmount={disableQuoteAmount}
                disableAmount={!adjustAmount}
                isServiceFee={isServiceFee}
                wantedGeoCode={wantedGeoCode}
                handleMarketplaceToggle={handleMarketplaceToggle}
                showForMarketplace={showForMarketplace}
            />
        </Card.Content>
        <Table>
            <Header>
                <Column>Partner name</Column>
                <Column>Partner Subscription</Column>
                <Column>Published (Updated)</Column>
                <Column>Status</Column>
                <Column className="text-right">Price</Column>
            </Header>
            <Rows
                items={quotes}
                noDataText="No quotes for this lead."
            >
                <QuoteRow/>
            </Rows>
        </Table>
    </div>
)
