import React, { PureComponent } from 'react'
import Cell from './Cell'

interface EditCellModel {
    value
    onBlur
    rowId?: number
}

export default class EditCell extends PureComponent<EditCellModel>{
    state = {
        editing: false,
        loader: false,
    }
    tdDivHeight = 0
    textInput

    onFocus = () => {
        this.setState({ editing: true }, () => {
            this.textInput.focus()
        })
    }

    onBlur = e => {
        if (this.props.value !== e.target.value) {
            this.setState({ loader: true })
            this.props.onBlur(e.target.value, this.props.rowId)
                .then(res => {
                    this.setState({ loader: false })
                })
                .catch(err => {
                    this.setState({ loader: false })
                })
        }
        this.setState({ editing: false })
    }

    render() {
        const { value } = this.props
        return <Cell style={{ width: '300px' }} className={this.state.loader ? 'text-center' : ''}>
            {this.state.loader
                ? <i className="fa fa-spin fa-spinner"/>
                : this.state.editing
                    ? <textarea
                        style={{ width: '100%', height: this.tdDivHeight + 20 + 'px' }}
                        ref={input => {
                            this.textInput = input
                        }}
                        defaultValue={value}
                        onBlur={this.onBlur}
                    />
                    : <div onClick={this.onFocus}
                        ref={div => {
                            this.tdDivHeight = div ? div.clientHeight : 0
                        }}
                    >
                        {value ? value : 'Create note'}
                    </div>
            }
        </Cell>
    }
}
