import * as leads from '../actions/leads'
import * as quotes from '../actions/quotes'
import * as partners from '../actions/partners'
import * as subscriptions from '../actions/subscriptions'
import * as businessUnit from '../actions/businessUnit'
import {
    INVOICES_REFUND_REQUEST_ACTION_SUCCESS,
    INVOICES_REFUND_REQUEST_ACTION_FAIL,
    INVOICES_REFUND_REQUEST_ACTION,
} from '../actions/invoices'
import {
    UN_DISABLE_MODAL,
    DISABLE_MODAL,
    HIDE_MODAL,
    SHOW_MODAL,
} from '../actions/modal'
import {
    CREATE_LEAD_ACTION_SUCCESS,
    CREATE_LEAD_ACTION_FAIL,
    CREATE_LEAD_ACTION,
} from '../actions/actions'
import { InitialAction } from './types/InitialAction'
import { CREATE_PARTNER_FEE } from '../actions/partners'
import { CREATE_PARTNER_FEE_SUCCESS } from '../actions/partners'
import { CREATE_PARTNER_FEE_FAIL } from '../actions/partners'
import { DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES } from '../state/allocationsQuotas/actions'
import { CREATE_PAUSE_PARTNER_SUBSCRIPTIONS } from '../state/pauseSubscriptions/actions'
import { DELETE_PARTNER_USER } from '../state/partnerUsers/actions'

interface ModalActionModel extends InitialAction {
    modalProps: any
}

export default function reducer(
    state = {},
    action: ModalActionModel
) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...action.modalProps,
            }

        case DISABLE_MODAL:
        case DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES[0]:
        case businessUnit.CREATE_PARTNER_BUSINESS_UNITS:
        case businessUnit.UPDATE_PARTNER_BUSINESS_UNITS:
        case businessUnit.DELETE_PARTNER_BUSINESS_UNITS:
        case subscriptions.CREATE_PARTNER_SUBSCRIPTIONS:
        case subscriptions.PARTNER_SUBSCRIPTION_ACTION:
        case subscriptions.DELETE_SUBSCRIPTION_PRICES:
        case subscriptions.CREATE_SUBSCRIPTION_PRICE:
        case subscriptions.CREATE_PARTNER_DISCOUNTS:
        case subscriptions.DELETE_PARTNER_DISCOUNTS:
        case leads.POSTPONE_CLIENT_MATCH_DECISION:
        case leads.POSTPONE_VALIDATION:
        case leads.LEAD_ACTION_RECORD_REJECTION:
        case leads.LEAD_ACTION_FORGOT_LEAD:
        case leads.LEAD_ACTION_GIVEN_UP:
        case leads.CLOSE_LEAD_COMPLETED:
        case leads.LEAD_ACTION_DISCARD:
        case leads.ACCEPT_LEAD_QUOTE:
        case leads.UNREJECT_QUOTES:
        case partners.PARTNER_ACTION_FORGOT_PARTNER:
        case partners.CREATE_PARTNER_ACTION:
        case quotes.REJECT_QUOTES:
        case quotes.DELETE_QUOTE:
        case CREATE_PAUSE_PARTNER_SUBSCRIPTIONS[0]:
        case INVOICES_REFUND_REQUEST_ACTION:
        case CREATE_LEAD_ACTION:
        case CREATE_PARTNER_FEE:
        case DELETE_PARTNER_USER[0]:
            return {
                ...state,
                disabled: true,
            }

        case HIDE_MODAL:
        case DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES[1]:
        case businessUnit.CREATE_PARTNER_BUSINESS_UNITS_SUCCESS:
        case businessUnit.UPDATE_PARTNER_BUSINESS_UNITS_SUCCESS:
        case businessUnit.DELETE_PARTNER_BUSINESS_UNITS_SUCCESS:
        case subscriptions.CREATE_PARTNER_SUBSCRIPTIONS_SUCCESS:
        case subscriptions.PARTNER_SUBSCRIPTION_ACTION_SUCCESS:
        case subscriptions.DELETE_SUBSCRIPTION_PRICES_SUCCESS:
        case subscriptions.CREATE_SUBSCRIPTION_PRICE_SUCCESS:
        case subscriptions.CREATE_PARTNER_DISCOUNTS_SUCCESS:
        case subscriptions.DELETE_PARTNER_DISCOUNTS_SUCCESS:
        case leads.POSTPONE_CLIENT_MATCH_DECISION_SUCCESS:
        case leads.POSTPONE_VALIDATION_SUCCESS:
        case leads.LEAD_ACTION_RECORD_REJECTION_SUCCESS:
        case leads.LEAD_ACTION_FORGOT_LEAD_SUCCESS:
        case leads.CLOSE_LEAD_COMPLETED_SUCCESS:
        case leads.LEAD_ACTION_GIVEN_UP_SUCCESS:
        case leads.LEAD_ACTION_DISCARD_SUCCESS:
        case leads.ACCEPT_LEAD_QUOTE_SUCCESS:
        case leads.UNREJECT_QUOTES_SUCCESS:
        case partners.PARTNER_ACTION_FORGOT_PARTNER_SUCCESS:
        case partners.CREATE_PARTNER_ACTION_SUCCESS:
        case quotes.REJECT_QUOTES_SUCCESS:
        case quotes.DELETE_QUOTE_SUCCESS:
        case CREATE_PAUSE_PARTNER_SUBSCRIPTIONS[1]:
        case INVOICES_REFUND_REQUEST_ACTION_SUCCESS:
        case CREATE_LEAD_ACTION_SUCCESS:
        case CREATE_PARTNER_FEE_SUCCESS:
        case DELETE_PARTNER_USER[1]:
            return {}

        case UN_DISABLE_MODAL:
        case DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES[2]:
        case businessUnit.CREATE_PARTNER_BUSINESS_UNITS_FAIL:
        case businessUnit.UPDATE_PARTNER_BUSINESS_UNITS_FAIL:
        case businessUnit.DELETE_PARTNER_BUSINESS_UNITS_FAIL:
        case subscriptions.CREATE_PARTNER_SUBSCRIPTIONS_FAIL:
        case subscriptions.PARTNER_SUBSCRIPTION_ACTION_FAIL:
        case subscriptions.DELETE_SUBSCRIPTION_PRICES_FAIL:
        case subscriptions.CREATE_SUBSCRIPTION_PRICE_FAIL:
        case subscriptions.CREATE_PARTNER_DISCOUNTS_FAIL:
        case subscriptions.DELETE_PARTNER_DISCOUNTS_FAIL:
        case leads.POSTPONE_CLIENT_MATCH_DECISION_FAIL:
        case leads.POSTPONE_VALIDATION_FAIL:
        case leads.LEAD_ACTION_RECORD_REJECTION_FAIL:
        case leads.LEAD_ACTION_FORGOT_LEAD_FAIL:
        case leads.CLOSE_LEAD_COMPLETED_FAIL:
        case leads.LEAD_ACTION_GIVEN_UP_FAIL:
        case leads.LEAD_ACTION_DISCARD_FAIL:
        case leads.ACCEPT_LEAD_QUOTE_FAIL:
        case leads.UNREJECT_QUOTES_FAIL:
        case partners.PARTNER_ACTION_FORGOT_PARTNER_FAIL:
        case partners.CREATE_PARTNER_ACTION_FAIL:
        case quotes.REJECT_QUOTES_FAIL:
        case quotes.DELETE_QUOTE_FAIL:
        case CREATE_PAUSE_PARTNER_SUBSCRIPTIONS[2]:
        case INVOICES_REFUND_REQUEST_ACTION_FAIL:
        case CREATE_LEAD_ACTION_FAIL:
        case CREATE_PARTNER_FEE_FAIL:
        case DELETE_PARTNER_USER[2]:
        case partners.CREATE_PARTNER_UNIQUE_SELLING_POINT_FAIL:
        case partners.UPDATE_PARTNER_UNIQUE_SELLING_POINT_FAIL:
        case partners.DELETE_PARTNER_UNIQUE_SELLING_POINT_FAIL:
            return {
                ...state,
                disabled: false,
            }

        default:
            return state
    }
}
