import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import { GET_ALLOCATIONS, LEAD_CREATE_ALLOCATION } from './actions'

const allocations = combineReducers({
    all: generalSubjectReducer(GET_ALLOCATIONS),
    lead: generalSubjectReducer(GET_ALLOCATIONS, LEAD_CREATE_ALLOCATION),
})

export default allocations
