import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_BUSINESS_MODELS = actionTypesCreator('businessModels', 'GET_BUSINESS_MODELS')

interface GetBusinessModels {
    businessModelId?: number
    identifier?: string
    geoCode?: string
    limit?: number
    page?: number
    query?: string
}

export const getBusinessModels = (criteria: GetBusinessModels) => ({
    [CALL_API]: {
        types: GET_BUSINESS_MODELS,
        endpoint: '/business_models',
        schema: schemas.businessModels,
        options: {
            body: {
                limit: 100,
                ...criteria,
            },
        },
    },
})
