import React, { memo } from 'react'
import moment, { Moment } from 'moment'
import FormattedDate from '../../FormattedDate'
import StateCell from './StateCell'

interface OverdueCellModel {
    date: string | Moment
    format?: string
}

const OverdueCell = memo(({
    date,
    format,
}: OverdueCellModel) => {
    date = moment(date)
    return (
        <StateCell danger={date.isBefore()} title={date.format('llll')}>
            <FormattedDate date={date} format={format} />
        </StateCell>
    )
})

export default OverdueCell
