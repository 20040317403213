import React, { memo } from 'react'
import numeral from 'numeral'
import { Amount } from '../../reducers/types/entities/Amount'

interface FormattedAmountModel {
    amount: Amount
    format?: string
}
export const FormattedAmount = memo(({ amount, format }: FormattedAmountModel) => {
    let price = amount ? amount.amount : null

    const currency = amount ? amount.currency || 'EUR' : null

    if (typeof format !== 'undefined') price = numeral(price).format(format)

    return <span className="number">
        {price} 
        {' '}
        {currency}
    </span>
})
