import React, { PureComponent } from 'react'
import { Card } from '../blocks/Card'
import { connect } from '../../utils/connect'
import { Col, Panel, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { SegmentationSubGeoRegion } from '../../reducers/types/entities/SegmentationSubGeoRegion'
import { GeoRegions, GeoRegion } from '../../reducers/types/entities/GeoRegion'
import config from '../../config'
import { CheckboxInputField } from '../elements/forms/inputs/CheckboxGroup'
import { selectPartnerDetailBusinessUnits } from '../../selectors/partner'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import { Typeahead } from 'react-bootstrap-typeahead'
import { forIn } from 'lodash'
import ColorHash from 'color-hash'
import GeoRegionsMap from '../elements/GeoRegionsMap'
import { getGeoRegions } from '../../state/geoRegions/actions'
import { getSegmentationSubGeoRegions } from '../../state/segmentationSubGeoRegions/actions'
import {
    allSegmentationSubGeoRegionsSelector,
    segmentationRegionAndSubRegionsSelector,
} from '../../state/segmentationSubGeoRegions/selectors'

const regionColor = (id) => new ColorHash().hex(id)

const MapElement = styled.div`
  height: 87vh;
`

const RegionLabel = styled.div<{regionId: number}>`
  height: 20px;
  width: 20px;
  padding: 2px 9px;
  border-radius: 5px;
  margin-right: 1em;
  background-color: ${({ regionId }) => regionColor(regionId)}80;
  border: 1px solid ${({ regionId }) => regionColor(regionId)}A6;
`

const RegionsUl = styled.ul`
  list-style: none;
  padding-left: 0;
  max-height: 75vh;
  overflow-y: auto;
  li {
    margin: 5px 0;
  }
  ul {
    list-style: none;
    padding-left: 30px;
  }
`

const CheckboxText = styled.div`
  width: 100%;
`

const SearchInput = styled(Typeahead)`
  .form-control {
    height: initial;
  }
`
const RegionPanel =  styled(Panel)`
  border: none;
  margin-bottom: 0;
`

const RegionPanelHeading =  styled(Panel.Heading)`
  border: 0;
  background-color: transparent!important;
`

export interface Props {
    subGeoRegions: SegmentationSubGeoRegion[]
    geoRegions: GeoRegions
    businessUnits: BusinessUnit[]
    geoRegionsId: number[]
    regionsAndSubRegions: GeoRegion[] & SegmentationSubGeoRegion[]
}

export interface Actions {
    getSegmentationSubGeoRegions: typeof getSegmentationSubGeoRegions
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getGeoRegions: typeof getGeoRegions
}

export interface State {
    showBusinessUnits: boolean
    selectedPolygons
    subGeoRegions: SegmentationSubGeoRegion[]
}
export type Model = Props & Actions

@connect<Props, Actions, any>(
    state => ({
        subGeoRegions: allSegmentationSubGeoRegionsSelector(state),
        businessUnits: selectPartnerDetailBusinessUnits(state),
        regionsAndSubRegions: segmentationRegionAndSubRegionsSelector(state),
        geoRegions: state.entities.geoRegions,
        geoRegionsId: state.subjects.geoRegions.all.ids,
    }),
    {
        getSegmentationSubGeoRegions,
        getPartnerBusinessUnits,
        getGeoRegions,
    }
)
export default class SegmentationRegionsPage extends PureComponent<
    Model, State
> {
    state = {
        showBusinessUnits: false,
        selectedPolygons: {},
        subGeoRegions: [],
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        this.props.getPartnerBusinessUnits({ geoCode: config.geoCode })
        await this.props.getGeoRegions({ geoCode: config.geoCode })

        const resSubGeoRegions: any = await this.props.getSegmentationSubGeoRegions({
            segmentationGeoRegionId: this.props.geoRegionsId,
        })

        const subGeoRegions = resSubGeoRegions.result
            .map(id => resSubGeoRegions.entities.segmentationSubGeoRegions[id])

        const selectedPolygons = this.props.geoRegionsId
            .reduce((acc, id) => {
                const reduceCheckedSubRegions = (regionId) => subGeoRegions
                    .reduce((acc2, subGeoRegion) =>
                        subGeoRegion.segmentationGeoRegionId === regionId ? ({ ...acc2, [subGeoRegion.id]: true }) : acc2,
                    {})
                return ({
                    ...acc,
                    [id]: {
                        checked: true,
                        subRegions: {
                            ...reduceCheckedSubRegions(id),
                        },
                    },
                })
            }, {})

        this.setState({ selectedPolygons, subGeoRegions })
    }

    onChangeRegions = (checked, regionId, subRegionId?) => {
        const reduceCheckedSubRegions = (checkboxStatus, subRegions) => {
            const newCheckedSubRegions = {}
            for (const id in subRegions) if (subRegions.hasOwnProperty(id)) newCheckedSubRegions[id] = checkboxStatus
            return newCheckedSubRegions
        }

        this.setState((state, props) => {
            const selectedPolygons = {
                ...state.selectedPolygons,
                [regionId]: subRegionId
                    ? {
                        checked: state.selectedPolygons[regionId].checked,
                        subRegions: {
                            ...state.selectedPolygons[regionId].subRegions,
                            [subRegionId]: checked,
                        },
                    }
                    : {
                        checked,
                        subRegions: {
                            ...reduceCheckedSubRegions(checked, state.selectedPolygons[regionId].subRegions),
                        },
                    },
            }

            const subGeoRegions = props.subGeoRegions.filter(el =>
                selectedPolygons[el.segmentationGeoRegionId].subRegions[el.id]
            )

            return ({ ...state, selectedPolygons, subGeoRegions })
        })
    }

    onChangeInput = (listOptions) => {
        forIn(this.state.selectedPolygons, (region: any, regionId: string) => {
            if (listOptions.find(el => el.regionName && el.id === Number(regionId)))
                this.onChangeRegions(true, regionId)
            else
                this.onChangeRegions(false, regionId)

            forIn(region.subRegions, (subRegion, subRegionId: string) => {
                if (listOptions.find(el => el.segmentationGeoRegionId && el.id === Number(subRegionId)))
                    this.onChangeRegions(true, regionId, subRegionId)
            })
        })
    }
    renderLabelKeyChildren = option => option.subRegionName || option.regionName

    renderMenuItemChildren = option => option.subRegionName ||
        <div>
            <RegionLabel regionId={option.id}/> 
            {' '}
            <b>{option.regionName}</b>
        </div>

    render() {
        const {
            regionsAndSubRegions,
            geoRegions,
            businessUnits,
            geoRegionsId,
        } = this.props

        const { subGeoRegions, selectedPolygons, showBusinessUnits } = this.state

        return <Card margin="0">
            <Card.Header>Segmentation regions</Card.Header>
            <Row>
                <Col md={10}>
                    <GeoRegionsMap
                        subGeoRegions={subGeoRegions}
                        geoRegions={geoRegions}
                        containerElement={<MapElement/>}
                        mapElement={<MapElement/>}
                        showBusinessUnits={showBusinessUnits}
                        businessUnits={businessUnits}
                        center={config.centers[config.geoCode]}
                    />
                </Col>
                <Col md={2}>
                    <Card.Content>
                        <CheckboxInputField
                            text="Show partners"
                            input={{
                                value: showBusinessUnits,
                                onChange: (e) => this.setState({ showBusinessUnits: e.target.checked }),
                            }}
                        />
                        <br />
                        <SearchInput
                            onChange={this.onChangeInput}
                            placeholder="Search region, sub region ..."
                            options={regionsAndSubRegions}
                            labelKey={this.renderLabelKeyChildren}
                            renderMenuItemChildren={this.renderMenuItemChildren}
                            clearButton
                            multiple
                        />
                        <br />
                        <Card.Label>Regions</Card.Label>
                        <RegionsUl>
                            {geoRegionsId.map(regionId =>
                                <RegionPanel key={regionId}>
                                    <RegionPanelHeading>
                                        <li key={'regionId_' + regionId}>
                                            <CheckboxInputField
                                                text={<CheckboxText>
                                                    <Panel.Title toggle>
                                                        <RegionLabel regionId={regionId}/> 
                                                        {' '}
                                                        <b>{geoRegions[regionId].regionName}</b>
                                                    </Panel.Title>
                                                </CheckboxText>}
                                                input={{
                                                    value: selectedPolygons[regionId] && selectedPolygons[regionId].checked,
                                                    onChange: e => this.onChangeRegions(e.target.checked, regionId),
                                                }}
                                            />
                                        </li>
                                    </RegionPanelHeading>
                                    <Panel.Collapse>
                                        <ul>
                                            {this.props.subGeoRegions.map(subGeoRegion =>
                                                subGeoRegion.segmentationGeoRegionId === regionId &&
                                                <li key={'subGeoRegion_' + subGeoRegion.id}>
                                                    <CheckboxInputField
                                                        text={subGeoRegion.subRegionName}
                                                        input={{
                                                            value: selectedPolygons[regionId] && selectedPolygons[regionId].subRegions[subGeoRegion.id],
                                                            onChange: e => {
                                                                this.onChangeRegions(e.target.checked, regionId, subGeoRegion.id)
                                                            },
                                                        }}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </Panel.Collapse>
                                </RegionPanel>
                            )}
                        </RegionsUl>
                    </Card.Content>
                </Col>
            </Row>
        </Card>
    }
}
