import React, { ComponentType, PureComponent } from 'react'
import moment from 'moment/moment'
import { Panel, PanelGroup } from 'react-bootstrap'
import CallButton from '../../elements/CallButton'
import { createCallPartnerAction } from '../../../actions/partners'
import { CopyToClipboard } from '../../elements/CopyToClipboard'
import { ProfilePicture } from '../../elements/ProfilePicture'
import Placeholder from '../../elements/Placeholder'
import { Card } from '../../blocks/Card'
import { BtnHref, BtnLink } from '../../elements/Links'
import { Flex } from '../../elements/Flex'
import { connect } from '../../../utils/connect'
import styled from 'styled-components'
import { getPartnerUsers } from '../../../state/partnerUsers/actions'
import { partnerUsersByPartnerSelector } from '../../../state/partnerUsers/selectors'

const PanelGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 50px auto 25px;
  align-items: center;
`

const UserPanel =  styled(Panel)`
  border: 0;
  & > .panel-heading {
    background-color: initial;
    p {
      font-weight: normal;
    }
  }
`

interface StatePropsPartnerUsers {
    loading: boolean
    partnerUsers
}
interface DispatchPropsPartnerUsers {
    createCallPartnerAction: typeof createCallPartnerAction
    getPartnerUsers: typeof getPartnerUsers
}
interface PathPropsPartnerUsers {
    partnerId: number
    geoCode: string
}

type PartnerUsersModel = StatePropsPartnerUsers & DispatchPropsPartnerUsers & PathPropsPartnerUsers

@connect<StatePropsPartnerUsers, DispatchPropsPartnerUsers, PathPropsPartnerUsers>(
    (state, { partnerId }) => ({
        loading: state.subjects.partnerUsers.all.isLoading,
        partnerUsers: partnerUsersByPartnerSelector(state, partnerId),
    }),
    {
        createCallPartnerAction,
        getPartnerUsers,
    },
)

class PartnerUsersCard extends PureComponent<PartnerUsersModel> {
    state = {
        activeKey: 0,
    }

    componentDidMount() {
        this.props.getPartnerUsers({
            partnerId: this.props.partnerId,
            limit: 10000,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.partnerId !== prevProps.partnerId) {
            this.props.getPartnerUsers({
                partnerId: this.props.partnerId,
                limit: 10000,
            })
        }
    }

    callButton(partnerId, phone) {
        return this.props.createCallPartnerAction(partnerId, phone)
    }

    render() {
        const {
            partnerUsers,
            partnerId,
            loading,
            geoCode,
        } = this.props

        return (
            <Card className="partnerUsersCard">
                {!loading && partnerUsers.length === 0 && <BtnLink
                    modifiers={[ 'warning', 'p_1', 'fullWidth', 'btnBlock' ]}
                    to={'/partners/' + partnerId + '/users/create'}
                >
                    Complete this Partner with a User
                </BtnLink>}
                {!loading && partnerUsers.length > 0 && partnerUsers.filter(partnerUser => partnerUser.accessType === 'master').length === 0 &&
                    <div className="alert alert-danger">No Master User. Set a Master User Now.</div>}
                <Card.Header>Partner users</Card.Header>
                <Card.Content modifiers={[ 'maxHeight', 'scroll' ]} scrollHeight={'calc(100% - 145px)'}>
                    {loading
                        ? <Placeholder counter={3} portrait/>
                        : <PanelGroup id="partner-users-panel" defaultActiveKey={this.state.activeKey} accordion>
                            {partnerUsers.map((user, i) =>
                                <UserPanel key={user.id} eventKey={i}>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            <PanelGrid>
                                                <ProfilePicture src={user.profilePicture ? user.profilePicture : '/assets/images/no-picture-color.png'}/>
                                                <div>
                                                    <Card.Text modifiers="m_0">
                                                        {user.firstName} 
                                                        {' '}
                                                        {user.lastName}
                                                        (
                                                        <CopyToClipboard>{user.email}</CopyToClipboard>
                                                        )
                                                    </Card.Text>
                                                    <Card.Meta>
                                                        {user.accessType === 'master' && <span>Master · </span>}
                                                        {user.isFinanceUser && <span>Finance · </span>}
                                                        {user.lastLogin && <span className="last-online">
                                                            Online 
                                                            {' '}
                                                            {moment(user.lastLogin).fromNow()}
&nbsp;
                                                            (
                                                            {moment(user.lastLogin).format('YYYY-MM-DD')}
                                                            )
                                                        </span>}
                                                        <span className="last-online">{!user.lastLogin && 'Never logged in'}</span>
                                                    </Card.Meta>
                                                </div>
                                                <div>
                                                    <i className="mdi mdi-chevron-down mdi-24px"/>
                                                    <i className="mdi mdi-chevron-up mdi-24px"/>
                                                </div>
                                            </PanelGrid>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Flex modifiers={[ 'p_1.5' ]}>
                                            {user.phone && <CallButton
                                                label={'Phone'}
                                                onCall={this.callButton.bind(this, partnerId, user.phone)}
                                                phoneNumber={user.phone}
                                                possibilityToCopy={true}
                                                geoCode={geoCode}
                                            />}
&nbsp; &nbsp;
                                            {user.mobile && <CallButton
                                                label={'Mobile'}
                                                onCall={this.callButton.bind(this, partnerId, user.mobile)}
                                                phoneNumber={user.mobile}
                                                possibilityToCopy={true}
                                                geoCode={geoCode}
                                            />}
                                            <BtnLink
                                                to={'/partners/' + partnerId + '/users/' + user.id}
                                                modifiers={[ 'secondary', 'mL_2' ]}
                                            >
                                                Edit user
                                            </BtnLink>
                                            <BtnHref
                                                href={'/partner-users/' + user.id + '/login'}
                                                modifiers={[ 'secondary', 'mL_2' ]}
                                                target="_blank"
                                            >
                                                Log in as user
                                            </BtnHref>
                                        </Flex>
                                    </Panel.Collapse>
                                </UserPanel>
                            )}
                        </PanelGroup>
                    }
                </Card.Content>
                <Card.Content>
                    <BtnLink
                        modifiers={[ 'secondary', 'p_1', 'fullWidth', 'btnBlock' ]}
                        to={'/partners/' + partnerId + '/users/create'}
                    >
                        Create new partner user
                    </BtnLink>
                </Card.Content>
            </Card>
        )
    }
}

export default PartnerUsersCard as unknown as ComponentType<PathPropsPartnerUsers>
