import { schema } from 'normalizr'
import { Invoicing } from './reducers/types/entities/Invoicing'

const { Entity, Array } = schema

export const client = new Entity('clients')
export const clients = new Array(client)

export const partner = new Entity('partners')
export const partners = new Array(partner)

export const partnerCategory = new Entity('partnerCategories')
export const partnerCategories = new Array(partnerCategory)

export const leadPartnerMeetingRequest = new Entity('leadPartnerMeetingRequests')
export const leadPartnerMeetingRequests = new Array(leadPartnerMeetingRequest)

export const partnerUser = new Entity('partnerUsers')
export const partnerUsers = new Array(partnerUser)

export const employee = new Entity('employees')
export const employees = new Array(employee)

export const paymentMethod = new Entity('paymentMethods')
export const paymentMethods = new Array(paymentMethod)

export const paymentSolution = new Entity('paymentSolutions')
export const paymentSolutions = new Array(paymentSolution)

export const paymentSchedule = new Entity('paymentSchedules', {}, { idAttribute: 'identifier' })
export const paymentSchedules = new Array(paymentSchedule)

export const industry = new Entity('industries')
export const industries = new Array(industry)

export const invoice = new Entity('invoices')
export const invoices = new Array(invoice)

export const subscription = new Entity('subscriptions')
export const subscriptions = new Array(subscription)

export const certification = new Entity('certifications')
export const certifications = new Array(certification)

export const lead = new Entity('leads')
export const leads = new Array(lead)

export const payment = new Entity('payments')
export const payments = new Array(payment)

export const location = new Entity('locations')
export const locations = new Array(location)

export const affiliate = new Entity('affiliates')
export const affiliates = new Array(affiliate)

export const campaign = new Entity('campaigns')
export const campaigns = new Array(campaign)

export const leadType = new Entity('leadTypes')
export const leadTypes = new Array(leadType)

export const rating = new Entity('ratings')
export const ratings = new Array(rating)

export const subscriptionPackage = new Entity('subscriptionPackages')
export const subscriptionPackages = new Array(subscriptionPackage)

export const transaction = new Entity('transactions')
export const transactions = new Array(transaction)

export const coupon = new Entity('coupons')
export const coupons = new Array(coupon)

export const invoicing = new Entity('invoicings', {}, { idAttribute: (entity: Invoicing) => `${entity.source.type}-${entity.source.id}` })
export const invoicings = new Array(invoicing)

export const paymentCard = new Entity('paymentCards')
export const paymentCards = new Array(paymentCard)

export const permission = new Entity('permissions')
export const permissions = new Array(permission)

export const cv = new Entity('cvs')
export const cvs = new Array(cv)

export const language = new Entity('languages', {}, { idAttribute: 'identifier' })
export const languages = new Array(language)

export const refund = new Entity('refunds')
export const refunds = new Array(refund)

export const task = new Entity('tasks')
export const tasks = new Array(task)

export const opportunity = new Entity('opportunities')
export const opportunities = new Array(opportunity)

export const quote = new Entity('quotes')
export const quotes = new Array(quote)

export const quoteItem = new Entity('quoteItems')
export const quoteItems = new Array(quoteItem)

export const activity = new Entity('activities')
export const activities = new Array(activity)

export const term = new Entity('terms')
export const terms = new Array(term)

export const generalQuote = new Entity('generalQuotes')
export const generalQuotes = new Array(generalQuote)

export const contactArchive = new Entity('contactArchives')
export const contactArchives = new Array(contactArchive)

export const partnerFees = new Entity('partnersFees')
export const partnersFees = new Array(partnerFees)

export const invoiceRefundRequest = new Entity('invoiceRefundRequests')
export const invoiceRefundRequests = new Array(invoiceRefundRequest)

export const dataField = new Entity('dataFields')
export const dataFields = new Array(dataField)

export const leadAggregation = new Entity('leadAggregations')
export const leadAggregations = new Array(leadAggregation)

export const product = new Entity('products')
export const products = new Array(product)

export const leadCategory = new Entity('leadCategories')
export const leadCategories = new Array(leadCategory)

export const partnerDiscount = new Entity('partnerDiscounts')
export const partnerDiscounts = new Array(partnerDiscount)

export const packageType = new Entity('packageTypes')
export const packageTypes = new Array(packageType)

export const sector = new Entity('sectors')
export const sectors = new Array(sector)

export const notification = new Entity('notifications')
export const notifications = new Array(notification)

export const kpiValue = new Entity('kpiValues', {},
    { idAttribute: value => `${value.employeeId}-${value.kpiIdentifier}-${value.year}-${value.month + (value.day ? '-' + value.day : '')}` }
)
export const kpiValues = new Array(kpiValue)

export const kpiTarget = new Entity('kpiTargets', {},
    { idAttribute: value => `${value.employeeId}-${value.kpiIdentifier}-${value.year}-${value.month + (value.day ? '-' + value.day : '')}` }
)
export const kpiTargets = new Array(kpiTarget)

export const businessUnit = new Entity('businessUnits')
export const businessUnits = new Array(businessUnit)

export const partnerContract = new Entity('partnerContracts')
export const partnerContracts = new Array(partnerContract)

export const partnerContractPause = new Entity('partnerContractPauses')
export const partnerContractPauses = new Array(partnerContractPause)

export const partnerContractBoost = new Entity('partnerContractBoosts')
export const partnerContractBoosts = new Array(partnerContractBoost)

export const partnerContractOfferGroup = new Entity('partnerContractOfferGroups')
export const partnerContractOfferGroups = new Array(partnerContractOfferGroup)

export const subscriptionPrice = new Entity('subscriptionPrices')
export const subscriptionPrices = new Array(subscriptionPrice)

export const subscriptionAgreement = new Entity('subscriptionAgreements')
export const subscriptionAgreements = new Array(subscriptionAgreement)

export const allocationsQuota = new Entity('allocationsQuotas')
export const allocationsQuotas = new Array(allocationsQuota)

export const geoRegion = new Entity('geoRegions')
export const geoRegions = new Array(geoRegion)

export const partnerTitle = new Entity('partnerTitles')
export const partnerTitles = new Array(partnerTitle)

export const pauseSubscription = new Entity('pauseSubscriptions')
export const pauseSubscriptions = new Array(pauseSubscription)

export const allocation = new Entity('allocations')
export const allocations = new Array(allocation)

export const revenueSegment = new Entity('revenueSegments')
export const revenueSegments = new Array(revenueSegment)

export const segmentationSubGeoRegion = new Entity('segmentationSubGeoRegions')
export const segmentationSubGeoRegions = new Array(segmentationSubGeoRegion)

export const partnerUniqueSellingPoint = new Entity('partnerUniqueSellingPoints')
export const partnerUniqueSellingPoints = new Array(partnerUniqueSellingPoint)

export const delivery = new Entity('deliveries')
export const deliveries = new Array(delivery)

export const partnerEmployeeNote = new Entity('partnerEmployeeNote')
export const partnerEmployeeNotes = new Array(partnerEmployeeNote)

export const partnerSignup = new Entity('partnerSignups')
export const partnerSignups = new Array(partnerSignup)

export const allocationSuggestion = new Entity('allocationSuggestions')
export const allocationSuggestions = new Array(allocationSuggestion)

const createId = value => {
    value.id = `${value.leadId}-${value.partnerId}`
    return value
}

export const cMatch = new Entity('cMatches', {},
    {
        idAttribute: value => `${value.leadId}-${value.partnerId}-${value.acceptedAt ? 'accepted' : 'unaccepted'}`,
        processStrategy: createId
    }
)
export const cMatches = new Array(cMatch)

export const businessModel = new Entity('businessModels')
export const businessModels = new Array(businessModel)

export const allocationLimit = new Entity('allocationLimits')
export const allocationLimits = new Array(allocationLimit)

export const partnerFeature = new Entity('partnerFeatures')
export const partnerFeatures = new Array(partnerFeature)

export const companySize = new Entity('companySizes')
export const companySizes = new Array(companySize)

export const partnerAssignee = new Entity('partnerAssignees')
export const partnerAssignees = new Array(partnerAssignee)

export const phoneVerification = new Entity('phoneVerification')
export const phoneVerifications = new Array(phoneVerification)

partner.define({
    industries,
    preferredPaymentSolution: paymentSolution,
})

paymentCard.define({
    paymentMethod,
})

paymentMethod.define({
    paymentCard,
})

partnerContract.define({
    businessUnits: [ {
        clientTypes: leadTypes,
        geoRegions,
        industries,
        partnerUser,
        sectors,
    } ],
})

task.define({
    employee,
    assignedEmployee: employee,
})

lead.define({
    quotesInfo: { quotes },
})
