import React, { Fragment, PureComponent } from 'react'
import { Card } from '../blocks/Card'
import { connect } from '../../utils/connect'
import { getPartner } from '../../actions/partners'
import { Partner } from '../../reducers/types/entities/Partner'
import { actualSubscriptionSelector } from '../../selectors/subscription'
import { Subscription } from '../../reducers/types/entities/Subscription'
import {
    getPackageTypes,
    getPartnerDiscounts,
    getPartnerSubscriptions,
    getSubscriptionPrices,
} from '../../actions/subscriptions'
import { createPausePartnerSubscription, getPausePartnerSubscription } from '../../state/pauseSubscriptions/actions'
import { getPaymentSolutions } from '../../actions/paymentSolutions'
import { showModal } from '../../actions/modal'
import { SubscriptionPrice } from '../../reducers/types/entities/SubscriptionPrice'
import { PauseSubscription } from '../../reducers/types/entities/PauseSubscription'
import { PartnerDiscount } from '../../reducers/types/entities/PartnerDiscount'
import { PaymentSolution } from '../../reducers/types/entities/PaymentSolution'
import { PackageTypes } from '../../reducers/types/entities/PackageType'
import {
    subscriptionPartnerDiscountsSelector,
    subscriptionPriceSelector,
} from '../../selectors/invoices'
import { partnerPauseSubscriptionsSelect } from '../../state/pauseSubscriptions/selectors'
import { paymentSolutionsSelector } from '../../selectors/payment'
import moment from 'moment'

import { Flex } from '../elements/Flex'
import { flatten, groupBy } from 'lodash'
import styled from 'styled-components'
import Slider from 'rc-slider'
import { FormattedAmount } from '../elements/FormattedAmount'
import { Button } from '../elements/Button'
import { DropdownButton } from 'react-bootstrap'
import Datetime from 'react-datetime'
import { toastr } from 'react-redux-toastr'
import { borderColor } from '../../utils/variables'

const formatDate = stringDate => moment(stringDate).format('YYYY-MM-DD')

const formatMonths = intMonth => (intMonth === 1) ? 'month' : `${intMonth.toString()} months`

const yesterday = moment().subtract( 1, 'day' )

function isValidDate(currentDate) {
    return currentDate.isAfter(yesterday)
}

const TimeLinContainer = styled.div`
    position: relative;
    margin: 60px 60px 120px 85px;
    ${Flex} {
        position: absolute;
        width: 100%;
        top: -32px;
    }
`

const SliderRange = styled(Slider)`
  background-color: initial;
  .rc-slider-track {
      background-color: #abe2fb;
  }
  .rc-slider-mark {
      top: -35px;
      .rc-slider-mark-text {
        cursor: initial!important;
      }
      .rc-slider-mark-text-active .mdi {
        color: #62b0e3;
      }
  }
`

const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid: repeat(1, auto) / auto-flow;
  grid-gap: 0 20px;
  padding: 20px;
`

const ButtonContainer = styled.div<{divider?: boolean}>`
  min-width: 120px;
  text-align: center;
  ${props => props.divider && `
      padding-left: 20px;
      border-left: 1px solid ${borderColor};
  `};
`

const CustomDropdownButton = styled(DropdownButton)`
    width: 100%;
    padding: 7px 14px;
    font-size: 15px;
`

const CardContent = styled(Card.Content)`
    height: calc(100vh - 165px);
`

const info = {
    start: {
        icon: 'airplane-takeoff',
    },
    discountStart: {
        icon: 'sale',
    },
    discountEnd: {
        icon: 'sack-percent',
    },
    pauseStart: {
        icon: 'pause-octagon',
        description: 'Pause starts',
    },
    pauseEnd: {
        icon: 'pause-octagon-outline',
        description: 'Pause ends',
    },
    cancel: {
        icon: 'file-cancel-outline',
        description: 'Subscription cancelled',
    },
    churned: {
        icon: 'cancel',
        description: 'Subscription churned',
    },
    price: {
        icon: 'currency-usd',
    },
}

export interface Props {
    cancelSubscriptionFromDatePermission: any
    subscriptionPrices: SubscriptionPrice[]
    pauseSubscription: {
        isActivePause: boolean
        pauses: PauseSubscription[]
    }
    packageDiscounts: PartnerDiscount[]
    paymentSolutions: PaymentSolution[]
    packageTypes: PackageTypes
    partner: Partner
    packagePausesCreateWithArbitraryDates
    packagePausesCreateUnlimitedDuration
    subscriptionInfo: {
        createdSubscription: Subscription,
        canceledSubscription: Subscription,
        isActiveSubscription: boolean,
        isAvailableForSubscription: boolean,
    }
    modal
}
export interface Actions {
    createPausePartnerSubscription: typeof createPausePartnerSubscription
    getPausePartnerSubscription: typeof getPausePartnerSubscription
    getPartnerSubscriptions: typeof getPartnerSubscriptions
    getSubscriptionPrices: typeof getSubscriptionPrices
    getPartnerDiscounts: typeof getPartnerDiscounts
    getPaymentSolutions: typeof getPaymentSolutions
    getPackageTypes: typeof getPackageTypes
    getPartner: typeof getPartner
    showModal: typeof showModal
}
export interface PathProps {
    match: {
        params: {
            partnerId: number
        }
    }
}
export type Model = Props & Actions & PathProps

@connect<
    Props,
    Actions,
    PathProps
>(
    (state, { match: { params: { partnerId } } }) => ({
        packageDiscounts: subscriptionPartnerDiscountsSelector(state),
        partner: state.entities.partners[partnerId],
        subscriptionPrices: subscriptionPriceSelector(state),
        pauseSubscription: partnerPauseSubscriptionsSelect(state),
        paymentSolutions: paymentSolutionsSelector(state),
        packageTypes: state.entities.packageTypes,
        cancelSubscriptionFromDatePermission: state.entities.permissions.subscription_cancel_by_date,
        packagePausesCreateWithArbitraryDates: state.entities.permissions.package_pauses_create_with_arbitrary_dates,
        packagePausesCreateUnlimitedDuration: state.entities.permissions.package_pauses_create_unlimited_duration,
        subscriptionInfo: actualSubscriptionSelector(state, partnerId),
        modal: state.modal,
    }),
    {
        createPausePartnerSubscription,
        getPausePartnerSubscription,
        getPartnerSubscriptions,
        getSubscriptionPrices,
        getPartnerDiscounts,
        getPaymentSolutions,
        getPackageTypes,
        getPartner,
        showModal,
    }
)
export default class PartnerSubscriptionTimeLinePage extends PureComponent<Model> {
    state = {
        activeStep: 0,
        isOpenCustomPause: false,
        isOpenMonthPause: false,
        legend: {},
        maxSteps: 0,
        monthlyPrice: { amount: 0, currency: '' },
        pauseFrom: null,
        pauseTo: null,
    }

    componentDidMount() {
        const { partnerId } = this.props.match.params

        this.props.getPaymentSolutions({ partnerId })
        this.props.getPartnerDiscounts(partnerId)

        const resPartner: any = this.props.getPartner(partnerId)
        resPartner.then(() => this.props.getPackageTypes(this.props.partner.geo.code))

        const subscriptionRes: any = this.props.getPartnerSubscriptions(partnerId)
        subscriptionRes.then(() => {
            const subscription = this.props.subscriptionInfo.createdSubscription
            if (subscription) {
                Promise.all([
                    this.props.getSubscriptionPrices(partnerId, subscription.id),
                    this.props.getPausePartnerSubscription(partnerId, subscription.id),
                ]).then(res => {
                    this.createSubscriptionTimeLine()
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modal.name && !this.props.modal.name) {
            this.createSubscriptionTimeLine()
        }
    }

    createSubscriptionTimeLine = () => {
        const {
            subscriptionInfo: {
                createdSubscription,
            },
            subscriptionPrices,
            pauseSubscription,
            packageDiscounts,
        } = this.props

        if (!createdSubscription) return

        let history = []

        history.push({ ...createdSubscription, type: 'start', sortDate: formatDate(createdSubscription.startsAt) })

        if (packageDiscounts.length)
            history.push(
                ...packageDiscounts
                    .filter(el => el.partnerPackageTypeId === createdSubscription.packageId)
                    .map(el => [
                        { ...el, type: 'discountStart', sortDate: formatDate(el.startsAt) },
                        { ...el, type: 'discountEnd', sortDate: formatDate(el.endsAt) },
                    ])
            )

        if (pauseSubscription)
            history.push(
                ...pauseSubscription.pauses.map(el => [
                    { ...el, type: 'pauseStart', sortDate: formatDate(el.startsAt) },
                    { ...el, type: 'pauseEnd', sortDate: formatDate(el.endsAt) },
                ])
            )

        if (subscriptionPrices.length)
            history.push(
                ...subscriptionPrices.map(el => ({ ...el, type: 'price', sortDate: formatDate(el.effectiveAt) }))
            )

        if (createdSubscription.canceledAt)
            history.push({ ...createdSubscription, type: 'cancel', sortDate: formatDate(createdSubscription.canceledAt) })

        if (createdSubscription.churnedAt)
            history.push({ ...createdSubscription, type: 'churned', sortDate: formatDate(createdSubscription.churnedAt) })

        history = flatten(history).sort((a, b) => moment(a.sortDate).isAfter(b.sortDate) ? 1 : -1)

        const legend = {}

        let activeStep = 0

        let monthlyPrice = { ...createdSubscription.monthlyPrice }

        const groupedByDate = groupBy(history, 'sortDate')

        const timelineSteps = Object.keys(groupedByDate)

        timelineSteps.forEach((step, i) => {
            const endTime = createdSubscription.canceledAt || createdSubscription.churnedAt

            const dayGroup = groupedByDate[step]

            if (moment().isSameOrAfter(step) && (!endTime || moment(step).isSameOrBefore(endTime)) ) {
                activeStep = i

                const discountEndPrice = dayGroup.find(el => el.type === 'discountEnd')
                if (discountEndPrice) monthlyPrice.amount = monthlyPrice.amount + discountEndPrice.discount.amount

                const newPrice = dayGroup.find(el => el.type === 'price')
                if (newPrice) monthlyPrice = { ...newPrice.monthlyPrice }

                const discountStartPrice = dayGroup.find(el => el.type === 'discountStart')
                if (discountStartPrice) monthlyPrice.amount = monthlyPrice.amount - discountStartPrice.discount.amount
            }

            legend[i] = <div className="text-center">
                <p>{dayGroup.map(el => <i className={`mdi mdi-${info[el.type].icon} mdi-24px`}/>)}</p>
                <br />
                <p>{step}</p>
                {dayGroup.map(el => <Fragment>
                    {el.type === 'start' && <p>
                        {el.packageType}
                        {' '}
                        start
                    </p>}
                    {el.type === 'price' && <p>
                        <b>New price:</b>
                        {' '}
                        <FormattedAmount amount={el.monthlyPrice}/>
                    </p>}
                    {el.type === 'discountStart' && <p>
                        <b>Discount:</b>
                        {' '}
                        <FormattedAmount amount={el.discount}/>
                        {' '}
                        start
                    </p>}
                    {el.type === 'discountEnd' && <p>
                        <b>Discount:</b>
                        {' '}
                        <FormattedAmount amount={el.discount}/>
                        {' '}
                        ends
                    </p>}
                    {[ 'cancel', 'churned', 'pauseStart', 'pauseEnd' ].includes(el.type) && <p>{info[el.type].description}</p>}
                </Fragment>)}
            </div>
        })

        this.setState({ legend, activeStep, maxSteps: timelineSteps.length - 1, monthlyPrice })
    }

    setModalProps = data => {
        const modalProps = { ...data }
        if (data.name === 'partnerDiscountCreateModal')
            Object.assign(modalProps, { partnerDiscounts: this.props.packageDiscounts })
        this.props.showModal({
            ...modalProps,
            paymentSolutions: this.props.paymentSolutions,
            partnerId: this.props.match.params.partnerId,
        })
    }

    changePrice = subscription => {
        this.props.showModal({
            subscription,
            subscriptionPrices: this.props.subscriptionPrices,
            name: 'subscriptionChangePriceModal',
        })
    }

    onSelectSubscriptionCancelDate = date => {
        this.props.showModal({
            subscription: this.props.subscriptionInfo.createdSubscription,
            cancelSubscriptionDate: date.format('YYYY-MM-DD HH:mm'),
            partnerId: this.props.match.params.partnerId,
            name: 'subscriptionCancelWithDateConfirm',
        })
    }

    cancelWithCancellationPeriod = () => {
        this.props.showModal({
            subscription: this.props.subscriptionInfo.createdSubscription,
            partnerId: this.props.match.params.partnerId,
            name: 'subscriptionCancelWithCancellationPeriodConfirm',
        })
    }

    onSelectSubscriptionPauseDate = (period, date) => {
        this.setState({ [period]: date.format('YYYY-MM-DD HH:mm') }, () => {
            if (this.state.pauseFrom && this.state.pauseTo) {
                this.props.showModal({
                    subscriptionId: this.props.subscriptionInfo.createdSubscription.id,
                    partnerId: this.props.match.params.partnerId,
                    body: {
                        startsAt: this.state.pauseFrom,
                        endsAt: this.state.pauseTo,
                    },
                    name: 'subscriptionPauseConfirm',
                    headerText: <span>
                        Pause from
                        {this.state.pauseFrom}
                        {' '}
                        to
                        {this.state.pauseTo}
                    </span>,
                    bodyText: 'You are about to pause a subscription with a custom pause period. If the subscription is not paused on the 25th of a month, the partner will receive an invoice. Please also remember it is you as a manager who decides how many leads the partner should receive, if the pause period contains a partial month.',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'primary' ],
                })
            }
        })
    }

    onSelectSubscriptionPauseMonthDate = async date => {
        const startsAt = date.startOf('month').format('YYYY-MM-DD HH:mm')
        const endsAt = date.endOf('month').format('YYYY-MM-DD HH:mm')
        await this.props.createPausePartnerSubscription(
            this.props.match.params.partnerId,
            this.props.subscriptionInfo.createdSubscription.id,
            { startsAt, endsAt }
        )
        this.createSubscriptionTimeLine()
        toastr.success('Success', `Pause between ${startsAt} and ${endsAt} was create`)
    }

    onToggleCustomPauseDropdown = isOpenCustomPause => {
        this.setState({
            isOpenCustomPause,
            pauseFrom: null,
            pauseTo: null,
        })
    }
    onToggleMonthPauseDropdown = isOpenMonthPause => {
        this.setState({ isOpenMonthPause })
    }

    render() {
        const {
            packagePausesCreateUnlimitedDuration,
            packagePausesCreateWithArbitraryDates,
            cancelSubscriptionFromDatePermission,
            subscriptionInfo: {
                createdSubscription,
                isActiveSubscription,
                isAvailableForSubscription,
            },
            partner,
        } = this.props

        const { legend, activeStep, maxSteps, isOpenCustomPause, isOpenMonthPause, monthlyPrice } = this.state

        const isAPM = createdSubscription && createdSubscription.packageType === 'Ageras Marketing Package'

        const singleStep = createdSubscription && !maxSteps

        return <Card margin="0">
            <Card.Header>{partner && `(#${partner.id}) ${partner.companyName}`}</Card.Header>
            <CardContent>
                <Card modifiers="info">
                    <Card.Content>
                        {createdSubscription
                            ? createdSubscription.churnedAt
                                ? <span>
                                    Latest subscription was churned on
                                    <b>{formatDate(createdSubscription.churnedAt)}</b>
                                </span>
                                : <span>
                                    Partner is subscribed to a
                                    {createdSubscription.packageType}
                                    {' '}
                                    with starting date
                                    <b>{formatDate(createdSubscription.startsAt)}</b>
                                    . The price is
                                    <b><FormattedAmount amount={monthlyPrice}/></b>
                                    . Renewal is every
                                    <b>{formatMonths(createdSubscription.extensionPeriodMonths)}</b>
                                    .
                                </span>
                            : 'Partner does not have a subscription yet.'
                        }
                    </Card.Content>
                </Card>
                <TimeLinContainer>
                    <SliderRange
                        disabled
                        marks={legend}
                        max={singleStep ? 1 : maxSteps}
                        value={singleStep ? 1 : activeStep}
                    />
                </TimeLinContainer>
                <ButtonsContainer>
                    {createdSubscription && <Fragment>
                        {!(createdSubscription.churnedAt || createdSubscription.canceledAt) && <Fragment>
                            <ButtonContainer>
                                {cancelSubscriptionFromDatePermission && <CustomDropdownButton
                                    onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                    bsStyle="danger"
                                    title="Cancel from specific date"
                                    noCaret
                                    dropup
                                    id="dropdown-no-caret"
                                >
                                    <Datetime
                                        open={true}
                                        input={false}
                                        isValidDate={isValidDate}
                                        onChange={this.onSelectSubscriptionCancelDate}
                                    />
                                </CustomDropdownButton>}
                            </ButtonContainer>
                            <ButtonContainer>
                                <Button
                                    modifiers="danger"
                                    onClick={this.cancelWithCancellationPeriod}
                                >
                                    Cancel with cancellation period
                                </Button>
                            </ButtonContainer>
                        </Fragment>}
                        {isActiveSubscription && isAPM && <Fragment>
                            {Boolean(packagePausesCreateWithArbitraryDates) && <ButtonContainer>
                                <CustomDropdownButton
                                    onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                    bsStyle="warning"
                                    title="Pause partner subscription"
                                    onToggle={this.onToggleMonthPauseDropdown}
                                    noCaret
                                    dropup
                                    id="dropdown-no-caret"
                                >
                                    {isOpenMonthPause && <Datetime
                                        onChange={this.onSelectSubscriptionPauseMonthDate}
                                        isValidDate={isValidDate}
                                        dateFormat="YYYY-M"
                                        input={false}
                                        open
                                    />}
                                </CustomDropdownButton>
                            </ButtonContainer>}
                            <ButtonContainer>
                                {Boolean(packagePausesCreateUnlimitedDuration) && <CustomDropdownButton
                                    onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                                    bsStyle="warning"
                                    title="Pause partner subscription (custom)"
                                    onToggle={this.onToggleCustomPauseDropdown}
                                    noCaret
                                    dropup
                                    id="dropdown-no-caret"
                                >
                                    {isOpenCustomPause && <Flex>
                                        <div className="text-center">
                                            <Card.Label>From</Card.Label>
                                            <Datetime
                                                onChange={date => this.onSelectSubscriptionPauseDate('pauseFrom', date)}
                                                isValidDate={isValidDate}
                                                input={false}
                                                open
                                            />
                                        </div>
                                        <div className="text-center">
                                            <Card.Label>to</Card.Label>
                                            <Datetime
                                                onChange={date => this.onSelectSubscriptionPauseDate('pauseTo', date)}
                                                isValidDate={isValidDate}
                                                input={false}
                                                open
                                            />
                                        </div>
                                    </Flex>}
                                </CustomDropdownButton>}
                            </ButtonContainer>
                        </Fragment>}
                    </Fragment>}

                    { createdSubscription && moment(createdSubscription.startsAt).isAfter(moment()) && <ButtonContainer>
                        <Button
                            modifiers="primary"
                            onClick={() => this.setModalProps({ name: 'subscriptionUpdateModal', subscriptionId: this.props.subscriptionInfo.createdSubscription.id })}
                        >
                            Manage subscription date
                        </Button>
                    </ButtonContainer>}
                    {createdSubscription && <ButtonContainer>
                        <Button
                            modifiers="primary"
                            onClick={() => this.changePrice(createdSubscription)}
                        >
                            Manage subscription price
                        </Button>
                    </ButtonContainer>}
                    {createdSubscription && <ButtonContainer>
                        <Button
                            modifiers="primary"
                            onClick={() => this.setModalProps({ name: 'partnerDiscountCreateModal' })}
                        >
                            Manage subscription discount
                        </Button>
                    </ButtonContainer>}
                </ButtonsContainer>
            </CardContent>
        </Card>
    }

}
