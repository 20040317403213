import { Pagination } from '../reducers/types/Pagination'
import { InitialAction } from '../reducers/types/InitialAction'

export interface GeneralSubjectReducerState {
    isLoading: boolean
    pagination: Pagination
    ids: number[]
}

const initialState: GeneralSubjectReducerState = {
    isLoading: false,
    ids: [],
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

export default (actionGetTypes, actionAddTypes?, actionRemoveTypes?) => (
    state = initialState,
    action: InitialAction
) => {
    switch (action.type) {
        case actionGetTypes[0]:
            return {
                ...state,
                isLoading: true,
            }
        case actionGetTypes[1]:
            const result: number | number[] = action.response.result
            return {
                ...state,
                ids: action.append ? state.ids.concat(result) : (result instanceof Array) ? result : [ result ],
                pagination: action.response.pagination,
                isLoading: false,
            }
        case actionGetTypes[2]:
            return {
                ...state,
                isLoading: false,
            }
        case actionAddTypes && actionAddTypes[1]:
            return {
                ...state,
                ids: action.response.result ? state.ids.concat(action.response.result) : state.ids,
                isLoading: false,
            }
        case actionRemoveTypes && actionRemoveTypes[1]:
            return {
                ...state,
                ids: state.ids.filter(item => item !== action.id),
            }
        default:
            return state
    }
}
