import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import connect from 'react-redux/es/connect/connect'
import { getFollowUpLeads } from '../../../actions/leads'
import { leadActivitiesSelector, matchSurveillanceLeadSelector } from '../../../selectors/lead'
import { createLeadNoteActivity, getActivities } from '../../../actions/newsfeed'
import { Link } from 'react-router-dom'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Quotes } from '../../../reducers/types/entities/Quote'
import { LinkCell, Cell, DateCell, Header, Column, Rows, EditCell, LazyTable } from '../../elements/table'

interface FollowUpsRowModel {
    employeeId: number
    quotes: Quotes
    leadActivity
    createLeadNoteActivity: typeof createLeadNoteActivity
    getActivities: typeof getActivities
}

interface RowsHocModel {
    rowData: Lead
}
@connect(
    (state, props) => ({
        employeeId: state.auth.employeeId,
        quotes: state.entities.quotes,
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
    }),
    {
        createLeadNoteActivity,
        getActivities,
    }
)
class FollowUpsRow extends PureComponent<FollowUpsRowModel & RowsHocModel> {
    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        await this.props.createLeadNoteActivity(leadId, noteData)
        return this.props.getActivities({ leadId })
    }
    render() {
        const lead = this.props.rowData

        const { leadActivity, quotes } = this.props

        const publishedQuotes = lead.quotesInfo.quotes
            .map(id => quotes[id])
            .filter(quote => quote.publishedAt)

        const mostRecentQuotePublicationDate = new Date(Math.max.apply(null, publishedQuotes.map(quote => {
            return new Date(quote.publishedAt)
        })))

        return <tr>
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>{lead.customerInfo.firstName + ' ' + lead.customerInfo.lastName}</Cell>
            <DateCell date={mostRecentQuotePublicationDate} />
            {lead.actionsInfo
                ? <DateCell date={lead.actionsInfo.latestCalledAt} />
                : <Cell />
            }
            <EditCell
                value={leadActivity && leadActivity.note ? leadActivity.note.text : ''}
                onBlur={this.handleChangeNoteCell}
            />
        </tr>
    }
}

const FollowUpsTableRow  = FollowUpsRow as ComponentType

interface FollowUpsTableCardModel {
    leads: Lead[]
    employeeId: number
    createLeadNoteActivity: typeof createLeadNoteActivity
    getFollowUpLeads: typeof getFollowUpLeads
    getActivities: typeof getActivities
}

@connect(
    state => ({
        leads: matchSurveillanceLeadSelector(state),
        employeeId: state.auth.employeeId,
    }),
    {
        createLeadNoteActivity,
        getFollowUpLeads,
        getActivities,
    }
)
class FollowUpsTableCard extends PureComponent<FollowUpsTableCardModel> {
    componentDidMount() {
        this.retrieveData()
    }

    retrieveData = async (data?, append?) => {
        const criteria = {
            ...data,
            validatorId: this.props.employeeId,
        }
        const res: any = await this.props.getFollowUpLeads(criteria, append)

        if (res.result.length) this.props.getActivities({ leadId: res.result })
    }

    loadMore = page => this.retrieveData({ page }, true)

    render() {
        const { leads } = this.props

        return <Card>
            <Card.Header>
                <Link to="/quotes/follow-ups">Follow-ups</Link>
            </Card.Header>
            <LazyTable
                pagesStateName="matchSurveillance"
                modifiers={[ 'maxHeightMd', 'p_0' ]}
                retrieveData={this.loadMore}
            >
                <Header>
                    <Column>Lead ID</Column>
                    <Column>Client name</Column>
                    <Column>Last quote</Column>
                    <Column>Last contact</Column>
                    <Column>Notes</Column>
                </Header>
                <Rows
                    items={leads}
                    noDataText="No quotes for this lead."
                >
                    <FollowUpsTableRow/>
                </Rows>
            </LazyTable>
        </Card>
    }
}

export default FollowUpsTableCard as unknown as ComponentType
