import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_ALLOCATIONS = actionTypesCreator('allocations', 'GET_ALLOCATIONS')

export const LEAD_CREATE_ALLOCATION = actionTypesCreator('allocation', 'LEAD_CREATE_ALLOCATION')

interface GetAllocationsModel {
    leadId?: number
    partnerBusinessUnitId?: number | number[]
    partnerId?: number
    startsAt?: string
    endsAt?: string
    hasBusinessUnit?: 0 | 1
    isProcessed?: 0 | 1
    sort?: string
    isUsed?: 0 | 1
    limit?: number
}

export const getAllocations = (criteria: GetAllocationsModel) => ({
    [CALL_API]: {
        types: GET_ALLOCATIONS,
        endpoint: '/leads/allocations',
        schema: schemas.allocations,
        options: {
            body: {
                limit: 100,
                ...criteria,
            },
        },
    },
})

type leadAllocationCreateModel  = {
    partnerId?: number | number[]
} & {
    leadAllocations: object[]
}

export const leadAllocationCreate = (
    leadId: number,
    body: leadAllocationCreateModel
) => ({
    [CALL_API]: {
        types: LEAD_CREATE_ALLOCATION,
        endpoint: `/leads/${leadId}/allocations`,
        schema: schemas.allocation,
        options: {
            method: 'post',
            body,
        },
    },
})
