import styled from 'styled-components'
import {
    alignItems,
    AlignItemsProps,
    background,
    BackgroundProps,
    display,
    DisplayProps,
    flexDirection,
    FlexDirectionProps,
    flexGrow,
    FlexGrowProps,
    flexShrink,
    FlexShrinkProps,
    height,
    HeightProps,
    justifyContent,
    JustifyContentProps,
    justifySelf,
    JustifySelfProps,
    size,
    SizeProps,
    space,
    SpaceProps,
    width,
    WidthProps,
} from 'styled-system'

type ViewProps =
    AlignItemsProps
    & BackgroundProps
    & DisplayProps
    & FlexDirectionProps
    & FlexGrowProps
    & FlexShrinkProps
    & HeightProps
    & JustifyContentProps
    & JustifySelfProps
    & SizeProps
    & SpaceProps
    & WidthProps

const View = styled.div<ViewProps>`
    display: flex;
    ${alignItems}
    ${background}
    ${display}
    ${flexDirection}
    ${flexGrow}
    ${flexShrink}
    ${height}
    ${justifyContent}
    ${justifySelf}
    ${size}
    ${space}
    ${width}
`

View.defaultProps = {
    flexDirection: 'column',
}

export default View
