import React, { Fragment, memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { HasPermission } from '../NavigationBar'
import { Link } from 'react-router-dom'

const PartnersDropdown = memo(({ employeeId, hasPermission }: HasPermission) =>
    <NavDropdown eventKey={1} title="Partners" id="basic-nav-dropdown-Partners">
        <MenuItem eventKey={1.1}>
            <Link to="/partners">All Partners</Link>
        </MenuItem>
        <MenuItem eventKey={1.2}>
            <Link to="/business-units">Business units</Link>
        </MenuItem>
        <MenuItem>
            <Link to="/deliveries">Deliveries</Link>
        </MenuItem>
        {hasPermission('assignable_to_partners') &&
        <MenuItem eventKey={1.3}>
            <Link to={`/partners?employeeId=${employeeId}`}>My partners</Link>
        </MenuItem>}
        {hasPermission('sell_packages_to_partners') && <MenuItem eventKey={1.4} href="/partners/packages">
            Subscriptions
        </MenuItem>}
        <MenuItem eventKey={1.12}>
            <Link to="/admin/gdpr-data">GDPR data</Link>
        </MenuItem>
        {hasPermission('segmentation_geo_regions_list') &&
        <MenuItem eventKey={1.12}>
            <Link to="/segmentation-regions">Segmentation regions</Link>
        </MenuItem>}
        {hasPermission('view_partner_objections') && <Fragment>
            <MenuItem divider />
            <MenuItem eventKey={1.5}>
                <Link to="/refund-requests">Refund requests</Link>
            </MenuItem>
        </Fragment>}
        {!hasPermission('exclusion_canvas') && <Fragment>
            <MenuItem divider />
            <MenuItem header>Canvas</MenuItem>
            <MenuItem eventKey={1.7}>
                <Link to="/partners?state=canvas&sidebar=map&visible_columns=id%2Ccompany_name%2Clocation%2Cemployee_name%2Clast_phone_call%2Cnext_task%2Ccanvas_status">Canvas Discovery</Link>
            </MenuItem>
            <MenuItem eventKey={1.8}>
                <Link to={`/partners?column=last_login&direction=desc&state=demo&visible_columns=id%2Ccompany_name%2Cstate%2Csatisfaction%2Cemployee_name%2Clast_login%2Clocation%2Clast_phone_call%2Cnext_task&employee_id=${
                    hasPermission('assignable_to_partners') ? employeeId : ''
                }`}
                >
                    Demo partners
                </Link>
            </MenuItem>
            <MenuItem eventKey={1.13} href="/partners/create-new-demo-partner">
                Create demo partner
            </MenuItem>
            {hasPermission('view_interested_partners') && <MenuItem eventKey={1.9} href="/partners/interested">
                Interested
            </MenuItem>}
            {hasPermission('view_interested_partners') && <MenuItem href="/partnersignups">
                Sign-ups
            </MenuItem>}
        </Fragment>}
        <MenuItem divider />
        <MenuItem header>Invoices</MenuItem>
        <MenuItem>
            <Link to="/invoicings">Invoicing</Link>
        </MenuItem>
        {!hasPermission('view_interested_partners') &&
        <Fragment>
            <MenuItem eventKey={1.10} href="/vouchers/invoices">Invoices</MenuItem>
            <MenuItem eventKey={1.11} href="/vouchers/credit-notes">Credit notes</MenuItem>
        </Fragment>}
        {hasPermission('exclusion_voucher') || hasPermission('create_custom_invoice') &&
            <MenuItem href="/vouchers/create-custom-invoice">Create custom invoice</MenuItem>
        }
    </NavDropdown>
)

export default PartnersDropdown
