import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import {
    GET_BUSINESS_UNIT_LEAD_TYPES,
    GET_LEAD_TYPES,
    GET_PARTNER_LEAD_TYPES,
    REMOVE_LEAD_TYPE_FROM_PARTNER,
    UPDATE_PARTNER_LEAD_TYPES,
} from './actions'

const leadTypes = combineReducers({
    all: generalSubjectReducer(GET_LEAD_TYPES),
    partner: generalSubjectReducer(GET_PARTNER_LEAD_TYPES, UPDATE_PARTNER_LEAD_TYPES, REMOVE_LEAD_TYPE_FROM_PARTNER),
    businessUnit: generalSubjectReducer(GET_BUSINESS_UNIT_LEAD_TYPES),
})

export default leadTypes
