import React, { PureComponent, ComponentType, Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { Field, submit } from 'redux-form'
import { Card } from '../blocks/Card'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Button } from '../elements/Button'
import { connect } from '../../utils/connect'
import { hideModal } from '../../actions/modal'
import ReduxForm from '../elements/forms/ReduxForm'
import { deleteSubscriptionPrice, createSubscriptionPrice } from '../../actions/subscriptions'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import styled from 'styled-components'
import { FormattedAmount } from '../elements/FormattedAmount'
import { Subscription } from '../../reducers/types/entities/Subscription'
import { SubscriptionPrice } from '../../reducers/types/entities/SubscriptionPrice'
import { Flex } from '../elements/Flex'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'

const ContentLine = styled.div`
  display: flex;
  >div:first-child {
    margin-right: 15px;
  }
  > * {
    flex-grow: 1;
    > span {
      width: 100%;
    }
  }
`

interface StatePropsChangePrice {
    subscriptionPriceDelete: any
    modalProps: {
        disabled: boolean
        subscription: Subscription
        subscriptionPrices: SubscriptionPrice[]
    }
}
interface DispatchPropsChangePrice {
    deleteSubscriptionPrice: typeof deleteSubscriptionPrice
    createSubscriptionPrice: typeof createSubscriptionPrice
    hideModal: typeof hideModal
    submit: typeof submit
}

type SubscriptionChangePriceModel = StatePropsChangePrice & DispatchPropsChangePrice

@connect<StatePropsChangePrice, DispatchPropsChangePrice>(
    state => ({
        subscriptionPriceDelete: state.entities.permissions.subscription_price_delete,
        modalProps: state.modal,
    }),
    {
        deleteSubscriptionPrice,
        createSubscriptionPrice,
        hideModal,
        submit,
    }
)
class SubscriptionChangePriceModal extends PureComponent<SubscriptionChangePriceModel> {
    hideModal = () => this.props.hideModal()

    subscriptionCreatePrice = data => {
        const { partnerId, id, monthlyPrice } = this.props.modalProps.subscription

        const body = {
            partnerPackageId: id,
            effectiveAt: moment(data.effectiveAt).format('YYYY-MM-DD'),
            monthlyPrice: {
                amount: data.monthlyPriceAmount,
                currency: monthlyPrice.currency,
            },
        }
        this.props.createSubscriptionPrice(partnerId, body)
    }

    save = () => this.props.submit('subscriptionChangePrice')

    deletePrice = (priceId) => {
        const { partnerId, id } = this.props.modalProps.subscription

        if (this.props.subscriptionPriceDelete)
            this.props.deleteSubscriptionPrice(partnerId, id, priceId)
        else
            toastr.error('Error!', 'Permission Required: "subscription_price_delete')
    }

    render() {
        const { modalProps } = this.props

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Body>
                <Card.Text className="text-right">
                    Current price: 
                    {' '}
                    <b><FormattedAmount amount={modalProps.subscription.monthlyPrice}/></b>
                </Card.Text>
                <Card.Line modifiers="mY_2"/>
                <ReduxForm form="subscriptionChangePrice" onSubmit={this.subscriptionCreatePrice}>
                    <ContentLine>
                        <Field
                            label="New price"
                            name="monthlyPriceAmount"
                            component={FieldGroup}
                            removeClassFormGroup={true}
                            type="number"
                        />
                        <Field
                            label="Update from"
                            name="effectiveAt"
                            removeClassFormGroup={true}
                            dateFormat="YYYY-MM"
                            component={DateTimePickerGroup}
                        />
                    </ContentLine>
                </ReduxForm>
                <br />
                <Card.Label>Scheduled changes</Card.Label>
                { modalProps.subscriptionPrices.map(price => <Fragment>
                    <Card.Line modifiers="mY_1"/>
                    <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                        <span>
                            <FormattedAmount amount={price.monthlyPrice}/>
                            {' '}
                            from
                            {moment(price.effectiveAt).format('YYYY-MM-DD')}
                        </span>
                        <Button
                            onClick={() => this.deletePrice(price.id)}
                            modifiers={[ 'danger', 'btnIcon' ]}
                        >
                            <i className="mdi mdi-close" />
                        </Button>
                    </Flex>
                </Fragment>)}

            </Modal.Body>
            <Modal.Footer className="text-right">
                <Flex modifiers={[ 'justifyEnd' ]}>
                    <Button
                        modifiers={[ 'secondary' ]}
                        disabled={modalProps.disabled}
                        onClick={this.hideModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        modifiers={[ 'action', 'mL_1.5' ]}
                        disabled={modalProps.disabled}
                        onClick={this.save}
                    >
                        Create price
                    </Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    }
}

export default SubscriptionChangePriceModal as unknown as ComponentType
