import {
    GET_PARTNER_BUSINESS_UNITS,
    GET_PARTNER_BUSINESS_UNITS_SUCCESS,
    GET_PARTNER_BUSINESS_UNITS_FAIL,
} from '../../actions/businessUnit'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'


export interface BusinessUnitsModel extends InitialState {
    businessUnitIds: number[]
}
const initialState: BusinessUnitsModel = {
    isLoading: true,
    businessUnitIds: [],
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_PARTNER_BUSINESS_UNITS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTNER_BUSINESS_UNITS_SUCCESS:
            return {
                ...state,
                businessUnitIds: action.append ? state.businessUnitIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_PARTNER_BUSINESS_UNITS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
