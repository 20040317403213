import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import config from '../config'

export const GET_INVOICE_REFUND_REQUESTS = 'invoices/GET_INVOICE_REFUND_REQUESTS'
export const GET_INVOICE_REFUND_REQUESTS_SUCCESS = 'invoices/GET_INVOICE_REFUND_REQUESTS_SUCCESS'
export const GET_INVOICE_REFUND_REQUESTS_FAIL = 'invoices/GET_INVOICE_REFUND_REQUESTS_FAIL'

export const GET_INVOICE_REFUND_REQUEST = 'invoices/GET_INVOICE_REFUND_REQUEST'
export const GET_INVOICE_REFUND_REQUEST_SUCCESS = 'invoices/GET_INVOICE_REFUND_REQUEST_SUCCESS'
export const GET_INVOICE_REFUND_REQUEST_FAIL = 'invoices/GET_INVOICE_REFUND_REQUEST_FAIL'

export const INVOICES_REFUND_REQUEST_ACTION = 'invoices/INVOICES_REFUND_REQUEST_ACTION'
export const INVOICES_REFUND_REQUEST_ACTION_SUCCESS = 'invoices/INVOICES_REFUND_REQUEST_ACTION_SUCCESS'
export const INVOICES_REFUND_REQUEST_ACTION_FAIL = 'invoices/INVOICES_REFUND_REQUEST_ACTION_FAIL'

interface GetInvoiceRefundRequestsPropsModel {
    assignedEmployeeId: number
    column: string
    direction: string
    sort: string
}
export const getInvoiceRefundRequests = (
    criteria: GetInvoiceRefundRequestsPropsModel,
    append?: boolean,
) => ({
    [CALL_API]: {
        types: [ GET_INVOICE_REFUND_REQUESTS, GET_INVOICE_REFUND_REQUESTS_SUCCESS, GET_INVOICE_REFUND_REQUESTS_FAIL ],
        endpoint: '/invoices/refundrequests',
        schema: schemas.invoiceRefundRequests,
        options: {
            body: {
                geoCode: config.geoCode,
                limit: 5000,
                ...criteria,
            },
        },
    },
    append,
})

export const getInvoiceRefundRequest = (
    refundRequestId: number
) => ({
    [CALL_API]: {
        types: [ GET_INVOICE_REFUND_REQUEST, GET_INVOICE_REFUND_REQUEST_SUCCESS, GET_INVOICE_REFUND_REQUEST_FAIL ],
        endpoint: '/invoices/refundrequests/' + refundRequestId,
        schema: schemas.invoiceRefundRequest,
    },
})

interface RefundRequestActionPropsModel {
    action: string
    invoiceId: number
    requestId: number
    decisionReason: string
    acceptedAmountExclVat?: number
}
const refundRequestAction = (
    body: RefundRequestActionPropsModel,
) => ({
    [CALL_API]: {
        types: [
            INVOICES_REFUND_REQUEST_ACTION,
            INVOICES_REFUND_REQUEST_ACTION_SUCCESS,
            INVOICES_REFUND_REQUEST_ACTION_FAIL,
        ],
        endpoint: `/invoices/${body.invoiceId}/refundrequests/${body.requestId}/actions`,
        schema: schemas.invoiceRefundRequest,
        options: {
            method: 'post',
            body,
        },
    },
})

export const acceptRefundRequest = criteria => refundRequestAction({ ...criteria, action: 'accept' })

export const rejectRefundRequest = criteria => refundRequestAction({ ...criteria, action: 'reject' })
