import { ApiActionCreator, CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_CERTIFICATIONS = actionTypesCreator('certifications', 'GET_CERTIFICATIONS')

export const GET_LEAD_CERTIFICATIONS = actionTypesCreator('certifications', 'GET_LEAD_CERTIFICATIONS')

export const ADD_LEAD_CERTIFICATION = actionTypesCreator('certifications', 'ADD_LEAD_CERTIFICATION')

export const REMOVE_LEAD_CERTIFICATION = actionTypesCreator('certifications', 'REMOVE_LEAD_CERTIFICATION')

export const UPDATE_LEAD_CERTIFICATION = actionTypesCreator('certifications', 'UPDATE_LEAD_CERTIFICATION')

export const GET_PARTNER_CERTIFICATIONS = actionTypesCreator('certifications', 'GET_PARTNER_CERTIFICATIONS')

export const ADD_PARTNER_CERTIFICATION = actionTypesCreator('certifications', 'ADD_PARTNER_CERTIFICATION')

export const REMOVE_PARTNER_CERTIFICATION = actionTypesCreator('certifications', 'REMOVE_PARTNER_CERTIFICATION')

export const UPDATE_PARTNER_CERTIFICATION = actionTypesCreator('certifications', 'UPDATE_PARTNER_CERTIFICATION')

interface GetCertificationModel {
    geoCode?: string,
    industryId?: number,
    isSegmentable?: true,
    limit?: number,
}

export const getCertifications = (
    body: GetCertificationModel
) => ({
    [CALL_API]: {
        types: GET_CERTIFICATIONS,
        endpoint: '/certifications',
        schema: schemas.certifications,
        options: {
            body: {
                limit: 1000,
                ...body,
            },
        },
    },
})

export const getLeadCertifications: ApiActionCreator = (
    leadId: number
) => ({
    [CALL_API]: {
        types: GET_LEAD_CERTIFICATIONS,
        endpoint: `/leads/${leadId}/certifications`,
        schema: schemas.certifications,
        options: {
            body: {
                limit: 1000,
                isSegmentable: true,
            },
        },
    },
})

export const addLeadCertification = (
    leadId: number,
    certificationId: number
) => ({
    [CALL_API]: {
        types: UPDATE_LEAD_CERTIFICATION,
        endpoint: `/leads/${leadId}/certifications`,
        schema: schemas.certification,
        options: {
            method: 'post',
            body: {
                id: certificationId,
            },
        },
    },
})

export const updateLeadCertification = (
    leadId: number,
    certificationIds: number[] | number
) => ({
    [CALL_API]: {
        types: UPDATE_LEAD_CERTIFICATION,
        endpoint: `/leads/${leadId}/certifications`,
        schema: schemas.certification,
        options: {
            method: 'put',
            body: {
                certificationIds,
            },
        },
    },
})

export const removeLeadCertification = (
    leadId: number,
    certificationId: number
) => ({
    [CALL_API]: {
        types: REMOVE_LEAD_CERTIFICATION,
        endpoint: `/leads/${leadId}/certifications/${certificationId}`,
        schema: schemas.certification,
        options: {
            method: 'delete',
        },
    },
})

export const getPartnerCertifications = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: GET_PARTNER_CERTIFICATIONS,
        endpoint: `/partners/${partnerId}/certifications`,
        schema: schemas.certifications,
        options: {
            body: {
                limit: 1000,
            },
        },
    },
})

export const addPartnerCertification = (
    partnerId: number,
    certificationId: number
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_CERTIFICATION,
        endpoint: `/partners/${partnerId}/certifications`,
        schema: schemas.certification,
        options: {
            method: 'post',
            body: {
                id: certificationId,
            },
        },
    },
    id: certificationId,
})

export const removePartnerCertification = (
    partnerId: number,
    certificationId: number
) => ({
    [CALL_API]: {
        types: REMOVE_PARTNER_CERTIFICATION,
        endpoint: `/partners/${partnerId}/certifications/${certificationId}`,
        schema: schemas.certification,
        options: {
            method: 'delete',
        },
    },
    id: certificationId,
})

export const updatePartnerCertification = (
    partnerId: number,
    certificationIds: number[] | number
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_CERTIFICATION,
        endpoint: `/partners/${partnerId}/certifications`,
        options: {
            method: 'put',
            body: {
                certificationIds,
            },
        },
    },
})
