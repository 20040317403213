import React, { memo } from 'react'
import { FormGroup, InputGroup } from 'react-bootstrap'
import Datetime from 'react-datetime'
import moment from 'moment'
import ControlLabel from '../../text/MetaLabel'
import styled from 'styled-components'
import Addon from '../../Addon'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { FieldInputProps, FormikHelpers } from 'formik'
import get from 'lodash/get'

// Note that for the value to be stored correctly, you need to update the submit-handler in the original form
// as Datetime returns a Date object instead of a string.
//
// Example: `body.expiresAt = body.expiresAt && moment(body.expiresAt).toISOString(true)`

const DateTimePicker = styled(Datetime)`
  .form-control {
    height: 40px;
  }
`
interface DateTimePickerGroupModel<Value> {
    removeClassFormGroup?: boolean
    removeAddon?: boolean
    dateFormat?: string
    timeFormat?: boolean | string
    className?: string
    label?: string
    input?: WrappedFieldInputProps | {
        name: string
        value: any
        onBlur?: (date:string) => void
        onChange: (date:string) => void
    }
    field?: FieldInputProps<Value> | any
    form?: FormikHelpers<Value>
    meta?: WrappedFieldMetaProps
    isValidDate?: any
    timeConstraints?: any
    defaultValue?: any
    inputProps?: object
}

const convertDate = (value, dateFormat) => moment(value).format(dateFormat || 'YYYY-MM-DD HH:mm')

export const DateTimePickerGroup = memo(({
    removeClassFormGroup,
    removeAddon,
    className,
    dateFormat,
    timeFormat,
    field,
    form,
    input,
    label,
    meta,
    isValidDate,
    timeConstraints,
    defaultValue,
    inputProps,
}: DateTimePickerGroupModel<any>) =>
    <FormGroup
        controlId={(input || field).name}
        bsClass={
            (removeClassFormGroup ? '' : 'form-group ') + (className || '')
        }
        validationState={
            meta && meta.touched && (meta.error || meta.warning) ||
            get(form, `errors.${get(field, 'name')}`)
                ? 'error'
                : null
        }
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        <InputGroup>
            <DateTimePicker
                {...(input || field)}
                value={(input || field).value && convertDate((input || field).value, dateFormat)}
                onChange={e => (input ? input.onChange : (value => form.setFieldValue(field.name, value)))(convertDate(e, dateFormat))}
                onBlur={e => (input ? input.onChange : (value => form.setFieldValue(field.name, value)))(convertDate(e, dateFormat))}
                dateFormat={dateFormat || 'YYYY-MM-DD'}
                timeFormat={timeFormat !== undefined ? timeFormat : 'HH:mm'}
                defaultValue={defaultValue}
                inputProps={inputProps}
                input={true}
                isValidDate={isValidDate}
                timeConstraints={timeConstraints}
            />
            {removeAddon || <Addon className="mdi mdi-calendar mdi-24px"/>}
        </InputGroup>
    </FormGroup>
)
