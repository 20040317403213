import styled from 'styled-components'
import { ControlLabel } from 'react-bootstrap'
import { metaTextColor, primaryColor } from '../../../utils/variables'
import { applyStyleModifiers, applyResponsiveStyleModifiers } from 'styled-components-modifiers'

const MODIFIER_METALABEL_CONFIG = {
    highlight: () => `
        color: ${primaryColor}
    `,
    colorRed: () => `
        color: #FF0000;
    `,
    colorGreen: () => `
        color: #27D77A;
    `,
}

interface MetaLabelModel {
    modifiers: keyof typeof MODIFIER_METALABEL_CONFIG
    responsiveModifiers
    size
}

export default styled(ControlLabel)<Partial<MetaLabelModel>>`
  font-size: 12px;
  font-weight: bold;
  color: ${metaTextColor};
  text-transform: uppercase;
  ${applyStyleModifiers(MODIFIER_METALABEL_CONFIG)}
  ${applyResponsiveStyleModifiers(MODIFIER_METALABEL_CONFIG)}
`
