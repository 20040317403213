import React, { PureComponent, ComponentType } from 'react'
import { login } from '../../actions/auth'
import { connect } from '../../utils/connect'
import { withFormik } from '../../utils/withFormik'
import { Field, Form, FormikProps } from 'formik'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Button } from '../elements/Button'

interface LoginDispatchProps {
    login: typeof login
}

interface FormValues {
    email: string
    password: string
}

@connect<any, LoginDispatchProps>(
    null,
    {
        login,
    }
)
@withFormik<LoginDispatchProps, FormValues>({
    handleSubmit: (values, formikBag) => {
        formikBag.props.login(values.email, values.password)
    },
})
class LoginPage extends PureComponent<LoginDispatchProps & FormikProps<FormValues>> {
    render() {
        return (
            <Form>
                <Field
                    label="Email"
                    name="email"
                    type="email"
                    component={FieldGroup}
                />
                <Field
                    label="Password"
                    name="password"
                    type="password"
                    component={FieldGroup}
                />
                <Button modifiers={[ 'action' ]} type="submit">Log me in!</Button>
            </Form>
        )
    }
}

export default LoginPage as unknown as ComponentType
