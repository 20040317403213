import isoFetch from 'isomorphic-fetch'

const simpleFetch = (url: string | URL, option?) => {
    url = new URL(url)

    const { method = 'get', body = {} } = option || {}

    const options: RequestInit = {
        method,
        headers: option.headers,
        mode: 'cors',
    }

    if (body) {
        if ([ 'get', 'head' ].includes(method)) {
            for (const [ name, value ] of Object.entries(body)) {
                if (value) url.searchParams.append(name, String(value))
            }
        } else {
            options.body = JSON.stringify(body)
        }
    }

    return isoFetch(url, options)
}

export { simpleFetch }
