import React, { ComponentType, memo, PureComponent } from 'react'
import { getQuotes } from '../../actions/quotes'
import config from '../../config'
import { Card } from '../blocks/Card/index'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { Quote } from '../../reducers/types/entities/Quote'
import { connect } from '../../utils/connect'
import { quotesSelector } from '../../selectors/quote'
import ProviderTable from '../blocks/ProviderTable'
import { Cell, LinkCell, DateCell, DropdownFilter, Column, SortColumn, AmountCell } from '../elements/table'

const quoteAccepted = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
]

const quotePublished = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
]

const matchCalled = {
    partner_user: 'Partner user',
    unknown: '-',
    employee: 'Employee',
    partner: 'Partner',
    client: 'Client',
}

interface QuoteRowModel {
    visibleCells: string[],
    rowData: Quote,
}

const QuoteRow = memo((props: QuoteRowModel) => {

    const quote = props.rowData

    return <ProviderTable.Row {...props}>
        <LinkCell to={`/leads/${quote.leadId}/validation`}>{quote.leadId}</LinkCell>
        <Cell>
            {quote.client.companyName
                ? quote.client.companyName
                : quote.client.firstName + ' ' + quote.client.lastName
            }
        </Cell>
        <Cell>{quote.partner.id}</Cell>
        <LinkCell to={`/partners/${quote.partner.id}`}>{quote.partner.companyName}</LinkCell>
        <Cell>{quote.status}</Cell>
        {quote.totalAmountExclVat ? <AmountCell amount={quote.totalAmountExclVat} /> : <Cell />}
        <DateCell date={quote.publishedAt} format="datetime" />
        <DateCell date={quote.acceptedAt} format="datetime" />
        <DateCell date={quote.rejectedAt} format="datetime" />
        <DateCell date={quote.createdAt}  format="datetime" />
        <Cell>{quote.acceptedByActioner ? matchCalled[quote.acceptedByActioner.actionerType] : '-'}</Cell>
    </ProviderTable.Row>
})

const QuoteTableRow = QuoteRow as ComponentType

interface Criteria {
    sort: string
    visibleColumns: string[]
    isAccepted: string[]
    isPublished: string[]
}

interface QuotesListPageModel {
    quotes: Quote[]
    totalItems: number
    criteria: Criteria
    getQuotes: typeof getQuotes
}

interface QuotesListOwnProps {
    location: {
        query: Criteria
    }
}

@connect<any,  any,  QuotesListOwnProps>(
    (state, { location: { query } }) => ({
        quotes: quotesSelector(state),
        totalItems: state.pages.quotes.pagination.total,
        criteria: {
            sort: query.sort || '-created_at',
            visibleColumns: splitFilterString(query.visibleColumns),
            isAccepted: splitFilterString(query.isAccepted),
            isPublished: splitFilterString(query.isPublished),
        },
    }),
    {
        getQuotes,
    }
)

export default class QuotesListPage extends PureComponent<QuotesListPageModel> {
    retrieveData = (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
            limit: 100,
        })

        if (!criteria.isAccepted)
            delete criteria.isAccepted
        if (!criteria.isPublished)
            delete criteria.isPublished

        return this.props.getQuotes({ criteria, append })
    }

    render() {
        const {
            quotes,
            criteria,
            totalItems,
        } = this.props

        return <Card margin="0">
            <Card.Header>
                Quotes 
                {' '}
                <span className="text-muted">
                    (
                    {quotes.length}
                    {' '}
                    /
                    {totalItems || 0}
                    )
                </span>
            </Card.Header>
            <ProviderTable
                pageName="quotes"
                retrieveData={this.retrieveData}
                criteria={criteria}
            >
                <ProviderTable.FilterContainer>
                    <DropdownFilter
                        selectedIds={criteria.isAccepted}
                        items={quoteAccepted}
                        type="radio"
                        name="isAccepted"
                        search={false}
                        hideZero={false}
                        label="Accepted"
                    />
                    <DropdownFilter
                        selectedIds={criteria.isPublished}
                        items={quotePublished}
                        type="radio"
                        name="isPublished"
                        search={false}
                        hideZero={false}
                        label="Published"
                    />
                </ProviderTable.FilterContainer>
                <ProviderTable.LazyTable>
                    <ProviderTable.Header>
                        <Column name="lead_id">Lead ID</Column>
                        <Column name="headline">Client name</Column>
                        <Column name="identifier">Partner ID</Column>
                        <Column name="partner_name">Partner Name</Column>
                        <Column name="quote_status">Status</Column>
                        <Column name="quote_amount">Quote total amount</Column>
                        <SortColumn name="published_at">Published at</SortColumn>
                        <SortColumn name="accepted_at">Accepted at</SortColumn>
                        <SortColumn name="rejected_at">Rejected at</SortColumn>
                        <SortColumn name="created_at">Created at</SortColumn>
                        <Column name="match_called">Match done by</Column>
                    </ProviderTable.Header>
                    <ProviderTable.Rows
                        items={quotes}
                        noDataText="We couldn't find any partners matching your selection."
                    >
                        <QuoteTableRow/>
                    </ProviderTable.Rows>
                </ProviderTable.LazyTable>
            </ProviderTable>
        </Card>
    }
}
