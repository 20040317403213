import { createSelector } from 'reselect'
import { Location, Locations } from '../reducers/types/entities/Location'
import { AppStoreState } from '../reducers/types/index'

const pagesLocationIds = state => state.pages.locationFilter.locationIds

const entitiesLocations = (state: AppStoreState) => state.entities.locations

export const locationSelector = createSelector<AppStoreState, number[], Locations, Location[]>(
    pagesLocationIds, entitiesLocations,
    (locationIds, locations) => locationIds.map(id => locations[id])
)
