import React, { createElement } from 'react'
import 'moment-duration-format'
import ReactDOM from 'react-dom'
import moment from 'moment'
import numeral from 'numeral'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './components/App'
import ReduxToastr from 'react-redux-toastr'
import LoginPage from './components/pages/LoginPage'
import DashboardPage from './components/employees/DashboardPage'
import PartnerListPage from './components/partners/PartnerListPage'
import OldPartnerListPage from './components/partners/OldPartnerListPage'
import LeadListPage from './components/leads/LeadListPage'
import BusinessUnitListPage from './components/business-unit/BusinessUnitListPage'
import EmployeesPage from './components/employees/EmployeesPage'
import CSMTaskListPage from './components/tasks/CSMTaskListPage'
import TaskListPage from './components/tasks/TaskListPage'
import QuoteSurveillancePage from './components/surveillance/QuoteSurveillancePage'
import FollowUpListPage from './components/surveillance/follow-up/FollowUpListPage'
import TermsPage from './components/admin/TermsPage'
import QuotesListPage from './components/quote/QuotesListPage'
import RefundsListPage from './components/employees/RefundsListPage'
import RefundRequestDetailPage from './components/employees/RefundRequestDetailPage'
import LeadValidationPage from './components/leads/LeadValidationPage'
import EditQuotePage from './components/quote/EditQuotePage'
import GDPRPage from './components/admin/GDPRPage'
import CompletedCallSurveillancePage from './components/surveillance/CompletedCallSurveillancePage'
import LeadExclusiveListPage from './components/leads/LeadExclusiveListPage'
import KPITargetsPage from './components/admin/KPITargetsPage'
import MatchReportsPage from './components/employees/MatchReportsPage'
import CurrentIntervalLeadsList from './components/leads/CurrentIntervalLeadsList'
import PartnerPage from './components/partners/PartnerPage'
import SendEmailToExclusiveLeadPage from './components/leads/SendEmailToExclusiveLeadPage'
import PartnerContactArchivesPage from './components/partners/PartnerContactArchivesPage'
import { PartnerUserPage } from './components/partners/PartnerUserPage'
import CompanyDetailsPage from './components/partners/CompanyDetailsPage'
import SegmentationRegionsPage from './components/employees/SegmentationRegionsPage'
import SegmentationPage from './components/partners/SegmentationPage'
import ContractDetailPage from './components/partners/ContractDetailPage'
import PartnerSubscriptionTimeLinePage from './components/partners/PartnerSubscriptionTimeLinePage'
import CreateDemoPartnerPage from './components/partners/CreateDemoPartnerPage'
import SearchListPage from './components/employees/SearchListPage'
import LeadCreatePage from './components/leads/LeadCreatePage'
import 'react-redux-toastr/src/styles/index.scss'
import './sass/index.scss'
import NavigationBar from './components/topbar/NavigationBar'
import SendEmailToPartnersRegionsPage from './components/leads/SendEmailToPartnersRegionsPage'
import config from './config'
import PartnerInvoicingsPage from './components/partners/PartnerInvoicingsPage'
import AllocationDeliveriesPage from './components/partners/AllocationDeliveriesPage'
import IframePage from './components/pages/IframePage'
import PartnerSignupsPage from './components/partners/PartnerSignupsPage'
import LeadIntervalRedirect from './components/leads/LeadIntervalRedirect'

declare const document

if (document.getElementById('toastr-container')) {
    ReactDOM.render(
        <Provider store={store}>
            <div>
                <ReduxToastr/>
            </div>
        </Provider>,
        document.getElementById('toastr-container')
    )
}

if (!config.isReactPage && window.self === window.top) {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Route component={NavigationBar}/>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('react-topbar')
    )
}

if (config.isReactPage) {
    numeral.register('locale', 'en-gb', {
        delimiters: {
            thousands: '.',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            thousands: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        currency: {
            symbol: config.currency
        },
    })
    numeral.locale('en-gb')
    moment.locale('en-gb')

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Route component={NavigationBar}/>
                <App>
                    <Switch>
                        <Route exact path="/" component={DashboardPage} />
                        <Route exact path="/partners" component={PartnerListPage} />
                        <Route exact path="/partners-old" component={OldPartnerListPage} />
                        <Route exact path="/partners/create-new-demo-partner" component={CreateDemoPartnerPage} />
                        <Route exact path="/partners/:partnerId(\d+)" component={PartnerPage} />
                        <Route exact path="/partners/:partnerId/segmentation" component={SegmentationPage} />
                        <Route exact path="/partners/:partnerId/contracts" component={ContractDetailPage}/>
                        <Route exact path="/partners/:partnerId/contracts/:contractId" component={ContractDetailPage}/>
                        <Route exact path="/partners/:partnerId/company-details" component={CompanyDetailsPage} />
                        <Route exact path="/partners/:partnerId/users/:partnerUserId" component={PartnerUserPage}/>
                        <Route exact path="/partners/:partnerId/contact-archive" component={PartnerContactArchivesPage} />
                        <Route exact path="/partners/:partnerId/subscription-time-line" component={PartnerSubscriptionTimeLinePage} />
                        <Route exact path="/leads/validation" component={LeadIntervalRedirect} />
                        <Route exact path="/leads" component={LeadListPage} />
                        <Route exact path="/leads/:leadId/validation" component={LeadValidationPage} />
                        <Route exact path="/leads/:leadId/send-email/exclusive" component={SendEmailToExclusiveLeadPage} />
                        <Route exact path="/leads/:leadId/send-email-regions" component={SendEmailToPartnersRegionsPage} />
                        <Route exact path="/leads/completed-call-flow-list" component={CompletedCallSurveillancePage} />
                        <Route exact path="/quotes" component={QuotesListPage} />
                        <Route exact path="/quote/:leadId/:quoteId" component={EditQuotePage} />
                        <Route exact path="/quotes/surveillance" component={QuoteSurveillancePage} />
                        <Route exact path="/quotes/follow-ups" component={FollowUpListPage} />
                        <Route exact path="/tasks" component={TaskListPage} />
                        <Route exact path="/tasks/csm" component={CSMTaskListPage} />
                        <Route exact path="/business-units" component={BusinessUnitListPage} />
                        <Route exact path="/hr/employees" component={EmployeesPage} />
                        <Route exact path="/refund-requests" component={RefundsListPage} />
                        <Route exact path="/refund-requests/:refundRequestId" component={RefundRequestDetailPage} />
                        <Route exact path="/admin/terms" component={TermsPage} />
                        <Route exact path="/admin/gdpr-data" component={GDPRPage} />
                        <Route exact path="/admin/kpi-target" component={KPITargetsPage} />
                        <Route exact path="/exclusive-leads" component={LeadExclusiveListPage} />
                        <Route exact path="/match-reports" component={MatchReportsPage} />
                        <Route exact path="/interval" component={CurrentIntervalLeadsList} />
                        <Route exact path="/segmentation-regions" component={SegmentationRegionsPage} />
                        <Route exact path="/search" component={SearchListPage} />
                        <Route exact path="/create-lead" component={LeadCreatePage} />
                        <Route exact path="/invoicings" component={PartnerInvoicingsPage} />
                        <Route exact path="/partnersignups" component={PartnerSignupsPage} />
                        <Route exact path="/deliveries" component={AllocationDeliveriesPage} />
                        <Route component={IframePage} />
                    </Switch>
                </App>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    )
}
