import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_PARTNER_CATEGORIES = 'partner/GET_PARTNER_CATEGORIES_POINTS'
export const GET_PARTNER_CATEGORIES_SUCCESS = 'partner/GET_PARTNER_CATEGORIES_SUCCESS'
export const GET_PARTNER_CATEGORIES_FAIL = 'partner/GET_PARTNER_CATEGORIES_FAIL'

export const getPartnerCategories = (
    geoCode: string
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_CATEGORIES,
            GET_PARTNER_CATEGORIES_SUCCESS,
            GET_PARTNER_CATEGORIES_FAIL,
        ],
        endpoint: '/partners/categories',
        schema: schemas.partnerCategories,
        options: {
            body: {
                geoCode,
                limit: 100,
            },
        },
    },
})
