import React, { memo, PureComponent } from 'react'
import { Card } from '../blocks/Card'
import PageTitle from '../elements/PageTitle'
import { Col, Row } from 'react-bootstrap'
import { Partner } from '../../reducers/types/entities/Partner'
import {
    Cell,
    Column, DateCell,
    EmployeeCell,
    LinkCell,
    SatisfactionCell,
    Table,
} from '../elements/table'
import { connect } from '../../utils/connect'
import { partnerListSelector } from '../../selectors/partner'
import { getPartners } from '../../actions/partners'
import { ActionButtonBlock, Button, ButtonContainer, ButtonGrid, ButtonGroup } from '../elements/Button'
import { showModal } from '../../actions/modal'
import LoadingBar from '../elements/LoadingBar'
import config from '../../config'

const NoPartnersFoundStyle = {
    marginTop: '20px',
    fontSize: '15px',
}

const PartnerRow = memo((props: {partner?: Partner}) => {
    const { partner } = props

    return <tr>
        <LinkCell to={`/partners/${partner.id}`} onClick={e => e.stopPropagation()}>{partner.id}</LinkCell>
        <Cell>{partner.companyName}</Cell>
        <Cell>
            {partner.geo.zipCode}
            {' '}
            {partner.geo.cityName}
        </Cell>
        <EmployeeCell portrait={false} employee={partner.employee} />
        <SatisfactionCell satisfaction={partner.satisfaction} />
        <DateCell date={partner.kpi.lastLogin} format="datetime" />
        <DateCell date={partner.kpi.lastPhoneCall} format="datetime" />
        <Cell>{partner.subscription ? partner.subscription.map(el => el.packageType).join(', ') : ''}</Cell>
        <DateCell date={partner.kpi.lastSubscriptionStartDate} format="datetime" />
        <DateCell date={partner.kpi.lastPublishedQuote} format="datetime" />
        <Cell>{partner.kpi.publishedQuotesCount}</Cell>
        <Cell>{partner.kpi.winRatio}</Cell>
        <Cell>{partner.kpi.unmatchedCount}</Cell>
        <Cell>{partner.kpi.unmatchedPercent}</Cell>
        <Cell>{partner.preferences.digitalLeads ? 'yes' : 'no'}</Cell>
        <Cell>{partner.canvas ? partner.canvas.status : ''}</Cell>
    </tr>
})

interface DemoPartnerListProps {
    partners: Partner[]
}

interface DemoPartnerDispatchProps {
    getPartners: typeof getPartners
    showModal: typeof showModal
}

interface DemoPartnerPageState {
    hasSearched: boolean
    searching: boolean
}

type DemoPartnerPageModel = DemoPartnerListProps & DemoPartnerDispatchProps

@connect<DemoPartnerListProps, DemoPartnerDispatchProps>(
    (state) => ({
        partners: partnerListSelector(state),
    }),
    {
        getPartners,
        showModal,
    }
)
export default class CreateDemoPartnerPage extends PureComponent<DemoPartnerPageModel, DemoPartnerPageState> {
    state = {
        hasSearched: false,
        searching: false,
    }

    showCreateDemoPartnerModal = () => {
        this.props.showModal({ name: 'createDemoPartnerModal' })
    }

    searchForPartners = async (event) => {
        if (event.keyCode !== 13 || this.state.searching) {
            return
        }

        this.setState({ hasSearched: true })

        this.setState({ searching: true })
        await this.props.getPartners(
            {
                geoCode: config.geoCode,
                query: event.target.value,
            },
            false
        )
        this.setState({ searching: false })
    }

    get hasSearched() {
        return this.state.hasSearched && ! this.state.searching
    }

    render() {
        const {
            partners,
        } = this.props

        return (
            <Row>
                <Col md={12}>
                    <Card margin="0 0 105px 0">
                        <PageTitle title="Create new demo partner"/>
                        <Card.Header>
                            <strong>Create new demo partner</strong>
                        </Card.Header>

                        <div style={{ margin: '20px' }}>
                            <input
                                type="text"
                                className="form-control input-lg"
                                placeholder="Search for partners.."
                                onKeyUp={this.searchForPartners}
                                disabled={this.state.searching}
                            />

                            {partners.length > 0 && <Table style={{ marginTop: '20px' }}>
                                <thead>
                                    <Column>ID</Column>
                                    <Column>Company name</Column>
                                    <Column>Location</Column>
                                    <Column>Employee</Column>
                                    <Column>Satisfaction</Column>
                                    <Column>Last Login</Column>
                                    <Column>Last Phone Call</Column>
                                    <Column>Subscription</Column>
                                    <Column>Subscription start date</Column>
                                    <Column>Last published quote</Column>
                                    <Column>Total quotes</Column>
                                    <Column>Success ratio</Column>
                                    <Column>Refunds</Column>
                                    <Column>Refund ratio</Column>
                                    <Column>Digital leads</Column>
                                    <Column>Canvas Status</Column>
                                </thead>
                                <tbody>
                                    {partners.map((partner) => <PartnerRow key={partner.id} partner={partner} />)}
                                </tbody>
                            </Table>}

                            {this.hasSearched && partners.length === 0 && <p style={NoPartnersFoundStyle}>
                                No partners found
                            </p>}
                        </div>

                        <Card.Line />
                    </Card>
                </Col>

                <ActionButtonBlock>
                    <LoadingBar />
                    {this.hasSearched && <ButtonGrid>
                        <ButtonContainer>
                            <ButtonGroup>
                                <Button
                                    modifiers={[ 'primary' ]}
                                    style={{
                                        height: '40px',
                                        margin: '20px',
                                    }}
                                    onClick={this.showCreateDemoPartnerModal}
                                >
                                    Create a new demo partner
                                </Button>
                            </ButtonGroup>
                        </ButtonContainer>
                    </ButtonGrid>}
                </ActionButtonBlock>
            </Row>
        )
    }
}
