import { createSelector } from 'reselect'

const entitiesBusinessModels = state => state.entities.businessModels

const allBusinessModelsId = state => state.subjects.businessModels.all.ids

export const allBusinessModelsSelector = createSelector(
    allBusinessModelsId, entitiesBusinessModels,
    (ids, allocations) => ids.map(id => allocations[id])
)
