import React, { Fragment, memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { HasPermission } from '../NavigationBar'
import { Link } from 'react-router-dom'

const MarketingDropdown = memo(({ hasPermission }: HasPermission) =>
    <NavDropdown eventKey={3} title="Marketing" id="basic-nav-dropdown-Marketing">
        {hasPermission('view_affiliates') && <Fragment>
            <MenuItem eventKey={3.20} href="/affiliates/menu">Menu</MenuItem>
            <MenuItem eventKey={3.1} href="/affiliates">All affiliates</MenuItem>
            <MenuItem eventKey={3.2} href="/affiliates/campaigns">Affiliate campaigns</MenuItem>
            <MenuItem eventKey={3.3} href="/affiliates/payments">Affiliate payments</MenuItem>
            <MenuItem divider />
        </Fragment>}
        {hasPermission('leads_create') && <Fragment>
            <MenuItem eventKey={3.31}><Link to="/create-lead">Create lead</Link></MenuItem>
            <MenuItem divider />
        </Fragment>}

        <MenuItem header>Contact</MenuItem>
        <MenuItem eventKey={3.4} href="/admin/design-templates">Design Templates</MenuItem>
        <MenuItem eventKey={3.5} href="/admin/contact-templates">Templates</MenuItem>
        <MenuItem eventKey={3.6} href="/admin/contact-queue">Contact queue</MenuItem>

        {hasPermission('admin_edit_frontend') && <Fragment>
            <MenuItem divider />
            <MenuItem header>Website</MenuItem>
            <MenuItem eventKey={3.21} href="/frontend/menu">Menu</MenuItem>
            <MenuItem eventKey={3.7}href="/frontend/pages">Pages</MenuItem>
            <MenuItem eventKey={3.8} href="/frontend/blog/posts">Blog</MenuItem>
            <MenuItem eventKey={3.9} href="/frontend/redirects">Redirects</MenuItem>
            <MenuItem eventKey={3.10} href="/frontend/snippets">Snippets</MenuItem>
            <MenuItem eventKey={3.11} href="/frontend/resources">Resources</MenuItem>
            <MenuItem eventKey={3.12} href="/frontend/routings">Routings</MenuItem>
            <MenuItem eventKey={3.13} href="/frontend/settings">Settings</MenuItem>
            <MenuItem eventKey={3.14} href="/frontend/fields">Fields</MenuItem>
            <MenuItem eventKey={3.15} href="/frontend/services">Services</MenuItem>
            <MenuItem eventKey={3.16} href="/frontend/locations">Locations</MenuItem>
            <MenuItem eventKey={3.17} href="/frontend/indexed-locations">Indexed locations</MenuItem>
            <MenuItem eventKey={3.18} href="/frontend/employees">Employees</MenuItem>
            <MenuItem eventKey={3.19} href="/frontend/jobs">Jobs</MenuItem>
            <MenuItem eventKey={3.21} href="/frontend/dictionary?direct_page=dictionary_post">Dictionary</MenuItem>
        </Fragment>}
    </NavDropdown>
)

export default MarketingDropdown
