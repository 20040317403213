import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import config from '../config'
import moment from 'moment'
import { actionTypesCreator } from '../utils'
import { GET_QUOTES, GET_QUOTES_FAIL, GET_QUOTES_SUCCESS } from './quotes'

export const GET_LEADS = 'leads/GET_LEADS'
export const GET_LEADS_SUCCESS = 'leads/GET_LEADS_SUCCESS'
export const GET_LEADS_FAIL = 'leads/GET_LEADS_FAIL'

export const CREATE_LEAD = actionTypesCreator('leads', 'CREATE_LEAD')

export const GET_SEGMENTED_LEADS = 'leads/GET_SEGMENTED_LEADS'
export const GET_SEGMENTED_LEADS_SUCCESS = 'leads/GET_SEGMENTED_LEADS_SUCCESS'
export const GET_SEGMENTED_LEADS_FAIL = 'leads/GET_SEGMENTED_LEADS_FAIL'

export const GET_MATCH_SURVEILLANCE_LEADS = 'leads/GET_MATCH_SURVEILLANCE_LEADS'
export const GET_MATCH_SURVEILLANCE_LEADS_SUCCESS = 'leads/GET_MATCH_SURVEILLANCE_LEADS_SUCCESS'
export const GET_MATCH_SURVEILLANCE_LEADS_FAIL = 'leads/GET_MATCH_SURVEILLANCE_LEADS_FAIL'

export const GET_ALL_LEADS = 'leads/GET_ALL_LEADS'
export const GET_ALL_LEADS_SUCCESS = 'leads/GET_ALL_LEADS_SUCCESS'
export const GET_ALL_LEADS_FAIL = 'leads/GET_ALL_LEADS_FAIL'

export const GET_BOUGHT_LEADS = 'leads/GET_BOUGHT_LEADS'
export const GET_BOUGHT_LEADS_SUCCESS = 'leads/GET_BOUGHT_LEADS_SUCCESS'
export const GET_BOUGHT_LEADS_FAIL = 'leads/GET_BOUGHT_LEADS_FAIL'

export const GET_SMART_LEADS = 'leads/GET_SMART_LEADS'
export const GET_SMART_LEADS_SUCCESS = 'leads/GET_SMART_LEADS_SUCCESS'
export const GET_SMART_LEADS_FAIL = 'leads/GET_SMART_LEADS_FAIL'

export const GET_LEAD = 'leads/GET_LEAD'
export const GET_LEAD_SUCCESS = 'leads/GET_LEAD_SUCCESS'
export const GET_LEAD_FAIL = 'leads/GET_LEAD_FAIL'

export const POSTPONE_CLIENT_MATCH_DECISION = 'leads/POSTPONE_CLIENT_MATCH_DECISION'
export const POSTPONE_CLIENT_MATCH_DECISION_SUCCESS = 'leads/POSTPONE_CLIENT_MATCH_DECISION_SUCCESS'
export const POSTPONE_CLIENT_MATCH_DECISION_FAIL = 'leads/POSTPONE_CLIENT_MATCH_DECISION_FAIL'

export const POSTPONE_VALIDATION = 'leads/POSTPONE_VALIDATION'
export const POSTPONE_VALIDATION_SUCCESS = 'leads/POSTPONE_VALIDATION_SUCCESS'
export const POSTPONE_VALIDATION_FAIL = 'leads/POSTPONE_VALIDATION_FAIL'

export const ACCEPT_LEAD_QUOTE = 'leads/ACCEPT_LEAD_QUOTE'
export const ACCEPT_LEAD_QUOTE_SUCCESS = 'leads/ACCEPT_LEAD_QUOTE_SUCCESS'
export const ACCEPT_LEAD_QUOTE_FAIL = 'leads/ACCEPT_LEAD_QUOTE_FAIL'

export const CLOSE_LEAD_COMPLETED = 'leads/CLOSE_LEAD_COMPLETED'
export const CLOSE_LEAD_COMPLETED_SUCCESS = 'leads/CLOSE_LEAD_COMPLETED_SUCCESS'
export const CLOSE_LEAD_COMPLETED_FAIL = 'leads/CLOSE_LEAD_COMPLETED_FAIL'

export const LEAD_ACTION_RECORD_REJECTION = 'leads/LEAD_ACTION_RECORD_REJECTION'
export const LEAD_ACTION_RECORD_REJECTION_SUCCESS = 'leads/LEAD_ACTION_RECORD_REJECTION_SUCCESS'
export const LEAD_ACTION_RECORD_REJECTION_FAIL = 'leads/LEAD_ACTION_RECORD_REJECTION_FAIL'

export const LEAD_ACTION_FORGOT_LEAD = 'leads/LEAD_ACTION_FORGOT_LEAD'
export const LEAD_ACTION_FORGOT_LEAD_SUCCESS = 'leads/LEAD_ACTION_FORGOT_LEAD_SUCCESS'
export const LEAD_ACTION_FORGOT_LEAD_FAIL = 'leads/LEAD_ACTION_FORGOT_LEAD_FAIL'

export const SAVE_LEAD = 'leads/SAVE_LEAD'
export const SAVE_LEAD_SUCCESS = 'leads/SAVE_LEAD_SUCCESS'
export const SAVE_LEAD_FAIL = 'leads/SAVE_LEAD_FAIL'

export const GET_LEADS_AGGREGATIONS = 'leads/GET_LEADS_AGGREGATIONS'
export const GET_LEADS_AGGREGATIONS_SUCCESS = 'leads/GET_LEADS_AGGREGATIONS_SUCCESS'
export const GET_LEADS_AGGREGATIONS_FAIL = 'leads/GET_LEADS_AGGREGATIONS_FAIL'

export const GET_EXCLUDED_PARTNERS = 'leads/GET_EXCLUDED_PARTNERS'
export const GET_EXCLUDED_PARTNERS_SUCCESS = 'leads/GET_EXCLUDED_PARTNERS_SUCCESS'
export const GET_EXCLUDED_PARTNERS_FAIL = 'leads/GET_EXCLUDED_PARTNERS_FAIL'

export const DELETE_EXCLUDED_PARTNER = 'leads/DELETE_EXCLUDED_PARTNER'
export const DELETE_EXCLUDED_PARTNER_SUCCESS = 'leads/DELETE_EXCLUDED_PARTNER_SUCCESS'
export const DELETE_EXCLUDED_PARTNER_FAIL = 'leads/DELETE_EXCLUDED_PARTNER_FAIL'

export const CREATE_EXCLUDED_PARTNER = 'leads/CREATE_EXCLUDED_PARTNER'
export const CREATE_EXCLUDED_PARTNER_SUCCESS = 'leads/CREATE_EXCLUDED_PARTNER_SUCCESS'
export const CREATE_EXCLUDED_PARTNER_FAIL = 'leads/CREATE_EXCLUDED_PARTNER_FAIL'

export const LEAD_ACTION_VALIDATE = 'leads/LEAD_ACTION_VALIDATE'
export const LEAD_ACTION_VALIDATE_SUCCESS = 'leads/LEAD_ACTION_VALIDATE_SUCCESS'
export const LEAD_ACTION_VALIDATE_FAIL = 'leads/LEAD_ACTION_VALIDATE_FAIL'

export const LEAD_ACTION_MARK_AS_UNTREATED = 'leads/LEAD_ACTION_MARK_AS_UNTREATED'
export const LEAD_ACTION_MARK_AS_UNTREATED_SUCCESS = 'leads/LEAD_ACTION_MARK_AS_UNTREATED_SUCCESS'
export const LEAD_ACTION_MARK_AS_UNTREATED_FAIL = 'leads/LEAD_ACTION_MARK_AS_UNTREATED_FAIL'

export const CREATE_LEAD_DISCARDS = '/leads/CREATE_LEAD_DISCARDS'
export const CREATE_LEAD_DISCARDS_SUCCESS = '/leads/CREATE_LEAD_DISCARDS_SUCCESS'
export const CREATE_LEAD_DISCARDS_FAIL = '/leads/CREATE_LEAD_DISCARDS_FAIL'

export const LEAD_ACTION_DISCARD = 'leads/LEAD_ACTION_DISCARD'
export const LEAD_ACTION_DISCARD_SUCCESS = 'leads/LEAD_ACTION_DISCARD_SUCCESS'
export const LEAD_ACTION_DISCARD_FAIL = 'leads/LEAD_ACTION_DISCARD_FAIL'

export const GET_COMPLETED_CALL_FLOW_LEADS = 'leads/GET_COMPLETED_CALL_FLOW_LEADS'
export const GET_COMPLETED_CALL_FLOW_LEADS_SUCCESS = 'leads/GET_COMPLETED_CALL_FLOW_LEADS_SUCCESS'
export const GET_COMPLETED_CALL_FLOW_LEADS_FAIL = 'leads/GET_COMPLETED_CALL_FLOW_LEADS_FAIL'

export const UNREJECT_QUOTES = 'leads/UNREJECT_QUOTES'
export const UNREJECT_QUOTES_SUCCESS = 'leads/UNREJECT_QUOTES_SUCCESS'
export const UNREJECT_QUOTES_FAIL = 'leads/UNREJECT_QUOTES_FAIL'

export const LEAD_ACTION_GIVEN_UP = 'leads/LEAD_ACTION_GIVEN_UP'
export const LEAD_ACTION_GIVEN_UP_SUCCESS = 'leads/LEAD_ACTION_GIVEN_UP_SUCCESS'
export const LEAD_ACTION_GIVEN_UP_FAIL = 'leads/LEAD_ACTION_GIVEN_UP_FAIL'

export const LEAD_ACTION_SEND_TO_PARTNER = 'leads/LEAD_ACTION_SEND_TO_PARTNER'
export const LEAD_ACTION_SEND_TO_PARTNER_SUCCESS = 'leads/LEAD_ACTION_SEND_TO_PARTNER_SUCCESS'
export const LEAD_ACTION_SEND_TO_PARTNER_FAIL = 'leads/LEAD_ACTION_SEND_TO_PARTNER_FAIL'

export const GET_LEAD_CATEGORIES = 'leads/GET_LEAD_CATEGORIES'
export const GET_LEAD_CATEGORIES_SUCCESS = 'leads/GET_LEAD_CATEGORIES_SUCCESS'
export const GET_LEAD_CATEGORIES_FAIL = 'leads/GET_LEAD_CATEGORIES_FAIL'

export const GET_QUOTES_SURVEILLANCE_LEADS = 'leads/GET_QUOTES_SURVEILLANCE_LEADS'
export const GET_QUOTES_SURVEILLANCE_LEADS_SUCCESS = 'leads/GET_QUOTES_SURVEILLANCE_LEADS_SUCCESS'
export const GET_QUOTES_SURVEILLANCE_LEADS_FAIL = 'leads/GET_QUOTES_SURVEILLANCE_LEADS_FAIL'

export const GET_OPEN_CASES_LEADS = 'lead/GET_OPEN_CASES_LEADS'
export const GET_OPEN_CASES_SUCCESS = 'lead/GET_OPEN_CASES_SUCCESS'
export const GET_OPEN_CASES_FAIL = 'lead/GET_OPEN_CASES_FAIL'

export const GET_CURRENT_INTERVAL_LEADS = 'leads/GET_CURRENT_INTERVAL_LEADS'
export const GET_CURRENT_INTERVAL_LEADS_SUCCESS = 'leads/GET_CURRENT_INTERVAL_LEADS_SUCCESS'
export const GET_CURRENT_INTERVAL_LEADS_FAIL = 'leads/GET_CURRENT_INTERVAL_LEADS_FAIL'

export const LEAD_ACTION_QUEUE_FEEDBACK_EMAIL = 'leads/LEAD_ACTION_QUEUE_FEEDBACK_EMAIL'
export const LEAD_ACTION_QUEUE_FEEDBACK_EMAIL_SUCCESS = 'leads/LEAD_ACTION_QUEUE_FEEDBACK_EMAIL_SUCCESS'
export const LEAD_ACTION_QUEUE_FEEDBACK_EMAIL_FAIL = 'leads/LEAD_ACTION_QUEUE_FEEDBACK_EMAIL_FAIL'

export const GET_DEMO_LEADS = 'leads/GET_DEMO_LEADS'
export const GET_DEMO_LEADS_SUCCESS = 'leads/GET_DEMO_LEADS_SUCCESS'
export const GET_DEMO_LEADS_FAIL = 'leads/GET_DEMO_LEADS_FAIL'

export const LEAD_ACTION_COPY_TO_NEW = 'leads/LEAD_ACTION_COPY_TO_NEW'
export const LEAD_ACTION_COPY_TO_NEW_SUCCESS = 'leads/LEAD_ACTION_COPY_TO_NEW_SUCCESS'
export const LEAD_ACTION_COPY_TO_NEW_FAIL = 'leads/LEAD_ACTION_COPY_TO_NEW_FAIL'

export const DELETE_ALLOCATION_SUGGESTIONS = 'leads/DELETE_ALLOCATION_SUGGESTIONS'
export const DELETE_ALLOCATION_SUGGESTIONS_SUCCESS = 'leads/DELETE_ALLOCATION_SUGGESTIONS_SUCCESS'
export const DELETE_ALLOCATION_SUGGESTIONS_FAIL = 'leads/DELETE_ALLOCATION_SUGGESTIONS_FAIL'

interface GetLeadsModel {
    partnerId?: number
    leadId?: number | number[]
    limit?: string
}

interface GetFollowUpLeadsModel {
    column?: string
    direction?: string
    validatorId?: string
    industry?: string
    leadCategory?: string
    geo_rect?: string
    geo_point?: string
    geoDistance?: string
    locationId?: string
}

interface GetCompletedCallFlowLeadsModel {
    employeeId?: string
    geoRect?: string
    geoPoint?: string
    geoDistance?: string
    locationId?: string
}

interface GetQuotesSurveillanceLeadsModel {
    leadCategory?: string
    validatorId?: number
    column?: string
    direction?: string
    visibleColumns?: string
    industry?: string
    expiresWithin?: string
    geo_rect?: string
    geo_point?: string
    geoDistance?: string
    locationId?: string
}

interface GetOpenCasesLeadsModel {
    employeeId?: number
    validatorId?: number
    limit?: string
}

interface GetSpecifiedIntervalLeadsModel extends GetLeadsModel {
    latestCalledAtBefore: string
    createdAtGte?: string
}

export const getLeads = (
    criteria: GetLeadsModel | any,
    types?: string[],
    append?: boolean
) => ({
    [CALL_API]: {
        types: types || [ GET_LEADS, GET_LEADS_SUCCESS, GET_LEADS_FAIL ],
        endpoint: '/leads',
        schema: schemas.leads,
        options: {
            body: {
                limit: 100,
                ...criteria,
            },
        },
    },
    append,
})

export const saveLead = body => ({
    [CALL_API]: {
        types: [ SAVE_LEAD, SAVE_LEAD_SUCCESS, SAVE_LEAD_FAIL ],
        endpoint: `/leads/${body.id}`,
        schema: schemas.lead,
        options: {
            method: 'put',
            body,
        },
    },
})

export const createLead = (body) => ({
    [CALL_API]: {
        types: CREATE_LEAD,
        endpoint: '/leads',
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                ...body,
                geo: {
                    ...body.geo,
                    geoCode: config.geoCode,
                },
            },
        },
    },
})

export const getSegmentedLeads = (
    partnerId: number
) => getLeads({
    status: 'validated',
    isQuotesLead: '1',
    hasMaxQuotes: '0',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    segmentedForPartnerId: partnerId,
    noQuoteForPartnerId: partnerId,
    sort: '-validated_at',
}, [ GET_SEGMENTED_LEADS, GET_SEGMENTED_LEADS_SUCCESS, GET_SEGMENTED_LEADS_FAIL ])

export const getDemoLeadsForPartner = (partnerId, segmentIds, geoRegionIds, criteria = {}) => ({
    [CALL_API]: {
        types: [ GET_DEMO_LEADS, GET_DEMO_LEADS_SUCCESS, GET_DEMO_LEADS_FAIL ],
        endpoint: `/leads/demopartners/${partnerId}`,
        schema: schemas.leads,
        options: {
            body: {
                segmentedForRevenueSegmentId: segmentIds,
                geoRegionId: geoRegionIds,
                ...criteria,
            },
        },
    },
})

export const getFollowUpLeads = (
    criteria: GetFollowUpLeadsModel,
    append?: boolean
) => getLeads({
    sort: 'quote_published_at',
    isQuotesLead: '1',
    hasMaxQuotes: '1',
    clientDecisionPostponed: '0',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    isGivenUp: '0',
    latestCalledAtBefore: moment().add(-2, 'day').format('YYYY-MM-DD HH:mm'),
    geoCode: config.geoCode,
    ...criteria,
},
[ GET_MATCH_SURVEILLANCE_LEADS, GET_MATCH_SURVEILLANCE_LEADS_SUCCESS, GET_MATCH_SURVEILLANCE_LEADS_FAIL ], append)

export const getCompletedCallFlowLeads = (
    criteria: GetCompletedCallFlowLeadsModel,
    append?: boolean
) => getLeads({
    sort: 'completed_call_priority_date',
    isQuotesLead: '1',
    hasMaxQuotes: '1',
    clientDecisionPostponed: '0',
    isCompletedCallPriority: '1',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    ...criteria,
}, [ GET_COMPLETED_CALL_FLOW_LEADS, GET_COMPLETED_CALL_FLOW_LEADS_SUCCESS, GET_COMPLETED_CALL_FLOW_LEADS_FAIL ], append)

export const getQuotesSurveillanceLeads = (
    criteria: GetQuotesSurveillanceLeadsModel,
    append?: boolean
) => getLeads({
    isQuotesLead: '1',
    hasMaxQuotes: '0',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    status: 'validated',
    geoCode: config.geoCode,
    isAnonymized: '0',
    ...criteria,
},
[ GET_QUOTES_SURVEILLANCE_LEADS, GET_QUOTES_SURVEILLANCE_LEADS_SUCCESS, GET_QUOTES_SURVEILLANCE_LEADS_FAIL ],
append
)

export const getLead = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ GET_LEAD, GET_LEAD_SUCCESS, GET_LEAD_FAIL ],
        endpoint: `/leads/${leadId}`,
        schema: schemas.lead,
    },
})

export const getLeadUpdateQuotes = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ GET_QUOTES, GET_QUOTES_SUCCESS, GET_QUOTES_FAIL ],
        endpoint: `/leads/${leadId}`,
        schema: schemas.lead,
    },
})

interface PostponeClientMatchDecisionModel {
    clientDecisionPostponedReason: string
    date: string
}

export const postponeClientMatchDecision = (
    leadId: number,
    criteria: PostponeClientMatchDecisionModel
) => ({
    [CALL_API]: {
        types: [
            POSTPONE_CLIENT_MATCH_DECISION,
            POSTPONE_CLIENT_MATCH_DECISION_SUCCESS,
            POSTPONE_CLIENT_MATCH_DECISION_FAIL,
        ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'postpone_client_match_decision',
                ...criteria,
            },
        },
    },
    leadId,
})

interface PostponeValidationModel {
    date: string
}

export const postponeValidation = (
    leadId: number,
    criteria: PostponeValidationModel
) => ({
    [CALL_API]: {
        types: [
            POSTPONE_VALIDATION,
            POSTPONE_VALIDATION_SUCCESS,
            POSTPONE_VALIDATION_FAIL,
        ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'postpone_validation',
                ...criteria,
            },
        },
    },
    leadId,
})

export const closeLeadAsCompleted = (
    leadId: number,
    reasonCompleted: string
) => ({
    [CALL_API]: {
        types: [ CLOSE_LEAD_COMPLETED, CLOSE_LEAD_COMPLETED_SUCCESS, CLOSE_LEAD_COMPLETED_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'mark_as_completed',
                reasonCompleted,
            },
        },
    },
    leadId,
})

export const acceptLeadQuote = (
    leadId: number,
    leadQuoteId: number
) => ({
    [CALL_API]: {
        types: [ ACCEPT_LEAD_QUOTE, ACCEPT_LEAD_QUOTE_SUCCESS, ACCEPT_LEAD_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${leadQuoteId}/actions`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body: {
                action: 'accept',
            },
        },
    },
    leadId,
})

export const leadActionRecordRejection = (
    leadId: number,
    rejectionReasons: object
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_RECORD_REJECTION, LEAD_ACTION_RECORD_REJECTION_SUCCESS, LEAD_ACTION_RECORD_REJECTION_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'record_rejection_reasons',
                rejectionReasons,
            },
        },
    },
})

export const leadActionForgotLead = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_FORGOT_LEAD, LEAD_ACTION_FORGOT_LEAD_SUCCESS, LEAD_ACTION_FORGOT_LEAD_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'invoke_right_to_be_forgotten',
            },
        },
    },
})

interface GetLeadsAggregationsModel {
    industry: string | string[]
}
export const getLeadsAggregations = (
    criteria: GetLeadsAggregationsModel
) => ({
    [CALL_API]: {
        types: [ GET_LEADS_AGGREGATIONS, GET_LEADS_AGGREGATIONS_SUCCESS, GET_LEADS_AGGREGATIONS_FAIL ],
        endpoint: '/leads/aggregations',
        schema: schemas.leadAggregations,
        options: {
            body: {
                ...criteria,
                geoCode: config.geoCode,
            },
        },
    },
})

export const getExcludedPartners = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ GET_EXCLUDED_PARTNERS, GET_EXCLUDED_PARTNERS_SUCCESS, GET_EXCLUDED_PARTNERS_FAIL ],
        endpoint: `/leads/${leadId}/excludedpartners`,
        schema: schemas.partners,
        options: {
            body: {
                limit: 100,
            },
        },
    },
})

export const deleteExcludedPartner = (
    leadId: number,
    partnerId: number
) => ({
    [CALL_API]: {
        types: [ DELETE_EXCLUDED_PARTNER, DELETE_EXCLUDED_PARTNER_SUCCESS, DELETE_EXCLUDED_PARTNER_FAIL ],
        endpoint: `/leads/${leadId}/excludedpartners/${partnerId}`,
        schema: schemas.partners,
        options: {
            method: 'delete',
        },
    },
})

export const createExcludedPartner = (
    leadId: number,
    partnerId: number
) => ({
    [CALL_API]: {
        types: [ CREATE_EXCLUDED_PARTNER, CREATE_EXCLUDED_PARTNER_SUCCESS, CREATE_EXCLUDED_PARTNER_FAIL ],
        endpoint: `/leads/${leadId}/excludedpartners/`,
        options: {
            method: 'post',
            body: {
                id: partnerId,
            },
        },
    },
})

export const leadActionValidate = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_VALIDATE, LEAD_ACTION_VALIDATE_SUCCESS, LEAD_ACTION_VALIDATE_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'validate',
            },
        },
    },
})

export const leadActionMarkAsUntreated = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_MARK_AS_UNTREATED, LEAD_ACTION_MARK_AS_UNTREATED_SUCCESS, LEAD_ACTION_MARK_AS_UNTREATED_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'mark_lead_as_untreated',
            },
        },
    },
})

export const createLeadDiscards = (
    leadIds: number[],
    status: string,
) => ({
    [CALL_API]: {
        types: [ CREATE_LEAD_DISCARDS, CREATE_LEAD_DISCARDS_SUCCESS, CREATE_LEAD_DISCARDS_FAIL ],
        endpoint: '/leads/discards',
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                leadIds: leadIds,
                status: status,
            },
        },
    }
})


interface LeadActionDiscardModel {
    discardStatus: string
}
export const leadActionDiscard = (
    leadId: number,
    criteria: LeadActionDiscardModel
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_DISCARD, LEAD_ACTION_DISCARD_SUCCESS, LEAD_ACTION_DISCARD_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'discard',
                ...criteria,
            },
        },
    },
})

export const unrejectQuotesAction = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ UNREJECT_QUOTES, UNREJECT_QUOTES_SUCCESS, UNREJECT_QUOTES_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'unreject_all_quotes',
            },
        },
    },
    leadId,
})

export const leadActionGivenUp = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_GIVEN_UP, LEAD_ACTION_GIVEN_UP_SUCCESS, LEAD_ACTION_GIVEN_UP_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'given_up',
            },
        },
    },
    leadId,
})

export const leadActionQueueFeedbackEmail = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_QUEUE_FEEDBACK_EMAIL, LEAD_ACTION_QUEUE_FEEDBACK_EMAIL_SUCCESS, LEAD_ACTION_QUEUE_FEEDBACK_EMAIL_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'queue_feedback_email',
            },
        },
    },
    leadId,
})

export const leadActionSendToPartner = (
    leadId: number,
    partnerId: number | number[]
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_SEND_TO_PARTNER, LEAD_ACTION_SEND_TO_PARTNER_SUCCESS, LEAD_ACTION_SEND_TO_PARTNER_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'send_to_partner',
                leadId: leadId,
                partnerId: partnerId,
            },
        },
    },
    leadId,
})

export const getLeadCategories = () => ({
    [CALL_API]: {
        types: [ GET_LEAD_CATEGORIES, GET_LEAD_CATEGORIES_SUCCESS, GET_LEAD_CATEGORIES_FAIL ],
        endpoint: '/leads/categories',
        schema: schemas.leadCategories,
    },
})

export const getOpenCasesLeads = (
    criteria: GetOpenCasesLeadsModel,
    append?: boolean
) => getLeads({
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    isGivenUp: '0',
    geoCode: config.geoCode,
    isQuotesLead: '1',
    ...criteria,
}, [ GET_OPEN_CASES_LEADS, GET_OPEN_CASES_SUCCESS, GET_OPEN_CASES_FAIL ], append)

export const getIntervalLeads = (
    criteria: GetSpecifiedIntervalLeadsModel,
    append?: boolean
) => getLeads({
    ...criteria,
    status: 'untreated',
    limit: criteria.limit,
    isValidationPostponed: 'false',
    latestCalledAtBefore: criteria.latestCalledAtBefore,
    callCountBeforeValidationLt: 8,
    sort: 'call_count_before_validation',
    createdAtGte: moment().add(-30, 'day').format('YYYY-MM-DD'),
    geoCode: config.geoCode,
}, [ GET_CURRENT_INTERVAL_LEADS, GET_CURRENT_INTERVAL_LEADS_SUCCESS, GET_CURRENT_INTERVAL_LEADS_FAIL ], append)

export const leadCopyToNewLead = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_COPY_TO_NEW, LEAD_ACTION_COPY_TO_NEW_SUCCESS, LEAD_ACTION_COPY_TO_NEW_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'copy_to_new',
            },
        },
    },
    leadId,
})

export const deleteAllocationSuggestion = (leadId: number, allocationSuggestionId: number) => ({
    [CALL_API]: {
        types: [ DELETE_ALLOCATION_SUGGESTIONS, DELETE_ALLOCATION_SUGGESTIONS_SUCCESS, DELETE_ALLOCATION_SUGGESTIONS_FAIL ],
        schema: schemas.allocationSuggestions,
        endpoint: `/leads/${leadId}/allocationsuggestions/${allocationSuggestionId}`,
        options: {
            method: 'delete',
        },
    },
})
