import React, { Children, cloneElement, ComponentType, memo, ReactElement } from 'react'
import { TableProviderContext } from './_Provider'
import { DropdownFilter, ToggleFilter } from '../../elements/table'

const _FilterContainer = memo(({ children }) =>
    <TableProviderContext.Consumer>
        {({ onFilterChange }) => {
            return <div className="infotable-header">

                {Children.toArray(children).map((child:ReactElement<any>) => cloneElement(child, {
                    onChange: value => {
                        // onChange either takes an object with key/value pairs, or a value directly
                        // if the value sent is an object it is passed on directly
                        if (typeof value === 'object' && !Array.isArray(value)) {
                            onFilterChange(value)
                        } else {
                            // if the value is not an object we use the name prop of the child component
                            // as the key for the onChange event
                            onFilterChange({ [child.props.name]: value })
                        }
                    },
                }))}

                <TableProviderContext.Consumer>
                    {({ columnNames, visibleColumns }) =>
                        columnNames && <DropdownFilter
                            selectedIds={(visibleColumns && visibleColumns.length) ? visibleColumns : columnNames}
                            items={columnNames.filter(name => name).map(name => ({
                                id: name,
                                name: name.replace(/_/g, ' ').toUpperCase(),
                            }))}
                            name="visibleColumns"
                            label="Visible Columns"
                            search={false}
                            onChange={value => onFilterChange({ visibleColumns: value })}
                        />
                    }
                </TableProviderContext.Consumer>

                <TableProviderContext.Consumer>
                    {({ onFilterReset }) =>
                        <a onClick={onFilterReset}>
                            Reset filters
                        </a>
                    }
                </TableProviderContext.Consumer>

                <TableProviderContext.Consumer>
                    {({ tabs, sidebarName }) =>
                        <ToggleFilter
                            name="sidebarName"
                            selected={sidebarName}
                            tabs={tabs}
                            onClick={onFilterChange}
                        />
                    }
                </TableProviderContext.Consumer>

            </div>
        }}
    </TableProviderContext.Consumer>
)


export default _FilterContainer as ComponentType
