import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PAYMENT_SOLUTIONS = 'paymentSolutions/GET_PAYMENT_SOLUTIONS'
export const GET_PAYMENT_SOLUTIONS_SUCCESS = 'paymentSolutions/GET_PAYMENT_SOLUTIONS_SUCCESS'
export const GET_PAYMENT_SOLUTIONS_FAIL = 'paymentSolutions/GET_PAYMENT_SOLUTIONS_FAIL'

export const getPaymentSolutions = (criteria?) => ({
    [CALL_API]: {
        types: [ GET_PAYMENT_SOLUTIONS, GET_PAYMENT_SOLUTIONS_SUCCESS, GET_PAYMENT_SOLUTIONS_FAIL ],
        endpoint: '/paymentsolutions',
        schema: schemas.paymentSolutions,
        options: {
            body: {
                isActive: 'null',
                ...criteria,
            },
        },
    },
})
