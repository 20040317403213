import {
    GET_INVOICINGS,
    GET_INVOICINGS_SUCCESS,
    GET_INVOICINGS_FAIL,

} from '../../actions/invocings'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface InvoicingsModel extends InitialState {
    invoicings: number[]
}
const initialState: InvoicingsModel = {
    isLoading: null,
    invoicings: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction,
) {
    switch (action.type) {
        case GET_INVOICINGS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_INVOICINGS_SUCCESS:
            return {
                ...state,
                invoicings: action.append ? state.invoicings.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_INVOICINGS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
