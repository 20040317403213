import {
    GET_LEAD_CONTACT_ARCHIVES,
    GET_LEAD_CONTACT_ARCHIVES_SUCCESS,
    GET_LEAD_CONTACT_ARCHIVES_FAIL,
} from '../../actions/contact'
import { InitialAction } from '../types/InitialAction'

export interface LeadDetailModel {
    archivesIds: number[]
    loadingArchives: boolean
    archivesPagination: {
        pages: number
        page: number
        total: number
    },
}
const initialState: LeadDetailModel = {
    loadingArchives: null,
    archivesIds: [],
    archivesPagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction,
) {
    switch (action.type) {
        case GET_LEAD_CONTACT_ARCHIVES:
            return {
                ...state,
                loadingArchives: true,
            }
        case GET_LEAD_CONTACT_ARCHIVES_SUCCESS:
            return {
                ...state,
                archivesIds: action.append ? state.archivesIds.concat(action.response.result) : action.response.result,
                archivesPagination: action.response.pagination,
                loadingArchives: false,
            }
        case GET_LEAD_CONTACT_ARCHIVES_FAIL:
            return {
                ...state,
                loadingArchives: false,
            }
        default:
            return state
    }
}
