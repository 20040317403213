import styled from 'styled-components'
import { MODIFIER_MARGIN_CONFIG } from './modifiers/spaceModifiers'
import React, { memo } from 'react'
import { applyStyleModifiers } from 'styled-components-modifiers'
import ColorHash from 'color-hash'

export type ModifierMarginConfig = keyof typeof MODIFIER_MARGIN_CONFIG

interface ProfilePictureModel {
    name?: string
    size?: number
    src?: string
    square?: boolean
    hash?: string
    modifiers?: ModifierMarginConfig | ModifierMarginConfig[]
}
const Image = styled.div<ProfilePictureModel>`
  ${props => `
        background: url(${props.src}) center ${props.hash};
        line-height: ${props.size || '40'}px;
        height: ${props.size || '40'}px;
        width: ${props.size || '40'}px;
        border-radius: ${props.square ? '9px' : '100%'};
    `};
    border: 1px solid #f5f5f5;
    background-size: cover;
    letter-spacing: 2px;
    text-align: center;
    color: #fff;
    display: inline-block;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`

export const ProfilePicture = memo(({
    name = '',
    ...props
}: ProfilePictureModel) => {
    const colorHash = new ColorHash()

    let initials:any = name.match(/\b\w/g) || []

    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

    return <Image {...props} hash={colorHash.hex(name)}>
        {props.src ? '' : initials}
    </Image>
})
