import React, { memo, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Cell from './Cell'

interface LinkCell {
    className?: string
    children: ReactNode,
    onClick?: (e: Event) => void,
    title?: string,
    to: string,
}

const LinkCell = memo(({
    className,
    children,
    onClick,
    title,
    to,
}: LinkCell) =>
    <Cell title={title} className={className} >
        <Link
            to={to}
            onClick={e => {e.stopPropagation()}}
        >
            { children }
        </Link>
    </Cell>
)

export default LinkCell
