import { createSelector } from 'reselect'

const entitiesPartnerUsers = state => state.entities.partnerUsers

export const allPartnerUsersId = state => state.subjects.partnerUsers.all.ids

const partnerId = (_, id) => id

export const partnerUsersByPartnerSelector = createSelector(
    entitiesPartnerUsers, allPartnerUsersId, partnerId,
    (users, ids, pid) => ids.reduce((acc, id) => users[id].partnerId === Number(pid) ? [ ...acc, users[id] ] : acc, [])
)
