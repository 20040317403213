import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG } from '../modifiers/spaceModifiers'
import { tableBorderColor } from '../../../utils/variables'

const MODIFIER_TABLE_CONFIG = {
    splitByRow: () => `
        td,th {
            border: none!important;
        }
        tr {
            border-bottom: 1px solid ${tableBorderColor};
        }
    `,
}

type TableModifiers = keyof typeof MODIFIER_TABLE_CONFIG
interface TableModel {
    modifiers?: TableModifiers | TableModifiers[]
}

export const Table = styled.table<TableModel>`
  width: 100%;
  position: relative;
  border-collapse: separate;

  tr {
    &:hover td {
        background-color: #F8FAFC;
    }
    &.active td {
        background-color: #f5f7f9;
    }
    &.clickable {
        cursor: pointer;
        &:hover {
            background-color: #F8FAFC;
        }
    }
  }
  ${applyStyleModifiers(MODIFIER_TABLE_CONFIG)}
  ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
