import React, { memo } from 'react'
import { Modal } from 'react-bootstrap'
import { hideModal } from '../../actions/modal'
import styled from 'styled-components'

const ModalBody = styled(Modal.Body)`
    max-height: 95vh;
    overflow-y: auto;
    p {
      font-size: 16px;
    }
`

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 820px;
    }
`

interface ConfirmModel {
    modalProps: {
        bodyText: JSX.Element | string
    }
    onHide: typeof hideModal
}
const PreviewModal = memo(({
    modalProps,
    onHide,
}: ConfirmModel) =>
    <LgModal
        show={true}
        onHide={onHide}
    >
        <ModalBody>
            {modalProps.bodyText}
        </ModalBody>
    </LgModal>
)

export default PreviewModal
