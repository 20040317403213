import React from 'react'
import styled, { css } from 'styled-components'
import { Check, Minus } from 'react-feather'
import { borderColor, primaryColor } from '../../../utils/variables'

const CheckboxContainer = styled.div<{ checked: boolean, disabled: boolean }>`
  cursor: pointer;
  display: block;
  margin-right: 1em;
  margin-bottom: 0;
  height: 1.4em;
  width: 1.4em;
  border: 1px solid ${borderColor};
  border-radius: 3px;
  box-sizing: content-box;
  ${props => Boolean(props.checked) && css`
    border-color: ${primaryColor};
    background-color: ${primaryColor};
  `}
  ${props => Boolean(props.disabled) && css`
    cursor: not-allowed;
    border-color: ${borderColor};
    background-color: #edf5f9;
    & line, & polyline {
      stroke: ${borderColor};
    }
  `}
}
`

const SvgContainer = styled.div`
    margin: 0px;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    display: inline-block;
    svg {
      width: 1.4em;
      height: 1.4em;
      polyline, line {
        stroke: white;
      }
    }
`

const Svg = props =>
    <SvgContainer>
        {Boolean(props.checked) && props.checked !== 'partially' && <Check/>}
        {props.checked === 'partially' && <Minus/>}
    </SvgContainer>

const Checkbox = props => (
    <CheckboxContainer
        className={props.className}
        onClick={e => props.onChange ? props.onChange({
            ...e,
            target: {
                ...e.target,
                checked: !props.checked,
                value: props.value,
            }
        })
            : props.form.setFieldValue(props.field.name, props.checked ? props.uncheckedValue : props.value)
        }
        checked={props.checked}
        disabled={props.disabled}
    >
        <Svg
            checked={props.checked}
        />
    </CheckboxContainer>
)

export default Checkbox
