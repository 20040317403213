import React, { ComponentType, PureComponent } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Card } from '../../blocks/Card/index'
import { getQuotesSurveillanceLeads } from '../../../actions/leads'
import {
    leadsQuotesSurveillanceSelector,
    leadActivitiesSelector,
} from '../../../selectors/lead'
import { createLeadNoteActivity, getActivities } from '../../../actions/newsfeed'
import LoaderComponent from '../../elements/LoaderComponent'
import { Link } from 'react-router-dom'
import { Lead } from '../../../reducers/types/entities/Lead'
import { connect } from '../../../utils/connect'
import { LinkCell, Cell, DateCell, EditCell, Table, Header, Column, Rows } from '../../elements/table'

interface TableRowModel {
    rowData: Lead
    employeeId: number
    leadActivity: {
        note: {
            text: string
        }
    }
    createLeadNoteActivity: typeof createLeadNoteActivity
    getActivities: typeof getActivities
}

@connect<any,  any,  {rowData: Lead}>(
    (state, props) => ({
        employeeId: state.auth.employeeId,
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
    }),
    {
        createLeadNoteActivity,
        getActivities,
    }
)
class TableRow extends PureComponent<TableRowModel> {
    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        await this.props.createLeadNoteActivity(leadId, noteData)
        this.props.getActivities({ leadId })
    }

    render() {
        const leadActivity = this.props.leadActivity

        const lead = this.props.rowData

        return <tr>
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>{lead.title}</Cell>
            <DateCell date={lead.validatedAt} />
            <Cell>{lead.quotesInfo.count + '/' + lead.quotesInfo.max}</Cell>
            <Cell/>
            <EditCell
                value={leadActivity && leadActivity.note ? leadActivity.note.text : ''}
                onBlur={this.handleChangeNoteCell}
            />
        </tr>
    }
}

const SurveillanceTableRow = TableRow as ComponentType

interface QuotesSurveillanceTableCardModel {
    leads: Lead[]
    pagination: {
        pages: number
        page: number
        total: number
    }
    isLoading: boolean
    employeeId: number
    getQuotesSurveillanceLeads: typeof getQuotesSurveillanceLeads
    getActivities: typeof getActivities
}
@connect(
    state => ({
        leads: leadsQuotesSurveillanceSelector(state),
        pagination: state.pages.quotesSurveillance.pagination,
        isLoading: state.pages.quotesSurveillance.isLoading,
        employeeId: state.auth.employeeId,
    }),
    {
        getQuotesSurveillanceLeads,
        getActivities,
    }
)
class QuotesSurveillanceTableCard extends PureComponent<QuotesSurveillanceTableCardModel>{
    componentDidMount() {
        this.retrieveData()
    }

    async retrieveData(data?, append?) {
        const criteria = {
            ...data,
            leadCategory: 'basic',
            validatorId: this.props.employeeId,
        }
        const res: any = await this.props.getQuotesSurveillanceLeads(criteria, append)
        if (res.result.length) this.props.getActivities({ leadId: res.result })
    }

    loadMore = page => {
        this.retrieveData({
            page,
        }, true)
    }

    render() {
        const {
            isLoading,
            leads,
            pagination: {
                pages,
                page,
            },
        } = this.props

        const hasMore = (page < pages)

        return <Card>
            <Card.Header>
                <Link to="/quotes/surveillance">Quote surveillance</Link>
            </Card.Header>
            <Card.Content modifiers={[ 'p_0', 'scroll', 'maxHeightMd' ]} isLoading={isLoading}>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={this.loadMore}
                    hasMore={isLoading ? false : hasMore}
                    loader={<LoaderComponent/>}
                    useWindow={false}
                >
                    <Table>
                        <Header>
                            <Column>Lead ID</Column>
                            <Column>Lead title</Column>
                            <Column>Validated at</Column>
                            <Column>Quotes</Column>
                            <Column>Reserved quotes</Column>
                            <Column>Notes</Column>
                        </Header>
                        <Rows
                            items={leads}
                            noDataText="No leads for follow up list."
                        >
                            <SurveillanceTableRow/>
                        </Rows>
                    </Table>
                </InfiniteScroll>
            </Card.Content>
        </Card>
    }
}

export default QuotesSurveillanceTableCard as unknown as ComponentType
