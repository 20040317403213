import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import { getKPITargets, getKPIValues } from '../../../actions/kpi'
import moment from 'moment'
import { pickBy, values } from 'lodash'
import { TargetsBarChart } from './TargetsBarChart'
import {  KPITargets } from '../../../reducers/types/entities/KPITarget'
import { KPIValues } from '../../../reducers/types/entities/KPIValue'
import { connect } from '../../../utils/connect'
import { Cell, Header, Table, Column, Rows } from '../../elements/table'

const KPI = [
    { kpiIdentifier: 'validations', name: 'Validations' },
    { kpiIdentifier: 'completions', name: 'Completions' },
    { kpiIdentifier: 'matches', name: 'Matches' },
]

interface KPIRowModel {
    kpiValues: KPIValues
    kpiTargets: KPITargets
    employeeId: number
}

interface RowsHocModel {
    rowData: typeof KPI[0]
}
@connect(
    state => ({
        kpiValues: state.entities.kpiValues,
        kpiTargets: state.entities.kpiTargets,
        employeeId: state.auth.employeeId,
    })
)
class KPIRow extends PureComponent<KPIRowModel & RowsHocModel> {
    currentDay = moment().get('date')

    render() {
        const { rowData, kpiTargets, kpiValues, employeeId } = this.props

        const kpiId = `${employeeId}-${rowData.kpiIdentifier}-${moment().format('YYYY-M')}`

        const todayKpiId = kpiId + '-' + this.currentDay

        const previousFourthMonth = moment().add(-4, 'months')

        const monthValues = values(
            pickBy(kpiValues, el =>
                (el.kpiIdentifier === rowData.kpiIdentifier && moment({ year: el.year, month: (el.month - 1) }).isBetween(previousFourthMonth, moment(), 'month')) ||
                (el.kpiIdentifier === `${rowData.kpiIdentifier}_forecast` && moment({ year: el.year, month: (el.month - 1) }).isSame(moment(), 'month'))
            )
        )
            .sort((a, b) =>
                moment({ year: a.year, month: a.month })
                    .isAfter(moment({ year: b.year, month: b.month }), 'month') ? 1 : -1
            )

        return <tr>
            <Cell>{rowData.name}</Cell>
            <Cell>{kpiValues[todayKpiId] ? kpiValues[todayKpiId].value : '-'}</Cell>
            <Cell>{kpiValues[kpiId] ? kpiValues[kpiId].value : '-'}</Cell>
            <Cell>{kpiTargets[kpiId] ? kpiTargets[kpiId].value : '-'}</Cell>
            <Cell><TargetsBarChart data={monthValues} /></Cell>
        </tr>
    }
}

const KPITableRow = KPIRow as ComponentType

interface MyTargetsTableCardModel {
    employeeId: number
    getKPIValues: typeof getKPIValues
    getKPITargets: typeof getKPITargets
}
@connect(
    state => ({
        employeeId: state.auth.employeeId,
    }),
    {
        getKPIValues,
        getKPITargets,
    }
)
class MyTargetsTableCard extends PureComponent<MyTargetsTableCardModel> {
    componentDidMount() {
        const body = {
            employeeId: this.props.employeeId,
            kpiIdentifier: KPI.map(el => el.kpiIdentifier),
        }

        const dailyBody = {
            ...body,
            temporalScope: 'daily',
        }

        this.props.getKPIValues(dailyBody)
    }

    render() {
        return <Card>
            <Card.Header>My targets</Card.Header>
            <Table>
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col width="250px"/>
                </colgroup>
                <Header>
                    <Column>KPI</Column>
                    <Column>Today</Column>
                    <Column>Month</Column>
                    <Column>Target</Column>
                    <Column>Forecast</Column>
                </Header>
                <Rows items={KPI}>
                    <KPITableRow/>
                </Rows>
            </Table>
        </Card>
    }
}

export default MyTargetsTableCard as unknown as ComponentType

