import React, { PureComponent } from 'react'
import { getFollowUpLeads } from '../../../actions/leads'
import { createCallLeadAction } from '../../../actions/actions'
import config from '../../../config'
import { rejectQuotes } from '../../../actions/quotes'
import { createLeadNoteActivity, getActivities } from '../../../actions/newsfeed'
import { Card } from '../../blocks/Card/index'
import { submit } from 'redux-form'
import { removeEmptyProperties, splitFilterString } from '../../../utils/index'
import { matchSurveillanceLeadSelector } from '../../../selectors/lead'
import { employeesByGeoCodeSelector } from '../../../selectors/employees'
import { Alert } from 'react-bootstrap'
import { connect } from '../../../utils/connect'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Employee } from '../../../reducers/types/entities/Employee'
import { Industry } from '../../../reducers/types/entities/Industry'
import ProviderTable from '../../blocks/ProviderTable'
import { DropdownFilter, LocationFilter, Column, SortColumn } from '../../elements/table'
import FollowUpTableRow from './FollowUpTableRow'
import { getIndustries } from '../../../state/industries/actions'
import { splittedIndustriesSelector } from '../../../state/industries/selectors'

const LEAD_CATEGORY = [
    { id: 'basic', name: 'Basic' },
    { id: 'exclusive', name: 'Exclusive' },
]

interface Criteria {
    sort: string
    visibleColumns: string[]
    validatorId: string[]
    industryId: string[]
    leadCategory: string[]
    geoRect: string
    geoPoint: string
    geoDistance: string
    locationId: string
}

interface FollowUpListStateProps {
    leads: Lead[]
    totalItems: number
    employees: Employee[]
    employeeId: number
    industries: {
        parent: Industry[]
    }
    criteria: Criteria
}

interface FollowUpListDispatchProps {
    createLeadNoteActivity: typeof createLeadNoteActivity
    createCallLeadAction: typeof createCallLeadAction
    getFollowUpLeads: typeof getFollowUpLeads
    getActivities: typeof getActivities
    getIndustries: typeof getIndustries
    rejectQuotes: typeof rejectQuotes
    submit: typeof submit
}

interface FollowUpListPathProps {
    location: {
        query: Criteria
    }
}

type FollowUpRowModel = FollowUpListStateProps & FollowUpListDispatchProps & FollowUpListPathProps

@connect<FollowUpListStateProps, FollowUpListDispatchProps, FollowUpListPathProps>(
    (state, { location: { query } }) => ({
        leads: matchSurveillanceLeadSelector(state),
        totalItems: state.pages.matchSurveillance.pagination.total,
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        employeeId: state.auth.employeeId,
        industries: splittedIndustriesSelector(state),
        criteria: {
            sort: query.sort || 'created_at',
            visibleColumns: splitFilterString(query.visibleColumns),
            validatorId: splitFilterString(query.validatorId),
            industryId: splitFilterString(query.industryId),
            leadCategory: splitFilterString(query.leadCategory),
            geoRect: query.geoRect || '',
            geoPoint: query.geoPoint || '',
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
        },
    }),
    {
        createLeadNoteActivity,
        createCallLeadAction,
        getFollowUpLeads,
        getActivities,
        getIndustries,
        rejectQuotes,
        submit,
    }
)
export default class FollowUpListPage extends PureComponent<FollowUpRowModel> {
    componentDidMount() {
        this.props.getIndustries({ geoCode: config.geoCode })
    }

    retrieveData = async (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({ ...filterCriteria })
        const res: any = await this.props.getFollowUpLeads(criteria, append)
        if (res.result.length) this.props.getActivities({ leadId: res.result })
        return res
    }

    render() {
        const {
            leads,
            criteria,
            employees,
            industries,
            totalItems,
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    Follow-up list 
                    {' '}
                    <span className="text-muted">
                        (
                        {leads.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <Card.Content modifiers="pB_0">
                    <Alert bsStyle="warning" style={{ fontSize: '14px' }}>
                        Here you can see all 
                        {' '}
                        <strong>leads that have all quotes</strong>
                        , which means the lead is completed
                        (the lead's current quotes equal the max quotes that are set).
                        Please be aware here that this does not apply for leads where no quotes could be found,
                        because there would be nothing to match here. In this list expiration does not matter: you
                        see leads that have expired and also leads that have expiration in the future. 
                        {' '}
                        <br />
                        You can postpone leads here, which means you can specify after how many days the lead should
                        appear here in Follow-up list again, so that you get reminded to call that lead again.
                    </Alert>
                </Card.Content>
                <ProviderTable
                    pageName="matchSurveillance"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.validatorId}
                            items={employees}
                            name="validatorId"
                            label="Validator"
                            search={false}
                        />
                        <DropdownFilter
                            selectedIds={criteria.industryId}
                            items={industries.parent}
                            name="industryId"
                            label="Parent industry"
                            search={false}
                        />
                        <DropdownFilter
                            selectedIds={criteria.leadCategory}
                            items={LEAD_CATEGORY}
                            name="leadCategory"
                            label="Lead category"
                            type="radio"
                            search={false}
                        />
                        <LocationFilter
                            label="Location"
                            name="location"
                            locationId={criteria.locationId}
                            geoDistance={criteria.geoDistance}
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="id">Lead ID</Column>
                            <Column name="lead_categories">Lead categories</Column>
                            <Column name="lead_title">Lead title</Column>
                            <Column name="validator">Validator</Column>
                            <Column name="last_quote">Last quote</Column>
                            <Column name="client_name">Client name</Column>
                            <Column name="quote_1">Quote 1</Column>
                            <Column name="quote_2">Quote 2</Column>
                            <Column name="quote_3">Quote 3</Column>
                            <Column name="note">Notes</Column>
                            <SortColumn name="client_decision_postponed_to">Postponed to</SortColumn>
                            <SortColumn name="last_contact_at">Last contact</SortColumn>
                            <SortColumn name="last_action_at">Last action</SortColumn>
                            <SortColumn name="validated_at">Validated at</SortColumn>
                            <Column name="action">Action</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={leads}
                            noDataText="We couldn't find any leads matching your selection."
                        >
                            <FollowUpTableRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
