import React, { PureComponent, ComponentType } from 'react'
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import { isEqual } from 'lodash'
import styled from 'styled-components'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import { Flex } from './Flex'
import { connect } from '../../utils/connect'
import { replace } from 'connected-react-router'
import { tableBorderColor } from '../../utils/variables'
import queryString from 'query-string'

let LatLng, LatLngBounds, Circle

try {
    // @ts-ignore
    LatLng = window.google.maps.LatLng
    // @ts-ignore
    LatLngBounds = window.google.maps.LatLngBounds
    // @ts-ignore
    Circle = window.google.maps.Circle
} catch (e) {
}

const MapContainerElement = styled.div<{showSlider: boolean}>`
  ${props => props.showSlider
        ? 'height: calc(100% - 76px);'
        : 'height: calc(100vh - 275px);'
}
`

const SliderContainer = styled(Flex)`
  padding: 20px;
  background: #fff;
  border-top: 1px solid ${tableBorderColor};
  border-right: 1px solid ${tableBorderColor};
`

function percentFormatter(v) {
    return `${v} km`
}

const SliderWithTooltip = createSliderWithTooltip(Slider)

interface MyMapComponentModel {
    extraOptions?: Partial<{
        type: string
        radius?: number
    }>
    selectedId?: number
    pinLink: string
    mapChanged?: () => void
    list: Array<{
        id: number
        companyName: string
        displayName: string
        partnerId: number
        partner: {
            isSubscriptionPaused: boolean
        }
        geo: {
            address: string
            zipCode: string
            cityName: string
            point: {
                lat: number
                lon: number
            }
        }
    }>
    onPinClick?: (id?: number) => void
}

interface StatePropsMapSidebar {
    query: any
}
interface DispatchPropsMapSidebar {
    replace: typeof replace
}
interface PathPropsMapSidebar {
    saveSlider?: (distance: number) => void
    list: any[]
    header: string
    viewKey: string
    pinLink?: string
    disableFilter?: boolean
    extraOptions?: Partial<{
        type: string
        radius?: number
    }>
}

interface OwnPropsMapSidebar {
    step1Circle: any
    step2Circle: any
    step3Circle: any
    moveEventDisabled: boolean
    moveEventTimer: any
    moveSlider: any
    fitBoundsBlocked: boolean
    map: any
}

type MapSidebarModel = StatePropsMapSidebar & DispatchPropsMapSidebar & PathPropsMapSidebar & OwnPropsMapSidebar

const MyMapComponent = withGoogleMap((props: MyMapComponentModel) =>
    <GoogleMap
        defaultCenter={{
            lat: 57.059008,
            lng: 9.923137,
        }}
        defaultZoom={12}
        onIdle={props.mapChanged}
        options={{
            scaleControl: false,
            mapTypeControl: false,
            streetViewControl: false,
        }}
    >
        {props.list.map(el => {
            return <Marker
                key={el.id}
                position={{
                    lat: el.geo.point.lat,
                    lng: el.geo.point.lon,
                }}
                icon={{
                    url: `/assets/images/${el.partnerId
                        ? el.partner.isSubscriptionPaused ? 'pause-bu-pin' : 'bu-pin'
                        : 'small-pin'
                    }.svg`,
                }}
                onClick={() => props.onPinClick(el.id)}
            >
                {el.id == props.selectedId && (
                    <InfoWindow onCloseClick={props.onPinClick}>
                        <div>
                            {el.partnerId
                                ? <a href={`/${props.pinLink}/${el.partnerId}`} target="_blank" rel="noreferrer"><b>{el.displayName}</b></a>
                                : <a href={`/${props.pinLink}/${el.id}`} target="_blank" rel="noreferrer"><b>{el.companyName}</b></a>
                            }
                            <div>
                                {el.geo.address && (el.geo.address + ', ')}
                                {el.geo.zipCode}
&nbsp;
                                {el.geo.cityName}
                            </div>
                        </div>
                    </InfoWindow>
                )}
            </Marker>
        })}
    </GoogleMap>
)

@connect<StatePropsMapSidebar, DispatchPropsMapSidebar, PathPropsMapSidebar>(
    state => ({
        query: state.router.location.query,
    }),
    {
        replace,
    }
)
class MapSidebar extends PureComponent<MapSidebarModel, {circleRadius?: number}> {
    step1Circle = null
    step2Circle = null
    step3Circle = null
    moveEventDisabled = true
    moveEventTimer = null
    moveSlider = null
    fitBoundsBlocked = false
    map = null
    constructor(props) {
        super(props)
        this.state = {
            circleRadius: props.extraOptions ? props.extraOptions.radius : 0,
        }
    }

    componentDidMount() {
        setTimeout(this.fitBounds.bind(this, this.props.list), 0)
        setTimeout(this.fitCircleOptions.bind(this, this.props.extraOptions), 0)
    }

    fitBounds(list) {
        if (!list.length) return

        if (this.fitBoundsBlocked) {
            this.fitBoundsBlocked = false
            return
        }

        this.moveEventDisabled = true
        const bounds = new LatLngBounds()
        list.forEach(el => {
            bounds.extend(new LatLng(
                el.geo.point.lat,
                el.geo.point.lon
            ))
        })
        this.map.state.map.fitBounds(bounds)
    }

    fitCircleOptions(extraOptions) {
        if (!(extraOptions && extraOptions.center))
            return null
        const orderCenter = new LatLng(
            extraOptions.center.lat,
            extraOptions.center.lon
        )

        const mainRadiusOptions = {
            center: orderCenter,
            strokeColor: '#0a5482',
            strokeWeight: 1,
            fillColor: '#00aef8',
            map: this.map.state.map,
        }

        this.step1Circle = new Circle(mainRadiusOptions)
        this.step2Circle = new Circle(mainRadiusOptions)
        this.step3Circle = new Circle(mainRadiusOptions)

        const centerMarkerOptions = {
            position: orderCenter,
            map: this.map.state.map,
        }
        // @ts-ignore
        new window.google.maps.Marker(centerMarkerOptions)
        this.updateRadii(extraOptions.radius)
        this.map.state.map.setCenter(orderCenter)
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.list, this.props.list))
            this.fitBounds(this.props.list)

        if (
            this.props.extraOptions &&
            this.props.extraOptions.type === 'circle' &&
            !isEqual(prevProps.extraOptions, this.props.extraOptions)
        ) {
            this.fitCircleOptions(this.props.extraOptions)
        }
    }

    onFilterChange = filters => {
        const { query } = this.props

        this.props.replace({
            pathname: window.location.pathname,
            search: queryString.stringify({ ...query, ...filters }),
        })
    }

    updateRadii = (kilometers) => {
        const maxStep1 = kilometers / 3
        const maxStep2 = kilometers / 3 * 2
        const maxStep3 = kilometers
        this.step1Circle.setRadius(maxStep1 * 1000)
        this.step2Circle.setRadius(maxStep2 * 1000)
        this.step3Circle.setRadius(maxStep3 * 1000)
        clearTimeout(this.moveSlider)
        this.moveSlider = setTimeout(() => {
            const r = { circleRadius: kilometers }
            this.onFilterChange(r)
            this.setState(r)
        }, 500)
    }

    mapChanged = () => {
        const { disableFilter } = this.props

        if (disableFilter || this.moveEventDisabled) {
            this.moveEventDisabled = false
            return
        }
        clearTimeout(this.moveEventTimer)
        this.moveEventTimer = setTimeout(() => {
            this.fitBoundsBlocked = true
            this.onFilterChange({
                geo_rect: this.map.state.map.getBounds().toUrlValue(),
            })
        }, 1000)
    }

    mapRef = ref => {
        this.map = ref
    }

    onPinClick = id => {
        this.onFilterChange({
            activeRowId: id,
            sidebarName: 'map',
        })
    }

    render() {
        const {
            list,
            pinLink,
            query,
            extraOptions,
        } = this.props

        return <div>
            {extraOptions && extraOptions.type === 'circle' && <SliderContainer modifiers={[ 'alignCenter' ]}>
                <span>
                    <b>
                        {this.state.circleRadius}
                        {' '}
                        km
                        {' '}
                    </b>
                    {' '}
                </span>
                <SliderWithTooltip
                    onChange={this.updateRadii}
                    tipFormatter={percentFormatter}
                    defaultValue={extraOptions.radius}
                    max={1500}
                />
            </SliderContainer>}
            <MyMapComponent
                containerElement={<MapContainerElement showSlider={extraOptions && extraOptions.type === 'circle'}/>}
                mapElement={<div style={{ height: '100%' }}/>}
                ref={this.mapRef}
                pinLink={pinLink}
                list={list}
                mapChanged={this.mapChanged}
                onPinClick={this.onPinClick}
                selectedId={query.activeRowId}
                extraOptions={extraOptions}
            />
        </div>
    }
}

export default MapSidebar as unknown as ComponentType<PathPropsMapSidebar>
