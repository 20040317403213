import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, ButtonContainer } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Flex } from '../elements/Flex'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import { postponeValidation } from '../../actions/leads'
import { Lead } from '../../reducers/types/entities/Lead'
import { returnLocalTimezone } from '../../utils'

const required = [ value => value ? undefined : 'Required!' ]

interface PostponeValidationModel {
    modalProps: {
        lead: Lead
    }
    isLoading: boolean
    postponeValidation: typeof postponeValidation
    hideModal: typeof hideModal
    submit: typeof submit
}

@connect(
    state => ({
        modalProps: state.modal,
        isLoading: state.pages.leadValidation.isLoading,
    }),
    {
        postponeValidation,
        hideModal,
        submit,
    }
)
class PostponeValidationModal extends PureComponent<PostponeValidationModel> {
    hideModal = () => this.props.hideModal()

    save = () => this.props.submit('postponeValidationForm')

    postponeValidation = async criteria => {
        const leadId = this.props.modalProps.lead.id
        await this.props.postponeValidation(leadId, criteria)
        window.location.href = `/leads/validation?timezone=${returnLocalTimezone()}`
    }

    render() {
        const {
            isLoading,
        } = this.props

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Postpone validation</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="postponeValidationForm"
                    onSubmit={this.postponeValidation}
                >
                    <Flex modifiers={[ 'alignCenter' ]}>
                        <Field
                            label="Postpone validation until"
                            name="date"
                            component={DateTimePickerGroup}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            validate={required}
                        />
                        <ButtonContainer>
                            <Button
                                disabled={isLoading}
                                modifiers={[ 'action', 'mL_2', 'mT_1' ]}
                                onClick={this.save}
                            >
                                Postpone
                            </Button>
                        </ButtonContainer>
                    </Flex>
                </ReduxForm>
            </Modal.Body>
        </Modal>
    }
}

export default PostponeValidationModal as unknown as ComponentType
