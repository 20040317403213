import React, { memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import config from '../../../config'
import styled from 'styled-components'

const FlagNavDropdown = styled(NavDropdown)`a{padding:9px 15px!important;}`

const CountriesDropdown = memo(({ selectedCountry, changeCountry }: {selectedCountry: string, changeCountry}) =>
    <FlagNavDropdown
        noCaret
        eventKey={7}
        title={<img src={`${config.bladeFrontendUrl}/assets/images/flags/${selectedCountry}-32.png`}/>}
        id="basic-nav-dropdown-Country"
    >
        <MenuItem header>Country</MenuItem>
        {config.countries.map(country =>
            <MenuItem eventKey={country.id} key={country.id} onClick={() => changeCountry(country.id)}>
                <img src={`${config.bladeFrontendUrl}/assets/images/flags/${country.id}-16.png`}/>
                {' '}
                {country.name}
            </MenuItem>
        )}
        <MenuItem eventKey="com" onClick={() => changeCountry('com')}>
            <img src={`${config.bladeFrontendUrl}/assets/images/flags/com-16.png`}/>
            {' '}
            International (Experimental)
        </MenuItem>
    </FlagNavDropdown>
)

export default CountriesDropdown
