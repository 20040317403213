import { createSelector } from 'reselect'

const entitiesNotifications = state => state.entities.notifications

const allEmployeeNotificationsId = state => state.subjects.notifications.all.ids

export const allEmployeeNotificationsSelector = createSelector(
    allEmployeeNotificationsId, entitiesNotifications,
    (ids, notifications) => ids.map(id => notifications[id])
)
