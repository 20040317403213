import styled from 'styled-components'
import { Flex } from '../../elements/Flex'
import { messageBackgroundColor, messageBorderColor, messageTextColor } from '../../../utils/variables'

export default styled(Flex)`
    background-color: ${messageBackgroundColor};
    border: 1px solid ${messageBorderColor};
    color: ${messageTextColor};
    font-size: 16px;
    padding: 20px;
    border-radius: 6px;
    width: 100%;
    align-items: center;
`
