import { PureComponent } from 'react'

export default class PageTitle extends PureComponent<{ title: string }> {
    componentDidMount() {
        document.title = this.props.title + ' - Ageras Intranet'
    }
    render() {
        return null
    }
}
