import React, { PureComponent, Fragment, ComponentType, useState } from 'react'
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from 'react-google-maps'
import { savePartner } from '../../../actions/partners'
import { Card } from '../../blocks/Card'
import PartnerMetaData from './PartnerMetaData'
import { BtnHref, BtnLink } from '../../elements/Links'
import { connect } from '../../../utils/connect'
import { Flex } from '../../elements/Flex'
import styled from 'styled-components'
import { Partner } from '../../../reducers/types/entities/Partner'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'

const MapElement = styled.div`
  height: 220px;
`

interface PathPropsCompanyDetailsPartner {
    partner: Partner
    businessUnits: BusinessUnit[]
}

interface CompanyDetailsPartnerModel extends PathPropsCompanyDetailsPartner{
    savePartner: typeof savePartner
}
interface MyMapComponentModel {
    markerPosition: {}
    list: BusinessUnit[]
}

const MyMapComponent = withGoogleMap((props: MyMapComponentModel) => {
    const [ selectedId, onPinClick ] = useState(null)
    return <GoogleMap
        defaultZoom={8}
        defaultCenter={props.markerPosition}
        options={{
            scaleControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: false,
        }}
    >
        <Marker position={props.markerPosition} />
        {props.list.map(el => {
            return <Marker
                key={el.id}
                position={{
                    lat: el.geo.point.lat,
                    lng: el.geo.point.lon,
                }}
                icon={{ url: '/assets/images/bu-pin.svg' }}
                onClick={() => onPinClick(el.id)}
            >
                {el.id === selectedId &&
                <InfoWindow onCloseClick={() => onPinClick(null)}>
                    <div>
                        {el.partner.companyName}
                        <div>
                            {el.geo.address && (el.geo.address + ', ')}
                            {el.geo.zipCode}
&nbsp;
                            {el.geo.cityName}
                        </div>
                    </div>
                </InfoWindow>
                }
            </Marker>
        })}
    </GoogleMap>
})

@connect(null,  { savePartner })
class CompanyDetailsPartnerCard extends PureComponent<CompanyDetailsPartnerModel> {
    render() {
        const { partner, businessUnits } = this.props

        const markerPosition = { lat: partner.geo.point.lat, lng: partner.geo.point.lon }

        return (
            <Card className="companyDetailsPartnerCard">
                <Card.Header>Company Details</Card.Header>
                <Card.Content>
                    <MyMapComponent
                        containerElement={<MapElement/>}
                        mapElement={<MapElement/>}
                        markerPosition={markerPosition}
                        list={businessUnits}
                    />
                    <br />
                    <Fragment>
                        <PartnerMetaData partner={partner}/>
                        <Flex>
                            <BtnLink
                                modifiers={[ 'secondary', 'fullWidth', 'p_1', 'btnBlock', 'mR_2' ]}
                                to={'/partners/' + partner.id + '/company-details'}
                            >
                                Edit partner details
                            </BtnLink>
                            <BtnHref
                                modifiers={[ 'secondary', 'fullWidth', 'p_1', 'btnBlock' ]}
                                href={'/partners/' + partner.id + '/contact-archive'}
                            >
                                View contact archive
                            </BtnHref>
                        </Flex>
                    </Fragment>
                </Card.Content>
            </Card>
        )
    }
}

export default CompanyDetailsPartnerCard as unknown as ComponentType<PathPropsCompanyDetailsPartner>
