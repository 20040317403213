import React, { memo, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Card } from '../../blocks/Card'
import { Flex } from '../../elements/Flex'
import { metaTextColor } from '../../../utils/variables'
import GeoRegionsMap from '../../elements/GeoRegionsMap'
import { FormattedAmount } from '../../elements/FormattedAmount'

const MapElement = styled.div`
  height: 86vh;
`

const Ul = styled.ul`
  padding: 0;
  li {
    font-size: 13px;
    line-height: 22px;
    list-style: none;
    color: ${metaTextColor};
    &:before {
        content: '* ';
    }
  }
`

const Text = styled.span`
    font-size: 13px;
    line-height: 22px;
    color: ${metaTextColor};
`

const PreviewMode = ({
    businessUnit,
    clientTypes,
    sectors,
    industries,
    geoRegions,
    subGeoRegions,
    hasLBModel,
    revenueSegment,
}) => {
    const revenueRange = revenueSegment ? {
        minimumRevenue: revenueSegment.minimumRevenue,
        maximumRevenue: revenueSegment.maximumRevenue,
    } : {
        minimumRevenue: 0,
        maximumRevenue: 99999999999,
    }

    const quotas = businessUnit.allocationQuota ? [ businessUnit.allocationQuota ] : []
    const ppsModel = businessUnit.allocationLimit

    return (
        <Row>
            <Col md={12} lg={6}>
                <Card.Content>
                    <Row>
                        {hasLBModel && <Col md={3}>
                            <Card.Label><strong>LB quota</strong></Card.Label>
                            {quotas.map((quota, index) => (
                                <Flex modifiers="justifySpaceBetween" key={index}>
                                    <Text>
                                        {quota.count}
                                    </Text>
                                </Flex>
                            ))}
                        </Col>}
                        <Col md={3}>
                            <Card.Label><strong>Revenue range</strong></Card.Label>
                            <Text>
                                {revenueRange && revenueRange.minimumRevenue + ' - ' + revenueRange.maximumRevenue}
                            </Text>
                        </Col>
                        {ppsModel &&
                        <Fragment>
                            <Col md={2}>
                                <Card.Label><strong>PPS limit</strong></Card.Label>
                                <Text>{ppsModel.count}</Text>
                            </Col>
                            <Col md={2}>
                                <Card.Label><strong>PPS price</strong></Card.Label>
                                <Text><FormattedAmount amount={ppsModel.priceExclVat}/></Text>
                            </Col>
                        </Fragment>
                        }
                    </Row>
                    <Card.Line modifiers={[ 'mY_2' ]}/>
                    <Row>
                        <Col md={3}>
                            <Card.Label><strong>Lead type</strong></Card.Label>
                            <Ul>
                                {businessUnit.clientTypes.map(clientType =>
                                    <li key={'clientType_' + clientType.id}>{clientTypes[clientType.id].title}</li>
                                )}
                            </Ul>
                        </Col>
                        <Col md={3}>
                            <Card.Label><strong>Services</strong></Card.Label>
                            <Ul>
                                {businessUnit.industries.map(industry =>
                                    <li key={'leadType_' + industry.id}>{industries[industry.id].name}</li>
                                )}
                            </Ul>
                        </Col>
                        <Col md={3}>
                            <Card.Label><strong>Sectors</strong></Card.Label>
                            <Ul>
                                {businessUnit.sectors.map(sector =>
                                    <li key={'sector_' + sector.id}>{sectors[sector.id].displayName}</li>
                                )}
                            </Ul>
                        </Col>
                        <Col md={3}>
                            <Card.Label><strong>Regions</strong></Card.Label>
                            <Ul>
                                {businessUnit.geoRegions.map(geoRegion =>
                                    <li key={'geoRegion_' + geoRegion.id}>{geoRegions[geoRegion.id].regionName}</li>
                                )}
                            </Ul>
                        </Col>
                    </Row>
                </Card.Content>
            </Col>
            <Col md={12} lg={6}>
                <GeoRegionsMap
                    subGeoRegions={subGeoRegions}
                    geoRegions={geoRegions}
                    containerElement={<MapElement/>}
                    mapElement={<MapElement/>}
                    showBusinessUnits={true}
                    businessUnits={[ businessUnit ]}
                    zoomPoint={businessUnit.geo.point}
                    zoom={7}
                />
            </Col>
        </Row>
    )
}

export default memo(PreviewMode)
