import React, { memo } from 'react'
import Cell from './Cell'
import { ProfilePicture } from '../../ProfilePicture'
import { Employee } from '../../../../reducers/types/entities/Employee'

interface EmployeeCellProps {
    employee: Partial<Employee>
    portrait?: boolean
    colSpan?: number
}

export default memo(({ employee, portrait = true, colSpan = 1 }: EmployeeCellProps) => {
    if (!employee) {
        return <Cell />
    }
    return (
        <Cell colSpan={colSpan} title={`${employee.name} <${employee.email}>`}>
            {portrait && <ProfilePicture
                src={employee.image}
                name={employee.name}
                hash={employee.initials}
                size={32}
                modifiers="mR_1"
            />}
            <span style={{
                lineHeight: '32px',
                textTransform: 'uppercase',
            }}
            >
                {employee.initials}
            </span>
        </Cell>
    )
})
