import React, { memo } from 'react'
import { Cell, Column, Header, Rows, Table } from '../../elements/table'
import { Card } from '../../blocks/Card'
import LinkCell from '../../elements/table/cells/LinkCell'

const LeadRow = memo(({ rowData }: {rowData?: {lead}}) => {
    const { lead } = rowData
    return <tr key={lead.id}>
        <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
        <Cell>{lead.fullName}</Cell>
        <Cell>{lead.title}</Cell>
        <Cell>
            {lead.zipCode} 
            {' '}
            {lead.city}
        </Cell>
    </tr>
})

const LeadsTable = ({ leads }) =>
    <Card.Content>
        <Table>
            <Header>
                <Column>id</Column>
                <Column>Name</Column>
                <Column>Title</Column>
                <Column>Location</Column>
            </Header>
            <Rows
                items={leads}
                noDataText="No leads"
            >
                <LeadRow />
            </Rows>
        </Table>
    </Card.Content>

export default memo(LeadsTable)
