import React, { PureComponent } from 'react'
import {
    getInvoiceRefundRequest,
    acceptRefundRequest,
    rejectRefundRequest,
} from '../../actions/invoices'
import { Col, Label, Row } from 'react-bootstrap'
import { FormattedAmount } from '../elements/FormattedAmount'
import { Button } from '../elements/Button'
import { replace } from 'connected-react-router'
import { getPartner, getPartnerFees, savePartner } from '../../actions/partners'
import {
    partnerByRefundRequestSelector,
    partnerFeesSelector,
    partnerQuotesByRefundRequestSelector,
} from '../../selectors/partner'
import { PartnerStatus } from '../partners/PartnerStatus'
import Placeholder from '../elements/Placeholder'
import { Card } from '../blocks/Card/index'
import { BtnLink } from '../elements/Links'
import FinancePartnerCard from '../partners/detailPage/FinancePartnerCard'
import { Link } from 'react-router-dom'
import FormattedDate from '../elements/FormattedDate'
import { connect } from '../../utils/connect'
import { InvoiceRefundRequest } from '../../reducers/types/entities/InvoiceRefundRequest'
import { Invoice } from '../../reducers/types/entities/Invoice'
import { Partner } from '../../reducers/types/entities/Partner'
import { showModal } from '../../actions/modal'
import { getPartnerSubscriptions } from '../../actions/subscriptions'
import { getPartnerQuotes } from '../../actions/quotes'
import { subscriptionsSelector } from '../../selectors/subscription'
import { Quote } from '../../reducers/types/entities/Quote'
import { Subscription } from '../../reducers/types/entities/Subscription'
import { PartnerFee } from '../../reducers/types/entities/PartnerFee'
import PartnerUsersCard from '../partners/detailPage/PartnerUsersCard'
import { getInvoices } from '../../state/invoices/actions'
import { allInvoicesSelector } from '../../state/invoices/selectors'

// According translation in partner portal
const refundsRequestReason = {
    no_contact: 'Contact with the client was not possible through chat, email nor phone.',
    company_closed: 'The client\'s company has seized to exist.',
    size_changed: 'The volume of work changed significantly.',
    task_changed: 'The character of the assignment changed significantly.',
    client_reconsidered: 'The client changed his/her mind.',
}

interface RefundRequestStateProps {
    refundRequest: InvoiceRefundRequest
    invoices: Invoice[]
    givenQuotes: Quote[]
    partner: Partner
    subscriptions: Subscription[]
    isLoadingPartner: boolean
    partnersCreatePermission: any
    partnerFees: PartnerFee[]
}

interface RefundRequestDispatchProps {
    getInvoiceRefundRequest: typeof getInvoiceRefundRequest
    getPartnerSubscriptions: typeof getPartnerSubscriptions
    rejectRefundRequest: typeof rejectRefundRequest
    acceptRefundRequest: typeof acceptRefundRequest
    getPartnerQuotes: typeof getPartnerQuotes
    getPartnerFees: typeof getPartnerFees
    savePartner: typeof savePartner
    getInvoices: typeof getInvoices
    getPartner: typeof getPartner
    showModal: typeof showModal
    replace: typeof replace
}

interface RefundRequestOwnProps {
    match: {
        params: {
            refundRequestId: number
            partnerId: number
        }
    }
}

type RefundRequestDetailPageModel = RefundRequestStateProps & RefundRequestDispatchProps & RefundRequestOwnProps

@connect<RefundRequestStateProps, RefundRequestDispatchProps, RefundRequestOwnProps>(
    (state, { match: { params } }) => ({
        refundRequest: state.entities.invoiceRefundRequests[params.refundRequestId],
        givenQuotes: partnerQuotesByRefundRequestSelector(state, params.refundRequestId),
        partner: partnerByRefundRequestSelector(state, params.refundRequestId),
        partnersCreatePermission: state.entities.permissions.partners_create,
        partnerFees: partnerFeesSelector(state, params.partnerId),
        isLoadingPartner: state.pages.partnerDetail.isLoading,
        subscriptions: subscriptionsSelector(state, params.partnerId),
        invoices: allInvoicesSelector(state),
    }),
    {
        getInvoiceRefundRequest,
        getPartnerSubscriptions,
        rejectRefundRequest,
        acceptRefundRequest,
        getPartnerQuotes,
        getPartnerFees,
        savePartner,
        getInvoices,
        getPartner,
        showModal,
        replace,
    }
)
export default class RefundRequestDetailPage extends PureComponent<RefundRequestDetailPageModel> {
    state = {
        modalProps: {},
    }

    componentDidMount() {
        (this.props.getInvoiceRefundRequest(this.props.match.params.refundRequestId) as any)
            .then(res => {
                const partnerId = this.props.refundRequest.partner.id
                this.props.getInvoices({
                    sort: '-created_at',
                    partnerId,
                })
                this.props.getPartner(partnerId)
                this.props.getPartnerSubscriptions(partnerId, { isActive: 1 })
                this.props.getPartnerQuotes({ partnerId, limit: 1000 })
                this.props.getPartnerFees(partnerId)
            })
            .catch(err => {
                this.props.replace('/refund-requests')
            })
    }

    rejectRefundRequest = () => {
        const { refundRequest } = this.props

        this.props.showModal({
            name: 'rejectRefundRequestModel',
            invoiceId: refundRequest.invoice.id,
            requestId: refundRequest.id,
        })
    }

    acceptRefundRequest = () => {
        const { refundRequest } = this.props

        this.props.showModal({
            name: 'acceptRefundRequestModel',
            invoiceId: refundRequest.invoice.id,
            requestId: refundRequest.id,
        })
    }

    editPartnerFee = () => {
        this.props.showModal({
            name: 'editPartnerFeeModel',
            partnerId: this.props.partner.id,
        })
    }

    render() {
        const {
            partnersCreatePermission,
            isLoadingPartner,
            refundRequest,
            subscriptions,
            givenQuotes,
            partnerFees,
            invoices,
            partner,
        } = this.props

        const hideActionBlock = [ 'accepted', 'rejected' ].includes(refundRequest && refundRequest.status)

        return <Card.Content>
            <Card>
                <Card.Header>
                    Refund request details: #
                    {refundRequest && refundRequest.id}
                </Card.Header>
                <Card.Content>
                    {refundRequest ?
                        <Row>
                            <Col md={hideActionBlock ? 12 : 9}>
                                <Row>
                                    <Col md={6}>
                                        <Card.Label>VOUCHER ID</Card.Label>
                                        {refundRequest.invoice && <Card.Text>
                                            <a href={'/vouchers/' + refundRequest.invoice.id} target="_blank" rel="noreferrer">
                                                <strong>{refundRequest.invoice.id}</strong>
                                            </a>
                                        </Card.Text>}
                                        <Card.Label>PARTNER NAME</Card.Label>
                                        <Card.Text>
                                            <Link to={'/partners/' + refundRequest.partner.id} target="_blank">
                                                <strong>{refundRequest.partner.companyName}</strong>
                                            </Link>
                                        </Card.Text>
                                        <Card.Label>TOTAL ORIGINAL FEE</Card.Label>
                                        {refundRequest.invoice && <Card.Text>
                                            <FormattedAmount
                                                amount={refundRequest.invoice.totalAmountExclVat}
                                                format="00.00"
                                            />
                                        </Card.Text>}
                                        <Card.Label>TIME OF ORIGINAL INVOICE</Card.Label>
                                        {refundRequest.invoice && <Card.Text>
                                            <FormattedDate date={refundRequest.invoice.createdAt} />
                                        </Card.Text>}
                                        <Card.Label>STATUS</Card.Label>
                                        <Card.Text>
                                            <Label
                                                bsStyle={(refundRequest.status === 'rejected'
                                                    ? 'danger'
                                                    : refundRequest.status === 'accepted'
                                                        ? 'info'
                                                        : 'default'
                                                )}
                                            >
                                                {refundRequest.status}
                                            </Label >
                                        </Card.Text>
                                        <Card.Label>REQUEST CREATED AT</Card.Label>
                                        <Card.Text><FormattedDate date={refundRequest.createdAt} /></Card.Text>
                                        <Card.Label>Request refund amount:</Card.Label>
                                        <Card.Text>
                                            <FormattedAmount
                                                amount={refundRequest.requestedAmountExclVat}
                                                format="00.00"
                                            />
                                        </Card.Text>
                                        <Card.Label>Reason for the refund request:</Card.Label>
                                        <Card.Text>{refundsRequestReason[refundRequest.requestReason]}</Card.Text>
                                        <Card.Label>Additional information from the partner</Card.Label>
                                        <Card.Text>{refundRequest.partnerRequestDescription}</Card.Text>
                                    </Col>
                                    <Col md={6}>
                                        <Card.Label>Documentation:</Card.Label>
                                        <br />
                                        <br />
                                        {refundRequest.documentationUrl ?
                                            <BtnLink
                                                to={refundRequest.documentationUrl}
                                                modifiers={[ 'primary', 'btnLg' ]}
                                                target="_blank"
                                            >
                                                Download documentation
                                            </BtnLink> :
                                            <Card.Text>No Documentation</Card.Text>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            {hideActionBlock || <Col md={3}>
                                <Button
                                    modifiers={[ 'primary', 'btnBlock', 'btnLg' ]}
                                    onClick={this.acceptRefundRequest}
                                >
                                    Accept Refund Request
                                </Button>
                                <br />
                                <Button
                                    modifiers={[ 'danger', 'btnBlock', 'btnLg' ]}
                                    onClick={this.rejectRefundRequest}
                                >
                                    Reject Refund Request
                                </Button>
                            </Col>}
                        </Row> :
                        <Placeholder />
                    }
                </Card.Content>
            </Card>
            {isLoadingPartner || !refundRequest ?
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header>Partner information...</Card.Header>
                            <Card.Content>
                                <Placeholder />
                            </Card.Content>
                        </Card>
                    </Col>
                </Row> :
                partner && <Row>
                    {[ 'active', 'inactive', 'disabled', 'ex_partner' ].includes(partner.state) &&
                    <Col md={4}>
                        {subscriptions && givenQuotes && <PartnerStatus
                            hidePartnerPayment={false}
                            subscriptions={subscriptions}
                            partner={partner}
                            partnerFee={partnerFees[0]}
                            partnersCreatePermission={partnersCreatePermission}
                            lastGivenQuote={givenQuotes[0]}
                            totalQuotes={givenQuotes.length}
                            editPartnerFee={this.editPartnerFee}
                        />}
                    </Col>}
                    <Col md={4}>
                        <PartnerUsersCard partnerId={partner.id} geoCode={partner.geo.code}/>
                    </Col>
                    {partner.state !== 'demo' && partner.kpi &&
                    <Col md={4}>
                        <FinancePartnerCard partner={partner} invoices={invoices} />
                    </Col>}
                </Row>
            }
        </Card.Content>
    }
}
