import React, { memo } from 'react'
import { AmountCell, Cell, Column, DateCell, Header, Rows, Table } from '../../elements/table'
import { Card } from '../../blocks/Card'
import { Invoice } from '../../../reducers/types/entities/Invoice'
import LinkCell from '../../elements/table/cells/LinkCell'

const InvoiceRow = memo(({ rowData }: {rowData?: Invoice}) => {
    const invoice = rowData
    return <tr key={invoice.id}>
        <Cell><a href={'vouchers/' + invoice.id}>{invoice.id}</a></Cell>
        <Cell><a href={'vouchers/' + invoice.id}>{invoice.invoiceNumber}</a></Cell>
        <LinkCell to={'partners/' + invoice.partnerId}>{invoice.partnerData ? invoice.partnerData.companyName : invoice.partnerId}</LinkCell>
        <Cell>{invoice.partnerData ? invoice.partnerData.contactPerson : ''}</Cell>
        <AmountCell amount={invoice.amounts.totalExcludingVat} />
        <DateCell date={invoice.createdAt} format="datetime"/>
        <DateCell date={invoice.dueAt} format="datetime"/>
        <DateCell date={invoice.paidAt} format="datetime"/>
    </tr>
})

const InvoicesTable = ({ invoices }) =>
    <Card.Content>
        <Table>
            <Header>
                <Column>id</Column>
                <Column>Voucher number</Column>
                <Column>Partner</Column>
                <Column>Contact person</Column>
                <Column>Sum</Column>
                <Column>Created at</Column>
                <Column>Deadline</Column>
                <Column>Paid at</Column>
            </Header>
            <Rows
                items={invoices}
                noDataText="No invoices"
            >
                <InvoiceRow />
            </Rows>
        </Table>
    </Card.Content>

export default memo(InvoicesTable)
