import { toastr } from 'react-redux-toastr'
import { SHOW_ERROR, LOG_ERROR } from '../actions/errors'

export default () => next => action => {
    if (action.type && (action.type === SHOW_ERROR || action.type.split('_').pop() === 'FAIL'))
        toastr.error('Error!', action.error || action.type)
    else if (action.type && action.type === LOG_ERROR)
        console.error(action.error, action.type)
    return next(action)
}
