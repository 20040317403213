import React, { memo } from 'react'
import Cell, { NestedTableCell } from './Cell'
import FormattedDate from '../../FormattedDate'
import { MomentInput } from 'moment'

interface DateCellModel {
    date: string | MomentInput
    format?: string
    colSpan?: number
    nested?: boolean
    withTitle?: boolean
}

export default memo(({ date, format = 'date', colSpan = 1, nested = false, withTitle = false }: DateCellModel) => {
    if (!nested) {
        return date
            ? <Cell colSpan={colSpan}><FormattedDate date={date} format={format} withTitle={withTitle}/></Cell>
            : <Cell colSpan={colSpan}/>
    }
    return date
        ? <NestedTableCell colSpan={colSpan}><FormattedDate date={date} format={format} withTitle={withTitle}/></NestedTableCell>
        : <NestedTableCell colSpan={colSpan}/>
})
