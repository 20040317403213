import React, { ComponentType, PureComponent } from 'react'
import { Field, reset, SubmissionError, submit } from 'redux-form'
import { Flex } from '../elements/Flex'
import { Button } from '../elements/Button'
import { TextareaGroup } from '../elements/forms/inputs/TextareaGroup'
import styled from 'styled-components'
import ReduxForm from '../elements/forms/ReduxForm'
import { createLeadNoteActivity, createPartnerNoteActivity, getNewsFeed } from '../../actions/newsfeed'
import SatisfactionSelectField from '../elements/forms/inputs/SatisfectionSelectField'
import { connect } from '../../utils/connect'

const TextNoteGroup = styled(TextareaGroup)`
    margin-right: 10px;
`

interface AddNoteStateProps {
    employeeId: number
}

interface AddNoteDispatchProps {
    createPartnerNoteActivity: typeof createPartnerNoteActivity
    createLeadNoteActivity: typeof createLeadNoteActivity
    getNewsFeed: typeof getNewsFeed
    submit: typeof submit
    reset: typeof reset
}

interface AddNotePathProps {
    selectedTab: string
    stateName: string
    entityId: number
}

type AddNoteModel = AddNoteStateProps & AddNoteDispatchProps & AddNotePathProps

@connect<AddNoteStateProps, AddNoteDispatchProps, AddNotePathProps>(
    state => ({
        employeeId: state.auth.employeeId,
    }),
    {
        createPartnerNoteActivity,
        createLeadNoteActivity,
        getNewsFeed,
        submit,
        reset,
    }
)
class AddNoteForm extends PureComponent<AddNoteModel> {
    save = async values => {
        if (!values.note) throw new SubmissionError({ note: 'Required!' })

        const tabIndex = this.props.selectedTab === 'feed' ? '' : this.props.selectedTab

        const noteValues = {
            employeeId: this.props.employeeId,
            ...values,
        }

        let idNaming = ''

        if(this.props.stateName === 'leadDetail') {
            await this.props.createLeadNoteActivity(this.props.entityId, noteValues)
            idNaming = 'leadId'
        } else {
            await this.props.createPartnerNoteActivity(this.props.entityId, noteValues)
            idNaming = 'partnerId'
        }

        // delay because of elastic search indexing
        await setTimeout(() => this.props.getNewsFeed({
            [idNaming]: this.props.entityId,
            type: tabIndex,
            sort: '-is_pinned',
        }), 1000)

        this.props.reset('createNoteForm')
    }

    submitNoteForm = () => {
        this.props.submit('createNoteForm')
    }

    render() {
        return (
            <ReduxForm
                form="createNoteForm"
                onSubmit={this.save}
            >
                <Flex>
                    <Field
                        name="note"
                        component={TextNoteGroup}
                        placeholder="Add note..."
                        removeClassFormGroup={true}
                    />
                    {this.props.stateName === 'partnerDetail' &&
                    <Field
                        name="satisfaction.satisfactionLevelId"
                        component={SatisfactionSelectField}
                    />}
                    <div>
                        <Button
                            onClick={this.submitNoteForm}
                            modifiers={[ 'primary', 'p_1', 'nowrap', 'mL_1' ]}
                        >
                            Add note
                        </Button>
                    </div>
                </Flex>
            </ReduxForm>
        )
    }
}

export default AddNoteForm as unknown as ComponentType<AddNotePathProps>
