import React from 'react'
import { Flex } from './Flex'
import Tab, { TabModel } from './Tab'
import { dangerColor } from '../../utils/variables'

interface TabsModel<T> {
    errorTabIds?: T[]
    tabs: TabModel<T>[],
    changeTab: (tabId) => void
    selectedTab
    onClose?: (tabId) => void
}

const Tabs = ({
    errorTabIds,
    tabs,
    changeTab,
    selectedTab,
    onClose,
}: TabsModel<any>) =>
    <Flex modifiers={[ 'alignCenter' ]}>
        {tabs.map(el =>
            <Tab
                key={`tab_${el.tabId}`}
                tabId={el.tabId}
                tabName={el.tabName}
                responsiveModifiers={{ active: [ 'active' ] }}
                modifiers={
                    Array.isArray(errorTabIds) && errorTabIds.includes(el.tabId) ? [ 'error' ] : []
                }
                size={selectedTab === el.tabId ? 'active' : ''}
                onClick={() => el.disabled || changeTab(el.tabId)}
            >
                <strong>{el.tabName}</strong>
                {Boolean(onClose) && selectedTab === el.tabId &&
                <div
                    onClick={e => {
                        e.stopPropagation()
                        onClose(el.tabId)
                    }}
                    style={{
                        minHeight: '16px',
                        minWidth: '16px',
                        color: '#fff',
                        backgroundColor: dangerColor,
                        lineHeight: '16px',
                        textAlign: 'center',
                        borderRadius: '3px',
                        marginLeft: '10px',
                    }}
                >
                    x
                </div>
                }
            </Tab>
        )}
    </Flex>

export default Tabs
