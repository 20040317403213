import { InitialAction } from '../types/InitialAction'
import {
    CLEANUP_MATCH_REPORT,
    GET_MATCH_REPORTS,
    GET_MATCH_REPORTS_SUCCESS,
    GET_MATCH_REPORTS_FAIL,
} from '../../actions/businessUnit'

export interface MatchReportState {
    isLoading: boolean
    matches: {},
}

interface MatchReportAction extends InitialAction {
    criteria: string
}

const initialState: MatchReportState = {
    isLoading: null,
    matches: {},
}

export default function reducer(
    state = initialState,
    action: MatchReportAction
) {
    switch (action.type) {
        case GET_MATCH_REPORTS:
            return {
                ...state,
                isLoading: true,
            }
        case  CLEANUP_MATCH_REPORT:
            return initialState
        case GET_MATCH_REPORTS_SUCCESS:
            const matches = {}
            action.response.result.forEach(id => {
                matches[id] = (state.matches[id]|| []).concat(action.criteria)
            })
            return {
                matches: {
                    ...state.matches,
                    ...matches,
                },
                isLoading: false,
            }
        case GET_MATCH_REPORTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
