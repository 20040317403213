import React, { PureComponent, ComponentType } from 'react'
import { connect } from '../../../utils/connect'
import { Card } from '../../blocks/Card'
import { Col, Row } from 'react-bootstrap'
import ActionCheckBox from '../../elements/ActionCheckBox'
import { toastr } from 'react-redux-toastr'
import { allCertificationsSelector } from '../../../state/certifications/selectors'
import {
    getCertifications,
    addPartnerCertification,
    getPartnerCertifications,
    removePartnerCertification,
} from '../../../state/certifications/actions'

interface StateProps {
    partnerCertificationIds,
    allCertification
}
interface DispatchProps {
    removePartnerCertification: typeof removePartnerCertification
    getPartnerCertifications: typeof getPartnerCertifications
    addPartnerCertification: typeof addPartnerCertification
    getCertifications: typeof getCertifications
}
interface PathProps {
    geoCode: string
    partnerId: number
    industryId: number
}

type PartnerCertificationsModel = StateProps & DispatchProps & PathProps

@connect<StateProps, DispatchProps, PathProps>(
    state => ({
        partnerCertificationIds: state.subjects.certifications.partner.ids,
        allCertification: allCertificationsSelector(state),
    }),
    {
        removePartnerCertification,
        getPartnerCertifications,
        addPartnerCertification,
        getCertifications,
    }
)
class PartnerCertifications extends PureComponent<PartnerCertificationsModel> {
    componentDidMount() {
        const { partnerId, geoCode, industryId } = this.props
        this.props.getCertifications({ geoCode, industryId })
        this.props.getPartnerCertifications(partnerId)

    }

    handleCertificationChange = async (certificationId, checked) => {
        const { partnerId } = this.props
        try {
            if (checked) {
                await this.props.addPartnerCertification(partnerId, certificationId)
                toastr.success('Certification was added')
            } else {
                await this.props.removePartnerCertification(partnerId, certificationId)
                toastr.success('Certification was delete')
            }
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        const {
            allCertification,
            partnerCertificationIds,
        } = this.props
        return <Card>
            <Card.Header>Partner certifications</Card.Header>
            <Card.Content>
                <Row>
                    {allCertification.map(cer => {
                        const checked = partnerCertificationIds.includes(cer.id)
                        return <Col md={4} key={'cer_' + cer.id}>
                            <ActionCheckBox
                                onClick={this.handleCertificationChange}
                                value={cer.id}
                                checked={checked}
                                text={cer.title}
                            />
                        </Col>
                    })}
                </Row>
            </Card.Content>
        </Card>
    }
}

export default PartnerCertifications as unknown as ComponentType<PathProps>
