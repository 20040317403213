import React from 'react'
import { default as ReactSelect, components } from 'react-select'

const selectStyles = {
    control: () => ({
        display: 'flex',
        width: '100%',
        height: '40px',
        fontSize: '14px',
        lineHeight: '1.42857',
        color: '#555555',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
    }),
    placeholder: () => ({
        color: '#555',
    }),
    valueContainer: (provided) => ({
        ...provided,
        overflowX: 'hidden',
        overflowY: 'scroll',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#A7B7BE' : null,
        cursor: 'pointer',
    }),
}

export const MultiSelect = props => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                {...props}
                styles={selectStyles}
                options={[ props.allOption, ...props.options ]}
                onChange={(selected, event) => {
                    if (selected !== null && selected.length > 0) {
                        if (selected[selected.length - 1].value === props.allOption.value) {
                            return props.onChange([ props.allOption, ...props.options ])
                        }
                        let result = []
                        if (selected.length === props.options.length) {
                            if (selected.includes(props.allOption)) {
                                result = selected.filter(
                                    option => option.value !== props.allOption.value
                                )
                            } else if (event.action === 'select-option') {
                                result = [ props.allOption, ...props.options ]
                            }
                            return props.onChange(result)
                        }
                    }

                    return props.onChange(selected)
                }}
            />
        )
    }

    return <ReactSelect {...props} styles={selectStyles} />
}

export const allOption = {
    label: 'Select all',
    value: '*',
}

MultiSelect.defaultProps = {
    allOption,
}

export const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue()
    let toBeRendered = children
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [ [ children[0][0] ], children[1] ]
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    )
}

export const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}, `
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = 'All is selected'
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    )
}
