import React, { ComponentType, PureComponent } from 'react'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import { acceptRefundRequest } from '../../actions/invoices'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
const required = [ value => value ? undefined : 'Required!' ]

interface RefundRequestAcceptModel {
    modalProps: {
        disabled: boolean,
        invoiceId: number
        requestId: number
    }
    acceptRefundRequest: typeof acceptRefundRequest
    hideModal: typeof hideModal
    submit: typeof submit
}

@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        acceptRefundRequest,
        hideModal,
        submit,
    }
)
class RefundRequestAcceptModal extends PureComponent<RefundRequestAcceptModel> {
    hideModal = () => this.props.hideModal()

    save = () => this.props.submit('acceptRefundRequest')

    acceptRefundRequest = data => {
        const { invoiceId, requestId } = this.props.modalProps

        this.props.acceptRefundRequest({
            ...data,
            invoiceId,
            requestId,
        })
    }


    render() {
        const { modalProps } = this.props

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Accept refund request</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="acceptRefundRequest"
                    onSubmit={this.acceptRefundRequest}
                >
                    <Field
                        component={FieldGroup}
                        validate={required}
                        name="acceptedAmountExclVat"
                        placeholder="Enter accepted amount"
                        label="Are you sure, that you want to accept the refund request ?"
                        type="number"
                    />
                    <Field
                        component={FieldGroup}
                        validate={required}
                        name="decisionReason"
                        placeholder="Reason"
                        label="Please add a reason why you are accepting the request:"
                    />
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    modifiers={[ 'secondary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.hideModal}
                >
                    No
                </Button>
                {' '}
&nbsp;
                <Button
                    modifiers={[ 'action' ]}
                    disabled={modalProps.disabled}
                    onClick={this.save}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default RefundRequestAcceptModal as unknown as ComponentType
