import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, FormSection, submit } from 'redux-form'
import { Button } from '../elements/Button'
import RadioGroup from '../elements/forms/inputs/RadioGroup'
import { Flex } from '../elements/Flex'
import { connect } from '../../utils/connect'
import { hideModal } from '../../actions/modal'
import { leadActionRecordRejection } from '../../actions/leads'
import { rejectQuotes } from '../../actions/quotes'
import { Quote } from '../../reducers/types/entities/Quote'

interface QuotesRejectModel {
    modalProps: {
        leadId: number
        disabled: boolean
        quotes: Quote[],
    }
    leadActionRecordRejection: typeof leadActionRecordRejection
    rejectQuotes: typeof rejectQuotes
    hideModal: typeof hideModal
    submit: typeof submit
}

@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        leadActionRecordRejection,
        rejectQuotes,
        hideModal,
        submit,
    }
)
export class QuotesRejectModal extends PureComponent<QuotesRejectModel> {
    hideModal = () => this.props.hideModal()

    save = () => this.props.submit('leadRejectReasonForm')

    rejectAllQuotes = async quoteRejectedReasons => {
        const leadId = this.props.modalProps.leadId
        const rejectionReasons = Object.keys(quoteRejectedReasons)
            .map(quoteId => ({ ...quoteRejectedReasons[quoteId], quoteId }))

        await this.props.rejectQuotes(leadId)
        await this.props.leadActionRecordRejection(leadId, rejectionReasons)
    }

    render() {
        const {
            modalProps,
        } = this.props

        const rejectQuoteReasons = [
            'Missing expertise (experience / knowledge)',
            'Missing personal chemistry',
            'No contact with partner',
            'Unsatisfying process handling by the partner',
            'Wrong geographic location',
            'Price',
            'Chose an expert recommended by own network instead',
            'No need for the service anymore',
        ]

        const reasonsFields = rejectQuoteReasons.map(reason => ({ id: reason, name: reason }))

        return <Modal
            bsSize="large"
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Reject all quotes</Modal.Header>
            <Modal.Body>
                <h3 className="text-center">Why did this lead not choose any of the partners provided by Ageras ?</h3>
                <br />
                <ReduxForm
                    form="leadRejectReasonForm"
                    onSubmit={this.rejectAllQuotes}
                >
                    <Flex modifiers={[ 'justifySpaceAround' ]}>
                        {modalProps.quotes.map((quote, i) =>
                            <div key={'leadRejectReason_' + i}>
                                <h4>
                                    <strong>
                                        Partner
                                        {i + 1}
                                        :
                                        {quote.partner.companyName}
                                    </strong>
                                </h4>
                                <br />
                                <FormSection name={String(quote.id)}>
                                    <Field
                                        component={RadioGroup}
                                        options={reasonsFields}
                                        name="reason"
                                    />
                                </FormSection>
                            </div>
                        )}
                    </Flex>
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    modifiers={[ 'secondary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.hideModal}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    modifiers={[ 'primary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.save}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default QuotesRejectModal as unknown as ComponentType

