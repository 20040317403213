import React, { PureComponent, ComponentType } from 'react'
import { Field, change } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import { Card } from '../../blocks/Card'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import { AsyncTypeaheadGroup } from '../../elements/forms/inputs/AsyncTypeaheadGroup'
import { locationSelector } from '../../../selectors/locations'
import { getLocations } from '../../../actions/locations'
import { connect } from '../../../utils/connect'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Location } from '../../../reducers/types/entities/Location'
import styled from 'styled-components'
import { isLoanLead } from '../../../utils/isLoanLead'

interface LeadDetailsStateProps {
    locations: Location[]
    isLoadingLocations: boolean
}
interface LeadDetailsDispatchProps {
    getLocations: typeof getLocations
    change: typeof change
}
interface LeadDetailsPathProps {
    lead: Lead
    showPhoneNumber: boolean
}

type LeadDetailsModel = LeadDetailsStateProps & LeadDetailsDispatchProps & LeadDetailsPathProps

interface LeadDetailsStateModel {
    address
    initZipCode
}

const Sup = styled.sup`
    color: #e63637;
    font-size: 115%;
    vertical-align: middle;
`

@connect<LeadDetailsStateProps, LeadDetailsDispatchProps, LeadDetailsPathProps>(
    state => ({
        locations: locationSelector(state),
        isLoadingLocations: state.pages.locationFilter.isLoading,
    }),
    {
        getLocations,
        change,
    }
)
class LeadDetailsCard extends PureComponent<LeadDetailsModel, LeadDetailsStateModel> {
    constructor(props) {
        super(props)
        this.state = {
            address: props.lead.geo.address,
            initZipCode: [ {
                zipCode: props.lead.geo.zipCode,
                cityName: props.lead.geo.cityName,
            } ],
        }
    }

    async componentDidMount() {
        if (!this.props.lead.geo.zipCode) {
            return
        }
        const res = await this.props.getLocations({ zipCode: this.props.lead.geo.zipCode })
        // @ts-ignore
        const location = res.entities.locations[res.result[0]]

        if (location && location.subRegionName) {
            this.props.change('leadValidation', 'geo.subRegionName', location.subRegionName)
        }
    }

    getLocations = zipCode => {
        this.props.change('leadValidation', 'geo.zipCode', zipCode)
        this.props.getLocations({ zipCode })
    }

    addressChanged = (e) => {
        this.setState({ address: e.target.value })
    }

    renderZipCodeMenuItem = option => `${option.zipCode}, ${option.cityName}`

    render() {
        const {
            isLoadingLocations,
            showPhoneNumber,
            locations,
            lead,
        } = this.props

        const isLoanLeadType = isLoanLead(this.props.lead.industries.primary)
        return <Card>
            <Card.Header>
                <b>Contact information</b>
            </Card.Header>
            <Card.Content>
                <Row>
                    {lead.personalTitle && <Col sm={6} md={12} lg={6}>
                        <Field
                            name="personalTitle.title"
                            label="Personal title"
                            component={FieldGroup}
                            type="text"
                            readOnly
                        />
                    </Col>}
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="customerInfo.firstName"
                            label={<span>
                                First name
                                <Sup>*</Sup>
                            </span>}
                            component={FieldGroup}
                            type="text"
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="customerInfo.lastName"
                            label={<span>
                                Last name
                                <Sup>*</Sup>
                            </span>}
                            component={FieldGroup}
                            type="text"
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="geo.address"
                            label="Address"
                            component={FieldGroup}
                            type="text"
                            onChange={this.addressChanged}
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            component={AsyncTypeaheadGroup}
                            name="geo"
                            label={<span>
                                Zip code
                                <Sup>*</Sup>
                            </span>}
                            labelKey="zipCode"
                            extraData={{ address: this.state.address }}
                            isLoading={isLoadingLocations}
                            options={locations}
                            renderMenuItemChildren={this.renderZipCodeMenuItem}
                            onSearch={this.getLocations}
                            defaultSelected={this.state.initZipCode}
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="geo.cityName"
                            label="City"
                            component={FieldGroup}
                            type="text"
                            readOnly
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="geo.subRegionName"
                            label="Region"
                            component={FieldGroup}
                            readOnly
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="customerInfo.email"
                            label={<span>
                                Lead email
                                <Sup>*</Sup>
                            </span>}
                            component={FieldGroup}
                            type="email"
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6} hidden={!showPhoneNumber}>
                        <Field
                            name="customerInfo.phone"
                            label={<span>
                                Phone
                                <Sup>*</Sup>
                            </span>}
                            component={FieldGroup}
                        />
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <Field
                            name="client.email"
                            label="Client email"
                            component={FieldGroup}
                            type="email"
                            readOnly
                        />
                    </Col>
                    {!isLoanLeadType && <Col sm={6} md={12} lg={6}>
                        <Field
                            label="Client Classification"
                            name="category.id"
                            returnId={true}
                            component={SelectInputGroup}
                        >
                            <option value="1">Basic</option>
                            <option value="2">Exclusive</option>
                        </Field>
                    </Col>}
                </Row>
            </Card.Content>
        </Card>
    }
}

export default LeadDetailsCard as unknown as ComponentType<LeadDetailsPathProps>
