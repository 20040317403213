import React, { memo } from 'react'
import KpiIndicator from '../../elements/KpiIndicator'
import moment from 'moment'
import { Card } from '../../blocks/Card'
import { Col, Row } from 'react-bootstrap'
import { BtnHref } from '../../elements/Links'
import { Flex } from '../../elements/Flex'
import { MetaText } from '../../elements/text/MetaText'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Invoice } from '../../../reducers/types/entities/Invoice'
import { RoundCheckBox } from '../../elements/RoundCheckBox'
import ActionCheckBox from '../../elements/ActionCheckBox'

interface FinancePartnerCardModel {
    partner: Partner
    invoices: Invoice[]
    handleWantsOverdueInvoiceReminders?: any
}

const FinancePartnerCard = memo(({
    partner,
    invoices,
    handleWantsOverdueInvoiceReminders,
}: FinancePartnerCardModel) =>
    <Card className="financePartnerCard">
        <Card.Header>Finance</Card.Header>
        <Card.Content padding='20px 20px 5px 20px'>
            { handleWantsOverdueInvoiceReminders && <Row>
                <ActionCheckBox
                    onClick={handleWantsOverdueInvoiceReminders}
                    value={partner.wantsOverdueInvoiceReminders}
                    checked={partner.wantsOverdueInvoiceReminders}
                    text={'Partner wants overdue invoice reminders'}
                />
            </Row> }
            <Row>
                <Col md={4}>
                    <Card.Label>Revenue last 6 months</Card.Label>
                    <Card.Text>
                        {partner.kpi.revenue6months.amount} 
                        {' '}
                        {partner.kpi.revenue6months.currency}
                    </Card.Text>
                </Col>
                <Col md={4}>
                    <Card.Label>Last invoice created</Card.Label>
                    <Card.Text>
                        {partner.kpi.lastInvoice ? moment(partner.kpi.lastInvoice).calendar() : '-'}
                    </Card.Text>
                </Col>
            </Row>
            <Card.Label>
                Outstanding invoices &nbsp;
                {invoices.length > 0 && <KpiIndicator
                    ranges={[
                        { min: 1, max: 3, class: 'warning' },
                        { min: 3, class: 'danger' }
                    ]}
                    value={invoices.length}
                                        />}
            </Card.Label>
        </Card.Content>
        <Card.Content
            modifiers={[ 'scroll', 'maxHeight', 'pY_0' ]}
            scrollHeight="calc(100% - 245px)"
        >
            {invoices.length > 0
                ? invoices.map(invoice => <Flex>
                    <RoundCheckBox type="circle" modifiers="mR_1"/>
                    <div>
                        <Card.Text modifiers={[ 'm_0' ]}>
                            <a href={'/vouchers/' + invoice.id}>
                                Invoice #
                                {invoice.invoiceNumber}
                            </a>
&nbsp;
                            (
                            {invoice.amounts.totalExcludingVat.amount} 
                            {' '}
                            {invoice.amounts.totalExcludingVat.currency}
                            )
                        </Card.Text>
                        <MetaText className="text-muted">
                            Due 
                            {' '}
                            {moment(invoice.dueAt).calendar()}
                            &middot;
                            {moment(invoice.dueAt).startOf('day').isBefore(moment().startOf('day'))
                                ? [ 'unpaid', 'disputed', 'overdue', 'failed' ].includes(invoice.status)
                                    ? <span className="label label-danger">Overdue!</span>
                                    : invoice.status === 'paid'
                                        ? 'Paid'
                                        : 'Not Paid'
                                : ''
                            }
                        </MetaText>
                    </div>
                </Flex>)
                : <Card.Text>Partner has no outstanding invoices</Card.Text>
            }
        </Card.Content>
        <Card.Content>
            <BtnHref
                href={'/vouchers/invoices?partner_id=' + partner.id}
                modifiers={[ 'secondary', 'fullWidth', 'btnBlock', 'p_1' ]}
            >
                See all partner invoices
            </BtnHref>
        </Card.Content>
    </Card>
)

export default FinancePartnerCard
