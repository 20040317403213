import { createSelector } from 'reselect'

export const geoRegionsEntities = state => state.entities.geoRegions

export const allGeoRegionsId = state => state.subjects.geoRegions.all.ids

export const businessUnitGeoRegionsId = state => state.subjects.geoRegions.businessUnit.ids

export const allGeoRegionsSelector = createSelector(
    allGeoRegionsId, geoRegionsEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const businessUnitGeoRegionsSelector = createSelector(
    businessUnitGeoRegionsId, geoRegionsEntities,
    (ids, entities) => ids.map(id => entities[id])
)
