import {
    GET_LOCATIONS,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS_FAIL,
} from '../../actions/locations'
import { InitialState } from '../types/InitialState'
import { InitialAction } from '../types/InitialAction'

export interface LocationFilterModel extends InitialState {
    locationIds: number[]
}
const initialState: LocationFilterModel = {
    isLoading: null,
    locationIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_LOCATIONS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                locationIds: action.response.result,
                isLoading: false,
            }
        case GET_LOCATIONS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
