import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_LANGUAGES = 'languages/GET_LANGUAGES'
export const GET_LANGUAGES_SUCCESS = 'languages/GET_LANGUAGES_SUCCESS'
export const GET_LANGUAGES_FAIL = 'languages/GET_LANGUAGES_FAIL'

export const getLanguages = () => ({
    [CALL_API]: {
        types: [ GET_LANGUAGES, GET_LANGUAGES_SUCCESS, GET_LANGUAGES_FAIL ],
        endpoint: '/languages',
        schema: schemas.languages,
    },
})
