import {
    GET_TOKEN_INFO_SUCCESS,
    GET_AUTH_PERMISSIONS_SUCCESS, LOGIN_SUCCESS,
} from '../actions/auth'
import { camelize } from 'humps'
import { InitialAction } from './types/InitialAction'
import { GET_EMPLOYEES_SUCCESS } from '../actions/employees'
import config from '../config'

export interface Auth {
    token: string, // deprecated: New: accessToken
    accessToken: string|null,
    employeeId: number,
    userType: string,
    permissions: string[],
    wantedGeoCode: string|null,
    wantedIndustryId: number|null,
}

const initialState: Auth = {
    token: '',
    accessToken: null,
    employeeId: null,
    userType: null,
    permissions: [],
    wantedGeoCode: null,
    wantedIndustryId: null,
}

export default function reducer(
    state = initialState,
    action: InitialAction
): Auth {
    switch (action.type) {
        case GET_AUTH_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.response.result as unknown as string[],
            }
        case GET_EMPLOYEES_SUCCESS:
            config.geoCode = action.response.entities.employees[state.employeeId].wantedGeoCode
            return {
                ...state,
                wantedGeoCode: config.geoCode,
            }
        case GET_TOKEN_INFO_SUCCESS:
            return {
                ...state,
                token: action.response.token,
                accessToken: action.response.token,
                [camelize(action.response.userType) + 'Id']: action.response.userId,
                userType: action.response.userType,
                wantedIndustryId: Number(window.localStorage.getItem('industry_id')) || null,
            }
        case LOGIN_SUCCESS:
            window.localStorage.setItem('auth.refresh_token', action.response.token)
            window.location.reload()
            break
        default:
            return state
    }
}
