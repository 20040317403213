import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { Button } from './Button'
import { primaryColor } from '../../utils/variables'
import { Link } from 'react-router-dom'

const MODIFIER_LINK_CONFIG = {
    underline: () => `
        text-decoration: underline;
    `,
}

const A = styled.a``
export const PrimaryBlankLink = styled.a.attrs({ target: '_blank' })`
    display: block;
    margin-top: 10px;
    text-decoration: underline;
    color: ${primaryColor};
`

export const PrimaryLink = styled(Link)`
    display: block;
    color: ${primaryColor};
    ${applyStyleModifiers(MODIFIER_LINK_CONFIG)}
`

export const BtnLink: any = Button.withComponent(Link)

export const BtnHref = Button.withComponent(A)
