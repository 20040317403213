import React, { Fragment, memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { HasPermission } from '../NavigationBar'
import { Link } from 'react-router-dom'

interface ClientsDropdownProps {
    hasPermission: HasPermission['hasPermission']
}

const ClientsDropdown = memo(({ hasPermission }: ClientsDropdownProps) =>
    <NavDropdown eventKey={2} title="Clients" id="basic-nav-dropdown-Partners">
        <MenuItem eventKey={2.1}>
            <Link to="/leads/validation">Validation</Link>
        </MenuItem>
        <MenuItem eventKey={2.16}>
            <Link to="/quotes">Quotes</Link>
        </MenuItem>
        <MenuItem eventKey={2.2}>
            <Link to="/quotes/surveillance">Quote surveillance</Link>
        </MenuItem>
        <MenuItem eventKey={2.3}>
            <Link to="/quotes/follow-ups">Follow-ups</Link>
        </MenuItem>
        {hasPermission('exclusive_clients') && <MenuItem eventKey={2.4}>
            <Link to="/exclusive-leads">Exclusive-leads</Link>
        </MenuItem>}
        <MenuItem divider />
        {hasPermission('view_all_leads') && <MenuItem eventKey={2.5}>
            <Link to="/leads">All leads</Link>
        </MenuItem>}
        <MenuItem eventKey={2.6}>
            <Link to="/match-reports">Generate match report</Link>
        </MenuItem>
        <MenuItem eventKey={2.8} href="/leads/not-treated">Leads not treated</MenuItem>
        {hasPermission('view_leads_dismissed') && <MenuItem eventKey={2.9} href="/leads/dismissed">
            Leads dismissed
        </MenuItem>}
        <MenuItem eventKey={2.11} href="/leads/map">Lead Map</MenuItem>
        {hasPermission('change_client_emails') && <MenuItem eventKey={2.12} href="/client/email-tool">
            Change client email
        </MenuItem>}

        {!hasPermission('exclusionRatings') && <Fragment>
            <MenuItem divider />
            <MenuItem header>Ratings</MenuItem>
            <MenuItem eventKey={2.13} href="/ratings/all">All ratings</MenuItem>
            <MenuItem eventKey={2.14} href="/ratings/not-validated">
                Not validated
            </MenuItem>
            <MenuItem eventKey={2.15} href="/ratings/objections">
                Objections
            </MenuItem>
        </Fragment>}
    </NavDropdown>
)

export default ClientsDropdown
