import styled from 'styled-components'
import { tableBorderColor } from '../../../../utils/variables'

const Cell = styled.td`
    vertical-align: middle;
    position: relative;
    &:after {
      content:'';
      position: absolute;
      pointer-events: none;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-bottom: 1px solid ${tableBorderColor};
    }
    height: 50px;
    padding: 0px 20px;
    line-height: 16px;
    a {
      font-weight: bold;
    }
    &.number {
      text-align: right;
    }
`

export const NestedTableCell = styled.td`
    vertical-align: middle;
    border-bottom: 1px solid ${tableBorderColor};
    height: 50px;
    padding: 0px 20px;
    line-height: 16px;
    a {
      font-weight: bold;
    }
    &.number {
      text-align: right;
    }
`

export const StyledCell = styled.td`
    vertical-align: middle;
    border: 1px solid ${tableBorderColor};
    height: 30px;
    padding: 0px 20px;
    line-height: 16px;
    a {
      font-weight: bold;
    }
    &.number {
      text-align: right;
    }
`

export default Cell
