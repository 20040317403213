import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import { GET_DUE_INVOICES, GET_INVOICE, GET_INVOICES } from './actions'

const invoices = combineReducers({
    all: generalSubjectReducer(GET_INVOICES, GET_INVOICE),
    due: generalSubjectReducer(GET_DUE_INVOICES),
})

export default invoices
