import React, { Fragment, memo } from 'react'
import { range } from 'lodash'

const Placeholder = memo((
    { portrait, counter = 1 }: {portrait?: boolean, counter?: number},
) =>
    <Fragment>
        {range(counter).map(el => <div key={el + 'placeholder'} className="placeholder">
            {portrait && <div> 
                {' '}
                <div className="portrait"/>
            </div>}
            <div>
                <div /> 
                {' '}
                <div />
            </div>
        </div>)}
    </Fragment>
)
export default Placeholder
