import config from '../config'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { actionTypesCreator } from '../utils'

export const GET_AFFILIATES = actionTypesCreator('affiliates', 'GET_AFFILIATES')
export const GET_AFFILIATE_CAMPAIGNS = actionTypesCreator('campaigns', 'GET_AFFILIATE_CAMPAIGNS')

interface GetAffiliateModel {
    isActive: 0 | 1,
    hasActiveCampaigns: 0 | 1,
}

interface GetAffiliateCampaignsModel {
    isActive: 0 | 1,
}

export const getAffiliates = (
    criteria: GetAffiliateModel
) => ({
    [CALL_API]: {
        types: GET_AFFILIATES,
        endpoint: '/affiliates',
        schema: schemas.affiliates,
        options: {
            body: {
                geoCode: config.geoCode,
                ...criteria,
            },
        },
    },
})

export const getAffiliateCampaigns = (
    criteria: GetAffiliateCampaignsModel,
    affiliateId: number
) => ({
    [CALL_API]: {
        types: GET_AFFILIATE_CAMPAIGNS,
        endpoint: `/affiliates/${affiliateId}/campaigns`,
        schema: schemas.campaigns,
        options: {
            body: {
                geoCode: config.geoCode,
                ...criteria,
            },
        },
    },
})
