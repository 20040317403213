import React, { Children, ComponentType, createContext, PureComponent, ReactElement } from 'react'
import { replace } from 'connected-react-router'
import styled from 'styled-components'
import { connect } from '../../../utils/connect'
import ProviderTable, { PathPropsTableProvider } from './index'
import { Card } from '../Card'
import { removeEmptyProperties } from '../../../utils'
import { Sidebar } from '../../elements/table'
import queryString from 'query-string'

const LIST_OF_IGNORED_PROPS = {
    visibleColumns: undefined,
    sidebarName: undefined,
    activeRowId: undefined,
    circleRadius: undefined,
}

const CRITERIA = {}

export const TableProviderContext = createContext(null)

const Content = styled.div<{sidebarName?: string, pageName?: string, isFilter: boolean}>`
  display: grid;
  > * {
    &:nth-child(1) {
      grid-area: f;
    }
    &:nth-child(2) {
      grid-area: t;
    }
    &:nth-child(3) {
      grid-area: s;
    }
  }
  grid-template-columns: repeat(3, 1fr);
  ${props => props.pageName === 'send-email'
        ? `
        grid-template-areas: "f f t" "f f t";
        grid-template-rows: auto calc(100vh - 200px) auto;
    `
        : `
        grid-template-areas: ${props.isFilter
        ? (props.sidebarName ? '"f f f" "t t s"' : '"f f f" "t t t"')
        : (props.sidebarName ? '"f f t"' : '"f f f"')
};
        grid-template-rows: ${props.isFilter
        ? 'auto calc(100vh - 285px) auto;'
        : 'calc(100vh - 200px) auto;'
};
    `
};
`

interface StatePropsTableProvider {
    query: any
    loading: boolean
}
interface DispatchPropsTableProvider {
    replace: typeof replace
}

type TableProviderModel = StatePropsTableProvider & DispatchPropsTableProvider & PathPropsTableProvider

@connect<StatePropsTableProvider, DispatchPropsTableProvider, PathPropsTableProvider>(
    (state, props) => ({
        query: state.router.location.query,
        criteria: props.criteria || CRITERIA,
        loading: props.loading !== undefined
            ? props.loading
            : props.pageName
                ? state.pages[props.pageName].isLoading
                : false,
    }),
    {
        replace,
    }
)
class _Provider extends PureComponent<TableProviderModel, any> {
    constructor(props) {
        super(props)

        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilterReset = this.onFilterReset.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.loadMore = this.loadMore.bind(this)
        this.onSort = this.onSort.bind(this)

        this.state = {
            tabs: [],
            onFilterChange: this.onFilterChange,
            onFilterReset: this.onFilterReset,
            onRowClick: this.onRowClick,
            loadMore: this.loadMore,
            onSort: this.onSort,
            hasMore: false,
            page: 0,
            ...props.criteria,
        }
    }
    getListOfData = (criteria = { sort: '' }, page?, append?) => {
        this.setState({ ...criteria, sort: criteria.sort, hasMore: false })

        const promise: any = this.props.retrieveData({
            ...criteria,
            ...LIST_OF_IGNORED_PROPS,
            page,
        }, append)

        promise.then(res => {
            this.setState({
                ...criteria,
                hasMore: res.pagination.page < res.pagination.pages,
                page: res.pagination.page,
            })
        })
    }
    componentDidUpdate(prevProps) {
        if (this.props.retrieveData && JSON.stringify({
            ...prevProps.query,
            ...LIST_OF_IGNORED_PROPS,
        }) !== JSON.stringify({
            ...this.props.query,
            ...LIST_OF_IGNORED_PROPS,
        })) {
            this.getListOfData(this.props.query)
        }

        if (JSON.stringify(prevProps.query.visibleColumns) !== JSON.stringify(this.props.query.visibleColumns))
            this.setState({ visibleColumns: this.props.query.visibleColumns })
        if (JSON.stringify(prevProps.query.activeRowId) !== JSON.stringify(this.props.query.activeRowId))
            this.setState({ activeRowId: this.props.query.activeRowId })

        if (JSON.stringify(prevProps.query.sort) !== JSON.stringify(this.props.query.sort)) {
            this.setState({ sort: this.props.query.sort })
        }

        // Fix to update the sidebar
        if (this.state.tabs && this.state.sidebarName !== this.props.criteria.sidebarName) {
            this.setState({ sidebarName: this.props.criteria.sidebarName })
        }
    }

    componentDidMount(): void {
        const criteria = removeEmptyProperties(this.props.criteria)
        const existCriteria = Boolean(Object.values(criteria).length)
        if (this.props.retrieveData) {
            if (Object.values(this.props.query).length || !existCriteria) {
                this.getListOfData(this.props.query)
            } else if (existCriteria) {
                this.props.replace({
                    pathname: window.location.pathname,
                    search: queryString.stringify(criteria),
                })
            }
        }
        const filter = Children
            .toArray(this.props.children)
            .find((child: ReactElement<any>) => child.type === ProviderTable.FilterContainer)

        if (filter) {
            const lazyTable: any = Children
                .toArray(this.props.children)
                .find((child: ReactElement<any>) => child.type === ProviderTable.LazyTable)

            const header = lazyTable.props.children
                .find((child: ReactElement<any>) => child.type === ProviderTable.Header)

            const headerColumns = header.props.children

            const columnNames = headerColumns.map(col => col.props.name)

            this.setState({ columnNames })
        }

        const sidebar: any = Children
            .toArray(this.props.children)
            .find((child: ReactElement<any>) => child.type === Sidebar)

        if (sidebar) {
            const tabs = Children.toArray(sidebar.props.children)
                .map((child: ReactElement<any>) => ({
                    viewKey: child.props.viewKey,
                    header: child.props.header,
                }))
            this.setState({ tabs })
        }
    }

    onFilterChange(filters) {
        const { replace, query } = this.props

        if (
            Array.isArray(filters.states) &&
            filters.states.length === 1 &&
            filters.states[0] === 'canvas'
        ) {
            filters = {
                ...filters,
                speeds: undefined,
                satisfactions: undefined,
            }
        }

        if (
            Array.isArray(filters.states) &&
            !filters.states.includes('canvas')
        ) {
            filters.canvasStatus = undefined
        }

        replace({
            pathname: window.location.pathname,
            search: queryString.stringify({ ...query, ...filters }),
        })
    }

    onSort(sort) {
        this.onFilterChange({ sort })
    }

    onRowClick(activeRowId) {
        const { tabs } = this.state
        this.setState({ activeRowId })

        if (tabs.length) {
            const sidebarName = this.props.criteria.sidebarName || ((activeRowId && tabs[0]) ? tabs[0].viewKey : '')
            this.onFilterChange({ activeRowId, sidebarName })
        } else {
            this.onFilterChange({ activeRowId })
        }
    }

    onFilterReset() {
        this.props.replace({ pathname: window.location.pathname })
    }

    loadMore() {
        const page = this.state.page + 1
        this.getListOfData(this.props.query, page, true)
    }

    render() {
        const { children, criteria, pageName, loading } = this.props

        return (
            <Card.Content isLoading={loading}>
                <Content
                    sidebarName={criteria.sidebarName}
                    pageName={pageName}
                    isFilter={Boolean(criteria.visibleColumns)}
                >
                    <TableProviderContext.Provider value={this.state}>
                        {children}
                    </TableProviderContext.Provider>
                </Content>
            </Card.Content>
        )
    }
}

export default _Provider as ComponentType<PathPropsTableProvider>
