import React, { PureComponent, Fragment } from 'react'
import { ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Card } from '../../blocks/Card'
import styled from 'styled-components'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'
import { Flex } from '../../elements/Flex'
import { successColor, primaryDarkColor } from '../../../utils/variables'

const DeliveryProgressBarDiv = styled.div<{padding?: number, nested?: boolean, margin?: string}>`
    position: relative;
    padding: ${({ padding }) => padding || 0}px 0;
    margin: ${({ margin }) => margin || 0};

    & > .progress {
        margin: 0;
        height: 12px;
    }

    ${({ nested }) => !nested ? '' : `
        & > .progress {
            margin: 0;
            height: 8px;
        }
    `}
`

const ProgressLine = styled(ProgressBar)<{contentValue?: string}>`
  margin: 0;
  position: relative;
  &::after {
      color: #000;
      content: "${props => props.contentValue}";
      line-height: 22px;
      position: absolute;
      left: 48%;
    }
`

const TooltipIcon = styled.span`
    display: inline-flex;
    width: 1em;
    height: 1em;
    justify-content: center;
    align-items: center;
    color: ${primaryDarkColor};
    border: 1px solid currentColor;
    border-radius: 50%;
    cursor: pointer;
`

const IdealProgressMarker = styled.div<{periodProgress?: number, width?: number, height?: number}>`
    position: absolute;
    z-index: 5;
    background-color: ${successColor};
    width: ${({ width }) => width || 6}px;
    height: ${({ height }) => height || 20}px;
    top: 50%;
    transform: translateY(-50%);
    left: calc(${({ periodProgress }) => periodProgress || 1}% - ${({ width }) => width || 6}px);
    border-radius: 3px;
`

interface PartnerContractPeriod {
    startsAt: string
    endsAt: string
}

interface DeliveryProgressBarProps {
    deliveryQuota: number
    deliveryCount: number
    overDeliveryCount?: number
    businessUnits?: BusinessUnit[]
    deliveryPeriod?: PartnerContractPeriod
    allocationType: string
    periodDeliveryProgress?: any
    deliveryType: string
    disableTooltip?: boolean
    tooltipText?: string
    tooltipId?: string,
}

export default class DeliveryProgressBar extends PureComponent<DeliveryProgressBarProps> {
    state = {
        dropdownToggled: false,
    }

    componentDidUpdate(prevProps, prevState): void {
        if (prevState.dropdownToggled && this.props.deliveryQuota === 0 && this.props.deliveryCount === 0) {
            this.setState({ dropdownToggled: false })
        }
    }

    togglePanel = () => this.setState({ dropdownToggled: !this.state.dropdownToggled })

    render() {
        const {
            deliveryQuota,
            deliveryCount,
            overDeliveryCount = 0,
            businessUnits,
            deliveryPeriod,
            periodDeliveryProgress,
            deliveryType,
            allocationType,
            disableTooltip,
            tooltipText,
            tooltipId,
        } = this.props

        const { dropdownToggled } = this.state
        const totalRemainder = deliveryQuota - deliveryCount
        const totalDeliveryString = `${deliveryCount} ${Boolean(overDeliveryCount) ? `+ ${overDeliveryCount}` : ''} / ${deliveryQuota}${totalRemainder !== 0 && deliveryType === 'lb' ? ` (${totalRemainder} left)` : ''}`

        return <Fragment>
            <Flex modifiers={[ 'justifySpaceBetween' ]}>
                <Card.Label>
                    {deliveryType}
                    {' '}
                    delivery 
                    {' '}
                    {allocationType === 'currentMonthAllocation' ? 'per suggested quota' : ''}
                </Card.Label>
                <span>
                    <span style={{ marginRight: '12px' }}>{totalDeliveryString}</span>
                    {!disableTooltip && <OverlayTrigger
                        delay={0}
                        placement="left"
                        overlay={
                            <Tooltip placement="left" className="in" id={tooltipId}>
                                {tooltipText}
                                {Boolean(deliveryQuota) && <Fragment>
                                    {tooltipText && <Fragment>
                                        <br/>
                                        <br/>
                                    </Fragment>}
                                    In selected period, this partner has been allocated 
                                    {' '}
                                    {deliveryCount}
                                    {' '}
                                    lead/s out
                                    of 
                                    {' '}
                                    {deliveryQuota} 
                                    {' '}
                                    {deliveryType === 'lb' ? 'required' : 'allowed'}
                                    .
                                    <br/>
                                    {overDeliveryCount ? `${overDeliveryCount} leads ${overDeliveryCount > 1 ? 'have' : 'has'} been allocated outside of the normal quota.` : ''}
                                </Fragment>}
                                {!Boolean(deliveryQuota) && <Fragment>
                                    {tooltipText && <Fragment>
                                        <br/>
                                        <br/>
                                    </Fragment>}
                                    There are no 
                                    {' '}
                                    {deliveryType.toUpperCase()}
                                    {' '}
                                    business units in current selection.
                                </Fragment>}
                            </Tooltip>}
                        animation={false}
                        delayHide={0}
                    >
                        <TooltipIcon>
                            <span style={{ fontSize: '10px' }}>?</span>
                        </TooltipIcon>
                    </OverlayTrigger>}
                </span>
            </Flex>
            <DeliveryProgressBarDiv margin="0 0 20px">
                <ProgressBar>
                    <ProgressLine
                        bsStyle={deliveryType === 'lb' ? 'info' : 'warning'}
                        now={deliveryCount}
                        max={deliveryQuota + overDeliveryCount === 0 ? 1 : deliveryQuota + overDeliveryCount}
                        key={1}
                    />
                    {Boolean(overDeliveryCount) && <ProgressLine
                        now={overDeliveryCount}
                        max={deliveryQuota + overDeliveryCount === 0 ? 1 : deliveryQuota + overDeliveryCount}
                        contentValue=""
                        key={2}
                    />}
                    {(deliveryPeriod && Boolean(deliveryQuota)) && <IdealProgressMarker
                        periodProgress={periodDeliveryProgress(deliveryPeriod)}
                    />}
                </ProgressBar>
            </DeliveryProgressBarDiv>
            {(businessUnits && Boolean(businessUnits.length) && dropdownToggled) && <Card.Content modifiers={[ 'maxHeight', 'scroll', 'pT_1', 'pX_3', 'mY_2' ]} scrollHeight="420px">
                <Card.Line modifiers="mB_1" />
                {businessUnits.map(bu => {
                    let buDeliveryCount
                    let buDeliveryQuota
                    let buOverDeliveryCount = 0

                    if (deliveryType === 'lb') {
                        if (allocationType === 'currentMonthAllocation') {
                            buDeliveryCount = bu[allocationType].currentMonthAllocationCount
                            buDeliveryQuota = bu.isActive ? bu[allocationType].suggestedAllocationQuota : bu[allocationType].currentMonthAllocationCount
                            buOverDeliveryCount = bu[allocationType].overdeliveryAllocationCount
                        } else {
                            buDeliveryCount = bu[allocationType].normalQuotaAllocationCount
                            buDeliveryQuota = bu[allocationType].normalAllocationQuota
                            buOverDeliveryCount = bu[allocationType].overdeliveryAllocationCount
                        }
                    } else {
                        buDeliveryCount = bu[allocationType].payPerSpotAllocationCount
                        buDeliveryQuota = bu[allocationType].payPerSpotAllocationLimit
                    }

                    if (buDeliveryQuota === 0 && buDeliveryCount === 0) {
                        return null
                    }

                    const remainder = buDeliveryQuota - buDeliveryCount
                    const deliveryString = `${buDeliveryCount} ${buOverDeliveryCount > 0 ? `+ ${buOverDeliveryCount}` : ''} / ${buDeliveryQuota}${remainder !== 0 ? ` (${remainder} left)` : ''}`

                    return <Fragment key={'bu_delivery_' + bu.id}>
                        <Flex modifiers={[ 'justifySpaceBetween' ]}>
                            <Card.Label>{bu.displayName}</Card.Label>
                            <span>{deliveryString}</span>
                        </Flex>
                        <DeliveryProgressBarDiv margin="0 0 16px">
                            <ProgressBar>
                                <ProgressLine
                                    bsStyle={deliveryType === 'lb' ? 'info' : 'warning'}
                                    now={buDeliveryCount}
                                    max={buDeliveryQuota + buOverDeliveryCount === 0 ? 1 : buDeliveryQuota + buOverDeliveryCount}
                                    key={1}
                                />
                                {Boolean(buOverDeliveryCount) && <ProgressLine
                                    now={buOverDeliveryCount}
                                    max={buDeliveryQuota + buOverDeliveryCount === 0 ? 1 : buDeliveryQuota + buOverDeliveryCount}
                                    contentValue=""
                                    key={2}
                                />}
                            </ProgressBar>
                            {(deliveryPeriod && periodDeliveryProgress) && <IdealProgressMarker
                                periodProgress={periodDeliveryProgress(deliveryPeriod)}
                            />}
                        </DeliveryProgressBarDiv>
                    </Fragment>
                })}
            </Card.Content>}
            {(businessUnits && Boolean(businessUnits.length) && Boolean(deliveryQuota)) && <Flex modifiers={[ 'justifyCenter' ]}>
                <span style={{ cursor: 'pointer' }} onClick={this.togglePanel}>{dropdownToggled ? 'Hide business units' : 'View individual business units'}</span>
            </Flex>}
        </Fragment>
    }
}
