import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_COUPONS = 'coupons/GET_COUPONS'
export const GET_COUPONS_SUCCESS = 'coupons/GET_COUPONS_SUCCESS'
export const GET_COUPONS_FAIL = 'coupons/GET_COUPONS_FAIL'

export const CREATE_COUPON_ACTION = 'coupons/CREATE_COUPON_ACTION'
export const CREATE_COUPON_ACTION_SUCCESS = 'coupons/CREATE_COUPON_ACTION_SUCCESS'
export const CREATE_COUPON_ACTION_FAIL = 'coupons/CREATE_COUPON_ACTION_FAIL'

interface GetCouponsPropsModel {
    showDeactivated: boolean
    showExpired: boolean
    showEmpty: boolean
}
export const getCoupons = (
    partnerId: number,
    criteria: GetCouponsPropsModel,
) => ({
    [CALL_API]: {
        types: [ GET_COUPONS, GET_COUPONS_SUCCESS, GET_COUPONS_FAIL ],
        endpoint: `/partners/${partnerId}/coupons`,
        schema: schemas.coupons,
        options: {
            body: {
                limit: 1000,
                ...criteria,
            },
        },
    },
})

interface CreateCouponActionPropsModel {
    action: string
    date?: string
}
export const createCouponAction = (
    partnerId: number,
    couponId: number,
    body: CreateCouponActionPropsModel,
) => ({
    [CALL_API]: {
        types: [ CREATE_COUPON_ACTION, CREATE_COUPON_ACTION_SUCCESS, CREATE_COUPON_ACTION_FAIL ],
        endpoint: `/partners/${partnerId}/coupons/${couponId}/actions`,
        schema: schemas.partner,
        options: {
            method: 'post',
            body,
        },
    },
})
