import React from 'react'
import { MenuItem, NavDropdown, SelectCallback } from 'react-bootstrap'
import styled from 'styled-components'
import { Industries, Industry } from '../../../reducers/types/entities/Industry'

interface IndustriesDropdownProps {
    selectedIndustry?: Industry
    changeIndustry: (wantedIndustry?: Industry) => void
    industries: Industries
}

const IndustryNavDropdown = styled(NavDropdown)`
    height: inherit
`

const ALL_INDUSTRIES = 'All industries'

function IndustriesDropdown({
    selectedIndustry,
    changeIndustry,
    industries,
}: IndustriesDropdownProps) {
    return (
        <IndustryNavDropdown
            noCaret
            eventKey={7}
            title={<div>{selectedIndustry && selectedIndustry.name || ALL_INDUSTRIES}</div>}
            id="whatever-dont-care-useless-3rd-party-drop-down"
        >
            <MenuItem header>Industry</MenuItem>
            {Object.values(industries).map(industry =>
                <MenuItem eventKey={industry} key={industry.id} onClick={() => changeIndustry(industry)}>
                    {industry.name}
                </MenuItem>
            )}
            <MenuItem onClick={() => changeIndustry()}>{ALL_INDUSTRIES}</MenuItem>
        </IndustryNavDropdown>
    )
}

export default IndustriesDropdown
