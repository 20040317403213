import React, { ComponentType, PureComponent, Fragment } from 'react'
import { get } from 'lodash'
import { Lead } from '../../../reducers/types/entities/Lead'
import {
    Column, SortColumn,
    Table,
} from '../../elements/table'
import { FormattedBusinessUnit } from '../SendEmailToPartnersRegionsPage'
import SendEmailRow from './SendEmailRow'
import ScrollOverflow from '../../elements/ScrollOverflow'
import { Card } from '../../blocks/Card'
import ProviderTable from '../../blocks/ProviderTable'
import { hideModal, showModal } from '../../../actions/modal'

export interface PathProps {
    lead: Lead
    businessUnits: FormattedBusinessUnit[]
    numberOfSelectedRegions?: number
    hasPermissionToOverdeliver: boolean
    selectedBusinessUnits: number[]
    selectedPartners: number[]
    disableCheckActions: boolean
    submitting: boolean
    selectBusinessUnit: (partner: {id: number, overdelivery: boolean, partnerId: number }) => Promise<void>
    deselectBusinessUnit: (partner: {id: number, partnerId: number }) => Promise<void>
    hideModal: typeof hideModal
    showModal: typeof showModal
    sort: string
}

export interface FormattedBusinessUnitWithDistance extends FormattedBusinessUnit {
    distance: number
}

export type Model = PathProps

class BusinessUnitsTable extends PureComponent<Model> {
    state = {
        businessUnits: [],
    }

    componentDidMount() {
        this.setState({
            businessUnits: this.getSortedBusinessUnits(),
        })
    }

    componentDidUpdate(prevProps: Readonly<Model>, prevState: Readonly<{}>, snapshot?: any) {
        if ((prevProps.businessUnits.length !== this.props.businessUnits.length) || (prevProps.sort !== this.props.sort) || (prevProps.numberOfSelectedRegions !== this.props.numberOfSelectedRegions)) {
            this.setState({
                businessUnits: this.getSortedBusinessUnits(),
            })
        }
    }

    calculateDistance = (lat1, lon1, lat2, lon2, geoCode: string) => {
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0
        } else {
            const radlat1 = Math.PI * lat1 / 180
            const radlat2 = Math.PI * lat2 / 180
            const theta = lon1 - lon2
            const radtheta = Math.PI * theta / 180
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
            if (dist > 1) {
                dist = 1
            }
            dist = Math.acos(dist)
            dist = dist * 180 / Math.PI
            dist = dist * 60 * 1.1515
            return geoCode === 'us' ? dist : dist * 1.609344
        }
    }

    sortByDistance = (a, b) => {
        if (!this.props.sort.startsWith('-')) {
            return a.distance - b.distance
        } else {
            return b.distance - a.distance
        }
    }

    sortByDeliveryTrend = (a, b) => {
        const deliveryA = get(a, 'kpi.currentPeriodDeliveryTrend')
        const deliveryB = get(b, 'kpi.currentPeriodDeliveryTrend')

        if (deliveryA === deliveryB) {
            return 0
        }
        if (!deliveryA) {
            return 1
        }
        if (!deliveryB) {
            return -1
        }
        if (!this.props.sort.startsWith('-')) {
            return deliveryA - deliveryB
        }
        return deliveryB - deliveryA
    }

    getSortedBusinessUnits = () => {
        const {
            businessUnits,
            sort,
            lead,
        } = this.props

        const businessUnitsWithDistance = businessUnits
            .map((bu) => {
                return {
                    ...bu,
                    distance: this.calculateDistance(lead.geo.point.lat, lead.geo.point.lon, bu.geo.point.lat, bu.geo.point.lon, lead.geo.code),
                } as FormattedBusinessUnitWithDistance
            })

        if (!sort) {
            return businessUnitsWithDistance
        }

        const allocatableBusinessUnits = businessUnitsWithDistance
            .filter((bu) => {
                return ! bu.requiresOverdelivery
            })
            .sort(sort.includes('distance') ? this.sortByDistance : this.sortByDeliveryTrend)

        const unallocatableBusinessUnits = businessUnitsWithDistance
            .filter((bu) => {
                return bu.requiresOverdelivery
            })
            .sort(sort.includes('distance') ? this.sortByDistance : this.sortByDeliveryTrend)

        return allocatableBusinessUnits.concat(unallocatableBusinessUnits)
    }

    render() {
        const { businessUnits } = this.state
        const { lead } = this.props

        const { max, count, reserved } = lead ? lead.quotesInfo : { max: 0, count: 0, reserved: 0 }

        const isQuoteFull = count + reserved >= max
        const currentMax = max - count - reserved

        return (
            <Fragment>
                <ScrollOverflow heightCorrection={255}>
                    <Card.Content padding="0">
                        <Table>
                            <ProviderTable.Header>
                                <Column>Allocate</Column>
                                <Column>ID</Column>
                                <Column>Partner name</Column>
                                <Column>Latest allocation</Column>
                                <Column>Segmentation</Column>
                                <Column>City</Column>
                                <Column>Models</Column>
                                <Column>Status</Column>
                                <Column>Comments</Column>
                                <Column>Delivery this period</Column>
                                <Column>Delivery since start</Column>
                                <Column>Overdelivery this period</Column>
                                <Column>PPS delivery</Column>
                                <Column>PPS Fee</Column>
                                <SortColumn name="delivery_trend">Delivery trend</SortColumn>
                                <Column>Current delivery need</Column>
                                <SortColumn name="distance">Distance to Lead</SortColumn>
                            </ProviderTable.Header>
                            <ProviderTable.Rows
                                items={businessUnits}
                                noDataText="We couldn't find any business units matching your selection."
                            >
                                <SendEmailRow
                                    hasPermissionToOverdeliver={this.props.hasPermissionToOverdeliver}
                                    selectBusinessUnit={this.props.selectBusinessUnit}
                                    deselectBusinessUnit={this.props.deselectBusinessUnit}
                                    isDisableCheckBox={isQuoteFull || this.props.disableCheckActions}
                                    selectedBusinessUnits={this.props.selectedBusinessUnits}
                                    selectedPartners={this.props.selectedPartners}
                                    hideModal={this.props.hideModal}
                                    showModal={this.props.showModal}
                                    currentMax={currentMax}
                                />
                            </ProviderTable.Rows>
                        </Table>
                    </Card.Content>
                </ScrollOverflow>
            </Fragment>
        )
    }
}

export default BusinessUnitsTable as unknown as ComponentType<PathProps>
