import React, { memo } from 'react'
import styled from 'styled-components'
import Spinner from './Spinner'

const SpinnerContainer = styled.div<{size: number}>`
    position: relative;
    width: ${props => props.size || 50}px;
    height: ${props => props.size || 50}px;
`

interface LoaderComponentModel {
    className?: string
    type?: string
    size?: number
}
const LoaderComponent = memo((
    props: LoaderComponentModel
) => props.type === 'logo'
    ? <div className={'logo-loader ' + props.className}>
        <div className="square-1"/>
        <div className="square-2"/>
        <div className="square-3"/>
        <div className="square-4"/>
    </div>
    : <SpinnerContainer size={props.size}>
        <Spinner {...props}/>
    </SpinnerContainer>
)

export default LoaderComponent
