import React, { PureComponent, Fragment } from 'react'
import { formatNumber, parseNumber } from 'libphonenumber-js'
import ButtonWithIcon from '../blocks/ButtonWithIcon'
import { Button } from './Button'
import { HelpBlock } from 'react-bootstrap'

interface LeadCallButtonModel {
    onCall(result?: string): void
    phoneNumber: string
    ignoreAction?: string
    callStatus?: string
    geoCode: string
    label?: string
}

export default class LeadCallButton extends PureComponent<LeadCallButtonModel> {
    static defaultProps = {
        label: 'Call',
    }

    state = {
        called: '',
    }

    componentDidUpdate(prevProps) {
        if (prevProps.phoneNumber !== this.props.phoneNumber) {
            this.setState({ called: '' })
        }
    }

    call = () => {
        const { called } = this.state

        if(this.props.ignoreAction === called) return

        if(!called) {
            this.props.onCall()
            this.setState({ called: 'wasCalled' })
        } else if(called === 'wasCalled') {
            this.setState({ called: 'noAnswer' })
        } else if(called === 'noAnswer') {
            this.props.onCall('noAnswer')
        }
    }

    render() {
        const {
            phoneNumber,
            geoCode,
            label,
            callStatus,
            ignoreAction,
        } = this.props

        const { called } = this.state

        const callResult = callStatus || called

        const noAnswer = callResult === 'noAnswer' && ignoreAction !== 'noAnswer'

        let displayNumber = null

        let linkNumber = '#'

        let parsedPhoneNumber = null

        // We're using 'uk' as geoCode, but `libphonenumber-js` uses 'GB'
        const phoneGeoCode: any = geoCode === 'uk' ? 'GB' : geoCode.toUpperCase()

        try {
            parsedPhoneNumber = parseNumber(phoneNumber, phoneGeoCode)

            if (parsedPhoneNumber.country === phoneGeoCode) {
                displayNumber = formatNumber(parsedPhoneNumber, 'National')
            } else {
                displayNumber = formatNumber(parsedPhoneNumber, 'International')
            }

            linkNumber = 'tel:' + formatNumber(parsedPhoneNumber, 'International').replace(/\s/g, '')

            if (!parsedPhoneNumber.phone) {
                throw Error('No valid phone number parsed from ' + phoneNumber)
            }
            return <ButtonWithIcon
                modifiers={[ 'fullWidth' ]}
                onClick={this.call}
                responsiveModifiers={{
                    call: [ 'secondary' ],
                    noAnswer: [ 'danger' ],
                }}
                size={noAnswer ? 'noAnswer' : 'call'}
                title={displayNumber}
            >
                { (callResult === 'wasCalled' || (callResult === 'noAnswer' && ignoreAction === 'noAnswer')) ?
                    <ButtonWithIcon.Href href={linkNumber}>{displayNumber}</ButtonWithIcon.Href> : [
                        <ButtonWithIcon.Icon key={'btn_1'} className={`mdi mdi-phone${callResult ? '-minus' : ''} flipped`} />,
                        <ButtonWithIcon.Text key={'btn_2'}>{callResult === 'noAnswer' ? 'No Answer' : label }</ButtonWithIcon.Text>,
                    ]
                }
            </ButtonWithIcon>
        } catch (error) {
            return <div className="has-error">
                <Button
                    onClick={() => this.props.onCall('notValid')}
                    modifiers={[ 'fullWidth', 'danger' ]}
                >
                    {noAnswer ? 'No Answer' : phoneNumber}
                </Button>
                { !noAnswer && <Fragment>
                    <br />
                    <HelpBlock>Phone number is not valid for tel: link</HelpBlock>
                </Fragment>}
            </div>
        }
    }
}
