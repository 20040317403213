import React, { memo, PureComponent } from 'react'
import { Label } from 'react-bootstrap'
import config from '../../config'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { Card } from '../blocks/Card'
import ProviderTable from '../blocks/ProviderTable'
import {
    Cell,
    LinkCell,
    DateCell,
    DropdownFilter,
    TextFilter,
    SortColumn,
    Column
} from '../elements/table'
import { connect } from '../../utils/connect'
import DateFilter from '../elements/table/filters/DateFilter'
import moment from 'moment'
import { Employee } from '../../reducers/types/entities/Employee'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { AllocationDeliveries, AllocationDelivery } from '../../reducers/types/entities/Deliveries'
import { Partners } from '../../reducers/types/entities/Partner'
import { getDeliveries } from '../../actions/deliveries'
import { getRevenueSegments } from '../../actions/revenueSegments'
import { RevenueSegments } from '../../reducers/types/entities/RevenueSegment'

const DeliveryRow = memo((props: { rowData?: AllocationDelivery }) => {
    const delivery = props.rowData

    const returnStatusBadge = (line: AllocationDelivery) => {
        if (line.processedAt) {
            return <Label bsStyle="success">Processed</Label>
        }
        return <Label bsStyle="info">Unprocessed</Label>
    }

    return <ProviderTable.Row {...props}>
        <Cell>{returnStatusBadge(delivery)}</Cell>
        <LinkCell to={`/partners/${delivery.partner.id}`}>{delivery.partner.id}</LinkCell>
        <Cell>{delivery.partner.companyName}</Cell>
        <Cell>{delivery.partnerBusinessUnit.id}</Cell>
        <Cell>{delivery.partnerBusinessUnit.displayName}</Cell>
        <DateCell date={delivery.processAt} />
        <Cell>{delivery.deliveryAllocationCount}</Cell>
        <Cell>{delivery.allocatedAllocationCount}</Cell>
    </ProviderTable.Row>
})

interface Criteria {
    revenueSegmentId?: string[]
    partnerId: string
    employeeId: string[]
    processAtGte: string
    processAtLte: string
    geoCode: string
    sort: string
    visibleColumns: string[]
}

interface DeliveriesListStateProps {
    deliveries: AllocationDeliveries
    revenueSegments: RevenueSegments
    employees: Employee[]
    partners: Partners
    criteria: Criteria
}

interface DeliveriesListDispatchProps {
    getDeliveries: typeof getDeliveries
    getRevenueSegments: typeof getRevenueSegments
}

interface DeliveriesListPathProps {
    location: {
        query: Criteria
    }
}

type DeliveriesListModel = DeliveriesListStateProps & DeliveriesListDispatchProps & DeliveriesListPathProps

@connect<DeliveriesListStateProps, DeliveriesListDispatchProps, DeliveriesListPathProps>(
    (state, { location: { query } }) => ({
        deliveries: state.entities.deliveries,
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        partners: state.entities.partners,
        revenueSegments: state.entities.revenueSegments,
        criteria: {
            visibleColumns: splitFilterString(
                query.visibleColumns || [ 'status', 'partner_id', 'partner_name', 'partner_business_unit_id', 'segmentation_name', 'process_at',
                    'delivery_allocation_count', 'allocated_allocation_count' ]
            ),
            revenueSegmentId: splitFilterString(query.revenueSegmentId),
            partnerId: query.partnerId || '',
            geoCode: query.geoCode || '',
            employeeId: splitFilterString(query.employeeId),
            sort: query.sort || 'process_at',
            processAtGte: query.processAtGte || moment().startOf('month').format('YYYY-MM-DD').toString(),
            processAtLte: query.processAtLte || '',
        },
    }),
    {
        getDeliveries,
        getRevenueSegments,
    }
)
export default class PartnerInvoicingsPage extends PureComponent<DeliveriesListModel> {
    state = {
        isLoadingDeliveries: false,
        deliveryIds: [],
        revenueSegmentIds: [],
    }

    async componentDidMount() {
        const segments: any = await this.props.getRevenueSegments(config.geoCode)
        this.setState({
            revenueSegmentIds: segments.result,
        })
    }

    retrieveData = async (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
        })

        await this.setState({ isLoadingDeliveries: true, })

        try {
            const deliveries: any = await this.props.getDeliveries(criteria, append)

            await this.setState({
                isLoadingDeliveries: false,
                deliveryIds: deliveries.result,
            })

            return deliveries
        } catch (e) {
            await this.setState({ isLoadingDeliveries: false })
        }
    }

    render() {
        const { criteria, deliveries, employees, revenueSegments } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    Allocation deliveries
                </Card.Header>
                <Card.Content modifiers={[ 'p_0' ]}>
                    <ProviderTable
                        pageName="deliveries"
                        retrieveData={this.retrieveData}
                        criteria={this.props.criteria}
                        loading={this.state.isLoadingDeliveries}
                    >
                        <ProviderTable.FilterContainer>
                            <DropdownFilter
                                selectedIds={criteria.employeeId}
                                items={employees}
                                name="employeeId"
                                label="Employee"
                                search={false}
                            />
                            <DropdownFilter
                                selectedIds={criteria.revenueSegmentId}
                                items={this.state.revenueSegmentIds.map(id => ({ id, name: revenueSegments[id].displayName }))}
                                name="revenueSegmentId"
                                label="Revenue segment"
                                search={false}
                            />
                            <TextFilter
                                value={criteria.partnerId}
                                name="partnerId"
                                label="Search partner IDs ..."
                            />
                            <DateFilter
                                value={criteria.processAtGte}
                                name="processAtGte"
                                placeholder="Process at after.."
                            />
                            <DateFilter
                                value={criteria.processAtLte}
                                name="processAtLte"
                                placeholder="Process at before.."
                            />
                        </ProviderTable.FilterContainer>
                        <ProviderTable.LazyTable>
                            <ProviderTable.Header>
                                <Column name="status">Status</Column>
                                <Column name="partner_id">Partner ID</Column>
                                <Column name="partner_name">Partner name</Column>
                                <Column name="partner_business_unit_id">Business unit ID</Column>
                                <Column name="segmentation_name">Segmentation name</Column>
                                <SortColumn name="process_at">Process at</SortColumn>
                                <Column name="delivery_allocation_count">Allocation count</Column>
                                <Column name="allocated_allocation_count">Allocated count</Column>
                            </ProviderTable.Header>
                            <ProviderTable.Rows
                                items={this.state.deliveryIds.map(id => deliveries[id])}
                                noDataText="There aren't any deliveries matching your criteria."
                            >
                                <DeliveryRow />
                            </ProviderTable.Rows>
                        </ProviderTable.LazyTable>
                    </ProviderTable>
                </Card.Content>
            </Card>
        )
    }
}
