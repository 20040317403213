import {
    GET_COMPLETED_CALL_FLOW_LEADS,
    GET_COMPLETED_CALL_FLOW_LEADS_SUCCESS,
    GET_COMPLETED_CALL_FLOW_LEADS_FAIL,
} from '../../actions/leads'
import { InitialState } from '../types/InitialState'
import { InitialAction } from '../types/InitialAction'

export interface CompletedCallFlowModel extends InitialState {
    leadIds: number[]
}
const initialState: CompletedCallFlowModel = {
    isLoading: null,
    leadIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_COMPLETED_CALL_FLOW_LEADS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_COMPLETED_CALL_FLOW_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: action.append ? state.leadIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_COMPLETED_CALL_FLOW_LEADS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
