import React, { memo, Fragment, PureComponent, ComponentType } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Card } from '../../blocks/Card'
import { Field, FieldArray, FieldInputProps, FormikHelpers } from 'formik'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import Checkbox from '../../blocks/Checkbox'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import get from 'lodash/get'
import { Industries } from '../../../reducers/types/entities/Industry'
import { LeadTypes } from '../../../reducers/types/entities/LeadType'
import { Sectors } from '../../../reducers/types/entities/Sector'
import { Partner } from '../../../reducers/types/entities/Partner'
import { GeoRegions } from '../../../reducers/types/entities/GeoRegion'
import { RevenueSegments } from '../../../reducers/types/entities/RevenueSegment'
import ToggleCheckbox from '../../blocks/ToggleCheckbox'
import MetaLabel from '../../elements/text/MetaLabel'
import { Amount } from '../../../reducers/types/entities/Amount'
import { Flex } from '../../elements/Flex'
import View from '../../theme/View'
import { FormattedAmount } from '../../elements/FormattedAmount'
import sortAscending from '../../../utils/sortAscending'

const BasicGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  height: fit-content;
`
const BasicGridRow = styled(BasicGrid)<{repeat?: number, style?: object}>`
  grid-template-columns: repeat(${({ repeat }) => repeat || 2 }, 200px);
`

const BasicBusinessUnitInfoGrid = styled.div`
  display: grid;
  gap: 20px;
  > * {
    &:nth-child(1) { grid-area: a;}
    &:nth-child(2) { grid-area: b;}
    &:nth-child(3) { grid-area: c;}
    &:nth-child(4) { grid-area: d;}
    &:nth-child(5) { grid-area: e;}
  }
  grid-template-areas: "a a a" "b b b";
  max-width: 1120px;
`

const FlexCol = styled(Col)`
  display: flex;
`

const required = [ value => value ? undefined : 'Required!' ]

interface MultipleCheckboxDataFieldModel<Value> {
    field: FieldInputProps<Value>,
    form: FormikHelpers<Value> & {
        initialValues?: any
    }
    optionValue: {
        id: number | string
    }
    isLimited?: boolean
}

interface AllocationFormValue {
    count: number | string
    priceExclVat: Amount
    contractPriceExclVat?: number
}

export interface BusinessUnitFormValue {
    allocationLimit?: AllocationFormValue
    allocationQuota?: AllocationFormValue
    clientTypes: {
        id: number
    }[]
    displayName: string
    geo: {
        zipCode: string
        cityName: string
        point?: object
        address: string
    }
    id?: number
    industries: {
        id: number
    }[]
    geoRegions: {
        id: number
    }[]
    partnerBusinessUnitGroupingId?: number
    sectors: {
        id: number
    }[]
    revenueSegment?: {
        id: number | string
    }
}

const renderZipCodeMenuItem = option => `${option.zipCode}, ${option.cityName}`

export const makeBusinessUnitObject = partner => ({
    displayName: '',
    geo: {
        address: get(partner, 'geo.address', ''),
        cityName: get(partner, 'geo.cityName', ''),
        zipCode: get(partner, 'geo.zipCode', ''),
        point: get(partner, 'geo.point', {}),
    },
    industries: [],
    geoRegions: [],
    clientTypes: [],
    sectors: [],
    revenueSegment: {
        id: null,
    },
}) as BusinessUnitFormValue

const MultipleCheckbox = memo(({
    field,
    form,
    optionValue,
    isLimited,
}: MultipleCheckboxDataFieldModel<any>) => {
    const wasSelectedInitially = get(form.initialValues, field.name, []).find(option => option.id == optionValue.id)

    return <Checkbox
        value={field.value}
        checked={(field.value || []).find(option => option.id == optionValue.id)}
        disabled={isLimited && wasSelectedInitially}
        onChange={event => {
            let newValue = [ ...field.value ]
            if (event.target.checked) newValue.push(optionValue)
            else newValue = newValue.filter(option => option.id != optionValue.id)
            return form.setFieldValue(field.name, newValue)
        }}
    />
})

const SelectAllCheckbox = memo(({
    optionValue,
    field,
    form,
    isLimited,
}: any) => {
    const initialOptions = get(form.initialValues, field.name)

    return <Checkbox
        checked={optionValue.length === (field.value || []).length}
        disabled={isLimited && (initialOptions.length === optionValue.length)}
        onChange={event => {
            let newValue = optionValue
            if (!event.target.checked) newValue = isLimited ? initialOptions : []
            return form.setFieldValue(field.name, newValue)
        }}
    />
})

const industryLabelError = {
    color: '#E63637',
}

const industryLineError = {
    borderColor: '#E63637',
}

interface EditModeModel {
    isLimited: boolean
    industries: Industries
    businessUnit: BusinessUnitFormValue
    clientTypes: LeadTypes
    sectors: Sectors
    geoRegions: GeoRegions
    hasLBModel: boolean
    hasPPSModel: boolean
    partner: Partner
    index: number
    revenueSegments: RevenueSegments
    businessUnitQuotaPrice: Amount
    setRevenueSegmentId: (any) => any
    errors: any
}

class EditMode extends PureComponent<EditModeModel> {
    state = {
        leadCategoryId: null,
    }

    setRevenueSegment = event => {
        this.props.setRevenueSegmentId(event)

        const selectedValue = get(event, 'target.value')
        this.setState({
            leadCategoryId: this.props.revenueSegments[selectedValue].leadCategory.id,
        })
    }

    render() {
        const {
            isLimited,
            industries,
            businessUnit,
            clientTypes,
            sectors,
            geoRegions,
            hasLBModel,
            hasPPSModel,
            index,
            revenueSegments,
            businessUnitQuotaPrice,
            errors,
        } = this.props

        const childIndustries = Object.values(industries).filter(industry => Number(industry.parentIndustryId) !== 0)

        return <Fragment>
            {!isLimited && <Fragment>
                <BasicBusinessUnitInfoGrid>
                    <Field
                        label="Revenue segment"
                        name={`businessUnits.${index}.revenueSegment.id`}
                        returnId={true}
                        component={SelectInputGroup}
                        removeClassFormGroup={true}
                        onChange={this.setRevenueSegment}
                    >
                        <option selected={!get(businessUnit, 'revenueSegment.id')} value="">Select ...</option>
                        {Object.values(revenueSegments).map(revenueSegment => <option
                            key={revenueSegment.id}
                            value={revenueSegment.id}
                            disabled={Boolean(revenueSegment.archivedAt)}
                            hidden={Boolean(revenueSegment.archivedAt)}
                        >
                            {revenueSegment.displayName}
                        </option>)}
                    </Field>
                </BasicBusinessUnitInfoGrid>
                {this.state.leadCategoryId !== 2 && <Fragment>
                    <Card.Label><strong>Business models</strong></Card.Label>
                    <Card.Line modifiers="mB_1"/>
                    <BasicGridRow repeat={3}>
                        <div>
                            <MetaLabel>LB Model</MetaLabel>
                            <Field
                                name={`businessUnits.${index}.allocationQuota`}
                                component={ToggleCheckbox}
                                value={{}}
                                checked={hasLBModel}
                            />
                        </div>
                        <div>
                            <MetaLabel>PPS Model</MetaLabel>
                            <Field
                                name={`businessUnits.${index}.allocationLimit`}
                                component={ToggleCheckbox}
                                value={{}}
                                checked={hasPPSModel}
                            />
                        </div>
                    </BasicGridRow>
                </Fragment>}
                {hasLBModel &&
                <Flex>
                    <View mr={3}>
                        <Field
                            label="LB quota"
                            name={`businessUnits.${index}.allocationQuota.count`}
                            component={FieldGroup}
                            type="number"
                            addon="The allocation quota for the whole delivery period"
                            defaultValue={1000}
                        />
                    </View>
                    <View>
                        <Field
                            validate={required}
                            label="LB Quota price"
                            name={`businessUnits.${index}.allocationQuota.contractPriceExclVat`}
                            type="number"
                            component={FieldGroup}
                            addon="The contract price for this business unit"
                        />
                        <br/>
                        <Card.Text>
                            This business unit's price per invoice:
                            <FormattedAmount
                                amount={businessUnitQuotaPrice}
                                format={'0,0.00'}
                            />
                        </Card.Text>
                    </View>
                </Flex>
                }
                {hasPPSModel &&
                <Flex>
                    <View mr={3}>
                        <Field
                            label="PPS limit"
                            name={`businessUnits.${index}.allocationLimit.count`}
                            type="number"
                            component={FieldGroup}
                            addon="The PPS limit per period"
                        />
                    </View>
                    <Field
                        label="PPS Price"
                        name={`businessUnits.${index}.allocationLimit.priceExclVat.amount`}
                        type="number"
                        component={FieldGroup}
                        addon="The price per PPS lead"
                    />
                </Flex>
                }
                <br/>
            </Fragment>}
            <Card.Label
                style={errors.businessUnits && errors.businessUnits[index] && errors.businessUnits[index].industries ? industryLabelError : {}}
            >
                <strong>Industries</strong>
            </Card.Label>
            <Card.Line
                style={errors.businessUnits && errors.businessUnits[index] && errors.businessUnits[index].industries ? industryLineError : {}}
                modifiers="mB_1"
            />
            <Row>
                <FlexCol md={2}>
                    <Field
                        name={`businessUnits.${index}.industries`}
                        component={SelectAllCheckbox}
                        optionValue={childIndustries.map(el => ({ id: el.id }))}
                        isLimited={isLimited}
                    />
                    <Card.Text><b>Select all</b></Card.Text>
                </FlexCol>
                <FieldArray
                    name={`businessUnits.${index}.industries`}
                    render={() => (
                        childIndustries.map((industry, i) => (
                            <FlexCol md={2} key={'industry_' + i}>
                                <Field
                                    name={`businessUnits.${index}.industries`}
                                    component={MultipleCheckbox}
                                    optionValue={{ id: industry.id }}
                                    isLimited={isLimited}
                                />
                                <Card.Text>{industry.name}</Card.Text>
                            </FlexCol>
                        )
                        ))}
                />
            </Row>
            <br/>
            <Card.Label><strong>Sectors</strong></Card.Label>
            <Card.Line modifiers="mB_1"/>
            <Row>
                <FlexCol md={2}>
                    <Field
                        name={`businessUnits.${index}.sectors`}
                        component={SelectAllCheckbox}
                        optionValue={Object.values(sectors).filter(sector => !sector.archivedAt).map(el => ({ id: el.id }))}
                    />
                    <Card.Text><b>Select all</b></Card.Text>
                </FlexCol>
                <FieldArray
                    name={`businessUnits.${index}.sectors`}
                    render={() => (
                        Object.values(sectors).filter(sector => !sector.archivedAt).map((sector, i) => (
                            <FlexCol md={2} key={'sector_' + i}>
                                <Field
                                    name={`businessUnits.${index}.sectors`}
                                    component={MultipleCheckbox}
                                    optionValue={{ id: sector.id }}
                                />
                                <Card.Text>{sector.displayName}</Card.Text>
                            </FlexCol>
                        ))
                    )}
                />
            </Row>
            <br/>
            <Card.Label><strong>Geo regions</strong></Card.Label>
            <Card.Line modifiers="mB_1"/>
            <Row>
                <ul className="list-unstyled georegion-grid">
                    <li>
                        <Field
                            name={`businessUnits.${index}.geoRegions`}
                            component={SelectAllCheckbox}
                            optionValue={Object.values(geoRegions).map(el => ({ id: el.id }))}
                        />
                        <Card.Text><b>Select all</b></Card.Text>
                    </li>
                    {sortAscending(Object.values(geoRegions), 'regionName').map((geoRegion, i) =>
                        <li key={'geoRegion_' + i}>
                            <Field
                                name={`businessUnits.${index}.geoRegions`}
                                component={MultipleCheckbox}
                                optionValue={{ id: geoRegion.id }}
                            />
                            <Card.Text>{geoRegion.regionName}</Card.Text>
                        </li>
                    )}
                </ul>
            </Row>
        </Fragment>
    }
}

export default EditMode as unknown as ComponentType<EditModeModel>
