import React, { memo } from 'react'

import { Cell, Column, Header, Rows, Table } from '../../elements/table'
import { Card } from '../../blocks/Card'
import LinkCell from '../../elements/table/cells/LinkCell'

const PartnerRow = memo(({ rowData }: {rowData?: {partner}}) => {
    const { partner } = rowData
    return <tr key={partner.id}>
        <LinkCell to={'partners/' + partner.id}>{partner.id}</LinkCell>
        <Cell>{partner.companyName}</Cell>
        <Cell>
            {partner.zipCode} 
            {' '}
            {partner.city}
        </Cell>
        <Cell>{partner.partnerState.replace(/_/g, ' ')}</Cell>
    </tr>
})

const PartnersTable = ({ partners }) =>
    <Card.Content>
        <Table>
            <Header>
                <Column>id</Column>
                <Column>Company name</Column>
                <Column>Location</Column>
                <Column>Status</Column>
            </Header>
            <Rows
                items={partners}
                noDataText="No Partners"
            >
                <PartnerRow />
            </Rows>
        </Table>
    </Card.Content>

export default memo(PartnersTable)
