import { createSelector } from 'reselect'

export const invoicesEntities = state => state.entities.invoices

export const allInvoicesId = state => state.subjects.invoices.all.ids

export const dueInvoicesId = state => state.subjects.invoices.due.ids

export const allInvoicesSelector = createSelector(
    allInvoicesId, invoicesEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const dueInvoicesSelector = createSelector(
    dueInvoicesId, invoicesEntities,
    (ids, entities) => ids.map(id => entities[id])
)
