import React, { memo } from 'react'
import styled from 'styled-components'
import { CheckboxInputField } from '../../../elements/forms/inputs/CheckboxGroup'
import { SelectInputGroup } from '../../../elements/forms/inputs/SelectInputGroup'
import { FieldGroup } from '../../../elements/forms/inputs/FieldGroup'
import { Field } from 'redux-form'
import { Auth } from '../../../../reducers/auth'
import config from '../../../../config'

const GridFieldGroup = styled.div`
  display: grid;
  grid-gap: 20px;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  .form-group {
    margin-bottom: 0;
  }
`

interface QuotesSetupFieldsPropsModel {
    createQuoteFeePermission: string
    disableQuoteAmount: boolean
    disableAmount: boolean
    isServiceFee: boolean
    wantedGeoCode?: Auth['wantedGeoCode'],
    handleMarketplaceToggle: () => void,
    showForMarketplace: boolean
}
export const QuotesSetupFields = memo(({
    createQuoteFeePermission,
    disableQuoteAmount,
    disableAmount,
    isServiceFee,
    wantedGeoCode,
    handleMarketplaceToggle,
    showForMarketplace
}: QuotesSetupFieldsPropsModel) =>
    <GridFieldGroup>
        <Field
            label="Quotes max"
            name="quotesInfo.max"
            returnId={true}
            disabled={disableQuoteAmount}
            component={SelectInputGroup}
        >
            {[ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 999 ]
                .map(value =>
                    <option value={value} key={'quote_max_' + value}>
                        {value && value !== 999 ? `${value} quote${value > 1 ? 's' : ''}` :
                            value ? 'No quote limit' : 'Zero quotes'}
                    </option>
                )
            }
        </Field>
        {wantedGeoCode && config.marketplaceCountries.includes(wantedGeoCode) &&
            <CheckboxInputField
                text="Show in marketplace"
                input={{
                    value: showForMarketplace,
                    onChange: handleMarketplaceToggle,
                }}
            />
        }
        {isServiceFee &&
        <Field
            label="Service fee"
            name="serviceFee.amount"
            component={FieldGroup}
            disabled={disableAmount}
            type="number"
        />}
    </GridFieldGroup>
)
