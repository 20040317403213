import React, { memo, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Card } from '../../blocks/Card'
import LoadingBar from '../../elements/LoadingBar'
import { Flex } from '../../elements/Flex'
import { Field, FormSection } from 'redux-form'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import { DateTimePickerGroup } from '../../elements/forms/inputs/DateTimePickerGroup'
import { Button } from '../../elements/Button'
import moment from 'moment'
import ReduxForm from '../../elements/forms/ReduxForm'
import { Model as BusinessUnitSegmentationModel } from './BusinessUnitSegmentation'
import { dangerColor } from '../../../utils/variables'
import Checkbox from '../../blocks/Checkbox'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import { AsyncTypeaheadGroup } from '../../elements/forms/inputs/AsyncTypeaheadGroup'
import Placeholder from '../../elements/Placeholder'
import ToggleCheckboxGroup from '../../elements/forms/inputs/ToggleCheckboxGroup'
import { get } from 'lodash'
import sortAscending from '../../../utils/sortAscending'

const BasicGrid = styled.div`
  align-items: flex-start;
  display: grid;
  grid-gap: 30px;
`
const BasicGridRow = styled(BasicGrid)<{repeat?: number}>`
  grid-template-columns: repeat(${({ repeat }) => repeat || 2 }, 200px);
`

const BasicBusinessUnitInfoGrid = styled.div`
  display: grid;
  gap: 20px;
  > * {
    &:nth-child(1) { grid-area: a;}
    &:nth-child(2) { grid-area: b;}
    &:nth-child(3) { grid-area: c;}
    &:nth-child(4) { grid-area: d;}
    &:nth-child(5) { grid-area: e;}
    &:nth-child(6) { grid-area: f;}
  }
  grid-template-areas: "a a b" "c c c" "d e f";
  max-width: 1120px;
`

const TargetContainer = styled(BasicGrid)`
  grid-template-columns: 430px 1fr;
`

const FlexCol = styled(Col)`
  display: flex;
`

const DeleteIcon = styled.i.attrs({ className: 'mdi mdi-close' })`
  line-height: 22px;
  font-size: 18px;
  margin-left: 15px;
  color: ${dangerColor};
  cursor: pointer;
`

interface MultipleCheckboxDataFieldModel {
    input: any
    optionValue: number | string
}

const renderZipCodeMenuItem = option => `${option.zipCode}, ${option.cityName}`

const MultipleCheckbox = memo(({
    optionValue,
    input,
}: MultipleCheckboxDataFieldModel) =>
    <Checkbox
        value={optionValue}
        checked={(input.value || []).find(id => id == optionValue)}
        onChange={event => {
            let newValue = [ ...input.value ]
            if (event.target.checked) newValue.push(optionValue)
            else newValue = newValue.filter(id => id != optionValue)
            return input.onChange(newValue)
        }}
    />
)

const SelectAllCheckbox = memo(({
    optionValue,
    input,
}: any) =>
    <Checkbox
        value={optionValue}
        checked={optionValue.length === input.value.length}
        onChange={event => {
            let newValue = optionValue
            if (!event.target.checked) newValue = []
            return input.onChange(newValue)
        }}
    />
)

type SegmentationTemplateModel = Partial<BusinessUnitSegmentationModel> & {
    handleFormSubmit
    deleteAllocationQuota
    onGoBack
    onSave
    isLoading,
    hasLBModel,
    hasPPSModel,
}

const SegmentationEditMode = ({
    businessModels,
    handleFormSubmit,
    onGoBack,
    onSave,
    businessUnitLeadTypesId,
    businessUnitIndustriesId,
    businessUnitSectorsId,
    allocationsQuotas,
    industries,
    businessUnit,
    isLoading,
    leadTypes,
    sectors,
    deleteAllocationQuota,
    businessUnitGeoRegionsId,
    geoRegions,
    segmentationGeoRegionsListPermission,
    allocationLimit,
    getLocations,
    hasLBModel,
    hasPPSModel,
    isLoadingBusinessModels,
    isLoadingLocations,
    locations,
    partnerUsers,
    segmentationForm,
}: SegmentationTemplateModel) =>
    <Fragment>
        <LoadingBar />
        <Card.Content isLoading={isLoading}>
            <ReduxForm
                form="segmentationForm"
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                initialValues={{
                    revenueRange: businessUnit.desiredRevenueRange,
                    geoRegions: businessUnitGeoRegionsId,
                    industries: businessUnitIndustriesId,
                    leadTypes: businessUnitLeadTypesId,
                    sectors: businessUnitSectorsId,
                    geoRange: businessUnit.geoRange,
                    allocationLimit,
                    unit: {
                        id: businessUnit.id,
                        businessModels: businessUnit.businessModels,
                        displayName: businessUnit.displayName,
                        partnerUserId: businessUnit.partnerUserId,
                        geo: {
                            address: businessUnit.geo.address,
                            zipCode: businessUnit.geo.zipCode,
                            cityName: businessUnit.geo.cityName,
                            point: businessUnit.geo.point,
                        },
                    },
                }}
            >
                <FormSection name="unit">
                    <BasicBusinessUnitInfoGrid>
                        <Field
                            name="displayName"
                            label="Name of unit"
                            component={FieldGroup}
                            removeClassFormGroup={true}
                        />
                        <Field
                            label="Assigned partner user"
                            name="partnerUserId"
                            returnId={true}
                            component={SelectInputGroup}
                            removeClassFormGroup={true}
                        >
                            {partnerUsers.map(user => <option
                                key={user.id}
                                value={user.id}
                            >
                                {user.firstName} 
                                {' '}
                                {user.lastName}
                            </option>)}
                        </Field>
                        <Field
                            name="geo.address"
                            label="Address"
                            component={FieldGroup}
                            type="text"
                            removeClassFormGroup={true}
                        />
                        <Field
                            component={AsyncTypeaheadGroup}
                            name="geo"
                            label="Zip code"
                            labelKey="zipCode"
                            extraData={{ address: businessUnit.geo.address }}
                            isLoading={isLoadingLocations}
                            options={locations}
                            renderMenuItemChildren={renderZipCodeMenuItem}
                            onSearch={getLocations}
                            defaultSelected={[ {
                                zipCode: businessUnit.geo.zipCode,
                                cityName: businessUnit.geo.cityName,
                            } ]}
                        />
                        <Field
                            name="geo.cityName"
                            label="City"
                            component={FieldGroup}
                            disabled={true}
                            removeClassFormGroup
                        />
                        <Field
                            name="geo.regionName"
                            label="Region"
                            component={FieldGroup}
                            disabled={true}
                            removeClassFormGroup
                        />
                    </BasicBusinessUnitInfoGrid>
                    <Card.Line modifiers="mB_1"/>
                    {isLoadingBusinessModels
                        ? <Placeholder />
                        : <Field
                            name="businessModels"
                            component={ToggleCheckboxGroup}
                            options={businessModels}
                            titleName="abbreviation"
                        />
                    }
                </FormSection>
                <br />
                <Card.Label><strong>Targets</strong></Card.Label>
                <Card.Line modifiers="mB_1"/>
                <TargetContainer>
                    <div>
                        <FormSection name="revenueRange">
                            <BasicGridRow>
                                <Field
                                    label="Revenue range from"
                                    name="minimumRevenue"
                                    type="number"
                                    component={FieldGroup}
                                />
                                <Field
                                    label="Revenue range to"
                                    name="maximumRevenue"
                                    type="number"
                                    component={FieldGroup}
                                />
                            </BasicGridRow>
                        </FormSection>
                        <Field
                            removeClassFormGroup
                            label="Max lead distance"
                            name="geoRange"
                            returnId={true}
                            component={SelectInputGroup}
                        >
                            {[ 10, 20, 30, 40, 50, 75, 100, 250, 500 ].map(km =>
                                <option key={km + '_km'} value={km}>
                                    {km}
                                    {' '}
                                    km
                                </option>
                            )}
                            <option value={0}>Unlimited</option>
                        </Field>
                    </div>
                    <div>
                        {hasPPSModel(get(segmentationForm, 'unit')) && <FormSection name="allocationLimit">
                            <BasicGridRow repeat={3}>
                                <Field
                                    label="PPS limit"
                                    name="limit"
                                    type="number"
                                    component={FieldGroup}
                                />
                                <Field
                                    label="PPS Price"
                                    name="price.amount"
                                    type="number"
                                    component={FieldGroup}
                                />
                                <Field
                                    label="Effective at"
                                    name="effectiveAt"
                                    dateFormat="YYYY-MM-DD"
                                    component={DateTimePickerGroup}
                                />
                            </BasicGridRow>
                        </FormSection>}
                        {hasLBModel(get(segmentationForm, 'unit')) && <FormSection name="targetAllocation">
                            <BasicGridRow repeat={3}>
                                <Field
                                    removeClassFormGroup
                                    label="LB quota"
                                    name="allocationQuotaPerRefill"
                                    component={FieldGroup}
                                    type="number"
                                />
                                <Field
                                    removeClassFormGroup
                                    label="Update from"
                                    name="effectiveAt"
                                    dateFormat="YYYY-MM"
                                    component={DateTimePickerGroup}
                                />
                                <div>
                                    <Card.Label><strong>Scheduled LB quotas</strong></Card.Label>
                                    {allocationsQuotas.map(quota =>
                                        <Flex modifiers="justifySpaceBetween">
                                            <Card.Text key={'quota_' + quota.id}>
                                                {quota.allocationQuotaPerRefill}
                                                {' '}
                                                from
                                                {moment(quota.effectiveAt).format('MMMM-YYYY')}
                                            </Card.Text>
                                            <DeleteIcon onClick={() => deleteAllocationQuota(quota.id)}/>
                                        </Flex>
                                    )}
                                </div>
                            </BasicGridRow>
                        </FormSection>}
                    </div>
                </TargetContainer>
                <br />
                <Card.Label><strong>Industries</strong></Card.Label>
                <Card.Line modifiers="mB_1"/>
                <Row>
                    <FlexCol md={2}>
                        <Field
                            name="industries"
                            component={SelectAllCheckbox}
                            optionValue={industries.map(el => el.id)}
                        />
                        <Card.Text><b>Select all</b></Card.Text>
                    </FlexCol>
                    {industries.map((industry, i) =>
                        <FlexCol md={2} key={'industry_' + i}>
                            <Field
                                name="industries"
                                component={MultipleCheckbox}
                                optionValue={industry.id}
                            />
                            <Card.Text>{industry.name}</Card.Text>
                        </FlexCol>
                    )}
                </Row>
                <br />
                <Card.Label><strong>Sectors</strong></Card.Label>
                <Card.Line modifiers="mB_1"/>
                <Row>
                    <FlexCol md={2}>
                        <Field
                            name="sectors"
                            component={SelectAllCheckbox}
                            optionValue={sectors.map(el => el.id)}
                        />
                        <Card.Text><b>Select all</b></Card.Text>
                    </FlexCol>
                    {sectors.map((sector, i) =>
                        <FlexCol md={2} key={'sector_' + i}>
                            <Field
                                name="sectors"
                                component={MultipleCheckbox}
                                optionValue={sector.id}
                            />
                            <Card.Text>{sector.displayName}</Card.Text>
                        </FlexCol>
                    )}
                </Row>
                <br />
                <Card.Label><strong>Lead Types</strong></Card.Label>
                <Card.Line modifiers="mB_1"/>
                <Row>
                    <FlexCol md={2}>
                        <Field
                            name="leadTypes"
                            component={SelectAllCheckbox}
                            optionValue={leadTypes.map(el => el.id)}
                        />
                        <Card.Text><b>Select all</b></Card.Text>
                    </FlexCol>
                    {leadTypes.map((leadType, i) =>
                        <FlexCol md={2} key={'leadType_' + i}>
                            <Field
                                name="leadTypes"
                                component={MultipleCheckbox}
                                optionValue={leadType.id}
                            />
                            <Card.Text>{leadType.title}</Card.Text>
                        </FlexCol>
                    )}
                </Row>
                {segmentationGeoRegionsListPermission && <Fragment>
                    <br />
                    <Card.Label><strong>Geo regions</strong></Card.Label>
                    <Card.Line modifiers="mB_1"/>
                    <Row>
                        <ul className="list-unstyled georegion-grid">
                            <li>
                                <Field
                                    name="geoRegions"
                                    component={SelectAllCheckbox}
                                    optionValue={geoRegions.map(el => el.id)}
                                />
                                <Card.Text><b>Select all</b></Card.Text>
                            </li>
                            {sortAscending(geoRegions, 'regionName').map((geoRegion, i) =>
                                <li key={'geoRegion_' + i}>
                                    <Field
                                        name="geoRegions"
                                        component={MultipleCheckbox}
                                        optionValue={geoRegion.id}
                                    />
                                    <Card.Text>{geoRegion.regionName}</Card.Text>
                                </li>
                            )}
                        </ul>
                    </Row>
                </Fragment>}
                <Card.Line modifiers="mY_2"/>
                <Flex>
                    <Button modifiers="secondary" onClick={onGoBack}>Go back</Button>
                    <Button modifiers={[ 'action', 'mL_2' ]} onClick={onSave}>Save</Button>
                </Flex>
            </ReduxForm>
        </Card.Content>
    </Fragment>

export default memo(SegmentationEditMode)
