import { InfoWindow, Marker } from 'react-google-maps'
import React, { memo, useState } from 'react'

interface PartnerMarkerModel {
    item
    onPinClick?: (id?) => void
    selectedId?: number | string
    pinLink: string
    isSingle?: boolean
}

const PartnerMarker = (
    { item, onPinClick, selectedId, pinLink, isSingle }: PartnerMarkerModel
) => {
    const [ id, selectId ] = useState(null)
    const position = {
        lat: item.geo.point.lat,
        lng: item.geo.point.lon,
    }
    const icon = {
        url: `/assets/images/${item.partnerId
            ? item.partner.isSubscriptionPaused ? 'pause-bu-pin' : 'bu-pin'
            : 'small-pin'
        }.svg`,
    }
    const onMarkerClick = () => onPinClick ? onPinClick(item.id) : selectId(item.id)
    const onCloseClick = () => onPinClick ? onPinClick () : selectId(null)
    const partnerId = selectedId || id

    return <Marker
        key={item.id}
        position={position}
        icon={isSingle || icon}
        onClick={onMarkerClick}
    >
        {item.id == partnerId && <InfoWindow onCloseClick={onCloseClick}>
            <div>
                {item.partnerId
                    ? <a href={`/${pinLink}/${item.partnerId}`} target="_blank" rel="noreferrer"><b>{item.displayName}</b></a>
                    : <a href={`/${pinLink}/${item.id}`} target="_blank" rel="noreferrer"><b>{item.companyName}</b></a>
                }
                <div>
                    {item.geo.address && (item.geo.address + ', ')}
                    {item.geo.zipCode}
&nbsp;
                    {item.geo.cityName}
                </div>
            </div>
        </InfoWindow>}
    </Marker>
}

export default memo(PartnerMarker)
