import { createSelector } from 'reselect'

const entitiesBusinessUnits = state => state.entities.businessUnits

const matchReportMatchIds = state => state.pages.matchReport.matches

export const matchReportsSelector = createSelector(
    entitiesBusinessUnits, matchReportMatchIds,
    (businessUnitIds, matches) =>
        Object.keys(matches).map(buId =>
            ({
                ...businessUnitIds[buId],
                matches: matches[buId],
            })
        )
)
