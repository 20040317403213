import React, { ComponentType, PureComponent } from 'react'
import { connect } from '../../../utils/connect'
import { createKPITargets } from '../../../actions/kpi'
import { FormControl } from 'react-bootstrap'
import { RowElement } from './ColumnKPI'

interface DelegatedPropsModel {
    cellChange: (cellValue: number, employeeId:number, calculate: true) => void
    employeeId: number
    dividedValue: number
    cellIdentifier: string
}

interface CellKPIPropsModel extends DelegatedPropsModel{
    cellValue: number
    createKPITargets: typeof createKPITargets
}

interface CellKPIStateModel {
    cellValue: number
}

@connect<any, any, DelegatedPropsModel>(
    (state, props) => ({
        cellValue: state.entities.kpiTargets[props.cellIdentifier]
            ? state.entities.kpiTargets[props.cellIdentifier].value
            : 0,
    }),
    {
        createKPITargets,
    }
)
class CellKPI extends PureComponent<CellKPIPropsModel, CellKPIStateModel> {
    constructor(props) {
        super(props)
        this.state = {
            cellValue: props.cellValue,
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.cellValue !== prevProps.cellValue) {
            this.setState({ cellValue: this.props.cellValue })
        } else if(this.props.dividedValue !== prevProps.dividedValue) {
            this.setState({ cellValue: this.props.dividedValue })
        }
    }

    onBlur = e => {
        const cellValue = e.target.value
        if(cellValue && this.props.cellValue != cellValue)
            this.props.cellChange(cellValue, this.props.employeeId, true)
    }

    formControlOnChange = e => {
        this.setState({ cellValue: Number(e.target.value) })
    }

    render() {
        return <RowElement>
            <FormControl
                value={this.state.cellValue}
                type="number"
                onBlur={this.onBlur}
                onChange={this.formControlOnChange}
            />
        </RowElement>
    }
}

export default CellKPI as unknown as ComponentType<DelegatedPropsModel>
