import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const CREATE_PARTNER_LANGUAGE = 'languages/CREATE_PARTNER_LANGUAGE'
export const CREATE_PARTNER_LANGUAGE_SUCCESS = 'languages/CREATE_PARTNER_LANGUAGE_SUCCESS'
export const CREATE_PARTNER_LANGUAGE_FAIL = 'languages/CREATE_PARTNER_LANGUAGE_FAIL'

export const DELETE_LANGUAGE = 'languages/DELETE_LANGUAGE'
export const DELETE_LANGUAGE_SUCCESS = 'languages/DELETE_LANGUAGE_SUCCESS'
export const DELETE_LANGUAGE_FAIL = 'languages/DELETE_LANGUAGE_FAIL'

export const GET_CV = 'languages/GET_CV'
export const GET_CV_SUCCESS = 'languages/GET_CV_SUCCESS'
export const GET_CV_FAIL = 'languages/GET_CV_FAIL'

interface CreatePartnerUserLanguagePropsModel {
    identifier: string
    partnerUserId: number,
    proficiency: string,
}

export const createPartnerUserLanguage = (
    partnerId: number,
    body: CreatePartnerUserLanguagePropsModel,
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_LANGUAGE, CREATE_PARTNER_LANGUAGE_SUCCESS, CREATE_PARTNER_LANGUAGE_FAIL ],
        endpoint: `/cv/${partnerId}/language`,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerUserLanguage = (
    partnerId: number,
    languageId: number,
) => ({
    [CALL_API]: {
        types: [ DELETE_LANGUAGE, DELETE_LANGUAGE_SUCCESS, DELETE_LANGUAGE_FAIL ],
        endpoint: `/cv/${partnerId}/language/${languageId}`,
        options: {
            method: 'delete',
        },
    },
})

export const getPartnerUserCv = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: [ GET_CV, GET_CV_SUCCESS, GET_CV_FAIL ],
        endpoint: `/cv/${partnerId}`,
        schema: schemas.cv,
    },
})
