import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import entities from './entities'
import auth from './auth'
import { reducer as form } from 'redux-form'
import { reducer as toastr } from 'react-redux-toastr'
import pages from './pages/index'
import loader from './loader'
import modal from './modal'
import subjects from '../state/subjects'

const rootReducer = (history) => combineReducers({
    auth,
    entities,
    pages,
    subjects,
    modal,
    loader,
    form,
    toastr,
    router: connectRouter(history),
})

export default rootReducer
