import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_INVOICINGS = 'invoicings/GET_INVOICINGS'
export const GET_INVOICINGS_SUCCESS = 'invoicings/GET_INVOICINGS_SUCCESS'
export const GET_INVOICINGS_FAIL = 'invoicings/GET_INVOICINGS_FAIL'

interface GetInvoicingsPropsModel {
    businessModel: string
    partnerId?: number[]
    employeeId?: number[]
    processAtGte?: string
}
export const getInvocings = (
    criteria: GetInvoicingsPropsModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [ GET_INVOICINGS, GET_INVOICINGS_SUCCESS, GET_INVOICINGS_FAIL ],
        endpoint: '/partners/invoicings',
        schema: schemas.invoicings,
        options: {
            body: {
                limit: 200,
                ...criteria,
            },
        },
    },
    append,
})
