import { createSelector } from 'reselect'
import { values } from 'lodash'
import moment from 'moment'

const entitiesSubscriptions = state => state.entities.subscriptions
const getId = (onpopstate,  id) => id

export const subscriptionsSelector = createSelector(
    entitiesSubscriptions, getId,
    (subscriptions, partnerId) => values(subscriptions).reverse().filter(el => el.partnerId == partnerId)
)

export const actualSubscriptionSelector = createSelector(
    subscriptionsSelector,
    (subscriptions) => {
        const createdSubscription = subscriptions
            .find(sub =>
                sub.startsAt &&
                moment().isBefore(sub.expiresAt) &&
                (!sub.canceledAt || moment().isBefore(sub.canceledAt)) &&
                (!sub.churnedAt || moment().isBefore(sub.churnedAt))
            )

        const isActiveSubscription = createdSubscription && moment().isSameOrAfter(createdSubscription.startsAt)

        const canceledSubscription = isActiveSubscription ? null : subscriptions.find(sub => Boolean(sub.churnedAt) || Boolean(sub.canceledAt))

        const isAvailableForSubscription = !canceledSubscription ||
            canceledSubscription && moment().isAfter(canceledSubscription.churnedAt || canceledSubscription.canceledAt)

        return {
            createdSubscription,
            isActiveSubscription,
            canceledSubscription,
            isAvailableForSubscription,
        }
    }
)
