import React, { memo, } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { Notification } from '../../../reducers/types/entities/Notification'
import styled from 'styled-components'

const MarkAsRead = styled.span`
  color: red;
`

const DropDown = styled(NavDropdown)`
  > a {
    line-height: 0!important;
  }
  ul {
    max-height: 400px;
    overflow-y: auto;
    li {
        &:first-child {
          position: sticky;
          top: -3px;
          background: #fff;
        }
        &:nth-child(2){
          position: sticky;
          top: 24px;
        }
    }
  }
`

const Badge = styled.span`
    position: absolute;
    padding: 2px;
    font-size: 10px;
    right: 4px;
    top: 2px;
    min-width: 17px;
    line-height: 12px;
    background-color: #21a9e1;
    font-weight: 400;
    color: #fff;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
`

const NotificationHeader = ({ count }) =>
    <span>
        <i className="mdi mdi-bell-outline mdi-24px"/>
        {Boolean(count) && <Badge>{12}</Badge>}
    </span>

interface NotificationsDropdownModel {
    notifications: Notification[]
    total: number
    markAsRead()
}

const NotificationsDropdown = memo(({
    notifications,
    markAsRead,
    total,
}: NotificationsDropdownModel) =>
    <DropDown
        noCaret
        eventKey={8}
        title={<NotificationHeader count={total}/>}
        id="basic-nav-dropdown-Notifications"
    >
        <MenuItem>
            { Boolean(total)
                ? <span>
                    You have
                    <b>{total}</b>
                    {' '}
                    new notification
                    <MarkAsRead onClick={markAsRead}>Mark all Read</MarkAsRead>
                </span>
                : 'No new notifications'
            }
        </MenuItem>
        {Boolean(total) && <MenuItem divider />}
        {notifications.map(notification =>
            <MenuItem
                href={'/notification/read/' + notification.id}
                eventKey={notification.id}
                key={notification.id}
            >
                {notification.message}
            </MenuItem>
        )}
    </DropDown>
)

export default NotificationsDropdown
