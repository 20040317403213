import React, { memo, PureComponent } from 'react'
import { Label } from 'react-bootstrap'
import config from '../../config'
import { getInvocings } from '../../actions/invocings'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { Card } from '../blocks/Card'
import ProviderTable from '../blocks/ProviderTable'
import {
    Cell,
    LinkCell,
    DateCell,
    DropdownFilter,
    TextFilter, SortColumn, Column,
} from '../elements/table'
import BusinessModelLabel from '../elements/BusinessModelLabel'
import { FormattedAmount } from '../elements/FormattedAmount'
import styled from 'styled-components'
import { connect } from '../../utils/connect'
import { Invoicing, Invoicings } from '../../reducers/types/entities/Invoicing'
import DateFilter from '../elements/table/filters/DateFilter'
import moment from 'moment'
import { invoicingListSelector } from '../../selectors/invoicings'
import { Employee } from '../../reducers/types/entities/Employee'
import { employeesByGeoCodeSelector } from '../../selectors/employees'

const BUSINESS_MODELS = [
    { id: 'lead_budget', name: 'LB', abbreviation: 'lb', badgeName: 'Lead budget allocations' },
    { id: 'pay_per_spot', name: 'PPS', abbreviation: 'pps', badgeName: 'Pay per spot allocations' },
]

const InvoicingHeading = styled.span`
    display: inline-block;
    margin-right: 20px;
`

const InvoicingRow = memo((props: { rowData?: Invoicing }) => {
    const invoicing = props.rowData

    const returnStatusBadge = (line: Invoicing) => {
        if (line.processedAt) {
            return <Label bsStyle="success">Processed</Label>
        }
        return <Label bsStyle="info">Unprocessed</Label>
    }

    return <ProviderTable.Row {...props}>
        <Cell>{returnStatusBadge(invoicing)}</Cell>
        <LinkCell to={`/partners/${invoicing.partner.id}`}>{invoicing.partner.id}</LinkCell>
        <Cell>{invoicing.partner.companyName}</Cell>
        <DateCell date={invoicing.processAt} format="date" />
        {invoicing.voucher
            ? <Cell><a href={`/vouchers/${invoicing.voucher.id}`}>See invoice</a></Cell>
            : <Cell>{invoicing.processedAt ? <Label bsStyle="warning">No Invoice</Label> : ''}</Cell>}
        {(invoicing.voucher && invoicing.voucher.paidAt)
            ? <DateCell date={invoicing.voucher.paidAt} format="date" />
            : <Cell />}
        <Cell><FormattedAmount amount={invoicing.amountExclVat} /></Cell>
    </ProviderTable.Row>
})

interface Criteria {
    businessModel: string
    partnerId: string
    employeeId: string[]
    sort: string
    processAtGte: string
    processAtLte: string
    visibleColumns: string[]
}

interface InvocingsListStateProps {
    invoicings: Invoicings
    employees: Employee[]
    criteria: Criteria
}

interface InvocingsListDispatchProps {
    getInvocings: typeof getInvocings
}

interface InvocingsListPathProps {
    location: {
        query: Criteria
    }
}

type BusinessUnitListModel = InvocingsListStateProps & InvocingsListDispatchProps & InvocingsListPathProps

@connect<InvocingsListStateProps, InvocingsListDispatchProps, InvocingsListPathProps>(
    (state, { location: { query } }) => ({
        invoicings: invoicingListSelector(state),
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        criteria: {
            visibleColumns: splitFilterString(
                query.visibleColumns || [ 'status', 'partner_id', 'partner_name', 'process_at',
                    'invoice', 'invoice_paid_at', 'price' ]
            ),
            businessModel: query.businessModel || 'lead_budget',
            partnerId: query.partnerId || '',
            employeeId: splitFilterString(query.employeeId),
            sort: query.sort || 'process_at',
            processAtGte: query.processAtGte || moment().startOf('month').format('YYYY-MM-DD').toString(),
            processAtLte: query.processAtLte || '',
        },
    }),
    {
        getInvocings,
    }
)
export default class PartnerInvoicingsPage extends PureComponent<BusinessUnitListModel> {
    state = {
        isLoadingInvocings: false,
    }

    retrieveData = async (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
        })

        await this.setState({ isLoadingInvocings: true, })

        try {
            const invoicings: any = await this.props.getInvocings(criteria, append)

            await this.setState({ isLoadingInvocings: false, })

            return invoicings
        } catch (e) {
            await this.setState({ isLoadingInvocings: false, })
        }
    }

    returnBusinessModel = (criteria: Criteria) => {
        return BUSINESS_MODELS.find(model => criteria.businessModel === model.id)
    }

    render() {
        const { criteria, invoicings, employees } = this.props

        const selectedModel = this.returnBusinessModel(criteria)

        return (
            <Card margin="0">
                <Card.Header>
                    <InvoicingHeading>Invoicings</InvoicingHeading>
                    {selectedModel && <BusinessModelLabel
                        abbreviation={selectedModel.abbreviation}
                    >
                        {selectedModel.badgeName}
                    </BusinessModelLabel>}
                </Card.Header>
                <Card.Content modifiers={[ 'p_0' ]}>
                    <ProviderTable
                        pageName="invoicings"
                        retrieveData={this.retrieveData}
                        criteria={this.props.criteria}
                        loading={this.state.isLoadingInvocings}
                    >
                        <ProviderTable.FilterContainer>
                            <DropdownFilter
                                selectedIds={[ criteria.businessModel ]}
                                type="radio"
                                items={BUSINESS_MODELS}
                                name="businessModel"
                                label="Business model"
                            />
                            <DropdownFilter
                                selectedIds={criteria.employeeId}
                                items={employees}
                                name="employeeId"
                                label="Employee"
                                search={false}
                            />
                            <TextFilter
                                value={criteria.partnerId}
                                name="partnerId"
                                label="Search partner IDs ..."
                            />
                            <DateFilter
                                value={criteria.processAtGte}
                                name="processAtGte"
                                placeholder="Process at after.."
                            />
                            <DateFilter
                                value={criteria.processAtLte}
                                name="processAtLte"
                                placeholder="Process at before.."
                            />
                        </ProviderTable.FilterContainer>
                        <ProviderTable.LazyTable>
                            <ProviderTable.Header>
                                <Column name="status">Status</Column>
                                <Column name="partner_id">Partner ID</Column>
                                <Column name="partner_name">Partner name</Column>
                                <SortColumn name="process_at">Process at</SortColumn>
                                <Column name="invoice">Invoice</Column>
                                <Column name="invoice_paid_at">Paid at</Column>
                                <Column name="price">Price</Column>
                            </ProviderTable.Header>
                            <ProviderTable.Rows
                                items={Object.values(invoicings)}
                                noDataText="There aren't any invoicings matching your criteria."
                            >
                                <InvoicingRow />
                            </ProviderTable.Rows>
                        </ProviderTable.LazyTable>
                    </ProviderTable>
                </Card.Content>
            </Card>
        )
    }
}
