import React, { Fragment, memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { HasPermission } from '../NavigationBar'
import { Link } from 'react-router-dom'

const ManagementDropdown = memo(({ hasPermission }: HasPermission) =>
    <NavDropdown eventKey={4} title="Management" id="basic-nav-dropdown-Management">
        <MenuItem header>Employees</MenuItem>
        {hasPermission('admin_edit_employees') && <MenuItem eventKey={4.1} href="/admin/employees">Employee Administration</MenuItem>}
        {hasPermission('kpi_target_create') && <MenuItem eventKey={4.2}>
            <Link to="/admin/kpi-target">KPI Targets</Link>
        </MenuItem>}
        <MenuItem eventKey={4.3} href="/admin/holidays">Holidays</MenuItem>

        <MenuItem divider />
        {hasPermission('edit_certifications') && <MenuItem eventKey={4.4} href="/partners/certifications">
            Certifications
        </MenuItem>}
        {hasPermission('edit_partner_associations') && <MenuItem eventKey={4.5} href="/partners/association">
            Partner associations
        </MenuItem>}
        {hasPermission('view_business_partners') && <MenuItem eventKey={4.6} href="/partners/business-partners">
            Business partners
        </MenuItem>}
        {hasPermission('bulk_partner_update') && <MenuItem eventKey={4.7} href="/partners/bulk-update">
            Partner bulk update
        </MenuItem>}
        <MenuItem eventKey={4.8} href="/partners/credit-surveillance">Credit surveillance</MenuItem>
        <MenuItem eventKey={4.9}>
            <Link to="/admin/terms">Terms and conditions</Link>
        </MenuItem>
        {hasPermission('admin_view_employee_phone_calls') && <Fragment>
            <MenuItem divider />
            <MenuItem header>Phones</MenuItem>
            <MenuItem eventKey={4.11} href="/admin/employees/phone-calls">Phone calls</MenuItem>
        </Fragment>}

    </NavDropdown>
)

export default ManagementDropdown
