import {
    GET_PARTNERS,
    GET_PARTNER_FAIL,
    GET_PARTNERS_SUCCESS,
    GET_PARTNER_AGGREGATIONS,
    GET_PARTNER_AGGREGATIONS_SUCCESS,
    GET_PARTNER_AGGREGATIONS_FAIL,
} from '../../actions/partners'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface PartnersModel extends InitialState {
    partnerIds: number[]
    aggregations: any
    aggregationLoading: boolean
}
const initialState: PartnersModel = {
    isLoading: false,
    partnerIds: [],
    aggregations: [],
    aggregationLoading: null,
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_PARTNERS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTNERS_SUCCESS:
            return {
                ...state,
                partnerIds: action.append ? state.partnerIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_PARTNER_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_PARTNER_AGGREGATIONS:
            return {
                ...state,
                aggregationLoading: true,
            }
        case GET_PARTNER_AGGREGATIONS_SUCCESS:
            return {
                ...state,
                aggregations: action.response.data,
                aggregationLoading: false,
            }
        case GET_PARTNER_AGGREGATIONS_FAIL:
            return {
                ...state,
                aggregationLoading: false,
            }
        default:
            return state
    }
}
