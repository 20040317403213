import React, { PureComponent, ComponentType } from 'react'
import { Card } from '../../blocks/Card'
import { Flex } from '../../elements/Flex'
import styled from 'styled-components'
import { borderColor, cardPadding, primaryColor } from '../../../utils/variables'
import Placeholder from '../../elements/Placeholder'
import { Link } from 'react-router-dom'
import { connect } from '../../../utils/connect'
import { determineDayPartName, removeEmptyProperties } from '../../../utils'
import config from '../../../config'
import moment from 'moment-timezone'
import { getIntervalLeads } from '../../../actions/leads'

const WeatherIcon = styled.div`
  font-size: 24px;
`
const FlexWeather = styled(Flex)`
  ${Card.Text} {
      margin-bottom: 6px;
  }
  > div:not(:first-child) {
    border-left: 1px solid ${borderColor};
    padding-left: ${cardPadding};
  }
`
const IntervalsContent = styled.div<{highlight: boolean}>`
  ${props => props.highlight && `
        color: ${primaryColor};
        a {
            color: ${primaryColor}!important;
        }
    `};

  span {
    cursor: pointer;
  }
`

const validationIntervals = {
    morning: { name: 'Morning interval', icon: 'weather-sunny' },
    afternoon: { name: 'Afternoon interval', icon: 'weather-sunset' },
    evening: { name: 'Evening interval', icon: 'weather-night' },
}

const getCurrentDayIntervalTimes = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const dayPeriodHours = [
        { hour: config.intervals.morning, name: 'morning', pos: 1 },
        { hour: config.intervals.afternoon, name: 'afternoon', pos: 2 },
        { hour: config.intervals.evening, name: 'evening', pos: 3 },
    ]
    return dayPeriodHours.map(period => {
        const { hour, name, pos } = period
        const time = moment().set({ hour, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss')
        return {
            time: moment(time).tz(timezone).format(),
            name,
            pos,
        }
    })
}

interface LeadValidationIntervalsElementProps {
    industryId?: string
}

interface LeadValidationIntervalsModel {
    getIntervalLeads: typeof getIntervalLeads
}

@connect<any, LeadValidationIntervalsModel, LeadValidationIntervalsElementProps>(null, {
    getIntervalLeads,
})
class LeadValidationIntervals extends PureComponent<LeadValidationIntervalsModel & LeadValidationIntervalsElementProps> {
    state = {
        aggregations: [],
    }

    async componentDidMount() {
        getCurrentDayIntervalTimes().map(async period => {
            const { time, name, pos } = period
            const currentIntervalLeads: any = await this.props.getIntervalLeads(removeEmptyProperties({
                latestCalledAtBefore: time,
                limit: '0',
                industryId: this.props.industryId,
            }))
            const periodData = {
                pos,
                key: name,
                hits: currentIntervalLeads.pagination.total,
            }
            this.setState({ aggregations: [ ...this.state.aggregations, periodData ].sort((a, b) => a.pos - b.pos) })
        })
    }

    render() {
        const { aggregations } = this.state

        return (
            <Card>
                <Card.Content modifiers={[ 'pB_1.5' ]}>
                    {aggregations
                        ? <FlexWeather modifiers={[ 'justifySpaceBetween' ]}>
                            { aggregations.map(item =>
                                <IntervalsContent
                                    highlight={item.key === determineDayPartName()}
                                    key={item.key}
                                >
                                    <Card.Text>{validationIntervals[ item.key ].name}</Card.Text>
                                    <WeatherIcon>
                                        {item.key === determineDayPartName()
                                            ? <Link to="/interval">{item.hits}</Link>
                                            : <span>{item.hits}</span>
                                        }
                                        &nbsp;
                                        <i className={`mdi mdi-${validationIntervals[ item.key ].icon}`} />
                                    </WeatherIcon>
                                </IntervalsContent>)
                            }
                        </FlexWeather>
                        : <Placeholder />
                    }
                </Card.Content>
            </Card>
        )
    }
}
export default LeadValidationIntervals as unknown as ComponentType<LeadValidationIntervalsElementProps>
