import React, { ComponentType, memo, PureComponent } from 'react'
import config from '../../config'
import PageTitle from '../elements/PageTitle'
import { getLeads } from '../../actions/leads'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { Card } from '../blocks/Card/index'
import { LeadStatusColor } from '../elements/LeadStatusColor'
import { getLeadStatus } from '../../utils/getLeadStatus'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { leadsSelector } from '../../selectors/lead'
import { Quote, Quotes } from '../../reducers/types/entities/Quote'
import { Lead } from '../../reducers/types/entities/Lead'
import { Employee } from '../../reducers/types/entities/Employee'
import { connect } from '../../utils/connect'
import ProviderTable from '../blocks/ProviderTable'
import { Cell, Column, DropdownFilter, LinkCell, SortColumn, DateCell } from '../elements/table'
import Checkbox from '../blocks/Checkbox'
import { hideModal, showModal } from '../../actions/modal'
import Select from 'components/src/inputs/Select'
import Flex from 'components/src/layout/Flex'
import { AppStoreState } from '../../reducers/types'
import { isCustomerChoice } from '../../utils/isCustomerChoice'

const leadStatuses = [
    { id: 'test', name: 'Test' },
    { id: 'validated', name: 'Validated' },
    { id: 'untreated', name: 'Untreated' },
    { id: 'duplicate', name: 'Duplicate' },
    { id: 'not_serious', name: 'Not Serious' },
    { id: 'send_to_advisor', name: 'Send to advisor' },
    { id: 'spam', name: 'Spam' },
]

export const bulkActionLeadStatuses = [
    { id: 'test', name: 'Test' },
    { id: 'duplicate', name: 'Duplicate' },
    { id: 'spam', name: 'Spam' },
]

export const AVAILABLE_STATUS_FOR_BULK_ACTION = 'unknown'

export const canChangeLeadStatus = (state: AppStoreState): boolean => {
    return Boolean(state.entities.permissions.change_lead_status)
}

interface LeadRowHOCProps {
    quotes: Quote
    visibleCells: string[]
    rowData: Lead
}

interface LeadRowPathProps {
    quotes: Quotes
    addBulkItem: (id: number) => void
    selectedBulkItems: number[]
    canChangeLeadStatus
}

type LeadRowModel = LeadRowHOCProps & LeadRowPathProps

const LeadRow = memo((props: LeadRowModel) => {
    const { quotes, addBulkItem, selectedBulkItems, canChangeLeadStatus, ...resProps } = props

    const lead = props.rowData

    const disabled = AVAILABLE_STATUS_FOR_BULK_ACTION !== lead.status

    return <ProviderTable.Row {...resProps}>
        {canChangeLeadStatus && <Cell>
            <Checkbox
                disabled={disabled}
                checked={selectedBulkItems.includes(lead.id)}
                onChange={disabled ? (() => {}) : (() => props.addBulkItem(lead.id))}
            />
        </Cell>}
        <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
        <Cell>{lead.category && lead.category.title}</Cell>
        <Cell>
            {lead.industries.primary && lead.industries.primary.name}
            {lead.industries.primary && lead.industries.secondary && ' - '}
            {lead.industries.secondary && lead.industries.secondary.name}
        </Cell>
        <Cell>{lead.title}</Cell>
        <Cell>
            {lead.client.firstName}
            {' '}
            {lead.client.lastName}
        </Cell>
        <Cell>{lead.customerInfo.customerType.identifier}</Cell>
        <Cell>{lead.geo.cityName}</Cell>
        <Cell>
            {lead.validator.firstName}
            {lead.validator.firstName && lead.validator.lastName && ' - '}
            {lead.validator.lastName}
        </Cell>
        {config.geoCode === 'de' ? (
            <Cell>
                {lead.allocationSuggestionsInfo && lead.allocationSuggestionsInfo.accepted
                    ? lead.allocationSuggestionsInfo.accepted
                    : 0
                }
                &nbsp;/&nbsp;
                {lead.allocationSuggestionsInfo && lead.allocationSuggestionsInfo.count
                    ? lead.allocationSuggestionsInfo.count
                    : 0
                }
            </Cell>
        ) : <Cell style={{ display: 'none' }} />}
        <Cell>
            {lead.quotesInfo.count}
            {' '}
            /
            {' '}
            {lead.quotesInfo.max}
        </Cell>
        <DateCell date={lead.createdAt} format="datetime"/>
        <DateCell date={lead.validatedAt} format="datetime"/>
        <DateCell date={lead.quotesInfo.clientDecisionPostponedTo} format="datetime"/>
        {lead.actionsInfo
            ? <DateCell date={lead.actionsInfo.latestCreatedAt} />
            : <Cell />
        }
        <Cell>
            {isCustomerChoice(lead) ? 'Yes' : '-'}
        </Cell>
        <Cell>
            <LeadStatusColor
                leadStatus={ getLeadStatus({
                    lead,
                    leadQuotes: lead.quotesInfo.quotes.map(id => quotes[id]),
                })}
            />
        </Cell>
    </ProviderTable.Row>
})

const LeadTableRow = LeadRow as ComponentType<LeadRowPathProps>

interface Criteria {
    sort: string
    query: string
    geoRect: string
    geoPoint: string
    geoDistance: string
    locationId: string
    visibleColumns: string[]
    status: string[]
    validatorId: string[]
}

interface LeadListStateProps {
    leads: Lead[]
    employees: Employee[]
    totalItems: number
    quotes: Quotes
    criteria: Criteria
    industryId?: string
    canChangeLeadStatus
}
interface LeadListDispatchProps {
    getLeads: typeof getLeads
    hideModal: typeof hideModal
    showModal: typeof showModal
}

interface LeadListPathProps {
    location: {
        query: Criteria
    }
}

type LeadExclusiveListModel = LeadListStateProps & LeadListDispatchProps & LeadListPathProps

@connect<LeadListStateProps, LeadListDispatchProps, LeadListPathProps>(
    (state, { location: { query } }) => ({
        leads: leadsSelector(state),
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        totalItems: state.pages.leads.pagination.total,
        quotes: state.entities.quotes,
        canChangeLeadStatus: canChangeLeadStatus(state),
        criteria: {
            visibleColumns: splitFilterString(query.visibleColumns),
            validatorId: splitFilterString(query.validatorId),
            status: splitFilterString(query.status),
            sort: query.sort || '-created_at',
            query: query.query || '',
            geoRect: query.geoRect || '',
            geoPoint: query.geoPoint || '',
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
        },
        industryId: state.auth.wantedIndustryId ? String(state.auth.wantedIndustryId) : undefined,
    }),
    {
        getLeads,
        hideModal,
        showModal,
    }
)

export default class LeadListPage extends PureComponent<LeadExclusiveListModel> {
    state = {
        bulkItems: []
    }

    retrieveData = (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
            industryId: this.props.industryId,
        })
        return this.props.getLeads(criteria, null, append)
    }

    toggleArrayItem = (state, id) => {
        return  state.includes(id)
            ? state.filter(i => i !== id)
            : [ ...state, id ]
    }

    addBulkItem = (id) => {
        this.setState({
            bulkItems: this.toggleArrayItem(this.state.bulkItems, id)
        })
    }

    itemToLabel = (item) => item.name

    bulkActionChange = (item) => {
        this.state.bulkItems.length && this.props.showModal({
            name: 'bulkStatusLeadAction',
            statusId: item.id,
            statusName: item.name,
            leadIds: this.state.bulkItems,
        })
    }


    render() {
        const {
            leads,
            quotes,
            criteria,
            employees,
            totalItems,
            canChangeLeadStatus,
        } = this.props

        return (
            <Card margin="0">
                <PageTitle title="All leads" />
                <Card.Header>
                    Leads
                    {' '}
                    <span className="text-muted">
                        (
                        {leads.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="leads"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <Flex alignItems="center" justifyContent="flex-start" zIndex={10} gridGap={15}>
                        {canChangeLeadStatus && <Select
                            items={bulkActionLeadStatuses}
                            placeholder="Bulk Action"
                            onChange={this.bulkActionChange}
                            itemToLabel={this.itemToLabel}
                            disabled={!Boolean(this.state.bulkItems.length)}
                        />}
                        <ProviderTable.FilterContainer>
                            <DropdownFilter
                                selectedIds={criteria.validatorId}
                                items={employees}
                                name="validatorId"
                                label="Validator"
                                search={false}
                            />
                            <DropdownFilter
                                selectedIds={criteria.status}
                                items={leadStatuses}
                                name="status"
                                search={false}
                                hideZero={false}
                                label="Lead Status"
                            />
                        </ProviderTable.FilterContainer>
                    </Flex>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            {canChangeLeadStatus && <Column name="bulk_action">Bulk action</Column>}
                            <Column name="id">ID</Column>
                            <Column name="lead_category_id">Lead category</Column>
                            <Column name="industry">Industry</Column>
                            <Column name="title">Title</Column>
                            <Column name="client_name">Client name</Column>
                            <Column name="lead_type">Lead type</Column>
                            <Column name="city_name">City</Column>
                            <Column name="validator initials">Validator initials</Column>
                            {config.geoCode === 'de' ? (
                                <Column name="quotes" title="Allocations suggestions">
                                    Suggestions (accepted/count)
                                </Column>
                            ) : <Column style={{ display: 'none' }} />}
                            <Column name="quotes">Quotes (X / Y)</Column>
                            <SortColumn name="created_at">Created at</SortColumn>
                            <SortColumn name="validated_at">Validated at</SortColumn>
                            <SortColumn name="client_decision_postponed_to">Postponed to</SortColumn>
                            <SortColumn name="last_action_at">Last action</SortColumn>
                            <Column>Customer choice</Column>
                            <Column name="status">Status box</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={leads}
                            noDataText="We couldn't find any leads matching your selection."
                        >
                            <LeadTableRow quotes={quotes} addBulkItem={this.addBulkItem} selectedBulkItems={this.state.bulkItems} canChangeLeadStatus={canChangeLeadStatus}/>
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
