import React, { Component, ComponentType, PureComponent, Fragment } from 'react'
import { Label, Modal } from 'react-bootstrap'
import { uniqBy } from 'lodash'
import { Tool } from 'react-feather'
import { Button } from '../../elements/Button'
import { hideModal } from '../../../actions/modal'
import moment from 'moment'
import { connect } from '../../../utils/connect'
import {
    getPartnerContract,
    createPartnerContractAllocationBoostPeriod,
    updatePartnerContractAllocationBoostPeriod,
    deletePartnerContractAllocationBoostPeriod
} from '../../../actions/partnerContracts'
import styled from 'styled-components'
import { PartnerContract } from '../../../reducers/types/entities/PartnerContract'
import { Table } from '../../elements/table'
import Column from '../../elements/table/columns/Column'
import Header from '../../elements/table/Header'
import Rows from '../../elements/table/Rows'
import Cell from '../../elements/table/cells/Cell'
import { Flex } from '../../elements/Flex'
import BoostForm from './BoostPeriodForm'
import { fadedTextColor, tableBorderColor } from '../../../utils/variables'
import DateCell from '../../elements/table/cells/DateCell'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Card } from '../../blocks/Card'
import Tabs from '../../elements/Tabs'
import { removeEmptyProperties } from '../../../utils'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 820px;
    }
`

const FormContainer = styled.div`
    padding: 24px 24px 40px;
    border-bottom: 1px solid ${tableBorderColor};
`

const CloseBtn = styled.button`
    display: inline-block;
    border: none;
    background-color: transparent;
    margin-left: auto;
    margin-right: 0:
`

const Row = styled.tr<{fadeOut: boolean}>`
    ${props =>  props.fadeOut
        ? `pointer-events: none; color: ${fadedTextColor};`
        : ''}
    ${Button} {
        visibility: hidden;
    }

    &:hover ${Button} {
        visibility: visible;
    }
`

const StatusLabel = styled(Label)`
    padding: 5px 10px;
    border-radius: 12px;
`

const Views = {
    list: 'list',
    create: 'create',
}

interface BoostPeriodRowModel {
    rowData?: any
    partnerContract: PartnerContract
    boostUpdate: any
    boostEnd: any
    boostDelete: any
}

class BoostRow extends PureComponent<BoostPeriodRowModel> {
    state = {
        toggled: false,
    }

    toggleDropdown = () => {
        this.setState({
            toggled: !this.state.toggled,
        })
    }

    onBoostPeriodUpdate = async (startsAt, endsAt) => {
        const boost = this.props.rowData

        await this.props.boostUpdate(boost.id, {
            startsAt: boost.startsAt !== startsAt ? startsAt : '',
            endsAt: boost.endsAt !== endsAt ? endsAt : '',
        })

        this.toggleDropdown()
    }

    onBoostPeriodEnd = async () => {
        const boost = this.props.rowData

        await this.props.boostEnd(boost.id)
    }

    onBoostPeriodDelete = async () => {
        const boost = this.props.rowData

        await this.props.boostDelete(boost.id)
    }

    render() {
        const boost = this.props.rowData
        const now = moment()

        const isActive = moment(boost.startsAt).isBefore(now) && moment(boost.endsAt).isAfter(now)
        const isExpired = moment(boost.startsAt).isBefore(now) && moment(boost.endsAt).isBefore(now)
        const isUpcoming = moment(boost.startsAt).isAfter(now) && moment(boost.endsAt).isAfter(now)

        return <Fragment>
            <Row fadeOut={isExpired}>
                <Cell>
                    {isActive && <StatusLabel bsStyle="success">Active</StatusLabel>}
                    {isExpired && <StatusLabel bsStyle="default">Expired</StatusLabel>}
                    {isUpcoming && <StatusLabel bsStyle="info">Planned</StatusLabel>}
                </Cell>
                <DateCell date={boost.startsAt} format="date" />
                <DateCell date={boost.endsAt} format="date" />
                <Cell>
                    <Flex>
                        {!isExpired && <Button modifiers={[ 'cellBtn', 'secondary' ]} onClick={this.toggleDropdown}><Tool size={12} /></Button>}
                        {isActive && <Button modifiers={[ 'cellBtn', 'warning', 'mL_1' ]} onClick={this.onBoostPeriodEnd}>End</Button>}
                        {isUpcoming && <Button modifiers={[ 'cellBtn', 'danger', 'mL_1' ]} onClick={this.onBoostPeriodDelete}>Delete</Button>}
                    </Flex>
                </Cell>
            </Row>
            {this.state.toggled && <tr>
                <td colSpan={10}>
                    <FormContainer>
                        <BoostForm
                            submit={this.onBoostPeriodUpdate}
                            startsAt={boost.startsAt}
                            endsAt={boost.endsAt}
                        />
                    </FormContainer>
                </td>
            </tr>}
        </Fragment>
    }
}

interface Props {
    partner: Partner
    partnerContract: PartnerContract
}

interface Actions {
    createPartnerContractAllocationBoostPeriod: typeof createPartnerContractAllocationBoostPeriod
    updatePartnerContractAllocationBoostPeriod: typeof updatePartnerContractAllocationBoostPeriod
    deletePartnerContractAllocationBoostPeriod: typeof deletePartnerContractAllocationBoostPeriod
    getPartnerContract: typeof getPartnerContract
    hideModal: typeof hideModal
}

interface State {
    viewName: string
    boostIds: number[]
}

type ContractBoostsModel = Props & Actions & State

@connect(
    state => ({
        partnerContract: state.entities.partnerContracts[state.modal.partnerContractId],
        partner: state.entities.partners[state.modal.partnerId],
    }), {
        createPartnerContractAllocationBoostPeriod,
        updatePartnerContractAllocationBoostPeriod,
        deletePartnerContractAllocationBoostPeriod,
        getPartnerContract,
        hideModal,
    }
)
class ContractBoostModal extends Component<ContractBoostsModel> {
    state = {
        viewName: Views.list,
        boostIds: [],
    }

    async componentDidMount() {
        const boosts = this.props.partnerContract.allocationBoosts

        if (!boosts || !Boolean(boosts.length)) {
            await this.changeView(Views.create)
        } else {
            const boostIds = boosts.map(boost => boost.id)
            this.setState({
                boostIds,
            })
        }
    }

    updateBoosts = async () => {
        const contractResponse: any = await this.props.getPartnerContract(this.props.partner.id, this.props.partnerContract.id)

        const boosts = contractResponse.entities.partnerContracts[contractResponse.result].allocationBoosts

        if (boosts) {
            const boostIds = boosts.map(boost => boost.id)
            this.setState({
                boostIds,
            })
        }
    }

    changeView = viewName => this.setState({ viewName })

    onBoostPeriodCreate = async (startsAt, endsAt) => {
        const partnerId = this.props.partner.id
        const partnerContractId = this.props.partnerContract.id

        await this.props.createPartnerContractAllocationBoostPeriod(
            partnerId,
            {
                partnerContractId: [ partnerContractId ],
                startsAt,
                endsAt,
            }
        )

        await this.updateBoosts()

        this.changeView(Views.list)
    }

    onBoostUpdate = async (boostId, boostData) => {
        const { partnerContract, partner } = this.props

        const filteredData = removeEmptyProperties({ ...boostData })

        await this.props.updatePartnerContractAllocationBoostPeriod(partner.id, partnerContract.id, boostId, filteredData)

        await this.updateBoosts()
    }

    onBoostEnd = async boostId => {
        const { partnerContract, partner } = this.props

        await this.props.updatePartnerContractAllocationBoostPeriod(partner.id, partnerContract.id, boostId, {
            endsAt: moment().format(),
        })

        await this.updateBoosts()
    }

    onBoostDelete = async boostId => {
        const { partnerContract, partner } = this.props

        await this.props.deletePartnerContractAllocationBoostPeriod(partner.id, partnerContract.id, boostId)

        await this.updateBoosts()
    }

    render() {
        const { partnerContract } = this.props
        const { boostIds } = this.state

        const tabs = [
            { tabName: 'Boosts', tabId: Views.list },
            { tabName: 'Create', tabId: Views.create },
        ]

        const boostItems = partnerContract.allocationBoosts
            ? uniqBy(partnerContract.allocationBoosts.filter(boost => boostIds.includes(boost.id)), 'id')
            : []

        return <LgModal
            show={true}
            onHide={this.props.hideModal}
            backdrop="static"
        >
            <Card.Header>
                <Tabs
                    tabs={tabs}
                    selectedTab={this.state.viewName}
                    changeTab={tabId => this.changeView(tabId)}
                />
                <CloseBtn onClick={this.props.hideModal}>×</CloseBtn>
            </Card.Header>
            <Modal.Body>
                {this.state.viewName === Views.list && <Table>
                    <Header>
                        <Column name="boost_status">Status</Column>
                        <Column name="start_date">Start date</Column>
                        <Column name="end_date">End date</Column>
                        <Column name="actions">Actions</Column>
                    </Header>
                    <Rows
                        items={boostItems}
                    >
                        <BoostRow
                            partnerContract={partnerContract}
                            boostUpdate={this.onBoostUpdate}
                            boostEnd={this.onBoostEnd}
                            boostDelete={this.onBoostDelete}
                        />
                    </Rows>
                </Table>}
                {this.state.viewName === Views.create && <Card.Content>
                    <BoostForm
                        submit={this.onBoostPeriodCreate}
                    />
                </Card.Content>}
            </Modal.Body>
        </LgModal>
    }
}

export default ContractBoostModal as unknown as ComponentType
