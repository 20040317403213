import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card } from '../../blocks/Card/index'
import MetaLabel from '../../elements/text/MetaLabel'
import { Lead } from '../../../reducers/types/entities/Lead'

const LeadCustomVariablesCard = memo(({ lead }: { lead: Lead }) =>
    <Card.Content>
        {lead.customVariables.map((member, index) =>
            <Row key={index}>
                <Col md={12}>
                    <MetaLabel>{member.key}</MetaLabel>
                    <p>{member.value}</p>
                </Col>
            </Row>
        )}
    </Card.Content>
)

export default LeadCustomVariablesCard
