import { ApiActionCreator, CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'

export const GET_COMPANY_SIZES = 'companysizes/GET_COMPANY_SIZES'
export const GET_COMPANY_SIZES_SUCCESS = 'companysizes/GET_COMPANY_SIZES_SUCCESS'
export const GET_COMPANY_SIZES_FAIL = 'companysizes/GET_COMPANY_SIZES_FAIL'

export const GET_LEAD_COMPANY_SIZES = 'companysizes/GET_LEAD_COMPANY_SIZES'
export const GET_LEAD_COMPANY_SIZES_SUCCESS = 'companysizes/GET_LEAD_COMPANY_SIZES_SUCCESS'
export const GET_LEAD_COMPANY_SIZES_FAIL = 'companysizes/GET_LEAD_COMPANY_SIZES_FAIL'

export const UPDATE_LEAD_COMPANY_SIZES = 'companysizes/UPDATE_LEAD_COMPANY_SIZES'
export const UPDATE_LEAD_COMPANY_SIZES_SUCCESS = 'companysizes/UPDATE_LEAD_COMPANY_SIZES_SUCCESS'
export const UPDATE_LEAD_COMPANY_SIZES_FAIL = 'companysizes/UPDATE_LEAD_COMPANY_SIZES_FAIL'

export const getCompanySizes: ApiActionCreator = (
    geoCode: string
) => ({
    [CALL_API]: {
        types: [ GET_COMPANY_SIZES, GET_COMPANY_SIZES_SUCCESS, GET_COMPANY_SIZES_FAIL ],
        endpoint: '/companysizes',
        schema: schemas.companySizes,
        options: {
            body: {
                geoCode,
                limit: 100,
            },
        },
    },
})

export const getLeadCompanySizes: ApiActionCreator = (
    leadId: string | number
) => ({
    [CALL_API]: {
        types: [ GET_LEAD_COMPANY_SIZES, GET_LEAD_COMPANY_SIZES_SUCCESS, GET_LEAD_COMPANY_SIZES_FAIL ],
        endpoint: `/leads/${leadId}/companysizes`,
        schema: schemas.companySizes,
    },
})

export const updateLeadCompanySizes = (
    leadId: string | number,
    companySizeIds: number[] | number
) => ({
    [CALL_API]: {
        types: [ UPDATE_LEAD_COMPANY_SIZES, UPDATE_LEAD_COMPANY_SIZES_SUCCESS, UPDATE_LEAD_COMPANY_SIZES_FAIL ],
        endpoint: `/leads/${leadId}/companysizes`,
        schema: schemas.companySizes,
        options: {
            method: 'put',
            body: {
                companySizeIds,
            },
        },
    },
})
