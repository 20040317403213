import React, { memo } from 'react'
import { Card } from '../../../blocks/Card'
import { Button } from '../../../elements/Button'
import { BtnLink } from '../../../elements/Links'
import { Col } from 'react-bootstrap'
import { Flex } from '../../../elements/Flex'
import ReadMore from '../../../elements/ReadMore'
import { Link } from 'react-router-dom'
import FormattedDate from '../../../elements/FormattedDate'
import { Quote } from '../../../../reducers/types/entities/Quote'
import moment from 'moment'
import { AmountCell, Cell, Column, Header, Rows, Table } from '../../../elements/table'
import { Item } from '../../../../reducers/types/entities/Item'

interface QuoteDetailsPropsModel {
    leadWithAcceptedQuote: boolean
    leadWithRejectedQuote: boolean
    deleteQuotePermission: string
    quote: Quote
    showDeleteQuoteModal(id:any): void
    closeReservationModal(id: number): any
}

const QuoteRow = memo((props: { rowData?: Item }) => {
    const item = props.rowData
    return <tr key={`quote_item_${item.id}`}>
        <Cell>{item.title}</Cell>
        <Cell>{item.type}</Cell>
        <Cell>
            ×
            {item.quantity}
        </Cell>
        <AmountCell amount={item.amountExclVat} />
    </tr>
})

export const QuoteDetails = memo(({
    leadWithAcceptedQuote,
    leadWithRejectedQuote,
    deleteQuotePermission,
    showDeleteQuoteModal,
    closeReservationModal,
    quote,
}: QuoteDetailsPropsModel) => {
    const lastItem = quote.items && quote.items[quote.items.length - 1]

    const updatedAt = (lastItem && moment(lastItem.updatedAt).isAfter(quote.updatedAt))
        ? lastItem.updatedAt
        : quote.updatedAt

    return <div>
        <Card.Content>
            <Flex modifiers={[ 'mB_2' ]}>
                <div>
                    <Card.Label>Partner</Card.Label>
                    <Card.Text>
                        <Link to={`/partners/${quote.partner.id}`}>
                            {quote.partner.companyName}
                        </Link>
                    </Card.Text>
                </div>
                <Col md={2}>
                    <Card.Label>Publishing date</Card.Label>
                    <Card.Text>
                        {quote.publishedAt
                            ? <FormattedDate date={quote.publishedAt} format="datetime" />
                            : <span>Not published</span>
                        }
                    </Card.Text>
                </Col>
                {updatedAt !== quote.publishedAt && <Col md={2}>
                    <Card.Label>Updated at</Card.Label>
                    <Card.Text>
                        <FormattedDate date={updatedAt} format="datetime" />
                    </Card.Text>
                </Col>}
                <Col md={1}>
                    <Card.Label>Status</Card.Label>
                    <Card.Text>{quote.status}</Card.Text>
                </Col>
                <Col md={6}>
                    <Flex modifiers="justifyEnd">
                        {moment(quote.reservationExpiresAt).isAfter(moment()) && !quote.publishedAt &&
                        <Button
                            modifiers={[ 'warning','p_1', 'mdWidth', 'mR_2' ]}
                            onClick={() => closeReservationModal(quote.id)}
                        >
                            Close reservation
                        </Button>
                        }
                    </Flex>
                </Col>
                <Col md={6}>
                    <Flex modifiers={[ 'justifyEnd' ]}>
                        {leadWithAcceptedQuote || leadWithRejectedQuote || deleteQuotePermission &&
                        <Button
                            modifiers={[ 'danger', 'p_1', 'mdWidth', 'mR_2' ]}
                            onClick={() => showDeleteQuoteModal(quote.id)}
                        >
                            Delete
                        </Button>
                        }
                        {leadWithAcceptedQuote || leadWithRejectedQuote || quote.publishedAt &&
                        <BtnLink
                            modifiers={[ 'secondary', 'p_1', 'mdWidth' ]}
                            to={`/quote/${quote.leadId}/${quote.id}`}
                        >
                            Edit
                        </BtnLink>
                        }
                    </Flex>
                </Col>
            </Flex>
            <Card.Label>Quote description</Card.Label>
            <Card.Text>
                <ReadMore>
                    {quote.description}
                </ReadMore>
            </Card.Text>
        </Card.Content>
        <Table className="quote-amount-table">
            <Header>
                <Column>Product</Column>
                <Column>Frequence</Column>
                <Column>Quantity</Column>
                <Column className="text-right">Price</Column>
            </Header>
            <Rows
                items={quote.items ? quote.items : []}
                noDataText="No items for this quote."
            >
                <QuoteRow/>
            </Rows>
            <tbody>
                <tr className="vat-row">
                    <Cell>VAT</Cell>
                    <Cell>-</Cell>
                    <Cell>-</Cell>
                    <AmountCell amount={quote.totalVatAmount} />
                </tr>
                <tr>
                    <Cell>Total price incl vat.</Cell>
                    <Cell>-</Cell>
                    <Cell>-</Cell>
                    <AmountCell amount={quote.totalAmountInclVat} />
                </tr>
            </tbody>
        </Table>
    </div>
})
