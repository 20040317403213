import React, { ComponentType, Fragment, PureComponent } from 'react'
import { isArray } from 'lodash'
import { push } from 'connected-react-router'
import { connect } from '../../utils/connect'
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { getSearchTypeahead } from '../../actions/search'
import { Card } from '../blocks/Card'
import styled from 'styled-components'
import Placeholder from '../elements/Placeholder'
import { Flex } from '../elements/Flex'
import { Capitalize } from '../elements/text/Capitalize'

const SearchInput = styled(AsyncTypeahead)`
    input {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        box-shadow: none;
        color: rgba(255, 255, 255, 0.6);
        height: 28px;
        margin: 8px 0;
        padding-right: 26px;
        padding-left: 7px;
        border: 0px;
    }
`

const MenuContent = styled(Menu)`
    width: 500px!important;
    max-height: 600px!important;
    margin: 0;
    padding: 0;
`
const SeeALL = styled.div`
    position: sticky;
    top: 0;
    background-color: #f5f5f5;
    padding: 10px;
    margin-bottom: 20px;
`

const MenuText = styled.div`
    max-width: 400px;
    font-size: 15px;
    font-weight: bold;
    white-space: normal;
`

const PartnerItem = ({ result, i }) =>
    <MenuItem option={result} position={i}>
        <Flex modifiers="justifySpaceBetween">
            <MenuText>{result.partner.companyName}</MenuText>
            <MenuText>
                <Capitalize>{result.partner.partnerState}</Capitalize>
            </MenuText>
        </Flex>
        <Card.Meta>
            <i>
                #
                {result.partner.id}
                {' '}
                (
                {result.partner.zipCode} 
                {' '}
                {result.partner.city}
                )
            </i>
        </Card.Meta>
    </MenuItem>

const LeadItem = ({ result, i }) =>
    <MenuItem option={result} position={i}>
        <Flex modifiers="justifySpaceBetween">
            <MenuText>{result.lead.title}</MenuText>
            <MenuText>
                <Capitalize>{result.type}</Capitalize>
            </MenuText>
        </Flex>
        <Card.Meta>
            <i>
                #
                {result.lead.id}
                {' '}
                (
                {result.lead.zipCode} 
                {' '}
                {result.lead.city}
                )
            </i>
        </Card.Meta>
    </MenuItem>

interface TopBarSearchProps {
    typeFilter?: 'employee' | 'lead' | 'partner' | Array<'employee' | 'lead' | 'partner'>
}

interface TopbarModel extends TopBarSearchProps {
    getSearchTypeahead: typeof getSearchTypeahead
    push: typeof push
}

interface SearchCriteria {
    query: string
    type?: string
}

@connect(null, {
    getSearchTypeahead,
    push,
})
class TopBarSearch extends PureComponent<TopbarModel> {
    state = {
        isLoading: false,
        options: [],
        query: '',
    }

    onSelect = selectedItem => {
        const item = selectedItem[0]

        switch (item.type) {
            case 'lead':
                (window.location as any) = `/leads/${item[item.type].id}/validation`
                break
            case 'partner':
                (window.location as any) = `/partners/${item[item.type].id}`
                break
        }
    }

    onSearch = async query => {
        const {
            typeFilter
        } = this.props

        let criteria: SearchCriteria = {
            query,
        }

        if (typeFilter) {
            if (isArray(typeFilter)) {
                criteria.type = typeFilter.join(',')
            } else {
                criteria.type = typeFilter
            }
        }

        this.setState({ isLoading: true, query })
        const resGetSearchTypeahead: any = await this.props.getSearchTypeahead(criteria, true)
        this.setState({ options: resGetSearchTypeahead.data, isLoading: false })
    }

    labelKey = option => option.type === 'lead' ? option.lead.title : option.partner.companyName

    filterBy = option => option

    pressedEnter = event => {
        if (event.key === 'Enter') (window.location as any) = `/search?query=${this.state.query}`
    }

    renderMenu = (results, menuProps) => {
        const {
            typeFilter
        } = this.props

        return <MenuContent {...menuProps}>
            <SeeALL>
                <li>
                    <a href={'/search?query=' + this.state.query}>
                        <Card.Text modifiers="noMargin">
                            <i className="fa fa-search" />
                            {' '}
&nbsp;
                            <span>
                                See all results for query
                                <strong>{this.state.query}</strong>
                            </span>
                        </Card.Text>
                    </a>
                </li>
            </SeeALL>
            {this.state.isLoading ? <Placeholder /> : Boolean(results.length) && <Fragment>
                {results.find(result => result.type === 'partner') && <li>
                    {(!typeFilter || isArray(typeFilter)) && <Fragment>
                        <Card.Label><b>&ensp; Partners:</b></Card.Label>
                        <Card.Line/>
                    </Fragment>}
                </li>}
                {results.map((result, i) => result.type === 'partner' && <PartnerItem result={result} i={i}/>)}
                {results.find(result => result.type === 'lead') && <li>
                    {(!typeFilter || isArray(typeFilter)) && <Fragment>
                        <Card.Label><b>&ensp; Leads:</b></Card.Label>
                        <Card.Line/>
                    </Fragment>}
                </li>}
                {results.map((result, i) => result.type === 'lead' && <LeadItem result={result} i={i}/>)}
            </Fragment>}
        </MenuContent>
    }

    render() {
        return (
            <SearchInput
                id="topBarSearch"
                placeholder="Search ..."
                minLength={2}
                options={this.state.options}
                isLoading={this.state.isLoading}
                useCache={false}
                selectHintOnEnter={true}
                onChange={this.onSelect}
                onSearch={this.onSearch}
                labelKey={this.labelKey}
                filterBy={this.filterBy}
                onKeyDown={this.pressedEnter}
                renderMenu={this.renderMenu}
            />
        )
    }
}

export default TopBarSearch as unknown as ComponentType
