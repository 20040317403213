import React, { ComponentType, PureComponent } from 'react'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import { leadActionDiscard } from '../../actions/leads'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import RadioGroup from '../elements/forms/inputs/RadioGroup'
import { returnLocalTimezone } from '../../utils'

const required = value => value ? undefined : 'Required!'
const discardNoteRequired = (value, values) => {
    if (values.discardStatusReason ===  undefined) {
        return 'Required'
    } else if (values.discardStatusReason == 'Other' || values.discardStatusReason == 'Client misunderstood the service') {
        if (value === undefined || value == '') {
            return 'Required'
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

interface LeadDiscardModel {
    modalProps: {
        headerText: string
        bodyText: string
        disabled: boolean
        leadId: number
    }
    initialValues: {
        discardStatusNote: string
        discardStatus: string
    }
    leadActionDiscard: typeof leadActionDiscard
    hideModal: typeof hideModal
    submit: typeof submit
}

@connect(
    state => ({
        modalProps: state.modal,
        initialValues: {
            discardStatusNote: '',
            discardStatus: state.modal.discardStatus,
        },
    }),
    {
        leadActionDiscard,
        hideModal,
        submit,
    },
)
class LeadDiscardModal extends PureComponent<LeadDiscardModel> {
    hideModal = () => this.props.hideModal()

    discardLead = async discardData => {
        const leadId = this.props.modalProps.leadId
        await this.props.leadActionDiscard(leadId, discardData)
        window.location.href = `/leads/validation?timezone=${returnLocalTimezone()}`
    }

    save = () => {
        this.props.submit('leadDiscardModalForm')
    }

    render() {
        const {
            initialValues,
            modalProps,
        } = this.props

        const reasons = [
            'Client found another solution',
            'Client misunderstood the service',
            'Client just checking prices/doing research',
            'Other',
        ]
        const reasonsFields = reasons.map(reason => ({ id: reason, name: reason }))

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>{modalProps.headerText}</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    initialValues={initialValues}
                    form="leadDiscardModalForm"
                    onSubmit={this.discardLead}
                >
                    {this.props.initialValues.discardStatus == 'not_serious' ? <Field
                        component={RadioGroup}
                        options={reasonsFields}
                        name="discardStatusReason"
                        label={modalProps.bodyText}
                        validate={[ required ]}
                    /> : null}
                    <Field
                        component={FieldGroup}
                        validate={[ this.props.initialValues.discardStatus == 'not_serious' ? discardNoteRequired : required ]}
                        name="discardStatusNote"
                        label={modalProps.bodyText}
                    />
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    modifiers={[ 'secondary', 'mR_1' ]}
                    disabled={modalProps.disabled}
                    onClick={this.hideModal}
                >
                    Cancel
                </Button>
                <Button
                    modifiers={[ 'primary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.save}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default LeadDiscardModal as unknown as ComponentType
