import { createSelector } from 'reselect'
import { values } from 'lodash'

const entitiesPaymentSolutions = state => state.entities.paymentSolutions
const entitiesPaymentSchedules = state => state.entities.paymentSchedules


export const paymentSolutionsSelector = createSelector(
    entitiesPaymentSolutions,
    methods => values(methods).filter(el => ![ 'ideal' ].includes(el.identifier))
)
export const paymentSchedulesSelector = createSelector(
    entitiesPaymentSchedules,
    schedules => values(schedules).map(el => ({ ...el,  id: el.identifier }))
)
