import React, { ComponentType, PureComponent } from 'react'
import { Field, submit, reset } from 'redux-form'
import moment from 'moment'
import CheckBox from '../elements/CheckBox'
import config from '../../config'
import { currentEmployeeSelector, employeeSelector } from '../../selectors/employees'
import {
    saveTask,
    completeTask,
} from '../../actions/tasks'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { OverflowText } from '../elements/OverflowText'
import ReduxForm from '../elements/forms/ReduxForm'
import { Flex } from '../elements/Flex'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import styled from 'styled-components'
import { Button } from '../elements/Button'
import MetaLabel from '../elements/text/MetaLabel'
import { connect } from '../../utils/connect'
import { Employee } from '../../reducers/types/entities/Employee'
import { Task } from '../../reducers/types/entities/Task'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'

const required = [ value => value ? undefined : 'Required' ]

const TaskInputsRow = styled(Flex)`
    input {
      height: 35px!important;
    }
    div:first-child {
      width: 100%;
      input {
        border-radius: 3px 0 0 3px;
      }
    }
    span.input-group {
      width: 230px;
      input {
        border-left-width: 0;
        border-radius: 0 3px 3px 0;
      }
      .mdi-24px.mdi:before {
        font-size: 21px;
      }
    }
`

const TaskGridInfo = styled.div`
  display: grid;
  grid: auto-flow dense / 35px 1fr 30px;
  font-size: 15px;
  align-items: center;
`

interface TaskRowStateProps {
    assignableEmployees: Employee[]
    assignedEmployee: Employee
}
interface TaskRowDispatchProps {
    completeTask: typeof completeTask
    saveTask: typeof saveTask
    submit: typeof submit
    reset: typeof reset
}
interface TaskRowPathProps {
    task?: Task
    initialTaskables?: [{
        type: string
        partner: {
            id: number
        }
    }]
    onDiscard?: () => void
}

interface TaskRowComponentStateProps {
    inEditMode: boolean
    completing: boolean
}

type TaskRowModel = TaskRowStateProps & TaskRowDispatchProps & TaskRowPathProps

@connect<TaskRowStateProps, TaskRowDispatchProps, TaskRowPathProps>(
    (state, { task }) => ({
        assignedEmployee: task && state.entities.employees[task.assignedEmployee],
        assignableEmployees: employeeSelector(state),
        currentEmployee: currentEmployeeSelector(state),
    }),
    {
        completeTask,
        saveTask,
        submit,
        reset,
    }
)

class TaskRow extends PureComponent<TaskRowModel, TaskRowComponentStateProps> {
    initialValues
    formName

    constructor(props) {
        super(props)
        const task = props.task
        this.state = {
            inEditMode: !task,
            completing: false,
        }
        this.formName ='taskForm-' + (task ? task.id : 'new')
        this.initialValues = {
            id: task && task.id,
            title: task && task.title,
            assignedEmployee: (task && task.assignedEmployee)
                ? task.assignedEmployee
                : props.currentEmployee.id,
            deadlineAt: task && moment(task.deadlineAt).format('D MMM YYYY, HH:mm'),
            taskables: task ? task.taskables : (props.initialTaskables || []),
        }
    }

    toggleEditMode = (e) => {
        e.preventDefault()
        const inEditMode = !this.state.inEditMode
        this.setState({ inEditMode })
    }

    submitTask = async task => {
        await this.props.saveTask({
            ...task,
            assignedEmployee: task.assignedEmployee && { id: task.assignedEmployee },
        })
        if(this.props.task) this.setState({ inEditMode: false })
        this.props.reset(this.formName)
    }

    onDiscard = () => {
        if(this.props.onDiscard)
            this.props.onDiscard()
        if(this.props.task)
            this.setState({ inEditMode: false })
        this.props.reset(this.formName)
    }

    isCompleted = () => {
        const task = this.props.task
        return task && task.status && task.status.id === config.DONE_TASK
    }

    complete = async() => {
        if(this.props.task && !this.isCompleted()) {
            this.setState({ inEditMode: false })
            this.setState({ completing: true })
            await this.props.completeTask(this.props.task)
            this.setState({ completing: false })
        }
    }

    saveTask = () => {
        this.props.submit(this.formName)
    }

    render() {
        const {
            task,
            assignedEmployee,
            assignableEmployees,
        } = this.props

        const style = {
            opacity: this.isCompleted() ? 0.6 : 1,
            marginBottom: '10px',
        }

        const deadlineAt = task && task.deadlineAt && moment(task.deadlineAt)

        const {
            inEditMode,
            completing,
        } = this.state

        return (
            <div className={'task-row' + (this.isCompleted() ? ' completed' : '')} style={style}>
                {inEditMode && <ReduxForm
                    form={'taskForm-' + (task ? task.id : 'new')}
                    initialValues={this.initialValues}
                    onSubmit={this.submitTask}
                >
                    <TaskInputsRow>
                        <Field
                            name="title"
                            tabIndex="1"
                            placeholder="Title"
                            validate={required}
                            component={FieldGroup}
                            removeClassFormGroup={true}
                        />
                        <Field
                            name="deadlineAt"
                            tabIndex="2"
                            dateFormat="D MMM YYYY, HH:mm"
                            component={DateTimePickerGroup}
                            removeClassFormGroup={true}
                        />
                    </TaskInputsRow>
                    <Flex modifiers={[ 'mT_1', 'justifySpaceBetween' ]}>
                        <Flex>
                            <Button
                                modifiers={[ 'primary', 'mR_1' ]}
                                onClick={this.saveTask}
                                tabIndex={4}
                            >
                                Save
                            </Button>
                            <Button
                                tabIndex={5}
                                onClick={this.onDiscard}
                            >
                                Cancel
                            </Button>
                        </Flex>
                        <Field
                            name="assignedEmployee"
                            tabIndex="3"
                            returnId={true}
                            component={SelectInputGroup}
                            removeClassFormGroup={true}
                        >
                            <option value="">Assignee</option>
                            {assignableEmployees.map((e, i) =>
                                <option value={e.id} key={i}>{ e.name }</option>
                            )}
                        </Field>
                    </Flex>
                </ReduxForm>}
                {inEditMode || <TaskGridInfo>
                    <CheckBox
                        onClick={this.complete}
                        loading={completing}
                        checked={this.isCompleted() || completing}
                    />
                    <div>
                        <OverflowText>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={String(task.id)}>{task.description}</Tooltip>}
                            >
                                <span>{ task.title }</span>
                            </OverlayTrigger>
                        </OverflowText>
                        <div>
                            <MetaLabel>
                                {assignedEmployee.initials}
                                {deadlineAt && <span>
                                    ,
                                    {deadlineAt.calendar()}
                                </span>}
                            </MetaLabel>
                        </div>
                    </div>
                    { this.isCompleted() || <a href="#" onClick={this.toggleEditMode}>Edit</a>}
                </TaskGridInfo>}
            </div>
        )
    }
}

export default TaskRow as unknown as ComponentType<TaskRowPathProps>
