import React, { memo, PureComponent } from 'react'
import { getIntervalLeads } from '../../actions/leads'
import { determineDayPart } from '../../utils'
import { connect } from '../../utils/connect'
import { push } from 'connected-react-router'

interface PropsModel {
    location: {
        query: {
            visibleColumns: string[]
        },
    }
}

interface LeadIntervalRedirectProps {
    industryId?: string
    getIntervalLeads: typeof getIntervalLeads
    push: typeof push
}

@connect<any, any, PropsModel>(
    (state, { location: { query } } ) => ({
        industryId: state.auth.wantedIndustryId ? String(state.auth.wantedIndustryId) : undefined,
    }),
    {
        getIntervalLeads,
        push,
    }
)
export default class LeadIntervalRedirect extends PureComponent<PropsModel & LeadIntervalRedirectProps> {
    state = {
        loading: true,
    }
    async componentDidMount() {
        await this.redirectToValidationPage()
    }

    redirectToValidationPage = async () => {
        const res: any = await this.props.getIntervalLeads({ limit: '1', latestCalledAtBefore: determineDayPart() })
        const leadId = res.result[0]
        if (leadId) {
            this.props.push(`/leads/${leadId}/validation`)
            return
        }
    }

    render() {
        if (this.state.loading) {
            return <div>Getting next lead in the current interval</div>
        }

        return (
            <div>There are no more leads in the current interval</div>
        )
    }
}
