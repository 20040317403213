import { withFormik } from '../../../utils/withFormik'
import React, { ComponentType, PureComponent } from 'react'
import snakeCase from 'lodash/snakeCase'
import { Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'
import { Flex } from '../../elements/Flex'
import { Button } from '../../elements/Button'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'

const validationSchema = yup.object().shape({
    assigneeId: yup.number()
        .required(),
    assigneeType: yup.string()
        .required(),
    assigneeRole: yup.string()
        .required(),
})

export const assigneeTypes = {
    employee: 'Employee',
    partnerUser: 'Partner User',
}

export const assigneeRoles = {
    clientConsultant: 'Client consultant',
}

interface PauseFormModel {
    submit: (values) => void
    options: any[]
}

interface FormValues {
    assigneeId: string | number
    assigneeType: string
    assigneeRole: string
}

@withFormik<PauseFormModel, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: () => ({
        assigneeId: '',
        assigneeType: Object.keys(assigneeTypes)[0],
        assigneeRole: Object.keys(assigneeRoles)[0],
    }),
    handleSubmit: async (values, bag) => {
        await bag.props.submit({
            assigneeId: values.assigneeId,
            assigneeType: snakeCase(values.assigneeType),
            assigneeRole: snakeCase(values.assigneeRole),
        })
    },
    validationSchema,
})
class PartnerAssigneeCreateForm extends PureComponent<PauseFormModel & FormikProps<FormValues>> {
    render() {
        return <Form>
            <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                <Field
                    label="Assignee"
                    name="assigneeId"
                    component={SelectInputGroup}
                    options={this.props.options.sort((a, b) => a.name.localeCompare(b.name))}
                />
                <Field
                    label="Type"
                    name="assigneeType"
                    disabled={true}
                    component={SelectInputGroup}
                    options={Object.keys(assigneeTypes).map(id => ({ id, name: assigneeTypes[id] }))}
                    defaultValue={Object.keys(assigneeTypes)[0]}
                />
                <Field
                    label="Role"
                    name="assigneeRole"
                    disabled={true}
                    component={SelectInputGroup}
                    options={Object.keys(assigneeRoles).map(id => ({ id, name: assigneeRoles[id] }))}
                    defaultValue={Object.keys(assigneeRoles)[0]}
                />
                <Button type="submit" modifiers={[ 'action', 'pX_3', 'pY_1' ]}>Assign</Button>
            </Flex>
        </Form>
    }
}

export default PartnerAssigneeCreateForm as unknown as ComponentType<PauseFormModel>
