import React, { PureComponent, ComponentType } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Button } from '../../elements/Button'
import { connect } from '../../../utils/connect'
import { savePartner, getPartner } from '../../../actions/partners'
import { Partner } from '../../../reducers/types/entities/Partner'
import { PaymentCard } from '../../../reducers/types/entities/PaymentCard'
import { getPaymentCards } from '../../../actions/paymentCards'
import { preferPaymentMethod, unpreferPaymentMethod } from '../../../actions/paymentMethods'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import { get } from 'lodash'
import { Field, Form, FormikProps } from 'formik'
import { withFormik } from '../../../utils/withFormik'
import { toastr } from 'react-redux-toastr'
import { PaymentMethods } from '../../../reducers/types/entities/PaymentMethod'

interface PartnerPaymentOptionsStateProps {
    paymentCards: PaymentCard[]
    paymentMethods: PaymentMethods
    preferredPaymentMethod: number
}

interface PartnerPaymentOptionsDispatchProps {
    getPaymentCards: typeof getPaymentCards
    savePartner: typeof savePartner
    getPartner: typeof getPartner
    preferPaymentMethod: typeof preferPaymentMethod
    unpreferPaymentMethod: typeof unpreferPaymentMethod
}

interface PartnerPaymentOptionsPathProps {
    partner: Partner
}

interface FormValues {
    invoicingDaysGrace: number | string
    preferredPaymentMethod: number | string
}

type PartnerPaymentOptionsModel = PartnerPaymentOptionsStateProps
    & PartnerPaymentOptionsDispatchProps
    & PartnerPaymentOptionsPathProps

@connect<PartnerPaymentOptionsStateProps, PartnerPaymentOptionsDispatchProps, PartnerPaymentOptionsPathProps>(
    (state, props) => {
        const paymentCards = Object.values(state.entities.paymentCards)
            .filter(card => get(card, 'owner.partner.id') === get(props, 'partner.id'))

        const preferredPaymentCard = paymentCards.find(card => card.isPreferred)

        return {
            paymentCards,
            paymentMethods: state.entities.paymentMethods,
            preferredPaymentMethod: get(preferredPaymentCard, 'paymentMethod', 0)
        }
    },
    {
        getPaymentCards,
        savePartner,
        getPartner,
        preferPaymentMethod,
        unpreferPaymentMethod,
    }
)
@withFormik<PartnerPaymentOptionsModel, FormValues>({
    enableReinitialize: true,
    handleSubmit: async (values, bag) => {
        if (Number(values.preferredPaymentMethod)) {
            await bag.props.preferPaymentMethod(Number(values.preferredPaymentMethod))
            toastr.success(
                'Success!',
                'Partner\'s invoices will be paid automatically in the future'
            )
            return
        }
        await bag.props.savePartner({
            id: bag.props.partner.id,
            invoicing: {
                daysGrace: Number(values.invoicingDaysGrace),
            },
        })
        if (Number(bag.props.preferredPaymentMethod)) {
            await bag.props.unpreferPaymentMethod(bag.props.preferredPaymentMethod)
        }
        toastr.success(
            'Success!',
            `Partner will have to pay invoices manually within ${Number(values.invoicingDaysGrace)} days of creation`
        )
    },
    mapPropsToValues: props => ({
        invoicingDaysGrace: get(props.partner, 'invoicing.daysGrace'),
        preferredPaymentMethod: props.preferredPaymentMethod,
    }),
})
class PartnerPaymentOptions extends PureComponent<PartnerPaymentOptionsModel & FormikProps<FormValues>> {
    state = {
        paymentCardIds: [],
    }

    componentDidMount() {
        this.getPaymentCards()
    }

    get manualIsPreferred() {
        return !Number(this.props.values.preferredPaymentMethod)
    }

    async getPaymentCards() {
        const partner = this.props.partner

        const response: any = await this.props.getPaymentCards({
            partnerId: partner.id,
        })

        await this.setState({
            paymentCardIds: response.result,
        })
    }

    render() {
        let paymentMethods = [
            { id: 0, name: 'Manual' },
            ...this.props.paymentCards.map(card => ({
                id: card.paymentMethod,
                name: `${card.cardName}${card.cardMask}`,
            })),
        ]

        return (
            <Form>
                <Row>
                    <Col md={this.manualIsPreferred ? 6 : 12}>
                        <Field
                            label="Preferred payment method"
                            name="preferredPaymentMethod"
                            component={SelectInputGroup}
                            options={paymentMethods}
                            returnId={true}
                            removeClassFormGroup={true}
                        />
                    </Col>
                    {this.manualIsPreferred &&
                    <Col md={6}>
                        <Field
                            label=" Invoicing grace period"
                            name="invoicingDaysGrace"
                            component={FieldGroup}
                            type="number"
                            removeClassFormGroup={true}
                            required={true}
                        />
                    </Col>
                    }
                </Row>
                <Button
                    modifiers={[ 'action', 'mT_2', 'fullWidth', 'p_1' ]}
                    type="submit"
                >
                    Save
                </Button>
            </Form>
        )
    }
}

export default PartnerPaymentOptions as unknown as ComponentType<PartnerPaymentOptionsPathProps>
