import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'
import {
    CREATE_PARTNER_DISCOUNTS,
    CREATE_PARTNER_DISCOUNTS_FAIL,
    CREATE_PARTNER_DISCOUNTS_SUCCESS,
    CREATE_SUBSCRIPTION_PRICE_FAIL,
    DELETE_SUBSCRIPTION_PRICES_SUCCESS,
    GET_PARTNER_DISCOUNTS,
    GET_PARTNER_DISCOUNTS_FAIL,
    GET_PARTNER_DISCOUNTS_SUCCESS,
    GET_SUBSCRIPTION_PRICES,
    GET_SUBSCRIPTION_PRICES_FAIL,
    GET_SUBSCRIPTION_PRICES_SUCCESS,
    CREATE_SUBSCRIPTION_PRICE,
    DELETE_SUBSCRIPTION_PRICES,
    DELETE_SUBSCRIPTION_PRICES_FAIL,
    CREATE_SUBSCRIPTION_PRICE_SUCCESS,
    DELETE_PARTNER_DISCOUNTS_SUCCESS,
    DELETE_PARTNER_DISCOUNTS_FAIL,
    DELETE_PARTNER_DISCOUNTS,
} from '../../actions/subscriptions'

export interface SubscriptionModel extends InitialState {
    priceIds: number[]
    partnerDiscountIds: number[]
    isLoadingPrices: boolean
    isLoadingDiscounts: boolean
}
const initialState: SubscriptionModel = {
    isLoading: null,
    isLoadingPrices: null,
    isLoadingDiscounts: null,
    priceIds: [],
    partnerDiscountIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

interface SubscriptionActionsModel extends InitialAction {
    priceId: number
    discountId: number
}

export default function reducer(
    state = initialState,
    action: SubscriptionActionsModel
) {
    switch (action.type) {
        case GET_PARTNER_DISCOUNTS:
        case CREATE_PARTNER_DISCOUNTS:
        case DELETE_PARTNER_DISCOUNTS:
            return {
                ...state,
                isLoadingDiscounts: true,
            }
        case GET_PARTNER_DISCOUNTS_SUCCESS:
            return {
                ...state,
                partnerDiscountIds: action.append
                    ? state.partnerDiscountIds.concat(action.response.result)
                    : action.response.result,
                isLoadingDiscounts: false,
            }
        case CREATE_PARTNER_DISCOUNTS_SUCCESS:
            return {
                ...state,
                partnerDiscountIds: state.partnerDiscountIds.concat(action.response.result),
                isLoadingDiscounts: false,
            }
        case DELETE_PARTNER_DISCOUNTS_SUCCESS:
            return {
                ...state,
                partnerDiscountIds: state.partnerDiscountIds.filter(item => item !== action.discountId),
                isLoadingDiscounts: false,
            }
        case GET_PARTNER_DISCOUNTS_FAIL:
        case CREATE_PARTNER_DISCOUNTS_FAIL:
        case DELETE_PARTNER_DISCOUNTS_FAIL:
            return {
                ...state,
                isLoadingDiscounts: false,
            }

        case GET_SUBSCRIPTION_PRICES:
        case CREATE_SUBSCRIPTION_PRICE:
        case DELETE_SUBSCRIPTION_PRICES:
            return {
                ...state,
                isLoadingPrices: true,
            }
        case GET_SUBSCRIPTION_PRICES_SUCCESS:
            return {
                ...state,
                priceIds: action.append
                    ? state.priceIds.concat(action.response.result)
                    : action.response.result,
                isLoadingPrices: false,
            }
        case CREATE_SUBSCRIPTION_PRICE_SUCCESS:
            return {
                ...state,
                priceIds: state.priceIds.concat(action.response.result),
                isLoadingPrices: false,
            }
        case DELETE_SUBSCRIPTION_PRICES_SUCCESS:
            return {
                ...state,
                priceIds: state.priceIds.filter(item => item !== action.priceId),
                isLoadingPrices: false,
            }
        case GET_SUBSCRIPTION_PRICES_FAIL:
        case CREATE_SUBSCRIPTION_PRICE_FAIL:
        case DELETE_SUBSCRIPTION_PRICES_FAIL:
            return {
                ...state,
                isLoadingPrices: false,
            }

        default:
            return state
    }
}
