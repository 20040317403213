import React, { PureComponent, ComponentType, Fragment } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { connect } from '../../utils/connect'
import PartnersDropdown from './dropdowns/PartnersDropdown'
import ClientsDropdown from './dropdowns/ClientsDropdown'
import MarketingDropdown from './dropdowns/MarketingDropdown'
import ManagementDropdown from './dropdowns/ManagementDropdown'
import AdministrationDropdown from './dropdowns/AdministrationDropdown'
import TopBarSearch from './TopBarSearch'
import EmployeeDropdown from './dropdowns/EmployeeDropdown'
import { Employee } from '../../reducers/types/entities/Employee'
import { Notification } from '../../reducers/types/entities/Notification'
import { ProfilePicture } from '../elements/ProfilePicture'
import CountriesDropdown from './dropdowns/CountriesDropdown'
import { createEmployeesAction, getEmployees } from '../../actions/employees'
import NotificationsDropdown from './dropdowns/NotificationsDropdown'
import PhoneDropdown from './dropdowns/PhoneDropdown'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { loadAuth } from '../../actions/auth'
import { allEmployeeNotificationsSelector } from '../../state/notifications/selectors'
import { getNotification } from '../../state/notifications/actions'
import config from '../../config'
import IndustriesDropdown from './dropdowns/IndustriesDropdown'
import { Auth } from '../../reducers/auth'
import { Industries, Industry } from '../../reducers/types/entities/Industry'
import { getIndustries } from '../../state/industries/actions'

const FlexNav = styled(Nav)`
  display: flex;
  align-items: center;
`

const TopBar = styled(Navbar)`
    position: sticky;
    top: 0;
    z-index: 20;
`

function changeIndustry(industry?: Industry) {
    if (industry === undefined) {
        window.localStorage.removeItem('industry_id')
        return
    }
    window.localStorage.setItem('industry_id', String(industry.id))
}

interface NavigationBarModel {
    permissions
    employee: Employee
    notifications: Notification[]
    employeeId: number
    totalNotifications: number
    notificationsId: number[]
    wantedIndustryId: Auth['wantedIndustryId']
    industries: Industries
    createEmployeesAction: typeof createEmployeesAction
    getIndustries: typeof getIndustries
    getNotification: typeof getNotification
    loadAuth: typeof loadAuth
    getEmployees: typeof getEmployees
}

export interface HasPermission {
    hasPermission: (permissionName: string) => boolean
    employeeId?: number
}

@connect(
    state => ({
        permissions: state.entities.permissions,
        employee: state.entities.employees[state.auth.employeeId],
        notifications: allEmployeeNotificationsSelector(state),
        notificationsId: state.subjects.notifications.all.ids,
        totalNotifications: state.subjects.notifications.all.pagination.total,
        employeeId: state.auth.employeeId,
        wantedIndustryId: state.auth.wantedIndustryId,
        industries: state.entities.industries,
    }),
    {
        createEmployeesAction,
        getIndustries,
        getNotification,
        loadAuth,
        getEmployees,
    }
)
class NavigationBar extends PureComponent<NavigationBarModel> {
    state = {
        isLoading: true,
    }

    constructor(props) {
        super(props)
        this.hasPermission = this.hasPermission.bind(this)
    }

    async componentDidMount() {
        if (config.isReactPage) {
            return
        }
        try {
            const response: any = await this.props.loadAuth()
            await this.props.getEmployees({ isActive: '1' })
            await this.props.getNotification(this.props.employeeId, {
                isSeen: '0',
                limit: 1000,
            })
            this.props.getIndustries({ geoCode: response.employee.wantedGeoCode })
        } finally {
            await this.setState({
                isLoading: false,
            })
        }
    }

    changeCountry = async wantedGeoCode => {
        try {
            changeIndustry()
            await this.props.createEmployeesAction(
                this.props.employeeId,
                {
                    action: 'change_wanted_geo_code',
                    wantedGeoCode,
                }
            )
        } finally {
            document.location.reload()
        }
    }

    changeIndustry: typeof changeIndustry = (industry) => {
        changeIndustry(industry)
        window.location.reload()
    }

    hasPermission(permissionName) {
        return Boolean(this.props.permissions[permissionName])
    }

    markAsRead = async () => {
        await this.props.createEmployeesAction(this.props.employeeId, {
            action: 'mark_notifications_as_read',
            markAsReadNotificationIds: this.props.notificationsId,
        })
        this.props.getNotification(this.props.employeeId, {
            isSeen: '0',
            limit: 1000,
        })
    }

    render() {
        const { employee, employeeId, notifications, totalNotifications, permissions } = this.props

        if (isEmpty(permissions)) return null

        return <TopBar inverse collapseOnSelect fluid>
            <Navbar.Header>
                <Navbar.Brand>
                    <a href="/" style={{ marginTop: '10px' }}>
                        <img src="/assets/images/logo_ageras.svg" alt="ageras logo"/>
                    </a>
                </Navbar.Brand>
            </Navbar.Header>
            <Navbar.Collapse>
                <FlexNav>
                    {!this.hasPermission('exclusion_partners') &&
                        <PartnersDropdown hasPermission={this.hasPermission} employeeId={employeeId}/>
                    }
                    {!this.hasPermission('exclusion_leads') &&
                    <ClientsDropdown hasPermission={this.hasPermission}/>
                    }
                    {(this.hasPermission('view_affiliates') || this.hasPermission('admin_edit_frontend')) &&
                        <MarketingDropdown hasPermission={this.hasPermission}/>
                    }
                    {this.hasPermission('view_management_section') &&
                        <ManagementDropdown hasPermission={this.hasPermission}/>
                    }
                    {this.hasPermission('view_administration_section') &&
                        <AdministrationDropdown hasPermission={this.hasPermission}/>
                    }
                </FlexNav>

                <FlexNav pullRight>
                    <li><TopBarSearch/></li>
                    {employee && <Fragment>
                        {employee.currentCallActivity && <PhoneDropdown callActivity={employee.currentCallActivity}/>}
                        <NotificationsDropdown
                            notifications={notifications}
                            total={totalNotifications}
                            markAsRead={this.markAsRead}
                        />
                        <CountriesDropdown
                            changeCountry={this.changeCountry}
                            selectedCountry={employee.wantedGeoCode}
                        />
                        <IndustriesDropdown
                            changeIndustry={this.changeIndustry}
                            selectedIndustry={this.props.industries[this.props.wantedIndustryId || 0]}
                            industries={Object.assign({}, ...Object.entries(this.props.industries).map((entry) => (entry[1].parentIndustryId === 0 ? { [entry[0]]: entry[1] } : undefined)))}

                        />

                        <li>
                            <ProfilePicture
                                modifiers="m_1"
                                src={employee.image}
                                name={employee.name}
                                size={25}
                            />
                        </li>
                        <EmployeeDropdown name={employee.name}/>
                    </Fragment>}
                </FlexNav>
            </Navbar.Collapse>
        </TopBar>
    }
}

export default NavigationBar as unknown as ComponentType
