import React, { ComponentType, PureComponent } from 'react'
import { Tooltip, OverlayTrigger, Label } from 'react-bootstrap'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Subscription } from '../../../reducers/types/entities/Subscription'
import { Flex } from '../../elements/Flex'
import { BtnHref, BtnLink } from '../../elements/Links'
import { Button } from '../../elements/Button'
import { createPartnerAction } from '../../../actions/partners'
import { connect } from '../../../utils/connect'
import moment from 'moment'
import { getPausePartnerSubscription } from '../../../state/pauseSubscriptions/actions'
import BusinessModelLabel from '../../elements/BusinessModelLabel'

interface InterfacePartnerHeader {
    partner: Partner
    subscription: Subscription
    isActivePause?: boolean
    badges?: any[]
    isRenderedByPartnerPage?: boolean
}
interface PartnerHeaderActions {
    getPausePartnerSubscription: typeof getPausePartnerSubscription
    createPartnerAction: typeof createPartnerAction
}

type PartnerHeaderModel = PartnerHeaderActions & InterfacePartnerHeader

@connect(null,
    {
        getPausePartnerSubscription,
        createPartnerAction,
    }
)

class PartnerHeader extends PureComponent<PartnerHeaderModel> {
    convertExPartnerToDemo = async (partnerId) => {
        await this.props.createPartnerAction(partnerId, { action: 'make-demo' });

        (window.location as any) = `/partners/${partnerId}`
    }

    render() {
        const {
            partner,
            subscription,
            isActivePause,
            badges,
            isRenderedByPartnerPage,
        } = this.props

        const labels = []

        if (subscription) {
            const status = isActivePause ? 'warning' : moment().isSameOrAfter(subscription.startsAt) ? 'success' : 'info'
            labels.push(<Label bsStyle={status}>{subscription.packageType}</Label>)
        }

        if (partner.features && partner.features.includes('billy_partner') ) {
            labels.push(<Label bsStyle="info">{'billy partner'}</Label>)
        }

        return (
            <div style={{ marginTop: '20px' }}>
                <div className="header">
                    {partner.satisfaction && <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip">{partner.satisfaction.label}</Tooltip>}
                    >
                        <div className={'satisfaction satisfaction-' + partner.satisfaction.score}><i /></div>
                    </OverlayTrigger>}
                    <h2>
                        #
                        {partner.id} 
                        {' '}
                        {partner.industry.name}
                        , 
                        {' '}
                        {partner.geo.cityName}
                        {labels.map(label =>
                            <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>{label}</span>
                        )}
                        {badges ? badges.map(badge =>
                            <BusinessModelLabel
                                key={'businessModels_' + badge}
                                abbreviation={badge}
                            >
                                {badge}
                            </BusinessModelLabel>
                        ) : null}
                    </h2>
                    <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                        <h1>{partner.companyName}</h1>
                        <Flex>
                            {!isRenderedByPartnerPage && <BtnLink
                                modifiers={[ 'secondary', 'mR_2' ]}
                                to={'/partners/' + partner.id}
                            >
                                <i className="mdi mdi-undo-variant"/>
                                {' '}
                                Go back
                            </BtnLink>}
                            <BtnHref
                                modifiers="secondary"
                                href={'/partners/' + partner.id + '/publicpage'}
                            >
                                Go to public profile
                            </BtnHref>
                        </Flex>
                    </Flex>
                    {partner.state === 'demo' && <div className="alert alert-warning">Demo partner.</div>}
                    {partner.state === 'canvas' && <div className="alert alert-warning">Canvas partner.</div>}
                    {partner.state === 'ex_partner' &&
                    <div className="alert alert-warning">
                        This partner is an ex partner.
                        <span style={{ paddingLeft: '20px' }}>
                            <Button
                                modifiers={[ 'action' ]}
                                onClick={() => this.convertExPartnerToDemo(partner.id)}
                            >
                                Convert to demo
                            </Button>
                        </span>
                    </div>}
                </div>
            </div>
        )
    }
}

export default PartnerHeader as unknown as ComponentType<InterfacePartnerHeader>
