import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_RATINGS = 'ratings/GET_RATINGS'
export const GET_RATINGS_SUCCESS = 'ratings/GET_RATINGS_SUCCESS'
export const GET_RATINGS_FAIL = 'ratings/GET_RATINGS_FAIL'
export const UPDATE_RATING = 'ratings/UPDATE_RATING'
export const UPDATE_RATING_SUCCESS = 'ratings/UPDATE_RATING_SUCCESS'
export const UPDATE_RATING_FAIL = 'ratings/UPDATE_RATING_FAIL'

export const getRatings = (
    partnerId: number,
) => ({
    [CALL_API]: {
        types: [ GET_RATINGS, GET_RATINGS_SUCCESS, GET_RATINGS_FAIL ],
        endpoint: '/ratings',
        schema: schemas.ratings,
        options: {
            body: {
                partnerId,
                limit: 10,
                sort: '-created_at',
            },
        },
    },
})
