import React, { memo } from 'react'
import { Field, submit as reduxSubmit } from 'redux-form'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import { Button } from '../../elements/Button'
import styled from 'styled-components'
import ReduxForm from '../../elements/forms/ReduxForm'

const AcceptItemBtnContainer = styled.div`
  padding-top: 25px;
`
const CreateQuoteItemRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 1fr 1fr 1fr auto;
  .form-group {
    margin-bottom: 0;
  }
`
interface AddQuoteItemModel {
    title: string
    type: string
    quantity: number
    amountExclVat: {
        amount: number
    }
}

interface CreateQuoteItemFormModel {
    submit: typeof reduxSubmit,
    onSubmit: (data: AddQuoteItemModel) => void,
    quoteItemType: string
}

const CreateQuoteItemForm = memo(({
    submit,
    onSubmit,
    quoteItemType,
}: CreateQuoteItemFormModel) =>
    <ReduxForm form="addQuoteItem" onSubmit={onSubmit}>
        <CreateQuoteItemRow>
            <Field
                label="Product"
                name="title"
                placeholder="Item title..."
                component={FieldGroup}
            />
            <Field
                label="Type"
                name="type"
                returnId={true}
                component={SelectInputGroup}
            >
                <option value="yearly">Yearly</option>
                <option value="quarterly">Quarterly</option>
                <option value="monthly">Monthly</option>
                <option value="unit">Unit</option>
            </Field>
            <Field
                label="Quantity"
                name="quantity"
                type="number"
                disabled={quoteItemType !== 'unit'}
                component={FieldGroup}
            />
            <Field
                label="Amount Excl Vat"
                name="amountExclVat.amount"
                placeholder="Item price..."
                component={FieldGroup}
                type="number"
            />
            <AcceptItemBtnContainer>
                <Button
                    onClick={() => submit('addQuoteItem')}
                    modifiers={[ 'action', 'btnIcon' ]}
                >
                    <i className="mdi mdi-check" />
                </Button>
            </AcceptItemBtnContainer>
        </CreateQuoteItemRow>
    </ReduxForm>
)

export default CreateQuoteItemForm
