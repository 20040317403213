import React, { PureComponent } from 'react'
import DateTime from 'react-datetime'
import moment from 'moment'
import styled from 'styled-components'
import { tableBackgroundColor, tableBorderColor } from '../../../../utils/variables'

const DateTimeFilter = styled(DateTime)`
    display: inline-block;
    margin-right: 20px;

    input {
        cursor: pointer;
        padding: 9px 15px;
        color: #6F8289;
        background: ${tableBackgroundColor};
        border-radius: 3px;
        font-size: 12px;
        line-height: 1.428571429;
        text-transform: uppercase;
        border: 1px solid ${tableBorderColor};
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        box-shadow: none;

        &:hover {
            border-color: #C7D6DC;
        }

        &::placeholder {
            font-size: 11px;
            color: #6F8289;
            line-height: 1.428571429;
            text-transform: uppercase;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
    }
`

interface TextFilterModel {
    onChange?: (value: string) => void
    name: string
    value: string
    placeholder?: string
}

export default class DateFilter extends PureComponent<TextFilterModel> {

    onChange = async e => {
        const formattedDate = moment(e._d).format('YYYY-MM-DD')

        this.props.onChange(formattedDate)
    }

    render() {
        const {
            placeholder,
        } = this.props

        return (
            <DateTimeFilter
                value={this.props.value}
                onChange={this.onChange}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                inputProps={{ placeholder: placeholder || null }}
            />
        )
    }
}
