import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_MEETINGS = 'meetings/GET_MEETINGS'
export const GET_MEETINGS_SUCCESS = 'meetings/GET_MEETINGS_SUCCESS'
export const GET_MEETINGS_FAIL = 'meetings/GET_MEETINGS_FAIL'

export const getMeetings = (
    leadId: number,
) => ({
    [CALL_API]: {
        types: [ GET_MEETINGS, GET_MEETINGS_SUCCESS, GET_MEETINGS_FAIL ],
        endpoint: '/meetings',
        schema: schemas.leadPartnerMeetingRequests,
        options: {
            body: {
                leadId: leadId,
                limit: 100,
            },
        },
    },
})
