import React, { memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const EmployeeDropdown = memo(({ name }: {name:string}) =>
    <NavDropdown eventKey={6} title={name} id="basic-nav-dropdown-Employee">
        <MenuItem eventKey={6.1}>
            <Link to="/tasks">Tasks</Link>
        </MenuItem>
        <MenuItem eventKey={6.2} href="/employees/profile">View profile</MenuItem>
        <MenuItem eventKey={6.3} href="/employees/change-password">Change password</MenuItem>
        <MenuItem eventKey={6.4}>
            <Link to="/hr/employees">Employee Directory</Link>
        </MenuItem>
        <MenuItem eventKey={6.5} href="/auth/logout">Log out</MenuItem>
    </NavDropdown>
)

export default EmployeeDropdown
