import React, { PureComponent, ComponentType, Fragment } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { MultiSelect, MultiValue, ValueContainer, allOption } from '../elements/Select'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Button } from '../elements/Button'
import { connect } from '../../utils/connect'
import { hideModal } from '../../actions/modal'
import { toastr } from 'react-redux-toastr'
import { withFormik } from '../../utils/withFormik'
import { Field, FieldArray, Form, FormikProps } from 'formik'
import { createPartnerContractOfferGroup, getPartnerContractOfferGroups } from '../../actions/partnerContractOfferGroups'
import { getRevenueSegments } from '../../actions/revenueSegments'
import { savePartner } from '../../actions/partners'
import View from '../theme/View'
import { Plus, X } from 'react-feather'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import { PartnerContractOfferGroup } from '../../reducers/types/entities/PartnerContractOfferGroup'
import { RevenueSegments } from '../../reducers/types/entities/RevenueSegment'
import { Card } from '../blocks/Card'
import { Partner } from '../../reducers/types/entities/Partner'
import styled from 'styled-components'
import * as yup from 'yup'
import FormControl from '../elements/FormControl'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import { getDemoLeadsForPartner } from '../../actions/leads'
import config from '../../config'
import { GeoRegions } from '../../reducers/types/entities/GeoRegion'
import { getGeoRegions } from '../../state/geoRegions/actions'

const WideModal = styled(Modal)`
    .modal-lg {
        width: 1000px;
    }
`

const OFFER_NAMES = [
    'LITE',
    'BASIC',
    'PREMIUM',
]

const validationSchema = yup.object().shape({
    offers: yup.array().of(yup.object().shape({
        offerItems: yup.array().of(yup.object().shape({
            leadQuota: yup.number().required().positive().integer(),
            revenueSegment: yup.object().shape({
                id: yup.number().required().min(1).integer(),
            }),
        })),
        priceExclVat: yup.object().shape({
            currency: yup.string().required(),
            amount: yup.number().required().positive(),
        }),
        sort: yup.number().required(),
    })),
})

interface StatePropsModel {
    activePartnerContractOfferGroup: PartnerContractOfferGroup
    fakeLeadExtensionPeriod: string | number
    partner: Partner
    revenueSegments: RevenueSegments
    geoRegions: GeoRegions
}

interface DispatchPropsModel {
    createPartnerContractOfferGroup: typeof createPartnerContractOfferGroup
    getDemoLeadsForPartner: typeof getDemoLeadsForPartner
    getPartnerContractOfferGroups: typeof getPartnerContractOfferGroups
    getRevenueSegments: typeof getRevenueSegments
    hideModal: typeof hideModal
    savePartner: typeof savePartner
    getGeoRegions: typeof getGeoRegions
}

type ComponentPropsModel = StatePropsModel & DispatchPropsModel

interface FormValues {
    offers: Array<{
        offerItems: Array<{
            leadQuota: number | string
            revenueSegment: {
                id: number | string
            }
        }>
        sort: number
        priceExclVat: any
    }>
    segmentationGeoRegions: number[]
}

@connect<StatePropsModel, DispatchPropsModel>(
    state => ({
        activePartnerContractOfferGroup: _.findLast(Object.values(state.entities.partnerContractOfferGroups), group =>
            String(group.partner.id) === String(state.modal.partnerId) && !group.archivedAt),
        fakeLeadExtensionPeriod: state.modal.fakeLeadExtensionPeriod,
        partner: state.entities.partners[state.modal.partnerId],
        revenueSegments: state.entities.revenueSegments,
        geoRegions: state.entities.geoRegions,
    }),
    {
        createPartnerContractOfferGroup,
        getDemoLeadsForPartner,
        getPartnerContractOfferGroups,
        getRevenueSegments,
        hideModal,
        savePartner,
        getGeoRegions,
    }
)
@withFormik<ComponentPropsModel, FormValues>({
    enableReinitialize: true,
    handleSubmit: async (values, bag) => {
        await bag.props.createPartnerContractOfferGroup(
            bag.props.partner.id,
            values
        )
        await bag.props.getPartnerContractOfferGroups(bag.props.partner.id)
        toastr.success('Success!', 'New offers will be shown to the partner')
        bag.props.hideModal()
    },
    mapPropsToValues: props => {
        if (props.activePartnerContractOfferGroup) {
            return {
                offers: props.activePartnerContractOfferGroup.offers.map(offer => ({
                    offerItems: offer.offerItems.map(offerItem => ({
                        leadQuota: offerItem.leadQuota,
                        revenueSegment: {
                            id: offerItem.revenueSegment.id,
                        },
                    })),
                    sort: offer.sort,
                    priceExclVat: {
                        currency: config.currency[config.geoCode],
                        amount: offer.priceExclVat.amount,
                    },
                })),
                segmentationGeoRegions: [],
            }
        }
        return {
            offers: [
                {
                    offerItems: [ {
                        leadQuota: '',
                        revenueSegment: { id: '' },
                    } ],
                    priceExclVat: {
                        currency: config.currency[config.geoCode],
                        amount: null,
                    },
                    sort: 1,
                },
                {
                    offerItems: [ {
                        leadQuota: '',
                        revenueSegment: { id: '' },
                    } ],
                    priceExclVat: {
                        currency: config.currency[config.geoCode],
                        amount: null,
                    },
                    sort: 2,
                },
                {
                    offerItems: [ {
                        leadQuota: '',
                        revenueSegment: { id: '' },
                    } ],
                    priceExclVat: {
                        currency: config.currency[config.geoCode],
                        amount: null,
                    },
                    sort: 3,
                },
            ],
            segmentationGeoRegions: [],
        }
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
})
class ContractOfferGroupCreationModal extends PureComponent<ComponentPropsModel & FormikProps<FormValues>> {
    state = {
        leads: 0,
        geoRegions: [],
        selectedGeoRegions: null,
    }

    componentDidMount() {
        this.props.getRevenueSegments(this.props.partner.geo.code)
        this.props.getPartnerContractOfferGroups(this.props.partner.id)
        this.props.getGeoRegions({ geoCode: this.props.partner.geo.code, limit: 200 })
            // @ts-ignore
            .then(regions => this.setState({
                geoRegions: Object.values(regions.entities.geoRegions).map(region => ({
                    // @ts-ignore
                    value: region.id,
                    // @ts-ignore
                    label: region.regionName,
                })),
            }))
            .then(() => {
                const defaultGeoRegions = [ allOption ]
                this.state.geoRegions.forEach(geoRegion => defaultGeoRegions.push(geoRegion))

                this.setState({
                    // @ts-ignore
                    selectedGeoRegions: defaultGeoRegions,
                })
            })
    }

    componentDidUpdate(prevProps) {
        const previousRevenueSegmentIds = this.extractRevenueSegmentIds(prevProps.values.offers)
        const revenueSegmentIds = this.extractRevenueSegmentIds(this.props.values.offers)

        if (
            JSON.stringify(previousRevenueSegmentIds) !== JSON.stringify(revenueSegmentIds) ||
            get(prevProps, 'partner.demo.leadExtensionPeriod') !== get(this.props, 'partner.demo.leadExtensionPeriod')
        ) {
            this.queryLeadList(revenueSegmentIds)
        }
    }

    extractRevenueSegmentIds(offers) {
        return flatMap(flatMap(flatMap(offers, 'offerItems'), 'revenueSegment'), 'id').filter(v => v)
    }

    async queryLeadList(revenueSegmentIds: string[], segmentedGeoRegions?: number[]) {
        const revenueSegments = [ ...new Set(revenueSegmentIds.map(id => id.toString())) ]
        const geoRegionIds = (segmentedGeoRegions || (this.state.selectedGeoRegions || []).map((geoRegion) => geoRegion.value)).filter(id => id !== '*')

        const response = await this.props.getDemoLeadsForPartner(this.props.partner.id, revenueSegments, geoRegionIds, { limit: '0' }) as any

        this.setState({ leads: response.pagination.total })
    }

    hideModal = () => this.props.hideModal()

    saveLeadExtensionPeriod = (leadExtensionPeriod: string) => {
        this.props.savePartner({
            id: this.props.partner.id,
            demo: {
                ...this.props.partner.demo,
                leadExtensionPeriod: Number(leadExtensionPeriod),
            },
        })
    }

    handleSelectedGeoRegions = selected => {
        this.props.setFieldValue('segmentationGeoRegions', selected.map(geoRegion => {
            return { id: geoRegion.value }
        }))

        this.setState({
            selectedGeoRegions: selected,
        })

        this.queryLeadList(this.extractRevenueSegmentIds(this.props.values.offers), selected.map(geoRegion => geoRegion.value))
    }

    render() {
        const {
            partner,
            revenueSegments,
            values,
        } = this.props

        return (
            <WideModal
                show={true}
                onHide={this.hideModal}
                backdrop="static"
                bsSize="large"
            >
                <Form>
                    <Modal.Body
                        style={{
                            maxHeight: '600px',
                            overflow: 'auto',
                        }}
                    >
                        <FieldArray
                            name="offers"
                            render={() => (
                                <View justifyContent="space-between" flexDirection="row">
                                    {values.offers.map((offer, offerIndex) => (
                                        <View width="30%" key={offerIndex}>
                                            {`${OFFER_NAMES[offerIndex]} (${offer.sort})`}
                                            <Card.Line modifiers="mY_1"/>
                                            <Field
                                                label="Price excluding VAT"
                                                name={`offers.${offerIndex}.priceExclVat.amount`}
                                                component={FieldGroup}
                                                removeClassFormGroup={true}
                                                type="number"
                                                tabIndex={offer.sort * 100}
                                            />
                                            <Card.Line modifiers="mY_1"/>
                                            <FieldArray
                                                name={`offers.${offerIndex}.offerItems`}
                                                render={offerItemHelpers => (
                                                    <Fragment>
                                                        {values.offers[offerIndex].offerItems.map((offerItem, offerItemIndex) => (
                                                            <View key={offerItemIndex}>
                                                                <Field
                                                                    label="Revenue segment"
                                                                    name={`offers.${offerIndex}.offerItems.${offerItemIndex}.revenueSegment.id`}
                                                                    component={SelectInputGroup}
                                                                    returnId={true}
                                                                    options={Object.values(revenueSegments).map(segment => ({
                                                                        id: segment.id,
                                                                        name: segment.displayName,
                                                                    }))}
                                                                    tabIndex={offer.sort * 1000}
                                                                />
                                                                <View justifyContent="space-between" flexDirection="row">
                                                                    <Field
                                                                        label="Lead quota"
                                                                        name={`offers.${offerIndex}.offerItems.${offerItemIndex}.leadQuota`}
                                                                        component={FieldGroup}
                                                                        removeClassFormGroup={true}
                                                                        type="number"
                                                                        tabIndex={offer.sort * 10000}
                                                                    />
                                                                    {values.offers[offerIndex].offerItems.length > 1 &&
                                                                    <Button
                                                                        modifiers={[ 'danger' ]}
                                                                        style={{
                                                                            height: '40px',
                                                                            marginLeft: '10px',
                                                                            marginTop: '24px',
                                                                        }}
                                                                        onClick={() => offerItemHelpers.remove(offerItemIndex)}
                                                                        tabIndex={offer.sort * 100000}
                                                                    >
                                                                        <X/>
                                                                    </Button>
                                                                    }
                                                                </View>
                                                                <Card.Line modifiers="mY_1"/>
                                                            </View>
                                                        ))}
                                                        <Button
                                                            modifiers={[ 'primary' ]}
                                                            onClick={() => offerItemHelpers.push({})}
                                                            tabIndex={offer.sort * 1000000}
                                                        >
                                                            <View mr={2}>Add another revenue segment</View>
                                                            <Plus size="15px"/>
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            />
                                        </View>
                                    ))}
                                </View>
                            )}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <View flexDirection="row" justifyContent="space-between">
                            <View width="20%">
                                <FormControl
                                    componentClass="select"
                                    defaultValue={get(partner, 'demo.leadExtensionPeriod')}
                                    onChange={(e: any) => this.saveLeadExtensionPeriod(e.currentTarget.value)}
                                >
                                    <option disabled>Lead juice (auto-saves)</option>
                                    <option value={0}>Disabled</option>
                                    <option value={7}>1 week</option>
                                    <option value={14}>2 weeks</option>
                                    <option value={21}>3 weeks</option>
                                    <option value={28}>4 weeks</option>
                                    <option value={35}>5 weeks</option>
                                </FormControl>
                            </View>
                            <View width="40%">
                                <MultiSelect
                                    isMulti
                                    name="geoRegions"
                                    placeholder={'Select geo regions...'}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{
                                        MultiValue,
                                        ValueContainer,
                                    }}
                                    options={this.state.geoRegions}
                                    className="basic-multi-select"
                                    onChange={this.handleSelectedGeoRegions}
                                    allowSelectAll={true}
                                    value={this.state.selectedGeoRegions}
                                />
                            </View>
                            <View width="10%" alignItems="center" justifyContent="center">
                                {`${this.state.leads} leads`}
                            </View>
                            <View flexDirection="row">
                                <Button
                                    modifiers={[ 'secondary' ]}
                                    onClick={this.hideModal}
                                    tabIndex={30000000}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    modifiers={[ 'action', 'mL_1.5' ]}
                                    type="submit"
                                    tabIndex={40000000}
                                >
                                    Create offers
                                </Button>
                            </View>
                        </View>
                    </Modal.Footer>
                </Form>
            </WideModal>
        )
    }
}

export default ContractOfferGroupCreationModal as unknown as ComponentType
