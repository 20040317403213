import React, { PureComponent } from 'react'
import { ProgressBar, Row, Col } from 'react-bootstrap'
import { Card } from '../../blocks/Card/index'
import { LeadStatusColor } from '../../elements/LeadStatusColor'
import MetaLabel from '../../elements/text/MetaLabel'
import styled from 'styled-components'
import { getLeadStatus } from '../../../utils/getLeadStatus'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Quote } from '../../../reducers/types/entities/Quote'
import { Button } from '../../elements/Button'
import { verifyPhoneNumber } from '../../../actions/customers'
import { connect } from '../../../utils/connect'
import { toastr } from 'react-redux-toastr'
import { showModal } from '../../../actions/modal'
import { Flex } from '../../elements/Flex'
import {
    hasActionMarkLeadAsUntreatedRight
} from '../../../selectors/access'

const StatusLabelContainer = styled.div`
  margin-bottom: 13px;
`

const getLeadProgressPercentage = ({ lead, leadQuotes }) => {
    switch(getLeadStatus({ lead, leadQuotes })) {
        case 'quotes_full':
            return 62
        case 'validated':
            return 33
        default:
            return 0
    }
}

interface LeadStatusCardDispatchProps {
    verifyPhoneNumber?: typeof verifyPhoneNumber
    showModal: typeof showModal
    hasActionMarkLeadAsUntreatedRight: boolean
}

interface StatePropsLeadStatusCard {
    leadQuotes: Quote[]
    lead: Lead
}

type LeadStatusModel = LeadStatusCardDispatchProps & StatePropsLeadStatusCard

@connect<StatePropsLeadStatusCard, LeadStatusCardDispatchProps>(
    (state, { lead }) => ({
        hasActionMarkLeadAsUntreatedRight: hasActionMarkLeadAsUntreatedRight(state),
    }),
    {
        verifyPhoneNumber,
        showModal,
    }
)

export default class LeadStatusCard extends PureComponent<LeadStatusModel> {
    state = {
        isLoading: false,
        isVerifiedByEmployee: false,
    }

    verifyNumber = async () => {
        const { lead } = this.props

        this.setState({ isLoading: true })
        try {
            await this.props.verifyPhoneNumber(lead.client.id)
            this.setState({ isLoading: false, isVerifiedByEmployee: true  })
        } catch (e) {
            toastr.error('There was a problem during veryfing phone number', e)
        } finally {
            document.location.reload()
        }
        this.setState({ isLoading: false })
    }

    markAsUntreated = async () => {
        const { lead, showModal } = this.props

        showModal({
            name: 'markLeadAsUntreated',
            leadId: lead.id,
        })
    }

    render() {
        const { lead, leadQuotes, hasActionMarkLeadAsUntreatedRight } = this.props
        const { isLoading, isVerifiedByEmployee } = this.state
        return <Card>
            {getLeadStatus({ lead, leadQuotes }) === 'untreated' ||
            getLeadStatus({ lead, leadQuotes }) === 'validated' ||
            getLeadStatus({ lead, leadQuotes }) === 'quotes_full' ?
                <Card.Content>
                    <ProgressBar bsStyle="info" now={getLeadProgressPercentage({ lead, leadQuotes })}/>
                    <Row>
                        <Col sm={3}>
                            <MetaLabel
                                responsiveModifiers={{ untreated: 'highlight' }}
                                size={getLeadStatus({ lead, leadQuotes })}
                            >
                                Untreated
                            </MetaLabel>
                        </Col>
                        <Col sm={3}>
                            <MetaLabel
                                responsiveModifiers={{ validated: 'highlight' }}
                                size={getLeadStatus({ lead, leadQuotes })}
                            >
                                Validated
                            </MetaLabel>
                        </Col>
                        <Col sm={3}>
                            <MetaLabel
                                responsiveModifiers={{ quotes_full: 'highlight' }}
                                size={getLeadStatus({ lead, leadQuotes })}
                            >
                                Quotes full
                            </MetaLabel>
                        </Col>
                        <Col sm={3}>
                            <MetaLabel>Accept/reject</MetaLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" style={{ position: 'relative', marginTop: '2rem' }}>
                            <Flex modifiers={['alignCenter', 'justifyCenter']}>
                                {!leadQuotes.length && getLeadStatus({ lead, leadQuotes }) !== 'unknown' && <Button
                                    onClick={this.markAsUntreated}
                                    disabled={!hasActionMarkLeadAsUntreatedRight}
                                    modifiers={[ 'warning', 'nowrap', 'mAuto' ]}
                                >
                                    Mark as untreated
                                </Button>}
                                {
                                    isVerifiedByEmployee || lead.client.isPhoneVerified ?
                                        <MetaLabel modifiers={'colorGreen'}>Phone number verified</MetaLabel> :
                                        <div>
                                            <MetaLabel modifiers={'colorRed'}>Phone number not verified</MetaLabel>
                                            <Button
                                                onClick={this.verifyNumber}
                                                disabled={isLoading}
                                                modifiers={[ 'action', 'nowrap', 'mAuto' ]}
                                            >
                                                Mark as verified
                                            </Button>
                                        </div>
                                }
                            </Flex>
                        </Col>
                    </Row>
                </Card.Content> :
                <Card.Content>
                    <Row>
                        <MetaLabel>Status</MetaLabel>
                        <StatusLabelContainer>
                            <LeadStatusColor leadStatus={getLeadStatus({ lead, leadQuotes })}/>
                        </StatusLabelContainer>
                    </Row>
                    <Row>
                        <Col className="text-center" style={{ position: 'relative', marginTop: '2rem' }}>
                            <Flex modifiers={['alignCenter', 'justifyCenter']}>
                                {!leadQuotes.length && getLeadStatus({ lead, leadQuotes }) !== 'unknown' && <Button
                                    onClick={this.markAsUntreated}
                                    disabled={!hasActionMarkLeadAsUntreatedRight}
                                    modifiers={[ 'warning', 'nowrap', 'mAuto' ]}
                                >
                                    Mark as untreated
                                </Button>}
                                {
                                    lead.client.isPhoneVerified ?
                                        <MetaLabel modifiers={'colorGreen'}>Phone number verified</MetaLabel> :
                                        <div>
                                            <MetaLabel modifiers={'colorRed'}>Phone number not verified</MetaLabel>
                                            <Button
                                                onClick={this.verifyNumber}
                                                disabled={isLoading}
                                                modifiers={[ 'action', 'nowrap', 'mAuto' ]}
                                            >
                                                Mark as verified
                                            </Button>
                                        </div>
                                }
                            </Flex>
                        </Col>
                    </Row>
                </Card.Content>
            }
        </Card>
    }
}
