import React, { PureComponent, memo } from 'react'
import ReactMarkdown from 'react-markdown'
import { getTerms } from '../../actions/terms'
import { Modal } from 'react-bootstrap'
import { connect } from '../../utils/connect'
import { Term } from '../../reducers/types/entities/Term'
import { Card } from '../blocks/Card'
import { Column, Header, Rows, Table, Cell } from '../elements/table'
import styled from 'styled-components'

interface TermsPageModel {
    isLoadingTerms: boolean
    terms: Term[]
    getTerms: typeof getTerms
}

interface TermRowModel {
    showModal: (terms: Term) => void
    rowData?: Term
}

const ModalBody = styled(Modal.Body)`
  max-height: 50vh;
  padding: 0 20px;
  overflow: auto;
  border: 0;
`

const TermRow = memo(({ showModal, rowData }: TermRowModel) =>
    <tr key={rowData.id}>
        <Cell>{rowData.id}</Cell>
        <Cell>{rowData.headline}</Cell>
        <Cell>{rowData.identifier}</Cell>
        <Cell>
            <button
                onClick={() => showModal(rowData)}
                className="btn btn-primary mdi mdi-calendar-check"
            />
        </Cell>
    </tr>
)

interface TermsStateModel {
    showModal: boolean,
    term: {} | {
        headline: string
        body: string
    },
}

@connect(
    state => ({
        terms: state.pages.terms.termsIds.map(termId => state.entities.terms[termId]),
        isLoadingTerms: state.pages.terms.isLoading,
    }),
    {
        getTerms,
    }
)

export default class TermsPage extends PureComponent<TermsPageModel, TermsStateModel> {
    state = {
        showModal: false,
        term: {
            headline: '',
            body: '',
        },
    }

    componentDidMount() {
        this.props.getTerms()
    }

    showModal = term => {
        this.setState({ showModal: true, term })
    }

    render() {
        const {
            terms,
            isLoadingTerms,
        } = this.props

        return <Card>
            <Card.Header>Terms</Card.Header>
            <Card.Content modifiers="p_0" isLoading={isLoadingTerms}>
                <Table>
                    <Header>
                        <Column name="id">ID</Column>
                        <Column name="headline">Company name</Column>
                        <Column name="identifier">Identifier</Column>
                        <Column name="button">Show details</Column>
                    </Header>
                    <Rows
                        items={terms}
                        noDataText="We couldn't find any partners matching your selection."
                    >
                        <TermRow showModal={this.showModal}/>
                    </Rows>
                </Table>
            </Card.Content>
            <Modal
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false, term: {} })}
            >
                <Modal.Header closeButton={true}>{this.state.term.headline}</Modal.Header>
                <ModalBody>
                    <ReactMarkdown source={this.state.term.body} />
                </ModalBody>
                <Modal.Footer className="text-right">
                    <button
                        className="btn btn-secondary"
                        onClick={() => this.setState({ showModal: false, term: {} })}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </Card>
    }
}
