import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { Field, submit } from 'redux-form'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { PartnerDiscount } from '../../reducers/types/entities/PartnerDiscount'
import { Button } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import styled from 'styled-components'
import { createPartnerDiscounts, deletePartnerDiscounts } from '../../actions/subscriptions'
import { PackageTypes } from '../../reducers/types/entities/PackageType'
import { toastr } from 'react-redux-toastr'
import { AmountCell, Cell, Column, DateCell, Header, Table, Rows } from '../elements/table'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 1300px;
    }
`

interface PartnerDiscountCreateModel {
    subscriptionDiscountDelete: any
    partner: Partner
    packageTypes: PackageTypes
    employeeId: number
    modalProps: {
        partnerDiscounts: PartnerDiscount[]
        isLoadingContact: boolean
        leadId: number
    }
    deletePartnerDiscounts: typeof deletePartnerDiscounts
    createPartnerDiscounts: typeof createPartnerDiscounts
    hideModal: typeof hideModal
    submit: typeof submit
}

const DiscountRow = ({ onDelete, rowData, packageTypes }) => {
    const discount = rowData
    return <tr key={`discount_${discount.id}`}>
        <Cell>
            {packageTypes[discount.partnerPackageTypeId] &&
            packageTypes[discount.partnerPackageTypeId].name}
        </Cell>
        <DateCell date={discount.startsAt}/>
        <DateCell date={discount.endsAt}/>
        <AmountCell amount={discount.discount} />
        <Cell>
            <Button
                onClick={() => onDelete(discount.id)}
                modifiers={[ 'danger', 'btnIcon' ]}
            >
                <i className="mdi mdi-close" />
            </Button>
        </Cell>
    </tr>
}

const DiscountRowData = DiscountRow as ComponentType<{
    onDelete: (number) => void
    packageTypes: PackageTypes
}>


@connect(
    state => ({
        subscriptionDiscountDelete: state.entities.permissions.discount_delete,
        partner: state.entities.partners[state.modal.partnerId],
        packageTypes: state.entities.packageTypes,
        employeeId: state.auth.employeeId,
        modalProps: state.modal,
    }),
    {
        deletePartnerDiscounts,
        createPartnerDiscounts,
        hideModal,
        submit,
    }
)
class PartnerDiscountCreateModal extends PureComponent<PartnerDiscountCreateModel> {
    hideModal = () => this.props.hideModal()

    save = () => this.props.submit('createPartnerDiscount')

    deleteDiscount = discountId => {
        if (this.props.subscriptionDiscountDelete) this.props.deletePartnerDiscounts(this.props.partner.id, discountId)
        else toastr.error('Error!', 'Permission Required: "discount_delete')
    }

    createPartnerDiscount = data => {
        this.props.createPartnerDiscounts(this.props.partner.id, {
            employeeId: this.props.employeeId,
            ...data,
        })
    }

    render() {
        const {
            packageTypes,
            modalProps,
            partner,
        } = this.props

        return <LgModal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>
                Manage packages for
                {partner.companyName}
            </Modal.Header>
            <Modal.Body>
                <ReduxForm form="createPartnerDiscount" onSubmit={this.createPartnerDiscount}>
                    <Table modifiers="splitByRow">
                        <Header>
                            <Column>Package type</Column>
                            <Column>Starts at</Column>
                            <Column>Ends at</Column>
                            <Column>Discount amount</Column>
                            <Column>Action</Column>
                        </Header>
                        <Rows
                            items={modalProps.partnerDiscounts}
                            noDataText="No discounts"
                        >

                            <DiscountRowData
                                onDelete={this.deleteDiscount}
                                packageTypes={packageTypes}
                            />
                        </Rows>
                        <tr>
                            <Cell>
                                <Field
                                    name="partnerPackageTypeId"
                                    component={SelectInputGroup}
                                    removeClassFormGroup={true}
                                    returnId={true}
                                >
                                    {Object.values(packageTypes).map(el =>
                                        <option key={el.id + '_package'} value={el.id}>{el.name}</option>
                                    )}
                                </Field>
                            </Cell>
                            <Cell>
                                <Field
                                    name="startsAt"
                                    component={DateTimePickerGroup}
                                    removeClassFormGroup={true}
                                    removeAddon={true}
                                />
                            </Cell>
                            <Cell>
                                <Field
                                    name="endsAt"
                                    component={DateTimePickerGroup}
                                    removeClassFormGroup={true}
                                    removeAddon={true}
                                />
                            </Cell>
                            <Cell>
                                <Field
                                    name="discount"
                                    component={FieldGroup}
                                    removeClassFormGroup={true}
                                    type="number"
                                />
                            </Cell>
                            <Cell>
                                <Button
                                    onClick={this.save}
                                    modifiers={[ 'action', 'btnIcon' ]}
                                >
                                    <i className="mdi mdi-plus" />
                                </Button>
                            </Cell>
                        </tr>
                    </Table>
                </ReduxForm>
            </Modal.Body>
        </LgModal>
    }
}

export default PartnerDiscountCreateModal as unknown as ComponentType
