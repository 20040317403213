import React, { PureComponent } from 'react'
import FilterContainer from './_FilterContainer'
import LazyTable from './_LazyTable'
import Provider from './_Provider'
import Header from './_Header'
import Rows from './_Rows'
import Row from './_Row'

export interface PathPropsTableProvider {
    retrieveData?: (query: any, add?: boolean) => any
    pageName?: string
    statePageName?: string
    loading?: boolean
    criteria?
}

class ProviderTable extends PureComponent<PathPropsTableProvider> {
    static FilterContainer = FilterContainer
    static LazyTable = LazyTable
    static Header = Header
    static Rows = Rows
    static Row = Row
    render() { return <Provider {...this.props}/> }
}

export default ProviderTable
