import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import config from '../config'

export const GET_PRODUCTS = 'products/GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'products/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'products/GET_PRODUCTS_FAIL'

export const getProducts = (
    query: string,
    isArchived?: string
) => ({
    [CALL_API]: {
        types: [ GET_PRODUCTS, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL ],
        endpoint: '/products',
        schema: schemas.products,
        options: {
            body: {
                query,
                isArchived,
                geoCode: config.geoCode,
            },
        },
    },
})
