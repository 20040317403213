import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_PARTNER_ASSIGNEES = 'partnerAssignees/GET_PARTNER_ASSIGNEES'
export const GET_PARTNER_ASSIGNEES_SUCCESS = 'partnerAssignees/GET_PARTNER_ASSIGNEES_SUCCESS'
export const GET_PARTNER_ASSIGNEES_FAIL = 'partnerAssignees/GET_PARTNER_ASSIGNEES_FAIL'

export const CREATE_PARTNER_ASSIGNEE = 'partnerAssignees/CREATE_PARTNER_ASSIGNEE'
export const CREATE_PARTNER_ASSIGNEE_SUCCESS = 'partnerAssignees/CREATE_PARTNER_ASSIGNEE_SUCCESS'
export const CREATE_PARTNER_ASSIGNEE_FAIL = 'partnerAssignees/CREATE_PARTNER_ASSIGNEE_FAIL'

export const DELETE_PARTNER_ASSIGNEE = 'partnerAssignees/DELETE_PARTNER_ASSIGNEE'
export const DELETE_PARTNER_ASSIGNEE_SUCCESS = 'partnerAssignees/DELETE_PARTNER_ASSIGNEE_SUCCESS'
export const DELETE_PARTNER_ASSIGNEE_FAIL = 'partnerAssignees/DELETE_PARTNER_ASSIGNEE_FAIL'

export const getPartnerAssignees = (
    partnerId: number | string
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_ASSIGNEES,
            GET_PARTNER_ASSIGNEES_SUCCESS,
            GET_PARTNER_ASSIGNEES_FAIL,
        ],
        endpoint: `/partners/${partnerId}/assignees`,
        schema: schemas.partnerAssignees,
        options: {
            body: {
                limit: 500,
            },
        },
    },
})

interface CreatePartnerAssigneeModel {
    assigneeId: string | number
    assigneeType: string
    assigneeRole: string
}

export const createPartnerAssignee = (
    partnerId: number | string,
    body: CreatePartnerAssigneeModel
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_ASSIGNEE,
            CREATE_PARTNER_ASSIGNEE_SUCCESS,
            CREATE_PARTNER_ASSIGNEE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/assignees`,
        schema: schemas.partnerAssignee,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerAssignee = (
    partnerId: number | string,
    partnerAssigneeId: number | string
) => ({
    [CALL_API]: {
        types: [
            DELETE_PARTNER_ASSIGNEE,
            DELETE_PARTNER_ASSIGNEE_SUCCESS,
            DELETE_PARTNER_ASSIGNEE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/assignees/${partnerAssigneeId}`,
        schema: schemas.partnerAssignee,
        options: {
            method: 'delete',
        },
    },
})
