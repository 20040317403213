import { createSelector } from 'reselect'

const entitiesBusinessUnits = state => state.entities.businessUnits

const pagesBusinessUnitsBusinessUnitIds = state => state.pages.businessUnits.businessUnitIds

export const businessUnitListSelector = createSelector(
    entitiesBusinessUnits, pagesBusinessUnitsBusinessUnitIds,
    (businessUnits, businessUnitIds) => businessUnitIds.map(id => businessUnits[id])
)
