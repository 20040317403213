import React, { PureComponent, ComponentType } from 'react'
import ActionButton from '../elements/ActionButton'
import { getLeadContactArchives, getPartnerContactArchives, resendContactArchive } from '../../actions/contact'
import { ContactArchive } from '../../reducers/types/entities/ContactArchives'
import { connect } from '../../utils/connect'
import { LazyTable, Table, Column, Header, Rows, Cell, DateCell } from '../elements/table'
import { Pagination } from '../../reducers/types/Pagination'

interface ContactFlowRowModel<T> {
    rowData?: ContactArchive
    resendArchive: any
}
class ContactFlowRow<T> extends PureComponent<ContactFlowRowModel<T>> {
    resendArchive = () => this.props.resendArchive(this.props.rowData.id)

    render() {
        const contact = this.props.rowData

        const email = contact.channels.find(el => el.type === 'email')

        let emailIcon = ''

        switch (email && email.status) {
            case 'open':
                emailIcon = 'fa fa-envelope-o text-primary'
                break
            case 'delivered':
                emailIcon = 'fa fa-envelope text-success'
                break
            case 'processed':
                emailIcon = 'fa fa-envelope text-info'
                break
            case 'deferred':
                emailIcon = 'fa fa-envelope text-warning'
                break
            case 'click':
                emailIcon = 'fa fa-envelope-o text-success'
                break
            default:
                emailIcon = 'entypo-mail'
                break
        }

        return <tr key={contact.id}>
            <Cell><i className={emailIcon} /></Cell>
            <Cell>{email.status}</Cell>
            <Cell>{contact.id}</Cell>
            <DateCell date={contact.sentAt} format="relative" />
            <Cell>{email.title}</Cell>
            <Cell className="text-center">
                <ActionButton
                    label="Resend"
                    modifiers={[ 'primary', 'btnBlock' ]}
                    action={this.resendArchive}
                    hiddenData={<i className="fa fa-check" />}
                />
            </Cell>
        </tr>
    }
}

interface ContactFlowProps {
    stateName: string
    entityId: number
}

interface ContactFlowModel extends ContactFlowProps {
    contactArchives: ContactArchive[]
    isLoading: boolean
    pagination: Pagination
    getPartnerContactArchives: typeof getPartnerContactArchives
    getLeadContactArchives: typeof getLeadContactArchives
    resendContactArchive: typeof resendContactArchive
}

@connect<any,  any,  ContactFlowProps>(
    (state, props) => ({
        contactArchives: state.pages[props.stateName].archivesIds.map(id => state.entities.contactArchives[id]),
        isLoading: state.pages[props.stateName].loadingArchives,
        pagination: state.pages[props.stateName].archivesPagination,
    }),
    {
        getPartnerContactArchives,
        getLeadContactArchives,
        resendContactArchive,
    }
)
class ContactFlow extends PureComponent<ContactFlowModel> {
    componentDidMount() {
        this.retrieveData(1)
    }

    retrieveData = page => {
        if (this.props.stateName === 'leadDetail')
            return this.props.getLeadContactArchives(this.props.entityId, page, page > 1)
        else
            return this.props.getPartnerContactArchives(this.props.entityId, page, page > 1)
    }

    render() {
        const {
            contactArchives,
            isLoading,
            pagination,
        } = this.props

        return (
            <LazyTable
                isLoading={isLoading}
                pagination={pagination}
                modifiers={[ 'smMaxHeight', 'p_0' ]}
                retrieveData={this.retrieveData}
            >
                <Table modifiers="splitByRow">
                    <Header>
                        <Column />
                        <Column>Status</Column>
                        <Column>Archive ID</Column>
                        <Column>Send to</Column>
                        <Column>Type</Column>
                        <Column>Action</Column>
                    </Header>
                    <Rows
                        items={contactArchives}
                        noDataText="No e-mails"
                    >
                        <ContactFlowRow resendArchive={this.props.resendContactArchive}/>
                    </Rows>
                </Table>
            </LazyTable>
        )
    }
}

export default ContactFlow as unknown as ComponentType<ContactFlowProps>
