import { createSelector } from 'reselect'
import { values } from 'lodash'

const entitiesTasks = state => state.entities.tasks

const pagesTaskListId = state => state.pages.taskList.taskIds

const partnerTasksId = state => state.pages.taskList.partnerTaskId

export const tasksByPartnerSelector = createSelector(
    entitiesTasks,
    tasks => partnerId => values(tasks).filter(task =>
        task.taskables.find(taskable => taskable.partner && taskable.partner.id === Number(partnerId))
    )
)

export const partnerTasksSelector = createSelector(
    partnerTasksId, entitiesTasks,
    (ids, tasks) => ids.map(id => tasks[id])
)

export const tasksSelector = createSelector(
    entitiesTasks, pagesTaskListId,
    (tasks, taskIds) => taskIds.map(id => tasks[id])
)
