import React, { memo, ReactNode } from 'react'
import { FormGroup } from 'react-bootstrap'
import ControlLabel from '../../text/MetaLabel'
import FormControl from '../../FormControl'
import { WrappedFieldProps } from 'redux-form'
import { FieldInputProps } from 'formik'
import get from 'lodash/get'

interface SelectOptionModel {
    name?: string
    title?: string
    id: any
}

interface Event {
    target: {
        value: any,
    }
}

interface SelectInputGroupModel<Value> extends WrappedFieldProps {
    removeClassFormGroup?: boolean
    shouldMatch?: string
    className?: string
    label?: string
    options?: Array<any>
    defaultValue?: any
    returnId?: boolean
    field?: FieldInputProps<Value> | any
    form?: any
    children?: ReactNode
}
export const SelectInputGroup = memo(({
    removeClassFormGroup,
    className,
    returnId,
    children,
    options = [],
    input,
    field,
    form,
    label,
    defaultValue,
    meta,
    ...props
}: SelectInputGroupModel<any>) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={
            meta && meta.touched && (meta.error || meta.warning) ||
            get(form, `errors.${get(field, 'name')}`)
                ? 'error'
                : null
        }
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        <FormControl
            {...(returnId ? (input || field) : {})}
            onChange={(event: Event) => {
                if (form) {
                    return form.setFieldValue(field.name, event.target.value)
                }
                if (returnId) {
                    input.onChange(event.target.value)
                } else {
                    const selectedValue = options.find(el => el.id == event.target.value)
                    input.onChange(selectedValue)
                }
            }}
            componentClass="select"
            defaultValue={String(defaultValue || get(input, 'value.id') || get(input, 'value') || get(field, 'value') || '')}
            {...props}
        >
            <option value="" hidden disabled>Select...</option>
            {children}
            {options.map(option =>
                <option
                    key={option.id}
                    value={option.id}
                >
                    {option.title || option.name}
                </option>
            )}
        </FormControl>
    </FormGroup>
)
