import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import connect from 'react-redux/es/connect/connect'
import { getLeads } from '../../../actions/leads'
import {
    activitiesIdToLeadIdSelector,
    leadActivitiesSelector,
    leadsSelector,
} from '../../../selectors/lead'
import { createLeadNoteActivity, getActivities } from '../../../actions/newsfeed'
import { Link } from 'react-router-dom'
import { replace } from 'connected-react-router'
import config from '../../../config'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Activity } from '../../../reducers/types/entities/Activities'
import { Cell, DateCell, LinkCell, EditCell, Column, SortColumn } from '../../elements/table'
import ProviderTable from '../../blocks/ProviderTable'
import { removeEmptyProperties } from '../../../utils'

interface ExclusiveListRowModel {
    employeeId: number
    leadActivity: Activity
    createLeadNoteActivity: typeof createLeadNoteActivity
    getActivities: typeof getActivities
}

interface RowsHocModel {
    rowData: Lead
}

@connect(
    (state, props) => ({
        employeeId: state.auth.employeeId,
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
    }),
    {
        createLeadNoteActivity,
        getActivities,
    }
)
class ExclusiveListRow extends PureComponent<ExclusiveListRowModel & RowsHocModel> {
    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        await this.props.createLeadNoteActivity(leadId, noteData)
        return this.props.getActivities({ leadId })
    }

    render() {
        const lead = this.props.rowData

        const { leadActivity } = this.props

        return <tr key={lead.id} >
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>
                {lead.client.firstName} 
                {' '}
                {lead.client.lastName}
            </Cell>
            <DateCell date={lead.validatedAt} format="longdatetime" />
            <Cell>{lead.quotesInfo.count + '/' + lead.quotesInfo.max}</Cell>
            <EditCell
                value={leadActivity && leadActivity.note ? leadActivity.note.text : ''}
                onBlur={this.handleChangeNoteCell}
            />
            <DateCell date={lead.quotesInfo.clientDecisionPostponedTo} format="longdatetime" />
        </tr>
    }
}

const FollowUpsTableRow  = ExclusiveListRow as ComponentType

interface ExclusiveListTableCardModel {
    leads: Lead[]
    activities: Activity[]
    employeeId: number
    location: {
        pathname: string
    }
    criteria: {
        sort: string
    }
    getActivities: typeof getActivities
    getLeads: typeof getLeads
    replace: typeof replace
}
@connect(
    state => ({
        leads: leadsSelector(state),
        activities: activitiesIdToLeadIdSelector(state),
        employeeId: state.auth.employeeId,
        location: state.router.location,
        criteria: {
            sort: state.router.location.query.sort || '-client_decision_postponed_to',
        },
    }),
    {
        getActivities,
        getLeads,
        replace,
    }
)
class ExclusiveListTableCard extends PureComponent<ExclusiveListTableCardModel> {
    retrieveData = async (data?, append?) => {
        const criteria = removeEmptyProperties({
            ...data,
            leadCategory: 'exclusive',
            status: 'validated',
            hasAcceptedQuote: '0',
            hasRejectedQuote: '0',
            isGivenUp: '0',
            geoCode: config.geoCode,
        })
        const res: any = await this.props.getLeads(criteria, null, append)

        if (res.result.length) this.props.getActivities({ leadId: res.result })
        return res
    }

    render() {
        const {
            leads,
            criteria,
        } = this.props


        return <Card>
            <Card.Header>
                <Link to="/exclusive-leads">Exclusive list</Link>
            </Card.Header>
            <ProviderTable
                pageName="leads"
                retrieveData={this.retrieveData}
                criteria={criteria}
            >
                <ProviderTable.LazyTable>
                    <ProviderTable.Header>
                        <Column>Lead ID</Column>
                        <Column>Client name</Column>
                        <Column>Validated at</Column>
                        <Column>Quotes</Column>
                        <Column>Notes</Column>
                        <SortColumn name="client_decision_postponed_to">Postponed to</SortColumn>
                    </ProviderTable.Header>
                    <ProviderTable.Rows
                        items={leads}
                        noDataText="No leads for follow up list."
                    >
                        <FollowUpsTableRow />
                    </ProviderTable.Rows>
                </ProviderTable.LazyTable>
            </ProviderTable>
        </Card>
    }
}

export default ExclusiveListTableCard as unknown as ComponentType
