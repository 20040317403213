import React, { ComponentType, PureComponent } from 'react'
import { leadActivitiesSelector, leadsSelector } from '../../selectors/lead'
import { createLeadNoteActivity, getActivities } from '../../actions/newsfeed'
import { LeadStatusColor } from '../elements/LeadStatusColor'
import { getLeadStatus } from '../../utils/getLeadStatus'
import LeadActionsButtonDropdown from './LeadActionsButtonDropdown'
import { getLeads, getLead } from '../../actions/leads'
import { replace } from 'connected-react-router'
import { Card } from '../blocks/Card/index'
import { removeEmptyProperties } from '../../utils/index'
import config from '../../config'
import { connect } from '../../utils/connect'
import { Lead } from '../../reducers/types/entities/Lead'
import { Quotes } from '../../reducers/types/entities/Quote'
import { Activity } from '../../reducers/types/entities/Activities'
import ProviderTable from '../blocks/ProviderTable'
import { Cell, DateCell, LinkCell, EditCell, Column, SortColumn } from '../elements/table'

interface TableRowStateProps{
    employeeId: number
    quotes: Quotes
    leadActivity: Activity
}
interface TableRowDispatchProps {
    createLeadNoteActivity: typeof createLeadNoteActivity
    getActivities: typeof getActivities
    getLead: typeof getLead
}
interface TableRowHOCProps {
    rowData: Lead
}

type TableRowModel = TableRowStateProps & TableRowDispatchProps & TableRowHOCProps

@connect<TableRowStateProps, TableRowDispatchProps, TableRowHOCProps>(
    (state, props) => ({
        employeeId: state.auth.employeeId,
        quotes: state.entities.quotes,
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
    }),
    {
        createLeadNoteActivity,
        getActivities,
        getLead,
    }
)
class TableRow extends PureComponent<TableRowModel> {
    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        await this.props.createLeadNoteActivity(leadId, noteData)
        this.props.getLead(leadId)
        return this.props.getActivities({ leadId })
    }

    render() {
        const { leadActivity, quotes, ...props } = this.props

        const lead = this.props.rowData

        const publishedQuotes = lead.quotesInfo.quotes.map(id => quotes[id]).filter(quote => quote.publishedAt)

        return <ProviderTable.Row {...props}>
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>{lead.title}</Cell>
            <Cell>
                {lead.client.firstName} 
                {' '}
                {lead.client.lastName}
            </Cell>
            <DateCell date={lead.validatedAt} format="longdatetime" />
            <DateCell date={lead.quotesInfo.clientDecisionPostponedTo} format="longdatetime" />
            {lead.actionsInfo
                ? <DateCell date={lead.actionsInfo.latestCreatedAt} format="longdatetime" />
                : <Cell />
            }
            <EditCell
                rowId={lead.id}
                value={leadActivity && leadActivity.note ? leadActivity.note.text : ''}
                onBlur={this.handleChangeNoteCell}
            />
            <Cell>
                <LeadStatusColor
                    leadStatus={ getLeadStatus({
                        lead,
                        leadQuotes: lead.quotesInfo.quotes.map(id => quotes[id]),
                    })}
                />
            </Cell>
            <Cell className="text-center">
                <LeadActionsButtonDropdown
                    lead={lead}
                    publishedQuotes={publishedQuotes}
                />
            </Cell>
        </ProviderTable.Row>
    }
}

const ExclusiveLeadTableRow = TableRow as ComponentType

interface Criteria {
    sort: string
    query: string
}

interface LeadExclusiveStateProps{
    leads: Lead[]
    totalItems: number
    employeeId: number
    exclusiveClientsPermission: any
    criteria: Criteria
}
interface LeadExclusiveDispatchProps {
    getActivities: typeof getActivities
    getLeads: typeof getLeads
    replace: typeof replace
}

interface LeadExclusivePathProps {
    location: {
        query: Criteria
    }
}

type LeadExclusiveListModel = LeadExclusiveStateProps & LeadExclusiveDispatchProps & LeadExclusivePathProps

@connect<LeadExclusiveStateProps, LeadExclusiveDispatchProps, LeadExclusivePathProps>(
    (state, { location: { query } }) => ({
        leads: leadsSelector(state),
        totalItems: state.pages.leads.pagination.total,
        employeeId: state.auth.employeeId,
        exclusiveClientsPermission: state.entities.permissions.exclusive_clients,
        criteria: {
            sort: query.sort || 'last_action_at',
            query: query.query || '',
        },
    }),
    {
        getActivities,
        getLeads,
        replace,
    }
)
export default class LeadExclusiveListPage extends PureComponent<LeadExclusiveListModel> {
    componentDidMount() {
        if(!this.props.exclusiveClientsPermission) this.props.replace('/')
    }

    retrieveData = async (data, append?) => {
        const criteria = removeEmptyProperties({
            ...data,
            leadCategory: 'exclusive',
            geoCode: config.geoCode,
            status: 'validated',
            hasAcceptedQuote: '0',
            hasRejectedQuote: '0',
            isGivenUp: '0',
        })
        const res: any = await this.props.getLeads(criteria, null, append)
        if (res.result.length) this.props.getActivities({ leadId: res.result })
    }

    render() {
        const {
            leads,
            criteria,
            totalItems,
        } = this.props

        return <Card>
            <Card.Header>
                Exclusive list 
                {' '}
                <span className="text-muted">
                    (
                    {leads.length}
                    {' '}
                    /
                    {totalItems || 0}
                    )
                </span>
            </Card.Header>
            <ProviderTable
                pageName="leads"
                retrieveData={this.retrieveData}
                criteria={criteria}
            >
                <ProviderTable.LazyTable>
                    <ProviderTable.Header>
                        <Column>Lead ID</Column>
                        <Column>Lead title</Column>
                        <Column>Client name</Column>
                        <Column>Validated at</Column>
                        <SortColumn name="client_decision_postponed_to">Postponed to</SortColumn>
                        <SortColumn name="last_action_at">Last action</SortColumn>
                        <Column>Last note</Column>
                        <Column>Status</Column>
                        <Column>Action</Column>
                    </ProviderTable.Header>
                    <ProviderTable.Rows
                        items={leads}
                        noDataText="No leads for follow up list."
                    >
                        <ExclusiveLeadTableRow/>
                    </ProviderTable.Rows>
                </ProviderTable.LazyTable>
            </ProviderTable>
        </Card>
    }
}
