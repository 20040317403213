import React, { PureComponent, ComponentType } from 'react'
import { Card } from '../../blocks/Card/index'
import { Col, Row } from 'react-bootstrap'
import { Field, FieldArray, submit } from 'redux-form'
import { Button } from '../../elements/Button'
import styled from 'styled-components'
import Placeholder from '../../elements/Placeholder'
import { DynamicDataFields } from '../../elements/dynamicDataFields'
import { TypeaheadGroup } from '../../elements/forms/inputs/TypeaheadGroup'
import { excludedLeadPartnersSelector } from '../../../selectors/lead'
import { showModal } from '../../../actions/modal'
import { connect } from '../../../utils/connect'
import { Certification } from '../../../reducers/types/entities/Certification'
import { leadIndustryCertificationsSelector } from '../../../state/certifications/selectors'
import { updateLeadCertification } from '../../../state/certifications/actions'
import { updateLeadCompanySizes } from '../../../state/companysizes/actions'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'

const ExcludeContainer = styled(Col)`
  padding-top: 25px;
`

interface LeadPartnersRequirementsStateProps {
    certifications: Certification[]
    totalExcludedPartners: number
}
interface LeadPartnersRequirementsDispatchProps {
    updateLeadCertification: typeof updateLeadCertification
    updateLeadCompanySizes: typeof updateLeadCompanySizes
    showModal: typeof showModal
    submit: typeof submit
}
interface LeadPartnersRequirementsPathProps {
    selectedPrimaryIndustryId: number
    isLoadingDataFields: boolean
    leadId: number
    companySizes: any[]
}

type LeadPartnersRequirementsModel = LeadPartnersRequirementsStateProps
    & LeadPartnersRequirementsDispatchProps
    & LeadPartnersRequirementsPathProps

@connect<LeadPartnersRequirementsStateProps, LeadPartnersRequirementsDispatchProps, LeadPartnersRequirementsPathProps>(
    (state, props) => ({
        certifications: leadIndustryCertificationsSelector(state, props.selectedPrimaryIndustryId),
        totalExcludedPartners: excludedLeadPartnersSelector(state).length,
    }),
    {
        updateLeadCertification,
        updateLeadCompanySizes,
        showModal,
        submit,
    }
)
class LeadPartnersRequirementsCard extends PureComponent<LeadPartnersRequirementsModel> {
    showModal = () => {
        this.props.showModal({
            name: 'excludePartners',
            leadId: this.props.leadId,
        })
    }

    render() {
        const {
            totalExcludedPartners,
            isLoadingDataFields,
            certifications,
            companySizes,
        } = this.props

        return <Card>
            <Card.Header>
                <strong>Partner requirements</strong>
            </Card.Header>
            <Card.Content>
                {isLoadingDataFields ?
                    <Placeholder /> :
                    <FieldArray
                        name="attributes"
                        component={DynamicDataFields}
                        category="requirement"
                    />
                }
                <Field
                    name="geoRange"
                    label="Maximum distance to partner (km)"
                    component={FieldGroup}
                    type="number"
                />
                <Field
                    name="companySizes"
                    label="Desired company sizes"
                    component={TypeaheadGroup}
                    options={companySizes}
                    labelKey="title"
                    multiple={true}
                />
                <Field
                    name="certifications"
                    label="Certifications"
                    component={TypeaheadGroup}
                    options={certifications}
                    labelKey="title"
                    multiple={true}
                />
                <Row>
                    <ExcludeContainer md={6}>
                        <Button
                            onClick={this.showModal}
                            modifiers={[ 'secondary', 'fullWidth' ]}
                        >
                            Exclude partners (
                            {totalExcludedPartners}
                            )
                        </Button>
                    </ExcludeContainer>
                </Row>
            </Card.Content>
        </Card>
    }
}

export default LeadPartnersRequirementsCard as unknown as ComponentType<LeadPartnersRequirementsPathProps>
