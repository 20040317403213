import React, { memo } from 'react'
import Cell from './Cell'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Satisfaction from '../../Satisfaction'
import styled from 'styled-components'

interface SatisfactionCellProps {
    satisfaction: {
        note: string
        score: number
        createdAt: string
    }
}

const SatisfactionEmoji = styled(Satisfaction)`
  padding: 3px;
  margin: auto
`

export default memo(({ satisfaction }: SatisfactionCellProps) => {
    if (!satisfaction) return <Cell>-</Cell>

    if (satisfaction.note) {
        let satisfactionTooltip = <Tooltip
            placement="right"
            className="in"
            id={'satisfaction-tooltip-' + satisfaction.createdAt}
        >
            {satisfaction.note}
        </Tooltip>

        return <Cell>
            <OverlayTrigger delay={0} placement="right" overlay={satisfactionTooltip} animation={false} delayHide={0}>
                <SatisfactionEmoji size={30} score={satisfaction.score} round/>
            </OverlayTrigger>
        </Cell>
    }

    return <Cell>
        <Satisfaction size={20} score={satisfaction.score}/>
    </Cell>
})
