import React, { memo } from 'react'
import { Card } from '../../../blocks/Card'
import { FieldArray } from 'redux-form'
import { QuotesSetupFields } from './QuotesSetupFields'
import QuoteItemsForm from './QuoteItemsForm'
import { Auth } from '../../../../reducers/auth'

interface CreateProductTabPropsModel {
    createQuoteFeePermission: string
    disableQuoteAmount: boolean
    adjustAmount: boolean
    disableItems: boolean
    isServiceFee: boolean
    leadId: number
    wantedGeoCode: Auth['wantedGeoCode']
    handleMarketplaceToggle: () => void,
    showForMarketplace: boolean
}

export const CreateProductTab = memo(({
    createQuoteFeePermission,
    disableQuoteAmount,
    adjustAmount,
    disableItems,
    isServiceFee,
    leadId,
    wantedGeoCode,
    handleMarketplaceToggle,
    showForMarketplace
}: CreateProductTabPropsModel) =>
    <Card.Content>
        <QuotesSetupFields
            createQuoteFeePermission={createQuoteFeePermission}
            disableAmount={adjustAmount ? false : disableItems}
            disableQuoteAmount={disableQuoteAmount}
            isServiceFee={isServiceFee}
            wantedGeoCode={wantedGeoCode}
            handleMarketplaceToggle={handleMarketplaceToggle}
            showForMarketplace={showForMarketplace}
        />
        <Card.Line modifiers={[ 'mY_2' ]} />
        <FieldArray
            name="products"
            leadId={leadId}
            disableItems={disableItems}
            component={QuoteItemsForm}
        />
    </Card.Content>
)
