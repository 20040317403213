import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const CREATE_KPI_TARGETS = 'leads/CREATE_KPI_TARGETS'
export const CREATE_KPI_TARGETS_SUCCESS = 'leads/CREATE_KPI_TARGETS_SUCCESS'
export const CREATE_KPI_TARGETS_FAIL = 'leads/CREATE_KPI_TARGETS_FAIL'

export const GET_KPI_VALUES = 'leads/GET_KPI_VALUES'
export const GET_KPI_VALUES_SUCCESS = 'leads/GET_KPI_VALUES_SUCCESS'
export const GET_KPI_VALUES_FAIL = 'leads/GET_KPI_VALUES_FAIL'

export const GET_KPI_TARGETS = 'leads/GET_KPI_TARGETS'
export const GET_KPI_TARGETS_SUCCESS = 'leads/GET_KPI_TARGETS_SUCCESS'
export const GET_KPI_TARGETS_FAIL = 'leads/GET_KPI_TARGETS_FAIL'

interface GetKPIModel {
    employeeId: number | number[]
    kpiIdentifier: string[]
    temporalScope?: string
    geoCode?: string
    year?: number
    month?: number
}
export const getKPIValues = (
    body: GetKPIModel,
) => ({
    [CALL_API]: {
        types: [ GET_KPI_VALUES, GET_KPI_VALUES_SUCCESS, GET_KPI_VALUES_FAIL ],
        endpoint: '/kpi/values',
        schema: schemas.kpiValues,
        options: {
            body,
        },
    },
})

interface CreateKPITargetsModel {
    kpiIdentifier: string
    employeeId: string
    month: string
    value: number
    year: string
}
export const createKPITargets = (
    body: CreateKPITargetsModel,
) => ({
    [CALL_API]: {
        types: [ CREATE_KPI_TARGETS, CREATE_KPI_TARGETS_SUCCESS, CREATE_KPI_TARGETS_FAIL ],
        endpoint: '/kpi/targets',
        schema: schemas.kpiTarget,
        options: {
            method: 'post',
            body,
        },
    },
})

export const getKPITargets = (
    body: GetKPIModel,
) => ({
    [CALL_API]: {
        types: [ GET_KPI_TARGETS, GET_KPI_TARGETS_SUCCESS, GET_KPI_TARGETS_FAIL ],
        endpoint: '/kpi/targets',
        schema: schemas.kpiTargets,
        options: {
            body,
        },
    },
})
