import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../elements/Button'
import moment from 'moment'
import { FormattedAmount } from '../elements/FormattedAmount'
import { hideModal, showModal } from '../../actions/modal'
import { createMatchPrediction } from '../../actions/employees'
import { connect } from '../../utils/connect'

interface LeadValidationConfirmModel {
    modalProps: {
        disabled: boolean
    }
    predictionValues: {
        quoteFee: {
            amount: number
            currency: string
        }
        matchBy: string
        probabilityOfMatch: string
    }
    createMatchPrediction: typeof createMatchPrediction
    hideModal: typeof hideModal
    showModal: typeof showModal
}

@connect(
    state => ({
        modalProps: state.modal,
        predictionValues: state.form.leadValidation.values.quotesInfo.employeeLeadPrediction || {},
    }), {
        hideModal,
        showModal,
    }
)
class LeadValidationConfirmModal extends PureComponent<LeadValidationConfirmModel> {
    hideModal = () => this.props.hideModal()

    validateLead = () => {
        this.hideModal()
        this.props.showModal({ confirm: true })
    }

    render() {
        const {
            predictionValues,
            modalProps,
        } = this.props
        return (
            <Modal
                show={true}
                onHide={this.hideModal}
                backdrop="static"
            >
                <Modal.Header>Validate lead</Modal.Header>
                <Modal.Body>
                    <h4>Are you sure, that you want validate lead ?</h4>
                    <br />
                    <h4>Please confirm your match predictions:</h4>
                    <p>
                        <strong>Expected fee: </strong>
                        <FormattedAmount amount={predictionValues.quoteFee}/>
                    </p>
                    <p>
                        <strong>Expected closing date: </strong>
                        {moment(predictionValues.matchBy).format('YYYY-MM-DD')}
                    </p>
                    <p>
                        <strong>Chance of closing: </strong>
                        {predictionValues.probabilityOfMatch}
                        {' '}
                        %
                    </p>
                </Modal.Body>
                <Modal.Footer className="text-right">
                    <Button
                        modifiers={[ 'secondary' ]}
                        onClick={this.hideModal}
                        disabled={modalProps.disabled}
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        modifiers={[ 'action' ]}
                        onClick={this.validateLead}
                        disabled={modalProps.disabled}
                    >
                        Yes - validate lead
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default LeadValidationConfirmModal as unknown as ComponentType
