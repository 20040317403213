import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const ADD_PARTNER_FEATURE = actionTypesCreator('features', 'ADD_PARTNER_FEATURE')
export const REMOVE_PARTNER_FEATURE = actionTypesCreator('features', 'REMOVE_PARTNER_FEATURE')

export const addPartnerFeature = (
    partnerId: number,
    feature: string
) => ({
    [CALL_API]: {
        types: ADD_PARTNER_FEATURE,
        endpoint: `/partners/${partnerId}/features`,
        schema: schemas.partnerFeatures,
        options: {
            method: 'post',
            body: {
                identifier: feature,
            },
        },
    },
})

export const removePartnerFeature = (
    partnerId: number,
    feature: string
) => ({
    [CALL_API]: {
        types: REMOVE_PARTNER_FEATURE,
        endpoint: `/partners/${partnerId}/features/${feature}`,
        schema: schemas.partnerFeatures,
        options: {
            method: 'delete',
        },
    },
})
