import React, { memo, ReactNode } from 'react'
import Column from './Column'

interface SortColumnModel {
    className?: string
    children: ReactNode
    onClick?: (direction: string) => void
    sort?: string
    name: string
}

const getSortDirection = (sort, name) => {
    if (sort === name) {
        return '-' + name
    }
    if (sort === `-${name}`) {
        return ''
    }
    return name
}

const SortColumn = memo(({
    className,
    sort = '',
    children,
    onClick,
    name,
}: SortColumnModel) =>
    <Column className={className} name={name}>
        <a
            onClick={() => {
                onClick(getSortDirection(sort, name))
            }}
        >
            <strong>{children}</strong>
            {sort && sort.includes(name) && sort.indexOf(name) < 2 &&
            <span>
&nbsp;
                <i className={'fa fa-caret-' + (sort === name ? 'up' : 'down')} />
            </span>}
        </a>
    </Column>
)

export default SortColumn
