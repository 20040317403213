import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_INVOICES = actionTypesCreator('invoices', 'GET_INVOICES')

export const GET_INVOICE = actionTypesCreator('invoices', 'GET_INVOICE')

export const GET_DUE_INVOICES = actionTypesCreator('invoices', 'GET_DUE_INVOICES')

export const getInvoices = (
    criteria,
    page: number = 1
) => ({
    [CALL_API]: {
        types: GET_INVOICES,
        endpoint: '/invoices',
        schema: schemas.invoices,
        options: {
            body: {
                limit: 10,
                ...criteria,
                page,
            },
        },
    },
})

export const getInvoice = (
    invoiceId: number
) => ({
    [CALL_API]: {
        types: GET_INVOICE,
        endpoint: '/invoices/' + invoiceId,
        schema: schemas.invoice,
    },
})

export const getDueInvoices = (
    partnerId: number,
) => ({
    [CALL_API]: {
        types: GET_DUE_INVOICES,
        endpoint: '/invoices',
        schema: schemas.invoices,
        options: {
            body: {
                per_page: 1000,
                limit: 1000,
                isPaid: 'false',
                partnerId,
            },
        },
    },
})
