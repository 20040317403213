import React, { PureComponent } from 'react'
import { formatNumber, parseNumber } from 'libphonenumber-js'
import { Button } from './Button'
import { BtnHref } from './Links'
import { CopyToClipboard } from './CopyToClipboard'
import { Phone } from 'react-feather'
import View from '../theme/View'

interface CallButtonModel {
    onCall: (result?: string) => void
    phoneNumber: string
    geoCode: string
    possibilityToCopy?: boolean
    label?: string
}
export default class CallButton extends PureComponent<CallButtonModel> {
    static defaultProps = {
        btnClass: 'btn btn-action btn-sm',
        label: 'Call',
    }

    state = {
        called: false,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.phoneNumber !== this.props.phoneNumber) {
            this.setState({ called: false })
        }
    }

    call = () => {
        this.setState({ called: true })
        if (this.props.onCall) {
            this.props.onCall()
        }
    }

    render() {
        const {
            possibilityToCopy,
            phoneNumber,
            geoCode,
            label,
        } = this.props

        const { called } = this.state

        let displayNumber = null
        let linkNumber = '#'
        let parsedPhoneNumber = null

        // We're using 'uk' as geoCode, but `libphonenumber-js` uses 'GB'
        const phoneGeoCode: any = geoCode === 'uk' ? 'GB' : geoCode.toUpperCase()

        if (!called) {
            return <Button modifiers={[ 'action', 'nowrap' ]} onClick={this.call}>
                <View mr={2}><Phone size={16}/></View>
                {label}
            </Button>
        }

        try {
            parsedPhoneNumber = parseNumber(phoneNumber, phoneGeoCode)

            if (parsedPhoneNumber.country === phoneGeoCode) {
                displayNumber = formatNumber(parsedPhoneNumber, 'National')
            } else {
                displayNumber = formatNumber(parsedPhoneNumber, 'International')
            }

            linkNumber = 'tel:' + formatNumber(parsedPhoneNumber, 'International').replace(/\s/g, '')

            if (!parsedPhoneNumber.phone) {
                throw Error('No valid phone number parsed from ' + phoneNumber)
            }

            return possibilityToCopy
                ? <BtnHref modifiers={[ 'action', 'nowrap' ]} href={linkNumber}>
                    <CopyToClipboard>{displayNumber}</CopyToClipboard>
                </BtnHref>
                : <BtnHref modifiers={[ 'action', 'nowrap' ]} href={linkNumber}>{displayNumber}</BtnHref>
        } catch (error) {
            return <Button modifiers={[ 'action', 'nowrap' ]}>{phoneNumber}</Button>
        }
    }
}
