import React, { PureComponent, ComponentType } from 'react'
import PartnerNewsFeed from './PartnerNewsFeed'
import CanvasControlsCard from './detailPage/CanvasControlsCard'
import TasksCard from '../tasks/TasksCard'
import PartnerUsersCard from './detailPage/PartnerUsersCard'
import { Card } from '../blocks/Card'
import PartnerMetaData from './detailPage/PartnerMetaData'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { getPartner } from '../../actions/partners'

interface StatePropsSidebar {
    partner: Partner
    activeRowId: number
}
interface DispatchPropsSidebar {
    getPartner: typeof getPartner
}
interface PathPropsSidebar {
    header: string
    viewKey: string
}

type PartnerPreviewSidebarModel = StatePropsSidebar & DispatchPropsSidebar & PathPropsSidebar

@connect<StatePropsSidebar, DispatchPropsSidebar, PathPropsSidebar >(
    state => ({
        partner: state.entities.partners[state.router.location.query.activeRowId],
        activeRowId: state.router.location.query.activeRowId,
    }),
    {
        getPartner,
    }
)
class PartnerPreviewSidebar extends PureComponent<PartnerPreviewSidebarModel> {
    componentDidMount() {
        this.retrieveInfo(this.props.activeRowId)
    }

    componentDidUpdate(newProps) {
        if (this.props.activeRowId !== newProps.activeRowId)
            this.retrieveInfo(newProps.activeRowId)
    }

    retrieveInfo(partnerId) {
        this.props.getPartner(partnerId)
    }

    render() {
        const {
            activeRowId,
            partner,
        } = this.props

        if (!activeRowId || !partner)
            return <Card margin="0">
                <Card.Content>
                    Select a partner to show partner details...
                </Card.Content>
            </Card>
        return (
            <Card.Content modifiers="p_1">
                <PartnerUsersCard
                    geoCode={partner.geo.code}
                    partnerId={partner.id}
                />
                <Card>
                    <Card.Header>Company Details</Card.Header>
                    <Card.Content>
                        <PartnerMetaData partner={partner}/>
                    </Card.Content>
                </Card>
                {partner.state === 'canvas' && <CanvasControlsCard
                    partner={partner}
                />}
                <TasksCard entityType="partner" entityId={partner.id}/>
                <PartnerNewsFeed partnerId={partner.id} />
            </Card.Content>
        )
    }
}

export default PartnerPreviewSidebar as unknown as ComponentType<PathPropsSidebar>
