import React, { PureComponent, ComponentType } from 'react'
import { replace } from 'connected-react-router'
import { getCSMTasks } from '../../actions/tasks'
import { getLeads } from '../../actions/leads'
import { Card } from '../blocks/Card/index'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { connect } from '../../utils/connect'
import { Task } from '../../reducers/types/entities/Task'
import { Lead } from '../../reducers/types/entities/Lead'
import { Employee } from '../../reducers/types/entities/Employee'
import { Taskable } from '../../reducers/types/entities/Taskable'
import { employeeSelector } from '../../selectors/employees'
import ProviderTable from '../blocks/ProviderTable'
import {
    Cell,
    Column,
    DateCell,
    DropdownFilter,
    EmployeeCell,
    LinkCell,
    OverdueCell,
    SortColumn,
} from '../elements/table'

const DUE_BEFORE_FILTER_ITEMS = [
    {
        id: 'tomorrow',
        name: 'Today and overdue',
    }, {
        id: 'next week',
        name: 'This week and overdue',
    }, {
        id: 'next month',
        name: 'This month and overdue',
    },
]

interface TaskRowStateProps {
    lead: Lead
    employee: Employee
    taskable: Taskable
}

interface TaskRowHOCProps {
    rowData: Task
    assignedEmployee: number
    visibleCells: string[]
}

type TaskRowModel = TaskRowStateProps & TaskRowHOCProps

@connect<TaskRowStateProps, {},  TaskRowHOCProps>(
    ({ entities }, { rowData }) => ({
        taskable: rowData.taskables.find(taskable => taskable.type === 'lead'),
        lead: entities.leads[rowData.taskables.find(taskable => taskable.type === 'lead').lead.id],
        employee: entities.employees[rowData.assignedEmployee],
    })
)
class TaskRow extends PureComponent<TaskRowModel> {
    render() {
        const {
            taskable,
            employee,
            lead,
            ...props
        } = this.props

        const task = this.props.rowData

        return (
            <ProviderTable.Row {...props}>
                <EmployeeCell employee={employee} />
                <OverdueCell date={task.deadlineAt} />
                <Cell>{ task.title }</Cell>
                { lead ? <Cell>{ lead.industries.primary && lead.industries.primary.name }</Cell> : <Cell/> }
                <Cell>{`${taskable.lead.price} credits`}</Cell>
                <Cell>{ taskable.lead.title }</Cell>
                <Cell>{ taskable.lead.city }</Cell>
                <DateCell date={taskable.createdAt} format="datetime" />
                <LinkCell to={`/leads/${taskable.lead.id}/validation`}>
                    #
                    { taskable.lead.id }
                </LinkCell>
            </ProviderTable.Row>
        )
    }
}

const TaskTableRow = TaskRow as ComponentType

interface Criteria {
    assignedEmployeeId: string[]
    visibleColumns: string[]
    dueBefore: string[]
    sort: string
}

interface CSMTaskListStateProps {
    tasks: Task[]
    totalItems: number
    employees: Employee[]
    criteria: Criteria
}

interface CSMTaskListDispatchProps {
    getCSMTasks: typeof getCSMTasks
    getLeads: typeof getLeads
    replace: typeof replace
}

interface CSMTaskListPathProps {
    location: {
        query: Criteria
    }
}

type CSMTaskListPageModel = CSMTaskListStateProps & CSMTaskListDispatchProps & CSMTaskListPathProps

@connect<CSMTaskListStateProps, CSMTaskListDispatchProps, CSMTaskListPathProps>(
    (state, { location }) => ({
        tasks: state.pages.csmTaskList.taskIds.map(id => state.entities.tasks[id]),
        totalItems: state.pages.csmTaskList.pagination.total,
        employees: employeeSelector(state),
        criteria: {
            assignedEmployeeId: splitFilterString(location.query.assignedEmployeeId),
            visibleColumns: splitFilterString(location.query.visibleColumns),
            dueBefore: splitFilterString(location.query.dueBefore || 'tomorrow'),
            sort: location.query.sort || 'created_at',
        },
    }),
    {
        getCSMTasks,
        getLeads,
        replace,
    }
)
export default class CSMTaskListPage extends PureComponent<CSMTaskListPageModel> {
    retrieveData = (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({ ...filterCriteria })
        const resCSMTasks: any = this.props.getCSMTasks(criteria, append)
        resCSMTasks.then(() => this.props.getLeads({
            leadId: this.props.tasks.map(task => (
                task.taskables.find(taskable => taskable.type === 'lead').lead.id
            )),
            limit: this.props.tasks.length,
        }))
        return resCSMTasks
    }

    render() {
        const {
            tasks,
            criteria,
            employees,
            totalItems,
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    CSM Tasks 
                    {' '}
                    <span className="text-muted">
                        (
                        {tasks.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="csmTaskList"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.assignedEmployeeId}
                            items={employees}
                            name="assignedEmployeeId"
                            label="CSM"
                            search={false}
                        />
                        <DropdownFilter
                            selectedIds={criteria.dueBefore}
                            items={DUE_BEFORE_FILTER_ITEMS}
                            name="dueBefore"
                            label="Due"
                            search={false}
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="owner">Owner</Column>
                            <Column name="next-follow-up">Next follow up</Column>
                            <Column name="follow-up-types">Follow up type</Column>
                            <Column name="industry">Industry</Column>
                            <Column name="price">Price</Column>
                            <Column name="title">Title</Column>
                            <Column name="city">City</Column>
                            <SortColumn name="created_at">Created</SortColumn>
                            <Column name="lead-id">Lead ID</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={tasks}
                            noDataText="No tasks match the selected criteria."
                        >
                            <TaskTableRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
