import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import RadioGroup from '../elements/forms/inputs/RadioGroup'
import { closeLeadAsCompleted } from '../../actions/leads'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { push } from 'connected-react-router'

const required = [ value => value ? undefined : 'Required!' ]

const markReasons = [
    {
        id: 'no_relevant_partners_expertise',
        name: 'No relevant partners (missing expertise)',
    },
    {
        id: 'no_relevant_partners_geography',
        name: 'No relevant partners (geography)',
    },
    {
        id: 'client_budget',
        name: 'Budget of the client',
    },
    {
        id: 'client_not_serious',
        name: 'Client is not serious anymore',
    },
    {
        id: 'client_satisfied',
        name: 'Client is satisfied with less quotes',
    },
    {
        id: 'client_case_too_small',
        name: 'client case is too small / not interesting',
    },
]

interface LeadMarkAsCompleteModel {
    modalProps: {
        leadId: number
        disabled: boolean
    }
    closeLeadAsCompleted: typeof closeLeadAsCompleted
    hideModal: typeof hideModal
    push: typeof push
    submit: typeof submit
}
@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        closeLeadAsCompleted,
        push,
        hideModal,
        submit,
    }
)
class LeadMarkAsCompleteModal extends PureComponent<LeadMarkAsCompleteModel> {
    hideModal = () => this.props.hideModal()

    markAsCompleteAction = async ({ reasonCompleted }) => {
        await this.props.closeLeadAsCompleted(this.props.modalProps.leadId, reasonCompleted)
        this.props.push('/quotes/surveillance')
    }

    save = () => {
        this.props.submit('leadRejectReasonForm')
    }

    render() {
        const { modalProps } = this.props

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Mark lead as complete</Modal.Header>
            <Modal.Body>
                <h3 className="text-center">Why was it not possible to find 3 quotes for this lead?</h3>
                <br />
                <ReduxForm
                    form="leadRejectReasonForm"
                    onSubmit={this.markAsCompleteAction}
                >
                    <Field
                        validate={required}
                        component={RadioGroup}
                        options={markReasons}
                        name="reasonCompleted"
                    />
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    disabled={modalProps.disabled}
                    modifiers={[ 'secondary' ]}
                    onClick={this.hideModal}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    disabled={modalProps.disabled}
                    modifiers={[ 'action' ]}
                    onClick={this.save}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default LeadMarkAsCompleteModal as unknown as ComponentType
