import { createSelector } from 'reselect'
import moment from 'moment'

const entitiesAllocationLimits = state => state.entities.allocationLimits

const businessUnitAllocationLimitsId = state => state.subjects.allocationLimits.businessUnit.ids

export const businessUnitAllocationLimitsSelector = createSelector(
    businessUnitAllocationLimitsId, entitiesAllocationLimits,
    (ids, allocations) => ids
        .map(id => allocations[id])
        .sort((a, b) => moment(a.effectiveAt).isAfter(b.effectiveAt) ? -1 : 1)
)
