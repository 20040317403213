import React, { memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import Checkbox from '../../../blocks/Checkbox'
import { Flex, FlexModifierModel } from '../../Flex'
import ControlLabel from '../../text/MetaLabel'
import styled from 'styled-components'
import { WrappedFieldProps } from 'redux-form'
import { FieldInputProps, FormikHelpers } from 'formik'

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

interface CheckboxInputFieldPropsModel {
    modifiers?: FlexModifierModel[] | FlexModifierModel
    text?: JSX.Element | string
    input: {
        value: any
        checked?: string | boolean
        onChange?
        disabled?
    }
}

interface MultipleCheckboxDataFieldModel {
    modifiers?: FlexModifierModel[] | FlexModifierModel
    field?: FieldInputProps<any>
    input?: any
    text?: string
    option: {
        id: number
        text?: string
        title?: string
    }
}

interface CheckboxGroupModel extends WrappedFieldProps{
    options: []
    removeClassFormGroup?: boolean
    modifiers?: FlexModifierModel[]
    className?: string
    label?: string
}

interface SingleCheckboxDataFieldModel extends Partial<WrappedFieldProps> {
    options?: [{id: number}]
    modifiers?: FlexModifierModel[]
    className?: string
    text?: string
    field?: FieldInputProps<any>
    form?: FormikHelpers<any>
}

export const MultipleCheckboxDataField = memo(({
    modifiers,
    option,
    input,
    field,
    text,
}: MultipleCheckboxDataFieldModel) =>
    <Flex modifiers={modifiers}>
        <Checkbox
            value={option.id}
            checked={((input || field).value || []).find(el => String(el.id) === String(option.id))}
            onChange={event => {
                let newValue = [ ...(input || field).value ]
                if(event.target.checked) {
                    newValue.push(option)
                } else {
                    newValue = newValue.filter(el => String(el.id) !== String(option.id))
                }
                return (input || field).onChange(newValue)
            }}
        />
        {text || option.text || option.title}
    </Flex>
)

export const SingleCheckboxDataField = memo(({
    modifiers,
    options,
    field,
    form,
    input,
    text,
}: SingleCheckboxDataFieldModel) =>
    <Flex modifiers={modifiers}>
        <Checkbox
            checked={((input || field).value || []).find(el => Number(el.id) === 1)}
            onChange={event => {
                const id = Number(event.target.checked)
                const newValue = [
                    options.find(el => el.id === id),
                ]
                if (input) {
                    return input.onChange(newValue)
                }
                return form.setFieldValue(field.name, newValue)
            }}
        />
        {text}
    </Flex>
)

export const CheckboxInputField = memo(({
    modifiers,
    text,
    input,
}: CheckboxInputFieldPropsModel) =>
    <Flex modifiers={modifiers}>
        <Checkbox
            checked={input.checked !== undefined ? input.checked : input.value}
            {...input}
        />
        {text}
    </Flex>
)

const CheckboxGroup = memo(({
    removeClassFormGroup,
    modifiers,
    className,
    options,
    label,
    input,
    meta: {
        touched,
        error,
        warning,
    },
}: CheckboxGroupModel) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={touched && (error || warning) ? 'error' : null}
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        <CheckboxGrid>
            { options.map(option =>
                <MultipleCheckboxDataField
                    modifiers={modifiers}
                    option={option}
                    input={input}
                />
            )}
        </CheckboxGrid>
    </FormGroup>
)

export default CheckboxGroup
