import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { primaryColor } from '../../utils/variables'

export interface TabModel<T> {
    tabId: T
    disabled?: boolean
    tabName: string
    modifiers?: string | string[]
    responsiveModifiers?: object
    size?: string | string[]
}

const MODIFIER_TAB_CONFIG = {
    active: () => `
        border-bottom: 3px solid ${primaryColor};
        padding: 20px 0 17px;
    `,
    error: () => `
        border-bottom-color: #e63637 !important;
        color: #e63637;
    `,
}

export default styled.div<TabModel<any>>`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    padding: 20px 0;
    ${applyStyleModifiers(MODIFIER_TAB_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_TAB_CONFIG)}
`
