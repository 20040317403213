import moment from 'moment-timezone'
import { keys } from 'lodash'

/**
 * Replace filter string to array
 * @param {string} filterProps - Sting of filter cases.
 * @return {array} Array of property
 */

export const splitFilterString = filterProps => Array.isArray(filterProps)
    ? filterProps.filter(val => val !== '')
    : (filterProps || '').split(',').filter(val => val !== '')

/**
 *
 * Replace empty props from body request
 * @param {object} props - Object with parameters
 * @return {object} Object without empty property
 */
export const removeEmptyProperties = props => {
    const criteria = {
        ...props,
    }

    delete criteria.visibleColumns
    delete criteria.direction
    delete criteria.column
    delete criteria.sidebarName

    keys(criteria)
        .forEach(key => {
            switch (criteria[key]) {
                case '':
                case null:
                case undefined:
                case {}:
                    delete criteria[key]
                    break
                default:
                    if (Array.isArray(criteria[key]) && !criteria[key].length)
                        delete criteria[key]
            }
        })

    return criteria
}

export const returnLocalTimezone = () => {
    return moment.tz.guess()
}

export const determineDayPart = () => {
    const hours = new Date().getHours()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const datePeriod = hours >= 13 && hours < 17
        ? 13
        : hours >= 17 && hours < 20
            ? 17
            : 0
    const time = moment().set({ hour: datePeriod, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss')
    return moment(time).tz(timezone).format()
}

export const determineDayPartName = () => {
    const hours = new Date().getHours()
    if (hours >= 13 && hours < 17) {
        return 'afternoon'
    } else if (hours >= 17 && hours < 20) {
        return  'evening'
    } else if (hours >= 8 && hours < 13) {
        return 'morning'
    } else {
        return 'night'
    }
}

export const firstLetterUpperCase = (text: string) => (text.charAt(0).toUpperCase() + text.slice(1))
    .replace(/_/g, ' ')

export const actionTypesCreator = (schemaName, typeName) => [ '', '_SUCCESS', '_FAIL' ]
    .map(status => schemaName + '/' + typeName + status) as [string, string, string]

export const monthDiff = (start, end) => {
    function getAbsoluteMonths(momentDate) {
        const months = Number(momentDate.format('MM'))
        const years = Number(momentDate.format('YYYY'))
        return months + (years * 12)
    }

    const startMonths = getAbsoluteMonths(start)
    const endMonths = getAbsoluteMonths(end)

    return endMonths - startMonths
}

export const downloadFile = (url) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = url
    document.body.append(link)
    link.click()
    link.remove()
}
