import React, { Fragment, PureComponent } from 'react'
import { Label } from 'react-bootstrap'
import moment from 'moment'
import { get } from 'lodash'
import { Card } from '../../blocks/Card'
import Placeholder from '../../elements/Placeholder'
import { BtnLink } from '../../elements/Links'
import { Flex } from '../../elements/Flex'
import { ContractBusinessUnit, PartnerContract } from '../../../reducers/types/entities/PartnerContract'
import { Plus } from 'react-feather'
import { Button } from '../../elements/Button'
import View from '../../theme/View'
import { Partner } from '../../../reducers/types/entities/Partner'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'
import { AllocationLimit } from '../../../reducers/types/entities/AllocationLimit'
import Tabs from '../../elements/Tabs'
import BusinessModelLabel from '../../elements/BusinessModelLabel'
import { FormattedAmount } from '../../elements/FormattedAmount'
import styled from 'styled-components'
import { DateCell, LinkCell, Table } from '../../elements/table'
import Column, { NestedColumn } from '../../elements/table/columns/Column'
import Header from '../../elements/table/Header'
import Rows from '../../elements/table/Rows'
import Cell, { NestedTableCell } from '../../elements/table/cells/Cell'
import { tableBorderColor } from '../../../utils/variables'

interface PathPropsPartnerContract {
    partner: Partner
    isLoadingUnits: boolean
    isLoadingContracts: boolean
    archivePartnerContract: any
    downloadPartnerContract: any
    downloadSignedPartnerContract: any
    partnerContracts: PartnerContract[]
    showModal
    businessUnits?: BusinessUnit[]
    allocationLimits?: AllocationLimit[]
}

const TABS = [
    { tabId: 'contracts', tabName: 'Contracts' },
    { tabId: 'segmentation', tabName: 'Segmentation' },
]

const BusinessUnitDiv = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 40px 10fr 150px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  > span {
    font-size: 15px;
  }
`

const BuInfoGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
`

const StatusLabel = styled(Label)`
    &:not(:first-child) {
        margin-left: 8px;
    }
`

const showStateLabels = contract => {
    const statusLabels = []

    if (contract.acceptedAt && contract.currentPartnerContractPeriod) {
        statusLabels.push({
            name: 'Active',
            className: 'success',
        })
    }

    if (contract.canceledAt) {
        statusLabels.push({
            name: 'Cancelled',
            className: 'danger',
        })
    }

    if (contract.acceptedAt && !contract.currentPartnerContractPeriod && !contract.canceledAt) {
        statusLabels.push({
            name: 'Accepted',
            className: 'success',
        })
    }

    if (!contract.acceptedAt) {
        statusLabels.push({
            name: 'Awaiting acceptance',
            className: 'warning',
        })
    }

    if (contract.allocationPauses && contract.allocationPauses.find(pause => moment().isAfter(pause.startsAt) && moment().isBefore(pause.endsAt))) {
        statusLabels.push({
            name: 'Paused',
            className: 'warning',
        })
    }

    if (contract.allocationBoosts && contract.allocationBoosts.find(boost => moment().isAfter(boost.startsAt) && moment().isBefore(boost.endsAt))) {
        statusLabels.push({
            name: 'Boosted',
            className: 'success',
        })
    }

    return <Flex>
        {statusLabels.map((label, i) => <StatusLabel key={i} bsStyle={label.className}>{label.name}</StatusLabel>)}
    </Flex>
}

const NestedTableCard = styled.div`
    position: relative;
    padding: 40px 60px 72px;
    border-bottom: 1px solid ${tableBorderColor};
    width: 100%;

    & > table {
        width: 100%;
    }
`

const TableRow = styled.tr`
    &:hover td {
        background-color: #fff !important;
    }
`

const NestedBusinessUnitsTable = ({
    businessUnits,
}) => <NestedTableCard>
    <table>
        <thead>
            <NestedColumn name="bu_id">Business unit id</NestedColumn>
            <NestedColumn name="industry">Industry</NestedColumn>
            <NestedColumn name="quota">LB quota</NestedColumn>
            <NestedColumn name="limit">LB price</NestedColumn>
            <NestedColumn name="limit">PPS limit</NestedColumn>
            <NestedColumn name="limit">PPS price</NestedColumn>
        </thead>
        <tbody>
            {businessUnits && Boolean(businessUnits.length) && businessUnits.map((bu: ContractBusinessUnit) => <tr key={'contract_bu_' + bu.id}>
                <NestedTableCell>
                    #
                    {bu.id}
                </NestedTableCell>
                <NestedTableCell>{bu.displayName}</NestedTableCell>
                <NestedTableCell>{get(bu, 'allocationQuota.count', '-')}</NestedTableCell>
                <NestedTableCell>
                    {bu.allocationQuota ? <FormattedAmount amount={bu.allocationQuota.priceExclVat} format="0.00"/> : '-'}
                </NestedTableCell>
                <NestedTableCell>{get(bu, 'allocationLimit.count', '-')}</NestedTableCell>
                <NestedTableCell>
                    {bu.allocationLimit ? <FormattedAmount amount={bu.allocationLimit.priceExclVat} format="0.00"/> : '-'}
                </NestedTableCell>
            </tr>)}
            {(!businessUnits || !Boolean(businessUnits.length)) && <tr>
                <NestedTableCell colSpan={100}>
                    <i>This contract has no business units.</i>
                </NestedTableCell>
            </tr>}
        </tbody>
    </table>
</NestedTableCard>

interface ContractRowModel {
    rowData?: PartnerContract
    partner: Partner
    archivePartnerContract: any
    downloadPartnerContract: any
    downloadSignedPartnerContract: any
}

const DATE_OF_CONTRACT_DEPRECATION = '2024-03-20'

class ContractRow extends PureComponent<ContractRowModel> {
    state = {
        dropdownToggled: false,
    }

    get contractCanBeArchived() {
        const contract = this.props.rowData
        return contract && (!contract.acceptedAt || (contract.canceledAt && !contract.currentPartnerContractPeriod))
    }

    toggleDropdown = () => this.setState({ dropdownToggled: !this.state.dropdownToggled })

    hideContractDownloadAction = date => {
        return moment(date).isAfter(DATE_OF_CONTRACT_DEPRECATION)
    }

    render() {
        const { partner, downloadPartnerContract, downloadSignedPartnerContract } = this.props
        const contract = this.props.rowData

        if (!contract) {
            return null
        }

        return <Fragment>
            <tr onClick={this.toggleDropdown} style={{ cursor: 'pointer' }}>
                <LinkCell to={`/partners/${partner.id}/contracts/${contract.id}`} onClick={e => e.stopPropagation()}>
                    #
                    {contract.id}
                </LinkCell>
                {contract.acceptedAt ? <DateCell date={contract.acceptedAt} format="shortdate" withTitle={true} /> : <Cell />}
                <DateCell date={contract.startsAt} format="shortdate" withTitle={true}/>
                {contract.currentPartnerContractPeriod && !contract.churnsAt ? <DateCell date={contract.currentPartnerContractPeriod.endsAt} format="shortdate" withTitle={true}/> : <Cell />}
                {contract.churnsAt ? <DateCell date={contract.churnsAt} format="shortdate" withTitle={true} />  : <Cell />}
                <Cell>
                    {contract.bindingPeriodMonths}
                    {' '}
                    {contract.bindingPeriodMonths === 1 ? 'month' : 'months'}
                </Cell>
                <Cell><FormattedAmount amount={contract.bindingPeriodAmountExclVat} format="00.00" /></Cell>
                <Cell>{showStateLabels(contract)}</Cell>
                <Cell>
                    <Flex>
                        <BtnLink
                            to={`/partners/${partner.id}/contracts/${contract.id}`}
                            onClick={e => e.stopPropagation()}
                            modifiers={[ 'secondary', 'cellBtn' ]}
                        >
                            View
                        </BtnLink>
                        {this.contractCanBeArchived && <Button
                            modifiers={[ 'danger', 'cellBtn', 'mL_1' ]}
                            onClick={e => {
                                e.stopPropagation()
                                this.props.archivePartnerContract(partner.id, contract.id, { action: 'archive' })
                            }}
                        >
                            Archive
                        </Button>}
                        {!this.hideContractDownloadAction(contract.createdAt) && !contract.signedAt && <Button
                            modifiers={[ 'secondary', 'cellBtn', 'mL_1' ]}
                            onClick={e => {
                                e.stopPropagation()
                                downloadPartnerContract(partner.id, contract.id)
                            }}
                        >
                            Download contract
                        </Button>}
                        {!this.hideContractDownloadAction(contract.createdAt) && contract.signedAt && <Button
                            modifiers={[ 'secondary', 'cellBtn', 'mL_1' ]}
                            onClick={e => {
                                e.stopPropagation()
                                downloadSignedPartnerContract(partner.id, contract.id)
                            }}
                        >
                            Download signed contract
                        </Button>}
                    </Flex>
                </Cell>
            </tr>
            {this.state.dropdownToggled && <TableRow>
                <td colSpan={100}>
                    <NestedBusinessUnitsTable
                        businessUnits={contract.businessUnits}
                    />
                </td>
            </TableRow>}
        </Fragment>
    }
}

interface PartnerContractListCardModelState {
    selectedTab: string,
}

class PartnerContractListCard extends PureComponent<PathPropsPartnerContract, PartnerContractListCardModelState> {
    state = {
        selectedTab: 'contracts',
    }

    changeTab = tabId => {
        this.setState({ selectedTab: tabId })
    }

    render() {
        const {
            partnerContracts,
            downloadPartnerContract,
            downloadSignedPartnerContract,
            partner,
            isLoadingUnits,
            isLoadingContracts,
            showModal,
            businessUnits,
            allocationLimits,
        } = this.props

        const { selectedTab } = this.state

        return <Card className="contractCard">
            <Card.Header>
                <Flex
                    modifiers={[
                        'fullWidth',
                        'justifySpaceBetween',
                        'alignCenter',
                    ]}
                >
                    <Tabs
                        tabs={TABS}
                        selectedTab={selectedTab}
                        changeTab={this.changeTab}
                    />
                    <View flexDirection="row">
                        {partner.state === 'demo' &&
                        <Button
                            modifiers="secondary"
                            onClick={() => showModal({
                                name: 'contractOfferGroupCreationModal',
                                partnerId: partner.id,
                            })}
                        >
                            See/Create offers
                        </Button>
                        }
                        <BtnLink
                            modifiers={[ 'action', 'mL_1.5' ]}
                            to={`/partners/${partner.id}/contracts`}
                        >
                            <Plus/>
                        </BtnLink>
                    </View>
                </Flex>
            </Card.Header>
            <Card.Content modifiers={[ 'maxHeight', 'scroll', 'p_0', 'm_2' ]} scrollHeight={'calc(100% - 72px)'}>
                {selectedTab === 'contracts' && <Fragment>
                    {isLoadingContracts && <Placeholder counter={7} portrait={false}/>}
                    {!isLoadingContracts && (!partnerContracts || partnerContracts.length === 0) && <div
                        style={{
                            margin: '34px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ fontSize: '14px', marginBottom: '16px' }}>This partner has no contracts created yet. Check out segmentation to convert subscription based partner to contract.</span>
                        <Flex modifiers="justifyCenter">
                            <BtnLink modifiers="action" to={`/partners/${partner.id}/contracts`}>Create contract</BtnLink>
                            <Button modifiers={[ 'secondary', 'mL_2' ]} onClick={() => this.changeTab('segmentation')}>See segmentation</Button>
                        </Flex>
                    </div>}
                    {!isLoadingContracts && partnerContracts.length > 0 && <Table>
                        <Header>
                            <Column name="contract_id">Contract ID</Column>
                            <Column name="accepted_on">Accepted on</Column>
                            <Column name="starts_at">Starts at</Column>
                            <Column name="renews_at">Renews at</Column>
                            <Column name="churns_at">Churns at</Column>
                            <Column name="binding_period">Binding period</Column>
                            <Column name="value">Value</Column>
                            <Column name="contract_status">Status</Column>
                            <Column name="contract_actions">Actions</Column>
                        </Header>
                        <Rows
                            items={partnerContracts}
                        >
                            <ContractRow
                                archivePartnerContract={this.props.archivePartnerContract}
                                downloadPartnerContract={downloadPartnerContract}
                                downloadSignedPartnerContract={downloadSignedPartnerContract}
                                partner={partner}
                            />
                        </Rows>
                    </Table>}
                </Fragment>}
                {selectedTab === 'segmentation' && <Card.Content>
                    {isLoadingUnits && <Placeholder counter={7} portrait={false}/>}
                    {!isLoadingUnits && (!businessUnits || businessUnits.length === 0) && <div
                        style={{
                            margin: '34px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ fontSize: '14px', marginBottom: '16px' }}>This partner has no existing business units. Create a contract to add new.</span>
                    </div>}
                    {!isLoadingUnits && businessUnits && businessUnits.length > 0 && businessUnits.map(unit => {
                        const buAllocationLimit = allocationLimits
                            .filter(al => al.partnerBusinessUnitId === unit.id)
                            .sort((a, b) => moment(a.effectiveAt).isAfter(b.effectiveAt) ? -1 : 1)[0]

                        const isLBModel = Boolean(unit.businessModels.find(el => el.abbreviation === 'lb'))
                        const isPPSModel = Boolean(unit.businessModels.find(el => el.abbreviation === 'pps'))

                        return <BusinessUnitDiv key={unit.id + 'businessUnit'} >
                            <span>
                                #
                                {unit.id}
                            </span>
                            <div>
                                {unit.currentAllocation && <BuInfoGrid>
                                    <Card.Text>
                                        <Card.Label>Name</Card.Label>
                                        <Card.Text>{unit.displayName}</Card.Text>
                                    </Card.Text>
                                    {unit.partner.hasMarketingPackage && <Fragment>
                                        <div>
                                            <Card.Label>Starts at</Card.Label>
                                            <Card.Text>-</Card.Text>
                                        </div>
                                        <div>
                                            <Card.Label>Models</Card.Label>
                                            <Card.Text>
                                                {unit.businessModels.map(el =>
                                                    <BusinessModelLabel
                                                        key={'businessModels_' + el.id}
                                                        abbreviation={el.abbreviation}
                                                    >
                                                        {el.abbreviation}
                                                    </BusinessModelLabel>
                                                )}
                                            </Card.Text>
                                        </div>
                                        <div>
                                            <Card.Label>LB quota</Card.Label>
                                            <Card.Text>{isLBModel ? unit.currentAllocation.allocationQuotaPerRefill + ' per month' : '-'}</Card.Text>
                                        </div>
                                        <div>
                                            <Card.Label>PPS limit</Card.Label>
                                            <Card.Text>
                                                {(isPPSModel && buAllocationLimit) ? buAllocationLimit.limit : '-'}
                                            </Card.Text>
                                        </div>
                                        <div>
                                            <Card.Label>PPS Fee</Card.Label>
                                            <Card.Text>
                                                {(isPPSModel && buAllocationLimit)
                                                    ? <FormattedAmount amount={buAllocationLimit.price} />
                                                    : '-'
                                                }
                                            </Card.Text>
                                        </div>
                                    </Fragment>}
                                </BuInfoGrid>}
                            </div>
                            {!unit.partnerContract && <div>
                                <Button
                                    modifiers={[ 'primary', 'btnBlock', 'fullWidth' ]}
                                    onClick={() => showModal({
                                        name: 'businessUnitModal',
                                        partnerId: partner.id,
                                        unit,
                                    })}
                                >
                                    Edit basic info
                                </Button>
                                <BtnLink
                                    modifiers={[ 'secondary', 'btnBlock', 'fullWidth', 'mT_1' ]}
                                    to={`/partners/${partner.id}/segmentation#${unit.id}`}
                                >
                                    Edit segmentation
                                </BtnLink>
                            </div>}
                        </BusinessUnitDiv>
                    })
                    }
                </Card.Content>}
            </Card.Content>
        </Card>
    }
}
export default PartnerContractListCard
