import * as lead from '../../actions/leads'
import {
    GET_LEAD_VALIDATION_DATA_FIELDS,
    GET_LEAD_VALIDATION_DATA_FIELDS_FAIL,
    GET_LEAD_VALIDATION_DATA_FIELDS_SUCCESS,
} from '../../actions/datafields'
import {
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_SUCCESS,
} from '../../actions/products'
import {
    CREATE_LEAD_ACTION,
    CREATE_LEAD_ACTION_FAIL,
    CREATE_LEAD_ACTION_SUCCESS
} from '../../actions/actions'
import {
    GET_QUOTES,
    GET_QUOTES_FAIL,
    GET_QUOTES_SUCCESS,
    REJECT_QUOTES,
    REJECT_QUOTES_FAIL,
    REJECT_QUOTES_SUCCESS,
} from '../../actions/quotes'
import { InitialAction } from '../types/InitialAction'

export interface LeadValidationModel {
    productsId: number[]
    dataFieldsId: number[]
    excludedPartnersId: number[]
    isLoading: boolean
    isSavingLead: boolean
    isValidating: boolean
    isLoadingProducts: boolean
    isLoadingLead: boolean,
    isLoadingDataFields: boolean
    isLoadingExcludePartners: boolean
}

const initialState: LeadValidationModel = {
    productsId: [],
    dataFieldsId: [],
    excludedPartnersId: [],
    isLoading: false,
    isSavingLead: false,
    isLoadingLead: true,
    isValidating: false,
    isLoadingProducts: false,
    isLoadingDataFields: false,
    isLoadingExcludePartners: false,
}

export default function reducer(
    state = initialState,
    action: InitialAction,
) {
    switch (action.type) {
        case lead.POSTPONE_CLIENT_MATCH_DECISION:
        case lead.POSTPONE_VALIDATION:
        case lead.CLOSE_LEAD_COMPLETED:
        case lead.LEAD_ACTION_DISCARD:
        case lead.ACCEPT_LEAD_QUOTE:
        case lead.UNREJECT_QUOTES:
        case CREATE_LEAD_ACTION:
        case lead.SAVE_LEAD:
        case REJECT_QUOTES:
        case GET_QUOTES:
            return {
                ...state,
                isLoading: true,
            }
        case lead.POSTPONE_CLIENT_MATCH_DECISION_SUCCESS:
        case lead.POSTPONE_VALIDATION_SUCCESS:
        case lead.CLOSE_LEAD_COMPLETED_SUCCESS:
        case lead.LEAD_ACTION_DISCARD_SUCCESS:
        case lead.ACCEPT_LEAD_QUOTE_SUCCESS:
        case lead.UNREJECT_QUOTES_SUCCESS:
        case CREATE_LEAD_ACTION_SUCCESS:
        case lead.SAVE_LEAD_SUCCESS:
        case REJECT_QUOTES_SUCCESS:
        case GET_QUOTES_SUCCESS:
        case lead.POSTPONE_CLIENT_MATCH_DECISION_FAIL:
        case lead.POSTPONE_VALIDATION_FAIL:
        case lead.CLOSE_LEAD_COMPLETED_FAIL:
        case lead.LEAD_ACTION_DISCARD_FAIL:
        case lead.ACCEPT_LEAD_QUOTE_FAIL:
        case lead.UNREJECT_QUOTES_FAIL:
        case CREATE_LEAD_ACTION_FAIL:
        case lead.SAVE_LEAD_FAIL:
        case REJECT_QUOTES_FAIL:
        case GET_QUOTES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case lead.GET_LEAD:
            return {
                ...state,
                isLoadingLead: true,
            }
        case lead.GET_LEAD_SUCCESS:
        case lead.GET_LEAD_FAIL:
            return {
                ...state,
                isLoadingLead: false,
            }
        case lead.LEAD_ACTION_VALIDATE:
            return {
                ...state,
                isValidating: true,
            }
        case lead.LEAD_ACTION_VALIDATE_SUCCESS:
        case lead.LEAD_ACTION_VALIDATE_FAIL:
            return {
                ...state,
                isValidating: false,
            }
        case GET_LEAD_VALIDATION_DATA_FIELDS:
            return {
                ...state,
                isLoadingDataFields: true,
            }
        case GET_LEAD_VALIDATION_DATA_FIELDS_SUCCESS:
            return {
                ...state,
                dataFieldsId: action.response.result,
                isLoadingDataFields: false,
            }
        case GET_LEAD_VALIDATION_DATA_FIELDS_FAIL:
            return {
                ...state,
                isLoadingDataFields: false,
            }
        case lead.GET_EXCLUDED_PARTNERS:
            return {
                ...state,
                isLoadingExcludePartners: false,
            }
        case lead.GET_EXCLUDED_PARTNERS_SUCCESS:
            return {
                ...state,
                excludedPartnersId: action.response.result,
                isLoadingExcludePartners: false,
            }
        case lead.GET_EXCLUDED_PARTNERS_FAIL:
            return {
                ...state,
                isLoadingExcludePartners: false,
            }
        case GET_PRODUCTS:
            return {
                ...state,
                isLoadingProducts: true,
            }
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                productsId: action.response.result,
                isLoadingProducts: false,
            }
        case GET_PRODUCTS_FAIL:
            return {
                ...state,
                isLoadingProducts: false,
            }
        default:
            return state
    }
}
