import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import {
    actionColor,
    dangerColor,
    darkGrey,
    primaryColor,
    secondaryLightColor,
    warningColor,
    secondaryDarkColor,
    primaryLightColor,
    primaryDarkColor,
    actionLightColor,
    actionDarkColor,
    warningLightColor,
    dangerLightColor,
    dangerDarkColor,
    warningDarkColor, borderColor,
} from '../../utils/variables'
import { Flex } from './Flex'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from './modifiers/spaceModifiers'
import { Popover } from 'react-bootstrap'

export const MODIFIER_BUTTON_CONFIG = {
    secondary: () => `
        background-color: ${darkGrey};
        &:hover {
            background-color: ${secondaryLightColor};
        }
        &:active, &:focus {
            background-color: ${secondaryDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${secondaryLightColor};
        }
    `,
    primary: () => `
        background-color: ${primaryColor};
        &:hover {
            background-color: ${primaryLightColor};
        }
        &:active, &:focus {
            background-color: ${primaryDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${primaryLightColor};
        }
        .icon {
            color: ${primaryColor};
            background-color: #fff;
        }
    `,
    action: () => `
        background-color: ${actionColor};

        &:hover {
            background-color: ${actionLightColor};
        }
        &:active, &:focus {
            background-color: ${actionDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${actionLightColor};
        }
        .icon {
            color: ${actionColor};
            background-color: #fff;
        }
    `,
    danger: () => `
        background-color: ${dangerColor};

        &:hover {
            background-color: ${dangerLightColor};
        }
        &:active, &:focus {
            background-color: ${dangerDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${dangerLightColor};
        }
        .icon {
            color: ${dangerColor};
            background-color: #fff;
        }
    `,
    warning: () => `
        background-color: ${warningColor};

        &:hover {
            background-color: ${warningLightColor};
        }
        &:active, &:focus {
            background-color: ${warningDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${warningLightColor};
        }
        .icon {
            color: ${warningColor};
            background-color: #fff;
        }
    `,
    btnIcon: ({ btnIconSize }) => `
        line-height: ${btnIconSize || 35}px;
        height: ${btnIconSize || 35}px;
        width: ${btnIconSize || 35}px;
        display: inline-block;
        padding: 0;
    `,
    nowrap: () => `
        white-space: nowrap;
    `,
    btnBlock: () => `
        display: block;
    `,
    btnXl: () => `
        height: 80px;
        line-height: 80px;
        font-size: 24px;
    `,
    btnLg: () => `
        padding: 15px;
    `,
    btnSm: () => `
        padding: 9px 17px 8px 17px;
        line-height: 12px;
        font-size: 12px;
    `,
    fullWidth: () => `
        width: 100%;
    `,
    mdWidth: () => `
        width: 130px;
    `,
    pullRight: () => `
        float: right;
    `,
    cellBtn: () => `
        font-weight: initial !important;
        font-size: 10px;
        line-height: 1;
        padding: 7px 10px;
    `,
}

export type ModifierButtonConfigModel = keyof typeof MODIFIER_BUTTON_CONFIG
    | keyof typeof MODIFIER_MARGIN_CONFIG
    | keyof typeof MODIFIER_PADDING_CONFIG

export type ButtonModifiersModel = ModifierButtonConfigModel | ModifierButtonConfigModel[]

interface ButtonPropsModel {
    modifiers: ButtonModifiersModel
    btnIconSize?: number
}
export const Button = styled.button.attrs(props => ({ type: props.type || 'button' }))<Partial<ButtonPropsModel>>`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 200ms ease;
    border-radius: 4px;
    padding: 7px 14px;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 15px;
    &:hover, &:active, &:focus {
        color: #fff;
    }
    &:disabled {
      cursor: not-allowed;
    }
    ${applyStyleModifiers(MODIFIER_BUTTON_CONFIG)}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_BUTTON_CONFIG)}
`

export const ButtonGroup = styled(Flex)`
   .mdi {
       padding: 0 5px;
       font-size: 23px;
   }
   > ${Button} {
      border-radius: 0;
      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
      }
      &:not(:last-child) {
        border-right: 1px solid #fff;
      }
   }
`

export const ActionButtonBlock = styled.div`
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffffe6;
  border-top: 1px solid #C7D7DD;
  box-shadow: 0 0 20px #c8cfd3;
  z-index: 1;
  ${Flex} > div:not(:first-child) {
    margin-left: 20px;
    padding-left: 20px;
  }
`

export const ButtonContainer = styled.div<{divider?: boolean}>`
  min-width: 120px;
  text-align: center;
  ${props => props.divider && `
      padding-left: 20px;
      border-left: 1px solid ${borderColor};
  `};
`

export const ButtonPopover = styled(Popover)`
  min-width: 150px;
  position: fixed;
  bottom: 85px;
  top: initial!important;
`

export const ButtonGrid = styled.div`
  margin: 20px;
  display: grid;
  grid: repeat(1, auto) / auto-flow;
  grid-gap: 0 20px;
`
