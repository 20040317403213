import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { tableBorderColor } from '../../utils/variables'
import { MODIFIER_MARGIN_CONFIG } from './modifiers/spaceModifiers'

type Modifiers = keyof typeof MODIFIER_MARGIN_CONFIG

interface SeparatorModel {
    margin?: string,
    modifiers?:  Modifiers | Modifiers[]
}

export const Separator = styled.div<SeparatorModel>`
    width: 100%;
    margin: ${props => props.margin || '30px auto'};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`

export const SeparatorLine = styled(Separator)`
    border-top: 1px solid ${tableBorderColor};
`
