import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import ScrollableContent from './_ScrollableContent'
import Content from './_Content'
import Header from './_Header'
import Label from './_Label'
import Text from './_Text'
import Line from './_Line'
import Meta from './_Meta'

import { MODIFIER_MARGIN_CONFIG } from '../../elements/modifiers/spaceModifiers'

const MODIFIERS_CARD_CONFIG = {
    info: () => `
      background-color: #d9edf7;
      color: #31708f;
      font-size: 14px;
      border-color: #bce8f1;
      ${Card.Content} {
        line-height: 25px;
      }
    `,
    error: () => `
      background-color: #fde9e9;
      color: #931b0f;
      font-weight: bold;
      font-size: 14px;
      border-color: #ebccd1;
      ${Card.Content} {
        line-height: 25px;
      }
    `,
    warning: () => `
      background-color: #fcf8e3;
      color: #8a6d3b;
      font-weight: bold;
      font-size: 14px;
      border-color: #faebcc;
      ${Card.Content} {
        line-height: 25px;
      }
    `,
}

interface CardPropsPropsModel {
    borderRadius?: string
    margin?: string
}

export const Card:any = styled.div<CardPropsPropsModel>`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    border-radius: ${props => props.borderRadius || '4px'};
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin: ${props => props.margin || '0 0 20px 0'};
    ${applyStyleModifiers(MODIFIERS_CARD_CONFIG)}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`

Card.ScrollableContent = ScrollableContent
Card.Content = Content
Card.Header = Header
Card.Label = Label
Card.Text = Text
Card.Line = Line
Card.Meta = Meta
