import React, { ComponentType, PureComponent } from 'react'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { change, Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { getIndustries } from '../../state/industries/actions'
import config from '../../config'
import { Industry } from '../../reducers/types/entities/Industry'
import { allIndustriesSelector } from '../../state/industries/selectors'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import { AsyncTypeaheadGroup } from '../elements/forms/inputs/AsyncTypeaheadGroup'
import { getLocations } from '../../actions/locations'
import { Location } from '../../reducers/types/entities/Location'
import { locationSelector } from '../../selectors/locations'
import { toastr } from 'react-redux-toastr'
const required = [ value => value ? undefined : 'Required!' ]

const renderZipCodeMenuItem = option => `${option.zipCode}, ${option.cityName}`

interface CreateDemoPartnerOtherModel {
    initZipCode
}

interface CreateDemoPartnerModel {
    industries: Industry[]
    isLoadingLocations: boolean
    locations: Location[]
    getLocations: (any) => any
    getIndustries: typeof getIndustries
    hideModal: typeof hideModal
    submit: typeof submit
    change: typeof change
}

@connect(
    state => ({
        industries: allIndustriesSelector(state),
        isLoadingLocations: state.pages.locationFilter.isLoading,
        locations: locationSelector(state),
    }),
    {
        getLocations,
        getIndustries,
        hideModal,
        submit,
        change,
    }
)
class CreateDemoPartnerModal extends PureComponent<CreateDemoPartnerModel, CreateDemoPartnerOtherModel> {
    constructor(props) {
        super(props)

        this.state = {
            initZipCode: [ {
                zipCode: '',
                cityName: '',
            } ],
        }
    }

    componentDidMount(): void {
        this.props.getIndustries({ geoCode: config.geoCode })
    }

    createDemoPartner = demoPartner => {
        const demoPartnerModel = {
            industry_id: demoPartner.industry,
            company_name: demoPartner.companyName,
            geo_zip_code: demoPartner.geo.zipCode,
            geo_city_name: demoPartner.geo.cityName,
            geo_lat: demoPartner.geo.point.lat,
            geo_lon: demoPartner.geo.point.lon,
        }

        // @ts-ignore
        $.post('/partners/create-new-partner', demoPartnerModel, (callback) => {
            if (callback.errors) {
                // @ts-ignore
                $.each(callback.errors, (index, value) => {
                    toastr.error(value)
                })

                // @ts-ignore
                $.each(callback.fields, (index, value) => {
                    // @ts-ignore
                    $('[name="' + value + '"]').addClass('brand-danger')
                })
            } else {
                const partnerId = parseInt(callback, 10)
                // @ts-ignore
                $.post(`/partners/canvas/${partnerId}`, { action: 'create_demo' }, (data) => {
                    if (data.errors) {
                        // @ts-ignore
                        $.each(data.errors, function(index, value) {
                            toastr.error(value)
                        })
                    } else {
                        this.hideModal()
                        window.location.replace(`/partners/${partnerId}`)
                    }
                })
            }
        })
    }

    hideModal = () => this.props.hideModal()

    getLocations = zipCode => {
        this.props.getLocations({ zipCode })
    }

    render() {
        const {
            isLoadingLocations,
            locations,
            industries,
        } = this.props

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Create new demo partner</Modal.Header>
            <ReduxForm form="createDemoPartner" onSubmit={this.createDemoPartner}>
                <Modal.Body>
                    <Field
                        label="Industry"
                        validate={required}
                        placeholder="Choose an industry..."
                        name="industry"
                        component={SelectInputGroup}
                        returnId={true}
                    >
                        {Object.values(industries).map(el =>
                            <option key={el.id} value={el.id}>{el.name}</option>
                        )}
                    </Field>
                    <Field
                        component={FieldGroup}
                        validate={required}
                        name="companyName"
                        label="Company name"
                        placeholder="What's the name of the company?"
                    />
                    <Field
                        component={AsyncTypeaheadGroup}
                        name="geo"
                        label="Zip code"
                        labelKey="zipCode"
                        extraData={{ address: '' }}
                        isLoading={isLoadingLocations}
                        options={locations}
                        renderMenuItemChildren={renderZipCodeMenuItem}
                        onSearch={this.getLocations}
                        defaultSelected={this.state.initZipCode}
                    />
                    <Field
                        name="geo.cityName"
                        label="City"
                        component={FieldGroup}
                        disabled={true}
                        removeClassFormGroup
                    />
                </Modal.Body>
                <Modal.Footer className="text-right">
                    <Button
                        modifiers={[ 'secondary' ]}
                        type="submit"
                    >
                        Create demo partner
                    </Button>
                </Modal.Footer>
            </ReduxForm>
        </Modal>
    }
}

export default CreateDemoPartnerModal as unknown as ComponentType
