import {
    GET_TERMS, GET_TERMS_SUCCESS, GET_TERMS_FAIL,
} from '../../actions/terms'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface TermsModel extends InitialState {
    termsIds: number[];
}
const initialState: TermsModel = {
    isLoading: null,
    termsIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_TERMS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_TERMS_SUCCESS:
            return {
                ...state,
                termsIds: action.response.result,
                isLoading: false,
            }
        case GET_TERMS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
