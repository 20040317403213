import styled from 'styled-components'
import Bevel from './_Bevel'
import Knob from './_Knob'
import { successColor } from '../../../utils/variables'

export default styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked + ${Bevel} {
        border: 1px solid ${successColor};
        background: ${successColor}
    }

    &:checked + ${Bevel} ${Knob} {
        left: 23px;
    }

    &:disabled + ${Bevel} {
        opacity: .5;
        cursor: not-allowed;
    }
`
