import React, { memo } from 'react'
import { Label } from 'react-bootstrap'
import styled from 'styled-components'

const StatusLabel = styled(Label)`
  text-transform: capitalize;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 12px;
`

const leadStatusColours = (status: string) => {
    switch (status) {
        case 'validated':
            return 'info'
        case 'quote_accepted':
        case 'quotes_full':
        case 'completed':
            return 'success'
        case 'send_to_advisor':
            return 'warning'
        case 'untreated':
        case 'unknown':
            return 'default'
        default:
            // rest statuses: all_quotes_rejected, test, graveyard, spam, capacity, deleted, unused, not_serious, duplicate
            return 'danger'
    }
}

export const LeadStatusColor = memo(({ leadStatus }: { leadStatus: string}) =>
    <StatusLabel bsStyle={leadStatusColours(leadStatus)}>{leadStatus.replace(/_/g, ' ')}</StatusLabel>
)
