import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const LOGIN = 'auth/LOGIN'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'auth/LOGIN_FAIL'
export const GET_TOKEN_INFO = 'auth/GET_TOKEN_INFO'
export const GET_TOKEN_INFO_SUCCESS = 'auth/GET_TOKEN_INFO_SUCCESS'
export const GET_TOKEN_INFO_FAIL = 'auth/GET_TOKEN_INFO_FAIL'
export const GET_AUTH_PERMISSIONS = 'auth/GET_AUTH_PERMISSIONS'
export const GET_AUTH_PERMISSIONS_SUCCESS = 'auth/GET_AUTH_PERMISSIONS_SUCCESS'
export const GET_AUTH_PERMISSIONS_FAIL = 'auth/GET_AUTH_PERMISSIONS_FAIL'
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'auth/CHANGE_PASSWORD_FAIL'

export const login = (email: string, password: string) => ({
    [CALL_API]: {
        types: [ LOGIN, LOGIN_SUCCESS, LOGIN_FAIL ],
        endpoint: '/auth',
        options: {
            method: 'post',
            body: {
                email,
                password,
                userType: 'employee',
            },
        },
    },
})

export const getTokenInfo = () => ({
    [CALL_API]: {
        types: [ GET_TOKEN_INFO, GET_TOKEN_INFO_SUCCESS, GET_TOKEN_INFO_FAIL ],
        endpoint: '/auth/token',
        options: {
            headers: {
                authorization: `Bearer ${window.localStorage.getItem('auth.refresh_token')}`,
            },
        },
    },
})

export const getAuthPermissions = () => ({
    [CALL_API]: {
        types: [ GET_AUTH_PERMISSIONS, GET_AUTH_PERMISSIONS_SUCCESS, GET_AUTH_PERMISSIONS_FAIL ],
        endpoint: '/auth/permissions',
        schema: schemas.permissions,
        options: {
            body: {
                limit: 10000,
            },
        },
    },
})

export const loadAuth = () => async dispatch => {
    const response = await dispatch(getTokenInfo())
    await dispatch(getAuthPermissions())
    return response
}

export const changePassword = (
    email: string,
    password: string,
    consumerType: string
) => ({
    [CALL_API]: {
        types: [
            CHANGE_PASSWORD,
            CHANGE_PASSWORD_SUCCESS,
            CHANGE_PASSWORD_FAIL,
        ],
        endpoint: '/auth/password',
        options: {
            method: 'post',
            body: {
                email: email,
                password: password,
                consumerType: consumerType
            },
        },
    },
})
