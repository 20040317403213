import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { hideModal, showModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import BusinessUnitsTable from '../leads/send-email-to-partners-regions-components/BusinessUnitsTable'
import { Lead } from '../../reducers/types/entities/Lead'
import ProviderTable from '../blocks/ProviderTable'
import SegmentationRegionsMap from '../leads/send-email-to-partners-regions-components/SegmentationRegionsMap'
import styled from 'styled-components'
import { getPartnerQuotes } from '../../actions/quotes'
import { get } from 'lodash'
import { Quote } from '../../reducers/types/entities/Quote'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import LoaderComponent from '../elements/LoaderComponent'
import { Flex } from '../elements/Flex'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 90vw;
        max-width: 1800px;
    }
`

interface Criteria {
    sort: string
}

interface Props {
    lead: Lead
    businessUnits: BusinessUnit[]
    criteria: Criteria
}

interface DispatchProps {
    hideModal: typeof hideModal
    showModal: typeof showModal
    getPartnerQuotes: typeof getPartnerQuotes
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
}

interface PathProps {
    location: {
        query: {
            sort?: string
        }
    }
}

type ShowBusinessUnitsForLeadModel = Props & DispatchProps & PathProps

@connect<Props, DispatchProps, PathProps>(
    (state, props) => ({
        lead: state.modal.lead,
        businessUnits: state.modal.businessUnits,
        criteria: {
            sort: get(props, 'location.query.sort') || 'distance',
        },
    }),
    {
        hideModal,
        showModal,
        getPartnerQuotes,
        getPartnerBusinessUnits,
    }
)
class ShowBusinessUnitsForLeadModal extends PureComponent<ShowBusinessUnitsForLeadModel> {
    state = {
        existingQuotesPartnerIds: [],
        selectedSubGeoRegions: [],
        loadingBusinessUnits: false,
        businessUnits: [],
    }

    componentDidMount() {
        this.getBusinessUnits()
    }

    getBusinessUnits = async () => {
        this.setState({
            loadingBusinessUnits: true,
        })
        const businessUnitResponse: any = await this.props.getPartnerBusinessUnits({
            isActive: '1',
            hasBusinessModel: '1',
            segmentationCriteria: [
                'sectors',
                'industries',
                'certifications',
                'languages',
                'client_types',
                'digital_work_preferences',
                'revenues',
                'fees',
                'exclusions',
                'primary_industries',
                'locations',
                'company_sizes',
            ].join(','),
            segmentedForLeadId: this.props.lead.id,
            limit: 5000,
        })

        this.setState({
            businessUnits: businessUnitResponse.result.length ? businessUnitResponse.result.map(id => businessUnitResponse.entities.businessUnits[id]) : [],
            loadingBusinessUnits: false,
        })
    }

    selectSubGeoRegions = selectedSubGeoRegions => {
        this.setState({ selectedSubGeoRegions })
    }

    async getQuotePartnerIdsToFilter() {
        const quotesResponse: any = await this.props.getPartnerQuotes({
            leadId: this.props.lead.id,
            limit: 100,
        }, false)

        const existingQuotesPartnerIds = Object.values(quotesResponse.entities.quotes).map((quote: Quote) => quote.partner.id)
        this.setState({
            existingQuotesPartnerIds,
        })
    }

    render() {
        const {
            lead,
        } = this.props

        const {
            businessUnits,
            loadingBusinessUnits,
            existingQuotesPartnerIds,
            selectedSubGeoRegions,
        } = this.state

        const filteredBusinessUnits = businessUnits
            .filter(bu => bu.geo.homeSubGeoRegion && selectedSubGeoRegions.includes(bu.geo.homeSubGeoRegion.id))
            .filter(bu => !existingQuotesPartnerIds.includes(bu.partnerId))

        return <LgModal
            show={true}
            onHide={this.props.hideModal}
        >
            <Modal.Header closeButton={true}>Partners</Modal.Header>
            <ProviderTable pageName="send-email" loading={false}>
                {loadingBusinessUnits && <Flex modifiers={[ 'justifyCenter' ]}><LoaderComponent /></Flex>}
                {!loadingBusinessUnits && <BusinessUnitsTable
                    sort={this.props.criteria.sort}
                    lead={lead}
                    businessUnits={filteredBusinessUnits}
                    disableCheckActions={true}
                    selectedBusinessUnits={[]}
                    selectedPartners={[]}
                    submitting={false}
                    selectBusinessUnit={() => new Promise(() => true)}
                    deselectBusinessUnit={() => new Promise(() => true)}
                    hideModal={this.props.hideModal}
                    showModal={this.props.showModal}
                    hasPermissionToOverdeliver={false}
                />}
                <SegmentationRegionsMap
                    lead={lead}
                    businessUnits={businessUnits}
                    selectSubGeoRegions={this.selectSubGeoRegions}
                    selectedSubGeoRegions={selectedSubGeoRegions}
                />
            </ProviderTable>
        </LgModal>
    }
}

export default ShowBusinessUnitsForLeadModal as unknown as ComponentType
