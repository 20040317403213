import React, { PureComponent, ComponentType, memo } from 'react'
import Placeholder from '../elements/Placeholder'
import NewsFeedItem from './NewsFeedItem'
import InfiniteScroll from 'react-infinite-scroller'
import { getNewsFeed } from '../../actions/newsfeed'
import { newsFeedActivitiesSelector } from '../../selectors/activities'
import { Card } from '../blocks/Card'
import { connect } from '../../utils/connect'
import { Activity } from '../../reducers/types/entities/Activities'
import PinnedNotes from '../blocks/PinnedNotesBlock'
import { pinActivity, unpinActivity } from '../../actions/activities'

interface NewsFeedProps {
    scrollHeight?: string
    selectedTab: string
    subject: {
        leadId: number
    } | {
        partnerId: number
    }
}

interface NewsFeedModel extends NewsFeedProps {
    loading: boolean
    items: Activity[]
    pagination: {
        pages: number
        page: number
        total: number
    }
    getNewsFeed: typeof getNewsFeed
    pinActivity: typeof pinActivity
    unpinActivity: typeof unpinActivity
}

const NewsFeedItemFunc = memo(({ item, pinItem }: any) =>
    [
        'partner_lead_quote_accepted',
        'partner_lead_quote_refused',
        'lead_sent_out_to_partners',
        'lead_marked_not_serious',
        'partners_ratings_create',
        'lead_reject_all_quotes',
        'lead_marked_completed',
        'partners_notes_create',
        'lead_marked_duplicate',
        'lead_marked_untreated',
        'partner_satisfaction',
        'lead_sent_to_advisor',
        'phonecalls_create',
        'lead_info_updated',
        'lead_note_created',
        'lead_sent_as_mail',
        'lead_marked_spam',
        'lead_marked_test',
        'invoices_create',
        'lead_postponed',
        'lead_no_answer',
        'lead_validated',
        'lead_given_up',
        'task_complete',
        'sent_as_mail',
        'task_create',
        'lead_call',
        'partner_contract_create',
        'partner_contract_accept',
        'partner_contract_delete',
        'partner_contract_cancel_normally',
        'partner_contract_cancel_immediately',
        'partner_contract_uncancel_normal_cancellation',
        'partners_employee_notes_create',
    ].includes(item.type) && <NewsFeedItem item={item} pinItem={pinItem}/>
)

@connect(
    state => ({
        loading: state.pages.newsfeed.isLoading,
        pagination: state.pages.newsfeed.pagination,
        items: newsFeedActivitiesSelector(state),
    }),
    {
        getNewsFeed,
        pinActivity,
        unpinActivity,
    }
)
class NewsFeed extends PureComponent<NewsFeedModel> {
    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.selectedTab !== this.props.selectedTab || JSON.stringify(this.props.subject) !== JSON.stringify(prevProps.subject)) &&
            this.props.selectedTab !== 'email'
        ) {
            this.getData()
        }
    }

    getData = (page?, append?) => {
        const tabIndex = this.props.selectedTab
        this.props.getNewsFeed({
            ...this.props.subject,
            type: tabIndex === 'feed' ? '' : tabIndex,
            page,
            sort: '-is_pinned',
        }, append)
    }

    loadMore = page => {
        this.getData(page, true)
    }

    pinItem = async (item: Activity) => {
        if (item.isPinned) {
            await this.props.unpinActivity(item.id)
        } else {
            await this.props.pinActivity(item.id)
        }

        setTimeout(() => {
            this.getData()
        }, 1000)
    }

    render() {
        const {
            scrollHeight,
            items,
            loading,
            pagination: {
                pages,
                page,
            },
        } = this.props

        const hasMore = loading ? false : page < pages

        const noItems = items.length === 0

        const pinnedItems = items.filter(item => item.isPinned)

        return (
            <Card.Content
                isLoading={loading && !noItems}
                modifiers={[ 'fullHeight', 'p_0' ]}
            >
                <Card.ScrollableContent
                    scrollHeight={scrollHeight}
                    modifiers={[ 'maxHeight' ]}
                >
                    {!loading && !noItems && Boolean(pinnedItems.length) && <PinnedNotes>
                        <PinnedNotes.Top />
                        {pinnedItems.map(item => <NewsFeedItemFunc key={item.id + '_NewsFeedItem'} item={item} pinItem={this.pinItem}/>)}
                        <PinnedNotes.Bottom />
                    </PinnedNotes>}
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadMore}
                        hasMore={hasMore}
                        useWindow={false}
                    >
                        {loading && noItems
                            ? <Placeholder counter={7} portrait={true} />
                            : noItems
                                ? 'No activities yet...'
                                : items.filter(item => !item.isPinned).map(item =>
                                    <NewsFeedItemFunc key={item.id + '_NewsFeedItem'} item={item} pinItem={this.pinItem}/>
                                )
                        }
                    </InfiniteScroll>
                </Card.ScrollableContent>
            </Card.Content>
        )
    }
}

export default NewsFeed as unknown as ComponentType<NewsFeedProps>
