import React, { PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import styled from 'styled-components'
const Text = styled.span`
  cursor: copy;
`
export class CopyToClipboard extends PureComponent {
    copyToClipboard = e => {
        const textField = document.createElement('textarea')
        const text = e.target.textContent
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        toastr.info('Copied: ' + text)
        textField.remove()
    }

    render() {
        return <Text onClick={this.copyToClipboard}>{this.props.children}</Text>
    }
}

