export const darkGrey = '#8197AD'
export const ctaColor = '#25CA74'
export const primaryColor = '#00A4EB'
export const successColor = '#00C561'
export const backgroundColor = '#EEF3F6'
export const fadedTextColor = '#AAA'
export const dangerColor = '#E63637'
export const borderColor = '#C7D7DD'
export const cardPadding = '20px'
export const actionColor = ctaColor
export const metaTextColor = '#8F8F8F'
export const warningColor = '#F0861C'

/*Table*/
export const tableBorderColor = '#E6EBF0'
export const tableBackgroundColor = '#F8FAFC'

export const satisfactionColor0 = '#A7A7A7'
export const satisfactionColor1 = '#EE5152'
export const satisfactionColor2 = '#F5A623'
export const satisfactionColor3 = '#7ED321'
export const satisfactionColor4 = '#00AF5B'
export const bodyBackgroundColor = '#E6E8EB'

/* Event message colors */
export const messageTextColor = '#707171'
export const messageIconColor = '#9CAEB6'
export const messageBackgroundColor = '#F9FBFC'
export const messageBorderColor = borderColor

/* Buttons color */
export const primaryLightColor = '#00affa'
export const primaryDarkColor = '#0099dc'

export const secondaryLightColor = '#91aac3'
export const secondaryDarkColor = '#798da0'

export const actionLightColor = '#27d77b'
export const actionDarkColor = '#23bd6d'

export const dangerLightColor = '#ec4d4e'
export const dangerDarkColor = '#e62527'

export const warningLightColor = '#f3983c'
export const warningDarkColor = '#e4770c'
