import React, { ComponentType, PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { Card } from '../../blocks/Card'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Flex } from '../../elements/Flex'
import { connect } from '../../../utils/connect'
import { createPartnerAction } from '../../../actions/partners'
import { Button } from '../../elements/Button'

interface StatePropsPartnerAccessControl {
    partner: Partner
}

interface DispatchPropsPartnerAccessControl {
    createPartnerAction?: typeof createPartnerAction
}

interface PathPropsPartnerAccessControl {
    partnerId: number
}

type PartnerAccessControlModel = StatePropsPartnerAccessControl
    & DispatchPropsPartnerAccessControl
    & PathPropsPartnerAccessControl

@connect<StatePropsPartnerAccessControl, DispatchPropsPartnerAccessControl, PathPropsPartnerAccessControl>(
    (state, { partnerId }) => ({
        partner: state.entities.partners[partnerId],
    }),
    {
        createPartnerAction,
    }
)

class PartnerAccessControl extends PureComponent<PartnerAccessControlModel> {
    grantAccess = async partnerId => {
        try {
            await this.props.createPartnerAction(partnerId, { action: 'grant_portal_access' })
            toastr.success('Success!', 'Partner access to Partner portal has been successfully granted')
        } catch (e) {
            toastr.error('Error!', 'Granting access was unsuccessful.')
        }
    }

    revokeAccess = async partnerId => {
        try {
            await this.props.createPartnerAction(partnerId, { action: 'revoke_portal_access' })
            toastr.success('Success!', 'Partner access to Partner portal has been successfully revoked')
        } catch (e) {
            toastr.error('Error!', 'Revoking access was unsuccessful.')
        }
    }

    render() {
        const {
            partner,
        } = this.props

        return (
            <Card className="partnerAccessControl">
                <Card.Header>Portal access Control</Card.Header>
                <Card.Content>
                    <Flex modifiers="justifySpaceBetween">
                        <Card.Text>
                            Partner has access:
                            <span>{partner.hasPortalAccess ? 'Yes' : 'No'}</span>
                        </Card.Text>
                        {!partner.hasPortalAccess && <Button modifiers={[ 'action' ]} onClick={() => this.grantAccess(partner.id)}>Grant access</Button>}
                        {partner.hasPortalAccess && <Button modifiers={[ 'danger' ]} onClick={() => this.revokeAccess(partner.id)}>Revoke access</Button>}
                    </Flex>
                </Card.Content>
            </Card>
        )
    }
}

export default PartnerAccessControl as unknown as ComponentType<PartnerAccessControlModel>
