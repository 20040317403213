import React, { PureComponent } from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'
import { getKPITargets } from '../../actions/kpi'
import config from '../../config'
import { Card } from '../blocks/Card/index'
import { Flex } from '../elements/Flex'
import { Col, FormControl, Row } from 'react-bootstrap'
import MetaLabel from '../elements/text/MetaLabel'
import { every } from 'lodash'
import { replace } from 'connected-react-router'
import { connect } from '../../utils/connect'
import { Employee } from '../../reducers/types/entities/Employee'
import { KPITargets } from '../../reducers/types/entities/KPITarget'
import ColumnKPI from './KPI-target-page-component/ColumnKPI'
import { employeeSelector } from '../../selectors/employees'

const COLUMN = [
    { identifier: '', name: '' },
    { identifier: 'validations', name: 'Validations' },
    { identifier: 'completions', name: 'Completions' },
    { identifier: 'matches', name: 'Matches' },
    { identifier: 'validation_efficiency', name: 'Validation efficiency' },
    { identifier: 'completion_ratio', name: 'Completion ratio' },
    { identifier: 'match_ratio', name: 'CMatch ratio' },
    { identifier: 'exclusive_fee', name: 'Exclusive fee' },
    { identifier: 'exclusive_match_ratio', name: 'Exclusive match ratio' },
    { identifier: 'exclusive_leads', name: 'Exclusive leads' },
    { identifier: 'total_fee', name: 'Total fee' },
    { identifier: 'average_fee', name: 'Average fee' },
]

export const PERCENT_COLUMNS = [ 'validation_efficiency', 'match_ratio', 'completion_ratio', 'exclusive_match_ratio',
    'average_fee' ]

export const sumPerColumn = (colIdentifier, employees, date, kpiTargets) => {
    const columnTargets = employees
        .map(em => kpiTargets[`${em.id}-${colIdentifier}-${date}`])
        .filter(el => el)

    if (PERCENT_COLUMNS.includes(colIdentifier)) {
        if (columnTargets.length === employees.length) {
            const firstEmployeeValue = columnTargets[0].value
            const isAllTargetsSame = every(columnTargets, { value: firstEmployeeValue })
            if (isAllTargetsSame)
                return firstEmployeeValue
            return ''
        }
        return ''
    }
    const sum = columnTargets.reduce((acc, next) => acc + next.value, 0)
    return sum ? sum.toFixed(2) : ''
}

interface KPITargetsPageModel {
    employees: Employee[]
    isLoadingKpis: boolean,
    kpiTargets: KPITargets,
    kpiTargetCreatePermission: {}
    getKPITargets: typeof getKPITargets
    replace: typeof replace,
}

@connect(
    state => ({
        employees: employeeSelector(state),
        isLoadingKpis: state.pages.kpiTarget.isLoading,
        kpiTargets: state.entities.kpiTargets,
        kpiTargetCreatePermission: state.entities.permissions.kpi_target_create,
    }),
    {
        getKPITargets,
        replace,
    }
)
export default class KPITargetsPage extends PureComponent<KPITargetsPageModel> {
    state = {
        date: moment().format('YYYY-M'),
        employees: this.props.employees.filter(employee =>
            employee.geoCode === config.geoCode && employee.roles.find(el => el.title === 'validator')
        ),
        geoCode: config.geoCode,
        column: COLUMN,
    }
    componentDidMount() {
        if (this.props.kpiTargetCreatePermission) {
            this.getData(
                this.state.geoCode,
                this.state.date,
                this.state.employees
            )
        } else {
            this.props.replace('/')
        }
    }

    updateColumnState() {
        const { employees, date } = this.state
        const { kpiTargets } = this.props
        const column = COLUMN.map(el => ({
            sumValue: sumPerColumn(el.identifier, employees, date, kpiTargets),
            ...el,
        }))
        this.setState({ column })
    }

    async getData(geoCode, date, employees) {
        const splitDate = date.split('-')
        const kpiIdentifier = COLUMN.map(el => el.identifier).filter(el => el)
        const year = splitDate[0]
        const month = splitDate[1]

        await this.props.getKPITargets({
            kpiIdentifier,
            geoCode,
            employeeId: employees.map(el => el.id),
            year,
            month,
        })
        await this.updateColumnState()
    }

    changeCountry = e => {
        const employees = this.props.employees.filter(employee =>
            employee.geoCode === e.target.value && employee.roles.find(el => el.title === 'validator')
        )
        this.setState({ employees })
        this.getData(
            e.target.value,
            this.state.date,
            employees
        )
    }

    onDateChange = e => {
        const date = e.format('YYYY-M')
        this.setState({ date })
        this.getData(
            this.state.geoCode,
            date,
            this.state.employees
        )
    }

    render() {
        return <Card>
            <Card.Header>KPI targets</Card.Header>
            <Card.Content>
                <Row>
                    <Col md={3}>
                        <MetaLabel>Country</MetaLabel>
                        <FormControl
                            defaultValue={this.state.geoCode}
                            onChange={this.changeCountry}
                            componentClass="select"
                        >
                            {config.countries.map(country =>
                                <option key={'option_' + country.id} value={country.id}>{country.name}</option>)
                            }
                        </FormControl>
                    </Col>
                    <Col md={3}>
                        <MetaLabel>Date</MetaLabel>
                        <Datetime
                            onChange={this.onDateChange}
                            value={this.state.date}
                            dateFormat="YYYY-M"
                            input={true}
                        />
                    </Col>
                </Row>
            </Card.Content>
            <Card.Content isLoading={this.props.isLoadingKpis}>
                <Flex>
                    {this.state.column.map((col, i) =>
                        <ColumnKPI
                            key={col.name + '_' + i}
                            col={col}
                            params={this.state}
                        />
                    )}
                </Flex>
            </Card.Content>
        </Card>
    }
}
