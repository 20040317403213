import {
    GET_QUOTES_SURVEILLANCE_LEADS,
    GET_QUOTES_SURVEILLANCE_LEADS_SUCCESS,
    GET_QUOTES_SURVEILLANCE_LEADS_FAIL, CLOSE_LEAD_COMPLETED_SUCCESS
} from '../../actions/leads'
import { GET_PARTNERS, GET_PARTNERS_FAIL, GET_PARTNERS_SUCCESS } from '../../actions/partners'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'
import { Pagination } from '../types/Pagination'

export interface QuotesSurveillanceModel extends InitialState {
    leadIds: number[]
    partnerIds: number[]
    partnersLoading: boolean
    partnersPagination: Pagination
}
const initialState: QuotesSurveillanceModel = {
    isLoading: true,
    partnersLoading: true,
    leadIds: [],
    partnerIds: [],
    partnersPagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

interface QuotesSurveillanceActionModel extends InitialAction{
    leadId: number
}

export default function reducer(
    state = initialState,
    action: QuotesSurveillanceActionModel
) {
    switch (action.type) {
        case GET_QUOTES_SURVEILLANCE_LEADS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_QUOTES_SURVEILLANCE_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: action.append
                    ? state.leadIds.concat(action.response.result)
                    : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_QUOTES_SURVEILLANCE_LEADS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_PARTNERS:
            return {
                ...state,
                partnersLoading: true,
            }
        case GET_PARTNERS_SUCCESS:
            return {
                ...state,
                partnerIds: action.append ? state.partnerIds.concat(action.response.result) : action.response.result,
                partnersLoading: false,
                partnersPagination: action.response.pagination,
            }
        case GET_PARTNERS_FAIL:
            return {
                ...state,
                partnersLoading: true,
            }
        case CLOSE_LEAD_COMPLETED_SUCCESS:
            return {
                ...state,
                leadIds: state.leadIds.filter(item => item !== action.leadId),
            }
        default:
            return state
    }
}
