import { createSelector } from 'reselect'

const entitiesMatches = state => state.entities.cMatches

const subjectMatchList = state => state.subjects.cMatches.all.ids

export const allCMatchesSelector = createSelector(
    entitiesMatches, subjectMatchList,
    (matches, ids) => ids.map(id => matches[id])
)
