import config from '../config'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_TERMS = 'terms/GET_TERMS'
export const GET_TERMS_SUCCESS = 'terms/GET_TERMS_SUCCESS'
export const GET_TERMS_FAIL = 'terms/GET_TERMS_FAIL'

export const GET_RENDERED_TERMS = 'terms/GET_RENDERED_TERMS'
export const GET_RENDERED_TERMS_SUCCESS = 'terms/GET_RENDERED_TERMS_SUCCESS'
export const GET_RENDERED_TERMS_FAIL = 'terms/GET_RENDERED_TERMS_FAIL'

interface GetTermsModel {
    geoCode?: string
    identifier?: string
}
export const getTerms = (
    criteria?: GetTermsModel
)  => ({
    [CALL_API]: {
        types: [ GET_TERMS, GET_TERMS_SUCCESS, GET_TERMS_FAIL ],
        endpoint: '/terms',
        schema: schemas.terms,
        options: {
            body: {
                geoCode: config.geoCode,
                limit: 1000,
                ...criteria,
            },
        },
    },
})

export const getTerm = (
    termId: number,
) => ({
    [CALL_API]: {
        types: [ GET_RENDERED_TERMS, GET_RENDERED_TERMS_SUCCESS, GET_RENDERED_TERMS_FAIL ],
        endpoint: `/terms/${termId}`,
        schema: schemas.term,
    },
})
