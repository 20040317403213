import React, { PureComponent } from 'react'

interface RangeModel {
    min: number
    max?: number
    class: string
}
interface KpiIndicatorModel {
    ranges: RangeModel[]
    value: number
}
export default class KpiIndicator extends PureComponent<KpiIndicatorModel> {
    render() {
        const { ranges, value } = this.props

        let c = ''
        for(const range of ranges) {
            if (
                (typeof range.min === 'undefined' || range.min <= value) &&
                (typeof range.max === 'undefined' || value < range.max)
            ) {
                c = range.class
            }
        }

        if(c === '') return null

        return <span className={'dot dot-' + c}/>
    }
}
