import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import RadioGroup from '../elements/forms/inputs/RadioGroup'
import { connect } from '../../utils/connect'
import { acceptLeadQuote } from '../../actions/leads'
import { hideModal } from '../../actions/modal'
import { getQuotes } from '../../actions/quotes'
import { Quote } from '../../reducers/types/entities/Quote'

interface LeadQuoteAcceptModel {
    modalProps: {
        disabled: boolean
        leadId: number
        quotes: Quote[]
    }
    getQuotes: typeof getQuotes
    acceptLeadQuote: typeof acceptLeadQuote
    hideModal: typeof hideModal
    submit: typeof submit,
}

@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        getQuotes,
        acceptLeadQuote,
        hideModal,
        submit,
    }
)
class LeadQuoteAcceptModal extends PureComponent<LeadQuoteAcceptModel> {
    hideModal = () => this.props.hideModal()

    acceptQuote = async quote => {
        const leadId = this.props.modalProps.leadId
        await this.props.acceptLeadQuote(leadId, quote.id)
        await this.props.getQuotes({
            criteria: {
                leadId,
                isPublished: true,
            },
        })
    }

    save = () => {
        this.props.submit('leadAcceptQuoteForm')
    }

    render() {
        const { modalProps } = this.props
        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Accept quote</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="leadAcceptQuoteForm"
                    onSubmit={this.acceptQuote}
                >
                    <Field
                        name="id"
                        label="Pleas select which quote you would like accept"
                        component={RadioGroup}
                        options={modalProps.quotes.map(quote => ({
                            id: quote.id,
                            name: quote.partner.companyName
                        }))}
                    />
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    modifiers={[ 'secondary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.hideModal}
                >
                    Cancel
                </Button>
                &nbsp;
                <Button
                    modifiers={[ 'primary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.save}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default LeadQuoteAcceptModal as unknown as ComponentType

