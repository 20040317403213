import React, { PureComponent } from 'react'
import nl2br from '../../utils/nl2br'
import { primaryColor } from '../../utils/variables'
import styled from 'styled-components'

const RegularLink = styled.a`
    color: ${primaryColor};
    text-decoration: underline;
`
interface ReadMoreModel {
    className?: string
    characters?: number
    children: string
}

export default class ReadMore extends PureComponent<ReadMoreModel> {
    state = {
        expanded: false,
    }

    componentDidUpdate(prevProps) {
        if(prevProps.children !== this.props.children) {
            this.setState({ expanded: false })
        }
    }

    readMore = () => this.setState({ expanded: true })

    render() {
        const { children, className, characters = 300 } = this.props
        const { expanded } = this.state

        const truncate = children.length > characters
        const text = expanded ?
            children :
            (truncate ? children.substring(0, characters) + '...' : children)

        return (
            <div className={className}>
                {nl2br(text)}

                {!expanded && truncate &&
                <RegularLink onClick={this.readMore}>
                    Read more
                </RegularLink>}
            </div>
        )
    }
}
