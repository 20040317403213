import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { Amount } from '../reducers/types/entities/Amount'
import { PaymentSolution } from '../reducers/types/entities/PaymentSolution'

export const GET_PARTNER_SUBSCRIPTIONS = 'packages/GET_PARTNER_SUBSCRIPTIONS'
export const GET_PARTNER_SUBSCRIPTIONS_SUCCESS = 'packages/GET_PARTNER_SUBSCRIPTIONS_SUCCESS'
export const GET_PARTNER_SUBSCRIPTIONS_FAIL = 'packages/GET_PARTNER_SUBSCRIPTIONS_FAIL'

export const GET_PARTNER_DISCOUNTS = 'packages/GET_PARTNER_DISCOUNTS'
export const GET_PARTNER_DISCOUNTS_SUCCESS = 'packages/GET_PARTNER_DISCOUNTS_SUCCESS'
export const GET_PARTNER_DISCOUNTS_FAIL = 'packages/GET_PARTNER_DISCOUNTS_FAIL'

export const CREATE_PARTNER_DISCOUNTS = 'packages/CREATE_PARTNER_DISCOUNTS'
export const CREATE_PARTNER_DISCOUNTS_SUCCESS = 'packages/CREATE_PARTNER_DISCOUNTS_SUCCESS'
export const CREATE_PARTNER_DISCOUNTS_FAIL = 'packages/CREATE_PARTNER_DISCOUNTS_FAIL'

export const PARTNER_SUBSCRIPTION_ACTION = 'packages/PARTNER_SUBSCRIPTION_ACTION'
export const PARTNER_SUBSCRIPTION_ACTION_SUCCESS = 'packages/PARTNER_SUBSCRIPTION_ACTION_SUCCESS'
export const PARTNER_SUBSCRIPTION_ACTION_FAIL = 'packages/PARTNER_SUBSCRIPTION_ACTION_FAIL'

export const DELETE_PARTNER_DISCOUNTS = 'packages/DELETE_PARTNER_DISCOUNTS'
export const DELETE_PARTNER_DISCOUNTS_SUCCESS = 'packages/DELETE_PARTNER_DISCOUNTS_SUCCESS'
export const DELETE_PARTNER_DISCOUNTS_FAIL = 'packages/DELETE_PARTNER_DISCOUNTS_FAIL'

export const GET_SUBSCRIPTION_PRICES = 'packages/GET_SUBSCRIPTION_PRICES'
export const GET_SUBSCRIPTION_PRICES_SUCCESS = 'packages/GET_SUBSCRIPTION_PRICES_SUCCESS'
export const GET_SUBSCRIPTION_PRICES_FAIL = 'packages/GET_SUBSCRIPTION_PRICES_FAIL'

export const DELETE_SUBSCRIPTION_PRICES = 'packages/DELETE_SUBSCRIPTION_PRICES'
export const DELETE_SUBSCRIPTION_PRICES_SUCCESS = 'packages/DELETE_SUBSCRIPTION_PRICES_SUCCESS'
export const DELETE_SUBSCRIPTION_PRICES_FAIL = 'packages/DELETE_SUBSCRIPTION_PRICES_FAIL'

export const CREATE_SUBSCRIPTION_PRICE = 'packages/CREATE_SUBSCRIPTION_PRICE'
export const CREATE_SUBSCRIPTION_PRICE_SUCCESS = 'packages/CREATE_SUBSCRIPTION_PRICE_SUCCESS'
export const CREATE_SUBSCRIPTION_PRICE_FAIL = 'packages/CREATE_SUBSCRIPTION_PRICE_FAIL'

export const GET_PACKAGE_TYPES = 'packages/GET_PACKAGE_TYPES'
export const GET_PACKAGE_TYPES_SUCCESS = 'packages/GET_PACKAGE_TYPES_SUCCESS'
export const GET_PACKAGE_TYPES_FAIL = 'packages/GET_PACKAGE_TYPES_FAIL'

export const CREATE_PARTNER_SUBSCRIPTIONS = 'packages/CREATE_PARTNER_SUBSCRIPTIONS'
export const CREATE_PARTNER_SUBSCRIPTIONS_SUCCESS = 'packages/CREATE_PARTNER_SUBSCRIPTIONS_SUCCESS'
export const CREATE_PARTNER_SUBSCRIPTIONS_FAIL = 'packages/CREATE_PARTNER_SUBSCRIPTIONS_FAIL'

export const CONVERT_PARTNER_SUBSCRIPTION = 'packages/CONVERT_PARTNER_SUBSCRIPTION'
export const CONVERT_PARTNER_SUBSCRIPTION_SUCCESS = 'packages/CONVERT_PARTNER_SUBSCRIPTION_SUCCESS'
export const CONVERT_PARTNER_SUBSCRIPTION_FAIL = 'packages/CONVERT_PARTNER_SUBSCRIPTION_FAIL'

export const UPDATE_PARTNER_SUBSCRIPTIONS = 'packages/UPDATE_PARTNER_SUBSCRIPTIONS'
export const UPDATE_PARTNER_SUBSCRIPTIONS_SUCCESS = 'packages/UPDATE_PARTNER_SUBSCRIPTIONS_SUCCESS'
export const UPDATE_PARTNER_SUBSCRIPTIONS_FAIL = 'packages/UPDATE_PARTNER_SUBSCRIPTIONS_FAIL'

interface GetPartnerSubscriptionsModel {
    isActive: 0 | 1
}

export const getPartnerSubscriptions = (
    partnerId: number,
    criteria?: GetPartnerSubscriptionsModel
) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_SUBSCRIPTIONS, GET_PARTNER_SUBSCRIPTIONS_SUCCESS, GET_PARTNER_SUBSCRIPTIONS_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions`,
        schema: schemas.subscriptions,
        options: {
            body: {
                limit: 1000,
                sort: '-created_at',
                ...criteria,
            },
        },
    },
})

interface PartnerSubscriptionActionModel {
    cancelAt?: string
    action: string
}

export const partnerSubscriptionAction = (
    partnerId: number,
    subscriptionId: number,
    body: PartnerSubscriptionActionModel
) => ({
    [CALL_API]: {
        types: [ PARTNER_SUBSCRIPTION_ACTION, PARTNER_SUBSCRIPTION_ACTION_SUCCESS, PARTNER_SUBSCRIPTION_ACTION_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions/${subscriptionId}/actions`,
        schema: schemas.subscription,
        options: {
            method: 'post',
            body,
        },
    },
})

export const getPartnerDiscounts = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_DISCOUNTS, GET_PARTNER_DISCOUNTS_SUCCESS, GET_PARTNER_DISCOUNTS_FAIL ],
        endpoint: `/partners/${partnerId}/discounts`,
        schema: schemas.partnerDiscounts,
    },
})

export const createPartnerDiscounts = (
    partnerId: number,
    body
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_DISCOUNTS, CREATE_PARTNER_DISCOUNTS_SUCCESS, CREATE_PARTNER_DISCOUNTS_FAIL ],
        endpoint: `/partners/${partnerId}/discounts`,
        schema: schemas.partnerDiscount,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerDiscounts = (
    partnerId: number,
    discountId: number
) => ({
    [CALL_API]: {
        types: [ DELETE_PARTNER_DISCOUNTS, DELETE_PARTNER_DISCOUNTS_SUCCESS, DELETE_PARTNER_DISCOUNTS_FAIL ],
        endpoint: `/partners/${partnerId}/discounts/${discountId}`,
        options: {
            method: 'delete',
        },
    },
    discountId,
})

export const getPackageTypes = geoCode => ({
    [CALL_API]: {
        types: [ GET_PACKAGE_TYPES, GET_PACKAGE_TYPES_SUCCESS, GET_PACKAGE_TYPES_FAIL ],
        endpoint: '/packages',
        schema: schemas.packageTypes,
        options: {
            body: {
                geoCode,
            },
        },
    },
})

export const getSubscriptionPrices = (partnerId, subscriptionId) => ({
    [CALL_API]: {
        types: [ GET_SUBSCRIPTION_PRICES, GET_SUBSCRIPTION_PRICES_SUCCESS, GET_SUBSCRIPTION_PRICES_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions/${subscriptionId}/prices`,
        schema: schemas.subscriptionPrices,
    },
})

export const deleteSubscriptionPrice = (partnerId, subscriptionId, priceId) => ({
    [CALL_API]: {
        types: [ DELETE_SUBSCRIPTION_PRICES, DELETE_SUBSCRIPTION_PRICES_SUCCESS, DELETE_SUBSCRIPTION_PRICES_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions/${subscriptionId}/prices/${priceId}`,
        options: {
            method: 'delete',
        },
    },
    priceId,
})

interface CreateSubscriptionPriceModel {
    partnerPackageId: number
    monthlyPrice: Amount
    effectiveAt: string
}
export const createSubscriptionPrice = (
    partnerId: number,
    body: CreateSubscriptionPriceModel
) => ({
    [CALL_API]: {
        types: [ CREATE_SUBSCRIPTION_PRICE, CREATE_SUBSCRIPTION_PRICE_SUCCESS, CREATE_SUBSCRIPTION_PRICE_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions/${body.partnerPackageId}/prices`,
        schema: schemas.subscriptionPrice,
        options: {
            method: 'post',
            body,
        },
    },
})

interface CreatePartnerSubscriptionModel {
    paymentSolution?: PaymentSolution
    monthlyPrice: Amount
    packageId: number
    expiresAt: string
}
export const createPartnerSubscription = (
    partnerId: number,
    body: CreatePartnerSubscriptionModel
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_SUBSCRIPTIONS, CREATE_PARTNER_SUBSCRIPTIONS_SUCCESS, CREATE_PARTNER_SUBSCRIPTIONS_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions`,
        schema: schemas.subscription,
        options: {
            method: 'post',
            body,
        },
    },
})

export const convertPartnerSubscription = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: [ CONVERT_PARTNER_SUBSCRIPTION, CONVERT_PARTNER_SUBSCRIPTION_SUCCESS, CONVERT_PARTNER_SUBSCRIPTION_FAIL ],
        endpoint: `/partners/${partnerId}/actions`,
        options: {
            method: 'post',
            body: {
                action: 'transition_to_contract',
            },
        },
    },
})

interface UpdatePartnerSubscriptionModel {
    packageId: number
    startsAt: string
    monthlyPrice: Amount
}
export const updatePartnerSubscription = (
    partnerId: number,
    subscriptionId: number,
    body: UpdatePartnerSubscriptionModel
) => ({
    [CALL_API]: {
        types: [ UPDATE_PARTNER_SUBSCRIPTIONS, UPDATE_PARTNER_SUBSCRIPTIONS_SUCCESS, UPDATE_PARTNER_SUBSCRIPTIONS_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions/${subscriptionId}`,
        schema: schemas.subscription,
        options: {
            method: 'put',
            body,
        },
    },
})
