import React, { PureComponent, Fragment } from 'react'
import moment from 'moment/moment'
import get from 'lodash/get'
import nl2br from '../../utils/nl2br'
import EventMessage from '../blocks/NewsMessage'
import { Flex } from '../elements/Flex'
import { Stars } from '../elements/Stars'
import { Capitalize } from '../elements/text/Capitalize'
import { Activity } from '../../reducers/types/entities/Activities'
import Checkbox from '../blocks/Checkbox'
import FormattedDate from '../elements/FormattedDate'

const ACTIONS_DATA = {
    lead_validated: { text: 'Validated lead', icon: 'thumb-up' },
    lead_info_updated: { text: 'Lead info updated', icon: 'autorenew' },
    lead_sent_out_to_partners: { text: 'Sent out lead to partners', icon: 'email' },
    lead_sent_to_advisor: { text: 'Lead sent to advisor', icon: 'email-search' },
    phonecalls_create: { text: 'Outgoing call', icon: 'phone-outgoing' },
    lead_no_answer: { text: 'Lead no answer', icon: 'phone-minus' },
    lead_marked_not_serious: { text: 'Lead marked as not serious', icon: 'emoticon-tongue' },
    lead_marked_spam: { text: 'Lead marked as spam', icon: 'bug' },
    lead_marked_test: { text: 'Lead marked as test', icon: 'eyedropper' },
    lead_marked_duplicate: { text: 'Lead marked as duplicate', icon: 'account-multiple' },
    lead_marked_untreated: { text: 'Lead marked as untreated', icon: 'delete-variant' },
    lead_marked_completed: { text: 'Lead marked as completed', icon: 'clipboard-check' },
    lead_call: { text: 'Called lead', icon: 'phone-in-talk' },
    lead_given_up: { text: 'Employee gave up on lead', icon: 'cancel' },
    lead_sent_as_mail: { text: 'Sent out as e-mail', icon: 'email-outline' },
}
interface NewsFeedItemModel {
    item: Activity
    pinItem: any
}
export default class NewsFeedItem extends PureComponent<NewsFeedItemModel> {
    invoicesCreateCase(item) {
        const showPunchPrice = (item.invoice.amountExclVat.amount > 0) && (item.invoice.punches > 0)
        const punchPrice = showPunchPrice
            ? '(' + Math.round(item.invoice.amountExclVat.amount / item.invoice.punches) + '/credit)' :
            ''
        const voucherType = item.invoice.type === 'credit' ? 'Credited' : 'Invoiced'
        return (
            <EventMessage.Note>
                <EventMessage.Icon className="mdi mdi-thumb-up" />
                <div>
                    {item.invoice.punches
                        ? <span>
                            Sold
                            {item.invoice.punches}
                            {' '}
                            credits for
                            {' '}
                        </span>
                        : <span>
                            {voucherType}
                            {' '}
                        </span>
                    }
                    {item.invoice.amountExclVat.currency}
                    {' '}
                    {item.invoice.amountExclVat.amount}
                    ,-
                    {' '}
                    {punchPrice}
                </div>
            </EventMessage.Note>
        )
    }

    noteData(item) {
        switch (item.type) {
            case 'lead_postponed':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-golf" />
                        <div>
                            <div>
                                Lead
                                <i>
                                    #
                                    {item.lead.id}
                                </i>
                                {' '}
                                Lead was postponed. Purpose of next contact is:
                                {' '}
                            </div>
                            {item.note && <div><strong>{item.note.text}</strong></div>}
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_contract_create':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-check" />
                        <div>
                            <div>
                                Created a draft contract (
                                <i>
                                    #
                                    {get(item, 'partnerContract.id')}
                                </i>
                                )
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_contract_accept':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-check" />
                        <div>
                            <div>
                                Accepted a contract (
                                <i>
                                    #
                                    {get(item, 'partnerContract.id')}
                                </i>
                                )
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_contract_delete':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-check" />
                        <div>
                            <div>
                                Deleted a draft contract (
                                <i>
                                    #
                                    {get(item, 'partnerContract.id')}
                                </i>
                                )
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_contract_cancel_normally':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-check" />
                        <div>
                            <div>
                                Canceled an accepted contract (
                                <i>
                                    #
                                    {get(item, 'partnerContract.id')}
                                </i>
                                )
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_contract_cancel_immediately':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-check" />
                        <div>
                            <div>
                                Canceled an accepted contract immediately (
                                <i>
                                    #
                                    {get(item, 'partnerContract.id')}
                                </i>
                                )
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_contract_uncancel_normal_cancellation':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-check" />
                        <div>
                            <div>
                                Reactivated a canceled contract (
                                <i>
                                    #
                                    {get(item, 'partnerContract.id')}
                                </i>
                                )
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_lead_quote_accepted':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-calendar" />
                        <div>
                            <div>
                                Lead
                                <i>
                                    #
                                    {item.lead.id}
                                </i>
                                {' '}
                                accepted a quote
                                {item.partner && <span>
                                    from
                                    <i>{item.partner.name}</i>
                                </span>}
                            </div>
                        </div>
                    </EventMessage.Note>
                )
            case 'lead_reject_all_quotes':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-account-off" />
                        <div>
                            <div>
                                Lead
                                <i>
                                    #
                                    {item.lead.id}
                                </i>
                                {' '}
                                has rejected a quote
                                {item.partner && <i>
                                    {' '}
                                    by
                                    {item.partner.name}
                                </i>}
                                {' '}
                                because of:
                            </div>
                            {item.note && <p><strong>{nl2br(item.note.text)}</strong></p>}
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_satisfaction':
                return (
                    <EventMessage.Note>
                        {nl2br(item.note)}
                    </EventMessage.Note>
                )
            case 'partners_ratings_create':
                return (
                    <EventMessage.Note>
                        <Stars total={5} filled={item.rating.rating} />
                        <blockquote>
                            <strong>{item.rating.title}</strong>
                            <br/>
                            {nl2br(item.rating.comment)}
                        </blockquote>
                    </EventMessage.Note>
                )
            case 'invoices_create':
                return this.invoicesCreateCase(item)
            case 'partners_notes_create':
            case 'lead_note_created':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-playlist-edit" />
                        <div>
                            {item.invoker === 'partner' && <div >Partner said:</div>}
                            {nl2br(item.note.text)}
                        </div>
                        <div style={{ width: '24px', marginLeft: 'auto', marginRight: 0 }}>
                            <Checkbox checked={item.isPinned} onChange={() => this.props.pinItem(item)} />
                        </div>
                    </EventMessage.Note>
                )
            case 'task_create':
                return (
                    <EventMessage.Note modifiers={[ 'alignCenter' ]}>
                        <EventMessage.Icon className="mdi mdi-calendar-plus" />
                        <div>
                            <div>
                                Created task:
                                <strong>{item.task.title}</strong>
                            </div>
                            {item.task.assignedEmployee && <span>
                                Assigned to
                                {item.task.assignedEmployee.name}
                                {' '}
                            </span>}
                            {!item.task.description && <p>{item.task.description}</p>}
                        </div>
                    </EventMessage.Note>
                )
            case 'task_complete':
                return (
                    <EventMessage.Note modifiers={[ 'alignCenter' ]}>
                        <EventMessage.Icon className="mdi mdi-calendar-check" />
                        <div>
                            <div>
                                Completed task:
                                <strong>{item.task.title}</strong>
                            </div>
                            {item.task.assignedEmployee && <span>
                                Assigned to
                                {item.task.assignedEmployee.name}
                                {' '}
                            </span>}
                        </div>
                    </EventMessage.Note>

                )
            case 'partners_employee_notes_create':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-playlist-edit" />
                        <div>
                            {item.invoker === 'partner' && <div >Partner said:</div>}
                            {nl2br(item.partnerEmployeeNote.content)}
                        </div>
                        <div style={{ width: '24px', marginLeft: 'auto', marginRight: 0 }}>
                            <Checkbox checked={item.isPinned} onChange={() => this.props.pinItem(item)} />
                        </div>
                    </EventMessage.Note>
                )
            case 'partner_lead_quote_refused':
                return (
                    <EventMessage.Note>
                        <EventMessage.Icon className="mdi mdi-account-off" />
                        <div>
                            <div>
                                Partner
                                {item.partner && <i>
                                    {' '}
                                    {item.partner.name}
                                </i>}
                                {' '}
                                has refused a lead
                                {' '}
                                <i>
                                    #
                                    {item.lead.id}
                                </i>
                                {' '}
                                because of:
                            </div>
                            {item.note && <p><strong>{nl2br(item.note.text)}</strong></p>}
                        </div>
                    </EventMessage.Note>
                )
            default:
                return (
                    <EventMessage.Note modifiers={[ 'alignCenter' ]}>
                        <EventMessage.Icon className={'mdi mdi-' + ACTIONS_DATA[item.type].icon} />
                        <span>{ACTIONS_DATA[item.type].text}</span>
                    </EventMessage.Note>
                )
        }
    }

    render() {
        const { item } = this.props

        const time = moment(item.createdAt).fromNow()
        const actorName = (item[item.invoker] || {}).name

        return (
            <EventMessage modifiers={[ 'alignCenter' ]}>
                <EventMessage.Meta existPortrait={item.invoker === 'employee'}>
                    <span>
                        {actorName && <Fragment>
                            <strong>{actorName}</strong>
                            &nbsp; &middot;
                        </Fragment>}
                        <span>
                            {' '}
                            {time}
                            {' '}
                            (
                            <FormattedDate date={item.createdAt} format="shortdate" withTitle={true}/>
                            )
                        </span>
                    </span>
                    {item.type === 'partner_lead_quote_accepted' &&
                    <i>
                        <Capitalize>{item.invoker}</Capitalize>
                        {' '}
                        pressed match
                    </i>}
                </EventMessage.Meta>
                <Flex modifiers={[ 'alignItemsEnd' ]}>
                    {item.invoker === 'employee' && <div>
                        <EventMessage.Portrait
                            name={item.employee.name}
                            src={item.employee.profilePicture}
                            size={55}
                        />
                    </div>}
                    {this.noteData(item)}
                    {item.satisfaction &&
                    <div><EventMessage.Satisfaction size={64} score={item.satisfaction.score} /></div>}
                </Flex>
            </EventMessage>
        )
    }
}
