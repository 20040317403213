import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_GEO_REGIONS = actionTypesCreator('geoRegions', 'GET_GEO_REGIONS')

export const GET_BUSINESS_UNIT_GEO_REGIONS = actionTypesCreator('geoRegions', 'GET_BUSINESS_UNIT_GEO_REGIONS')

export const UPDATE_BUSINESS_UNIT_GEO_REGIONS = actionTypesCreator('geoRegions', 'UPDATE_BUSINESS_UNIT_GEO_REGIONS')

export const getGeoRegions = (criteria) => ({
    [CALL_API]: {
        types: GET_GEO_REGIONS,
        endpoint: '/locations/segmentationgeoregions',
        schema: schemas.geoRegions,
        options: {
            body: Object.assign(
                { limit: 100 },
                criteria || {}
            ),
        },
    },
})

export const getBusinessUnitGeoRegions = (
    partnerId: number,
    businessUnitId: number
) => ({
    [CALL_API]: {
        types: GET_BUSINESS_UNIT_GEO_REGIONS,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/segmentationgeoregions`,
        schema: schemas.geoRegions,
        options: {
            body: { limit: 100 },
        },
    },
})

export const updateBusinessUnitGeoRegions = (
    partnerId: number,
    businessUnitId: number,
    segmentationGeoRegionIds: number[]
) => ({
    [CALL_API]: {
        types: UPDATE_BUSINESS_UNIT_GEO_REGIONS,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/segmentationgeoregions`,
        options: {
            method: 'put',
            body: {
                segmentationGeoRegionIds,
            },
        },
    },
})
