import React, { memo } from 'react'
import { Amount } from '../../../../reducers/types/entities/Amount'
import Cell from './Cell'
import { FormattedAmount } from '../../FormattedAmount'

const AmountCell = memo(({ amount }: {amount: Amount}) =>
    <Cell className="number">
        <FormattedAmount amount={amount} format="0.00"/>
    </Cell>
)

export default AmountCell
