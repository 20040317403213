import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import FollowUpsTableCard from './dashboard-cards/FollowUpsTableCard'
import QuotesSurveillanceTableCard from './dashboard-cards/QuotesSurveillanceTableCard'
import ExclusiveListTableCard from './dashboard-cards/ExclusiveListTableCard'
import MyPerformanceTableCard from './dashboard-cards/MyPerformanceTableCard'
import MyCustomersCard from './dashboard-cards/MyCustomersCard'
import MyTargetsTableCard from './dashboard-cards/MyTargetsTableCard'
import MyFeesBoxCard from './dashboard-cards/MyFeesBoxCard'
import MatchListCard from './dashboard-cards/MatchListCard'
import { getKPITargets, getKPIValues } from '../../actions/kpi'
import { connect } from '../../utils/connect'


interface DashboardPageModel {
    exclusiveClientsPermission: object
    employeeId: number
    getKPIValues: typeof getKPIValues
    getKPITargets: typeof getKPITargets
}
@connect(
    state => ({
        exclusiveClientsPermission: state.entities.permissions.exclusive_clients,
        employeeId: state.auth.employeeId,
    }),
    {
        getKPIValues,
        getKPITargets,
    }
)
export default class DashboardPage extends PureComponent<DashboardPageModel> {
    componentDidMount() {
        const body = {
            employeeId: this.props.employeeId,
            kpiIdentifier: [
                'validation_efficiency',
                'completion_ratio',
                'match_ratio',

                'validations',
                'completions',
                'matches',

                'average_fee',
                'total_fee',
            ],
            temporalScope: 'monthly',
        }
        const valuesBody = {
            ...body,
            kpiIdentifier: [
                ...body.kpiIdentifier,
                'validations_forecast',
                'completions_forecast',
                'matches_forecast',
                'total_fee_forecast',
            ],
        }

        this.props.getKPIValues(valuesBody)
        this.props.getKPITargets(body)
    }

    render() {
        return <Row>
            <Col md={6}>
                <MyCustomersCard/>
                <MyFeesBoxCard/>
                <MyPerformanceTableCard />
                <MyTargetsTableCard/>
            </Col>
            <Col md={6}>
                <FollowUpsTableCard />
                <QuotesSurveillanceTableCard />
                <MatchListCard />
                {this.props.exclusiveClientsPermission && <ExclusiveListTableCard />}
            </Col>
        </Row>
    }
}
