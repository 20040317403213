import React, { PureComponent } from 'react'
import { Field, WrappedFieldArrayProps } from 'redux-form'
import { Button } from '../../../elements/Button'
import styled from 'styled-components'
import MetaLabel from '../../../elements/text/MetaLabel'
import { FieldGroup } from '../../../elements/forms/inputs/FieldGroup'
import { SelectInputGroup } from '../../../elements/forms/inputs/SelectInputGroup'
import { AsyncTypeaheadGroup } from '../../../elements/forms/inputs/AsyncTypeaheadGroup'
import { getProducts } from '../../../../actions/products'
import { productsSelector } from '../../../../selectors/products'
import Error from '../../../elements/forms/Error'
import { connect } from '../../../../utils/connect'
import { Product } from '../../../../reducers/types/entities/Product'

const QuoteItemRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 3fr 3fr 80px;
  column-gap: 20px;
  row-gap: 1em;
  ${Button} {
    float: right;
  }
`

const QuoteItemsContent = styled.div`
  > div:not(:first-child):not(:last-child) {
    margin-bottom: 20px;
  }
`
interface QuoteItem {
    leadId: number
    quantity: number
}

interface QuoteItemsFormPathProps extends WrappedFieldArrayProps<QuoteItem> {
    leadId?: number
    disableItems?: boolean
}

interface QuoteItemsFormStateProps {
    products: Product[]
    isLoadingProducts: boolean
}

interface QuoteItemsFormDispatchProps {
    getProducts: typeof getProducts
}

type QuoteItemsFormModel = QuoteItemsFormStateProps & QuoteItemsFormDispatchProps & QuoteItemsFormPathProps

@connect(
    state => ({
        products: productsSelector(state),
        isLoadingProducts: state.pages.leadValidation.isLoadingProducts,
    }),
    {
        getProducts,
    }
)

export default class QuoteItemsForm<T> extends PureComponent<QuoteItemsFormModel & T> {
    componentDidMount() {
        if (!this.props.fields.length) {
            this.addRow()
            this.addRow()
        }
    }

    addRow = () => {
        this.props.fields.push({
            leadId: this.props.leadId,
            quantity: 0,
        })
    }

    removeRow = (fields, index) => {
        fields.remove(index)
        if (fields.length < 2)
            fields.push({ leadId: this.props.leadId })
    }

    onInputChange = (query: string) => {
        this.props.getProducts(query, '0')
    }

    renderMenuItemChildren = option => option.name

    render() {
        const {
            isLoadingProducts,
            disableItems,
            products,
            fields,
            meta,
        } = this.props

        return (
            <QuoteItemsContent>
                <QuoteItemRow>
                    <MetaLabel>Product name</MetaLabel>
                    <MetaLabel>Product description</MetaLabel>
                    <MetaLabel>Type</MetaLabel>
                    <MetaLabel>Quantity</MetaLabel>
                    <div/>
                </QuoteItemRow>
                {fields.map((member, index) =>
                    <QuoteItemRow key={index}>
                        <Field
                            name={`${member}.product`}
                            onSearch={this.onInputChange}
                            isLoading={isLoadingProducts}
                            removeClassFormGroup={true}
                            component={AsyncTypeaheadGroup}
                            shouldMatch={true}
                            disabled={disableItems}
                            options={products}
                            labelKey="name"
                            renderMenuItemChildren={this.renderMenuItemChildren}
                        />
                        <Field
                            name={`${member}.description`}
                            component={FieldGroup}
                            disabled={disableItems}
                            removeClassFormGroup={true}
                        />
                        <Field
                            name={`${member}.type`}
                            removeClassFormGroup={true}
                            disabled={disableItems}
                            returnId={true}
                            component={SelectInputGroup}
                        >
                            <option value="yearly">Yearly</option>
                            <option value="biannually">Biannually</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="every_second_month">Every second month</option>
                            <option value="monthly">Monthly</option>
                            <option value="weekly">Weekly</option>
                            <option value="unit">Unit</option>
                        </Field>
                        <Field
                            name={`${member}.quantity`}
                            type="number"
                            component={FieldGroup}
                            removeClassFormGroup={true}
                        />
                        <div>
                            <Button
                                disabled={disableItems}
                                onClick={() => this.removeRow(fields, index)}
                                modifiers={[ 'danger', 'btnIcon' ]}
                            >
                                <i className="mdi mdi-close" />
                            </Button>
                            {disableItems || (fields.length - 1) === index &&
                            <Button
                                onClick={this.addRow}
                                modifiers={[ 'action', 'btnIcon', 'mR_1' ]}
                            >
                                <i className="mdi mdi-plus" />
                            </Button>}
                        </div>
                    </QuoteItemRow>
                )}
                <Error meta={meta}/>
            </QuoteItemsContent>
        )
    }
}
