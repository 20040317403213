import moment from 'moment'
import React, { PureComponent } from 'react'
import { Card } from '../blocks/Card'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Button } from '../elements/Button'
import { Panel, Row, Col, Label } from 'react-bootstrap'
import { CheckboxInputField } from '../elements/forms/inputs/CheckboxGroup'
import styled from 'styled-components'
import { successColor, dangerColor } from '../../utils/variables'
import { connect } from '../../utils/connect'
import { matchReportsSelector } from '../../selectors/matches'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { cleanMatchReport, getMatchReports } from '../../actions/businessUnit'
import { firstLetterUpperCase } from '../../utils'
import { getLead } from '../../actions/leads'
import { Leads } from '../../reducers/types/entities/Lead'
import { getPartnerQuotes } from '../../actions/quotes'
import requireOverdelivery from '../business-unit/requireOverdelivery'
import Flex from 'components/src/layout/Flex'
declare const Distance

const required = [ value => value ? undefined : 'Required!' ]

const GrdRow = styled.div`
    display: grid;
    grid: auto-flow / 1fr  1fr  200px;
    grid-gap: 0 20px;
`

interface MatchedItemPropsModel {
    itemStyle: string
}
const MatchedItem = styled.div<MatchedItemPropsModel>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${
    props => props.itemStyle === 'success'
        ? '#b4e8a8'
        : props.itemStyle === 'danger'
            ? '#ffafbd'
            : '#a6e8f3'
};
    margin-bottom: 15px;
    padding-bottom: 5px;
`

const ThumbIcon = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 100%;
    font-size: 24px;
    margin-right: 5px;
    line-height: 10px;
    color: ${props => props.className === 'mdi mdi-thumb-up-outline' ? successColor : dangerColor};
`

const ResultsContent = styled(Card.Content)`
  min-height: 637px;
`

const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
  li {
    margin: 5px 0;
  }
`

const SEGMENTATION_CRITERIA = [
    'sectors', 'industries', 'certifications', 'languages', 'client_types',
    'digital_work_preferences', 'revenues', 'exclusions', 'primary_industries',
    'locations', 'company_sizes', 'accounting_software', 'regions', 'distances',
    'not_already_allocated',
]

const CRITERIA = {
    sectors: 'Lead\'s sector is part of partner\'s segmentation',
    industries: 'Service requested by the lead is partner of partner\'s segmentation',
    certifications: 'Partner does have certifications requested by the lead',
    languages: 'Partner is capable of working in language/s requested by the lead',
    client_types: 'Lead\'s type is part of partner\'s segmentation',
    digital_work_preferences: 'Lead is either local/digital and partner does want to work with that kind of lead (deprecated)',
    revenues: 'Lead\'s revenue is within partner\'s revenue segment limits',
    exclusions: 'Partner is not specifically excluded by the lead',
    primary_industries: 'Partner works in the same industry (accounting/law)',
    locations: 'Partner wants to work in the geo region where the lead is physically located',
    company_sizes: 'Partner works in the same industry (accounting/law)',
    accounting_software: 'Partner works with the same accounting software the lead uses',
    regions: 'Partner\'s physical address is located within the region requested by the lead that only wants to work locally',
    distances: 'Partner\'s physical address is within the distance requested by the lead',
    not_already_allocated: 'Partner does not have an existing allocation for this lead',
}

interface MatchReportsPropsModel {
    matches: Array<BusinessUnit & { matches: string[]}>
    isLoading: boolean
    leads: Leads
    hasPermissionToOverdeliver: boolean
    cleanMatchReport: typeof cleanMatchReport
    getMatchReports: typeof getMatchReports
    getLead: typeof getLead
    getPartnerQuotes: typeof getPartnerQuotes
    submit: typeof submit
}

@connect(
    state => ({
        matches: matchReportsSelector(state),
        isLoading: state.pages.matchReport.isLoading,
        leads: state.entities.leads,
        hasPermissionToOverdeliver: state.auth.permissions.includes('leads_allocate_with_overdelivery'),
    }),
    {
        cleanMatchReport,
        getMatchReports,
        getPartnerQuotes,
        getLead,
        submit,
    }
)
export default class MatchReportsPage extends PureComponent<MatchReportsPropsModel> {
    state = {
        open: false,
        openResult: false,
        matchedCriteria: [],
        leadGeoPoint: {},
    }

    match = data => {
        this.props.cleanMatchReport()
        const resGetLead: any = this.props.getLead(data.leadId)
        resGetLead.then(res => this.setState({ leadGeoPoint: res.entities.leads[data.leadId].geo.point }))
        SEGMENTATION_CRITERIA.forEach(criteria => {
            this.props.getMatchReports({
                segmentedForLeadId: data.leadId,
                partnerId: data.partnerId,
                segmentationCriteria: criteria,
            })

        })
    }

    submit = () => {
        this.props.submit('generateMatchReport')
    }

    thumbStatus = (businessUnit, criteria) => businessUnit.matches.includes(criteria)

    render() {
        const {
            isLoading,
            matches,
            hasPermissionToOverdeliver,
        } = this.props

        return <Row>
            <Col md={12}>
                <Card modifiers={[ 'warning' ]}>
                    <Card.Content>
                        On this page you can enter a lead ID and a partner ID and generate the match report between the two. Please note that the system also does a pre-screening, where it considers the following:
                        <ul>
                            <li>the lead is validated within 6 months</li>
                            <li>the lead and the partner should be within the same geographical market</li>
                            <li>the lead and the partner should work within the same field (acc/law)</li>
                            <li>the partner is active or “demo” - canvas partner are not included</li>
                            <li>the partner should not be excluded from the lead</li>
                        </ul>
                    </Card.Content>
                </Card>
            </Col>
            <Col md={6} >
                <Card>
                    <Card.Header>Generate a match report</Card.Header>
                    <Card.Content>
                        <ReduxForm
                            onSubmit={this.match}
                            form="generateMatchReport"
                        >
                            <GrdRow>
                                <Field
                                    label="Partner id"
                                    name="partnerId"
                                    component={FieldGroup}
                                    removeClassFormGroup={true}
                                    type="number"
                                />
                                <Field
                                    label="Lead id"
                                    name="leadId"
                                    component={FieldGroup}
                                    removeClassFormGroup={true}
                                    validate={required}
                                    type="number"
                                />
                                <Button
                                    onClick={this.submit}
                                    modifiers={[ 'primary', 'mT_2' ]}
                                >
                                    Generate match report
                                </Button>
                            </GrdRow>
                        </ReduxForm>
                        <br />
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>Match criteria</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <Ul>
                                {Object.entries(CRITERIA).map(([ key, el ]) =>
                                    <li key={key}>
                                        <CheckboxInputField
                                            text={<span><strong>{firstLetterUpperCase(key)}</strong>: {el}</span>}
                                            input={{ value: true, disabled: true }}
                                        />
                                    </li>
                                )}
                                <li>
                                    <CheckboxInputField
                                        text="Partner has enough unused spots or employee has permission to over-deliver"
                                        input={{ value: true, disabled: true }}
                                    />
                                </li>
                                </Ul>
                            </Panel.Body>
                        </Panel>
                    </Card.Content>
                </Card>
            </Col>
            <Col md={6}>
                <Card>
                    <Card.Header>Result</Card.Header>
                    <ResultsContent isLoading={isLoading}>
                        {matches.map(businessUnit => {
                            const isContractPaused = !!businessUnit.partnerContract?.allocationPauses?.find(pause => moment(pause.startsAt).isBefore(moment()) && moment(pause.endsAt).isAfter(moment()))
                            const matchStatus = SEGMENTATION_CRITERIA
                                .every(status => businessUnit.matches.includes(status))

                            const requiresOverdelivery = requireOverdelivery(businessUnit)
                            const ineligibleForDelivery = requiresOverdelivery && !hasPermissionToOverdeliver

                            const panelStyle = businessUnit.matches.length === 0
                                ? 'info'
                                : (matchStatus && !ineligibleForDelivery)
                                    ? 'success'
                                    : 'danger'

                            return <Panel bsStyle={panelStyle} key={businessUnit.id}>
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <Flex alignItems="center">
                                            {businessUnit.displayName + ' #' + businessUnit.id}
                                            <Flex ml={2}>
                                                {isContractPaused && <Label bsStyle="warning">Contract paused</Label>}
                                            </Flex>
                                        </Flex>
                                        <i className="mdi mdi-chevron-down mdi-18px"/>
                                        <i className="mdi mdi-chevron-up mdi-18px"/>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Collapse>
                                    <Panel.Body>
                                        <Row>
                                            {SEGMENTATION_CRITERIA.map((criteria, i) =>
                                                <Col md={4} key={'MatchedItem_' + criteria}>
                                                    <MatchedItem
                                                        itemStyle={this.thumbStatus(businessUnit, criteria) ? 'success' : 'danger'}
                                                    >
                                                        <div>{firstLetterUpperCase(criteria)}</div>
                                                        {this.thumbStatus(businessUnit, criteria)
                                                            ? <ThumbIcon className="mdi mdi-thumb-up-outline" />
                                                            : <ThumbIcon className="mdi mdi-thumb-down-outline" />
                                                        }
                                                    </MatchedItem>
                                                </Col>
                                            )}
                                            <Col md={4}>
                                                <MatchedItem
                                                    itemStyle={!ineligibleForDelivery ? 'success' : 'danger'}
                                                >
                                                    <div>Has unused allocations / can overdeliver</div>
                                                    {!ineligibleForDelivery
                                                        ? <ThumbIcon className="mdi mdi-thumb-up-outline" />
                                                        : <ThumbIcon className="mdi mdi-thumb-down-outline" />
                                                    }
                                                </MatchedItem>
                                            </Col>
                                        </Row>
                                    </Panel.Body>
                                </Panel.Collapse>
                            </Panel>
                        })}
                    </ResultsContent>
                </Card>
            </Col>
        </Row>

    }
}
