import React, { Fragment, PureComponent } from 'react'
import { Field, FieldArray, Form, FormikProps } from 'formik'
import { toastr } from 'react-redux-toastr'
import get from 'lodash/get'
import moment from 'moment'
import * as yup from 'yup'
import { Col, Row } from 'react-bootstrap'
import { withFormik } from '../../../utils/withFormik'
import { getPartner } from '../../../actions/partners'
import { connect } from '../../../utils/connect'
import { Card } from '../../blocks/Card'
import {
    cancelPartnerContractNormally,
    createPartnerContract,
    archivePartnerContract,
    getPartnerContract,
    acceptContractForPartner,
    uploadSignedPartnerContract,
} from '../../../actions/partnerContracts'
import LoaderComponent from '../../elements/LoaderComponent'
import { Partner } from '../../../reducers/types/entities/Partner'
import { replace } from 'connected-react-router'
import { Button, ButtonGroup, ActionButtonBlock, ButtonContainer, ButtonGrid } from '../../elements/Button'
import { Flex } from '../../elements/Flex'
import { getIndustries } from '../../../state/industries/actions'
import { getGeoRegions } from '../../../state/geoRegions/actions'
import { getLeadTypes } from '../../../state/leadTypes/actions'
import { getSectors } from '../../../state/sectors/actions'
import { ContractBusinessUnit, PartnerContract } from '../../../reducers/types/entities/PartnerContract'
import EditMode, { makeBusinessUnitObject, BusinessUnitFormValue } from './EditMode'
import PreviewMode from './PreviewMode'
import { Industries } from '../../../reducers/types/entities/Industry'
import { getSegmentationSubGeoRegions } from '../../../state/segmentationSubGeoRegions/actions'
import { LeadTypes } from '../../../reducers/types/entities/LeadType'
import { GeoRegions } from '../../../reducers/types/entities/GeoRegion'
import { SegmentationSubGeoRegions } from '../../../reducers/types/entities/SegmentationSubGeoRegion'
import { Sectors } from '../../../reducers/types/entities/Sector'
import { DateTimePickerGroup } from '../../elements/forms/inputs/DateTimePickerGroup'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import MetaLabel from '../../elements/text/MetaLabel'
import { getRevenueSegments } from '../../../actions/revenueSegments'
import { RevenueSegments } from '../../../reducers/types/entities/RevenueSegment'
import PartnerHeader from '../detailPage/PartnerHeader'
import { actualSubscriptionSelector } from '../../../selectors/subscription'
import LoadingBar from '../../elements/LoadingBar'
import { dangerColor } from '../../../utils/variables'
import { FormattedAmount } from '../../elements/FormattedAmount'
import styled from 'styled-components'
import { showModal } from '../../../actions/modal'
import FormattedDate from '../../elements/FormattedDate'
import {
    canCreateLowPriceContract,
    hasFullPartnerContractEditRights,
    canTerminateContract
} from '../../../selectors/access'
import config from '../../../config'
import { sortObjectWithLanguageSensitivity } from '../../../utils/sortObjectWithLanguageSensitivity'

const SHORT_BINDING_PERIOD_MONTHS_MAX = 6

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})

const allocationSchema = yup.object().shape({
    count: yup.number().required().min(1).integer(),
    priceExclVat: yup.object().shape({
        amount: yup.number().min(0).default(0),
    }),
    contractPriceExclVat: yup.number().min(0),
}).default(null).nullable()

const validationSchema = yup.object().shape({
    billingFrequencyMonths: yup.number().required().positive().integer(),
    bindingPeriodMonths: yup.number().required().positive().integer(),
    businessUnits: yup.array().of(yup.object().shape({
        allocationLimit: allocationSchema,
        allocationQuota: allocationSchema,
        revenueSegment: yup.object().shape({
            id: yup.number().required().positive().integer(),
        }).required(),
        industries: yup.array().min(1),
    })).min(1),
    cancellationPeriodMonths: yup.number().required().positive().integer(),
    startsAt: yup.string().required(),
})

export interface Props {
    subscriptionInfo
    partnerContract: PartnerContract
    businessUnits: ContractBusinessUnit[]
    clientTypes: LeadTypes
    geoRegions: GeoRegions
    industries: Industries
    partner: Partner
    sectors: Sectors
    subGeoRegions: SegmentationSubGeoRegions
    revenueSegments: RevenueSegments
    createShortContractPermission: any
    acceptForPartnerPermission: any
    hasFullPartnerContractEditRights: boolean
    hasUploadSignedPartnerContractPermission: any
    canCreateLowPriceContract: boolean
    canTerminateContracts: boolean
}

export interface Actions {
    createPartnerContract: typeof createPartnerContract
    archivePartnerContract: typeof archivePartnerContract
    getPartnerContract: typeof getPartnerContract
    getPartner: typeof getPartner
    replace: typeof replace
    getIndustries: typeof getIndustries
    getSectors: typeof getSectors
    getGeoRegions: typeof getGeoRegions
    getLeadTypes: typeof getLeadTypes
    getSegmentationSubGeoRegions: typeof getSegmentationSubGeoRegions
    cancelPartnerContractNormally: typeof cancelPartnerContractNormally
    acceptContractForPartner: typeof acceptContractForPartner
    uploadSignedPartnerContract: typeof uploadSignedPartnerContract
    getRevenueSegments: typeof getRevenueSegments
    showModal: typeof showModal
}

export interface State {
    editMode: boolean
    isLimitedEdit: boolean
    signedContract: File
    uploadingSignedContract: boolean
    yearlyContractValue: number
}

export interface PathProps {
    location: {
        hash: string
    }
    match: {
        params: {
            partnerId: string
            contractId?: string
        }
    }
}

export interface FormValues {
    billingFrequencyMonths: number | string
    bindingPeriodMonths: number | string
    businessUnits: BusinessUnitFormValue[]
    cancellationPeriodMonths: number | string
    intendingToReplacePartnerContractId?: number | string
    partner: {
        id: number | string
    }
    startsAt: string
}

export type Model = Props & Actions & PathProps

const bindingPeriod = {
    1: '1 month',
    3: '3 months',
    6: '6 months',
    9: '9 months (Sweden only)',
    12: '1 year',
    24: '2 years',
    36: '3 years',
}

const cancellationWithoutRenewalPeriod = {
    1: '1 month',
    2: '2 months',
    3: '3 months',
    4: '4 months',
    5: '5 months',
    6: '6 months',
    7: '7 months',
    8: '8 months',
    9: '9 months',
    10: '10 months',
    11: '11 months',
    12: '12 months',
}

const billingFrequency = {
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Bi-annually',
    12: 'Annually',
}

const cancellationPeriod = {
    2: 'Final 2 months',
    1: 'Final month',
}

const buttonGroupStyle = {
    display: 'inline',
}

const RemoveButton = styled(Button)`
    min-height: 16px;
    min-width: 16px;
    color: '#fff';
    background-color: ${dangerColor};
    line-height: 16px;
    text-align: center;
    border-radius: 3px;
    top: 10px;
    right: 10px;
    position: absolute;
`

@connect<Props, Actions, PathProps>(
    (state, { match: { params: { contractId, partnerId } } }) => ({
        partnerContract: state.entities.partnerContracts[contractId],
        businessUnits: get(state.entities.partnerContracts[contractId], 'businessUnits', []),
        clientTypes: state.entities.leadTypes,
        geoRegions: state.entities.geoRegions,
        industries: state.entities.industries,
        partner: state.entities.partners[partnerId],
        sectors: state.entities.sectors,
        subGeoRegions: state.entities.segmentationSubGeoRegions,
        revenueSegments: state.entities.revenueSegments,
        subscriptionInfo: actualSubscriptionSelector(state, partnerId),
        createShortContractPermission: state.entities.permissions.partner_contracts_create_short,
        acceptForPartnerPermission: state.entities.permissions.partner_contract_actions_accept_for_partner,
        hasFullPartnerContractEditRights: hasFullPartnerContractEditRights(state),
        hasUploadSignedPartnerContractPermission: state.entities.permissions.partner_contracts_upload,
        canCreateLowPriceContract: canCreateLowPriceContract(state),
        canTerminateContracts: canTerminateContract(state)
    }),
    {
        createPartnerContract,
        archivePartnerContract,
        getPartnerContract,
        getGeoRegions,
        getIndustries,
        getLeadTypes,
        getPartner,
        getSectors,
        getSegmentationSubGeoRegions,
        cancelPartnerContractNormally,
        acceptContractForPartner,
        uploadSignedPartnerContract,
        getRevenueSegments,
        replace,
        showModal,
    }
)
@withFormik<Model, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({
        businessUnits,
        match,
        partner,
        partnerContract,
    }) => ({
        partner: {
            id: parseInt(match.params.partnerId),
        },
        intendingToReplacePartnerContractId: get(partnerContract, 'id') || null,
        billingFrequencyMonths: get(partnerContract, 'billingFrequencyMonths') || 1,
        bindingPeriodMonths: get(partnerContract, 'bindingPeriodMonths') || SHORT_BINDING_PERIOD_MONTHS_MAX,
        cancellationPeriodMonths: get(partnerContract, 'cancellationPeriodMonths') || 2,
        startsAt: (get(partnerContract, 'startsAt') ? moment(get(partnerContract, 'startsAt')) : moment()).format('YYYY-MM'),
        businessUnits: businessUnits.length > 0 ? businessUnits.map(businessUnit => ({
            allocationLimit: businessUnit.allocationLimit ? {
                count: businessUnit.allocationLimit.count,
                priceExclVat: {
                    amount: businessUnit.allocationLimit.priceExclVat.amount,
                    currency: get(partner, 'preferences.currency.code'),
                },
            } : null,
            allocationQuota: businessUnit.allocationQuota ? {
                count: businessUnit.allocationQuota.count,
                priceExclVat: {
                    amount: businessUnit.allocationQuota.priceExclVat.amount,
                    currency: get(partner, 'preferences.currency.code'),
                },
                contractPriceExclVat: Math.round((businessUnit.allocationQuota.priceExclVat.amount * Number(get(partnerContract, 'bindingPeriodMonths')) / Number(get(partnerContract, 'billingFrequencyMonths')) + Number.EPSILON ) * 100) / 100,
            } : null,
            id: businessUnit.id,
            geoRegions: businessUnit.geoRegions.map(id => ({ id })),
            industries: businessUnit.industries.map(id => ({ id })),
            clientTypes: businessUnit.clientTypes.map(id => ({ id })),
            sectors: businessUnit.sectors.map(id => ({ id })),
            displayName: businessUnit.displayName,
            partnerBusinessUnitGroupingId: businessUnit.partnerBusinessUnitGroupingId,
            geo: {
                address: businessUnit.geo.address,
                zipCode: businessUnit.geo.zipCode,
                cityName: businessUnit.geo.cityName,
                point: businessUnit.geo.point,
            },
            revenueSegment: {
                id: businessUnit.revenueSegment ? Number(get(businessUnit, 'revenueSegment.id')) : null,
            },
        }))
            : [ makeBusinessUnitObject(partner) ],
    }),
    handleSubmit: async (values, bag) => {
        try {
            const contractResponse: any = await bag.props.createPartnerContract(bag.props.match.params.partnerId, {
                ...values,
                allocationFrequencyMonths: values.bindingPeriodMonths,
            })

            bag.props.replace(`/partners/${bag.props.match.params.partnerId}/contracts/${contractResponse.result}`)
        } catch (err) {
            console.log(err)
            toastr.error('Error!', err.message)
        }
    },
    validationSchema,
})
export default class ContractDetailPage extends PureComponent<Model & FormikProps<FormValues>, State> {
    state = {
        editMode: false,
        isLimitedEdit: false,
        signedContract: null,
        uploadingSignedContract: false,
        yearlyContractValue: 0,
    }

    async componentDidMount() {
        const {
            match: {
                params: {
                    partnerId,
                    contractId,
                },
            },
            location: { hash },
        } = this.props

        // hash
        // await this.props.replace(location.pathname + '#' + tabIndex)

        const partnerResponse = await this.props.getPartner(partnerId) as any
        const partner = partnerResponse.entities.partners[partnerResponse.result]
        const geoCode = partner.geo.code

        if (contractId) {
            await this.props.getPartnerContract(partnerId, contractId)
        }

        Promise.all([
            this.props.getIndustries({ parentIndustryId: partner.industry.id }),
            this.props.getSectors({ geoCode, sort: 'display_name' }),
            this.props.getGeoRegions({ geoCode }),
            this.props.getLeadTypes(geoCode),
            this.props.getSegmentationSubGeoRegions({}),
            this.props.getRevenueSegments(geoCode, partner.industry.id),
        ])

        this.setState({
            yearlyContractValue: this.getYearlyValueFromProps(this.props),
        })
    }

    componentDidUpdate(prevProps) {
        if (get(this.props, 'partnerContract.id') !== get(prevProps, 'partnerContract.id')) {
            this.setViewMode()
        } else if (this.isInEditMode) {
            const prevValue = this.getYearlyValueFromProps(prevProps)
            const currentValue = this.getYearlyValueFromProps(this.props)
            if (prevValue !== currentValue) {
                this.setState({
                    yearlyContractValue: currentValue,
                })
            }
        }
    }

    onEditSegmentation = async () => {
        await this.setEditMode()
    }

    onConfigureSegmentation = async () => {
        await this.setEditMode(true)
    }

    onAcceptContractForPartner = async () => {
        const partnerId = this.props.partner.id
        const partnerContractId = this.props.partnerContract.id

        this.props.showModal({
            partnerId,
            partnerContractId,
            name: 'acceptContractForPartner',
            headerText: 'Accept contract',
            bodyText: <h3 style={{ margin: 0 }}>
                You are about to accept a contract on behalf of a partner.
                <br />
                This action cannot be undone, would you like to proceed?
            </h3>,
            btnApproveText: 'Yes, accept contract',
            btnDenyText: 'No, cancel',
            btnApproveModifiers: [ 'danger' ],
        })
    }

    onCancelContractForPartner = async () => {
        const partnerContractId = this.props.partnerContract.id
        const partnerId = this.props.partnerContract.partner.id

        this.props.showModal({
            partnerId,
            partnerContractId,
            name: 'cancelPartnerContractNormally',
            headerText: 'Cancel contract',
            bodyText: <h3 style={{ margin: 0 }}>
                You are about to cancel partner's contract.
                <br />
                This action cannot be undone, would you like to proceed?
            </h3>,
            btnApproveText: 'Yes, cancel contract',
            btnDenyText: 'No, go back',
            btnApproveModifiers: [ 'danger' ],
        })
    }

    onTerminateContractForPartner = async () => {
        const partnerContractId = this.props.partnerContract.id
        const partnerId = this.props.partnerContract.partner.id

        this.props.showModal({
            partnerId,
            partnerContractId,
            name: 'cancelPartnerContractImmediately',
        })
    }

    onPauseContractForPartner = async () => {
        const partnerId = this.props.partnerContract.partner.id
        const partnerContractId = this.props.partnerContract.id

        this.props.showModal({
            partnerId,
            partnerContractId,
            name: 'pausePartnerContract',
        })
    }

    onBoostContractForPartner = async () => {
        const partnerId = this.props.partnerContract.partner.id
        const partnerContractId = this.props.partnerContract.id

        this.props.showModal({
            partnerId,
            partnerContractId,
            name: 'boostPartnerContract',
        })
    }

    async setEditMode(isLimited: boolean = false) {
        await this.setState({
            editMode: true,
            isLimitedEdit: isLimited,
        })
    }

    async setViewMode() {
        await this.setState({
            editMode: false,
            isLimitedEdit: false,
        })
    }

    get isInEditMode() {
        return this.state.editMode || !this.props.partnerContract
    }

    isEditAllowed(): boolean {
        return !this.props.partnerContract.acceptedAt || this.props.hasFullPartnerContractEditRights
    }

    isConfigureAllowed(): boolean {
        return !this.isEditAllowed() && !this.props.partnerContract.canceledAt
    }

    get isInLimitedEditMode() {
        return this.state.isLimitedEdit
    }

    get notInEditMode() {
        return ! this.isInEditMode
    }

    onGoBack = async () => {
        await this.setViewMode()
        await this.props.resetForm()
    }

    removeBusinessUnit = async index => {
        const values = {
            ...this.props.values,
            businessUnits: [ ...this.props.values.businessUnits ],
        }
        values.businessUnits.splice(index, 1)

        await this.props.setValues(values)
    }

    hasPPSModel(unit) {
        return Boolean(unit.allocationLimit)
    }

    hasLBModel(unit) {
        return Boolean(unit.allocationQuota)
    }

    calculateBusinessUnitPrice = businessUnitIndex => {
        return (partnerContract, businessUnit) => {
            const businessUnitContractValue = get(businessUnit, 'allocationQuota.contractPriceExclVat')

            if (!businessUnitContractValue && typeof businessUnitContractValue !== 'number') {
                return { amount: 0, currency: get(this.props.partner, 'preferences.currency.code') }
            }

            const quotaValue = Number(businessUnitContractValue * partnerContract.billingFrequencyMonths / partnerContract.bindingPeriodMonths)

            if (Number(get(businessUnit, 'allocationQuota.priceExclVat.amount')) != quotaValue) {
                this.props.setFieldValue(`businessUnits.${businessUnitIndex}.allocationQuota.priceExclVat.amount` as any, quotaValue)
            }

            return { amount: quotaValue, currency: get(this.props.partner, 'preferences.currency.code') }
        }
    }

    getYearlyValueFromProps = props => {
        const { values } = props
        if (!values || !values.businessUnits.length) {
            return 0
        }
        return this.calculateBusinessUnitsYearlyValue(values.businessUnits, values.billingFrequencyMonths)
    }

    calculateBusinessUnitsYearlyValue = (businessUnits, billingFrequencyMonths) => {
        return businessUnits.reduce((agg, cur) => {
            if (!cur.allocationQuota) {
                return agg
            }

            agg += get(cur.allocationQuota, 'priceExclVat.amount', 0)
            return agg
        }, 0) / billingFrequencyMonths * 12
    }

    setRevenueSegmentId = businessUnitIndex => {
        return event => {
            const selectedValue = get(event, 'target.value')
            if (selectedValue) {
                this.props.setFieldValue(`businessUnits.${businessUnitIndex}.revenueSegment.id` as any, selectedValue)
            }
        }
    }

    getSelectedSubRegions(businessUnit) {
        return (businessUnit.geoRegions || [])
            .map(gr => (
                Object.values(this.props.subGeoRegions).filter(
                    sgr => sgr.segmentationGeoRegionId === gr.id
                ))
            ).flat()
    }

    selectFile = (event) => {
        const file = event.target.files[0]

        if (file.type !== 'application/pdf') {
            toastr.error('You can only upload PDF files')
            return
        }

        this.setState({ signedContract: file })
    }

    uploadSignedContract = async () => {
        if (! this.state.signedContract) {
            toastr.error('No signed contract provided')
            return
        }

        this.setState({ uploadingSignedContract: true })

        const pdfContent = await toBase64(this.state.signedContract)
        await this.props.uploadSignedPartnerContract(
            this.props.partnerContract.partner.id,
            this.props.partnerContract.id,
            pdfContent
        )
        toastr.success('Signed contract has been uploaded')
        this.setState({ signedContract: null, uploadingSignedContract: false })
    }

    render() {
        const {
            subscriptionInfo: {
                createdSubscription,
            },
            clientTypes,
            errors,
            geoRegions,
            industries,
            partner,
            partnerContract,
            values,
            sectors,
            setFieldValue,
            revenueSegments,
            canCreateLowPriceContract,
        } = this.props

        if (!this.props.partner) {
            return <LoaderComponent type="logo" />
        }

        const permissionCheck = (months) => {
            if (Number(months) === 9) {
                return true
                return partner.geo.code === 'se'
            }
            if (parseInt(months, 10) < SHORT_BINDING_PERIOD_MONTHS_MAX) {
                return Boolean(this.props.createShortContractPermission)
            }
            return true
        }

        const activeContractPause = partnerContract && partnerContract.allocationPauses && partnerContract.allocationPauses.find(pause => moment(pause.startsAt).isBefore(moment()) && moment(pause.endsAt).isAfter(moment()))
        const activeContractBoost = partnerContract && partnerContract.allocationBoosts && partnerContract.allocationBoosts.find(boost => moment(boost.startsAt).isBefore(moment()) && moment(boost.endsAt).isAfter(moment()))

        const totalPriceStyle = {
            color: '#8F8F8F',
            display: 'inline-block',
            marginLeft: '8px',
        }

        const buIdStyle = {
            color: '#8F8F8F',
            display: 'inline-block',
            marginRight: '8px',
        }

        const currentYearlyValue = partnerContract ? this.calculateBusinessUnitsYearlyValue(partnerContract.businessUnits, partnerContract.billingFrequencyMonths) : 0

        const geoCode = partner.geo.code
        const sortedSectors = sortObjectWithLanguageSensitivity(sectors, 'displayName', geoCode)

        const saveButtonDisabled = Boolean(!canCreateLowPriceContract && partnerContract && partnerContract.acceptedAt && currentYearlyValue && currentYearlyValue - this.state.yearlyContractValue > 0)

        return <div>
            <PartnerHeader
                partner={partner}
                subscription={createdSubscription}
                isRenderedByPartnerPage={false}
            />
            <Form>
                <Card margin="0">
                    <Card.Header padding="0 20px" modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                        <strong>
                            Contract
                            <span style={totalPriceStyle}>
                                Total contract value:
                                {' '}
                                <FormattedAmount
                                    amount={{
                                        currency: get(partner, 'preferences.currency.code'),
                                        amount: values.businessUnits.reduce(
                                            (carry, item) => carry + get(item, 'allocationQuota.contractPriceExclVat', 0),
                                            0
                                        ),
                                    }}
                                    format={'0,0.00'}
                                />
                            </span>
                        </strong>
                    </Card.Header>
                    <Card.Content>
                        <Fragment>
                            {this.isInEditMode && !this.isInLimitedEditMode &&
                            <Fragment>
                                <Row>
                                    <Col md={4}>
                                        <Field
                                            label="Delivery period"
                                            component={SelectInputGroup}
                                            name="bindingPeriodMonths"
                                            returnId={true}
                                            onChange={event => {
                                                const value = Number(get(event, 'target.value'))
                                                setFieldValue('bindingPeriodMonths', value)
                                                setFieldValue('cancellationPeriodMonths', Math.min(value, Number(values.cancellationPeriodMonths)))
                                            }}
                                        >
                                            {Object.entries(bindingPeriod).filter(([ months, description ]) => permissionCheck(months)).map(([ key, value ]) => (
                                                <option value={key}>{value}</option>
                                            ))}
                                        </Field>
                                    </Col>

                                    <Col md={4}>
                                        <Field
                                            label="Billing frequency"
                                            component={SelectInputGroup}
                                            name="billingFrequencyMonths"
                                            returnId={true}
                                        >
                                            {Object.entries(billingFrequency).filter(([ key, value ]) => (key <= values.bindingPeriodMonths) && (Number(values.bindingPeriodMonths) % Number(key) === 0)).map(([ key, value ]) => (
                                                <option value={key}>{value}</option>
                                            ))}
                                        </Field>
                                    </Col>

                                    <Col md={4}>
                                        <Field
                                            label="Cancel with renewal"
                                            component={SelectInputGroup}
                                            name="cancellationPeriodMonths"
                                            returnId={true}
                                            onChange={event => {
                                                const value = Number(get(event, 'target.value'))
                                                setFieldValue('cancellationPeriodMonths', value)
                                                setFieldValue('bindingPeriodMonths', Math.max(Math.min(...Object.keys(bindingPeriod).map(month => Number(month)).filter(month => value < month)), Number(values.bindingPeriodMonths)))
                                            }}
                                        >
                                            {Object.entries(cancellationPeriod).map(([ key, value ]) => (
                                                <option value={key}>{value}</option>
                                            ))}
                                        </Field>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <Field
                                            label="Starts at"
                                            name="startsAt"
                                            dateFormat="YYYY-MM"
                                            component={DateTimePickerGroup}
                                        />
                                    </Col>
                                </Row>
                            </Fragment>
                            }
                            {this.isInEditMode ||
                            <Fragment>
                                <div>
                                    <MetaLabel>Delivery period</MetaLabel>
                                    <p>{bindingPeriod[partnerContract.bindingPeriodMonths]}</p>
                                </div>
                                <div>
                                    <MetaLabel>Billing frequency</MetaLabel>
                                    <p>{billingFrequency[partnerContract.billingFrequencyMonths]}</p>
                                </div>
                                <div>
                                    <MetaLabel>Cancellation period</MetaLabel>
                                    <p>{cancellationPeriod[partnerContract.cancellationPeriodMonths]}</p>
                                </div>
                                <div>
                                    <MetaLabel>Starts at</MetaLabel>
                                    <p>{moment(partnerContract.startsAt).format('YYYY-MM')}</p>
                                </div>
                                <div>
                                    <MetaLabel>Signed contract</MetaLabel>
                                    {partnerContract.hasOwnProperty('signedAt') && <div>
                                        <p>
                                            Contract was signed at
                                            {moment(partnerContract.signedAt).format('YYYY-MM-DD HH:mm:ss')}
                                        </p>
                                    </div>}
                                    <div>
                                        {this.props.hasUploadSignedPartnerContractPermission && <Fragment>
                                            <input type="file" onChange={this.selectFile} disabled={this.state.uploadingSignedContract} style={{ display: 'inline-block' }}/>
                                            <Button modifiers="secondary" onClick={this.uploadSignedContract} disabled={this.state.uploadingSignedContract} style={{ display: 'inline-block' }}>Upload signed contract</Button>
                                        </Fragment>}
                                        {! this.props.hasUploadSignedPartnerContractPermission && <p>
                                            Contract is not signed
                                        </p>}
                                    </div>
                                </div>
                            </Fragment>
                            }
                        </Fragment>
                        {this.isInLimitedEditMode && this.isInEditMode && <div className="alert alert-warning" style={{ marginBottom: '0' }}>Contract configuration mode. Here you can only configure sectors and geo regions on already existing business units.</div>}
                    </Card.Content>
                </Card>

                {this.isInEditMode && values.businessUnits.map((businessUnit, businessUnitIndex) => (
                    <Card margin="20px 0 20px 0">
                        {this.isInLimitedEditMode && <Card.Header>
                            <span style={buIdStyle}>
                                #
                                {businessUnit.id}
                            </span>
                            <strong>{businessUnit.displayName}</strong>
                        </Card.Header>}
                        <Card.Content>
                            {!this.isInLimitedEditMode && <RemoveButton modifiers="action" onClick={() => this.removeBusinessUnit(businessUnitIndex)}>x</RemoveButton>}
                            <EditMode
                                isLimited={this.isInLimitedEditMode}
                                clientTypes={clientTypes}
                                geoRegions={geoRegions}
                                sectors={sortedSectors}
                                industries={industries}
                                hasLBModel={this.hasLBModel(businessUnit)}
                                hasPPSModel={this.hasPPSModel(businessUnit)}
                                businessUnit={businessUnit}
                                partner={partner}
                                revenueSegments={revenueSegments}
                                index={businessUnitIndex}
                                businessUnitQuotaPrice={this.calculateBusinessUnitPrice(businessUnitIndex)(values, businessUnit)}
                                setRevenueSegmentId={this.setRevenueSegmentId(businessUnitIndex)}
                                errors={errors}
                            />
                        </Card.Content>
                    </Card>
                ))}

                {!this.isInEditMode && values.businessUnits.map(businessUnit => (
                    <Card margin="20px 0 20px 0">
                        <Card.Content>
                            <PreviewMode
                                clientTypes={clientTypes}
                                geoRegions={geoRegions}
                                sectors={sectors}
                                industries={industries}
                                businessUnit={businessUnit}
                                revenueSegment={get(partnerContract.businessUnits.find(bu => bu.id === businessUnit.id), 'revenues')}
                                hasLBModel={this.hasLBModel(businessUnit)}
                                subGeoRegions={this.getSelectedSubRegions(businessUnit)}
                            />
                        </Card.Content>
                    </Card>
                ))}

                <ActionButtonBlock>
                    <LoadingBar />
                    {this.isInEditMode && <ButtonGrid>
                        <ButtonContainer>
                            <ButtonGroup>
                                <Button modifiers="secondary" onClick={this.onGoBack}>Cancel</Button>
                                <Button
                                    modifiers={[ 'action', 'mL_2' ]}
                                    type="submit"
                                    disabled={saveButtonDisabled}
                                >
                                    Save contract
                                </Button>
                            </ButtonGroup>
                        </ButtonContainer>
                        {this.isInEditMode && partnerContract && partnerContract.acceptedAt && <div>
                            {Math.round((currentYearlyValue - this.state.yearlyContractValue) * 100) / 100 > 0 &&
                            <div className="alert alert-danger">
                                Missing
                                {' '}
                                {(currentYearlyValue - this.state.yearlyContractValue).toFixed(2)}
                                {' '}
                                {config.currency[partner.geo.code]}
                                {' '}
                                in yearly value
                            </div>}
                            {currentYearlyValue - this.state.yearlyContractValue <= 0 && <div className="alert alert-success">
                                Current contract's yearly value (
                                {this.state.yearlyContractValue}
                                {' '}
                                {config.currency[partner.geo.code]}
                                ) is more than minimum allowed (
                                {currentYearlyValue}
                                {' '}
                                {config.currency[partner.geo.code]}
                                )
                            </div>}
                        </div>}
                        {!this.isInLimitedEditMode && <ButtonContainer>
                            <ButtonGroup style={buttonGroupStyle}>
                                <FieldArray
                                    name="businessUnits"
                                    render={arrayHelpers => (
                                        <Button
                                            className="pull-right"
                                            modifiers="action"
                                            onClick={async () => { await arrayHelpers.push(makeBusinessUnitObject(partner))}}
                                        >
                                            Add new business unit
                                        </Button>
                                    )}
                                />
                            </ButtonGroup>
                        </ButtonContainer>}
                    </ButtonGrid>}

                    {this.notInEditMode && <ButtonGrid>
                        <ButtonContainer>
                            <Flex modifiers={[ 'alignCenter' ]}>
                                <ButtonGroup>
                                    {this.isEditAllowed() && <Button modifiers="secondary" onClick={this.onEditSegmentation}>Edit contract</Button>}
                                    {this.isConfigureAllowed() && <Button modifiers="secondary" onClick={this.onConfigureSegmentation}>
                                        Configure contract
                                    </Button>}
                                    {this.props.acceptForPartnerPermission && ! partnerContract.acceptedAt && <Button modifiers="action" onClick={this.onAcceptContractForPartner}>
                                        Accept contract for partner
                                    </Button>}
                                    {partnerContract.acceptedAt && <Button modifiers="secondary" onClick={this.onPauseContractForPartner}>
                                        Manage pauses
                                    </Button>}
                                    {partnerContract.acceptedAt && <Button modifiers="secondary" onClick={this.onBoostContractForPartner}>
                                        Manage boosts
                                    </Button>}
                                    {partnerContract.acceptedAt && ! partnerContract.canceledAt && <Button modifiers="danger" onClick={this.onCancelContractForPartner}>
                                        Cancel contract
                                    </Button>}
                                    {partnerContract.acceptedAt && this.props.canTerminateContracts && <Button modifiers="danger" onClick={this.onTerminateContractForPartner}>
                                        Terminate contract
                                    </Button>}
                                </ButtonGroup>
                                {activeContractPause && <Card.Text modifiers={[ 'mL_3', 'mB_0' ]}>
                                    Contract paused:
                                    {' '}
                                    <FormattedDate date={activeContractPause.startsAt} />
                                    {' '}
                                    -
                                    {' '}
                                    <FormattedDate date={activeContractPause.endsAt} />
                                </Card.Text>}
                                {activeContractBoost && <Card.Text modifiers={[ 'mL_3', 'mB_0' ]}>
                                    Contract boosted:
                                    {' '}
                                    <FormattedDate date={activeContractBoost.startsAt} />
                                    {' '}
                                    -
                                    {' '}
                                    <FormattedDate date={activeContractBoost.endsAt} />
                                </Card.Text>}
                            </Flex>
                        </ButtonContainer>
                    </ButtonGrid>}
                </ActionButtonBlock>
            </Form>
        </div>
    }
}
