import React, { PureComponent, ComponentType } from 'react'
import { connect } from '../../../utils/connect'
import { toastr } from 'react-redux-toastr'
import camelCase from 'lodash/camelCase'
import { Card } from '../../blocks/Card'
import { createPartnerAssignee, deletePartnerAssignee, getPartnerAssignees } from '../../../actions/partnerAssignees'
import { getEmployees } from '../../../actions/employees'
import { Flex } from '../../elements/Flex'
import { Button } from '../../elements/Button'
import LoaderComponent from '../../elements/LoaderComponent'
import { Col, Row } from 'react-bootstrap'
import PartnerAssigneeCreateForm, { assigneeRoles } from './PartnerAssigneeCreateForm'

interface StateProps {
    employees,
    partnerAssignees
}

interface DispatchProps {
    getPartnerAssignees: typeof getPartnerAssignees
    getEmployees: typeof getEmployees
    createPartnerAssignee: typeof createPartnerAssignee
    deletePartnerAssignee: typeof deletePartnerAssignee
}

interface PathProps {
    partnerId: number
    geoCode: string
}

type PartnerFeaturesModel = StateProps & DispatchProps & PathProps

@connect<StateProps, DispatchProps, PathProps>(
    (state, props) => ({
        employees: state.entities.employees,
        partnerAssignees: state.entities.partnerAssignees,
    }),
    {
        getPartnerAssignees,
        getEmployees,
        createPartnerAssignee,
        deletePartnerAssignee,
    }
)
class PartnerAssignees extends PureComponent<PartnerFeaturesModel> {
    state = {
        isLoading: false,
        assigneeIds: [],
        employeeIds: [],
    }

    async componentDidMount() {
        this.listAssignees()
        this.listEmployees()
    }

    listEmployees = async () => {
        const res: any = await this.props.getEmployees({ geoCode: this.props.geoCode, })
        this.setState({ employeeIds: res.result })
    }

    listAssignees = async () => {
        await this.setState({ isLoading: true })
        const res: any = await this.props.getPartnerAssignees(this.props.partnerId)

        await this.setState({
            assigneeIds: res.result,
            isLoading: false,
        })
    }

    createAssignee = async data => {
        try {
            await this.props.createPartnerAssignee(this.props.partnerId, data)
            await this.listAssignees()
        } catch (e) {
            toastr.error('Error!', 'Could not assign employee')
        }
    }

    removeAssignee = async partnerAssigneeId => {
        try {
            await this.props.deletePartnerAssignee(this.props.partnerId, partnerAssigneeId)
            await this.listAssignees()
        } catch (e) {
            toastr.error('Error!', 'Could not un-assign employee')
        }
    }

    render() {
        const { assigneeIds, employeeIds, isLoading } = this.state
        const { employees, partnerAssignees } = this.props

        return <Card>
            <Card.Header>Partner assignees</Card.Header>
            <Card.Content>
                {isLoading && <Flex modifiers={[ 'justifyCenter' ]}><LoaderComponent /></Flex>}
                {!isLoading && !Boolean(assigneeIds.length) && <PartnerAssigneeCreateForm
                    submit={this.createAssignee}
                    options={employeeIds
                        .map(employeeId => employees[employeeId])
                        .filter(employee => employee.isActive)
                        .map(employee => ({ id: employee.id, name: employee.name }))
                    }
                />}
                {!isLoading && assigneeIds.map(assigneeId => <div key={partnerAssignees[assigneeId]}>
                    <Row>
                        <Col md={2}>
                            <Card.Text modifiers="mY_1">{partnerAssignees[assigneeId].assigneeInfo.name}</Card.Text>
                        </Col>
                        <Col md={8}>
                            <Card.Label modifiers="mY_1">{assigneeRoles[camelCase(partnerAssignees[assigneeId].assigneeRole)] || 'Unset'}</Card.Label>
                        </Col>
                        <Col md={2}>
                            <Button
                                modifiers={[ 'danger', 'btnSm', 'mY_1' ]}
                                onClick={() => this.removeAssignee(assigneeId)}
                            >
                                Un-assign
                            </Button>
                        </Col>
                    </Row>
                    <Card.Line />
                </div>)}
            </Card.Content>
        </Card>
    }
}

export default PartnerAssignees as unknown as ComponentType<PathProps>
