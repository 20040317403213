import React, { ComponentType, PureComponent } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Field, submit } from 'redux-form'
import { hideModal } from '../../actions/modal'
import { getPaymentCards } from '../../actions/paymentCards'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { Card } from '../blocks/Card'
import { PackageTypes } from '../../reducers/types/entities/PackageType'
import { createPartnerSubscription } from '../../actions/subscriptions'
import { Button } from '../elements/Button'
import styled from 'styled-components'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import ReduxForm from '../elements/forms/ReduxForm'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { PaymentSolution } from '../../reducers/types/entities/PaymentSolution'
import moment from 'moment'
import { Column, Table, Header, Cell, AmountCell } from '../elements/table'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import { PaymentCards } from '../../reducers/types/entities/PaymentCard'
const required = [ value => value ? undefined : 'Required!' ]

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 820px;
    }
`

interface SubscriptionCreateModel {
    partner: Partner
    packageTypes: PackageTypes
    paymentCards: PaymentCards
    modalProps: {
        paymentSolutions: PaymentSolution[]
        partnerId: number
    }
    createPartnerSubscription: typeof createPartnerSubscription
    getPaymentCards: typeof getPaymentCards
    hideModal: typeof hideModal
    submit: typeof submit
}
@connect(
    state => ({
        partner: state.entities.partners[state.modal.partnerId],
        packageTypes: state.entities.packageTypes,
        paymentCards: state.entities.paymentCards,
        modalProps: state.modal,
    }),
    {
        createPartnerSubscription,
        getPaymentCards,
        hideModal,
        submit,
    }
)
class SubscriptionCreateModal extends PureComponent<SubscriptionCreateModel> {
    state = {
        paymentCardIds: [],
        sPackage: null
    }

    async componentDidMount() {
        const response = await this.props.getPaymentCards({
            partnerId: this.props.modalProps.partnerId,
        }) as unknown as { result: number[] }
        this.setState({
            paymentCardIds: response.result,
        })
    }

    get preferredPaymentCard() {
        return this.state.paymentCardIds.map(id => this.props.paymentCards[id]).find(card => card.isPreferred)
    }

    hideModal = () => this.props.hideModal()

    save = sPackage => {
        this.setState({ sPackage }, () =>
            this.props.submit('createPartnerSubscription')
        )
    }

    createPartnerSubscription = data => {
        const monthlyPrice = (data.monthlyPriceAmount && this.state.sPackage.name === 'Ageras Marketing Package')
            ? { amount: data.monthlyPriceAmount, currency: this.state.sPackage.monthlyPrice.currency }
            : this.state.sPackage.monthlyPrice

        const endOfSelectedMonth = moment(data.startsAt).endOf('month').format('YYYY-MM-DD HH:mm:ss')

        const body = {
            packageId: this.state.sPackage.id,
            startsAt: data.startsAt,
            expiresAt: endOfSelectedMonth,
            extensionPeriodMonths: data.extensionPeriodMonths,
            monthlyPrice,
        }

        this.props.createPartnerSubscription(this.props.modalProps.partnerId, body)
    }

    render() {
        const {
            packageTypes,
            partner,
        } = this.props

        return <LgModal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>
                Manage packages for
                {partner.companyName}
            </Modal.Header>
            <Modal.Body>
                <ReduxForm form="createPartnerSubscription" onSubmit={this.createPartnerSubscription}>
                    <Row>
                        <Col md={8}>Preferred payment method</Col>
                        <Col md={4}>
                            {this.preferredPaymentCard &&
                            `${this.preferredPaymentCard.cardName} (XXXX XXXX XXXX ${this.preferredPaymentCard.cardMask.replace(/X/g, '')})`
                            }
                            {Boolean(this.preferredPaymentCard) || 'Manual'}
                        </Col>
                    </Row>
                    <Card.Line modifiers="mY_2"/>
                    <Row>
                        <Col md={8}>
                            Override price 
                            {' '}
                            <br />
                            <i>(only possible for Ageras Marketing Package)</i>
                        </Col>
                        <Col md={4}>
                            <Field
                                name="monthlyPriceAmount"
                                component={FieldGroup}
                                removeClassFormGroup={true}
                                type="number"
                            />
                        </Col>
                    </Row>
                    <Card.Line modifiers="mY_2"/>
                    <Row>
                        <Col md={8}>
                            Choose when to start the subscription 
                            {' '}
                            <br />
                            <i>(only possible for Ageras Marketing Package)</i>
                        </Col>
                        <Col md={4}>
                            <Field
                                component={DateTimePickerGroup}
                                name="startsAt"
                            />
                        </Col>
                    </Row>
                    <Card.Line modifiers="mY_2"/>
                    <Row>
                        <Col md={8}>
                            Choose how many months the subscription should renew for
                            <br />
                        </Col>
                        <Col md={4}>
                            <Field
                                component={SelectInputGroup}
                                name="extensionPeriodMonths"
                                returnId={true}
                            >
                                <option>1</option>
                                <option>3</option>
                                <option>6</option>
                                <option>12</option>
                            </Field>
                        </Col>
                    </Row>
                    <Card.Line modifiers="mY_2"/>
                    <Table modifiers="splitByRow">
                        <Header>
                            <Column>Name</Column>
                            <Column className="text-right">Monthly price</Column>
                            <Column className="text-right">Action</Column>
                        </Header>
                        <tr>
                            <Cell>Free</Cell>
                            <AmountCell amount={{
                                amount: 0,
                                currency: '',
                            }}
                            />
                            <Cell className="text-right">Active</Cell>
                        </tr>
                        {Object.values(packageTypes).map(el =>
                            <tr key={`packageTypes_${el.id}`}>
                                <Cell>{el.name}</Cell>
                                <AmountCell amount={el.monthlyPrice} />
                                <Cell className="text-right">
                                    <Button
                                        onClick={() => this.save(el)}
                                        modifiers={[ 'action', 'pY_0' ]}
                                    >
                                        Sell package
                                    </Button>
                                </Cell>
                            </tr>
                        )}
                    </Table>
                </ReduxForm>
            </Modal.Body>
        </LgModal>
    }
}

export default SubscriptionCreateModal as unknown as ComponentType
