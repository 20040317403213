import styled from 'styled-components'
import { borderColor, metaTextColor } from '../../../utils/variables'

const PinnedNotesTop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 34px;

    &:before {
        content: 'Pinned notes';
        position: absolute;
        z-index: 3;
        display: inline-block;
        padding: 0 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        text-transform: uppercase;
        color: ${metaTextColor};
        background-color: #fff;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        background-color: ${borderColor};
    }
`

export default PinnedNotesTop
