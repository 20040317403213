import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import {
    GET_LEAD_SEGMENTATION_SUB_GEO_REGIONS,
    GET_SEGMENTATION_SUB_GEO_REGIONS,
    UPDATE_LEAD_SEGMENTATION_SUB_GEO_REGIONS,
} from './actions'

const segmentationSubGeoRegions = combineReducers({
    all: generalSubjectReducer(GET_SEGMENTATION_SUB_GEO_REGIONS),
    lead: generalSubjectReducer(GET_LEAD_SEGMENTATION_SUB_GEO_REGIONS, UPDATE_LEAD_SEGMENTATION_SUB_GEO_REGIONS),
})

export default segmentationSubGeoRegions
