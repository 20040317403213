import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_PAUSE_PARTNER_SUBSCRIPTIONS = actionTypesCreator('pauseSubscriptions', 'GET_PAUSE_PARTNER_SUBSCRIPTIONS')

export const CREATE_PAUSE_PARTNER_SUBSCRIPTIONS = actionTypesCreator('pauseSubscription', 'CREATE_PAUSE_PARTNER_SUBSCRIPTIONS')

export const getPausePartnerSubscription = (
    partnerId: number,
    packageId: number
) => ({
    [CALL_API]: {
        types: GET_PAUSE_PARTNER_SUBSCRIPTIONS,
        endpoint: `/partners/${partnerId}/subscriptions/${packageId}/pauses`,
        schema: schemas.pauseSubscriptions,
    },
})

interface PausePartnerSubscription {
    startsAt: string
    endsAt: string
}

export const createPausePartnerSubscription = (
    partnerId: number,
    packageId: number,
    body: PausePartnerSubscription
) => ({
    [CALL_API]: {
        types: CREATE_PAUSE_PARTNER_SUBSCRIPTIONS,
        endpoint: `/partners/${partnerId}/subscriptions/${packageId}/pauses`,
        schema: schemas.pauseSubscription,
        options: {
            method: 'post',
            body,
        },
    },
})
