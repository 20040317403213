import React, { ComponentType, PureComponent } from 'react'
import { savePartner, createPartnerAction } from '../../../actions/partners'
import { Card } from '../../blocks/Card'
import { Button } from '../../elements/Button'
import { Flex } from '../../elements/Flex'
import { Partner } from '../../../reducers/types/entities/Partner'
import { connect } from '../../../utils/connect'
import get from 'lodash/get'

interface DispatchPropsDemo {
    createPartnerAction: typeof createPartnerAction
    savePartner: typeof savePartner
}

interface PathPropsDemoControlsCard {
    partner: Partner
}

type DemoControlsCardModel = DispatchPropsDemo & PathPropsDemoControlsCard

@connect<null, DispatchPropsDemo, any>(null, {
    createPartnerAction,
    savePartner,
})
class DemoControlsCard extends PureComponent<DemoControlsCardModel> {

    notInterested = () => {
        let reason
        if (reason = prompt('Please enter the reason why the demo is not interested in becoming a partner')) {
            this.props.createPartnerAction(
                this.props.partner.id,
                {
                    action: 'not-interested',
                    note: reason,
                },
            )
        }
    }

    toggleLeadsSection = () => {
        const partner = this.props.partner
        this.props.savePartner({
            id: this.props.partner.id,
            demo: {
                leadExtensionPeriod: get(partner, 'demo.leadExtensionPeriod'),
                hasLeadAccess: !get(partner, 'demo.hasLeadAccess'),
            },
        })
    }

    makePartner = () => {
        this.props.createPartnerAction(
            this.props.partner.id,
            { action: 'make-partner' },
        )
    }

    render() {
        const { partner } = this.props

        return (
            <Card>
                <Card.Header>Demo status</Card.Header>
                <Card.Content>
                    <Flex>
                        <Button modifiers={[ 'action', 'fullWidth', 'mR_2' ]} onClick={this.toggleLeadsSection}>
                            {partner.demo && partner.demo.hasLeadAccess ? 'Disable' : 'Enable'}
                            {' '}
                            leads section
                        </Button>
                        <Button modifiers={[ 'action', 'fullWidth', 'mR_2' ]} onClick={this.makePartner}>Make partner</Button>
                        <Button modifiers={[ 'danger', 'fullWidth' ]} onClick={this.notInterested}>Not interested</Button>
                    </Flex>
                </Card.Content>
            </Card>
        )
    }
}

export default DemoControlsCard as unknown as ComponentType<PathPropsDemoControlsCard>
