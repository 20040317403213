import { createSelector } from 'reselect'
import { invoicesByPartnerSelector } from './invoices'
import { partnerUsersByPartnerSelector } from '../state/partnerUsers/selectors'

export const permissionsSelector = state => state.auth.permissions

export const canCreateInvoiceSelector = createSelector(
    (state, partnerId) => state.entities.partners[partnerId],
    invoicesByPartnerSelector,
    partnerUsersByPartnerSelector,
    (partner, invoicesByPartner, partnerUsersByPartner) => {
        return !invoicesByPartner.find((i: {status: string}) => i.status === 'overdue') &&
            partnerUsersByPartner.length &&
            partner &&
            partner.geo.address &&
            partner.geo.zipCode &&
            !!partner.geo.cityName
    }
)

export const hasFullPartnerContractEditRights = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_contract_edit_admin')
)

export const canTerminateContract = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_contract_actions_cancel_imediately')
)

export const canCreateLowPriceContract = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_contracts_set_low_price')
)

export const hasActionMarkLeadAsUntreatedRight = createSelector(
    permissionsSelector,
    permissions => permissions.includes('leads_action_mark_as_untreated')
)
