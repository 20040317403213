import React, { PureComponent } from 'react'
import { Field, destroy, submit as reduxFormSubmit, reset, initialize, SubmissionError } from 'redux-form'
import nl2br from '../../utils/nl2br'
import { addQuoteItem, deleteQuoteItem, getQuote, updateQuote } from '../../actions/quotes'
import { Card } from '../blocks/Card/index'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../elements/Button'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { TextareaGroup } from '../elements/forms/inputs/TextareaGroup'
import { Flex } from '../elements/Flex'
import styled from 'styled-components'
import ReduxForm from '../elements/forms/ReduxForm'
import { getLead } from '../../actions/leads'
import { push } from 'connected-react-router'
import LoaderComponent from '../elements/LoaderComponent'
import ButtonWithIcon from '../blocks/ButtonWithIcon'
import { borderColor, metaTextColor } from '../../utils/variables'
import keydown from 'react-keydown'
import { connect } from '../../utils/connect'
import QuoteItems from './edit-quote-page-components/QuoteItems'
import CreateQuoteItemForm from './edit-quote-page-components/CreateQuoteItemForm'
import { Lead } from '../../reducers/types/entities/Lead'
import { Quote } from '../../reducers/types/entities/Quote'
import { values } from 'lodash'

const ActionButtonBlock = styled.div`
  text-align: center;
  ${Card} {
      display: inline-block;
      ${Flex} > div:last-child {
        border-left: 1px solid ${borderColor};
        margin-left: 20px;
        padding-left: 20px;
        text-align: left;
        div {
          color: ${metaTextColor};
          margin-top: 15px;
          font-weight: bold;
          line-height: 0px;
        }
      }
  }
`

interface EditQuoteStateProps {
    lead: Lead
    quote: Quote
    goBack: () => void
    quoteItemProps: any
}
interface EditQuoteDispatchProps {
    deleteQuoteItem: typeof deleteQuoteItem
    addQuoteItem: typeof addQuoteItem
    updateQuote: typeof updateQuote
    initialize: typeof initialize
    getQuote: typeof getQuote
    getLead: typeof getLead
    submit: typeof reduxFormSubmit
    destroy: typeof destroy
    reset: typeof reset
    push: typeof push
}
interface EditQuoteOwnProps {
    match: {
        params: {
            quoteId: number
            leadId: number
        }
    }
    router: {
        goBack: () => void
    }
}

type EditQuotePageModel = EditQuoteStateProps & EditQuoteDispatchProps & EditQuoteOwnProps

@connect<EditQuoteStateProps, EditQuoteDispatchProps, EditQuoteOwnProps>(
    (state, props) => ({
        lead: state.entities.leads[props.match.params.leadId],
        quote: state.entities.quotes[props.match.params.quoteId],
        goBack: props.router.goBack,
        quoteItemProps: state.form.addQuoteItem ? state.form.addQuoteItem.values : {},
    }),
    {
        submit: reduxFormSubmit,
        deleteQuoteItem,
        addQuoteItem,
        updateQuote,
        initialize,
        getQuote,
        getLead,
        destroy,
        reset,
        push,
    }
)

export default class EditQuotePage extends PureComponent<EditQuotePageModel> {
    state = {
        validationError: null,
    }

    async componentDidMount() {
        const { leadId, quoteId } = this.props.match.params
        await this.props.getLead(leadId)
        this.getQuotes(leadId, quoteId)
    }

    async getQuotes(leadId, quoteId) {
        await this.props.getQuote(leadId, quoteId)
        const { quote } = this.props
        this.props.initialize('addQuoteItem', {
            title: quote.title,
            description: quote.description,
            partnerTerms: quote.partnerTerms,
        })
    }

    validateCreateQuoteItem(data) {
        let error = {}
        if (!data.title)
            error = { title: 'Item title is required' }
        if (!data.amountExclVat || !data.amountExclVat.amount)
            error = { ...error, amountExclVat: { amount: 'Item price is required' } }
        if (!data.type)
            error = { ...error, type: 'Item type is required' }
        if (Object.keys(error).length) {
            this.setState({ validationError: error })
            throw new SubmissionError(error)
        }
        this.setState({ validationError: null })
    }

    createQuoteItem = async data => {
        const { leadId, quoteId } = this.props.match.params
        this.validateCreateQuoteItem(data)
        await this.props.addQuoteItem(leadId, quoteId, data)
        this.getQuotes(leadId, quoteId)
        this.props.reset('addQuoteItem')
    }

    deleteQuoteItem = async itemId => {
        const { leadId, quoteId } = this.props.match.params
        await this.props.deleteQuoteItem(leadId, quoteId, itemId)
        this.getQuotes(leadId, quoteId)
    }

    @keydown('ctrl+s')
    onKeyPress(event) {
        event.preventDefault()
        this.props.submit('quote')
    }

    updateQuote = async data => {
        const { leadId, quoteId } = this.props.match.params
        await this.props.updateQuote(leadId, quoteId, data)
        this.props.goBack()
    }

    saveQuote = () => {
        this.props.submit('quote')
    }

    render() {
        const {
            lead,
            quote,
            submit,
            quoteItemProps,
        } = this.props

        if (!lead || !quote)
            return <LoaderComponent type="logo"/>

        const attributes = values(lead.attributes)

        return (
            <div>
                <Row>
                    <Col sm={6}>
                        <Card>
                            <Card.Header>
                                <b>Edit quote</b>
                            </Card.Header>
                            <Card.Content>
                                <ReduxForm
                                    form="quote"
                                    onSubmit={this.updateQuote}
                                    enableReinitialize={true}
                                    initialValues={{
                                        title: quote.title,
                                        description: quote.description,
                                        partnerTerms: quote.partnerTerms,
                                    }}
                                >
                                    <Field
                                        name="title"
                                        label="Quote title"
                                        component={FieldGroup}
                                        placeholder="Title..."
                                    />
                                    <Field
                                        label="Quote description"
                                        name="description"
                                        component={TextareaGroup}
                                        placeholder="Description..."
                                    />
                                    <br />
                                    <Field
                                        label="Terms and conditions"
                                        name="partnerTerms"
                                        component={TextareaGroup}
                                        placeholder="Terms... "
                                    />
                                    <br />
                                    <QuoteItems
                                        quote={quote}
                                        deleteQuoteItem={this.deleteQuoteItem}
                                    />
                                    <br />
                                    <CreateQuoteItemForm
                                        submit={submit}
                                        quoteItemType={quoteItemProps && quoteItemProps.type}
                                        onSubmit={this.createQuoteItem}
                                    />
                                </ReduxForm>
                            </Card.Content>
                        </Card>
                    </Col>

                    <Col sm={6}>
                        <Card>
                            <Card.Header>
                                <b>Client task description</b>
                            </Card.Header>
                            <Card.Content>
                                <p>{nl2br(lead.description)}</p>

                                {attributes.map((attribute, i) =>
                                    <p key={attribute.label + i}>
                                        <b>
                                            {attribute.label}
                                            :
                                        </b> 
                                        {' '}
                                        {attribute.selected[0].title}
                                    </p>
                                )}
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
                <ActionButtonBlock>
                    <Card>
                        <Card.Content>
                            <Flex>
                                <div>
                                    <ButtonWithIcon
                                        onClick={this.props.goBack}
                                        modifiers={[ 'btnBlock', 'secondary' ]}
                                    >
                                        <ButtonWithIcon.Icon className="mdi mdi-arrow-left"/>
                                        <ButtonWithIcon.Text>Back to overview</ButtonWithIcon.Text>
                                    </ButtonWithIcon>
                                </div>
                                <div>
                                    <Button
                                        onClick={this.saveQuote}
                                        modifiers={[ 'btnBlock', 'action' ]}
                                    >
                                        Save and update
                                    </Button>
                                    <div>CTRL+S</div>
                                </div>
                            </Flex>
                        </Card.Content>
                    </Card>
                </ActionButtonBlock>
            </div>
        )
    }
}
