import React, { memo } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { HasPermission } from '../NavigationBar'

const AdministrationDropdown = memo(({ hasPermission }: HasPermission) =>
    <NavDropdown eventKey={5} title="Administration" id="basic-nav-dropdown-Administration">
        <MenuItem header>Settings</MenuItem>
        {hasPermission('edit_certifications') && <MenuItem eventKey={5.1} href="/admin/api/settings">
            API keys
        </MenuItem>}
        <MenuItem eventKey={5.2} href="/admin/settings">Settings</MenuItem>
        <MenuItem eventKey={5.3} href="/admin/settings-table">Settings table</MenuItem>
        {hasPermission('admin_edit_field_generator') && <MenuItem eventKey={5.4} href="/admin/field-generator">
            Field generator
        </MenuItem>}
    </NavDropdown>
)

export default AdministrationDropdown
