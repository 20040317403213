import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'
import config from '../config'

export const GET_PARTNER_BUSINESS_UNITS = 'businessUnit/GET_PARTNER_BUSINESS_UNITS'
export const GET_PARTNER_BUSINESS_UNITS_SUCCESS = 'businessUnit/GET_PARTNER_BUSINESS_UNITS_SUCCESS'
export const GET_PARTNER_BUSINESS_UNITS_FAIL = 'businessUnit/GET_PARTNER_BUSINESS_UNITS_FAIL'

export const GET_PARTNER_BUSINESS_UNIT = 'businessUnit/GET_PARTNER_BUSINESS_UNIT'
export const GET_PARTNER_BUSINESS_UNIT_SUCCESS = 'businessUnit/GET_PARTNER_BUSINESS_UNIT_SUCCESS'
export const GET_PARTNER_BUSINESS_UNIT_FAIL = 'businessUnit/GET_PARTNER_BUSINESS_UNIT_FAIL'

export const CREATE_PARTNER_BUSINESS_UNITS = 'businessUnit/CREATE_PARTNER_BUSINESS_UNITS'
export const CREATE_PARTNER_BUSINESS_UNITS_SUCCESS = 'businessUnit/CREATE_PARTNER_BUSINESS_UNITS_SUCCESS'
export const CREATE_PARTNER_BUSINESS_UNITS_FAIL = 'businessUnit/CREATE_PARTNER_BUSINESS_UNITS_FAIL'

export const UPDATE_PARTNER_BUSINESS_UNITS = 'businessUnit/UPDATE_PARTNER_BUSINESS_UNITS'
export const UPDATE_PARTNER_BUSINESS_UNITS_SUCCESS = 'businessUnit/UPDATE_PARTNER_BUSINESS_UNITS_SUCCESS'
export const UPDATE_PARTNER_BUSINESS_UNITS_FAIL = 'businessUnit/UPDATE_PARTNER_BUSINESS_UNITS_FAIL'

export const DELETE_PARTNER_BUSINESS_UNITS = 'businessUnit/DELETE_PARTNER_BUSINESS_UNITS'
export const DELETE_PARTNER_BUSINESS_UNITS_SUCCESS = 'businessUnit/DELETE_PARTNER_BUSINESS_UNITS_SUCCESS'
export const DELETE_PARTNER_BUSINESS_UNITS_FAIL = 'businessUnit/DELETE_PARTNER_BUSINESS_UNITS_FAIL'

export const BUSINESS_UNIT_CREATE_REVENUE = 'businessUnit/BUSINESS_UNIT_CREATE_REVENUE'
export const BUSINESS_UNIT_CREATE_REVENUE_SUCCESS = 'businessUnit/BUSINESS_UNIT_CREATE_REVENUE_SUCCESS'
export const BUSINESS_UNIT_CREATE_REVENUE_FAIL = 'businessUnit/BUSINESS_UNIT_CREATE_REVENUE_FAIL'

export const ADD_INDUSTRY_TO_BUSINESS_UNIT = 'businessUnit/ADD_INDUSTRY_TO_BUSINESS_UNIT'
export const ADD_INDUSTRY_TO_BUSINESS_UNIT_SUCCESS = 'businessUnit/ADD_INDUSTRY_TO_BUSINESS_UNIT_SUCCESS'
export const ADD_INDUSTRY_TO_BUSINESS_UNIT_FAIL = 'businessUnit/ADD_INDUSTRY_TO_BUSINESS_UNIT_FAIL'


export const GET_BUSINESS_UNIT_LEAD_TYPES = 'businessUnit/GET_BUSINESS_UNIT_LEAD_TYPES'
export const GET_BUSINESS_UNIT_LEAD_TYPES_SUCCESS = 'businessUnit/GET_BUSINESS_UNIT_LEAD_TYPES_SUCCESS'
export const GET_BUSINESS_UNIT_LEAD_TYPES_FAIL = 'businessUnit/GET_BUSINESS_UNIT_LEAD_TYPES_FAIL'

export const ADD_LEAD_TYPE_TO_BUSINESS_UNIT = 'businessUnit/ADD_LEAD_TYPE_TO_BUSINESS_UNIT'
export const ADD_LEAD_TYPE_TO_BUSINESS_UNIT_SUCCESS = 'businessUnit/ADD_LEAD_TYPE_TO_BUSINESS_UNIT_SUCCESS'
export const ADD_LEAD_TYPE_TO_BUSINESS_UNIT_FAIL = 'businessUnit/ADD_LEAD_TYPE_TO_BUSINESS_UNIT_FAIL'

export const GET_MATCH_REPORTS = 'locations/GET_MATCH_REPORTS'
export const GET_MATCH_REPORTS_SUCCESS = 'locations/GET_MATCH_REPORTS_SUCCESS'
export const GET_MATCH_REPORTS_FAIL = 'locations/GET_MATCH_REPORTS_FAIL'

export const CLEANUP_MATCH_REPORT = 'locations/CLEANUP_MATCH_REPORT'

export const GET_BUSINESS_UNIT_GEO_REGIONS = 'businessUnit/GET_BUSINESS_UNIT_GEO_REGIONS'
export const GET_BUSINESS_UNIT_GEO_REGIONS_SUCCESS = 'businessUnit/GET_BUSINESS_UNIT_GEO_REGIONS_SUCCESS'
export const GET_BUSINESS_UNIT_GEO_REGIONS_FAIL = 'businessUnit/GET_BUSINESS_UNIT_GEO_REGIONS_FAIL'

export const ADD_GEO_REGIONS_TO_BUSINESS_UNIT = 'businessUnit/ADD_GEO_REGIONS_TO_BUSINESS_UNIT'
export const ADD_GEO_REGIONS_TO_BUSINESS_UNIT_SUCCESS = 'businessUnit/ADD_GEO_REGIONS_TO_BUSINESS_UNIT_SUCCESS'
export const ADD_GEO_REGIONS_TO_BUSINESS_UNIT_FAIL = 'businessUnit/ADD_GEO_REGIONS_TO_BUSINESS_UNIT_FAIL'

export const RESET_PARTNER_BUSINESS_UNITS = 'businessUnit/RESET_PARTNER_BUSINESS_UNITS'

interface GetPartnerBusinessUnitsModel {
    partnerId?: number | number[]
    businessUnitId?: number | number[]
    leadCategoryType?: 'exclusive' | 'basic'
    id?: number | number[]
    isActive?: '1' | '0'
    segmentationCriteria?: string
    segmentedForLeadId?: number
    geoCode?: string
    hasMarketingPackage?: '1' | '0'
    hasBusinessModel?: '1' | '0'
    subGeoRegionId?: number[] | number
    limit?: number | string
    sort?: string
    collapse?: string
}
export const getPartnerBusinessUnits = (
    body: GetPartnerBusinessUnitsModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_BUSINESS_UNITS,
            GET_PARTNER_BUSINESS_UNITS_SUCCESS,
            GET_PARTNER_BUSINESS_UNITS_FAIL,
        ],
        endpoint: '/partners/businessunits',
        schema: schemas.businessUnits,
        options: {
            body: {
                limit: 500,
                ...body,
            },
        },
    },
    append,
})

export const resetPartnerBusinessUnits = () => dispatch =>  dispatch({ type: RESET_PARTNER_BUSINESS_UNITS })

export const getPartnerBusinessUnit = (partnerId, buId) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_BUSINESS_UNIT,
            GET_PARTNER_BUSINESS_UNIT_SUCCESS,
            GET_PARTNER_BUSINESS_UNIT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/businessunits/` + buId,
        schema: schemas.businessUnit,
    },
})

interface GetMatchReportsModel {
    segmentationCriteria: string
    partnerId?: number
    segmentedForLeadId: number
}

export const getMatchReports = (
    body: GetMatchReportsModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [ GET_MATCH_REPORTS, GET_MATCH_REPORTS_SUCCESS, GET_MATCH_REPORTS_FAIL ],
        endpoint: '/partners/businessunits',
        schema: schemas.businessUnits,
        options: {
            body: {
                geoCode: config.geoCode,
                isActive: '1',
                limit: 200,
                ...body,
            },
        },
    },
    append,
    criteria: body.segmentationCriteria,
})

export const cleanMatchReport = () => ({
    type: CLEANUP_MATCH_REPORT,
})

interface CreatePartnerBusinessUnitsModel {
    geo?: {
        address: string
        zipCode: string
        cityName: string
        point: {
            lat: number
            lon: number
        }
    }
    disableDigitalLeads?: boolean
    displayName?: string
}
export const createPartnerBusinessUnits = (
    partnerId: number,
    body: CreatePartnerBusinessUnitsModel
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_BUSINESS_UNITS,
            CREATE_PARTNER_BUSINESS_UNITS_SUCCESS,
            CREATE_PARTNER_BUSINESS_UNITS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/businessunits`,
        schema: schemas.businessUnit,
        options: {
            method: 'post',
            body,
        },
    },
})

interface UpdatePartnerBusinessUnitsModel extends CreatePartnerBusinessUnitsModel{
    geoRange?
    id: number
}
export const updatePartnerBusinessUnits = (
    partnerId: number,
    body: UpdatePartnerBusinessUnitsModel
) => ({
    [CALL_API]: {
        types: [
            UPDATE_PARTNER_BUSINESS_UNITS,
            UPDATE_PARTNER_BUSINESS_UNITS_SUCCESS,
            UPDATE_PARTNER_BUSINESS_UNITS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/businessunits/${body.id}`,
        schema: schemas.businessUnit,
        options: {
            method: 'put',
            body,
        },
    },
})

export const deletePartnerBusinessUnits = (
    partnerId: number,
    businessUnitId: number
) => ({
    [CALL_API]: {
        types: [
            DELETE_PARTNER_BUSINESS_UNITS,
            DELETE_PARTNER_BUSINESS_UNITS_SUCCESS,
            DELETE_PARTNER_BUSINESS_UNITS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}`,
        options: {
            method: 'delete',
        },
    },
    businessUnitId,
})

interface CreateBusinessUnitRevenueRangeModel {
    minimumRevenue: number
    maximumRevenue: number
    revenueCurrency: string
}
export const createBusinessUnitRevenueRange = (
    partnerId: number,
    businessUnitId: number,
    body: CreateBusinessUnitRevenueRangeModel
) => ({
    [CALL_API]: {
        types: [ BUSINESS_UNIT_CREATE_REVENUE, BUSINESS_UNIT_CREATE_REVENUE_SUCCESS, BUSINESS_UNIT_CREATE_REVENUE_FAIL ],
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/revenueranges`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body,
        },
    },
})


export const updateBusinessUnitIndustries = (
    partnerId: number,
    businessUnitId: number,
    industryIds: number[]
) => ({
    [CALL_API]: {
        types: [
            ADD_INDUSTRY_TO_BUSINESS_UNIT,
            ADD_INDUSTRY_TO_BUSINESS_UNIT_SUCCESS,
            ADD_INDUSTRY_TO_BUSINESS_UNIT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/industries`,
        options: {
            method: 'put',
            body: {
                industryIds,
            },
        },
    },
})

export const updateBusinessUnitsLeadType = (
    partnerId: number,
    businessUnitId: number,
    typeIds: number[]
) => ({
    [CALL_API]: {
        types: [
            ADD_LEAD_TYPE_TO_BUSINESS_UNIT,
            ADD_LEAD_TYPE_TO_BUSINESS_UNIT_SUCCESS,
            ADD_LEAD_TYPE_TO_BUSINESS_UNIT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/types`,
        options: {
            method: 'put',
            body: {
                typeIds,
            },
        },
    },
})
