import React, { PureComponent } from 'react'
import moment from 'moment'
import {
    Bar,
    BarChart,
    CartesianAxis,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { darkGrey, primaryColor } from '../../../utils/variables'
import styled from 'styled-components'

const TooltipContainer = styled.div`
  background: #fff;
  padding: 10px;
  border: 1px solid #C7D7DD;
`

const renderTooltip = ({ payload }) => {
    if(payload && payload[0] && payload[0].payload ) {
        const kpi = payload[0].payload
        return (
            <TooltipContainer>
                <p>
                    Period:
                    {kpi.year + '-' + kpi.month}
                </p>
                <div>
                    Value:
                    {kpi.value}
                </div>
            </TooltipContainer>
        )
    }
    return null
}

const CustomBar = props => {
    const { x, y, width } = props

    return <g><path d={`M ${x},${y} h ${width} v ${2} h ${-width} Z`} fill={darkGrey}/></g>
}

interface ChartDataModel {
    year: number
    month: number
}
interface TargetsBarChartModel {
    data: ChartDataModel[]
    lineBar?: boolean
}
export class TargetsBarChart extends PureComponent<TargetsBarChartModel> {
    render() {
        const { data, lineBar } = this.props

        const currentMonth = moment().format('YYYY-MM')

        return (
            <ResponsiveContainer width={250}>
                <BarChart data={data} barSize={20}>
                    <CartesianAxis/>
                    <YAxis axisLine={false} width={30} />
                    <XAxis tick={false} height={1} />
                    <Tooltip content={renderTooltip} cursor={false} />
                    {lineBar
                        ? <Bar dataKey="value" shape={<CustomBar value="value" fill={darkGrey} />} />
                        : <Bar dataKey="value">
                            {data.map(entry => {
                                const kpiDate = entry.year + '-' + entry.month
                                return <Cell
                                    cursor="pointer"
                                    fill={kpiDate === currentMonth ? primaryColor : darkGrey}
                                    key={`cell-${kpiDate}`}
                                />
                            })}
                        </Bar>
                    }
                </BarChart>
            </ResponsiveContainer>
        )
    }
}

