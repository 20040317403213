import { createSelector } from 'reselect'
import { values } from 'lodash'

const invoiceRefundRequestsSelector = state => state.entities.invoiceRefundRequests

const refundsIdsSelector = state => state.pages.invoiceRefunds.refunds

const employeesSelector = state => state.entities.employees

const entitiesInvoices = state => state.entities.invoices

const entitiesPartnerDiscounts = state => state.entities.partnerDiscounts

const entitiesSubscriptionPrices = state => state.entities.subscriptionPrices

const subscriptionPriceIds = state =>  state.pages.subscription.priceIds

const subscriptionPartnerDiscountIds = state =>  state.pages.subscription.partnerDiscountIds


export const invoicesByPartnerSelector = (state, partnerId) => createSelector(
    entitiesInvoices,
    invoices => values(invoices).filter(invoice => invoice.partnerId === Number(partnerId))
)(state)

export const refundsInvoicesSelector = createSelector(
    refundsIdsSelector,
    invoiceRefundRequestsSelector,
    employeesSelector,
    (refundsIds, invoiceRefundRequests, employees) =>
        refundsIds.map(id => ({
            ...invoiceRefundRequests[id],
            employee: employees[invoiceRefundRequests[id].assignedEmployeeId] || {},
        }))
)

export const subscriptionPriceSelector = createSelector(
    entitiesSubscriptionPrices, subscriptionPriceIds,
    (prices, priceIds) => priceIds.map(id => prices[id])
)

export const subscriptionPartnerDiscountsSelector = createSelector(
    entitiesPartnerDiscounts, subscriptionPartnerDiscountIds,
    (partnerDiscounts, partnerDiscountIds) => partnerDiscountIds.map(id => partnerDiscounts[id])
)
