import styled from 'styled-components'
import { Button } from '../../elements/Button'
import Text from './_Text'
import Icon from './_Icon'
import Href from './_Href'

const ButtonWithIcon: any = styled(Button)`
  padding: 1px 15px;
  display: flex;
  align-items: center;
`

ButtonWithIcon.Text = Text
ButtonWithIcon.Icon = Icon
ButtonWithIcon.Href = Href

export default ButtonWithIcon
