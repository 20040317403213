import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_EMPLOYEES = 'employees/GET_EMPLOYEES'
export const GET_EMPLOYEES_SUCCESS = 'employees/GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAIL = 'employees/GET_EMPLOYEES_FAIL'

export const CREATE_MATCH_PREDICTION = 'lead/CREATE_MATCH_PREDICTION'
export const CREATE_MATCH_PREDICTION_SUCCESS = 'lead/CREATE_MATCH_PREDICTION_SUCCESS'
export const CREATE_MATCH_PREDICTION_FAIL = 'lead/CREATE_MATCH_PREDICTION_FAIL'

interface GetEmployeesPropsModel {
    geoCode?: string
    isActive?: '1' | '0'
    sort?: string
}
export const getEmployees = (
    criteria: GetEmployeesPropsModel,
) => ({
    [CALL_API]: {
        types: [ GET_EMPLOYEES, GET_EMPLOYEES_SUCCESS, GET_EMPLOYEES_FAIL ],
        endpoint: '/organisation/employees',
        schema: schemas.employees,
        options: {
            body: {
                isActive: 'null',
                ...criteria,
            },
        },
    },
})

interface CreateMatchPredictionPropsModel {
    leadId: number,
    quoteFee: {
        amount: number,
        currency: string,
    },
    matchBy: string,
    probabilityOfMatch: string,

}
export const createMatchPrediction = (
    employeeId: number,
    body: CreateMatchPredictionPropsModel,
) => ({
    [CALL_API]: {
        suppressLoader: true,
        types: [ CREATE_MATCH_PREDICTION, CREATE_MATCH_PREDICTION_SUCCESS, CREATE_MATCH_PREDICTION_FAIL ],
        endpoint: `/organisation/employees/${employeeId}/leadpredictions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body,
        },
    },
})

export const createEmployeesAction = (
    employeeId: number,
    body
) => ({
    [CALL_API]: {
        types: [ GET_EMPLOYEES, GET_EMPLOYEES_SUCCESS, GET_EMPLOYEES_FAIL ],
        endpoint: `/organisation/employees/${employeeId}/actions`,
        schema: schemas.employee,
        options: {
            method: 'post',
            body,
        },
    },
})
