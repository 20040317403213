import { createSelector } from 'reselect'

export const industriesEntities = state => state.entities.industries

export const allIndustriesId = state => state.subjects.industries.all.ids

export const businessUnitIndustriesId = state => state.subjects.industries.businessUnit.ids

export const allIndustriesSelector = createSelector(
    allIndustriesId, industriesEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const businessUnitIndustriesSelector = createSelector(
    businessUnitIndustriesId, industriesEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const quoteSurveillancePageIndustrySelector = createSelector(
    allIndustriesSelector,
    industries => industries.map(el => ({ ...el, id: el.identifier }))
)

export const splittedIndustriesSelector = createSelector(
    allIndustriesSelector,
    industries => industries.reduce((acc, next) => {
        if (next.parentIndustryId) acc.child.push(next)
        else acc.parent.push(next)
        return acc
    }, { parent: [], child: [] })
)
