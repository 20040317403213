import React, { PureComponent } from 'react'
import { getPartner } from '../../actions/partners'
import { connect } from '../../utils/connect'
import { Card } from '../blocks/Card'
import Tabs from '../elements/Tabs'
import { selectPartnerDetailBusinessUnits } from '../../selectors/partner'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import BusinessUnitSegmentation from './segmentationPage/BusinessUnitSegmentation'
import LoaderComponent from '../elements/LoaderComponent'
import { Partner } from '../../reducers/types/entities/Partner'
import { replace } from 'connected-react-router'
import { BtnLink } from '../elements/Links'
import { getBusinessUnitsAllocationLimits } from '../../state/allocationsLimits/actions'

export interface Props {
    businessUnits: BusinessUnit[]
    partner: Partner
}

export interface Actions {
    getBusinessUnitsAllocationLimits: typeof getBusinessUnitsAllocationLimits
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getPartner: typeof getPartner
    replace: typeof replace
}

export interface State {
    selectedTab
}

export interface PathProps {
    location: {
        hash: string
    }
    match: {
        params: {
            partnerId: number
        }
    }
}

export type Model = Props & Actions & PathProps

@connect<Props, Actions, PathProps>(
    (state, { match: { params: { partnerId } } }) => ({
        businessUnits: selectPartnerDetailBusinessUnits(state),
        partner: state.entities.partners[partnerId],
    }),
    {
        getBusinessUnitsAllocationLimits,
        getPartnerBusinessUnits,
        getPartner,
        replace,
    }
)
export default class SegmentationPage extends PureComponent<Model, State> {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: Number(props.location.hash.slice(1)),
        }
    }

    componentDidMount() {
        const {
            match: {
                params: { partnerId },
            },
            location: { hash },
        } = this.props

        const res: any = this.props.getPartnerBusinessUnits({ partnerId })
        res.then(res => {
            this.setState({ selectedTab: hash ? Number(hash.slice(1)) : res.result[0] })
        })
        this.props.getPartner(partnerId)
    }

    changeTab = tabIndex => {
        this.setState({ selectedTab: tabIndex })
        this.props.replace(window.location.pathname + '#' + tabIndex)
    }

    render() {
        const {
            businessUnits,
            partner,
        } = this.props
        const { selectedTab } = this.state

        if (!this.props.partner || !this.props.businessUnits.find(bu => bu.id === selectedTab)) {
            return <LoaderComponent type="logo" />
        }

        const tabs = businessUnits.map(el => ({
            tabId: el.id,
            tabName: el.displayName,
        }))

        return <Card margin="0">
            <Card.Header padding="0 20px" modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                <Tabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    changeTab={this.changeTab}
                />
                <div>
                    <BtnLink
                        modifiers="secondary"
                        to={'/partners/' + partner.id}
                    >
                        <i className="mdi mdi-undo-variant"/>
                        {' '}
                        Go back
                    </BtnLink>
                </div>
            </Card.Header>
            {Boolean(selectedTab) && <BusinessUnitSegmentation
                partnerId={this.props.match.params.partnerId}
                businessUnitId={selectedTab}
                partner={partner}
            />}
        </Card>
    }
}
