import React, { memo } from 'react'
import { Table } from '../../elements/table'
import { FormattedAmount } from '../../elements/FormattedAmount'
import { Button } from '../../elements/Button'
import { Quote } from '../../../reducers/types/entities/Quote'

interface QuoteItemsModel {
    quote: Quote
    deleteQuoteItem: (itemId: number) => void
}

const QuoteItems = memo(({
    quote,
    deleteQuoteItem,
}: QuoteItemsModel) =>
    <Table className="partner-datatable">
        <col width="55%" />
        <col width="15%" />
        <col width="10%" />
        <col width="15%" />
        <col width="5%" />
        <thead>
            <tr>
                <th>Description</th>
                <th>Product type</th>
                <th>Quantity</th>
                <th className="text-right">Price (ex VAT)</th>
                <th />
            </tr>
        </thead>
        <tbody>
            {quote.items && quote.items.map((item, index) => (
                <tr key={'quote-price-' + index}>
                    <td>{item.title}</td>
                    <td>{item.type}</td>
                    <td>{item.quantity}</td>
                    <td className="text-right">
                        <FormattedAmount amount={item.amountExclVat} format="00.00" />
                    </td>
                    <td className="text-center">
                        <Button
                            onClick={() => deleteQuoteItem(item.id)}
                            modifiers={[ 'danger', 'btnIcon' ]}
                        >
                            <i className="mdi mdi-close" />
                        </Button>
                    </td>
                </tr>
            ))}
            <tr>
                <td colSpan={3}>VAT</td>
                <td className="text-right">
                    <FormattedAmount amount={quote.totalVatAmount} format="00.00" />
                </td>
                <td />
            </tr>
            <tr>
                <td colSpan={3}>
                    <b>Total price including VAT</b>
                </td>
                <td className="text-right">
                    <b><FormattedAmount amount={quote.totalAmountInclVat} format="00.00" /></b>
                </td>
                <td />
            </tr>
        </tbody>
    </Table>
)

export default QuoteItems
