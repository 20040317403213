import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { actionColor, dangerColor } from '../../utils/variables'

const CheckBox = styled.div<{checked: boolean}>`
    background: ${({ checked }) => checked ? actionColor : dangerColor};
    cursor: pointer;
    float: left;
    width: 23px;
    height: 23px;
    margin-right: 10px;
    border-radius: 100%;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: flex;
    }
`

const CheckBoxContainer = styled.div`
    overflow: auto;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
`

interface ActionCheckBoxModel {
    onClick: (value: any,  checked: boolean) => void
    checked: boolean
    text: string | JSX.Element
    value: any
    disabled?: boolean
}
export default class ActionCheckBox extends PureComponent<ActionCheckBoxModel> {
    static defaultProps = {
        checked: false,
        disabled: false,
    }

    clicked = () => {
        if (typeof this.props.onClick === 'function' && !this.props.disabled) {
            this.props.onClick(this.props.value, !this.props.checked)
        }
    }

    render() {
        const { text, checked } = this.props

        return (
            <CheckBoxContainer onClick={this.clicked}>
                <CheckBox checked={checked}>
                    <i className={'mdi mdi-' + (checked ? 'check' : 'close')}/>
                </CheckBox>
                <span>{text}</span>
            </CheckBoxContainer>
        )
    }
}
