import React, { Component, PureComponent } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { get, uniqBy } from 'lodash'
import config from '../../config'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { Card } from '../blocks/Card'
import { partnerListSelector } from '../../selectors/partner'
import { getPartnerAggregations, getPartners } from '../../actions/partners'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { Employee } from '../../reducers/types/entities/Employee'
import { Certification } from '../../reducers/types/entities/Certification'
import ProviderTable from '../blocks/ProviderTable'
import { Aggregation } from '../../reducers/types/entities/Aggregation'
import {
    Cell,
    LinkCell,
    EmployeeCell,
    SatisfactionCell,
    DateCell,
    DropdownFilter,
    LocationFilter,
    TextFilter, SortColumn, Column, Sidebar,
} from '../elements/table'
import { allCertificationsSelector } from '../../state/certifications/selectors'
import { getCertifications } from '../../state/certifications/actions'
import { NestedColumn } from '../elements/table/columns/Column'
import { NestedTableCell } from '../elements/table/cells/Cell'
import { getPartnerContracts } from '../../actions/partnerContracts'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex'
import BusinessModelLabel from '../elements/BusinessModelLabel'
import { FormattedAmount } from '../elements/FormattedAmount'
import styled from 'styled-components'
import MapSidebar from '../elements/MapSidebar'
import queryString from 'query-string'
import { replace } from 'connected-react-router'
import { tableBorderColor } from '../../utils/variables'

const SATISFACTIONS = [
    { id: 4, name: 'Very happy', cssClass: 'label satisfaction satisfaction-4' },
    { id: 3, name: 'Mostly happy', cssClass: 'label satisfaction satisfaction-3' },
    { id: 2, name: 'Somewhat unhappy', cssClass: 'label satisfaction satisfaction-2' },
    { id: 1, name: 'Very unhappy', cssClass: 'label satisfaction satisfaction-1' },
]

const STATES = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'ex_partner', name: 'Ex Partner' },
    { id: 'canvas', name: 'Canvas' },
    { id: 'demo', name: 'Demo' },
]

const CANVAS_STATUSES = [
    { id: 'untreated', name: 'Untreated' },
    { id: 'postponed', name: 'Postponed' },
    { id: 'not_relevant', name: 'Not Relevant' },
    { id: 'not_interested', name: 'Not Interested' },
    { id: 'already_partner', name: 'Already Partner' },
    { id: 'x_partner', name: 'Ex Partner' },
]

const BOOLEANS = [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
]

const NestedTableCard = styled.div`
    position: relative;
    padding: 40px 60px 72px;
    width: 100%;
    border-bottom: 1px solid ${tableBorderColor};

    & > table {
        width: 100%;
    }

    &:hover th {
        background-color: #F8FAFC;
    }
`

interface PartnerContractsRowsModel {
    businessUnits: BusinessUnit[]
    partner: Partner
    isLoading: boolean
    returnBusinessModels: any
    aggregateQuota: any
    formatPercentage: any
}

class PartnerBusinessUnitNestedTable extends Component<PartnerContractsRowsModel> {
    shouldComponentUpdate(): boolean {
        return true
    }

    sorter = (a: BusinessUnit, b: BusinessUnit) => {
        if (!a.partnerContract && b.partnerContract) {
            return 1
        }

        if (a.partnerContract && !b.partnerContract) {
            return -1
        }

        return 0
    }

    render() {
        const { isLoading, businessUnits, returnBusinessModels, aggregateQuota, formatPercentage } = this.props

        const returnFormattedPeriodString = period => {
            if (!period) {
                return ''
            }
            if (Number(period) === 1) {
                return period + ' month'
            }
            return period + ' months'
        }

        const returnQuotaFulfillmentString = (allocations) => {
            return allocations.normalQuotaAllocationCount + (allocations.overdeliveryAllocationCount ? `+${allocations.overdeliveryAllocationCount}` : '') + ` / ${allocations.allocationQuota}`
        }

        return <NestedTableCard>
            <table>
                <thead>
                    <NestedColumn name="id">Contract ID</NestedColumn>
                    <NestedColumn name="unit_type">Unit types</NestedColumn>
                    <NestedColumn name="lead_quota">Lead quota</NestedColumn>
                    <NestedColumn name="industry">Industry</NestedColumn>
                    <NestedColumn name="contract_length">Contract length</NestedColumn>
                    <NestedColumn name="accepted_on">Accepted on</NestedColumn>
                    <NestedColumn name="starts_at">Starts at</NestedColumn>
                    <NestedColumn name="renews_at">Renews at</NestedColumn>
                    <NestedColumn name="cancellation_date">Cancellation date</NestedColumn>
                    <NestedColumn name="churns_on">Churns on</NestedColumn>
                    <NestedColumn name="quota_fulfillment">Quota fulfillment</NestedColumn>
                    <NestedColumn name="delivery_ratio">Delivery trend</NestedColumn>
                    <NestedColumn name="business_unit_mrr">Mrr</NestedColumn>
                </thead>
                <tbody>
                    {isLoading && businessUnits.length < 1 &&  <tr>
                        <NestedTableCell colSpan={100}>
                            <i>Loading business units..</i>
                        </NestedTableCell>
                    </tr>}
                    {!isLoading && businessUnits.length < 1 && <tr>
                        <NestedTableCell colSpan={100}>
                            <i>No business units currently available</i>
                        </NestedTableCell>
                    </tr>}
                    {businessUnits.length > 0 && businessUnits.sort(this.sorter).map(bu => <tr key={'bu_' + bu.id}>
                        <NestedTableCell>{get(bu, 'partnerContract.id') || '-'}</NestedTableCell>
                        <NestedTableCell>
                            {
                                returnBusinessModels([ bu ]).map(el => <BusinessModelLabel
                                    key={'businessModels_' + el.id}
                                    abbreviation={el.abbreviation}
                                >
                                    {el.abbreviation}
                                </BusinessModelLabel>)
                            }
                        </NestedTableCell>
                        <NestedTableCell>{get(bu.currentAllocation, 'normalAllocationQuota')}</NestedTableCell>
                        <NestedTableCell>{bu.displayName}</NestedTableCell>
                        <NestedTableCell>{returnFormattedPeriodString(get(bu, 'partnerContract.bindingPeriodMonths'))}</NestedTableCell>
                        <DateCell date={get(bu, 'partnerContract.acceptedAt')} format="datetime" nested/>
                        <DateCell date={get(bu, 'partnerContract.startAt')} format="datetime" nested/>
                        <DateCell date={get(bu, 'partnerContract.currentPartnerContractPeriod.endAt')} format="datetime" nested/>
                        <DateCell date={get(bu, 'partnerContract.canceledAt')} format="datetime" nested/>
                        <DateCell date={get(bu, 'partnerContract.churnAt')} format="datetime" nested/>
                        <NestedTableCell>{returnQuotaFulfillmentString(aggregateQuota([ bu ]))}</NestedTableCell>
                        <NestedTableCell>{formatPercentage(bu, 'kpi.deliveryTrend')}</NestedTableCell>
                        <NestedTableCell>
                            <FormattedAmount amount={get(bu, 'partnerContract.businessUnitMrr', 0)} format={'0,0.00'}/>
                        </NestedTableCell>
                    </tr>
                    )}
                </tbody>
            </table>
        </NestedTableCard>
    }
}

interface PartnerRowModel {
    rowData?: Partner
    partnerBusinessUnits: BusinessUnit[]
    loadingBusinessUnits: boolean
    alreadyLoadedPartners: number[]
    show: boolean
}

interface PartnerRowSearchProps {
    query: object
}

interface PartnerRowActions {
    replace: typeof replace
}

class PartnerRow extends Component<PartnerRowModel & PartnerRowSearchProps & PartnerRowActions> {
    state = {
        partnerBusinessUnitsDisplayed: false,
        matchingBusinessUnits: [],
    }

    componentDidMount() {
        const partner = this.props.rowData
        const filteredBusinessUnits = this.props.partnerBusinessUnits.filter(bu => bu.partnerId === partner.id)

        if (filteredBusinessUnits && filteredBusinessUnits.length > 0) {
            this.setState({
                matchingBusinessUnits: filteredBusinessUnits,
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<PartnerRowModel & PartnerRowSearchProps & PartnerRowActions>, prevState: Readonly<{}>, snapshot?: any) {
        const partner = this.props.rowData
        const prevFilteredBusinessUnits = prevProps.partnerBusinessUnits.filter(bu => bu.partnerId === partner.id)
        const currentFilteredBusinessUnits = this.props.partnerBusinessUnits.filter(bu => bu.partnerId === partner.id)

        if (prevFilteredBusinessUnits.length !== currentFilteredBusinessUnits.length) {
            this.setState({
                matchingBusinessUnits: currentFilteredBusinessUnits,
            })
        }
    }

    shouldComponentUpdate(): boolean {
        return true
    }

    showPartnerBusinessUnits = async () => {
        this.props.replace({
            search: queryString.stringify(Object.assign({}, this.props.query, { activeRowId: this.props.rowData.id })),
        })
        await this.setState({ partnerBusinessUnitsDisplayed: !this.state.partnerBusinessUnitsDisplayed })
    }

    returnBusinessModels = (buArray: BusinessUnit[]) => {
        const businessModels = []

        buArray.map(bu => {
            if (bu.accumulatedAllocation.allocationQuota > 0) {
                businessModels.push({
                    id: 1,
                    abbreviation: 'lb',
                })
            }
            if (bu.accumulatedAllocation.payPerSpotAllocationLimit > 0) {
                businessModels.push({
                    id: 2,
                    abbreviation: 'pps',
                })
            }
            if (bu.wantsExclusiveLeads) {
                businessModels.push({
                    id: 3,
                    abbreviation: 'excl',
                })
            }
        })
        return uniqBy(businessModels, 'id')
    }

    aggregateQuota = (buArray: BusinessUnit[]) => {
        const defaultAccQuota = {
            allocationQuota: 0,
            normalQuotaAllocationCount: 0,
            overdeliveryAllocationCount: 0,
        }
        return buArray.reduce((acc, cur) => {
            acc.allocationQuota = acc.allocationQuota + cur.accumulatedAllocation.allocationQuota
            acc.normalQuotaAllocationCount = acc.normalQuotaAllocationCount + cur.accumulatedAllocation.normalQuotaAllocationCount
            acc.overdeliveryAllocationCount = acc.overdeliveryAllocationCount + cur.accumulatedAllocation.overdeliveryAllocationCount
            return acc
        }, defaultAccQuota)
    }

    returnPauseState = (partner) => {
        const activeAllocationPausesCount = get(partner, 'newKpi.activeAllocationPausesCount')
        const activeContractsCount = get(partner, 'newKpi.activeContractsCount')
        if (!activeContractsCount && activeAllocationPausesCount) {
            return 'Paused'
        }

        if (!activeAllocationPausesCount || !activeContractsCount) {
            return '-'
        }

        if (activeAllocationPausesCount === activeContractsCount) {
            return 'Paused'
        }

        return `Paused ${activeAllocationPausesCount} / ${activeContractsCount}`
    }

    formatPercentage = (model, kpiName) => {
        const kpi = get(model, kpiName)

        if (!kpi) {
            return '-'
        }

        return (kpi * 100).toFixed(2) + '%'
    }

    render() {
        const { matchingBusinessUnits } = this.state
        const { loadingBusinessUnits, alreadyLoadedPartners } = this.props
        const partner = this.props.rowData
        const hasBeenLoaded = alreadyLoadedPartners.includes(partner.id)
        const returnQuotaFulfillmentString = (allocations) => {
            return allocations.normalQuotaAllocationCount + (allocations.overdeliveryAllocationCount ? `+${allocations.overdeliveryAllocationCount}` : '') + ` / ${allocations.allocationQuota}`
        }
        let tooltip = null

        if (partner.alerts && partner.alerts.length > 0)
            tooltip = <Tooltip placement="right" className="in" id={'tooltip-' + partner.id}>
                {partner.alerts.map((alert, i) => <span key={i}>
                    {alert.title}
                    <br/>
                </span>)}
            </Tooltip>

        return <React.Fragment>
            <ProviderTable.Row {...Object.assign({}, this.props, { onRowClick: this.showPartnerBusinessUnits })} active={this.state.partnerBusinessUnitsDisplayed}>
                <LinkCell to={`/partners/${partner.id}`} onClick={e => e.stopPropagation()}>{partner.id}</LinkCell>
                <LinkCell to={`/partners/${partner.id}`} onClick={e => e.stopPropagation()}>
                    {tooltip &&
                    <OverlayTrigger delay={0} placement="right" overlay={tooltip} animation={false} delayHide={0}>
                        <span className="dot dot-danger pull-right"/>
                    </OverlayTrigger>}
                    {partner.companyName}
                </LinkCell>
                <Cell>
                    {partner.geo.zipCode}
                    {' '}
                    {partner.geo.cityName}
                </Cell>
                <Cell>{get(partner.geo, 'homeSubGeoRegion.subRegionName', '-')}</Cell>
                <Cell>
                    {
                        (loadingBusinessUnits && !hasBeenLoaded)
                            ? <i>Loading..</i>
                            : this.returnBusinessModels(matchingBusinessUnits).map(el => <BusinessModelLabel
                                key={'businessModels_' + el.id}
                                abbreviation={el.abbreviation}
                            >
                                {el.abbreviation}
                            </BusinessModelLabel>)
                    }
                </Cell>
                <Cell>
                    {
                        (loadingBusinessUnits && !hasBeenLoaded) ? <i>Loading..</i> : this.aggregateQuota(matchingBusinessUnits).allocationQuota}
                </Cell>
                <SatisfactionCell satisfaction={partner.satisfaction}/>
                <EmployeeCell portrait={false} employee={partner.employee}/>
                <Cell>
                    {
                        (loadingBusinessUnits && !hasBeenLoaded)
                            ? <i>Loading..</i>
                            : returnQuotaFulfillmentString(this.aggregateQuota(matchingBusinessUnits))}
                </Cell>
                <Cell>{this.formatPercentage(partner, 'newKpi.currentPeriodWeightedDeliveryTrend')}</Cell>
                <Cell>{this.formatPercentage(partner, 'newKpi.publishingRatio')}</Cell>
                <Cell>{this.formatPercentage(partner, 'newKpi.successRatio')}</Cell>
                <Cell>{get(partner, 'newKpi.deallocations', '-')}</Cell>
                <Cell>
                    <FormattedAmount amount={get(partner, 'newKpi.overdueInvoiceAmountInclVat', 0)} format={'0,0.00'}/>
                </Cell>
                <Cell>{get(partner, 'newKpi.activeContractsCount', '-')}</Cell>
                <Cell>{this.returnPauseState(partner)}</Cell>
                <Cell>
                    <FormattedAmount amount={get(partner, 'newKpi.activeContractsAmountExclVat', 0)} format={'0,0.00'}/>
                </Cell>
                {(partner.newKpi && partner.newKpi.nextContractRenewalAt) ? <DateCell date={partner.newKpi.nextContractRenewalAt} /> : <Cell>-</Cell>}
                <Cell>
                    <FormattedAmount amount={get(partner, 'newKpi.activeBusinessUnitsAmountExclVat', 0)} format={'0,0.00'}/>
                </Cell>
                <Cell>
                    <FormattedAmount amount={get(partner, 'newKpi.activeBusinessUnitsMrrExclVat', 0)} format={'0,0.00'}/>
                </Cell>
                <Cell>{get(partner, 'newKpi.allTimePurchasedMarketplaceLeadsCount', '-')}</Cell>
                <Cell>
                    <FormattedAmount amount={get(partner, 'newKpi.allTimePurchasedMarketplaceLeadsValueExclVatLocalCurrency', 0)} format={'0,0'}/>
                </Cell>
                <Cell>
                    <FormattedAmount amount={get(partner, 'newKpi.leadMarketplacePartnerSpendExclVatLocalCurrency', 0)} format={'0,0'}/>
                </Cell>
            </ProviderTable.Row>
            {(this.state.partnerBusinessUnitsDisplayed || this.props.show) &&
                <tr>
                    <td colSpan={100}>
                        <PartnerBusinessUnitNestedTable
                            isLoading={(loadingBusinessUnits && !hasBeenLoaded)}
                            partner={partner}
                            businessUnits={matchingBusinessUnits}
                            returnBusinessModels={this.returnBusinessModels}
                            aggregateQuota={this.aggregateQuota}
                            formatPercentage={this.formatPercentage}
                        />
                    </td>
                </tr>
            }
        </React.Fragment>
    }
}

interface Criteria {
    state: string[]
    visibleColumns: string[]
    satisfaction: string[]
    digitalLeads: string[]
    canvasStatus: string[]
    employeeId: string[]
    badgeId: string[]
    sort: string
    sidebarName: string
    activeRowId: string
    geoDistance: string
    locationId: string
    geoPoint: string
    geoRect: string
    query: string
    hasUnusedAllocations: string[]
    hasActiveContract: string[]
    packageType: string[]
    partnerAssigneeClientConsultantEmployeeId: string[]
}

interface PartnerListState {
    properties: [],
    selectedProperties: [],
    loadingBusinessUnits: boolean,
    alreadyLoadedPartners: number[],
    showAll: boolean,
}

interface PartnersListProps {
    certifications: Certification[]
    employees: Employee[]
    partners: Partner[]
    partnerBusinessUnits: BusinessUnit[]
    totalItems: number
    aggregations: Aggregation[]
    criteria: Criteria
    industryId?: string
}

interface PartnersDispatchProps {
    getPartnerAggregations: typeof getPartnerAggregations
    getCertifications: typeof getCertifications
    getPartners: typeof getPartners
    getPartnerContracts: typeof getPartnerContracts
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    replace: typeof replace
}

interface PartnersListPathProps {
    location: {
        query: Criteria & {
            state: string[]
        }
    }
}

type PartnersListModel = PartnersListProps & PartnersDispatchProps & PartnersListPathProps & PartnerListState

@connect<PartnersListProps, PartnersDispatchProps, PartnersListPathProps>(
    (state, { location: { query } }) => ({
        partners: partnerListSelector(state),
        partnerBusinessUnits: Object.values(state.entities.businessUnits),
        aggregations: state.pages.partners.aggregations,
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        totalItems: state.pages.partners.pagination.total,
        certifications: allCertificationsSelector(state),
        criteria: {
            state: query.state ? splitFilterString(query.state) : 'active',
            visibleColumns: splitFilterString(query.visibleColumns),
            satisfaction: splitFilterString(query.satisfaction),
            digitalLeads: splitFilterString(query.digitalLeads),
            canvasStatus: splitFilterString(query.canvasStatus),
            employeeId: splitFilterString(query.employeeId),
            badgeId: splitFilterString(query.badgeId),
            sort: query.sort || 'current_period_weighted_delivery_trend',
            sidebarName: query.sidebarName || '',
            activeRowId: query.activeRowId || '',
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
            geoPoint: query.geoPoint || '',
            geoRect: query.geoRect || '',
            query: query.query || '',
            hasUnusedAllocations: splitFilterString(query.hasUnusedAllocations),
            hasActiveContract: splitFilterString(query.hasActiveContract),
            packageType: splitFilterString(query.packageType),
            partnerAssigneeClientConsultantEmployeeId: splitFilterString(query.partnerAssigneeClientConsultantEmployeeId),
        },
        industryId: state.auth.wantedIndustryId ? String(state.auth.wantedIndustryId) : undefined,
    }),
    {
        getPartnerAggregations,
        getPartners,
        getCertifications,
        getPartnerContracts,
        getPartnerBusinessUnits,
        replace,
    }
)
export default class PartnerListPage extends PureComponent<PartnersListModel> {
    state = {
        properties: [
            { id: 'allocationFrequencyMonths', text: 'Allocation Frequency', display: true },
            { id: 'billingFrequencyMonths', text: 'Billing Frequency', display: true },
            { id: 'bindingPeriodMonths', text: 'Binding Period', display: true },
        ],
        selectedProperties: [],
        loadingBusinessUnits: false,
        alreadyLoadedPartners: [],
        showAll: false,
    }

    componentDidMount() {
        this.props.getCertifications({
            geoCode: config.geoCode,
        })
    }

    retrieveData = async (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            state: filterCriteria.state ? filterCriteria.state : this.props.criteria.state,
            geoCode: config.geoCode,
            industryId: this.props.industryId,
            limit: 25,
        })

        await this.setState({
            loadingBusinessUnits: true,
        })

        this.props.getPartnerAggregations(criteria)
        const partners: any = await this.props.getPartners(criteria, append)

        if (partners.result.length) {
            await this.props.getPartnerBusinessUnits({
                partnerId: partners.result,
                isActive: '1',
                limit: 10000,
            })
        }

        this.setState({
            loadingBusinessUnits: false,
            alreadyLoadedPartners: [ ...this.state.alreadyLoadedPartners, ...partners.result ],
        })

        return partners
    }

    toggleShowAll = () => this.setState({ showAll: !this.state.showAll })

    onPropertiesChange = async (e) => {
        const properties = this.state.properties
        for (const prop of properties) {
            if (prop.id === e) {
                prop.display = !prop.display
            }
        }

        const selectedProperties = properties.filter(prop => prop.display).map(prop => prop.id)

        await this.setState({ properties, selectedProperties })
    }

    render() {
        const {
            partners,
            criteria,
            employees,
            totalItems,
            aggregations,
            certifications,
            partnerBusinessUnits,
        } = this.props
        const satisfactionAggregation = aggregations.find(aggregation => aggregation.name === 'satisfaction')
        const canvasStatusAggregation = aggregations.find(aggregation => aggregation.name === 'canvas_status')
        const partnerStateAggregation = aggregations.find(aggregation => aggregation.name === 'state')
        const employeePartnerAggregation = aggregations.find(aggregation => aggregation.name === 'employee_id')
        const employeePartnerClientConsultantAggregation = aggregations.find(aggregation => aggregation.name === 'partner_assignee_client_consultant_employee_id')
        const badgePartnerAggregation = aggregations.find(aggregation => aggregation.name === 'badge_id')
        const digitalLeadsAggregation = aggregations.find(aggregation => aggregation.name === 'digital_leads')

        return (
            <Card margin="0">
                <Card.Header>
                    Partners
                    {' '}
                    <span className="text-muted">
                        (
                        {partners.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <Flex className="infotable-header" style={{ margin: '0 20px', paddingBottom: 0 }}>
                    <Button modifiers={[ 'secondary' ]} onClick={this.toggleShowAll}>Show all</Button>
                </Flex>
                <ProviderTable
                    pageName="partners"
                    retrieveData={this.retrieveData}
                    criteria={this.props.criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.employeeId}
                            items={employees}
                            facets={employeePartnerAggregation}
                            name="employeeId"
                            label="Employee"
                            hideZero={true}
                        />
                        <DropdownFilter
                            selectedIds={criteria.partnerAssigneeClientConsultantEmployeeId}
                            items={employees}
                            facets={employeePartnerClientConsultantAggregation}
                            name="partnerAssigneeClientConsultantEmployeeId"
                            label="Assigned client consultant"
                            hideZero={true}
                        />
                        <DropdownFilter
                            selectedIds={criteria.state}
                            items={STATES}
                            name="state"
                            label="State"
                            facets={partnerStateAggregation}
                        />
                        <DropdownFilter
                            selectedIds={criteria.hasUnusedAllocations}
                            items={BOOLEANS}
                            name="hasUnusedAllocations"
                            label="Has unused allocations"
                        />
                        <DropdownFilter
                            selectedIds={criteria.hasActiveContract}
                            items={BOOLEANS}
                            name="hasActiveContract"
                            label="Has active contract"
                        />
                        <DropdownFilter
                            selectedIds={criteria.packageType}
                            items={[
                                { id: 'Ageras Marketplace Package', name: 'Ageras Marketplace Package' },
                                { id: 'Ageras Marketplace Basic Package', name: 'Ageras Marketplace Basic Package' },
                                { id: 'Ageras Marketplace Plus Package', name: 'Ageras Marketplace Plus Package' },
                            ]}
                            name="packageType"
                            label="Subscription"
                        />
                        {criteria.state.length === 1 &&
                        criteria.state[0] === 'canvas' &&
                        criteria.satisfaction.length === 0 || <DropdownFilter
                            selectedIds={criteria.satisfaction}
                            facets={satisfactionAggregation}
                            items={SATISFACTIONS}
                            name="satisfaction"
                            label="Satisfaction"
                        />}
                        {!criteria.state.includes('canvas') &&
                        criteria.canvasStatus.length === 0 || <DropdownFilter
                            selectedIds={criteria.canvasStatus}
                            facets={canvasStatusAggregation}
                            items={CANVAS_STATUSES}
                            name="canvasStatus"
                            label="Canvas Status"
                        />}
                        <DropdownFilter
                            selectedIds={criteria.badgeId}
                            items={certifications}
                            name="badgeId"
                            label="Certifications"
                            facets={badgePartnerAggregation}
                        />
                        <LocationFilter
                            label="Location"
                            name="location"
                            locationId={criteria.locationId}
                            geoDistance={criteria.geoDistance}
                        />
                        <TextFilter
                            value={criteria.query}
                            name="query"
                            label="Search for ..."
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <SortColumn name="id">Partner ID</SortColumn>
                            <SortColumn name="company_name">Partner name</SortColumn>
                            <Column name="location">Location</Column>
                            <Column name="home_sub_geo_region">Sub region</Column>
                            <Column name="unit_types">Unit types</Column>
                            <Column name="lead_quota">Lead quota</Column>
                            <SortColumn name="satisfaction">Satisfaction</SortColumn>
                            <SortColumn name="employee_name">Employee</SortColumn>
                            <Column name="quota_fulfillment">Quota fulfillment</Column>
                            <SortColumn name="current_period_weighted_delivery_trend">Delivery trend</SortColumn>
                            <SortColumn name="publishing_ratio">Publishing ratio</SortColumn>
                            <SortColumn name="success_ratio">Success ratio</SortColumn>
                            <SortColumn name="deallocations">Deallocations</SortColumn>
                            <SortColumn name="overdue_amount">Overdue amount</SortColumn>
                            <SortColumn name="active_contracts_count">Active contracts</SortColumn>
                            <SortColumn name="active_allocation_pauses_count">Contract pauses</SortColumn>
                            <SortColumn name="active_contracts_amount">Active contracts amount</SortColumn>
                            <SortColumn name="next_contract_renewal_at">Next renewal at</SortColumn>
                            <SortColumn name="active_business_units_amount">Active business units value</SortColumn>
                            <SortColumn name="active_business_units_mrr">Active business units mrr</SortColumn>
                            <SortColumn name="all_time_purchased_marketplace_leads_count">Amount of Leads purchased</SortColumn>
                            <SortColumn name="all_time_purchased_marketplace_leads_value_excl_vat_local_currency">Value of Cases Won</SortColumn>
                            <SortColumn name="lead_marketplace_partner_spend_excl_vat_local_currency">Marketplace Lead Spend</SortColumn>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={partners}
                            noDataText="We couldn't find any partners matching your selection."
                        >
                            <PartnerRow
                                partnerBusinessUnits={partnerBusinessUnits}
                                loadingBusinessUnits={this.state.loadingBusinessUnits}
                                alreadyLoadedPartners={this.state.alreadyLoadedPartners}
                                show={this.state.showAll}
                                query={this.props.location.query}
                                replace={this.props.replace}
                            />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                    <Sidebar>
                        <MapSidebar
                            list={partners}
                            header="Map"
                            viewKey="map"
                            pinLink="partners"
                        />
                    </Sidebar>
                </ProviderTable>
            </Card>
        )
    }
}
