import React, { Children, cloneElement, memo } from 'react'

interface RowModel {
    visibleColumns: string[]
    columnNames: string[]
    onRowClick: (id: number | string) => void
    children
    className?: string
    rowData: {
        id: number | string
    }
    active: boolean
}

const _Row = memo(({
    visibleColumns,
    columnNames,
    onRowClick,
    className,
    children,
    rowData,
    active,
}: Partial<RowModel>) =>
    <tr
        onClick={() => onRowClick(rowData.id)}
        className={`clickable${active ? ' active' : ''} ${className ? className : '' }`}
    >
        {(Array.isArray(visibleColumns) && visibleColumns.length && Array.isArray(columnNames))
            ? Children.map(children, (cell, i) => visibleColumns.includes(columnNames[i]) && cloneElement(cell))
            : children
        }
    </tr>
)
export default _Row
