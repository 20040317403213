import styled from 'styled-components'
import {
    flex,
    justifyContent,
} from 'styled-system'
import { cardPadding } from '../../../utils/variables'
import React, { PureComponent } from 'react'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import Spinner from '../../elements/Spinner'
import TransparentOverlay from '../../elements/TransparentOverlay'

const TextInfo = styled.div`
    top: 53%;
    margin: auto;
    width: 100%;
    position: absolute;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    z-index: 11;
`

interface LoadingContentPropsModel {
    preloadTextInfo: string
    isLoading?: boolean
    className?: string
    padding?: string
    disabled?: boolean
    flex?: any
}

// TODO: need reorganize scroll height config

const MODIFIER_SCROLLABLE_CONTENT_CONFIG = {
    scroll: ({ isLoading }) => `
        ${isLoading ? '' : 'overflow-y: auto'};
    `,
    scrollContent: ({ isLoading }) => `
        ${isLoading ? '' : 'overflow-y: auto'};
        height: calc(100% - 65px);
    `,
    maxHeightMd: () => `
        max-height: 300px;
    `,
    minHeightMd: () => `
       min-height: 300px;
    `,
    pageHeightScroll: ({ heightCorrection }) => `
        overflow-y: auto;
        height: calc(100vh - ${heightCorrection || 275}px);
    `,
    maxHeight: ({ scrollHeight }) => `
        max-height: ${scrollHeight || '100%'};
    `,
    fullHeight: () => `
        height: 100%;
    `,
}

class LoadingContent extends PureComponent<LoadingContentPropsModel> {
    render() {
        const { children, isLoading, className, disabled, preloadTextInfo } = this.props
        return <div className={className}>
            {(disabled || isLoading ) && <TransparentOverlay/>}
            {!disabled && isLoading && <Spinner />}
            {isLoading && preloadTextInfo && <TextInfo>{preloadTextInfo}</TextInfo>}
            {children}
        </div>
    }
}

export default styled(LoadingContent)<LoadingContentPropsModel>`
    display: flex;
    flex-direction: column;
    padding: ${props => props.padding || cardPadding};
    position: relative;
    ${props => props.isLoading && `
        overflow: hidden;
    `}
    ${flex}
    ${justifyContent}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_SCROLLABLE_CONTENT_CONFIG)}
`
