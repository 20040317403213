import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import { GET_BUSINESS_UNIT_GEO_REGIONS, GET_GEO_REGIONS } from './actions'

const geoRegions = combineReducers({
    all: generalSubjectReducer(GET_GEO_REGIONS),
    businessUnit: generalSubjectReducer(GET_BUSINESS_UNIT_GEO_REGIONS),
})

export default geoRegions
