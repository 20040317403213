import React, { memo, Fragment } from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Satisfaction from '../../Satisfaction'
import styled from 'styled-components'
import { WrappedFieldProps } from 'redux-form'

const SatisfactionBtn = styled.div`
  button {
    padding: 0;
  }
  .dropdown-menu {
    background: initial;
    min-width: initial;
    border: initial;
    cursor: pointer;
    top: 45px;
    padding: 0;
    margin: -4px 0 0 0;
  }
`

const SCORES = [ 0, 1, 4, 3, 2 ]

const SATISFACTION_LEVEL = [
    { id: 2, label: 'Very happy', score: 4 },
    { id: 3, label: 'Mostly happy', score: 3 },
    { id: 4, label: 'Somewhat unhappy', score: 2 },
    { id: 1, label: 'Very unhappy', score: 1 },
]

const SatisfactionList = memo(({ onSelect }: {onSelect: (level: number) => void}) =>
    <Fragment>
        {SATISFACTION_LEVEL.map(level =>
            <OverlayTrigger
                key={level.id + 'OverlayTrigger'}
                placement="right"
                overlay={<Tooltip id={level.id + 'Tooltip'}>{level.label}</Tooltip>}
            >
                <Satisfaction size={41} score={level.score} onClick={e => onSelect(level.id)}/>
            </OverlayTrigger>
        )}
    </Fragment>
)

const SatisfactionSelectField = memo(({
    input
}: WrappedFieldProps) =>
    <Dropdown componentClass={SatisfactionBtn} id="Dropdown">
        {input.value
            ? <Satisfaction size={41} score={SCORES[input.value]} onClick={e => input.onChange(0)}/>
            : <Dropdown.Toggle noCaret={true}>
                <Satisfaction size={41} score={0} />
            </Dropdown.Toggle>
        }
        <Dropdown.Menu>
            <SatisfactionList onSelect={input.onChange}/>
        </Dropdown.Menu>
    </Dropdown>
)

export default SatisfactionSelectField
