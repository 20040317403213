import styled from 'styled-components'

interface CheckBoxModel {
    checked: boolean,
    loading: boolean,
    onClick: () => void
}

export default styled.i.attrs(({ checked, loading, onClick }: CheckBoxModel) => ({
    onClick: !loading && onClick,
    className: `${checked ? 'mdi mdi-check' : ''} ${(onClick && !loading) ? 'checkable' : ''}`
}))<CheckBoxModel>`
    border: 1px solid #ccc;
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-family: ageras-punch-font;
    border-radius: 3px;
    font-size: 17px;
    text-align: center;
    display: block;
    font-style: normal;
    cursor: ${props => props.onClick ? 'pointer' : 'auto'};
    opacity: ${props => props.loading ? 0.6 : 1};
`
