import React, { ComponentType, PureComponent } from 'react'
import {
    getLeadCategories,
    getQuotesSurveillanceLeads,
} from '../../actions/leads'
import { dangerColor, successColor } from '../../utils/variables'
import config from '../../config'
import Timer from '../elements/Timer'
import { Alert, DropdownButton } from 'react-bootstrap'
import { createLeadNoteActivity, getActivities } from '../../actions/newsfeed'
import {
    leadActivitiesSelector,
    leadsQuotesSurveillanceSelector,
} from '../../selectors/lead'
import { Card } from '../blocks/Card/index'
import { showModal } from '../../actions/modal'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { connect } from '../../utils/connect'
import { Activity } from '../../reducers/types/entities/Activities'
import { Lead } from '../../reducers/types/entities/Lead'
import { LeadCategory } from '../../reducers/types/entities/LeadCategory'
import { Employee } from '../../reducers/types/entities/Employee'
import { Industry } from '../../reducers/types/entities/Industry'
import { leadCategoriesSelector } from '../../selectors/category'
import { Link } from 'react-router-dom'
import { Quotes } from '../../reducers/types/entities/Quote'
import styled from 'styled-components'
import moment from 'moment-business-time'
import momentDurationFormatSetup from 'moment-duration-format'
import ProviderTable from '../blocks/ProviderTable'
import {
    Cell,
    DateCell,
    EditCell,
    LinkCell,
    Column,
    SortColumn,
    DropdownFilter,
    LocationFilter,
} from '../elements/table'
import { getIndustries } from '../../state/industries/actions'
import { quoteSurveillancePageIndustrySelector } from '../../state/industries/selectors'
import { isCustomerChoice } from '../../utils/isCustomerChoice'

momentDurationFormatSetup(moment)

const Ul = styled.ul`
  padding-left: 0;
  li {
    margin: 5px 0;
  }
`

interface SurveillanceRowStateProps {
    leadActivity: Activity
    employeeId: number
    quotes: Quotes
}
interface SurveillanceRowDispatchProps {
    createLeadNoteActivity: typeof createLeadNoteActivity
    getLeadCategories: typeof getLeadCategories
    getActivities: typeof getActivities
    showModal: typeof showModal
}
interface SurveillanceRowHOCProps {
    rowData: Lead
    visibleCells: string[]
}

type SurveillanceModel = SurveillanceRowStateProps & SurveillanceRowDispatchProps & SurveillanceRowHOCProps

@connect<SurveillanceRowStateProps, SurveillanceRowDispatchProps, SurveillanceRowHOCProps>(
    (state, props) => ({
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
        employeeId: state.auth.employeeId,
        quotes: state.entities.quotes,
    }),
    {
        createLeadNoteActivity,
        getLeadCategories,
        getActivities,
        showModal,
    }
)
class SurveillanceRow extends PureComponent<SurveillanceModel> {
    calculateExpiresAt = () => {
        const expiresAt = this.props.rowData.expiresAt
        const localExpiresAt = moment.parseZone(expiresAt).local().format()
        const currentTime = moment()
        if (moment(localExpiresAt).isAfter(currentTime)) {
            const diff = moment(localExpiresAt).workingDiff(currentTime)
            return moment.duration(diff).format('HH:mm:ss', { trim: false })
        }
        return '00:00:00'
    }

    closeLeadAsCompleted = () => {
        this.props.showModal({
            name: 'leadMarkAsCompleteModal',
            leadId: this.props.rowData.id,
        })
    }

    getSegmentedPartners = () => {
        this.props.showModal({
            name: 'segmentedPartnersModal',
            lead: this.props.rowData,
        })
    }

    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        await this.props.createLeadNoteActivity(leadId, noteData)
        return this.props.getActivities({ leadId })
    }

    colorCondition(lead, forElement, timer?) {
        const { quotesInfo: { count, max } } = lead
        switch (forElement) {
            case 'quotes':
                return successColor
            case 'expiresAt':
                const hour = +timer.split(':')[0]
                return max === count
                    ? '-'
                    : (hour <= 8 || (max > count && timer === '00:00:00'))
                        ? dangerColor
                        : ''
        }
    }

    render() {
        const { leadActivity, quotes, ...props } = this.props

        const lead = this.props.rowData
        return <ProviderTable.Row {...props}>
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>{lead.category && lead.category.title}</Cell>
            <Cell>{lead.validator.firstName + ' ' + lead.validator.lastName}</Cell>
            {lead.client ? <Cell>
                {lead.client.firstName}
                {' '}
                {lead.client.lastName}
            </Cell> : <Cell/>}
            <Cell>{lead.customerInfo.customerType.identifier}</Cell>
            <Cell>{lead.title}</Cell>
            <Cell>
                <Timer
                    calculate={this.calculateExpiresAt}
                    color={time => this.colorCondition(lead, 'expiresAt', time)}
                />
&nbsp;
                {lead.expirationBoostedAt ? <i className="fa fa-stop-circle" style={{ color: 'orange' }}/> : ''}
            </Cell>
            <DateCell date={lead.validatedAt} />
            <Cell>{lead.geo.cityName}</Cell>
            <Cell>{lead.industries.primary && lead.industries.primary.identifier}</Cell>
            <Cell>{lead.industries.secondary && lead.industries.secondary.name}</Cell>
            {config.geoCode === 'de' ? (
                <Cell>
                    {lead.allocationSuggestionsInfo && lead.allocationSuggestionsInfo.accepted
                        ? lead.allocationSuggestionsInfo.accepted
                        : 0
                    }
                    &nbsp;/&nbsp;
                    {lead.allocationSuggestionsInfo && lead.allocationSuggestionsInfo.count
                        ? lead.allocationSuggestionsInfo.count
                        : 0
                    }
                </Cell>
            ) : <Cell style={{ display: 'none' }} />}
            <Cell style={{ color: this.colorCondition(lead, 'quotes') }}>
                {lead.quotesInfo.count}
                {' '}
                /
                {lead.quotesInfo.max}
            </Cell>
            <EditCell
                value={leadActivity && leadActivity.note ? leadActivity.note.text : ''}
                onBlur={this.handleChangeNoteCell}
            />
            <Cell>
                <Ul>
                    {lead.quotesInfo.quotes.map(quoteId => !quotes[quoteId].publishedAt && <li>
                        <Link to={`/partners/${quotes[quoteId].partner.id}`}>
                            {quotes[quoteId].partner.companyName}
                        </Link>
                    </li>)}
                </Ul>
            </Cell>
            <Cell>
                {isCustomerChoice(lead) ? 'Yes' : '-'}
            </Cell>
            <Cell className="text-center">
                <DropdownButton
                    onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                    pullRight
                    noCaret
                    title=""
                    id={lead.id + '_DropdownButton'}
                    className="btn btn-primary fa fa-wrench wrenchmodal-dropdown-trigger"
                >
                    <div className="text-left">
                        {lead.customerInfo.phone &&
                        <a onClick={this.getSegmentedPartners}>Show segmented partners</a>}
                        <Link to={`/leads/${lead.id}/send-email-regions`}>Send lead to partners</Link>
                        <a onClick={this.closeLeadAsCompleted}>Mark lead as completed</a>
                    </div>
                </DropdownButton>
            </Cell>
        </ProviderTable.Row>
    }
}

const SurveillanceTableRow = SurveillanceRow as ComponentType

interface LeadCategoryExtend {
    name: string
}

interface Criteria {
    sort: string
    visibleColumns: string[]
    validatorId: string[]
    industry: string[]
    leadCategory: string[]
    geoRect: string
    geoPoint: string
    geoDistance: string
    locationId: string
}

interface QuoteSurveillanceStateProps {
    leads: Lead[]
    leadCategories: Array<LeadCategory & LeadCategoryExtend>
    totalItems: number
    employees: Employee[]
    industries: Industry[]
    criteria: Criteria
}
interface QuoteSurveillanceDispatchProps {
    getQuotesSurveillanceLeads: typeof getQuotesSurveillanceLeads
    getLeadCategories: typeof getLeadCategories
    getActivities: typeof getActivities
    getIndustries: typeof getIndustries
}

interface QuoteSurveillanceOwnProps {
    location: {
        query: Criteria
    }
}

type QuoteSurveillanceModel = QuoteSurveillanceStateProps & QuoteSurveillanceDispatchProps & QuoteSurveillanceOwnProps

@connect<QuoteSurveillanceStateProps, QuoteSurveillanceDispatchProps, QuoteSurveillanceOwnProps>(
    (state, { location: { query } }) => ({
        leads: leadsQuotesSurveillanceSelector(state),
        leadCategories: leadCategoriesSelector(state),
        totalItems: state.pages.quotesSurveillance.pagination.total,
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        industries: quoteSurveillancePageIndustrySelector(state),
        criteria: {
            sort: query.sort || 'validated_at',
            visibleColumns: splitFilterString(query.visibleColumns),
            validatorId: splitFilterString(query.validatorId),
            industry: splitFilterString(query.industry),
            leadCategory: splitFilterString(query.leadCategory),
            geoRect: query.geoRect || '',
            geoPoint: query.geoPoint || '',
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
        },
    }),
    {
        getQuotesSurveillanceLeads,
        getLeadCategories,
        getActivities,
        getIndustries,
    }
)
export default class QuoteSurveillancePage extends PureComponent<QuoteSurveillanceModel> {
    componentDidMount() {
        this.props.getLeadCategories()
        this.props.getIndustries({ geoCode: config.geoCode })
    }

    retrieveData = async (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({ ...filterCriteria })

        const res: any = await this.props.getQuotesSurveillanceLeads(criteria, append)

        if (res.result.length) this.props.getActivities({ leadId: res.result })
        return res
    }

    render() {
        const {
            leads,
            criteria,
            employees,
            industries,
            totalItems,
            leadCategories,
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    Quote Surveillance
                    {' '}
                    <span className="text-muted">
                        (
                        {leads.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <Card.Content modifiers="pB_0">
                    <Alert bsStyle="warning" style={{ fontSize: '14px' }}>
                        This list shows all leads that are
                        {' '}
                        <strong>still open for quotes </strong>
                        (which means the lead's current quotes count is lower than the set max quotes). You will find leads that have expired and also leads that have expiration set for 5 work hours into the future, as long as these leads are not completed yet aka still open for quotes.
                        <br />
                        You can take the following actions here:
                        <ol>
                            <li>“complete lead”: this will automatically set down the lead's expected max quotes to the number of quotes that the lead currently has and will hence close the lead, which means the lead leaves Quote Surveillance. (If you want to open a lead again, please just adjust the max quotes for the lead on the validation page.)</li>
                            <li>“boost lead”: this sets the expiration of any lead in the list to up to 5 work hours into the future, which means the lead stays visible in Quote Surveillance and indicates that new quotes should be found for this lead.</li>
                        </ol>
                    </Alert>
                </Card.Content>
                <ProviderTable
                    pageName="quotesSurveillance"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.validatorId}
                            items={employees}
                            name="validatorId"
                            label="Validator"
                            search={false}
                        />
                        <DropdownFilter
                            selectedIds={criteria.industry}
                            items={industries}
                            name="industry"
                            type="radio"
                            label="Industry"
                            search={false}
                        />
                        <DropdownFilter
                            selectedIds={criteria.leadCategory}
                            items={leadCategories}
                            name="leadCategory"
                            type="radio"
                            label="Category"
                            search={false}
                        />
                        <LocationFilter
                            label="Location"
                            name="location"
                            locationId={criteria.locationId}
                            geoDistance={criteria.geoDistance}
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="id">ID</Column>
                            <Column name="category">Lead category</Column>
                            <Column name="validator">Validator</Column>
                            <Column name="client_name">Client name</Column>
                            <Column name="customer_type">Client type</Column>
                            <Column name="lead_title">Lead title</Column>
                            <SortColumn name="expires_at">Time left</SortColumn>
                            <SortColumn name="validated_at">Validated at</SortColumn>
                            <Column name="zip_code_city">Location</Column>
                            <Column name="industry">Industry</Column>
                            <Column name="sub_industry">Sub industry</Column>
                            {config.geoCode === 'de' ? (
                                <Column name="quotes" title="Allocations suggestions">
                                    Suggestions (accepted/count)
                                </Column>
                            ) : <Column style={{ display: 'none' }} />}
                            <Column name="quotes">Quotes</Column>
                            <Column name="notes">Notes</Column>
                            <Column name="quote_reserved">Unpublished quotes</Column>
                            <Column>Customer choice</Column>
                            <Column name="action">Action</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={leads}
                            noDataText="We couldn't find any partners matching your selection."
                        >
                            <SurveillanceTableRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
