import React, { PureComponent } from 'react'
import {
    formValueSelector,
    SubmissionError,
    initialize,
    submit,
    change,
    arrayPush,
} from 'redux-form'
import { Row, Col, Label } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import PageTitle from '../elements/PageTitle'
import * as leads from '../../actions/leads'
import LoaderComponent from '../elements/LoaderComponent'
import LeadDetailsCard from './lead-validation-cards/LeadDetailsCard'
import { LeadInformationCard } from './lead-validation-cards/LeadInformationCard'
import { LeadCaseInformationCard } from './lead-validation-cards/LeadCaseInformationCard'
import LeadPartnersRequirementsCard from './lead-validation-cards/LeadPartnersRequirementsCard'
import LeadProductsCard from './lead-validation-cards/LeadProductsCard'
import { getLeadValidationDataFields } from '../../actions/datafields'
import LeadNewsFeedCard from './lead-validation-cards/LeadNeewsFeedCard'
import LeadValidationIntervals from './lead-validation-cards/LeadValidationIntervalsCard'
import LeadActionBarCard from './lead-validation-cards/LeadActionBarCard'
import ReduxForm from '../elements/forms/ReduxForm'
import { Card } from '../blocks/Card'
import styled from 'styled-components'
import LeadStatusCard from './lead-validation-cards/LeadStatusCard'
import {
    leadValidationDataFieldsSelector,
    leadQuotesSelector,
} from '../../selectors/lead'
import config from '../../config'
import { partnerListSelector } from '../../selectors/partner'
import moment from 'moment-timezone'
import { set, cloneDeep, groupBy, flattenDeep, union, get } from 'lodash'
import { hideModal, showModal } from '../../actions/modal'
import { createMatchPrediction } from '../../actions/employees'
import { locationSelector } from '../../selectors/locations'
import { createQuoteFee, deleteQuoteFee } from '../../actions/quotes'
import { push } from 'connected-react-router'
import { connect } from '../../utils/connect'
import { isLoanLead } from '../../utils/isLoanLead'
import { Lead } from '../../reducers/types/entities/Lead'
import { Industry } from '../../reducers/types/entities/Industry'
import { LeadType } from '../../reducers/types/entities/LeadType'
import { Quote } from '../../reducers/types/entities/Quote'
import { DataField } from '../../reducers/types/entities/DataField'
import { Partner } from '../../reducers/types/entities/Partner'
import { Location } from '../../reducers/types/entities/Location'
import { Sector } from '../../reducers/types/entities/Sector'
import { getIndustries } from '../../state/industries/actions'
import { getLeadTypes } from '../../state/leadTypes/actions'
import { getSectors } from '../../state/sectors/actions'
import { allLeadTypesSelector } from '../../state/leadTypes/selectors'
import { allSectorsSelector } from '../../state/sectors/selectors'
import { getCertifications, getLeadCertifications, updateLeadCertification } from '../../state/certifications/actions'
import { allIndustriesSelector } from '../../state/industries/selectors'
import GeographyCard from './lead-validation-cards/GeographyCard'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { getCompanySizes, getLeadCompanySizes, updateLeadCompanySizes } from '../../state/companysizes/actions'
import {
    ApiActionCreatorConvertedResponse,
    convertApiActionCreatorResponse,
} from '../../middleware/api'
import { returnLocalTimezone } from '../../utils'
import { isCustomerChoice } from '../../utils/isCustomerChoice'

const ValidationPageContent = styled(Card.Content)`
  margin-bottom: 80px;
  padding: 0;
`

const FadeText = styled.span`
    font-size: 22px;
    color: #8F8F8F;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 0 20px;
  grid-template-columns: 1fr 1fr;
  > * {
    &:nth-child(1) { grid-area: a;}
    &:nth-child(2) { grid-area: b;}
    &:nth-child(3) { grid-area: c;}
  }
  grid-template-areas: "a c" "b c";
`

const mapValuesDeep = element => (
    element
        ? element instanceof Object
            ? flattenDeep(Object.values(element).map(mapValuesDeep))
            : element
        : element
)

const insertSelectedValue = (field, leadAttributes, currentAttributeFieldValue) => {
    if (leadAttributes[field.id]) {
        return leadAttributes[field.id].selected
    } else if (currentAttributeFieldValue) {
        return currentAttributeFieldValue.selected
    }
    return []
}

const placementFields = [
    'language_requirement',
    'size_of_partner',
    'accounting_software',
    'certification',
    'timeframe',
    'starting_date',
    'accountant_needs_to_sit_with_customer',
    'is_digital',
    'wants_local_partner',

    'personality_prio_three',
    'personality_prio_two',
    'personality_prio_one',

    'degree_of_automated work',
    'reason_of_change',
    'communication_style',

    'amount_previous_partners',
    'state_of_bookkeeping',

    'vision_for_future',
    'experience_business_owner',

    'budget_to',
    'budget_from',

    'fiscal_year_to',
    'fiscal_year_from',

    'number_of_receipts',
    'vat_payments',

    'number_of_employees',
    'annual_revenue',

    'employees',
    'yearly_revenue',

    'establishing_year',
    'industry',
]

interface LeadValidationStateProps {
    lead: Lead
    isLoading: boolean
    isValidating: boolean
    isLoadingLead: boolean
    industries: Industry[]
    industryId?: string
    leadTypes: LeadType[]
    leadQuotes: Quote[]
    selectedIndustries: {
        industries: Lead['industries']
    }
    primarySectors: Sector[]
    attributeDataFields: DataField[]
    dataFields: DataField[]
    isLoadingDataFields: boolean
    partners: Partner[]
    modalResult
    employeeId: number
    locations: Location[]
    createQuoteFeePermission,
    deleteQuoteFeePermission,
    validateWithoutFeesPermission
}
interface LeadValidationDispatchProps {
    deleteExcludedPartner: typeof leads.deleteExcludedPartner
    getLeadsAggregations: typeof leads.getLeadsAggregations
    getExcludedPartners: typeof leads.getExcludedPartners
    leadActionValidate: typeof leads.leadActionValidate
    leadActionDiscard: typeof leads.leadActionDiscard
    saveLead: typeof leads.saveLead
    getLead: typeof leads.getLead
    getLeadCertifications: ApiActionCreatorConvertedResponse
    getCertifications: typeof getCertifications
    getIndustries: ApiActionCreatorConvertedResponse
    getLeadTypes: typeof getLeadTypes
    getLeadValidationDataFields: typeof getLeadValidationDataFields
    createMatchPrediction: typeof createMatchPrediction
    createQuoteFee: typeof createQuoteFee
    deleteQuoteFee: typeof deleteQuoteFee
    initialize: typeof initialize
    getSectors: typeof getSectors
    hideModal: typeof hideModal
    showModal: typeof showModal
    submit: typeof submit
    change: typeof change
    arrayPush: typeof arrayPush
    push: typeof push
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getCompanySizes: ApiActionCreatorConvertedResponse
    getLeadCompanySizes: ApiActionCreatorConvertedResponse
    updateLeadCompanySizes: typeof updateLeadCompanySizes
    updateLeadCertification: typeof updateLeadCertification
}
interface LeadValidationPathProps {
    match: {
        params: {
            leadId
        }
    }
}

type LeadValidationModel = LeadValidationStateProps & LeadValidationDispatchProps & LeadValidationPathProps

interface LeadValidationStateModel {
    validate: string
    validationError
    showPhoneNumber: boolean
    showForMarketplace: boolean
    adjustAmount: boolean
    isLoadingBusinessUnits: boolean
    numberOfBusinessUnitsForLead: number
    businessUnits: BusinessUnit[]
    companySizes: any[]
    isLoading: boolean
}

@connect<LeadValidationStateProps, LeadValidationDispatchProps, LeadValidationPathProps>(
    (state, { match: { params: { leadId } } }) => ({
        lead: state.entities.leads[leadId],
        isLoading: state.pages.leadValidation.isLoading,
        isValidating: state.pages.leadValidation.isValidating,
        isLoadingLead: state.pages.leadValidation.isLoadingLead,
        industries: allIndustriesSelector(state),
        industryId: state.auth.wantedIndustryId ? String(state.auth.wantedIndustryId) : undefined,
        leadTypes: allLeadTypesSelector(state),
        primarySectors: allSectorsSelector(state),
        leadQuotes: leadQuotesSelector(state, leadId),
        selectedIndustries: formValueSelector('leadValidation')(state,
            'industries.primary.id',
            'industries.secondary.id',
            'industries.subIndustries'
        ),
        dataFields: leadValidationDataFieldsSelector(state),
        attributeDataFields: formValueSelector('leadValidation')(state,
            'attributes'
        ),
        isLoadingDataFields: state.pages.leadValidation.isLoadingDataFields,
        partners: partnerListSelector(state),
        modalResult: state.modal,
        employeeId: state.auth.employeeId,
        locations: locationSelector(state),
        createQuoteFeePermission: state.entities.permissions.lead_quote_fee_create,
        deleteQuoteFeePermission: state.entities.permissions.lead_quote_fee_delete,
        validateWithoutFeesPermission: state.entities.permissions.validate_without_fees,
    }),
    {
        deleteExcludedPartner: leads.deleteExcludedPartner,
        getLeadsAggregations: leads.getLeadsAggregations,
        getExcludedPartners: leads.getExcludedPartners,
        leadActionValidate: leads.leadActionValidate,
        leadActionDiscard: leads.leadActionDiscard,
        saveLead: leads.saveLead,
        getLead: leads.getLead,
        getLeadCertifications: convertApiActionCreatorResponse(getLeadCertifications),
        getCertifications,
        getIndustries: convertApiActionCreatorResponse(getIndustries),
        getLeadTypes,
        getLeadValidationDataFields,
        createMatchPrediction,
        createQuoteFee,
        deleteQuoteFee,
        initialize,
        getSectors,
        hideModal,
        showModal,
        submit,
        change,
        arrayPush,
        push,
        getPartnerBusinessUnits,
        getCompanySizes: convertApiActionCreatorResponse(getCompanySizes),
        getLeadCompanySizes: convertApiActionCreatorResponse(getLeadCompanySizes),
        updateLeadCompanySizes,
        updateLeadCertification,
    }
)

export default class LeadValidationPage extends PureComponent<LeadValidationModel, LeadValidationStateModel> {
    state = {
        validate: '',
        showPhoneNumber: false,
        showForMarketplace: false,
        validationError: null,
        adjustAmount: false,
        isLoadingBusinessUnits: false,
        numberOfBusinessUnitsForLead: 0,
        businessUnits: [],
        companySizes: [],
        isLoading: false,
    }

    async getBusinessUnits() {
        await this.setState({ isLoadingBusinessUnits: true })
        const businessUnitResponse: any = await this.props.getPartnerBusinessUnits({
            isActive: '1',
            hasBusinessModel: '1',
            segmentationCriteria: [
                'sectors',
                'industries',
                'certifications',
                'languages',
                'client_types',
                'digital_work_preferences',
                'revenues',
                'fees',
                'exclusions',
                'primary_industries',
                'locations',
                'company_sizes',
                'regions',
            ].join(','),
            segmentedForLeadId: this.props.match.params.leadId,
            limit: '0',
            sort: 'auto_allocation',
            collapse: 'partner_id',
        })

        this.setState({
            numberOfBusinessUnitsForLead: businessUnitResponse.pagination.total,
            isLoadingBusinessUnits: false,
        })
    }

    calculateData(activePeriod: string, bu: BusinessUnit) {
        const allocationType = activePeriod === 'currentMonth'
            ? 'currentAllocation'
            : 'accumulatedAllocation'

        let lbLimit = bu.currentAllocation.allocationQuotaPerRefill
        if (activePeriod === 'sinceStart') lbLimit = bu.accumulatedAllocation.allocationQuota
        const lbCount = bu[allocationType].normalQuotaAllocationCount
        const overdeliveryCount = bu[allocationType].justInTimeAllocationCount + bu[allocationType].overdeliveryAllocationCount

        const ppsCount = bu[allocationType].payPerSpotAllocationCount
        const ppsLimit = bu[allocationType].payPerSpotAllocationLimit

        return { lbLimit, lbCount, overdeliveryCount, ppsCount, ppsLimit }
    }

    async componentDidMount() {
        const { leadId } = this.props.match.params

        try {
            await this.setState({ isLoading: true })
            const [
                ,
                ,
                ,
                ,
                companySizesResponse,
                resIndustries,
                resLeadCertifications,
                resLeadCompanySizes,
            ] = await Promise.all([
                this.props.getLead(leadId),
                this.props.getExcludedPartners(leadId),
                this.props.getSectors({ geoCode: config.geoCode, sort: 'display_name' }),
                this.props.getLeadTypes(config.geoCode),
                this.props.getCompanySizes(config.geoCode),
                this.props.getIndustries({ geoCode: config.geoCode }),
                this.props.getLeadCertifications(leadId),
                this.props.getLeadCompanySizes(leadId),
            ])

            this.setState({
                companySizes: Object.values(companySizesResponse.entities.companySizes),
            })

            await this.props.getIndustries({
                geoCode: config.geoCode,
                parentIndustryId: resIndustries.result,
            }, true)

            const lead = { ...this.props.lead }

            lead.attributes = []

            const industriesPrimary = lead.industries && lead.industries.primary

            this.initializeCertification(industriesPrimary && industriesPrimary.id || 0, lead)

            const certifications = resLeadCertifications.entities && resLeadCertifications.entities.certifications && Object.values(resLeadCertifications.entities.certifications) || []
            const companySizes = resLeadCompanySizes.entities && resLeadCompanySizes.entities.companySizes && Object.values(resLeadCompanySizes.entities.companySizes) || []

            this.props.initialize('leadValidation', { ...lead, companySizes, certifications })

            this.setState({ showForMarketplace: !!lead.quotesInfo?.quoteFee })

        } catch (err) {
            console.error(err)
        } finally {
            this.setState({ isLoading: false })
            this.getBusinessUnits()
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.modalResult.confirm && prevProps.modalResult.action === 'save_and_validate') {
            await this.setState({ validate: 'validate' })
            this.props.submit('leadValidation')
        }

        if (
            this.props.selectedIndustries.industries &&
            this.props.selectedIndustries.industries.primary &&
            JSON.stringify(this.props.selectedIndustries.industries) !== JSON.stringify(prevProps.selectedIndustries.industries)
        ) {
            const primaryAndSecondaryIndustryIds = [
                Number(this.props.selectedIndustries.industries.primary.id),
                this.props.selectedIndustries.industries.secondary && Number(this.props.selectedIndustries.industries.secondary.id),
            ]

            if (!this.hasServiceFeeIndustry(primaryAndSecondaryIndustryIds)) {
                this.props.lead.isServiceFee = false
                this.props.change('leadValidation', 'serviceFee.amount', 0)
            }

            if(prevProps.attributeDataFields === this.props.attributeDataFields){
                if (get(prevProps.selectedIndustries.industries, 'secondary.id') !== get(this.props.selectedIndustries.industries, 'secondary.id')) {
                    const secondaryIndustryId = Number(get(this.props.selectedIndustries.industries, 'secondary.id'))
                    const subIndustry = this.props.industries.find(industry => industry.id === secondaryIndustryId)
                    const selectedSubIndustries = get(prevProps.selectedIndustries, 'subIndustries')

                    if (!selectedSubIndustries || !selectedSubIndustries.some(sub => subIndustry && sub.id === subIndustry.id)) {
                        this.props.arrayPush('leadValidation', 'industries.subIndustries', {
                            id: subIndustry.id,
                            name: subIndustry.name,
                            slug: subIndustry.slug,
                        })
                    }
                }
            }

            const industryIds = [
                Number(this.props.selectedIndustries.industries.primary.id),
                ...this.props.selectedIndustries.industries.subIndustries.map(industry => industry.id),
            ]

            try {
                await this.props.getLeadValidationDataFields({ industryId: industryIds })

                const leadAttributes = this.props.lead.attributes

                const attributes = this.props.dataFields
                    .map(field => {
                        const currentAttributeFieldValue = this.props.attributeDataFields.find(attr => attr.id === field.id)
                        return {
                            ...field,
                            selected: insertSelectedValue(field, leadAttributes, currentAttributeFieldValue),
                        }
                    })
                    .sort((a, b) => placementFields.indexOf(b.identifier) - placementFields.indexOf(a.identifier))

                this.props.change('leadValidation', 'attributes', attributes.filter(att => att.geo.code === this.props.lead.geo.code))
            } catch (err) {
                console.error(err)
            }
        }
    }

    hasServiceFeeIndustry = industryIds => {
        const serviceFeeIndustryIds = [ 2, 36, 252, 309, 310, 365 ]
        const industryIdMatches = industryIds.map(industryId => serviceFeeIndustryIds.includes(industryId))

        return industryIdMatches.includes(true)
    }

    showModal = data => {
        this.props.showModal({
            ...data,
            leadId: this.props.lead.id,
        })
    }

    hideModal = () => this.props.hideModal()

    checkEmployeeLeadPrediction = (data, omitThrow?) => {
        const employeeLeadPredictionErrors = {}
        if (data.quotesInfo.employeeLeadPrediction || this.state.validate === 'save_and_validate') {
            if (!get(data, 'quotesInfo.employeeLeadPrediction.matchBy'))
                set(employeeLeadPredictionErrors, 'matchBy', 'Expected closing date is required!')
            if (!moment(get(data, 'quotesInfo.employeeLeadPrediction.matchBy')).isValid())
                set(employeeLeadPredictionErrors, 'matchBy', 'Expected closing date is not valid!')
            if (!get(data, 'quotesInfo.employeeLeadPrediction.probabilityOfMatch'))
                set(employeeLeadPredictionErrors, 'probabilityOfMatch', 'Chance of closing is required!')
        }

        if (omitThrow) return employeeLeadPredictionErrors

        let error = {}
        if (Object.keys(employeeLeadPredictionErrors).length) {
            error = { ...error, quotesInfo: { employeeLeadPrediction: employeeLeadPredictionErrors } }
            this.setState({ validationError: error })
            throw new SubmissionError(error)
        }
        this.setState({ validationError: null })
    }

    checkProductValidation = (data, omitThrow?) => {
        const productsErrors = []
        if (data.products && data.products.length) {
            data.products.forEach((el, i) => {
                const productLine = { product: '', type: '' }
                if (!el.product) {
                    productLine.product = 'Products name is required!'
                    productsErrors[i] = productLine
                } else if (el.product === 'shouldMatch') {
                    productLine.product = 'Product not exist!'
                    productsErrors[i] = productLine
                }
                if (!el.type) {
                    productLine.type = 'Product type is required!'
                    productsErrors[i] = productLine
                }
            })
        }
        if (omitThrow) return productsErrors
        let error = {}
        if (productsErrors.length)
            error = { ...error, products: productsErrors }
        if (Object.keys(error).length) {
            this.setState({ validationError: error })
            throw new SubmissionError(error)
        }
        this.setState({ validationError: null })
    }

    checkValidation = data => {
        const error = {}
        const zipMatched = this.props.locations.find(loc =>
            loc.zipCode == data.geo.zipCode && loc.cityName === data.geo.cityName
        )

        if (zipMatched) {
            zipMatched.address = data.geo.address
            data.geo = zipMatched
        }

        const isLoanLeadType = isLoanLead(data.industries.primary)
        if (!get(data, 'customerInfo.firstName'))
            set(error, 'customerInfo.firstName', 'First name is required!')
        if (!get(data, 'customerInfo.lastName'))
            set(error, 'customerInfo.lastName', 'Last name is required!')
        if (!get(data, 'geo.zipCode') || !zipMatched)
            set(error, 'geo.zipCode', zipMatched ? 'Zip code is required!' : 'Zip code is not match!')
        if (!get(data, 'geo.cityName') || !zipMatched)
            set(error, 'geo.cityName', 'City name is not match!')
        if (!get(data, 'customerInfo.email'))
            set(error, 'customerInfo.email', 'Customer mail is required!')
        if (!get(data, 'customerInfo.phone'))
            set(error, 'customerInfo.phone', 'Customer phone is required!')
        if (!get(data, 'industries.primary.id'))
            set(error, 'industries.primary.id', 'Primary industries is required!')

        if (!this.props.validateWithoutFeesPermission && data.isServiceFee && !Number(get(data, 'serviceFee.amount')))
            set(error, 'serviceFee.amount', 'Service fee is required!')

        if (!get(data, 'products.length'))
            set(error, 'products', 'Should be at least one product!')

        if (!isLoanLeadType) {
            if (!get(data, 'category.id'))
                set(error, 'category.id', 'Client classification is required!')

            if (!get(data, 'industries.secondary.id') ||
                !get(data, 'industries.subIndustries', []).length)
                set(error, 'industries.secondary.id', 'Sub industries are required!')
            if (!parseInt(get(data, 'customerInfo.customerType.id', '0'), 10))
                set(error, 'customerInfo.customerType.id', 'Type of client is required!')
            if (!data.title)
                set(error, 'title', 'Title is required!' )

            const productsErrors = this.checkProductValidation(data, true)

            if (productsErrors.length)
                set(error, 'products', productsErrors)
        }

        if (Object.keys(error).length) {
            this.setState({ validationError: error })
            throw new SubmissionError(error)
        }
        this.setState({ validationError: null })
    }

    handlePromise = promise => promise
        .then(() => null)
        .catch((err) => err)

    confirmEmptyMarketingDescription = async () => {
        return new Promise((resolve, reject) =>
            this.props.showModal({
                name: 'leadMarketplaceDescription',
                headerText: 'Marketplace description',
                bodyText: <h4>Marketplace description is empty.</h4>,
                btnApproveText: 'Save anyway',
                btnDenyText: 'Cancel',
                btnApproveModifiers: [ 'warning' ],
                onConfirm: () => {
                    resolve()
                },
                onHide: () => {
                    reject()
                },
            })
        )
    }

    handleFormUpdate = async leadBody => {
        // Products need to be always validated, though you should be able to save without filling in products
        if (
            [ 'save', 'save_and_resend' ].includes(this.state.validate) &&
            leadBody.products.find(product => product.product || product.type)
        ) {
            this.checkProductValidation(leadBody)
        }

        if (this.props.lead.validatedAt) {
            // If already validated
            this.checkValidation(leadBody)
        }

        if (this.state.validate === 'save_and_validate') {
            this.checkValidation(leadBody)
            this.showModal({
                name: 'leadValidateConfirm',
                action: this.state.validate,
            })
            return
        }

        this.checkEmployeeLeadPrediction(leadBody)
        if (leadBody.quotesInfo.employeeLeadPrediction) {
            const prediction = leadBody.quotesInfo.employeeLeadPrediction
            const matchBy = prediction.matchBy

            this.props.createMatchPrediction(this.props.employeeId, {
                leadId: leadBody.id,
                quoteFee: {
                    amount: prediction.quoteFee ? prediction.quoteFee.amount : 0,
                    currency: 'EUR',
                },
                matchBy: typeof matchBy === 'string' ? matchBy : matchBy.format(),
                probabilityOfMatch: prediction.probabilityOfMatch,
            })
        }

        if ((!leadBody.marketplaceDescription || leadBody.marketplaceDescription.length === 0) &&
            this.state.showForMarketplace && this.props.createQuoteFeePermission && this.props.deleteQuoteFeePermission) {
            let shouldSaveEmptyMarketingDescription = false
            await this.confirmEmptyMarketingDescription().then(() => {
                shouldSaveEmptyMarketingDescription = true
                this.hideModal()
            }).catch(() => {
                this.hideModal()
            })

            if (!shouldSaveEmptyMarketingDescription) {
                return
            }
        }

        if (this.props.createQuoteFeePermission && this.props.deleteQuoteFeePermission) {
            if (!this.state.showForMarketplace && this.props.lead.quotesInfo?.quoteFee) {
                this.props.deleteQuoteFee(leadBody.id)
            }
            if (this.state.showForMarketplace && !this.props.lead.quotesInfo?.quoteFee) {
                const quoteFee = {
                    leadId: leadBody.id,
                    employeeId: this.props.employeeId,
                    quoteFee: {
                        amount: 0,
                        currency: config.currency[leadBody.geo.code],
                    },
                }
                this.props.createQuoteFee(quoteFee)
            }
        }

        if (leadBody.companySizes) {
            const ids = leadBody.companySizes.map(cs => cs.id)
            this.props.updateLeadCompanySizes(this.props.lead.id, ids)
        }

        if (leadBody.certifications) {
            const ids = leadBody.certifications.map(cer => cer.id)
            this.props.updateLeadCertification(this.props.lead.id, ids)
        }

        const body = cloneDeep(leadBody)
        body.expiresAt = body.expiresAt && moment(body.expiresAt).toISOString(true)
        body.attributes = body.attributes.filter(field => field.selected && field.selected.length)
        delete body.quotesInfo.quotes
        delete body.quotesInfo.employeeLeadPrediction

        const saveLeadError = await this.handlePromise(this.props.saveLead(body))

        if (saveLeadError) {
            toastr.error('Error!', 'There was a problem saving the lead data.')
            return
        }

        if (this.state.validate === 'validate') {
            const validateLeadError = await this.handlePromise(this.props.leadActionValidate(body.id))

            if (validateLeadError) {
                toastr.error('Error!', 'There was a problem validating lead.')
                return
            }

            if (get(this.props.lead, 'industries.primary.isAutoAllocatable') && Number(body.category.id) === 1) {
                this.props.push(`/leads/validation?timezone=${returnLocalTimezone()}`)
                return
            }
        }

        if (this.state.validate !== 'save') {
            this.props.push(
                `/leads/${body.id}/send-email${body.category && Number(body.category.id) === 2 ? '/exclusive' : '-regions'}`
            )
        }
    }

    resetSubIndustryFields = data => {
        this.initializeCertification(data[0], this.props.lead)
        this.props.change('certificationsField', 'certifications', [])
        this.props.change('leadValidation', 'industries.subIndustries', [])
        this.props.change('leadValidation', 'industries.secondary', {})
        this.saveLead()
    }

    initializeCertification(primaryId, lead) {
        this.props.getCertifications({
            geoCode: lead.geo.code,
            industryId: primaryId,
            isSegmentable: true,
        })
    }

    saveAction = (event, saveType) => {
        event.preventDefault()
        switch (saveType) {
            case 'save_and_validate':
                this.setState({ validate: 'save_and_validate' }, () => {
                    this.props.submit('leadValidation')
                })
                break
            case 'save_and_resend':
                this.setState({ validate: 'save_and_resend' }, () => {
                    this.props.submit('leadValidation')
                })
                break
            default:
                this.setState({ validate: 'save' }, () => {
                    this.props.submit('leadValidation')
                })
                break
        }
    }

    showCallNumberAction = () => {
        this.setState({ showPhoneNumber: true })
    }

    adjustAmount = () => {
        this.setState({ adjustAmount: true })
    }

    saveLead = () => {
        this.setState({ validate: 'save' }, () => {
            this.props.submit('leadValidation')
        })
    }

    handleMarketplaceToggle = () => {
        this.setState(prevState => ({
            showForMarketplace: !prevState.showForMarketplace
        }))
    }

    render() {
        const {
            lead,
            isLoadingLead,
            leadTypes,
            leadQuotes,
            selectedIndustries,
            primarySectors,
            isLoadingDataFields,
            isValidating,
        } = this.props

        const { companySizes } = this.state

        if (isLoadingLead || this.state.isLoading) return <LoaderComponent type="logo" />

        const selectedPrimaryIndustryId = selectedIndustries.industries &&
            selectedIndustries.industries.primary && selectedIndustries.industries.primary.id

        const isLoanLeadType = lead.industries && lead.industries.primary ? isLoanLead(lead.industries.primary) : false

        const separateByParentIndustryId = groupBy(this.props.industries, 'parentIndustryId')

        const parentIndustries = separateByParentIndustryId[0] || []

        const subIndustries = Number(selectedPrimaryIndustryId) !== 0 &&
            separateByParentIndustryId[selectedPrimaryIndustryId] ||
            []

        const listOfError = union(mapValuesDeep(this.state.validationError))

        return <ValidationPageContent
            isLoading={isValidating}
            preloadTextInfo="Validating lead ..."
        >
            <PageTitle title={lead.customerInfo.firstName + ' ' + lead.customerInfo.lastName}/>
            <div className="header">
                <h1>
                    {lead.customerInfo.firstName + ' ' + lead.customerInfo.lastName}
                    <FadeText>
                        {' '}
                        (id:
                        {lead.id}
                        )
                    </FadeText>
                    {isCustomerChoice(lead) && <Label bsStyle="warning" style={{ marginLeft: '20px' }}>Customer Choice</Label>}
                </h1>
            </div>
            {lead.quotesInfo.quoteFee && lead.status === 'validated' && <Card modifiers={[ 'info' ]}>
                <Card.Content>
                    Note! This lead has quote fee and may have one or several partners paying to quote
                </Card.Content>
            </Card>}
            {this.state.validationError && <Card modifiers={[ 'error' ]}>
                <Card.Header>Missing information</Card.Header>
                <Card.Content>
                    <div>Before you can save/validate the lead you must correct the following:</div>
                    <ul>
                        {listOfError.map((el, index) => <li key={index}>{el}</li>)}
                    </ul>
                </Card.Content>
            </Card>}
            <ReduxForm
                form="leadValidation"
                onSubmit={this.handleFormUpdate}
            >
                <Row>
                    <Col md={4}>
                        <LeadDetailsCard
                            showPhoneNumber={this.state.showPhoneNumber}
                            lead={lead}
                        />
                        <LeadCaseInformationCard
                            handlePrimaryIndustryChange={this.resetSubIndustryFields}
                            isLoadingDataFields={isLoadingDataFields}
                            parentIndustries={parentIndustries}
                            primarySectors={primarySectors}
                            subIndustries={subIndustries}
                            leadTypes={leadTypes}
                            geoCode={lead.geo.code}
                            isLoanLeadType={isLoanLeadType}
                        />
                    </Col>
                    <Col md={8}>
                        <Grid>
                            <LeadValidationIntervals industryId={this.props.industryId}/>
                            <LeadStatusCard lead={lead} leadQuotes={leadQuotes}/>
                            <LeadInformationCard lead={lead} />
                        </Grid>
                        <LeadProductsCard
                            leadId={lead.id}
                            clientId={lead.client.id}
                            adjustAmount={this.state.adjustAmount}
                            handleMarketplaceToggle={this.handleMarketplaceToggle}
                            showForMarketplace={this.state.showForMarketplace}
                        />
                        <Row>
                            <Col md={6}>
                                {!isLoanLeadType && <LeadPartnersRequirementsCard
                                    isLoadingDataFields={isLoadingDataFields}
                                    selectedPrimaryIndustryId={selectedPrimaryIndustryId}
                                    leadId={lead.id}
                                    companySizes={companySizes}
                                />}
                                <GeographyCard lead={lead}/>
                            </Col>
                            <Col md={6}>
                                <LeadNewsFeedCard leadId={lead.id} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ReduxForm>

            <LeadActionBarCard
                saveAction={this.saveAction}
                handleAdjustAmount={this.adjustAmount}
                showCallNumberAction={this.showCallNumberAction}
                showPhoneNumber={this.state.showPhoneNumber}
                numberOfBusinessUnitsForLead={this.state.numberOfBusinessUnitsForLead}
                isLoadingBusinessUnits={this.state.isLoadingBusinessUnits}
                isAutoAllocationEnabled={Boolean(get(this.props.lead, 'industries.primary.isAutoAllocatable'))}
                leadId={lead.id}
            />
        </ValidationPageContent>
    }
}
