import { createSelector } from 'reselect'
import moment from 'moment'
import { monthDiff } from '../../utils'

const entitiesAllocationsQuotas = state => state.entities.allocationsQuotas

const businessUnitAllocationsQuotasId = state => state.subjects.allocationsQuotas.businessUnit.ids

const filterProps = state => state.router.location

export const businessUnitAllocationsQuotasSelector = createSelector(
    businessUnitAllocationsQuotasId, entitiesAllocationsQuotas,
    (ids, entities) => ids.map(id => entities[id])
)

export const allocationTotalQuotaPerRefillSelector = createSelector(
    entitiesAllocationsQuotas, businessUnitAllocationsQuotasId, filterProps,
    (quotas,  ids, dateFilter) =>
        ids.reduce((acc, id, i, arr) => {
            const currentQuota = quotas[id]
            const nextQuota = quotas[arr[i + 1]]
            const x = moment(currentQuota.effectiveAt)
            const query = dateFilter.query
            const filterStartsAt = moment(query.startsAt)
            let count = 0
            if (!i) {
                const filterEndsAt = moment(query.endsAt)
                if (filterEndsAt.isAfter(currentQuota.effectiveAt)) {
                    const durationStartDate = filterStartsAt.isAfter(currentQuota.effectiveAt) ? filterStartsAt : x
                    const monthDuration1 = monthDiff(durationStartDate, filterEndsAt) + 1
                    count = currentQuota.allocationQuotaPerRefill * monthDuration1
                } else {
                    count = currentQuota.allocationQuotaPerRefill
                }
            }
            if (!nextQuota) return arr.length === 1 ? count : acc
            const y = moment(nextQuota.effectiveAt)
            const monthDuration = monthDiff(y, x)

            return acc + ((monthDuration || 1) * nextQuota.allocationQuotaPerRefill) + count
        }, 0)
)
