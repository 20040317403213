import React, { Children, cloneElement, PureComponent } from 'react'
import { TableProviderContext } from './_Provider'
import { Header, SortColumn } from '../../elements/table'

const emptyFunction = e => null

class _Header extends PureComponent {
    render() {
        const { children } = this.props

        return <Header>
            <TableProviderContext.Consumer>
                {({ onSort, visibleColumns, sort }) =>
                    Children.map(children, (column: any) =>
                        (visibleColumns && visibleColumns.length)
                            ? visibleColumns.some(el => el === column.props.name) &&
                            cloneElement(column, {
                                onClick: column.type === SortColumn ? onSort : emptyFunction,
                                sort,
                            })
                            : cloneElement(column, {
                                onClick: column.type === SortColumn ? onSort : emptyFunction,
                                sort,
                            })
                    )
                }
            </TableProviderContext.Consumer>
        </Header>
    }
}

export default _Header
