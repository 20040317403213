import { CALL_API } from '../middleware/api'
import { phoneVerification } from '../schemas'

export const VERIFY_PHONE_NUMBER = 'customers/phoneverifications/VERIFY_PHONE_NUMBER'
export const VERIFY_PHONE_NUMBER_SUCCESS = 'customers/phoneverifications/VERIFY_PHONE_NUMBER_SUCCESS'
export const VERIFY_PHONE_NUMBER_FAIL = 'customers/phoneverifications/VERIFY_PHONE_NUMBER_FAIL'

export const verifyPhoneNumber = (
    clientId: number
) => ({
    [CALL_API]: {
        types: [
            VERIFY_PHONE_NUMBER,
            VERIFY_PHONE_NUMBER_SUCCESS,
            VERIFY_PHONE_NUMBER_FAIL,
        ],
        endpoint: '/customers/phoneverifications/actions',
        schema: phoneVerification,
        options: {
            method: 'post',
            body: {
                action: 'verify_by_employee',
                client_id: clientId,
            },
        },
    },
})
