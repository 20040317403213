import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import {
    GET_BUSINESS_UNIT_ALLOCATIONS_QUOTES,
    CREATE_BUSINESS_UNIT_ALLOCATION_QUOTES,
    DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES,
} from './actions'

const allocationsQuotas = combineReducers({
    businessUnit: generalSubjectReducer(GET_BUSINESS_UNIT_ALLOCATIONS_QUOTES, CREATE_BUSINESS_UNIT_ALLOCATION_QUOTES, DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES),
})

export default allocationsQuotas
