import React, { memo, Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Card } from '../../blocks/Card'
import LoadingBar from '../../elements/LoadingBar'
import moment from 'moment'
import { Model as BusinessUnitSegmentationModel } from './BusinessUnitSegmentation'
import { Button } from '../../elements/Button'
import { Flex } from '../../elements/Flex'
import { metaTextColor } from '../../../utils/variables'
import GeoRegionsMap from '../../elements/GeoRegionsMap'
import { FormattedAmount } from '../../elements/FormattedAmount'

const MapElement = styled.div`
  height: 86vh;
`

const EditButton = styled(Button)`
    z-index: 1;
    position: absolute;
    bottom: 20px;
    left: 20px;
`

const Ul = styled.ul`
  padding: 0;
  li {
    font-size: 13px;
    line-height: 22px;
    list-style: none;
    color: ${metaTextColor};
    &:before {
        content: '* ';
    }
  }
`

const Text = styled.span`
    font-size: 13px;
    line-height: 22px;
    color: ${metaTextColor};
`

type SegmentationViewModel = Pick<BusinessUnitSegmentationModel,
    'allocationsQuotas'
    | 'businessUnit'
    | 'entitiesGeoRegions'
    | 'businessUnitGeoRegions'
    | 'businessUnitIndustries'
    | 'businessUnitLeadTypes'
    | 'businessUnitSectors'
    | 'subGeoRegions'
    | 'allocationLimit'
> & {
    onEditSegmentation
    isLoading: boolean
    isLBModel: boolean
    isPPSModel: boolean
}

const SegmentationPreviewMode = ({
    allocationsQuotas,
    businessUnit,
    businessUnitGeoRegions,
    businessUnitIndustries,
    businessUnitLeadTypes,
    businessUnitSectors,
    entitiesGeoRegions,
    isLoading,
    onEditSegmentation,
    subGeoRegions,
    allocationLimit,
    isLBModel,
    isPPSModel,
}: SegmentationViewModel) => {
    const revenueRange = businessUnit.desiredRevenueRange

    const quota = allocationsQuotas[0]

    return (
        <Fragment>
            <LoadingBar />
            <Card.Content isLoading={isLoading} modifiers="p_0">
                <Row>
                    <Col md={12} lg={6}>
                        <Card.Content>
                            <Row>
                                {isLBModel && <Col md={2}>
                                    <Card.Label><strong>LB quota</strong></Card.Label>
                                    {quota && <Flex modifiers="justifySpaceBetween">
                                        <Text key={'quota_' + quota.id}>
                                            {quota.allocationQuotaPerRefill}
                                            {' '}
                                            from
                                            {moment(quota.effectiveAt).format('MMMM-YYYY')}
                                        </Text>
                                    </Flex>}
                                </Col>}
                                <Col md={2}>
                                    <Card.Label><strong>Revenue range</strong></Card.Label>
                                    <Text>
                                        {revenueRange && revenueRange.minimumRevenue + ' - ' + revenueRange.maximumRevenue}
                                    </Text>
                                </Col>
                                <Col md={2}>
                                    <Card.Label><strong>Digital Partner</strong></Card.Label>
                                    <Text>{!businessUnit.disableDigitalLeads ? 'YES' : 'NO'}</Text>
                                </Col>
                                <Col md={2}>
                                    <Card.Label><strong>Max lead distance</strong></Card.Label>
                                    <Text>{businessUnit.geoRange}</Text>
                                </Col>
                                {isPPSModel && <Fragment>
                                    <Col md={2}>
                                        <Card.Label>PPS limit</Card.Label>
                                        <Text>{allocationLimit ? allocationLimit.limit : '-'}</Text>
                                    </Col>
                                    <Col md={2}>
                                        <Card.Label>PPS price</Card.Label>
                                        <Text>
                                            {allocationLimit
                                                ? <FormattedAmount amount={allocationLimit.price} />
                                                : '-'
                                            }
                                        </Text>
                                    </Col>
                                </Fragment>}
                            </Row>
                            <Card.Line modifiers={[ 'mY_2' ]}/>
                            <Row>
                                <Col md={3}>
                                    <Card.Label><strong>Lead type</strong></Card.Label>
                                    <Ul>
                                        {businessUnitLeadTypes.map(leadType =>
                                            <li key={'leadType_' + leadType.id}>{leadType.title}</li>
                                        )}
                                    </Ul>
                                </Col>
                                <Col md={3}>
                                    <Card.Label><strong>Services</strong></Card.Label>
                                    <Ul>
                                        {businessUnitIndustries.map(industry =>
                                            <li key={'leadType_' + industry.id}>{industry.name}</li>
                                        )}
                                    </Ul>
                                </Col>
                                <Col md={3}>
                                    <Card.Label><strong>Sectors</strong></Card.Label>
                                    <Ul>
                                        {businessUnitSectors.map(sector =>
                                            <li key={'sector_' + sector.id}>{sector.displayName}</li>
                                        )}
                                    </Ul>
                                </Col>
                                <Col md={3}>
                                    <Card.Label><strong>Regions</strong></Card.Label>
                                    <Ul>
                                        {businessUnitGeoRegions.map(geoRegion =>
                                            <li key={'geoRegion_' + geoRegion.id}>{geoRegion.regionName}</li>
                                        )}
                                    </Ul>
                                </Col>
                            </Row>
                        </Card.Content>
                    </Col>
                    <Col md={12} lg={6}>
                        <GeoRegionsMap
                            subGeoRegions={subGeoRegions}
                            geoRegions={entitiesGeoRegions}
                            containerElement={<MapElement/>}
                            mapElement={<MapElement/>}
                            showBusinessUnits={true}
                            businessUnits={[ businessUnit ]}
                            zoomPoint={businessUnit.geo.point}
                            zoom={7}
                        />
                    </Col>
                </Row>
                <EditButton modifiers="secondary" onClick={onEditSegmentation}>Edit segmentation</EditButton>
            </Card.Content>
        </Fragment>
    )
}

export default memo(SegmentationPreviewMode)
