import { createSelector } from 'reselect'

const entitiesInvocings = state => state.entities.invoicings

const pagesInvoicingsIds = state => state.pages.invoicings.invoicings

export const invoicingListSelector = createSelector(
    entitiesInvocings, pagesInvoicingsIds,
    (invoicings, invoicingIds) => invoicingIds.map(id => invoicings[id])
)
