import React, { PureComponent, ComponentType } from 'react'
import TaskRow from './TaskRow'
import { Task } from '../../reducers/types/entities/Task'
import { Card } from '../blocks/Card'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex'
import { partnerTasksSelector } from '../../selectors/tasks'
import { getTasksByPartnerId } from '../../actions/tasks'
import { connect } from '../../utils/connect'
import Placeholder from '../elements/Placeholder'

interface TaskPathProps {
    entityType: string
    entityId: number
}


interface TasksCardModel extends TaskPathProps{
    tasks: Task[]
    isLoadingTask: boolean
    getTasksByPartnerId: typeof getTasksByPartnerId
}

interface TasksCardModelState {
    taskCreation: boolean,
    initialTaskables: [{
        type: string
        partner: {
            id: number
        }
    }]
}

@connect(
    state => ({
        tasks: partnerTasksSelector(state),
        isLoadingTask: state.pages.taskList.isLoading,
    }),
    {
        getTasksByPartnerId,
    },
)
class TasksCard extends PureComponent<TasksCardModel, TasksCardModelState> {
    constructor(props) {
        super(props)
        this.state = {
            taskCreation: false,
            initialTaskables: [ {
                type: props.entityType,
                partner: { id: props.entityId },
            } ],
        }
    }

    componentDidMount(): void {
        this.retrieveInfo(this.props.entityId, this.props.entityType)
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.entityId !== this.props.entityId) {
            this.retrieveInfo(this.props.entityId, this.props.entityType)
            this.setState({ initialTaskables: [ {
                type: this.props.entityType,
                partner: { id: this.props.entityId },
            } ] })
        }
    }

    retrieveInfo = (entityId, entityType) => {
        if (entityType === 'partner') {
            this.props.getTasksByPartnerId(entityId)
        }
    }

    beginTaskCreation = () => {
        this.setState({ taskCreation: true })
    }

    cancelTaskCreation = () => {
        this.setState({ taskCreation: false })
    }

    render() {
        const {
            isLoadingTask,
            tasks,
        } = this.props

        const { taskCreation, initialTaskables } = this.state

        const scrollHeight = taskCreation ? 193 : 130

        return (
            <Card className="tasksCard">
                <Card.Header>Tasks</Card.Header>
                <Card.Content>
                    { this.state.taskCreation ||
                    <Flex onClick={this.beginTaskCreation} style={{ cursor:'pointer' }}>
                        <Button modifiers={[ 'secondary', 'btnIcon', 'mR_1' ]} btnIconSize={25}>
                            <i className="mdi mdi-plus" />
                        </Button>
                        <Card.Text>New task</Card.Text>
                    </Flex>}
                    { this.state.taskCreation && <TaskRow
                        onDiscard={this.cancelTaskCreation}
                        initialTaskables={initialTaskables}
                    /> }
                </Card.Content>
                <Card.Content
                    modifiers={[ 'maxHeight', 'scroll', 'pT_0' ]}
                    scrollHeight={`calc(100% - ${scrollHeight}px)`}
                    isLoading={isLoadingTask && Boolean(tasks.length)}
                >
                    {isLoadingTask && !Boolean(tasks.length) && <Placeholder counter={5} portrait={false} />}
                    {tasks.map((task) => <TaskRow task={task} key={'TaskRow_' + task.id} />)}
                </Card.Content>
            </Card>
        )
    }
}

export default TasksCard as unknown as ComponentType<TaskPathProps>
