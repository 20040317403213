import {
    GET_PARTNER,
    GET_PARTNER_SUCCESS,
    GET_PARTNER_FAIL,
    GET_PARTNER_SUBSCRIPTION_AGREEMENTS,
    CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS,
    CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS,
    CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL,
    GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS, GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL,
} from '../../actions/partners'

import {
    GET_SEGMENTED_LEADS,
    GET_SEGMENTED_LEADS_SUCCESS,
    GET_SEGMENTED_LEADS_FAIL,
} from '../../actions/leads'

import {
    GET_PARTNER_SUBSCRIPTIONS,
    GET_PARTNER_SUBSCRIPTIONS_SUCCESS,
    GET_PARTNER_SUBSCRIPTIONS_FAIL, GET_PACKAGE_TYPES, GET_PACKAGE_TYPES_SUCCESS, GET_PACKAGE_TYPES_FAIL,
} from '../../actions/subscriptions'

import {
    GET_RATINGS,
    GET_RATINGS_SUCCESS,
    GET_RATINGS_FAIL,
} from '../../actions/ratings'

import {
    GET_COUPONS,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_FAIL,
} from '../../actions/coupons'

import {
    GET_PARTNER_CONTACT_ARCHIVES,
    GET_PARTNER_CONTACT_ARCHIVES_FAIL,
    GET_PARTNER_CONTACT_ARCHIVES_SUCCESS,
} from '../../actions/contact'
import { InitialAction } from '../types/InitialAction'
import { Pagination } from '../types/Pagination'
import {
    CREATE_PARTNER_BUSINESS_UNITS_SUCCESS,
    DELETE_PARTNER_BUSINESS_UNITS_SUCCESS,
    GET_PARTNER_BUSINESS_UNITS,
    GET_PARTNER_BUSINESS_UNITS_FAIL,
    GET_PARTNER_BUSINESS_UNITS_SUCCESS, RESET_PARTNER_BUSINESS_UNITS,
} from '../../actions/businessUnit'
import { GET_TERMS, GET_TERMS_FAIL, GET_TERMS_SUCCESS } from '../../actions/terms'

const pagination = {
    page: 0,
    pages: 0,
    total: 0,
}

const segmentedLeadsState = {
    leadIds: [],
    isLoading: false,
    pagination,
}

export interface PartnerDetailModel {
    items: number[]
    archivesIds: number[]
    businessUnitsId: number[]
    isLoading: boolean
    loadingArchives: boolean
    isLoadingRatings: boolean
    isLoadingSellingPoints: boolean,
    isLoadingCoupons: boolean
    isLoadingInvoices: boolean
    isLoadingEmployees: boolean
    isLoadingAgreements: boolean
    loadingBusinessUnits: boolean
    isLoadingCreateCoupon: boolean
    isLoadingSubscriptions: boolean
    businessUnitsPagination: Pagination
    archivesPagination: Pagination
    segmentedLeads: {
        leadIds: number[]
        isLoading: boolean
        pagination: Pagination
    }
}

const initialState: any = {
    items: [],
    archivesIds: [],
    businessUnitsId: [],
    isLoading: false,
    loadingArchives: false,
    isLoadingRatings: false,
    isLoadingCoupons: true,
    isLoadingInvoices: false,
    isLoadingEmployees: false,
    isLoadingAgreements: false,
    loadingBusinessUnits: false,
    isLoadingCreateCoupon: false,
    isLoadingSubscriptions: false,
    businessUnitsPagination: pagination,
    archivesPagination: pagination,
    segmentedLeads: segmentedLeadsState,
    isLoadingSellingPoints: false,
}

function pageData(state, action) {
    switch (action.type) {
        case GET_SEGMENTED_LEADS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_SEGMENTED_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_SEGMENTED_LEADS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}

interface PartnerDetailActionModel extends InitialAction{
    businessUnitId: number
}

export default function reducer(
    state = initialState,
    action: PartnerDetailActionModel
) {
    switch (action.type) {
        case GET_SEGMENTED_LEADS:
        case GET_SEGMENTED_LEADS_SUCCESS:
        case GET_SEGMENTED_LEADS_FAIL:
            return {
                ...state,
                segmentedLeads: pageData(state.segmentedLeads, action),
            }
        case GET_PARTNER:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case GET_PARTNER_FAIL:
            return {
                ...state,
                isLoadingEmployees: false,
            }
        case GET_RATINGS:
            return {
                ...state,
                isLoadingRatings: true,
            }
        case GET_RATINGS_SUCCESS:
            return {
                ...state,
                isLoadingRatings: false,
            }
        case GET_RATINGS_FAIL:
            return {
                ...state,
                isLoadingRatings: false,
            }
        case GET_COUPONS:
            return {
                ...state,
                isLoadingCoupons: true,
            }
        case GET_COUPONS_SUCCESS:
            return {
                ...state,
                isLoadingCoupons: false,
            }
        case GET_COUPONS_FAIL:
            return {
                ...state,
                isLoadingCoupons: false,
            }
        case GET_PARTNER_SUBSCRIPTIONS:
            return {
                ...state,
                isLoadingSubscriptions: true,
            }
        case GET_PARTNER_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                isLoadingSubscriptions: false,
            }
        case GET_PARTNER_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                isLoadingSubscriptions: false,
            }

        case GET_PARTNER_CONTACT_ARCHIVES:
            return {
                ...state,
                loadingArchives: true,
            }
        case GET_PARTNER_CONTACT_ARCHIVES_SUCCESS:
            return {
                ...state,
                archivesIds: action.append ? state.archivesIds.concat(action.response.result) : action.response.result,
                archivesPagination: action.response.pagination,
                loadingArchives: false,
            }
        case GET_PARTNER_CONTACT_ARCHIVES_FAIL:
            return {
                ...state,
                loadingArchives: false,
            }
        case GET_PARTNER_BUSINESS_UNITS:
            return {
                ...state,
                loadingBusinessUnits: true,
            }
        case RESET_PARTNER_BUSINESS_UNITS:
            return {
                ...state,
                businessUnitsId: [],
            }
        case GET_PARTNER_BUSINESS_UNITS_SUCCESS:
            return {
                ...state,
                businessUnitsId: action.append
                    ? state.archivesIds.concat(action.response.result)
                    : action.response.result,
                businessUnitsPagination: action.response.pagination,
                loadingBusinessUnits: false,
            }
        case GET_PARTNER_BUSINESS_UNITS_FAIL:
            return {
                ...state,
                loadingBusinessUnits: false,
            }
        case DELETE_PARTNER_BUSINESS_UNITS_SUCCESS:
            return {
                ...state,
                businessUnitsId: state.businessUnitsId.filter(item => item !== action.businessUnitId),
                loadingBusinessUnits: false,
            }
        case CREATE_PARTNER_BUSINESS_UNITS_SUCCESS:
            return {
                ...state,
                businessUnitsId: state.businessUnitsId.concat(action.response.result),
                loadingBusinessUnits: false,
            }
        case GET_TERMS:
        case GET_PACKAGE_TYPES:
        case GET_PARTNER_SUBSCRIPTION_AGREEMENTS:
        case CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS:
            return {
                ...state,
                isLoadingAgreements: true,
            }
        case GET_TERMS_SUCCESS:
        case GET_TERMS_FAIL:
        case GET_PACKAGE_TYPES_SUCCESS:
        case GET_PACKAGE_TYPES_FAIL:
        case GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS:
        case GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL:
        case CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS:
        case CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL:
            return {
                ...state,
                isLoadingAgreements: false,
            }
        default:
            return state
    }
}
