import { createSelector } from 'reselect'

const entitiesPartnerSignups = state => state.entities.partnerSignups

const pagesPartnerSignupIds = state => state.pages.partnerSignups.partnerSignups

export const partnerSignupListSelector = createSelector(
    entitiesPartnerSignups, pagesPartnerSignupIds,
    (partnerSignups, partnerSignupIds) => partnerSignupIds.map(id => partnerSignups[id])
)
