import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Field, FormSection } from 'redux-form'
import { AsyncTypeaheadGroup } from '../../elements/forms/inputs/AsyncTypeaheadGroup'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'

const VoucherFields = ({
    renderZipCodeMenuItem,
    isLoadingLocations,
    selectZipCode,
    getLocations,
    locations,
}) => <FormSection name="invoicing">
    <Row>
        <Col md={6}>
            <Field
                label="Email"
                name="email"
                component={FieldGroup}
                type="email"
            />
        </Col>
        <Col md={6}>
            <Field
                label="Attention"
                name="attention"
                component={FieldGroup}
            />
        </Col>
    </Row>
    <Row>
        <Col md={6}>
            <Field
                label="Company name"
                name="companyName"
                component={FieldGroup}
            />
        </Col>
        <Col md={6}>
            <Field
                label="VAT number"
                name="vatNumber"
                component={FieldGroup}
            />
        </Col>
    </Row>
    <Field
        label="Address"
        name="address"
        component={FieldGroup}
    />
    <Row>
        <Col md={6}>
            <Field
                label="Zip code"
                name="zipCode"
                component={AsyncTypeaheadGroup}
                labelKey="zipCode"
                isLoading={isLoadingLocations}
                options={locations}
                renderMenuItemChildren={renderZipCodeMenuItem}
                onSearch={getLocations}
                onChange={selectZipCode}
                removeClassFormGroup
            />
        </Col>
        <Col md={6}>
            <Field
                label="City"
                name="cityName"
                component={FieldGroup}
                removeClassFormGroup
                readOnly
            />
        </Col>
    </Row>
</FormSection>

export default VoucherFields
