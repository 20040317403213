import {
    FormikProps,
    WithFormikConfig,
    withFormik as originalWithFormik,
} from 'formik'
import { ReactType } from 'react'

type InferableComponentEnhancerWithProps<InjectedProps> = <
    Component extends ReactType<InjectedProps>
    >(component: Component) => Component

export type WithFormik = <Props, Values>(
    config?: WithFormikConfig<Props, Values>
) => InferableComponentEnhancerWithProps<Props & FormikProps<Values>>

export const withFormik = originalWithFormik as WithFormik
