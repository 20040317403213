import React, { ComponentType, PureComponent } from 'react'
import { Dropdown } from 'react-bootstrap'
import connect from 'react-redux/es/connect/connect'
import {
    createCallLeadAction,
    createLeadNoAnswerAction,
} from '../../actions/actions'
import LeadCallButton from '../elements/LeadCallButton'
import { showModal } from '../../actions/modal'
import { Lead } from '../../reducers/types/entities/Lead'
import { Quote } from '../../reducers/types/entities/Quote'

interface LeadActionsDispatchProps{
    createLeadNoAnswerAction: typeof createLeadNoAnswerAction
    createCallLeadAction: typeof createCallLeadAction
    showModal: typeof showModal
}

interface LeadActionsPathProps {
    lead: Lead
    publishedQuotes: Quote[]
}

type LeadActionsModel = LeadActionsDispatchProps & LeadActionsPathProps

@connect(
    null,
    {
        createLeadNoAnswerAction,
        createCallLeadAction,
        showModal,
    }
)
class LeadActionsButtonDropdown extends PureComponent<LeadActionsModel> {
    state = {
        openDropDown: false,
    }

    setModalProps = data => {
        this.props.showModal({
            ...data,
            leadId: this.props.lead.id,
        })
    }

    callAction = event => {
        if(event === 'noAnswer') {
            this.setState({ openDropDown: false })
            this.props.createLeadNoAnswerAction(this.props.lead.id)
        } else if(event !== 'notValid') {
            this.props.createCallLeadAction(this.props.lead.id)
        }
    }

    render() {
        const {
            lead,
            publishedQuotes,
        } = this.props

        return (
            <Dropdown
                onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                pullRight
                open={this.state.openDropDown}
                onToggle={() => this.setState({ openDropDown: !this.state.openDropDown })}
                id={lead.id + '_DropdownButton'}
            >
                <Dropdown.Toggle
                    noCaret
                    className="btn btn-primary fa fa-wrench"
                />
                <Dropdown.Menu className="text-left">
                    {lead.customerInfo.phone &&
                    <LeadCallButton
                        label="Call client"
                        onCall={this.callAction}
                        phoneNumber={lead.customerInfo.phone}
                        geoCode={lead.geo.code}
                    />}
                    <a onClick={() => this.setModalProps({
                        name: 'postponeModal',
                    })}
                    >
                        Postpone decision
                    </a>
                    <a onClick={() => this.setModalProps({
                        name: 'acceptQuoteModal',
                        quotes: publishedQuotes,
                    })}
                    >
                        Accept quote
                    </a>
                    <a onClick={() => this.setModalProps({
                        name: 'rejectQuotesModal',
                        quotes: publishedQuotes,
                    })}
                    >
                        Reject all quotes
                    </a>
                    <a onClick={() => this.setModalProps({
                        name: 'leadGivenUpConfirm',
                    })}
                    >
                        Give up
                    </a>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default LeadActionsButtonDropdown as unknown as ComponentType<LeadActionsPathProps>
