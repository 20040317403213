import { createSelector } from 'reselect'
import moment from 'moment'
import { values } from 'lodash'

import 'geo-distance'

declare const Distance

export const entitiesPartners = state => state.entities.partners

const entitiesSubscriptions = state => values(state.entities.subscriptions)

const entitiesSubscriptionPackages =  state => values(state.entities.subscriptionPackages)

const entitiesQuotes = state => state.entities.quotes

const pagesPartnerQuotes = state => state.pages.partnerQuotes.quotesIds

const entitiesPartnersFees = (state, partnerId) => values(state.entities.partnersFees)
    .filter(fee => fee.partnerId === Number(partnerId))

const entitiesInvoiceRefundRequest = (state, refundRequestId) => state.entities.invoiceRefundRequests[refundRequestId]

const pagesPartnersPartnerIds = state => state.pages.partners.partnerIds

const pagesQuotesSurveillancePartnerIds = state => state.pages.quotesSurveillance.partnerIds

const entitiesBusinessUnits = state => state.entities.businessUnits

const partnerDetailBusinessUnitsId = state => state.pages.partnerDetail.businessUnitsId

const entitiesPartnerUniqueSellingPoints = (state) => values(state.entities.partnerUniqueSellingPoints)

// TODO: just find one subscription, for future will need to add possibility to find few
export const subscriptionPackageSelector = createSelector(
    entitiesSubscriptions, entitiesSubscriptionPackages,
    (subscriptions, packages) => packages.find(p =>
        subscriptions.find(subscription => p.id === subscription.packageId)
    )
)

export const partnerQuotesSelector = createSelector(
    entitiesQuotes, pagesPartnerQuotes,
    (quotes, ids) => ids.map(id => quotes[id])
)

export const partnerFeesSelector = createSelector(
    entitiesPartnersFees,
    partnersFees =>
        partnersFees.sort((cur, next) => moment(cur).isAfter(moment(next)) ? 1 : -1)
)

export const partnerQuotesByRefundRequestSelector = createSelector(
    entitiesQuotes, entitiesInvoiceRefundRequest,
    (quotes, refundRequest) => refundRequest
        ? values(quotes)
            .filter(quote => quote.partner.id === refundRequest.partner.id)
            .sort((a, b) => moment(b.createdAt).isBefore(a.createdAt) ? -1 : 1)
        : []
)

export const partnerByRefundRequestSelector = createSelector(
    entitiesPartners, entitiesInvoiceRefundRequest,
    (partners, refundRequest) => refundRequest
        ? partners[refundRequest.partner.id]
        : null
)

export const partnerListSelector = createSelector(
    entitiesPartners, pagesPartnersPartnerIds,
    (partners, partnerIds) => partnerIds.map(id => partners[id])
)

export const partnerListSendEmailMapSelector = createSelector(
    entitiesPartners, pagesPartnersPartnerIds,
    (partners, partnerIds) => partnerIds.reduce((acc, id) => {
        if (partners[id] && partners[id].subscription.find(el => el.packageType === 'Ageras Marketing Package')) {
            return acc
        }
        acc.push(partners[id])
        return acc
    }, [])
)

export const segmentedPartnerListSelector = createSelector(
    entitiesPartners, pagesQuotesSurveillancePartnerIds,
    (partners, partnerIds) => partnerIds.map(id => partners[id])
)

function calculateDistance(subject, leadGeo) {
    const partnerGeo = subject.geo.point
    const calcDistance = Distance.between(leadGeo, partnerGeo).human_readable()
    const distance = calcDistance.unit === 'km' ? Number(calcDistance.distance) : Number(calcDistance.distance) / 1000
    return  { ...subject, distance }
}

const circleRadius = (_, radius, lead) => ({ radius, lead })

const allowAddItem = (bu, r, hasMarketingPackage) => bu &&
    !(bu.distance > r || (bu.geoRange > 0 && bu.distance > bu.geoRange)) &&
    bu.partner.hasMarketingPackage === hasMarketingPackage

export const selectPartnerDetailBusinessUnits = createSelector(
    partnerDetailBusinessUnitsId, entitiesBusinessUnits,
    (businessUnitsId, businessUnits) => businessUnitsId.map(id => businessUnits[id])
)

const leadSelector = (state, leadId) => state.entities.leads[leadId]

const leadQuotes = state => state.entities.quotes

export const sendEmailPageBusinessUnits = createSelector(
    leadSelector, selectPartnerDetailBusinessUnits, leadQuotes,
    (lead, businessUnits, quotes) => businessUnits.filter(el => lead &&
        !lead.quotesInfo.quotes.find(quoteId => quotes[quoteId].partner.id === el.partnerId)
    )
)

export const agerasSendLeadToPartnersData = createSelector(
    selectPartnerDetailBusinessUnits, circleRadius,
    (businessUnits, pathProps) => {
        if (!businessUnits.length || !pathProps.lead)
            return {
                marketingBusinessUnits: [],
                subscriptionBusinessUnits: [],
            }

        const dataList = businessUnits.reduce((obj, bu) => {
            const r = Number(pathProps.radius) || 0

            const geoPoint = pathProps.lead.geo.point

            const businessUnit = calculateDistance(bu, geoPoint)

            if (allowAddItem(businessUnit, r, false))
                obj.subscriptionBusinessUnits.push(businessUnit)
            else if (allowAddItem(businessUnit, r, true))
                obj.marketingBusinessUnits.push(businessUnit)

            return obj
        }, {
            marketingBusinessUnits: [],
            subscriptionBusinessUnits: [],
        })

        return dataList
    }
)

export const partnerUniqueSellingPointsSelector = createSelector(
    entitiesPartnerUniqueSellingPoints, partnersUniqueSellingPoints => partnersUniqueSellingPoints
)
