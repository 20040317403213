import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PAYMENT_CARDS = 'paymentCards/GET_PAYMENT_CARDS'
export const GET_PAYMENT_CARDS_SUCCESS = 'paymentCards/GET_PAYMENT_CARDS_SUCCESS'
export const GET_PAYMENT_CARDS_FAIL = 'paymentCards/GET_PAYMENT_CARDS_FAIL'

export const getPaymentCards = (criteria?) => ({
    [CALL_API]: {
        types: [ GET_PAYMENT_CARDS, GET_PAYMENT_CARDS_SUCCESS, GET_PAYMENT_CARDS_FAIL ],
        endpoint: '/payments/cards',
        schema: schemas.paymentCards,
        options: {
            body: {
                ...criteria,
            },
        },
    },
})
