import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_MATCHES = actionTypesCreator('cMatches', 'GET_MATCHES')

interface GetMatchesModel {
    leadId?: number | number[]
    partnerId?: number | number[]
    validatorId?: number | number[]
    limit?: number
}

export const getMatches = (
    criteria: GetMatchesModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: GET_MATCHES,
        endpoint: '/matches',
        schema: schemas.cMatches,
        options: {
            body: {
                limit: 100,
                ...criteria,
            },
        },
        append,
    },
})
