import React, { memo } from 'react'
import { Lead } from '../../reducers/types/entities/Lead'
import { LinkCell, Cell, Table, Caption, Header, Column, Rows } from '../elements/table'
const LeadSegmentedRow = memo(({ rowData }: {rowData?: Lead}) =>
    <tr>
        <LinkCell to={'leads/' + rowData.id}>{rowData.id}</LinkCell>
        <Cell>{rowData.industries.primary.name}</Cell>
        <Cell>{rowData.customerInfo.customerType.identifier}</Cell>
        <Cell>{rowData.geo.cityName}</Cell>
        <Cell>
            {rowData.quotesInfo.count}
            {' '}
            / 
            {' '}
            {rowData.quotesInfo.max}
        </Cell>
    </tr>
)

const BusinessUnitSegmentationTable = memo(({ leads }: {leads: Lead[]}) =>
    <Table>
        <Caption>Segmented leads</Caption>
        <Header>
            <Column>Lead ID</Column>
            <Column>Industry</Column>
            <Column>Client Type</Column>
            <Column>City</Column>
            <Column>Quotes</Column>
        </Header>
        <Rows
            items={leads}
            noDataText="We couldn't find any leads."
        >
            <LeadSegmentedRow/>
        </Rows>
    </Table>

)

export default BusinessUnitSegmentationTable
