import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_BUSINESS_UNIT_ALLOCATIONS_QUOTES = actionTypesCreator(
    'allocationsQuotas',
    'GET_BUSINESS_UNIT_ALLOCATIONS_QUOTES'
)

export const CREATE_BUSINESS_UNIT_ALLOCATION_QUOTES = actionTypesCreator(
    'allocationsQuotas',
    'CREATE_BUSINESS_UNIT_ALLOCATION_QUOTES'
)

export const DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES = actionTypesCreator(
    'allocationsQuotas',
    'DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES'
)

interface GetBusinessUnitAllocationsQuotasModel {
    partnerBusinessUnitId?: number | number[]
    effectiveAtGte?: string
    effectiveAtLte?: string
    limit?: number
}
export const getBusinessUnitAllocationsQuotas = (
    partnerId: number,
    criteria: GetBusinessUnitAllocationsQuotasModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: GET_BUSINESS_UNIT_ALLOCATIONS_QUOTES,
        endpoint: `/partners/${partnerId}/businessunits/allocationquotas`,
        schema: schemas.allocationsQuotas,
        options: {
            body: {
                limit: 1000,
                ...criteria,
            },
        },
    },
    append,
})

interface PartnerAllocationCreateModel {
    allocationQuotaPerRefill: number
    createdByEmployeeId: number
    effectiveAt: string
    partnerBusinessUnitId: number
}

export const createBusinessUnitAllocation = (
    partnerId: number,
    businessUnit: number,
    body: PartnerAllocationCreateModel
) => ({
    [CALL_API]: {
        types: CREATE_BUSINESS_UNIT_ALLOCATION_QUOTES,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnit}/allocationquotas`,
        schema: schemas.allocationsQuota,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deleteBusinessUnitAllocationQuota = (
    partnerId: number,
    businessUnit: number,
    quotaId
) => ({
    [CALL_API]: {
        types: DELETE_BUSINESS_UNIT_ALLOCATION_QUOTES,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnit}/allocationquotas/${quotaId}`,
        options: {
            method: 'delete',
        },
    },
    id: quotaId,
})
