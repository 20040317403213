export const getLeadStatus = ({ lead, leadQuotes }) => {
    if(lead.status === 'validated' && leadQuotes.find(quote => quote.status === 'accepted'))
        return 'quote_accepted'
    if(lead.status === 'validated' &&
        leadQuotes.filter(quote => quote.status === 'rejected').length === lead.quotesInfo.max)
        return 'all_quotes_rejected'
    if(lead.status === 'validated' && lead.quotesInfo.max === lead.quotesInfo.count)
        return 'quotes_full'

    return lead.status
}
