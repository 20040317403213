import {
    GET_OPEN_CASES_LEADS, GET_OPEN_CASES_SUCCESS, GET_OPEN_CASES_FAIL
} from '../../actions/leads'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface OpenCasesLeadsModel extends InitialState {
    quotesIds: number[]
}
const initialState: OpenCasesLeadsModel = {
    isLoading: null,
    quotesIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_OPEN_CASES_LEADS:
            return {
                ...state,
                loading: true,
            }
        case GET_OPEN_CASES_SUCCESS:
            return {
                ...state,
                quotesIds: action.response.result,
                pagination: action.response.pagination,
                loading: false,
            }
        case GET_OPEN_CASES_FAIL:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}
