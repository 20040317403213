import React, { memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import ControlLabel from '../../text/MetaLabel'
import Error from '../Error'
import { WrappedFieldProps } from 'redux-form'
import ToggleCheckbox from '../../../blocks/ToggleCheckbox'
import styled from 'styled-components'
import { FieldInputProps, FormikHelpers } from 'formik'

const CheckboxGrid = styled.div`
  display: grid;
  grid-gap: 10px 20px;
  grid-template-columns: repeat(auto-fill, 120px);
`

const MultipleSwitchCheckboxField = ({
    option,
    optionKey,
    field,
    form,
    input,
}) =>
    <ToggleCheckbox
        value={option[optionKey]}
        disabled={option.disabled}
        checked={((input || field).value || []).find(el => String(el[optionKey]) === String(option[optionKey]))}
        onChange={event => {
            let newValue = [ ...(input || field).value ]
            if (event.target.checked) {
                newValue.push(option)
            } else {
                newValue = newValue.filter(el => String(el[optionKey]) !== String(option[optionKey]))
            }
            if (field && form) {
                return form.setFieldValue(field.name, newValue)
            }
            return input.onChange(newValue)
        }}
    />

interface RadioGroupModel<Value> extends WrappedFieldProps {
    removeClassFormGroup?: boolean
    shouldMatch?: string
    className?: string
    label?: string
    hideLabel?: boolean
    showErrorInfo?: boolean
    optionKey?: string,
    options
    field?: FieldInputProps<Value> | any
    form?: FormikHelpers<Value> | any
    titleName: string
}
const ToggleCheckboxGroup = ({
    removeClassFormGroup,
    showErrorInfo,
    className,
    label,
    field,
    form,
    input,
    meta,
    optionKey = 'id',
    options,
    titleName,
}: RadioGroupModel<any>) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={Boolean(meta) && meta.touched && (meta.error || meta.warning) ? 'error' : null}
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        <CheckboxGrid>
            { options.map((option, index) =>
                <div key={(input || field).name + '_' + index}>
                    <ControlLabel>{option[titleName].replace(/_/g, ' ')}</ControlLabel>
                    <MultipleSwitchCheckboxField
                        option={option}
                        optionKey={optionKey}
                        field={field}
                        form={form}
                        input={input}
                    />
                </div>
            )}
        </CheckboxGrid>
        {showErrorInfo && <Error meta={meta}/>}
    </FormGroup>

export default memo(ToggleCheckboxGroup)
