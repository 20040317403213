import {
    VERIFY_PHONE_NUMBER,
    VERIFY_PHONE_NUMBER_SUCCESS,
    VERIFY_PHONE_NUMBER_FAIL,
} from '../../actions/customers'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

const initialState: InitialState = {
    isLoading: false,
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case VERIFY_PHONE_NUMBER:
            return {
                ...state,
                isLoading: true,
            }
        case VERIFY_PHONE_NUMBER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case VERIFY_PHONE_NUMBER_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
