import React, { memo } from 'react'
import FormattedDate from '../../../elements/FormattedDate'
import { Cell, Column, Header, Rows, Table } from '../../../elements/table'
import { AllocationSuggestion } from '../../../../reducers/types/entities/AllocationSuggestion'
import { Card } from '../../../blocks/Card'
import styled from 'styled-components'
import { dangerColor, successColor } from '../../../../utils/variables'
import { Flex } from '../../../elements/Flex'
import { Button } from '../../../elements/Button'

interface MiddleDotPropsModel {
    accepted: boolean
}

const MiddleDot = styled.span<MiddleDotPropsModel>`
   font-size: 32px;
   color: ${({ accepted }) => accepted ? successColor : dangerColor};
   margin-right: 5px;
`

interface AllocationSuggestionsPropsModel {
    allocationSuggestions: AllocationSuggestion[]
    deleteSuggestion: any
}

const AllocationSuggestionRow = memo((props: {rowData?: AllocationSuggestion, deleteSuggestion: any}) => {
    const allocationSuggestion = props.rowData

    if (!allocationSuggestion) {
        return null
    }

    const isAccepted = allocationSuggestion.acceptedAt != null

    return <tr key={`allocation_suggestion_${allocationSuggestion.id}`}>
        <Cell>
            {allocationSuggestion.id}
        </Cell>
        <Cell>
            <a href={'/partners/' + allocationSuggestion.partner.id} target="_blank" rel="noreferrer">{allocationSuggestion.partner.companyName}</a>
        </Cell>
        <Cell>
            <Flex>
                <MiddleDot accepted={isAccepted}>•</MiddleDot>
                {!isAccepted && <span>Awaiting</span>}
                {isAccepted && <span>Accepted</span>}
                {' '}
&nbsp;
                {isAccepted && <span>
                    (
                    <FormattedDate date={allocationSuggestion.acceptedAt} format="datetime" />
                    )
                </span>}
            </Flex>
        </Cell>
        <Cell>
            <Button modifiers={[ 'danger', 'cellBtn' ]} onClick={() => props.deleteSuggestion(allocationSuggestion.lead.id, allocationSuggestion.id)}>Remove</Button>
        </Cell>
    </tr>})

export const AllocationSuggestionsTab = memo(({
    allocationSuggestions,
    deleteSuggestion,
}: AllocationSuggestionsPropsModel) =>
    <div>
        <Card.Content>
            Suggestions below are presented to the client in client portal. Accepted ones will be turned into quotes.
        </Card.Content>
        <Table>
            <Header>
                <Column>ID</Column>
                <Column>Partner name</Column>
                <Column>Accepted</Column>
                <Column>Actions</Column>
            </Header>
            <Rows
                items={allocationSuggestions}
                noDataText="No existing allocation suggestions"
            >
                <AllocationSuggestionRow
                    deleteSuggestion={deleteSuggestion}
                />
            </Rows>
        </Table>
    </div>
)
