import React, { PureComponent, ComponentType, memo } from 'react'
import { Label } from 'react-bootstrap'
import { getInvoiceRefundRequests } from '../../actions/invoices'
import { Card } from '../blocks/Card/index'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import config from '../../config'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { refundsInvoicesSelector } from '../../selectors/invoices'
import { InvoiceRefundRequest } from '../../reducers/types/entities/InvoiceRefundRequest'
import { Employee } from '../../reducers/types/entities/Employee'
import { connect } from '../../utils/connect'
import ProviderTable from '../blocks/ProviderTable'
import { Cell, LinkCell, AmountCell, DateCell, DropdownFilter, Column, SortColumn } from '../elements/table'

interface RowsHocModel {
    rowData: InvoiceRefundRequest & {
        employee: Employee
    }
}
const RefundsRow = memo((props: RowsHocModel) => {
    const { rowData, ...resProps } = props

    const refund = rowData

    return <ProviderTable.Row {...resProps}>
        <LinkCell to={'/refund-requests/' + refund.id}>{refund.id}</LinkCell>
        <Cell><a href={'/vouchers/' + refund.invoice.id}>{refund.invoice.id}</a></Cell>
        <Cell>{refund.employee && refund.employee.name}</Cell>
        <Cell>{refund.partner.companyName}</Cell>
        <AmountCell amount={refund.requestedAmountExclVat} />
        <AmountCell amount={refund.invoice.totalAmountExclVat} />
        <DateCell date={refund.invoice.createdAt} />
        <Cell>
            <Label
                bsStyle={(refund.status === 'rejected' ? 'danger' : refund.status === 'accepted' ? 'info' : 'new')}
            >
                {refund.status}
            </Label>
        </Cell>
        <DateCell date={refund.createdAt} />
    </ProviderTable.Row>
})

const RefundsTableRow = RefundsRow as ComponentType

interface Criteria {
    visibleColumns: string[]
    assignedEmployeeId: string[]
    sort: string
}

interface RefundsListPageModel {
    refunds: InvoiceRefundRequest[]
    totalItems: number
    employees: Employee[]
    criteria: Criteria
    getInvoiceRefundRequests: typeof getInvoiceRefundRequests
}

interface RefundsListPageModel {
    location: {
        query: Criteria
    }
}

@connect<any,  any, RefundsListPageModel>(
    (state, { location: { query } }) => ({
        refunds: refundsInvoicesSelector(state),
        totalItems: state.pages.invoiceRefunds.pagination.total,
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        criteria: {
            visibleColumns: splitFilterString(query.visibleColumns),
            assignedEmployeeId: splitFilterString(query.assignedEmployeeId),
            sort: query.sort || 'created_at',
        },
    }),
    {
        getInvoiceRefundRequests,
    }
)

export default class RefundsListPage extends PureComponent<RefundsListPageModel> {
    retrieveData = (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
        })

        return this.props.getInvoiceRefundRequests(criteria, append)
    }

    render() {
        const {
            refunds,
            criteria,
            employees,
            totalItems,
        } = this.props
        return (
            <Card margin="0">
                <Card.Header>
                    Refund requests from Partners
                    <span className="text-muted">
                        {' '}
                        (
                        {refunds.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="invoiceRefunds"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.assignedEmployeeId}
                            items={employees}
                            name="assignedEmployeeId"
                            label="Assigned employee"
                            search={false}
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="request_id">Request ID</Column>
                            <Column name="voucher_id">Voucher ID</Column>
                            <Column name="employee_name">Employee</Column>
                            <Column name="partner_name">Partner name</Column>
                            <SortColumn name="requested_amount_excl_vat">Requested refund total</SortColumn>
                            <Column name="total_original_fee">Total original fee</Column>
                            <Column name="original_invoice">Time of original invoice</Column>
                            <SortColumn name="status">Status</SortColumn>
                            <SortColumn name="created_at">Request created at</SortColumn>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={refunds}
                            noDataText="We couldn't find any data."
                        >
                            <RefundsTableRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
