import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { dangerColor, darkGrey, primaryColor, successColor } from '../../utils/variables'
import { MODIFIER_MARGIN_CONFIG } from './modifiers/spaceModifiers'


const checkBoxStatuses = {
    checked: {
        style: `background-color: ${successColor};`,
        icon: 'mdi mdi-check',
    },
    declined: {
        style: `background-color: ${dangerColor};`,
        icon: 'mdi mdi-close',
    },
    circle: {
        style: `border: 6px solid ${darkGrey}; background-color: initial;`,
        icon: '',
    },
    primaryCircle: {
        style: `border: 6px solid ${primaryColor}; background-color: initial;`,
        icon: '',
    },
}

type MarginModifiers = keyof typeof MODIFIER_MARGIN_CONFIG

interface RoundCheckBoxModel {
    type: string
    className?: string
    size?: number
    modifiers?: MarginModifiers  | MarginModifiers[]
}

export const RoundCheckBox = styled.div.attrs(
    (props: RoundCheckBoxModel) => ({
        className: `${props.className || ''} ${checkBoxStatuses[props.type].icon}`
    })
)<RoundCheckBoxModel>`
    text-align: center;
    border-radius: 100%;
    color: #fff;
    ${({ type, size }) => `
        font-size: ${(size || 32)/2}px;
        width: ${size || 32}px;
        height: ${size || 32}px;
        line-height: ${size || 32}px;
        ${checkBoxStatuses[type].style};
    `}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
