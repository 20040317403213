import React, { memo } from 'react'
import moment, { MomentInput } from 'moment'

interface FormattedDateModel {
    date: string | MomentInput,
    format?: string
    withTitle?: boolean
}
export default memo(({ date, format, withTitle }: FormattedDateModel) => {
    date = moment(date)
    const title = withTitle ? date.format('YYYY-MM-DD H:mm') : ''
    switch (format) {
        case 'time':
            date = date.format('HH:mm')
            break
        case 'longtime':
            date = date.format('HH:mm:ss')
            break
        case 'longdatetime':
            date = date.format('L HH:mm:ss')
            break
        case 'relative':
            date = date.fromNow()
            break
        case 'calendar':
            date = date.calendar()
            break
        case 'datetime':
            date = date.format('L HH:mm')
            break
        case 'shortdate':
            date = date.format('YYYY-MM-DD')
            break
        case 'date':
        default:
            date = date.format('L')
    }

    if (title) {
        return <span style={{ cursor: 'pointer' }} title={title}>{ date }</span>
    }

    return <span>{ date }</span>
})
