import {
    GET_MATCH_SURVEILLANCE_LEADS,
    GET_MATCH_SURVEILLANCE_LEADS_SUCCESS,
    GET_MATCH_SURVEILLANCE_LEADS_FAIL,
    POSTPONE_CLIENT_MATCH_DECISION_SUCCESS,
    ACCEPT_LEAD_QUOTE_SUCCESS,
    LEAD_ACTION_GIVEN_UP_SUCCESS,
} from '../../actions/leads'

import {
    REJECT_QUOTES_SUCCESS,
} from '../../actions/quotes'
import {
    CREATE_LEAD_NO_ANSWER_ACTION,
    CREATE_LEAD_NO_ANSWER_ACTION_FAIL,
    CREATE_LEAD_NO_ANSWER_ACTION_SUCCESS
} from '../../actions/actions'
import { InitialState } from '../types/InitialState'
import { InitialAction } from '../types/InitialAction'

export interface MatchSurveillanceModel extends InitialState {
    leadIds: number[]
}
const initialState: MatchSurveillanceModel = {
    isLoading: null,
    leadIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

interface MatchSurveillanceActionModel extends InitialAction{
    leadId: number
}

export default function reducer(
    state = initialState,
    action: MatchSurveillanceActionModel
) {
    switch (action.type) {
        case GET_MATCH_SURVEILLANCE_LEADS:
        case CREATE_LEAD_NO_ANSWER_ACTION:
            return {
                ...state,
                isLoading: true,
            }
        case GET_MATCH_SURVEILLANCE_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: action.append ? state.leadIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case REJECT_QUOTES_SUCCESS:
        case CREATE_LEAD_NO_ANSWER_ACTION_SUCCESS:
        case POSTPONE_CLIENT_MATCH_DECISION_SUCCESS:
            return {
                ...state,
                leadIds: state.leadIds.filter(item => item !== action.leadId),
                isLoading: false,
            }
        case GET_MATCH_SURVEILLANCE_LEADS_FAIL:
        case CREATE_LEAD_NO_ANSWER_ACTION_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case LEAD_ACTION_GIVEN_UP_SUCCESS:
        case ACCEPT_LEAD_QUOTE_SUCCESS:
            return {
                ...state,
                leadIds: state.leadIds.filter(item => item !== action.leadId),
                pagination: action.response.pagination,
                isLoading: false,
            }
        default:
            return state
    }
}
