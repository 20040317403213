import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import RadioGroup from '../elements/forms/inputs/RadioGroup'
import MetaLabel from '../elements/text/MetaLabel'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import { postponeClientMatchDecision } from '../../actions/leads'
import { connect } from '../../utils/connect'
import { hideModal } from '../../actions/modal'

const required = value => value ? undefined : 'Required!'

interface StateProps {
    modalProps: {
        leadId: number
        disabled: boolean;
    }
}

interface DispatchProps {
    postponeClientMatchDecision: typeof postponeClientMatchDecision
    hideModal: typeof hideModal
    submit: typeof submit
}

type LeadPostponePropsModel = StateProps & DispatchProps

@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        postponeClientMatchDecision,
        hideModal,
        submit,
    }
)
class LeadPostponeModal extends PureComponent<LeadPostponePropsModel> {
    postponeDecision = data => {
        this.props.postponeClientMatchDecision(this.props.modalProps.leadId, data)
    }

    onSubmit = () => {
        this.props.submit('leadPostponeModalForm')
    }

    render() {
        const { modalProps } = this.props

        const postponeReasons = [
            'See if client has made contact with partner',
            'Talk about the quotes',
            'See if the client has booked a meeting',
            'Follow up on meeting',
            'See if a decision has been made',
            'Checkup - no contact in a while',
            'Send personal e-mail',
        ]

        const reasonsFields = postponeReasons.map(reason => ({
            id: reason,
            name: reason,
        }))

        return <Modal
            show={true}
            onHide={this.props.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Postpone</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="leadPostponeModalForm"
                    onSubmit={this.postponeDecision}
                >
                    <Field
                        component={DateTimePickerGroup}
                        validate={[ required ]}
                        name="date"
                        label="Until when should the lead be postponed?"
                    />
                    <MetaLabel>Purpose of next contact</MetaLabel>
                    <Field
                        component={RadioGroup}
                        validate={[ required ]}
                        options={reasonsFields}
                        name="clientDecisionPostponedReason"
                    />
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    modifiers={[ 'secondary', 'mR_2' ]}
                    disabled={modalProps.disabled}
                    onClick={this.props.hideModal}
                >
                    No
                </Button>
                <Button
                    modifiers={[ 'primary' ]}
                    disabled={modalProps.disabled}
                    onClick={this.onSubmit}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default LeadPostponeModal as unknown as ComponentType
