import React, { memo } from 'react'
import { Field } from 'redux-form'
import ReduxForm from '../elements/forms/ReduxForm'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../elements/Button'
import { TextareaGroup } from '../elements/forms/inputs/TextareaGroup'

const required = [ value => value ? undefined : 'Required' ]
interface CanvasNotRelevantModel {
    onSubmit(data:any):void
    formName: string
    submit
}

const CanvasNotRelevantForm = memo(({
    formName,
    onSubmit,
    submit,
}: CanvasNotRelevantModel) =>
    <ReduxForm
        form={formName}
        onSubmit={onSubmit}
    >
        <Row>
            <Col md={12}>
                <p>
                    <label>
                        <Field
                            name="action"
                            component="input"
                            type="radio"
                            value="canvas-not-in-industry"
                            validate={required}
                        />
                        {' '}
                        Not within Ageras' industries.
                    </label>
                </p>
                <p>
                    <label>
                        <Field
                            name="action"
                            component="input"
                            type="radio"
                            value="canvas-out-of-business"
                            validate={required}
                        />
                        {' '}
                        Partner is out of business.
                    </label>
                </p>
                <p>
                    <label>
                        <Field
                            name="action"
                            component="input"
                            type="radio"
                            value="canvas-already-partner"
                            validate={required}
                        />
                        {' '}
                        Already partner.
                    </label>
                </p>
                <p>
                    <label>
                        <Field
                            name="action"
                            component="input"
                            type="radio"
                            value="canvas-not-relevant"
                            validate={required}
                        />
                        {' '}
                        Other
                    </label>
                </p>
            </Col>
            <Col md={12}>
                <label>Note</label>
                <Field
                    name="note"
                    component={TextareaGroup}
                    validate={required}
                />
            </Col>
        </Row>
        <Button
            modifiers={[ 'action', 'mT_2' ]}
            onClick={() => submit(formName)}
        >
            Dismiss canvas partner
        </Button>
    </ReduxForm>,
)

export default CanvasNotRelevantForm
