import React, { ComponentType, PureComponent } from 'react'
import LoaderComponent from './elements/LoaderComponent'
import ModalsModule from './modals'
import { connect } from '../utils/connect'
import styled from 'styled-components'
import LoginPage from './pages/LoginPage'
import { getEmployees } from '../actions/employees'
import { getNotification } from '../state/notifications/actions'
import { loadAuth } from '../actions/auth'
import { getIndustries } from '../state/industries/actions'
import { ThemeProvider } from 'styled-components'
import light from 'components/src/themes/light'
const Container = styled.div`
    padding: 0 15px;
    position: relative;
`

@connect<any, any, any>(
    state => ({
        loggedIn: !!state.auth.accessToken,
        employeeId: state.auth.employeeId,
    }),
    {
        getEmployees,
        getIndustries,
        getNotification,
        loadAuth,
    }
)
class App extends PureComponent<any> {
    state = {
        isLoading: true,
    }

    async componentDidMount() {
        try {
            const response = await this.props.loadAuth()
            await Promise.all([
                this.props.getEmployees({ isActive: '1' }),
                this.props.getNotification(this.props.employeeId, {
                    isSeen: '0',
                    limit: 1000,
                }),
                this.props.getIndustries({ geoCode: response.employee.wantedGeoCode }),
            ])
        } finally {
            await this.setState({
                isLoading: false,
            })
        }
    }

    render() {
        if (this.state.isLoading) {
            return <LoaderComponent type="logo" />
        }

        if (!this.props.loggedIn) {
            return <LoginPage/>
        }

        return <Container>
            <ThemeProvider theme={light}>
                {this.props.children}
            </ThemeProvider>
            <ModalsModule/>
        </Container>
    }
}

export default App as unknown as ComponentType
