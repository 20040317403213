import { createSelector } from 'reselect'

export const leadTypesEntities = state => state.entities.leadTypes

export const allLeadTypesId = state => state.subjects.leadTypes.all.ids

export const businessUnitTypesId = state => state.subjects.leadTypes.businessUnit.ids

export const allLeadTypesSelector = createSelector(
    allLeadTypesId, leadTypesEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const businessUnitLeadTypesSelector = createSelector(
    businessUnitTypesId, leadTypesEntities,
    (ids, entities) => ids.map(id => entities[id])
)
