import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import CallButton from '../elements/CallButton'
import { createCallPartnerAction, getPartners } from '../../actions/partners'
import { segmentedPartnerListSelector } from '../../selectors/partner'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { Lead } from '../../reducers/types/entities/Lead'
import { leadActionSendToPartner } from '../../actions/leads'
import ActionButton from '../elements/ActionButton'
import styled from 'styled-components'
import { Column, Header, Rows, Cell, SatisfactionCell, EmployeeCell, DateCell, LazyTable } from '../elements/table'
import { Pagination } from '../../reducers/types/Pagination'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 1300px;
        height: 100%;
    }
`
interface PartnerRowPathProps {
    leadId: number
}

interface PartnerRowModel extends PartnerRowPathProps {
    rowData: Partner
    createCallPartnerAction: typeof createCallPartnerAction
    leadActionSendToPartner: typeof leadActionSendToPartner
}

@connect(null, {
    createCallPartnerAction,
    leadActionSendToPartner,
})
class PartnerRow extends PureComponent<PartnerRowModel> {
    callButton = () => {
        const partner = this.props.rowData
        return this.props.createCallPartnerAction(partner.id, partner.phone)
    }

    sendToPartner = () => {
        const partner = this.props.rowData
        const leadId = this.props.leadId

        return (this.props.leadActionSendToPartner(leadId, partner.id) as any)
    }

    render() {
        const partner = this.props.rowData
        return <tr key={partner.id}>
            <Cell> 
                {' '}
                <a href={`/partners/${partner.id}`} target="_blank" rel="noreferrer">{partner.id}</a>
            </Cell>
            <Cell>{partner.companyName}</Cell>
            <Cell>
                {partner.geo.zipCode} 
                {' '}
                {partner.geo.cityName}
            </Cell>
            <Cell>{partner.state}</Cell>
            <SatisfactionCell satisfaction={partner.satisfaction} />
            <EmployeeCell portrait={false} employee={partner.employee} />
            {partner.kpi ? <DateCell date={partner.kpi.lastLogin} /> : <Cell/>}
            {partner.kpi ? <DateCell date={partner.kpi.lastPhoneCall} format="datetime" /> : <Cell/>}
            <Cell>{partner.subscription ? partner.subscription.map(el => el.packageType).join(', ') : ''}</Cell>
            <Cell className="text-center">
                {partner.phone &&
                <CallButton
                    onCall={this.callButton}
                    phoneNumber={partner.phone}
                    geoCode={partner.geo.code}
                />
                }
            </Cell>
            <Cell>
                <ActionButton
                    icon="mdi mdi-email-outline"
                    modifiers={[ 'action', 'btnBlock' ]}
                    action={this.sendToPartner}
                    hiddenData="Sent"
                />
            </Cell>
        </tr>
    }
}

const PartnerTableRow = PartnerRow as ComponentType<PartnerRowPathProps>

interface SegmentedPartnersModel {
    partners: Partner[]
    totalItems: null & number
    modalProps: {
        lead: Lead,
    }
    getPartners: typeof getPartners
    hideModal: typeof hideModal
    pagination: Pagination
}

@connect(
    state => ({
        partners: segmentedPartnerListSelector(state),
        totalItems: state.pages.quotesSurveillance.partnersPagination.total,
        pagination: state.pages.quotesSurveillance.partnersPagination,
        modalProps: state.modal,
    }),
    {
        getPartners,
        hideModal,
    }
)
class SegmentedPartnersModal extends PureComponent<SegmentedPartnersModel> {
    hideModal = () => this.props.hideModal()

    componentDidMount() {
        this.retrieveData()
    }

    retrieveData = (filterCriteria?, append?:boolean) => {
        const lead = this.props.modalProps.lead
        let leadPrimaryIndustry: number | undefined
        if (lead.industries.primary && lead.industries.primary.id) {
            leadPrimaryIndustry = lead.industries.primary.id
        }

        this.props.getPartners({
            ...filterCriteria,
            geoCode: lead.geo.code,
            wantsExclusiveLeads: true,
            hasUnusedAllocations: true,
            industryId: leadPrimaryIndustry,
            sort: 'company_name',
            geoPoint: lead.geo.point.lat + ',' + lead.geo.point.lon,
        }, append)
    }

    loadMore = page => {
        return this.retrieveData({ page }, page > 1)
    }

    render() {
        const {
            partners,
            modalProps,
            totalItems,
            pagination,
        } = this.props

        return <LgModal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title id="contained-modal-title-lg">
                    Partners segmented for lead #
                    {modalProps.lead.id}
                    <span className="text-muted">
                        {' '}
                        (
                        {partners.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Modal.Title>
            </Modal.Header>
            <LazyTable
                pagination={pagination}
                retrieveData={this.loadMore}
                pagesStateName="quotesSurveillance"
            >
                <Header>
                    <Column name="id">ID</Column>
                    <Column name="company_name">Company name</Column>
                    <Column name="location">Location</Column>
                    <Column name="state">Status</Column>
                    <Column name="satisfaction">Satisfaction</Column>
                    <Column name="employee_name">Employee</Column>
                    <Column name="last_login">Last Login</Column>
                    <Column name="last_phone_call">Last Phone Call</Column>
                    <Column name="package_type">Subscription</Column>
                    <Column>Call to partner</Column>
                    <Column>Send lead to partner</Column>
                </Header>
                <Rows
                    items={partners}
                    noDataText="We couldn't find any partners matching your selection."
                >
                    <PartnerTableRow leadId={modalProps.lead.id} />
                </Rows>
            </LazyTable>
        </LgModal>
    }
}

export default SegmentedPartnersModal as unknown as ComponentType

