import React, { PureComponent } from 'react'
import {
    partnerFeesSelector,
    partnerQuotesSelector,
    partnerUniqueSellingPointsSelector,
} from '../../selectors/partner'
import { toastr } from 'react-redux-toastr'
import CompanyDetailsPartnerCard from './detailPage/CompanyDetailsPartnerCard'
import SubscriptionsPartnerCard from './detailPage/SubscriptionsPartnerCard'
import { Subscription } from '../../reducers/types/entities/Subscription'
import SellingPointListPartnerCard from './detailPage/SellingPointListPartnerCard'
import { PartnerFee } from '../../reducers/types/entities/PartnerFee'
import {
    actualSubscriptionSelector,
    subscriptionsSelector,
} from '../../selectors/subscription'
import { getPartner, getPartnerFees, getPartnerSellingPoints, toggleWantsOverdueInvoiceReminders } from '../../actions/partners'
import CanvasControlsCard from './detailPage/CanvasControlsCard'
import FinancePartnerCard from './detailPage/FinancePartnerCard'
import { Partner } from '../../reducers/types/entities/Partner'
import { Invoice } from '../../reducers/types/entities/Invoice'
import PartnerUsersCard from './detailPage/PartnerUsersCard'
import DemoControlsCard from './detailPage/DemoControlsCard'
import { Quote } from '../../reducers/types/entities/Quote'
import LoaderComponent from '../elements/LoaderComponent'
import PartnerHeader from './detailPage/PartnerHeader'
import LeadListCard from './detailPage/LeadListCard'
import { showModal } from '../../actions/modal'
import PartnerNewsFeed from './PartnerNewsFeed'
import PageTitle from '../elements/PageTitle'
import { connect } from '../../utils/connect'
import styled from 'styled-components'
import {
    getPartnerContracts,
    cancelPartnerContractNormally,
    archivePartnerContract,
    downloadPartnerContract,
    downloadSignedPartnerContract,
} from '../../actions/partnerContracts'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import PartnerContractListCard from './detailPage/PartnerContractListCard'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import moment from 'moment'
import StatisticsCard from './detailPage/StatisticsCard'
import { getPartnerQuotes } from '../../actions/quotes'
import { PauseSubscription } from '../../reducers/types/entities/PauseSubscription'
import { PartnerUniqueSellingPoint } from '../../reducers/types/entities/PartnerUniqueSellingPoint'
import { getPausePartnerSubscription } from '../../state/pauseSubscriptions/actions'
import { partnerPauseSubscriptionsSelect } from '../../state/pauseSubscriptions/selectors'
import { AllocationLimit } from '../../reducers/types/entities/AllocationLimit'
import { getBusinessUnitsAllocationLimits } from '../../state/allocationsLimits/actions'
import { dueInvoicesSelector } from '../../state/invoices/selectors'
import { getDueInvoices } from '../../state/invoices/actions'
import { PartnerContracts } from '../../reducers/types/entities/PartnerContract'
import { convertPartnerSubscription } from '../../actions/subscriptions'
import PartnerAccessControl from './detailPage/PartnerAccessControl'
import { downloadFile } from '../../utils'

const BasicGridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
  > div {
      margin:0;
      overflow: hidden;
  }
`

const GridRow1 = styled(BasicGridRow)<{subscriptionIsActive: boolean, hasContract: boolean, isAMPartner: boolean}>`
  grid-auto-rows: 50px;
  .statisticsCard {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 19;
  }
  .partnerNewsFeed,
  .partnerStatus,
  .canvas-demo {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 12;
  }

  .leadListCard{
    grid-column-start: 2;
    grid-row-start: 12;
    grid-row-end: 19;
  }

  .partnerUsersCard {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  .partnerAccessControl {
    grid-column-start: 3;
    grid-row-start: 6;
    grid-row-end: 9;
  }

  .companyDetailsPartnerCard {
    grid-column-start: 3;
    grid-row-start: 9;
    grid-row-end: 19;
  }
`

const GridRow1Demo = styled(BasicGridRow)`
  grid-auto-rows: 50px;

  .partnerNewsFeed {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 10;
  }

  .canvas-demo {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .partnerUsersCard {
    grid-column-start: 2;
    grid-row-start: 5;
    grid-row-end: 10;
  }

  .companyDetailsPartnerCard {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 15;
  }

  .segmentationCard {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 10;
    grid-row-end: 20;
  }

  .contractCard {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 10;
    grid-row-end: 20;
  }
`

const GridRow2 = styled(BasicGridRow)`
  grid-auto-rows: 65px;
  .segmentationCard {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 10;
  }
  .contractCard {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 10;
  }
  .subscriptionsPartnerCard {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 10;
  }
  .financePartnerCard {
    grid-column-start: 2;
    grid-row-start: 10;
    grid-row-end: 15;
  }
  .sellingPointList {
    grid-column-start: 1;
    grid-row-start: 10;
    grid-row-end: 15;
  }
`

interface DispatchPropsPartnerPage {
    convertPartnerSubscription: typeof convertPartnerSubscription,
    getBusinessUnitsAllocationLimits: typeof getBusinessUnitsAllocationLimits
    getPausePartnerSubscription: typeof getPausePartnerSubscription
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getPartnerContracts: typeof getPartnerContracts
    cancelPartnerContractNormally: typeof cancelPartnerContractNormally
    archivePartnerContract: typeof archivePartnerContract
    downloadPartnerContract: typeof downloadPartnerContract
    downloadSignedPartnerContract: typeof downloadSignedPartnerContract
    getPartnerQuotes: typeof getPartnerQuotes
    getPartnerFees: typeof getPartnerFees
    getDueInvoices: typeof getDueInvoices
    getPartner: typeof getPartner
    getPartnerSellingPoints: typeof getPartnerSellingPoints
    showModal: typeof showModal
    toggleWantsOverdueInvoiceReminders: typeof toggleWantsOverdueInvoiceReminders
}

interface StatePropsPartnerPage {
    partnersCreatePermission: {}
    isLoadingSubscriptions: boolean
    isLoadingBusinessUnits: boolean
    subscriptions: Subscription[]
    businessUnits: BusinessUnit[]
    partnerContracts: PartnerContracts
    givenQuotes: Quote[]
    partnerFees: PartnerFee[]
    isLoading: boolean
    invoices: Invoice[]
    sellingPoints: PartnerUniqueSellingPoint[]
    partner: Partner
    allocationLimits: AllocationLimit[]
    partnerQuotesTotal: number
    subscriptionInfo
    pauseSubscription: {
        isActivePause: boolean
        pauses: PauseSubscription[]
    }
}
interface PathPropsPartnerPage {
    match: {
        params: {
            partnerId: number
        }
    }
}

type PartnerPageModel = StatePropsPartnerPage & DispatchPropsPartnerPage & PathPropsPartnerPage

@connect<StatePropsPartnerPage, DispatchPropsPartnerPage, PathPropsPartnerPage>(
    (state, props) => ({
        partnerFees: partnerFeesSelector(state, props.match.params.partnerId),
        givenQuotes: partnerQuotesSelector(state),
        businessUnits: Object.values(state.entities.businessUnits).filter(bu => bu.partnerId === Number(props.match.params.partnerId)),
        partnerContracts: state.entities.partnerContracts,
        subscriptions: subscriptionsSelector(state, props.match.params.partnerId),
        pauseSubscription: partnerPauseSubscriptionsSelect(state),
        invoices: dueInvoicesSelector(state),
        sellingPoints: partnerUniqueSellingPointsSelector(state),
        allocationsQuotas: state.entities.allocationsQuotas,
        isLoadingSubscriptions: state.pages.partnerDetail.isLoadingSubscriptions,
        isLoadingBusinessUnits: state.pages.partnerDetail.loadingBusinessUnits,
        partnersCreatePermission: state.entities.permissions.partners_create,
        partner: state.entities.partners[props.match.params.partnerId],
        isLoading: state.pages.partnerDetail.isLoading,
        partnerQuotesTotal: state.pages.partnerQuotes.pagination.total,
        subscriptionInfo: actualSubscriptionSelector(state, props.match.params.partnerId),
        allocationLimits: Object.values(state.entities.allocationLimits),
    }),
    {
        convertPartnerSubscription,
        getBusinessUnitsAllocationLimits,
        getPausePartnerSubscription,
        getPartnerBusinessUnits,
        getPartnerContracts,
        cancelPartnerContractNormally,
        archivePartnerContract,
        downloadPartnerContract,
        downloadSignedPartnerContract,
        getPartnerQuotes,
        getPartnerFees,
        getDueInvoices,
        getPartner,
        getPartnerSellingPoints,
        showModal,
        toggleWantsOverdueInvoiceReminders,
    }
)
export default class PartnerPage extends PureComponent<PartnerPageModel> {
    state = {
        isLoadingContracts: false,
        partnerContractIds: [],
        badges: [],
    }

    async componentDidMount() {
        const { partnerId } = this.props.match.params
        await this.props.getPartner(partnerId)
        await this.setState({ isLoadingContracts: true })
        const contractResponse: any = await this.props.getPartnerContracts(partnerId, { isArchived: '0' })
        await this.setState({ isLoadingContracts: false })
        const businessUnits: any = await this.props.getPartnerBusinessUnits({ partnerId })
        businessUnits.result.map(buId => this.props.getBusinessUnitsAllocationLimits(partnerId, {
            partnerBusinessUnitId: Number(buId),
        }))
        this.props.getPartnerFees(partnerId)
        this.props.getDueInvoices(partnerId)
        this.props.getPartnerSellingPoints(partnerId)
        const activeSubscription = this.props.partner.subscription.find(sub => moment().isBefore(sub.expiresAt))
        if (activeSubscription) {
            this.props.getPausePartnerSubscription(partnerId, activeSubscription.id)
        }
        this.props.getPartnerQuotes({
            partnerId,
            limit: 100,
            sort: '-created_at',
        })
        this.setState({
            partnerContractIds: contractResponse.result,
        })
        if (this.props.partner.state !== 'demo') this.getBusinessModelBadges(contractResponse.result.map(id => this.props.partnerContracts[id]))
    }

    getBusinessModelBadges = contracts => {
        const businessUnits = contracts.filter(contract => contract.currentPartnerContractPeriod &&
            moment().isSameOrAfter(contract.currentPartnerContractPeriod.startsAt) &&
            moment().isBefore(contract.currentPartnerContractPeriod.endsAt)).map(contract => contract.businessUnits)
            .flat()

        const badges = []
        businessUnits.forEach(bu => {
            if (bu.wantsExclusiveLeads) {
                badges.push('exclusive')
            }
            if (bu.allocationLimit) {
                badges.push('pps')
            }
            if (bu.allocationQuota) {
                badges.push('lb')
            }
        })
        this.setState({
            badges: [ ...new Set(badges) ],
        })
    }

    editPartnerFee = () => {
        this.props.showModal({
            name: 'editPartnerFeeModel',
            partnerId: this.props.partner.id,
        })
    }

    editPartnerUniqueSellingPoints = () => {
        this.props.showModal({
            name: 'editPartnerUniqueSellingPointsModel',
            partnerId: this.props.partner.id,
            sellingPoints: this.props.sellingPoints,
        })
    }

    convertPartnerSubscription = async partnerId => {
        try {
            await this.props.convertPartnerSubscription(partnerId)
            const contractsResponse = await this.props.getPartnerContracts(partnerId) as any
            await this.setState({ partnerContractIds: contractsResponse.result })
            toastr.success('Success!', 'The conversion succeeded.')
        } catch (e) {
            toastr.error('Error!', 'The conversion failed.')
        }
    }

    archivePartnerContract = async (partnerId: number, contractId: number) => {
        try {
            await this.props.archivePartnerContract(partnerId, contractId)
            const contractsResponse = await this.props.getPartnerContracts(partnerId, { isArchived: '0' }) as any
            await this.setState({ partnerContractIds: contractsResponse.result })
            toastr.success('Success!', 'The contract has been successfully archived.')
        } catch (e) {
            toastr.error('Error!', 'The contract could not be archived.')
            throw e
        }
    }

    downloadPartnerContract = async (partnerId: number, contractId: number) => {
        try {
            const result: any = await this.props.downloadPartnerContract(partnerId, contractId)

            downloadFile(result.url)
        } catch (e) {
            toastr.error('Error!', 'The contract could not be downloaded')
        }
    }

    downloadSignedPartnerContract = async (partnerId: number, contractId: number) => {
        try {
            const result: any = await this.props.downloadSignedPartnerContract(partnerId, contractId)

            downloadFile(result.url)
        } catch (e) {
            toastr.error('Error!', 'The signed contract could not be downloaded')
        }
    }

    handleWantsOverdueInvoiceReminders = async (checked) => {
        const { partner } = this.props
        try {
            if (checked) {
                await this.props.toggleWantsOverdueInvoiceReminders(partner.id, false)
                toastr.success('Partner will NOT receive overdue invoice reminders')
            } else {
                await this.props.toggleWantsOverdueInvoiceReminders(partner.id, true)
                toastr.success('Partner will receive overdue invoice reminders')
            }
        } catch (e) {
            toastr.error('Error!', e)
        }
    }

    render() {
        const {
            subscriptionInfo: {
                createdSubscription,
                isActiveSubscription,
            },
            isLoadingSubscriptions,
            isLoadingBusinessUnits,
            allocationLimits,
            pauseSubscription,
            businessUnits,
            partnerContracts,
            givenQuotes,
            isLoading,
            invoices,
            partner,
        } = this.props

        const {
            partnerContractIds,
            badges,
        } = this.state

        if (!partner || isLoading && !partner) return <LoaderComponent type="logo" />

        const isAMPartner = partner.subscription.some(el => el.packageType === 'Ageras Marketing Package')

        return (
            <div>
                <PageTitle title={partner.companyName} />
                <PartnerHeader
                    partner={partner}
                    subscription={createdSubscription}
                    badges={badges}
                    isActivePause={pauseSubscription.isActivePause}
                    isRenderedByPartnerPage={true}
                />

                {partner.state === 'demo' && <GridRow1Demo>
                    <PartnerNewsFeed partnerId={partner.id} />

                    <div className="canvas-demo">
                        <DemoControlsCard partner={partner}/>
                    </div>

                    <PartnerUsersCard partnerId={partner.id} geoCode={partner.geo.code}/>

                    <CompanyDetailsPartnerCard partner={partner} businessUnits={businessUnits}/>

                    <PartnerContractListCard
                        isLoadingContracts={this.state.isLoadingContracts}
                        isLoadingUnits={isLoadingBusinessUnits}
                        partnerContracts={partnerContractIds.map(id => partnerContracts[id])}
                        archivePartnerContract={this.archivePartnerContract}
                        downloadPartnerContract={this.downloadPartnerContract}
                        downloadSignedPartnerContract={this.downloadSignedPartnerContract}
                        partner={partner}
                        showModal={this.props.showModal}
                    />

                </GridRow1Demo>}

                {partner.state !== 'demo' && <GridRow1 subscriptionIsActive={isActiveSubscription} hasContract={partnerContractIds.length > 0} isAMPartner={isAMPartner}>
                    <PartnerNewsFeed partnerId={partner.id} />

                    <StatisticsCard
                        geoCode={partner.geo.code}
                        showModal={this.props.showModal}
                        businessUnits={businessUnits}
                        lastGivenQuote={givenQuotes[0]}
                        partnerKpi={partner.newKpi}
                        partnerId={partner.id}
                        activePartnerContracts={Boolean(partnerContractIds.length) ? partnerContractIds.map(id => partnerContracts[id]).filter(contract => contract.currentPartnerContractPeriod) : []}
                    />

                    {partner.state === 'canvas' && <div className="canvas-demo">
                        <CanvasControlsCard partner={partner}/>
                    </div>}

                    <LeadListCard
                        partner={partner}
                        givenQuotes={givenQuotes}
                    />

                    <PartnerUsersCard partnerId={partner.id} geoCode={partner.geo.code}/>

                    <PartnerAccessControl partner={partner} partnerId={partner.id} />

                    <CompanyDetailsPartnerCard partner={partner} businessUnits={businessUnits}/>
                </GridRow1>}

                {partner.state !== 'demo' && <GridRow2>
                    <PartnerContractListCard
                        isLoadingContracts={this.state.isLoadingContracts}
                        isLoadingUnits={isLoadingBusinessUnits}
                        partnerContracts={partnerContractIds.map(id => partnerContracts[id])}
                        archivePartnerContract={this.archivePartnerContract}
                        downloadPartnerContract={this.downloadPartnerContract}
                        downloadSignedPartnerContract={this.downloadSignedPartnerContract}
                        partner={partner}
                        showModal={this.props.showModal}
                        businessUnits={businessUnits}
                        allocationLimits={allocationLimits}
                    />

                    <SubscriptionsPartnerCard
                        isLoadingSubscriptions={isLoadingSubscriptions}
                        subscription={createdSubscription}
                        partner={partner}
                        onConvertClick={
                            this.state.partnerContractIds.length
                                ? null
                                : this.convertPartnerSubscription
                        }
                    />

                    {partner.kpi && <FinancePartnerCard
                        partner={partner}
                        invoices={invoices}
                        handleWantsOverdueInvoiceReminders={this.handleWantsOverdueInvoiceReminders}
                    />}

                    <SellingPointListPartnerCard
                        editPartnerUniqueSellingPoints={this.editPartnerUniqueSellingPoints}
                        partnerId={partner.id}
                    />
                </GridRow2>}
            </div>
        )
    }
}
