import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import connect from 'react-redux/es/connect/connect'
import { Link } from 'react-router-dom'
import { Pagination } from '../../../reducers/types/Pagination'
import { CMatch } from '../../../reducers/types/entities/CMatch'
import { Cell, Column, DateCell, Header, LinkCell, Rows, SortColumn, Table } from '../../elements/table'
import { allCMatchesSelector } from '../../../state/cMatches/selectors'
import { getMatches } from '../../../state/cMatches/actions'
import InfiniteScroll from 'react-infinite-scroller'
import LoaderComponent from '../../elements/LoaderComponent'

const MatchRow = ({ rowData }) => {
    const match = rowData
    return <tr>
        <LinkCell to={`/leads/${match.leadId}/validation`}>{match.leadId}</LinkCell>
        <Cell>{match.partnerId}</Cell>
        <Cell>{match.acceptedAt ? 'accepted' : 'unaccepted'}</Cell>
        <DateCell date={match.acceptedAt} />
        <DateCell date={match.unacceptedAt} />
        <Cell>{match.matchChannel}</Cell>
    </tr>
}

const MatchTableRow = MatchRow as ComponentType

interface MatchListCardModel {
    matches: CMatch[]
    pagination: Pagination
    isLoading: boolean
    employeeId: number
    location: {
        pathname: string
    }
    getMatches: typeof getMatches
}

@connect(
    state => ({
        matches: allCMatchesSelector(state),
        pagination: state.subjects.cMatches.all.pagination,
        isLoading: state.subjects.cMatches.all.isLoading,
        employeeId: state.auth.employeeId,
        location: state.router.location,
    }),
    {
        getMatches,
    }
)
class MatchListCard extends PureComponent<MatchListCardModel> {
    state = {
        sort: '-accepted_at'
    }

    componentDidMount() {
        this.retrieveData()
    }

    retrieveData = async (data?, append?) => {
        const criteria = {
            ...data,
            validatorId: this.props.employeeId,
        }
        return this.props.getMatches(criteria, append)
    }

    loadMore = page => this.retrieveData({ page, ...this.state }, true)

    onSort = (name) => {
        this.setState({ sort: name })
        this.retrieveData(this.state)
    }

    render() {
        const {
            isLoading,
            matches,
            pagination: {
                pages,
                page,
            },
        } = this.props

        const { sort } = this.state
        const hasMore = (page < pages)

        return <Card>
            <Card.Header>
                <Link to="/quotes/follow-ups">Matches</Link>
            </Card.Header>
            <Card.Content modifiers={[ 'p_0', 'scroll', 'maxHeightMd' ]} isLoading={isLoading}>
                <InfiniteScroll
                    pageStart={1}
                    loadMore={this.loadMore}
                    hasMore={isLoading ? false : hasMore}
                    loader={<LoaderComponent/>}
                    useWindow={false}
                >
                    <Table>
                        <Header>
                            <Column>Lead ID</Column>
                            <Column>Partner ID</Column>
                            <Column>Status</Column>
                            <SortColumn onClick={this.onSort} name="accepted_at" sort={sort}>Accepted date</SortColumn>
                            <SortColumn onClick={this.onSort} name="unaccepted_at" sort={sort}>Unaccepted date</SortColumn>
                            <Column>Match Channel</Column>
                        </Header>
                        <Rows
                            items={matches}
                            noDataText="No matches."
                        >
                            <MatchTableRow/>
                        </Rows>
                    </Table>
                </InfiniteScroll>
            </Card.Content>
        </Card>
    }
}


export default MatchListCard as unknown as ComponentType
