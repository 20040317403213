import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'

const calculateData = (activePeriod: string, bu: BusinessUnit) => {
    const allocationType = activePeriod === 'currentMonth'
        ? 'currentAllocation'
        : 'accumulatedAllocation'

    let lbLimit = bu.currentAllocation.allocationQuotaPerRefill
    if (activePeriod === 'sinceStart') lbLimit = bu.accumulatedAllocation.allocationQuota
    const lbCount = bu[allocationType].normalQuotaAllocationCount
    const overdeliveryCount = bu[allocationType].justInTimeAllocationCount + bu[allocationType].overdeliveryAllocationCount

    const ppsCount = bu[allocationType].payPerSpotAllocationCount
    const ppsLimit = bu[allocationType].payPerSpotAllocationLimit

    return { lbLimit, lbCount, overdeliveryCount, ppsCount, ppsLimit }
}

export default (bu: BusinessUnit) => {
    const currentMonth = calculateData('currentMonth', bu)
    const sinceStart = calculateData('sinceStart', bu)
    const lbFull = sinceStart.lbCount + sinceStart.lbLimit === 0 ||
        sinceStart.lbCount / sinceStart.lbLimit >= 1
    const ppsFull = currentMonth.ppsCount + currentMonth.ppsLimit === 0 ||
        currentMonth.ppsCount / currentMonth.ppsLimit >= 1

    return lbFull && ppsFull
}
