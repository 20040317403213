import React, { Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card } from '../blocks/Card/index'
import PartnerPaymentOptions from './detailPage/PartnerPaymentOptions'
import moment from 'moment'
import { Button } from '../elements/Button'

export const PartnerStatus = ({
    partnersCreatePermission,
    hidePartnerPayment,
    lastGivenQuote,
    editPartnerFee,
    subscriptions,
    totalQuotes,
    partnerFee,
    partner,
}) =>
    <Card className="partnerStatus">
        <Card.Header>
            Partner status 
            {' '}
            <span className="label label-success">{partner.status}</span>
        </Card.Header>
        <Card.Content>
            <Row>
                <Col sm={6}>
                    <Card.Label>Quotes</Card.Label>
                    <Card.Text>{totalQuotes}</Card.Text>
                </Col>
                <Col sm={6}>
                    <Card.Label>Subscription renew</Card.Label>
                    {subscriptions.map(subscription =>
                        <Card.Text key={ subscription.id }>{moment(subscription.expiresAt).calendar()}</Card.Text>,
                    )}
                </Col>
                <Col sm={6}>
                    <Card.Label>Matches</Card.Label>
                    <Card.Text className="data-field">{partner.kpi && partner.kpi.acceptedQuotesCount}</Card.Text>
                </Col>
                <Col sm={6}>
                    <Card.Label>Last quote</Card.Label>
                    <Card.Text>{lastGivenQuote && moment(lastGivenQuote.createdAt).format('D. MMMM YYYY')}</Card.Text>
                </Col>
                {partnersCreatePermission && <Col md={12}>
                    <Card.Label>Success fee</Card.Label>
                    {partnerFee ?
                        <Fragment>
                            <Card.Text>
                                Revenue share percentage:
                                {partnerFee.revenueSharePercentage}
                            </Card.Text>
                            <Card.Text>
                                Revenue share subsequent years percentage:
                                {partnerFee.revenueShareSubsequentYearsPercentage}
                            </Card.Text>
                        </Fragment> :
                        <Card.Text>Partner has the regular 30% and 10% fee structure.</Card.Text>
                    }
                    <Button modifiers={[ 'primary', 'mB_2' ]} onClick={editPartnerFee}>Edit fee</Button>
                </Col>}
            </Row>
            <div>
                {hidePartnerPayment || <Fragment>
                    <hr />
                    <PartnerPaymentOptions partner={partner}/>
                </Fragment>}
            </div>
        </Card.Content>
    </Card>
