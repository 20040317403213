import React, { memo } from 'react'
import styled from 'styled-components'
import Input from './_Input'
import Bevel from './_Bevel'
import Knob from './_Knob'
import { WrappedFieldProps } from 'redux-form'
import { FieldInputProps } from 'formik'

const InputLabel = styled.label`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-weight: normal;
    margin: 7px 0;
`

interface ToggleCheckboxModel<Value> extends WrappedFieldProps {
    field?: FieldInputProps<Value> | any
    form?: any
}

const ToggleCheckbox = (props: any) =>
    <InputLabel>
        <Input
            {...props}
            {...props.input}
            {...props.inputProps}
            {...(() => {
                if (props.form) {
                    return {
                        onChange: event => {
                            console.log({ props, event, checked: event.target.checked })
                            props.form.setFieldValue(props.field.name, props.checked ? undefined : props.value)
                        }
                    }
                }
                return {}
            })()}
        />
        <Bevel>
            <Knob/>
        </Bevel>
    </InputLabel>

export default memo(ToggleCheckbox)
