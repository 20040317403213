import React, { memo } from 'react'
import { Field, FieldArray } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import { Card } from '../../blocks/Card'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import { SeparatorLine } from '../../elements/Separators'
import { MultipleCheckboxDataField } from '../../elements/forms/inputs/CheckboxGroup'
import { RadioInputField } from '../../elements/forms/inputs/RadioGroup'
import { Flex } from '../../elements/Flex'
import Placeholder from '../../elements/Placeholder'
import { TextareaGroup } from '../../elements/forms/inputs/TextareaGroup'
import { DynamicDataFields } from '../../elements/dynamicDataFields'
import { LeadType } from '../../../reducers/types/entities/LeadType'
import { Industry } from '../../../reducers/types/entities/Industry'
import { Sector } from '../../../reducers/types/entities/Sector'
import { sortObjectWithLanguageSensitivity } from '../../../utils/sortObjectWithLanguageSensitivity'
import config from '../../../config'

interface LeadCaseInformationModel {
    handlePrimaryIndustryChange
    isLoadingDataFields: boolean
    parentIndustries: Industry[]
    subIndustries: Industry[]
    primarySectors: Sector[]
    leadTypes: LeadType[]
    geoCode: string
    isLoanLeadType: boolean
}

export const LeadCaseInformationCard = memo(({
    handlePrimaryIndustryChange,
    isLoadingDataFields,
    parentIndustries,
    primarySectors,
    subIndustries,
    leadTypes,
    geoCode,
    isLoanLeadType,
}: LeadCaseInformationModel) => {
    const styles = {
        marketingDescription: {
            marginBottom: 50,
        },
    }

    const sortedPrimarySectors = sortObjectWithLanguageSensitivity(primarySectors, 'displayName', geoCode)

    return <Card padding="30">
        <Card.Header>
            <b>Case information</b>
        </Card.Header>
        <Card.Content>
            <Row>
                <Col sm={6} md={12} lg={6}>
                    <Field
                        label="Type of professional needed"
                        name="industries.primary.id"
                        onChange={handlePrimaryIndustryChange}
                        returnId={true}
                        component={SelectInputGroup}
                    >
                        <option value="">Select..</option>
                        {parentIndustries.map((industry, i) =>
                            <option
                                key={'industries.primary_' + i}
                                value={industry.id}
                            >
                                {industry.name}
                            </option>
                        )}
                    </Field>
                </Col>
                <Col sm={6} md={12} lg={6}>
                    <Field
                        label="Type of client"
                        name="customerInfo.customerType.id"
                        returnId={true}
                        component={SelectInputGroup}
                    >
                        <option value="0">Select..</option>
                        {leadTypes.map((leadType, i) =>
                            <option
                                key={'leadType_' + i}
                                value={leadType.id}
                                hidden={Boolean(leadType.archivedAt)}
                            >
                                {leadType.title}
                            </option>
                        )}
                    </Field>
                </Col>
            </Row>
            {subIndustries.map((industry, i) =>
                <Flex key={'subIndustries_' + i}>
                    <Field
                        name="industries.secondary.id"
                        component={RadioInputField}
                        option={{
                            id: industry.id,
                            name: industry.name,
                            slug: industry.slug,
                        }}
                    />
                    <Field
                        name="industries.subIndustries"
                        component={MultipleCheckboxDataField}
                        option={{
                            id: industry.id,
                            name: industry.name,
                            slug: industry.slug,
                        }}
                    />
                    <Card.Text>{industry.name}</Card.Text>
                </Flex>
            )}
        </Card.Content>
        <SeparatorLine modifiers="m_0"/>
        <Card.Content>
            <Row>
                {!isLoanLeadType && <Col md={12}>
                    <Field
                        label="Title"
                        name="title"
                        component={FieldGroup}
                    />
                </Col>}
                {!isLoanLeadType && <Col sm={6} md={12} lg={6}>
                    <Field
                        label="Company name"
                        name="customerInfo.companyName"
                        component={FieldGroup}
                    />
                </Col>}
                <Col sm={6} md={12} lg={6}>
                    <Field
                        label="Vat number"
                        name="customerInfo.vatNumber"
                        component={FieldGroup}
                    />
                </Col>
                <Col sm={6} md={12} lg={6}>
                    <Field
                        label="Sector"
                        name="primarySector.id"
                        returnId={true}
                        component={SelectInputGroup}
                    >
                        {sortedPrimarySectors.map((sector, i) =>
                            <option
                                key={'primarySector.sector' + i}
                                value={sector.id}
                            >
                                {sector.archivedAt && '(Archived) '}
                                {sector.displayName}
                            </option>
                        )}
                    </Field>
                </Col>
            </Row>
            {/*Generate fields from response */}
            {isLoadingDataFields ?
                <Placeholder/> :
                <FieldArray
                    name="attributes"
                    component={DynamicDataFields}
                    category="unknown"
                />
            }
            {!isLoanLeadType && <Field
                label="Lead description"
                name="description"
                component={TextareaGroup}
            />}
            {!isLoanLeadType && geoCode && config.marketplaceCountries.includes(geoCode) && <Field
                label="Lead marketplace description"
                name="marketplaceDescription"
                component={TextareaGroup}
                style={styles.marketingDescription}
            />}
        </Card.Content>
    </Card>
})
