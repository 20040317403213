import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'
import { Amount } from '../reducers/types/entities/Amount'

export const CREATE_LEAD_ACTION = 'leads/CREATE_LEAD_ACTION'
export const CREATE_LEAD_ACTION_SUCCESS = 'leads/CREATE_LEAD_ACTION_SUCCESS'
export const CREATE_LEAD_ACTION_FAIL = 'leads/CREATE_LEAD_ACTION_FAIL'

export const CREATE_LEAD_NO_ANSWER_ACTION = 'leads/CREATE_LEAD_NO_ANSWER_ACTION'
export const CREATE_LEAD_NO_ANSWER_ACTION_SUCCESS = 'leads/CREATE_LEAD_NO_ANSWER_ACTION_SUCCESS'
export const CREATE_LEAD_NO_ANSWER_ACTION_FAIL = 'leads/CREATE_LEAD_NO_ANSWER_ACTION_FAIL'

interface CreateLeadActionModel {
  action: string
  partnerId?: number
  customFee?: Amount
}

export const createLeadAction = (
    leadId: number,
    body: CreateLeadActionModel,
    types?: string[],
) => ({
    [CALL_API]: {
        types: types || [ CREATE_LEAD_ACTION, CREATE_LEAD_ACTION_SUCCESS, CREATE_LEAD_ACTION_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body,
        },
    },
    leadId,
})

export const createLeadNoAnswerAction = (leadId: number) => createLeadAction(
    leadId,
    { action: 'no_answer' },
    [ CREATE_LEAD_NO_ANSWER_ACTION, CREATE_LEAD_NO_ANSWER_ACTION_SUCCESS, CREATE_LEAD_NO_ANSWER_ACTION_FAIL ],
)

export const unmatchLeadAction = (leadId: number) => createLeadAction(leadId, { action: 'unmatch' })

export const createCallLeadAction = (leadId: number) => createLeadAction(leadId, { action: 'call' })
