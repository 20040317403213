import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import {
    GET_CERTIFICATIONS,
    GET_LEAD_CERTIFICATIONS,
    GET_PARTNER_CERTIFICATIONS,
    REMOVE_LEAD_CERTIFICATION,
    REMOVE_PARTNER_CERTIFICATION,
    UPDATE_LEAD_CERTIFICATION,
    UPDATE_PARTNER_CERTIFICATION,
} from './actions'

const certifications = combineReducers({
    all: generalSubjectReducer(GET_CERTIFICATIONS),
    lead: generalSubjectReducer(GET_LEAD_CERTIFICATIONS, UPDATE_LEAD_CERTIFICATION, REMOVE_LEAD_CERTIFICATION),
    partner:
        generalSubjectReducer(GET_PARTNER_CERTIFICATIONS, UPDATE_PARTNER_CERTIFICATION, REMOVE_PARTNER_CERTIFICATION),
})

export default certifications
