import config from '../config'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_TASKS = 'tasks/GET_TASKS'
export const GET_TASKS_SUCCESS = 'tasks/GET_TASKS_SUCCESS'
export const GET_TASKS_FAIL = 'tasks/GET_TASKS_FAIL'

export const GET_TASK = 'tasks/GET_TASK'
export const GET_TASK_SUCCESS = 'tasks/GET_TASK_SUCCESS'
export const GET_TASK_FAIL = 'tasks/GET_TASK_FAIL'

export const CREATE_TASK = 'tasks/CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'tasks/CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAIL = 'tasks/CREATE_TASK_FAIL'

export const UPDATING_TASK_STATUS = 'tasks/UPDATING_TASK_STATUS'
export const UPDATING_TASK_STATUS_SUCCESS = 'tasks/UPDATING_TASK_STATUS_SUCCESS'
export const UPDATING_TASK_STATUS_FAIL = 'tasks/UPDATING_TASK_STATUS_FAIL'

export const GET_CSM_TASKS = 'tasks/GET_CSM_TASKS'
export const GET_CSM_TASKS_SUCCESS = 'tasks/GET_CSM_TASKS_SUCCESS'
export const GET_CSM_TASKS_FAIL = 'tasks/GET_CSM_TASKS_FAIL'

export const COMPLETE_TASK = 'tasks/COMPLETE_TASK'
export const COMPLETE_TASK_SUCCESS = 'tasks/COMPLETE_TASK_SUCCESS'
export const COMPLETE_TASK_FAIL = 'tasks/COMPLETE_TASK_FAIL'

export const GET_PARTNER_TASKS = 'tasks/GET_PARTNER_TASKS'
export const GET_PARTNER_TASKS_SUCCESS = 'tasks/GET_PARTNER_TASKS_SUCCESS'
export const GET_PARTNER_TASKS_FAIL = 'tasks/GET_PARTNER_TASKS_FAIL'

export const getTask = (
    id: number
) => ({
    [CALL_API]: {
        types: [ GET_TASK, GET_TASK_SUCCESS, GET_TASK_FAIL ],
        endpoint: `/tasks/${id}`,
        schema: schemas.task,
    },
})

interface GetTasksModel {
    direction: string
    dueBefore: string
    column: string
    sort: string
    assignedEmployeeId?: number
    process?: string
}
export const getTasks = (
    criteria: GetTasksModel | { partnerId: number },
    types?: string[],
    append?: boolean
) => ({
    [CALL_API]: {
        types: types || [ GET_TASKS, GET_TASKS_SUCCESS, GET_TASKS_FAIL ],
        endpoint: '/tasks',
        schema: schemas.tasks,
        options: {
            body: {
                geoCode: config.geoCode,
                ...criteria,
                limit: 100,
                includeDone: '0',
            },
        },
    },
    append,
})

export const getCSMTasks = (
    criteria: GetTasksModel,
    append: boolean
) => getTasks({
    ...criteria,
    process: 'csm',
}, [ GET_CSM_TASKS, GET_CSM_TASKS_SUCCESS, GET_CSM_TASKS_FAIL ], append)

export const getTasksByPartnerId = (
    partnerId: number
) => getTasks(
    { partnerId },
    [ GET_PARTNER_TASKS, GET_PARTNER_TASKS_SUCCESS, GET_PARTNER_TASKS_FAIL ]

)

interface SaveTaskModel {
    id?: number
    deadlineAt: string
    title: string
    assignedEmployee: null | { id: number }
    taskables: []
}
export const saveTask = (
    body: SaveTaskModel
) => ({
    [CALL_API]: {
        types: [ CREATE_TASK, CREATE_TASK_SUCCESS, CREATE_TASK_FAIL ],
        endpoint: body.id  ? `/tasks/${body.id}` : '/tasks',
        schema: schemas.task,
        options: {
            method: body.id ? 'put' : 'post',
            body,
        },
    },
})

interface CompleteTaskModel {
    id: number
    status: { id: number }
}
export const completeTask = (
    task: CompleteTaskModel
) => ({
    [CALL_API]: {
        types: [ COMPLETE_TASK, COMPLETE_TASK_SUCCESS, COMPLETE_TASK_FAIL ],
        endpoint: `/tasks/${task.id}`,
        schema: schemas.task,
        options: {
            method: 'put',
            body: {
                id: task.id,
                status: {
                    id: task.status.id === config.DONE_TASK ? config.PENDING_TASK : config.DONE_TASK,
                },
            },
        },
    },
    taskId: task.id,
})
