import React, { PureComponent, ComponentType } from 'react'
import { set, get } from 'lodash'
import { getPartner } from '../../../actions/partners'
import LoaderComponent from '../../elements/LoaderComponent'
import { SubmissionError, submit } from 'redux-form'
import { connect } from '../../../utils/connect'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Industry } from '../../../reducers/types/entities/Industry'
import {
    createBusinessUnitRevenueRange,
    getPartnerBusinessUnit,
    updateBusinessUnitIndustries,
    updateBusinessUnitsLeadType, updatePartnerBusinessUnits,
} from '../../../actions/businessUnit'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'
import { LeadType } from '../../../reducers/types/entities/LeadType'
import { Sector } from '../../../reducers/types/entities/Sector'
import SegmentationEditMode from './SegmentationEditMode'
import { showModal } from '../../../actions/modal'
import { toastr } from 'react-redux-toastr'
import SegmentationPreviewMode from './SegmentationPreviewMode'
import { getBusinessModels } from '../../../state/businessModels/actions'
import {
    getBusinessUnitGeoRegions,
    getGeoRegions,
    updateBusinessUnitGeoRegions,
} from '../../../state/geoRegions/actions'
import { getBusinessUnitsLeadTypes, getLeadTypes } from '../../../state/leadTypes/actions'
import { getIndustries, getPartnerBusinessUnitIndustries } from '../../../state/industries/actions'
import {
    getPartnerBusinessUnitsSectors,
    getSectors,
    updatePartnerBusinessUnitsSectors,
} from '../../../state/sectors/actions'
import { allSectorsSelector, businessUnitSectorsSelector } from '../../../state/sectors/selectors'
import { allGeoRegionsSelector, businessUnitGeoRegionsSelector } from '../../../state/geoRegions/selectors'
import { allLeadTypesSelector, businessUnitLeadTypesSelector } from '../../../state/leadTypes/selectors'
import { GeoRegion, GeoRegions } from '../../../reducers/types/entities/GeoRegion'
import { allIndustriesSelector, businessUnitIndustriesSelector } from '../../../state/industries/selectors'
import {
    createBusinessUnitAllocation,
    deleteBusinessUnitAllocationQuota,
    getBusinessUnitAllocationsQuotas,
} from '../../../state/allocationsQuotas/actions'
import { AllocationsQuota } from '../../../reducers/types/entities/AllocationsQuota'
import { businessUnitAllocationsQuotasSelector } from '../../../state/allocationsQuotas/selectors'
import { SegmentationSubGeoRegion } from '../../../reducers/types/entities/SegmentationSubGeoRegion'
import { getSegmentationSubGeoRegions } from '../../../state/segmentationSubGeoRegions/actions'
import { allSegmentationSubGeoRegionsSelector } from '../../../state/segmentationSubGeoRegions/selectors'
import { AllocationLimit } from '../../../reducers/types/entities/AllocationLimit'
import {
    createBusinessUnitsAllocationLimits, getBusinessUnitsAllocationLimits,
    updateBusinessUnitsAllocationLimits,
} from '../../../state/allocationsLimits/actions'
import { businessUnitAllocationLimitsSelector } from '../../../state/allocationsLimits/selectors'
import { partnerUsersByPartnerSelector } from '../../../state/partnerUsers/selectors'
import config from '../../../config'
import { getLocations } from '../../../actions/locations'
import { change } from 'redux-form'
import { PartnerUser } from '../../../reducers/types/entities/PartnerUser'
import { getPartnerUsers } from '../../../state/partnerUsers/actions'
import { locationSelector } from '../../../selectors/locations'
import { allBusinessModelsSelector } from '../../../state/businessModels/selectors'
import { Location } from '../../../reducers/types/entities/Location'
import moment from 'moment'

export interface Props {
    allocationLimit: AllocationLimit
    allocationsQuotas: AllocationsQuota[]
    businessModels: typeof allBusinessModelsSelector
    businessUnit: BusinessUnit
    businessUnitGeoRegions: GeoRegion[]
    businessUnitGeoRegionsId: number[]
    businessUnitIndustries: Industry[]
    businessUnitIndustriesId: number[]
    businessUnitLeadTypes: LeadType[]
    businessUnitLeadTypesId: number[]
    businessUnitSectors: Sector[]
    businessUnitSectorsId: number[]
    employeeId: number
    entitiesGeoRegions: GeoRegions
    geoRegions: GeoRegion[]
    geoRegionsUpdatePermission
    industries: Industry[]
    isLoadingBusinessModels: boolean
    isLoadingLocations: boolean
    leadAllocationQuotaDeletePermission
    locations: Location[]
    leadTypes: LeadType[]
    partnerUsers: PartnerUser[]
    sectors: Sector[]
    segmentationForm: any
    segmentationGeoRegionsListPermission
    subGeoRegions: SegmentationSubGeoRegion[]
}
export interface Actions {
    change: typeof change
    createBusinessUnitAllocation: typeof createBusinessUnitAllocation
    createBusinessUnitRevenueRange: typeof createBusinessUnitRevenueRange
    createBusinessUnitsAllocationLimits: typeof createBusinessUnitsAllocationLimits
    deleteBusinessUnitAllocationQuota: typeof deleteBusinessUnitAllocationQuota
    getBusinessModels: typeof getBusinessModels
    getBusinessUnitAllocationsQuotas: typeof getBusinessUnitAllocationsQuotas
    getBusinessUnitGeoRegions: typeof getBusinessUnitGeoRegions
    getBusinessUnitsAllocationLimits: typeof getBusinessUnitsAllocationLimits
    getBusinessUnitsLeadTypes: typeof getBusinessUnitsLeadTypes
    getGeoRegions: typeof getGeoRegions
    getIndustries: typeof getIndustries
    getLocations: (any) => any
    getLeadTypes: typeof getLeadTypes
    getPartner: typeof getPartner
    getPartnerBusinessUnit: typeof getPartnerBusinessUnit
    getPartnerBusinessUnitIndustries: typeof getPartnerBusinessUnitIndustries
    getPartnerBusinessUnitsSectors: typeof getPartnerBusinessUnitsSectors
    getPartnerUsers: typeof getPartnerUsers
    getSectors: typeof getSectors
    getSegmentationSubGeoRegions: typeof getSegmentationSubGeoRegions
    showModal: typeof showModal
    submit: typeof submit
    updateBusinessUnitGeoRegions: typeof updateBusinessUnitGeoRegions
    updateBusinessUnitIndustries: typeof updateBusinessUnitIndustries
    updateBusinessUnitsAllocationLimits: typeof updateBusinessUnitsAllocationLimits
    updateBusinessUnitsLeadType: typeof updateBusinessUnitsLeadType
    updatePartnerBusinessUnits: typeof updatePartnerBusinessUnits
    updatePartnerBusinessUnitsSectors: typeof updatePartnerBusinessUnitsSectors
}

export interface PathProps {
    partnerId: number
    businessUnitId: number
    partner: Partner
}

export interface State {
    editMode: boolean
    isLoading: boolean
}
export type Model = Props & Actions & PathProps

@connect<
    Props,
    Actions,
    PathProps
>(
    (state, { partnerId, businessUnitId }) => ({
        allocationLimit: businessUnitAllocationLimitsSelector(state)[0],
        allocationsQuotas: businessUnitAllocationsQuotasSelector(state),
        businessModels: allBusinessModelsSelector(state),
        businessUnit: state.entities.businessUnits[businessUnitId],
        businessUnitGeoRegions: businessUnitGeoRegionsSelector(state),
        businessUnitGeoRegionsId: state.subjects.geoRegions.businessUnit.ids,
        businessUnitIndustries: businessUnitIndustriesSelector(state),
        businessUnitIndustriesId: state.subjects.industries.businessUnit.ids,
        businessUnitLeadTypes: businessUnitLeadTypesSelector(state),
        businessUnitLeadTypesId: state.subjects.leadTypes.businessUnit.ids,
        businessUnitSectors: businessUnitSectorsSelector(state),
        businessUnitSectorsId: state.subjects.sectors.businessUnit.ids,
        employeeId: state.auth.employeeId,
        entitiesGeoRegions: state.entities.geoRegions,
        geoRegions: allGeoRegionsSelector(state),
        geoRegionsUpdatePermission: state.entities.permissions.partner_business_units_segmentation_geo_regions_update,
        industries: allIndustriesSelector(state),
        isLoadingBusinessModels: state.subjects.businessModels.all.isLoading,
        isLoadingLocations: state.pages.locationFilter.isLoading,
        leadAllocationQuotaDeletePermission: state.entities.permissions.lead_allocation_quota_delete,
        leadTypes: allLeadTypesSelector(state),
        locations: locationSelector(state),
        partnerUsers: partnerUsersByPartnerSelector(state, partnerId),
        sectors: allSectorsSelector(state),
        segmentationForm: get(state, 'form.segmentationForm.values'),
        segmentationGeoRegionsListPermission: state.entities.permissions.segmentation_geo_regions_list,
        subGeoRegions: allSegmentationSubGeoRegionsSelector(state),
    }),
    {
        change,
        createBusinessUnitAllocation,
        createBusinessUnitRevenueRange,
        createBusinessUnitsAllocationLimits,
        deleteBusinessUnitAllocationQuota,
        getBusinessModels,
        getBusinessUnitAllocationsQuotas,
        getBusinessUnitGeoRegions,
        getBusinessUnitsAllocationLimits,
        getBusinessUnitsLeadTypes,
        getGeoRegions,
        getIndustries,
        getLeadTypes,
        getLocations,
        getPartner,
        getPartnerBusinessUnit,
        getPartnerBusinessUnitIndustries,
        getPartnerBusinessUnitsSectors,
        getPartnerUsers,
        getSectors,
        getSegmentationSubGeoRegions,
        showModal,
        submit,
        updateBusinessUnitGeoRegions,
        updateBusinessUnitIndustries,
        updateBusinessUnitsAllocationLimits,
        updateBusinessUnitsLeadType,
        updatePartnerBusinessUnits,
        updatePartnerBusinessUnitsSectors,
    }
)

class BusinessUnitSegmentation extends PureComponent<
    Model,
    State
> {
    state = {
        editMode: false,
        isLoading: false,
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.businessUnitId !== this.props.businessUnitId) this.getData()
    }

    hasPPSModel(unit) {
        return get(unit, 'businessModels', []).find(el => el.abbreviation === 'pps')
    }

    hasLBModel(unit) {
        return get(unit, 'businessModels', []).find(el => el.abbreviation === 'lb')
    }

    async getData() {
        const {
            businessUnit,
            businessUnitId,
            partnerId,
        } = this.props
        const partner = this.props.partner
        const geoCode = partner.geo.code
        this.setState({ isLoading: true })
        Promise.all([
            this.props.getLocations({ zipCode: businessUnit.geo.zipCode }),
            this.props.getBusinessModels({ geoCode: businessUnit.geo.code }),
            this.props.getPartnerUsers({ partnerId }),
            this.props.getBusinessUnitsAllocationLimits(
                partnerId,
                {
                    partnerBusinessUnitId: businessUnitId,
                }
            ),
            this.props.getBusinessUnitAllocationsQuotas(partnerId, {
                partnerBusinessUnitId: businessUnitId,
            }),
            this.props.getIndustries({ parentIndustryId: partner.industry.id }),
            this.props.getPartnerBusinessUnitIndustries(partnerId, businessUnitId),
            this.props.getSectors({ geoCode }),
            this.props.getPartnerBusinessUnitsSectors(partnerId, businessUnitId),
            this.props.getLeadTypes(geoCode),
            this.props.getBusinessUnitsLeadTypes(partnerId, businessUnitId),
        ]).then(res => {
            this.setState({ isLoading: false })
        })

        if (this.props.segmentationGeoRegionsListPermission) {
            this.props.getGeoRegions({ geoCode })
            await this.props.getBusinessUnitGeoRegions(partnerId, businessUnitId)
            this.props.getSegmentationSubGeoRegions({
                segmentationGeoRegionId: this.props.businessUnitGeoRegionsId,
            })
        }
    }

    getLocations = zipCode => {
        this.props.change('leadValidation', 'geo.zipCode', zipCode)
        this.props.getLocations({ zipCode })
    }

    handleFormSubmit = async data => {
        this.businessUnitFormRequirements(data.unit)
        if (this.hasLBModel(data.unit)) {
            this.targetAllocationRequirements(data.targetAllocation)
        }
        if (this.hasPPSModel(data.unit)) {
            this.allocationLimitRequirements(data.allocationLimit)
        }
        this.setState({ isLoading: true })
        if (this.hasLBModel(data.unit) &&
            data.targetAllocation &&
            data.targetAllocation.allocationQuotaPerRefill &&
            data.targetAllocation.effectiveAt
        ) this.createTargetAllocation(data.targetAllocation)

        if (
            data.revenueRange &&
            typeof data.revenueRange.minimumRevenue !== 'undefined' &&
            typeof data.revenueRange.maximumRevenue !== 'undefined'
        ) this.updateRevenueRange(data.revenueRange)

        if (data.geoRange) this.updateLeadDistance(data.geoRange)

        if (data.allocationLimit && this.hasPPSModel(data.unit)) {
            if (this.props.allocationLimit) this.updateAllocationLimit(data.allocationLimit)
            else this.createAllocationLimit({
                ...data.allocationLimit,
                price: {
                    amount: data.allocationLimit.price.amount,
                    currency: config.currency[this.props.partner.geo.code],
                },
            })
        }

        Promise.all([
            this.updateBusinessUnit(data.unit),
            this.updateIndustries(data.industries),
            this.updateSectors(data.sectors),
            this.updateLeadTypes(data.leadTypes),
            this.updateGeoRegions(data.geoRegions),
        ]).then(res => {
            this.setState({
                editMode: false,
                isLoading: false,
            })
        })
    }

    targetAllocationRequirements = targetAllocation => {
        const error = {}

        const effectiveAt = get(targetAllocation, 'effectiveAt')

        if (!effectiveAt || moment().startOf('month').isAfter(effectiveAt)) {
            set(error, 'targetAllocation.effectiveAt', 'Date needs to be in the future')
        }

        if (Object.keys(error).length) {
            throw new SubmissionError(error)
        }
    }

    allocationLimitRequirements = allocationLimit => {
        const error = {}

        const effectiveAt = get(allocationLimit, 'effectiveAt')

        if (!effectiveAt || moment().startOf('day').isAfter(effectiveAt)) {
            set(error, 'allocationLimit.effectiveAt', 'Date needs to be in the future')
        }

        if (Object.keys(error).length) {
            throw new SubmissionError(error)
        }
    }

    businessUnitFormRequirements = unit => {
        const error = {}

        if (!get(unit, 'partnerUserId')) {
            set(error, 'partnerUserId', 'Partner user is required!')
        }
        if (!get(unit, 'geo.zipCode')) {
            set(error, 'geo.zipCode', 'Zip code is required!')
        }
        if (get(unit, 'geo.zipCode') && !get(unit, 'geo.cityName')) {
            set(error, 'geo.zipCode', 'Zip code doesn\'t exist!')
        }

        if (Object.keys(error).length) {
            throw new SubmissionError(error)
        }
    }

    updateBusinessUnit = unit => {
        this.businessUnitFormRequirements(unit)
        const unitBody = {
            ...unit,
            businessModels: unit.businessModels,
        }

        this.props.updatePartnerBusinessUnits(this.props.partnerId, unitBody)
    }

    updateAllocationLimit = data => {
        const {
            partner,
            businessUnit: { id },
        } = this.props
        this.props.updateBusinessUnitsAllocationLimits(partner.id, id, data)
    }

    createAllocationLimit = data => {
        const {
            partner,
            businessUnit: { id },
        } = this.props
        this.props.createBusinessUnitsAllocationLimits(partner.id, id, data)
    }

    updateLeadDistance = geoRange => {
        const {
            partner,
            businessUnit: { id },
        } = this.props
        this.props.updatePartnerBusinessUnits(partner.id, { id, geoRange })
    }

    updateRevenueRange = async data => {
        const {
            partner: { id, preferences },
            businessUnit,
        } = this.props

        await this.props.createBusinessUnitRevenueRange(id, businessUnit.id, {
            ...data,
            revenueCurrency: preferences.currency.code,
        })
        this.props.getPartnerBusinessUnit(id, businessUnit.id)
    }

    updateIndustries = async selectedIndustry => {
        const {
            partner: { id },
            businessUnit,
        } = this.props

        await this.props.updateBusinessUnitIndustries(id, businessUnit.id, selectedIndustry)
        this.props.getPartnerBusinessUnitIndustries(id, businessUnit.id)
    }

    updateSectors = async sectorIds => {
        const {
            partner: { id },
            businessUnit,
        } = this.props

        await this.props.updatePartnerBusinessUnitsSectors(id, businessUnit.id, sectorIds)
        this.props.getPartnerBusinessUnitsSectors(id, businessUnit.id)
    }

    updateLeadTypes = async leadTypeIds => {
        const {
            partner: { id },
            businessUnit,
        } = this.props

        await this.props.updateBusinessUnitsLeadType(id, businessUnit.id, leadTypeIds )
        this.props.getBusinessUnitsLeadTypes(id, businessUnit.id)
    }

    updateGeoRegions = async geoRegionsId => {
        const {
            partner: { id },
            businessUnit,
            geoRegionsUpdatePermission,
        } = this.props

        if (!geoRegionsUpdatePermission) {
            toastr.error('Error!', 'Permission Required: "partner_business_units_segmentation_geo_regions_update')
            return
        }

        await this.props.updateBusinessUnitGeoRegions(id, businessUnit.id, geoRegionsId )
        this.props.getBusinessUnitGeoRegions(id, businessUnit.id)
        this.props.getSegmentationSubGeoRegions({
            segmentationGeoRegionId: geoRegionsId,
        })
    }

    createTargetAllocation = async data => {
        const businessUnitId = this.props.businessUnitId
        const partnerId = this.props.partner.id

        const body = {
            ...data,
            createdByEmployeeId: this.props.employeeId,
            partnerBusinessUnitId: businessUnitId,
        }

        await this.props.createBusinessUnitAllocation(partnerId, businessUnitId, body)
        this.props.getBusinessUnitAllocationsQuotas(partnerId, {
            partnerBusinessUnitId: businessUnitId,
        })
    }

    deleteAllocationQuota = (quotaId) => {
        const {
            partner: { id },
            businessUnit,
            leadAllocationQuotaDeletePermission,
        } = this.props
        if (!leadAllocationQuotaDeletePermission) {
            toastr.error('Error!', 'Permission Required: "lead_allocation_quota_delete')
            return
        }
        this.props.showModal({
            name: 'deleteTargetBusinessUnitConfirm',
            partnerId: id,
            businessUnitId: businessUnit.id,
            quotaId,
        })
    }
    onEditSegmentation = () => { this.setState({ editMode: true }) }

    onGoBack = () => { this.setState({ editMode: false }) }

    onSave = () => { this.props.submit('segmentationForm') }

    render() {
        if (!this.props.partner) return <LoaderComponent type="logo" />

        const {
            allocationLimit,
            allocationsQuotas,
            businessUnit,
            businessUnitGeoRegions,
            businessUnitIndustries,
            businessUnitLeadTypes,
            businessUnitSectors,
            entitiesGeoRegions,
            subGeoRegions,
        } = this.props

        return this.state.editMode ?
            <SegmentationEditMode
                {...this.props}
                getLocations={this.getLocations}
                handleFormSubmit={this.handleFormSubmit}
                deleteAllocationQuota={this.deleteAllocationQuota}
                onGoBack={this.onGoBack}
                onSave={this.onSave}
                isLoading={this.state.isLoading}
                hasLBModel={this.hasLBModel}
                hasPPSModel={this.hasPPSModel}
            />
            : <SegmentationPreviewMode
                allocationLimit={allocationLimit}
                allocationsQuotas={allocationsQuotas}
                businessUnit={businessUnit}
                businessUnitGeoRegions={businessUnitGeoRegions}
                businessUnitIndustries={businessUnitIndustries}
                businessUnitLeadTypes={businessUnitLeadTypes}
                businessUnitSectors={businessUnitSectors}
                entitiesGeoRegions={entitiesGeoRegions}
                isLoading={this.state.isLoading}
                isLBModel={this.hasLBModel(businessUnit)}
                isPPSModel={this.hasPPSModel(businessUnit)}
                onEditSegmentation={this.onEditSegmentation}
                subGeoRegions={subGeoRegions}
            />
    }
}

export default BusinessUnitSegmentation as unknown as ComponentType<PathProps>
