import {
    GET_QUOTES,
    GET_QUOTES_SUCCESS,
    GET_QUOTES_FAIL,
    DELETE_QUOTE_SUCCESS,
} from '../../actions/quotes'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface QuotesModel extends InitialState {
    quotesIds: number[]
}
const initialState: QuotesModel = {
    isLoading: null,
    quotesIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

interface QuotesActionModel extends InitialAction {
    quoteId: number
}

export default function reducer(
    state = initialState,
    action: QuotesActionModel
) {
    switch (action.type) {
        case GET_QUOTES:
            return {
                ...state,
                isLoading: true,
            }
        case GET_QUOTES_SUCCESS:
            return {
                ...state,
                quotesIds: action.append ?
                    state.quotesIds.concat(action.response.result) :
                    action.response.result,
                isLoading: false,
                pagination: action.response.pagination,
            }
        case GET_QUOTES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case DELETE_QUOTE_SUCCESS:
            return {
                ...state,
                quotesIds: state.quotesIds.filter(id => id !== action.quoteId),
                isLoading: false,
                pagination: action.response.pagination,
            }
        default:
            return state
    }
}
