import React, { ComponentType, PureComponent } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { createCallPartnerAction, getPartner } from '../../actions/partners'
import { Partner } from '../../reducers/types/entities/Partner'
import { Card } from '../blocks/Card'
import CallButton from '../elements/CallButton'
import { SeparatorLine } from '../elements/Separators'
import NewsFeed from '../activities/NewsFeed'
import AddNoteForm from '../activities/AddNoteForm'
import Placeholder from '../elements/Placeholder'
import { getSegmentedLeads } from '../../actions/leads'
import { segmentedLeadsSelector } from '../../selectors/lead'
import { Lead } from '../../reducers/types/entities/Lead'
import BusinessUnitSegmentationTable from '../business-unit/BusinessUnitSegmentationTable'
import styled from 'styled-components'
import { borderColor } from '../../utils/variables'
import { PartnerUsers } from '../../reducers/types/entities/PartnerUser'
import { getPartnerUsers } from '../../state/partnerUsers/actions'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 80vw;
    }
`

const TableContent = styled(Card.Content)`
  border-left: 1px solid ${borderColor};
`

interface ActionModalStateProps {
    isLoadingSegmentedLeads:boolean
    businessUnit: BusinessUnit
    partnerUsers: PartnerUsers
    partner: Partner
    leads: Lead[]
}
interface ActionModalDispatchProps {
    createCallPartnerAction: typeof createCallPartnerAction
    getSegmentedLeads: typeof getSegmentedLeads
    getPartnerUsers: typeof getPartnerUsers
    getPartner: typeof getPartner
    hideModal: typeof hideModal
}

type BusinessUnitActionModel = ActionModalStateProps & ActionModalDispatchProps

@connect<ActionModalStateProps, ActionModalDispatchProps, any>(
    state => ({
        businessUnit: state.modal.businessUnit,
        partner: state.entities.partners[state.modal.businessUnit.partnerId],
        leads: segmentedLeadsSelector(state),
        partnerUsers: state.entities.partnerUsers,
        isLoadingSegmentedLeads: state.pages.partnerDetail.segmentedLeads.isLoading,
    }),
    {
        createCallPartnerAction,
        getSegmentedLeads,
        getPartnerUsers,
        getPartner,
        hideModal,
    }
)
class BusinessUnitActionModal extends PureComponent<BusinessUnitActionModel> {
    componentDidMount() {
        const partnerId = this.props.businessUnit.partnerId
        const partnerUserId = this.props.businessUnit.partnerUserId
        this.props.getPartner(partnerId)
        this.props.getPartnerUsers({ partnerUserId })
        this.props.getSegmentedLeads(partnerId)
    }

    hideModal = () => this.props.hideModal()

    callPartnerButton = () => {
        return this.props.createCallPartnerAction(this.props.partner.id, this.props.partner.phone)
    }

    render() {
        const {
            isLoadingSegmentedLeads,
            businessUnit,
            partnerUsers,
            partner,
            leads,
        } = this.props

        const partnerUser = partnerUsers[businessUnit.partnerUserId]

        return <LgModal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton>{businessUnit.displayName}</Modal.Header>
            <Card.Content>
                {partner ?
                    <div>
                        <Row>
                            <Col md={3}>
                                <Card.Label>Partner Name</Card.Label>
                                <Card.Text>{partner.companyName}</Card.Text>
                            </Col>
                            <Col md={3}>
                                <Card.Label>Partner user name</Card.Label>
                                <Card.Text>
                                    {partnerUser && (partnerUser.firstName + ' ' + partnerUser.lastName)}
                                </Card.Text>
                            </Col>
                            <Col md={3}>
                                <Card.Label>Email</Card.Label>
                                <Card.Text>{partnerUser && partnerUser.email}</Card.Text>
                            </Col>
                            <Col md={3}>
                                <CallButton
                                    label="Call partner"
                                    onCall={this.callPartnerButton}
                                    phoneNumber={partner.phone}
                                    geoCode={partner.geo.code}
                                />
                            </Col>
                        </Row>
                    </div>
                    : <Placeholder />
                }
            </Card.Content>
            <SeparatorLine modifiers="m_0"/>
            <Row>
                <Col md={5}>
                    <Card.Content modifiers="pR_0">
                        <AddNoteForm
                            entityId={businessUnit.partnerId}
                            stateName="partnerDetail"
                            selectedTab="feed"
                        />
                    </Card.Content>
                    <SeparatorLine modifiers="m_0" />
                    <NewsFeed
                        selectedTab="feed"
                        scrollHeight="50vh"
                        subject={{ partnerId: businessUnit.partnerId }}
                    />
                </Col>
                <Col md={7}>
                    <TableContent
                        isLoading={isLoadingSegmentedLeads}
                        scrollHeight="calc(50vh + 82px)"
                        modifiers={[ 'maxHeight', 'scroll', 'p_0' ]}
                    >
                        <BusinessUnitSegmentationTable leads={leads}/>
                    </TableContent>
                </Col>
            </Row>
        </LgModal>
    }
}

export default BusinessUnitActionModal as unknown as ComponentType
