import React, { ComponentType, PureComponent } from 'react'
import { get } from 'lodash'
import { Clipboard } from 'react-feather'
import Checkbox from '../../blocks/Checkbox'
import { toastr } from 'react-redux-toastr'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'
import { AmountCell, Cell, LinkCell } from '../../elements/table'
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ProviderTable from '../../blocks/ProviderTable'
import BusinessModelLabel from '../../elements/BusinessModelLabel'
import styled from 'styled-components'
import { Button } from '../../elements/Button'
import { hideModal, showModal } from '../../../actions/modal'
import { Flex } from '../../elements/Flex'
import moment from 'moment'
import { ctaColor } from '../../../utils/variables'
import { FormattedBusinessUnitWithDistance } from './BusinessUnitsTable'

interface PartnerRowPathProps {
    hasPermissionToOverdeliver: boolean
    isDisableCheckBox?: boolean
    selectedBusinessUnits
    selectedPartners
    hideModal: typeof hideModal
    showModal: typeof showModal
    selectBusinessUnit: (partner: {id: number, overdelivery: boolean, partnerId: number }) => Promise<void>
    deselectBusinessUnit: (partner: {id: number, partnerId: number }) => Promise<void>
    currentMax: number
}

interface PartnerRowStateProps {
    showOverdeliveryModal: boolean
}

const Row = styled(ProviderTable.Row)<{fadeOut: boolean, isRecommended: boolean}>`
    ${props =>  props.fadeOut ? 'opacity: 0.4' : ''};
    ${props =>  props.isRecommended ? `background-color: rgba(37, 202, 116, 0.15); border-color: ${ctaColor}` : ''};
`

const TooltipList = styled.ul`
    padding: 12px 12px 6px 18px;

    li:not(:last-child) {
        margin-bottom: 8px;
    }
`

interface PartnerRowModel extends PartnerRowPathProps {
    onRowClick: () => void
    rowData: FormattedBusinessUnitWithDistance
    active: boolean
    rowId: number
    hideModal: typeof hideModal
    showModal: typeof showModal
}
class SendEmailRow extends PureComponent<PartnerRowModel, PartnerRowStateProps> {
    state = {
        showOverdeliveryModal: false,
    }

    onCheckboxClickChange = e => {
        if (e.target.checked) {
            this.selectBusinessUnit(false)
            return
        }
        this.deselectBusinessUnit()
    }

    selectBusinessUnit(overdelivery: boolean) {
        if (new Set(this.props.selectedPartners).has(this.props.rowData.partnerId)) {
            toastr.warning('Cannot allocate to two business units for the same partner')
            return
        }
        if (this.props.isDisableCheckBox) {
            toastr.warning('Max amount of partners chosen')
            return
        }

        if (this.props.rowData.isOnPause) {
            return
        }

        this.props.selectBusinessUnit({
            overdelivery,
            id: this.props.rowData.id,
            partnerId: this.props.rowData.partnerId,
        })
    }

    deselectBusinessUnit() {
        this.props.deselectBusinessUnit({
            id: this.props.rowData.id,
            partnerId: this.props.rowData.partnerId,
        })
    }

    onOverdeliver = () => {
        this.props.showModal({
            name: 'overdeliveryConfirm',
            headerText: 'Overdelivery',
            bodyText: <h4>The lead will count as an overdelivery to this partner.</h4>,
            btnApproveText: 'Ok',
            btnDenyText: 'Cancel',
            btnApproveModifiers: [ 'warning' ],
            onConfirm: () => {
                this.selectBusinessUnit(true)
                this.props.hideModal()
            },
        })
    }

    hasPpsModel(bu: BusinessUnit): boolean {
        return Boolean(bu.businessModels.length)
            ? Boolean(bu.businessModels.find(el => el.abbreviation === 'pps'))
            : Boolean(this.returnBusinessModels(bu).find(el => el.abbreviation === 'pps'))
    }

    returnStatusLabel = (bu: BusinessUnit) => {
        if (bu.partnerContract) {
            if (bu.partnerContract.currentPartnerContractPeriod) {
                return <Label bsStyle="success">Active contract</Label>
            }
            return <Label bsStyle="warning">Unused allocations</Label>
        }
        return <Label bsStyle="info">Old subscription</Label>
    }

    calculateData(activePeriod: string, bu: BusinessUnit) {
        const allocationType = activePeriod === 'currentMonth'
            ? 'currentAllocation'
            : 'accumulatedAllocation'

        let lbLimit = bu.currentAllocation.normalAllocationQuota
        if (activePeriod === 'sinceStart') lbLimit = bu.accumulatedAllocation.allocationQuota
        const lbCount = bu[allocationType].normalQuotaAllocationCount
        const overdeliveryCount = bu[allocationType].justInTimeAllocationCount + bu[allocationType].overdeliveryAllocationCount

        const ppsCount = bu[allocationType].payPerSpotAllocationCount
        const ppsLimit = bu[allocationType].payPerSpotAllocationLimit

        return { lbLimit, lbCount, overdeliveryCount, ppsCount, ppsLimit }
    }

    returnDeliveryHunger = (bu: BusinessUnit) => {
        const { currentMonthAllocation } = bu

        return Math.max(currentMonthAllocation.suggestedAllocationQuota - currentMonthAllocation.currentMonthAllocationCount, 0)
    }

    returnBusinessModels = (bu: BusinessUnit) => {
        const businessModels: Array<{ id: number, abbreviation: string }> = []

        if (Boolean(bu.accumulatedAllocation.allocationQuota) || Boolean(bu.accumulatedAllocation.allocationCount)) {
            businessModels.push({
                id: 1,
                abbreviation: 'lb',
            })
        }
        if (Boolean(bu.accumulatedAllocation.payPerSpotAllocationLimit) || Boolean(bu.accumulatedAllocation.payPerSpotAllocationCount)) {
            businessModels.push({
                id: 2,
                abbreviation: 'pps',
            })
        }
        if (bu.wantsExclusiveLeads) {
            businessModels.push({
                id: 3,
                abbreviation: 'excl',
            })
        }

        return businessModels
    }

    render() {
        const {
            selectedBusinessUnits,
            rowData,
            hasPermissionToOverdeliver,
            currentMax,
        } = this.props

        const bu = rowData

        const hasPpsModel = this.hasPpsModel(bu)

        const currentMonth = this.calculateData('currentMonth', bu)

        const sinceStart = this.calculateData('sinceStart', bu)

        const isDisabledRow = (bu.requiresOverdelivery && !hasPermissionToOverdeliver) || bu.isOnPause || !bu.isPartOfSelectedRegions

        const isChecked = Object.keys(selectedBusinessUnits).includes(String(bu.id))

        const deliveryTrend = get(bu, 'kpi.currentPeriodDeliveryTrend')

        const businessModels = Boolean(bu.businessModels.length) ? bu.businessModels : this.returnBusinessModels(bu)

        const lastAllocatedAt = get(bu.kpi, 'latestAllocatedAt')

        const isAutoSelectable = bu.index < currentMax && !bu.requiresOverdelivery && !bu.isOnPause && Boolean(bu.isPartOfSelectedRegions)

        return <Row {...this.props} fadeOut={isDisabledRow} isRecommended={isAutoSelectable}>
            <Cell className="text-center">
                {(bu.requiresOverdelivery && !isChecked) ||
                <Checkbox
                    checked={isChecked}
                    onChange={this.onCheckboxClickChange}
                />
                }
                {!bu.requiresOverdelivery || !hasPermissionToOverdeliver || isChecked ||
                <Button
                    onClick={this.onOverdeliver}
                    modifiers={[ 'warning', 'btnSm' ]}
                >
                    Overdeliver
                </Button>
                }
            </Cell>
            <LinkCell to={`/partners/${bu.partnerId}`}>{bu.partnerId}</LinkCell>
            <Cell>
                {bu.partner.companyName}
&nbsp;
                {bu.partner.features && bu.partner.features.includes('billy_partner') && <Label bsStyle="info pull-right">BILLY PARTNER</Label>}
            </Cell>
            <Cell>{lastAllocatedAt ? moment(lastAllocatedAt).fromNow() : '-'}</Cell>
            <Cell>{bu.displayName}</Cell>
            <Cell>
                {bu.geo.zipCode}
                {' '}
                {bu.geo.cityName}
            </Cell>
            <Cell>
                {businessModels.map(el =>
                    <BusinessModelLabel
                        key={'businessModels_' + el.id}
                        abbreviation={el.abbreviation}
                        fadeOut={isDisabledRow}
                    >
                        {el.abbreviation}
                    </BusinessModelLabel>
                )}
            </Cell>
            <Cell>
                {this.returnStatusLabel(bu)}
                {bu.isBoosted && <>
                    {' '}
                    <Label bsStyle="info">Boosted</Label>
                </>}
                {!bu.partner.hasActiveAccess && <>
                    {' '}
                    <Label bsStyle="danger">Access revoked</Label>
                </>}
            </Cell>
            <Cell>
                {bu.highlightedNotes && Boolean(bu.highlightedNotes.length) && <OverlayTrigger
                    delay={0}
                    placement="left"
                    overlay={
                        <Tooltip placement="left" className="in">
                            <TooltipList>{bu.highlightedNotes.map((note, i) => <li key={i}>{note.replace(' Pinned Note', '')}</li>)}</TooltipList>
                        </Tooltip>}
                    animation={false}
                    delayHide={0}
                >
                    <Flex modifiers={[ 'justifyCenter', 'alignCenter' ]}>
                        <Clipboard size={18} />
                    </Flex>
                </OverlayTrigger>}
            </Cell>
            <Cell>
                {(bu.isOnPause || bu.partner.isSubscriptionPaused)
                    ? <Label bsStyle="warning">PAUSED</Label>
                    : (currentMonth.lbCount + ' / ' + currentMonth.lbLimit)}
            </Cell>
            <Cell>
                {sinceStart.lbCount}
                {' '}
                /
                {' '}
                {sinceStart.lbLimit}
            </Cell>
            <Cell>{currentMonth.overdeliveryCount}</Cell>
            {hasPpsModel ? <Cell>
                {currentMonth.ppsCount}
                {' '}
                /
                {' '}
                {currentMonth.ppsLimit}
            </Cell> : <Cell/>}
            {hasPpsModel ? <AmountCell amount={bu.currentAllocation.payPerSpotAllocationPrice}/> : <Cell/>}
            <Cell>{(deliveryTrend || deliveryTrend === 0) ? (deliveryTrend * 100).toFixed(2) + '%' : ''}</Cell>
            <Cell>{this.returnDeliveryHunger(bu)}</Cell>
            <Cell>
                {bu.distance.toFixed(2)}
                {' '}
                {bu.geo.code === 'us' ? 'M' : 'Km'}
            </Cell>
        </Row>
    }
}

export default SendEmailRow as unknown as ComponentType<PartnerRowPathProps>
