import { withFormik } from '../../../utils/withFormik'
import React, { ComponentType, Fragment, PureComponent } from 'react'
import { Field, Form, FormikProps } from 'formik'
import { DateTimePickerGroup } from '../../elements/forms/inputs/DateTimePickerGroup'
import * as yup from 'yup'
import moment from 'moment'
import { Flex } from '../../elements/Flex'
import { Button } from '../../elements/Button'

const validationFutureSchema = yup.object().shape({
    startsAt: yup.date()
        .required()
        .default(moment().startOf('day').toDate())
        .min(moment().startOf('day').toDate()),
    endsAt: yup.date()
        .required()
        .when('startsAt', (startsAt, schema) => (startsAt && schema.min(startsAt))),
})

const validationActiveSchema = yup.object().shape({
    endsAt: yup.date()
        .required()
        .min(moment().add(1, 'day').startOf('day').toDate()),
})

interface BoostFormModel {
    submit: (startsAt, endsAt) => void
    startsAt?: string
    endsAt?: string
}

interface FormValues {
    startsAt: string
    endsAt: string
}

@withFormik<BoostFormModel, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({
        startsAt,
        endsAt,
    }) => ({
        startsAt: startsAt || moment().startOf('day').format('YYYY-MM-DD'),
        endsAt: endsAt || '',
    }),
    handleSubmit: async (values, bag) => {
        await bag.props.submit(values.startsAt, moment(values.endsAt).endOf('day').toISOString())
    },
    validationSchema: props => {
        if (props.startsAt && moment(props.startsAt).isBefore(moment())) {
            return validationActiveSchema
        }
        return validationFutureSchema
    },
})
class BoostPeriodForm extends PureComponent<BoostFormModel & FormikProps<FormValues>> {
    render() {
        return <Fragment>
            <div className="alert alert-info text-center">The contract allocation boost includes the ending date</div>
            <Form>
                <Flex modifiers={[ 'justifySpaceBetween', 'alignItemsEnd' ]}>
                    <Field
                        label="Boost start date"
                        name="startsAt"
                        component={DateTimePickerGroup}
                        defaultValue={moment().startOf('day').format('YYYY-MM-DD')}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        removeClassFormGroup={true}
                        inputProps={{ disabled: !(!this.props.startsAt || moment(this.props.startsAt).isAfter(moment())) || false }}
                    />
                    <Field
                        label="Boost end date"
                        name="endsAt"
                        component={DateTimePickerGroup}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        removeClassFormGroup={true}
                    />
                    <Button type="submit" modifiers={[ 'action' ]}>{this.props.startsAt ? 'Update' : 'Create'}</Button>
                </Flex>
            </Form>
        </Fragment>
    }
}

export default BoostPeriodForm as unknown as ComponentType<BoostFormModel>
