import React, { ComponentType, PureComponent, Fragment } from 'react'
import { createCallPartnerAction } from '../../../actions/partners'
import { Card } from '../../blocks/Card'
import CallButton from '../../elements/CallButton'
import { connect } from '../../../utils/connect'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Flex } from '../../elements/Flex'
import ConsultantList from './ConsultantList'
import styled from 'styled-components'
import { Certification } from '../../../reducers/types/entities/Certification'
import { partnerCertificationsSelector } from '../../../state/certifications/selectors'
import { getPartnerCertifications } from '../../../state/certifications/actions'
import { firstLetterUpperCase } from '../../../utils'

const CertificationList = styled.ul`
    padding-left: 15px;
    max-height: 225px;
    overflow-y: auto;
    li {
        font-size: 15px;
    }
`

const GridRow = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;
`

interface StateProps {
    certifications: Certification[]
}

interface DispatchProps {
    createCallPartnerAction: typeof createCallPartnerAction
    getPartnerCertifications: typeof getPartnerCertifications
}

interface PathProps {
    partner: Partner
}

type PartnerMetaDataModel = StateProps & DispatchProps & PathProps

@connect<StateProps, DispatchProps, PathProps>(
    state => ({
        certifications: partnerCertificationsSelector(state),
    }),
    {
        createCallPartnerAction,
        getPartnerCertifications,
    }
)
class PartnerMetaData extends PureComponent<PartnerMetaDataModel> {
    componentDidMount() {
        this.props.getPartnerCertifications(this.props.partner.id)
    }

    fixUrl = url => {
        if (url.substring(0, 4) !== 'http') url = 'http://' + url
        return url
    }

    callPartnerButton = () => {
        return this.props.createCallPartnerAction(this.props.partner.id, this.props.partner.phone)
    }

    render() {
        const { partner, certifications } = this.props
        return <GridRow>
            <div>
                <Card.Label>Contact information</Card.Label>
                <Card.Text>{partner.companyName}</Card.Text>
                {partner.website && <a
                    href={this.fixUrl(partner.website)}
                    target="_blank" rel="noreferrer"
                                    >
                    <Card.Text>{partner.website}</Card.Text>
                </a>}
                {partner.vatNumber && <Fragment>
                    <Card.Label>VAT number</Card.Label>
                    <Card.Text>{partner.vatNumber}</Card.Text>
                </Fragment>}

                {partner.phone && <Fragment>
                    <CallButton
                        onCall={this.callPartnerButton}
                        phoneNumber={partner.phone}
                        geoCode={partner.geo.code}
                        label="Call partner"
                    />
                </Fragment>}
            </div>
            <Flex modifiers={[ 'directionColumn', 'justifySpaceBetween' ]}>
                <div>
                    <Card.Label>Status</Card.Label>
                    <Card.Text>{firstLetterUpperCase(partner.state)}</Card.Text>
                    <Card.Label>Address</Card.Label>
                    <Card.Text>{partner.geo.address}</Card.Text>
                    <Card.Text>
                        {partner.geo.zipCode} 
                        {' '}
                        {partner.geo.cityName}
                    </Card.Text>
                    <Card.Label>Industry</Card.Label>
                    <Card.Text>{partner.industry.name}</Card.Text>
                </div>
                {partner.employee && <ConsultantList partner={partner}/>}
            </Flex>
            <div>
                <Card.Label>Certifications</Card.Label>
                <CertificationList>
                    {certifications.map(el => <li key={'certification_' + el.id}>{el.title}</li>)}
                </CertificationList>
            </div>
        </GridRow>
    }
}

export default PartnerMetaData as unknown as ComponentType<PathProps>
