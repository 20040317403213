import React, { ComponentType, PureComponent } from 'react'
import { leadActivitiesSelector } from '../../../selectors/lead'
import { createCallLeadAction } from '../../../actions/actions'
import { createLeadNoteActivity } from '../../../actions/newsfeed'
import { rejectQuotes } from '../../../actions/quotes'
import { replace } from 'connected-react-router'
import { submit } from 'redux-form'
import FormattedDate from '../../elements/FormattedDate'
import LeadActionsButtonDropdown from '../../leads/LeadActionsButtonDropdown'
import styled from 'styled-components'
import { connect } from '../../../utils/connect'
import { Activity } from '../../../reducers/types/entities/Activities'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Quotes } from '../../../reducers/types/entities/Quote'
import ProviderTable from '../../blocks/ProviderTable'
import { LinkCell, Cell, DateCell, EditCell } from '../../elements/table'

const PartnerUl = styled.ul`
  margin: 10px 0;
  list-style: none;
  padding: 0;
`

const QuoteInfo = ({ publishedQuotes, index }) => publishedQuotes[index]
    ? <PartnerUl>
        <li>{publishedQuotes[index].partner.companyName || 'No company name'}</li>
        <li>
            {publishedQuotes[index].totalAmountExclVat.amount}
            {publishedQuotes[index].totalAmountExclVat.currency}
        </li>
        <li><FormattedDate date={publishedQuotes[index].publishedAt} /></li>
    </PartnerUl>
    : null


interface FollowUpRowStateProps {
    quotes: Quotes
    employeeId: number
    leadActivity: Activity
}
interface FollowUpRowDispatchProps {
    createLeadNoteActivity: typeof createLeadNoteActivity
    createCallLeadAction: typeof createCallLeadAction
    rejectQuotes: typeof rejectQuotes
    replace: typeof replace
    submit: typeof submit
}

interface FollowUpRowHOCProps {
    rowData: Lead
    visibleCells: string[]
}

type FollowUpRowModel = FollowUpRowStateProps & FollowUpRowDispatchProps & FollowUpRowHOCProps

@connect<FollowUpRowStateProps, FollowUpRowDispatchProps, FollowUpRowHOCProps>(
    (state, props) => ({
        quotes: state.entities.quotes,
        employeeId: state.auth.employeeId,
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
    }),
    {
        createLeadNoteActivity,
        createCallLeadAction,
        rejectQuotes,
        replace,
        submit,
    }
)
class FollowUpRow extends PureComponent<FollowUpRowModel> {
    state = {
        activityText: '',
        date: '',
    }

    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        const res: any = await this.props.createLeadNoteActivity(leadId, noteData)
        this.setState({
            activityText: res.note,
            date: res.createdAt,
        })
    }



    render() {
        const {
            quotes,
            leadActivity,
            ...props
        } = this.props

        const lead = this.props.rowData

        const activityText = (leadActivity && leadActivity.note) ? leadActivity.note.text : ''

        const publishedQuotes = lead.quotesInfo.quotes
            .map(id => quotes[id])
            .filter(quote => quote.publishedAt)

        const mostRecentQuotePublicationDate = new Date(Math.max.apply(null, publishedQuotes.map(quote => {
            return new Date(quote.publishedAt)
        })))

        return <ProviderTable.Row {...props}>
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>{lead.category ? lead.category.title : ''}</Cell>
            <Cell>{lead.title}</Cell>
            <Cell>{lead.validator.firstName + ' ' + lead.validator.lastName}</Cell>
            <DateCell date={mostRecentQuotePublicationDate} format="longdatetime"/>
            <LinkCell to={`/leads/${lead.id}/validation`}>
                {lead.customerInfo.firstName + ' ' + lead.customerInfo.lastName}
            </LinkCell>
            <Cell><QuoteInfo publishedQuotes={publishedQuotes} index={0}/></Cell>
            <Cell><QuoteInfo publishedQuotes={publishedQuotes} index={1}/></Cell>
            <Cell><QuoteInfo publishedQuotes={publishedQuotes} index={2}/></Cell>
            <EditCell
                value={this.state.activityText || activityText}
                onBlur={this.handleChangeNoteCell}
            />
            <DateCell date={lead.quotesInfo.clientDecisionPostponedTo} format="longdatetime" />
            {lead.actionsInfo
                ? <DateCell date={lead.actionsInfo.latestCalledAt} format="longdatetime" />
                : <Cell />
            }
            {(this.state.date || lead.actionsInfo)
                ? <DateCell date={this.state.date || lead.actionsInfo.latestCreatedAt} format="longdatetime" />
                : <Cell />
            }
            <DateCell date={lead.validatedAt} format="longdatetime"/>
            <Cell className="text-center">
                <LeadActionsButtonDropdown
                    lead={lead}
                    publishedQuotes={publishedQuotes}
                />
            </Cell>
        </ProviderTable.Row>
    }
}

export default FollowUpRow as unknown as ComponentType
