import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_LEAD_VALIDATION_DATA_FIELDS = 'datafield/GET_LEAD_VALIDATION_DATA_FIELDS'
export const GET_LEAD_VALIDATION_DATA_FIELDS_SUCCESS = 'datafield/GET_LEAD_VALIDATION_DATA_FIELDS_SUCCESS'
export const GET_LEAD_VALIDATION_DATA_FIELDS_FAIL = 'datafield/GET_LEAD_VALIDATION_DATA_FIELDS_FAIL'

interface GetLeadValidationDataFieldsPropsModel {
    industryId: number | number[]
}

export const getLeadValidationDataFields = (
    body: GetLeadValidationDataFieldsPropsModel,
) => ({
    [CALL_API]: {
        types: [
            GET_LEAD_VALIDATION_DATA_FIELDS,
            GET_LEAD_VALIDATION_DATA_FIELDS_SUCCESS,
            GET_LEAD_VALIDATION_DATA_FIELDS_FAIL,
        ],
        endpoint: '/datafields',
        schema: schemas.dataFields,
        options: {
            body: {
                ...body,
                type: 'lead',
                limit: 100,
                is_active: true,
            },
        },
    },
})
