import React, { memo, cloneElement } from 'react'

interface RowsModel {
    children
    noDataText?: string
    items: any[]
}

const Rows = memo(({
    noDataText,
    children,
    items,
}: RowsModel) =>
    <tbody>
        {items.length
            ? items.map(rowData => cloneElement(children, { rowData, key: rowData.id }))
            : <tr>
                <td colSpan={100} className="text-center">{noDataText}</td>
            </tr>}
    </tbody>
)

export default Rows
