import React, { ComponentType, PureComponent } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Field, submit } from 'redux-form'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { updatePartnerSubscription } from '../../actions/subscriptions'
import { Button } from '../elements/Button'
import styled from 'styled-components'
import ReduxForm from '../elements/forms/ReduxForm'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'
import { Subscription } from '../../reducers/types/entities/Subscription'
import moment from 'moment'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 820px;
    }
`

interface ExcludePartnersModel {
    partner: Partner
    subscription: Subscription
    modalProps: {
        partnerId: number
        subscriptionId: number
    }
    updatePartnerSubscription: typeof updatePartnerSubscription
    hideModal: typeof hideModal
    submit: typeof submit
}
@connect(
    state => ({
        partner: state.entities.partners[state.modal.partnerId],
        subscription: state.entities.subscriptions[state.modal.subscriptionId],
        modalProps: state.modal,
    }),
    {
        updatePartnerSubscription,
        hideModal,
        submit,
    }
)
class SubscriptionUpdateModal extends PureComponent<ExcludePartnersModel> {
    state = { subscription: null }

    hideModal = () => this.props.hideModal()

    /*    save = () => {
        this.props.submit('updatePartnerSubscription')
    }*/

    save = subscription => {
        this.setState({ subscription }, () =>
            this.props.submit('updatePartnerSubscription')
        )
    }

    updatePartnerSubscription = data => {
        const body = {
            packageId: this.state.subscription.packageId,
            startsAt: data.startsAt,
            monthlyPrice: this.state.subscription.monthlyPrice,
        }

        this.props.updatePartnerSubscription(this.props.modalProps.partnerId, this.props.modalProps.subscriptionId, body)
        this.hideModal()
    }

    valid = (current) => {
        let yesterday = moment().subtract( 1, 'day' )
        return current.isAfter(yesterday)
    };

    render() {
        const {
            partner,
            subscription,
        } = this.props

        return <LgModal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>
                Manage subscription for
                {partner.companyName}
            </Modal.Header>
            <Modal.Body>
                <ReduxForm form="updatePartnerSubscription" onSubmit={this.updatePartnerSubscription} initialValues={subscription}>
                    <Row>
                        <Col md={8}>
                            Choose when to start the subscription 
                            {' '}
                            <br />
                        </Col>
                        <Col md={4}>
                            <Field
                                component={DateTimePickerGroup}
                                name="startsAt"
                                isValidDate={this.valid}
                                timeConstraints={{ hours:{ min: moment().hour() + 1 } }}
                            />
                        </Col>
                    </Row>
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer className="text-right">
                <Button
                    modifiers={[ 'action', 'mR_1.5' ]}
                    onClick={this.save}
                >
                    Save date
                </Button>
                <Button
                    modifiers={[ 'secondary' ]}
                    onClick={this.hideModal}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </LgModal>
    }
}

export default SubscriptionUpdateModal as unknown as ComponentType
