import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import { Col, Row } from 'react-bootstrap'
import { getOpenCasesLeads } from '../../../actions/leads'
import { connect } from '../../../utils/connect'

interface MyCustomersCardModel {
    totalQS: number
    totalFU: number
    totalOC: number
    employeeId: number
    getOpenCasesLeads: typeof getOpenCasesLeads
}

@connect(
    state => ({
        totalQS: state.pages.quotesSurveillance.pagination.total,
        totalFU: state.pages.matchSurveillance.pagination.total,
        totalOC: state.pages.openCasesLeads.pagination.total,
        employeeId: state.auth.employeeId,
    }),
    {
        getOpenCasesLeads,
    }
)
class MyCustomersCard extends PureComponent<MyCustomersCardModel> {
    componentDidMount() {
        this.props.getOpenCasesLeads({
            employeeId: this.props.employeeId,
            validatorId: this.props.employeeId,
            limit: '0',
        })
    }
    render() {
        const { totalQS, totalFU, totalOC } = this.props
        return <Card>
            <Card.Header>
                My customers
            </Card.Header>
            <Card.Content>
                <Row>
                    <Col md={3}>
                        <Card.Label>Follow-ups</Card.Label>
                        <Card.Text>{totalFU}</Card.Text>
                    </Col>
                    <Col md={3}>
                        <Card.Label>Quote surveillance</Card.Label>
                        <Card.Text>{totalQS}</Card.Text>
                    </Col>
                    <Col md={3}>
                        <Card.Label>Open cases</Card.Label>
                        <Card.Text>{totalOC}</Card.Text>
                    </Col>
                </Row>
            </Card.Content>
        </Card>
    }
}

export default MyCustomersCard as unknown as ComponentType
