import { createSelector } from 'reselect'
import { allGeoRegionsSelector } from '../geoRegions/selectors'

const segmentationSubGeoRegionsEntities = state => state.entities.segmentationSubGeoRegions

const allSegmentationSubGeoRegionsIds = state => state.subjects.segmentationSubGeoRegions.all.ids

const leadSegmentationSubGeoRegionsIds = state => state.subjects.segmentationSubGeoRegions.lead.ids

export const allSegmentationSubGeoRegionsSelector = createSelector(
    allSegmentationSubGeoRegionsIds, segmentationSubGeoRegionsEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const segmentationRegionAndSubRegionsSelector = createSelector(
    allSegmentationSubGeoRegionsSelector, allGeoRegionsSelector,
    (subGeoRegions, geoRegions) => [ ...geoRegions, ...subGeoRegions ]
)

export const leadSegmentationSubGeoRegionsSelector = createSelector(
    leadSegmentationSubGeoRegionsIds, segmentationSubGeoRegionsEntities,
    (ids, entities) => ids.map(id => entities[id])
)
