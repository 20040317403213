const sortAscending = (items, key) => {
    return items
        .sort((a, b) => {
            if (a[key] < b[key]) {
                return -1
            }
            if (a[key] > b[key]) {
                return 1
            }
            return 0
        })
}

export default sortAscending
