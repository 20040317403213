import React, { ComponentType, PureComponent } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import CanvasNotRelevantForm from '../CanvasNotRelevantForm'
import PostponeCanvasForm from '../PostponeCanvasForm'
import { Card } from '../../blocks/Card'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Button } from '../../elements/Button'
import { createPartnerAction } from '../../../actions/partners'
import { connect } from '../../../utils/connect'
import { submit } from 'redux-form'
import moment from 'moment'
import { showModal } from '../../../actions/modal'

interface StatePropsCanvasControl {
    formData: any
}

interface DispatchPropsCanvas {
    createPartnerAction: typeof createPartnerAction
    showModal: typeof showModal
    submit: typeof submit
}
interface PathPropsCanvas {
    partner: Partner
}

type CanvasControlsCardModel = StatePropsCanvasControl & DispatchPropsCanvas & PathPropsCanvas

@connect<StatePropsCanvasControl, DispatchPropsCanvas, PathPropsCanvas>(
    state => ({
        formData: state.form,
    }),
    {
        createPartnerAction,
        showModal,
        submit,
    }
)
class CanvasControlsCard extends PureComponent<CanvasControlsCardModel, {modal: string}> {
    state = {
        modal: '',
    }

    showModal(modal) {
        this.setState({ modal })
    }
    notRelevant = async ({ note, action, duration, date }) => {
        const body = {
            action,
            note,
        }

        if (duration) {
            const callAt = duration === 'custom' ? moment(date) : moment().add(duration, 'day')
            Object.assign(body, {
                nextActionAt: callAt.format(),
                action: 'canvas-postpone',
            })
        }

        await this.props.createPartnerAction(this.props.partner.id, body)
        this.showModal(false)
    }

    convertCanvasToDemo = async () => {
        const { id } = this.props.partner

        await this.props.createPartnerAction(id, { action: 'make-demo' })
    }

    render() {
        const {
            formData,
            partner,
        } = this.props

        const {
            modal,
        } = this.state

        let state = 'Unknown'

        switch (partner.canvas && partner.canvas.status) {
            case 'x_partner':
                state = 'Ex-partner'
                break
            case 'not_interested':
                state = 'Not interested'
                break
            case 'untreated':
                state = 'Untreated'
                break
            case 'not_relevant':
            case 'already_partner':
                state = 'Not relevant'
                break
            case 'postponed':
                state = 'Postponed'
        }

        const hideDismissBtn = [ 'not_relevant', 'already_partner' ].includes(partner.canvas.status)

        return (
            <Card>
                <Card.Header>
                    Canvas status:
                    <b> 
                        {' '}
                        {state}
                    </b>
                </Card.Header>
                <Card.Content>
                    <Row>
                        <Col md={4}>
                            <Button
                                modifiers={[ 'action', 'fullWidth' ]}
                                onClick={this.convertCanvasToDemo}
                            >
                                Create demo
                            </Button>
                        </Col>
                        <Col md={4}>
                            <Button
                                modifiers={[ 'action', 'fullWidth' ]}
                                onClick={() => this.showModal('postpone')}
                            >
                                Call back
                            </Button>
                        </Col>
                        <Col md={4}>
                            {hideDismissBtn || <Button
                                modifiers={[ 'danger', 'fullWidth' ]}
                                onClick={() => this.showModal('not_relevant')}
                            >
                                Dismiss
                            </Button>}
                        </Col>
                    </Row>

                    <Modal
                        backdrop="static"
                        show={modal === 'postpone'}
                        onHide={() => this.showModal(false)}
                    >
                        <Modal.Header closeButton>Postpone</Modal.Header>
                        <Modal.Body>
                            <PostponeCanvasForm
                                formName={`postponeCanvas-${partner.id}`}
                                formValues={formData[`postponeCanvas-${partner.id}`]}
                                onSubmit={this.notRelevant}
                                submit={this.props.submit}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal
                        backdrop="static"
                        show={modal === 'not_relevant'}
                        onHide={() => this.showModal(false)}
                    >
                        <Modal.Header closeButton>Dismiss canvas partner</Modal.Header>
                        <Modal.Body>
                            <CanvasNotRelevantForm
                                formName={`canvasNotRelevant-${partner.id}`}
                                onSubmit={this.notRelevant}
                                submit={this.props.submit}
                            />
                        </Modal.Body>
                    </Modal>
                </Card.Content>
            </Card>
        )
    }
}

export default CanvasControlsCard as unknown as ComponentType<PathPropsCanvas>
