import React, { PureComponent, ComponentType } from 'react'
import { connect } from '../../../utils/connect'
import { addPartnerFeature, removePartnerFeature } from '../../../state/features/actions'
import { getPartner } from '../../../actions/partners'
import { Card } from '../../blocks/Card'
import { Col, Row } from 'react-bootstrap'
import ActionCheckBox from '../../elements/ActionCheckBox'
import { toastr } from 'react-redux-toastr'
import { firstLetterUpperCase } from '../../../utils'

interface StateProps {
    partnerFeatures,
    allFeatureIdentifiers,
}

interface DispatchProps {
    addPartnerFeature: typeof addPartnerFeature
    removePartnerFeature: typeof removePartnerFeature
    getPartner: typeof getPartner
}

interface PathProps {
    partnerId: number
    partnerFeatures: string[]
}

type PartnerFeaturesModel = StateProps & DispatchProps & PathProps

@connect<StateProps, DispatchProps, PathProps>(
    (state, props) => ({
        partnerFeatures: state.entities.partners[props.partnerId].features,
        allFeatureIdentifiers: [ 'billy_partner' ],
    }),
    {
        addPartnerFeature,
        removePartnerFeature,
        getPartner,
    }
)
class PartnerFeatures extends PureComponent<PartnerFeaturesModel> {
    handlePartnerFeatureChange = async (feature, checked) => {
        const { partnerId } = this.props
        try {
            if (checked) {
                await this.props.addPartnerFeature(partnerId, feature)
                this.props.getPartner(partnerId)
                toastr.success('Feature was added')
            } else {
                await this.props.removePartnerFeature(partnerId, feature)
                this.props.getPartner(partnerId)
                toastr.success('Feature was removed')
            }
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        const {
            allFeatureIdentifiers,
            partnerFeatures,
        } = this.props
        return <Card>
            <Card.Header>Partner features</Card.Header>
            <Card.Content>
                <Row>
                    {allFeatureIdentifiers.map(feature => {
                        const checked = partnerFeatures && partnerFeatures.includes(feature)
                        return <Col md={4} key={feature}>
                            <ActionCheckBox
                                onClick={this.handlePartnerFeatureChange}
                                value={feature}
                                checked={checked}
                                text={firstLetterUpperCase(feature)}
                            />
                        </Col>
                    })}
                </Row>
            </Card.Content>
        </Card>
    }
}

export default PartnerFeatures as unknown as ComponentType<PathProps>
