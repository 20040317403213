import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers/index'
import api from './middleware/api'
import toastrHandler from './middleware/toastrHandler'
import { AppStoreState } from './reducers/types'
import { createBrowserHistory } from 'history'
import { routerMiddleware,  } from 'connected-react-router'
import routeQueryParser from './middleware/routeQueryParser'

export const history = createBrowserHistory()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(initialState?: AppStoreState) {
    return createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(
                routeQueryParser,
                routerMiddleware(history),
                api,
                toastrHandler,
                thunkMiddleware
            )
        )
    )
}

const store = configureStore()

export default store
