import config from '../config'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_LOCATIONS = 'locations/GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'locations/GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAIL = 'locations/GET_LOCATIONS_FAIL'

export const GET_LOCATION = 'locations/GET_LOCATION'
export const GET_LOCATION_SUCCESS = 'locations/GET_LOCATION_SUCCESS'
export const GET_LOCATION_FAIL = 'locations/GET_LOCATION_FAIL'

interface GetLocationsModel {
    query?: string
    zipCode?: string
}
export const getLocations = (
    criteria: GetLocationsModel
) => ({
    [CALL_API]: {
        types: [ GET_LOCATIONS, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAIL ],
        endpoint: '/locations',
        schema: schemas.locations,
        options: {
            body: {
                geoCode: config.geoCode,
                limit: 50,
                ...criteria,
            },
        },
    },
})

export const getLocation = (
    id: string
) => ({
    [CALL_API]: {
        types: [ GET_LOCATION, GET_LOCATION_SUCCESS, GET_LOCATION_FAIL ],
        endpoint: `/locations/${id}`,
        schema: schemas.location,
    },
})
