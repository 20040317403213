import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import {
    GET_BUSINESS_UNIT_INDUSTRIES,
    GET_INDUSTRIES,
    GET_PARTNER_INDUSTRIES,
    REMOVE_INDUSTRY_FROM_PARTNER,
    UPDATE_PARTNER_INDUSTRY,
} from './actions'

const industries = combineReducers({
    all: generalSubjectReducer(GET_INDUSTRIES),
    partner: generalSubjectReducer(GET_PARTNER_INDUSTRIES, UPDATE_PARTNER_INDUSTRY, REMOVE_INDUSTRY_FROM_PARTNER),
    businessUnit: generalSubjectReducer(GET_BUSINESS_UNIT_INDUSTRIES),
})

export default industries
