import React, { ComponentType, memo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { TableProviderContext } from './_Provider'
import { Table } from '../../elements/table'
import styled from 'styled-components'

const ScrollOverflow = styled.div`
  overflow-y: auto;
`

const _LazyTable = memo(({
    children,
}) =>
    <ScrollOverflow>
        <TableProviderContext.Consumer>
            {({ loadMore, hasMore }) =>
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasMore}
                    useWindow={false}
                    initialLoad={false}
                >
                    <Table>
                        {children}
                    </Table>
                </InfiniteScroll>
            }
        </TableProviderContext.Consumer>
    </ScrollOverflow>
)

export default _LazyTable as ComponentType
