import styled from 'styled-components'
import { tableBorderColor } from '../../../../utils/variables'

const Column = styled.th<{name?: string}>`
    position: sticky;
    top: 0;
    z-index: 5;
    vertical-align: middle;
    height: 50px;
    padding: 0px 20px;
    line-height: 16px;
    background-color: #F8FAFC;
    color: #6F8289;
    text-transform: uppercase;
    font-size: 11px;
    &:after {
        content:'';
        position: absolute;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-top: 1px solid ${tableBorderColor};
        border-bottom: 1px solid ${tableBorderColor};
    }
    &:first-of-type:after {
        border-left: 1px solid ${tableBorderColor};
    }
    &:last-of-type:after {
        border-right: 1px solid ${tableBorderColor};
    }
    &:not(:first-of-type):before {
        content:'';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        border-left: 1px solid ${tableBorderColor};
    }
    a {
        font-weight: bold;
        color: #6F8289;
        cursor: pointer;
    }
    &.number {
        text-align: right;
    }
`

export const NestedColumn = styled.th<{ name?: string }>`
    position: sticky;
    top: 50px;
    z-index: 4;
    vertical-align: middle;
    background-color: #FFF;
    color: #6F8289;
    padding: 0px 20px;
    height: 30px;
    line-height: 16px;
    text-transform: uppercase;
    font-size: 11px;
    &:after{
      content:'';
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: 0;
      width:100%;
      border-bottom: 1px solid ${tableBorderColor};
    a {
      font-weight: bold;
      color: #6F8289;
      cursor: pointer;
    }
    &.number {
      text-align: right;
    }
`

export const StyledColumn = styled.th<{ name?: string }>`
    vertical-align: middle;
    height: 30px;
    padding: 0px 20px;
    line-height: 16px;
    background-color: #F1F5F9;
    color: #6F8289;
    text-transform: uppercase;
    font-size: 11px;
    &:after{
      content:'';
      position:absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-top: 1px solid ${tableBorderColor};
      border-left: 1px solid ${tableBorderColor};
      border-bottom: 1px solid ${tableBorderColor};
    &:last-of-type {
      border-right: 1px solid ${tableBorderColor};
    }
    a {
      font-weight: bold;
      color: #6F8289;
      cursor: pointer;
    }
    &.number {
      text-align: right;
    }
`

export default Column
