import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_LEAD_TYPES = actionTypesCreator('leadTypes', 'GET_LEAD_TYPES')

export const GET_PARTNER_LEAD_TYPES = actionTypesCreator('leadTypes', 'GET_PARTNER_LEAD_TYPES')

export const REMOVE_LEAD_TYPE_FROM_PARTNER = actionTypesCreator('leadTypes', 'REMOVE_LEAD_TYPE_FROM_PARTNER')

export const UPDATE_PARTNER_LEAD_TYPES = actionTypesCreator('leadTypes', 'UPDATE_PARTNER_LEAD_TYPES')

export const GET_BUSINESS_UNIT_LEAD_TYPES = actionTypesCreator('leadTypes', 'GET_BUSINESS_UNIT_LEAD_TYPES')

export const getLeadTypes = (
    geoCode: string
) => ({
    [CALL_API]: {
        types: GET_LEAD_TYPES,
        endpoint: '/leads/types',
        schema: schemas.leadTypes,
        options: {
            body: {
                geoCode,
                limit: 100,
            },
        },
    },
})

export const getPartnerLeadTypes = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: GET_PARTNER_LEAD_TYPES,
        endpoint: `/partners/${partnerId}/lead_types`,
        schema: schemas.leadTypes,
        options: {
            body: { limit: 100 },
        },
    },
})

export const addLeadTypeToPartner = (
    partnerId: number,
    leadTypeId: number
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_LEAD_TYPES,
        endpoint: `/partners/${partnerId}/lead_types`,
        schema: schemas.leadType,
        options: {
            method: 'post',
            body: {
                id: leadTypeId,
            },
        },
    },
})

export const updatePartnerLeadTypes = (
    partnerId: number,
    typeIds: number | number[]
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_LEAD_TYPES,
        endpoint: `/partners/${partnerId}/lead_types`,
        schema: schemas.leadType,
        options: {
            method: 'put',
            body: {
                typeIds,
            },
        },
    },
})

export const removeLeadTypeFromPartner = (
    partnerId: number,
    leadTypeId: number
) => ({
    [CALL_API]: {
        types: REMOVE_LEAD_TYPE_FROM_PARTNER,
        endpoint: `/partners/${partnerId}/lead_types/${leadTypeId}`,
        options: {
            method: 'delete',
        },
    },
    id: leadTypeId,
})

export const getBusinessUnitsLeadTypes = (
    partnerId: number,
    businessUnitId: number
) => ({
    [CALL_API]: {
        types: GET_BUSINESS_UNIT_LEAD_TYPES,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/types`,
        schema: schemas.leadTypes,
        options: {
            body: { limit: 100 },
        },
    },
})
