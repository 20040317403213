import React, { PureComponent, Fragment, ComponentType } from 'react'
import NewsFeed from '../activities/NewsFeed'
import ContactFlow from '../activities/ContactFlow'
import { Card } from '../blocks/Card'
import { SeparatorLine } from '../elements/Separators'
import Tabs from '../elements/Tabs'
import AddNoteForm from '../activities/AddNoteForm'
import { Flex } from '../elements/Flex'

const TABS = [
    { tabId: 'feed', tabName: 'Feed' },
    { tabId: 'partners_notes_create', tabName: 'Notes' },
    { tabId: 'phonecalls_create', tabName: 'Calls' },
    { tabId: 'invoices_create', tabName: 'Invoices' },
    { tabId: 'task_create,task_complete', tabName: 'Tasks' },
    { tabId: 'email', tabName: 'Email' },
]

interface PartnerNewsFeedModel {
    partnerId: number
}

interface PartnerNewsFeedModelState {
    selectedTab: string,
    subject: {
        partnerId: number
    }
}

class PartnerNewsFeed extends PureComponent<PartnerNewsFeedModel, PartnerNewsFeedModelState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 'feed',
            subject: {
                partnerId: props.partnerId,
            },
        }
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.partnerId !== this.props.partnerId) {
            this.setState({
                selectedTab: 'feed',
                subject: {
                    partnerId: this.props.partnerId,
                },
            })
        }
    }

    changeTab = tabIndex => {
        this.setState({ selectedTab: tabIndex })
    }

    render() {
        const {
            partnerId,
        } = this.props

        const { selectedTab } = this.state

        const headerFormContentHeight = [ 'feed', 'partners_notes_create' ].includes(selectedTab) ? 145 : 65

        return (
            <Card className="partnerNewsFeed">
                <Card.Header padding="0 20px">
                    <Flex modifiers={[ 'justifySpaceBetween' ]}>
                        <Tabs
                            tabs={TABS}
                            selectedTab={selectedTab}
                            changeTab={this.changeTab}
                        />
                    </Flex>
                </Card.Header>
                {selectedTab === 'email'
                    ? <ContactFlow
                        entityId={partnerId}
                        stateName="partnerDetail"
                    />
                    : <Fragment>
                        {[ 'feed', 'partners_notes_create' ].includes(selectedTab) &&
                        <Card.Content>
                            <AddNoteForm
                                entityId={partnerId}
                                stateName="partnerDetail"
                                selectedTab={selectedTab}
                            />
                        </Card.Content>}
                        <SeparatorLine modifiers="m_0" />
                        <NewsFeed
                            selectedTab={selectedTab}
                            subject={this.state.subject}
                            scrollHeight={`calc(100% - ${headerFormContentHeight}px)`}
                        />
                    </Fragment>
                }
            </Card>
        )
    }
}

export default PartnerNewsFeed as unknown as ComponentType<PartnerNewsFeedModel>
