import { createSelector } from 'reselect'

const pagesLeadValidationProductsId = state => state.pages.leadValidation.productsId

const entitiesProducts = state => state.entities.products

export const productsSelector = createSelector(
    pagesLeadValidationProductsId, entitiesProducts,
    (productsId, products) => productsId.map(id => products[id])
)
