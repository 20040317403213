import React, { PureComponent, Fragment } from 'react'
import { ProgressBar, Tooltip, OverlayTrigger, Label } from 'react-bootstrap'
import { get } from 'lodash'
import { Card } from '../../blocks/Card'
import Tabs from '../../elements/Tabs'
import { Table } from '../../elements/table'
import Cell from '../../elements/table/cells/Cell'
import Column from '../../elements/table/columns/Column'
import Header from '../../elements/table/Header'
import styled from 'styled-components'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'
import moment from 'moment'
import { Quote } from '../../../reducers/types/entities/Quote'
import { Partner } from '../../../reducers/types/entities/Partner'
import { FormattedAmount } from '../../elements/FormattedAmount'
import { Button } from '../../elements/Button'
import { showModal } from '../../../actions/modal'
import { Flex } from '../../elements/Flex'
import {
    actionColor,
    primaryColor,
    backgroundColor,
    secondaryLightColor,
    primaryDarkColor,
} from '../../../utils/variables'
import { PartnerContract } from '../../../reducers/types/entities/PartnerContract'
import FormattedDate from '../../elements/FormattedDate'
import DeliveryProgressBar from './DeliveryProgressBar'
import config from '../../../config'

const DeliveryProgressBarDiv = styled.div<{padding?: number, nested?: boolean, margin?: string}>`
    position: relative;
    padding: ${({ padding }) => padding || 0}px 0;
    margin: ${({ margin }) => margin || 0};

    & > .progress {
        margin: 0;
        height: 12px;
    }

    ${({ nested }) => !nested ? '' : `
        & > .progress {
            margin: 0;
            height: 8px;
        }
    `}
`

const ProgressLine = styled(ProgressBar)<{contentValue?: string}>`
  margin: 0;
  position: relative;
  &::after {
      color: #000;
      content: "${props => props.contentValue}";
      line-height: 22px;
      position: absolute;
      left: 48%;
    }
`

const TooltipIcon = styled.span`
    display: inline-flex;
    width: 1em;
    height: 1em;
    margin-bottom: .8em;
    justify-content: center;
    align-items: center;
    color: ${primaryDarkColor};
    border: 1px solid currentColor;
    border-radius: 50%;
    cursor: pointer;
`

const NestedTab = styled.div<{active?: boolean}>`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    padding: 8px 12px;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    border-radius: 18px;
    color: ${backgroundColor};

    background-color: ${({ active }) => active ? primaryColor : secondaryLightColor};
`

const Completion = styled.span<{completed: boolean }>`
    color: ${props => props.completed ? actionColor : 'initial'};
`

const NestedCard = styled.div`
    position: relative;
    padding: 40px 50px 60px;
    width: 100%;
`

interface BusinessUnitRowModel {
    allocationType: string
    businessUnit: BusinessUnit
    returnBusinessModels: any
    formatPeriodToProgress: any
}

export class BusinessUnitRow extends PureComponent<BusinessUnitRowModel> {
    state = {
        detailDisplayed: false,
    }

    showDetail = async () => {
        await this.setState({ detailDisplayed: !this.state.detailDisplayed })
    }

    render() {
        const { allocationType, businessUnit, returnBusinessModels, formatPeriodToProgress } = this.props

        let lbLimit
        let lbCount
        let overdeliveryCount

        if (allocationType === 'currentMonthAllocation') {
            // To handle scenario where an old subscription-based business unit had unused allocations allocated in current allocation type but defined quota from the business model
            lbLimit = businessUnit.isActive ? businessUnit[allocationType].suggestedAllocationQuota : businessUnit[allocationType].currentMonthAllocationCount
            lbCount = businessUnit[allocationType].currentMonthAllocationCount
            overdeliveryCount = businessUnit[allocationType].overdeliveryAllocationCount
        } else {
            lbLimit = businessUnit.isActive ? businessUnit[allocationType].normalAllocationQuota : businessUnit[allocationType].normalQuotaAllocationCount
            lbCount = businessUnit[allocationType].normalQuotaAllocationCount
            overdeliveryCount = businessUnit[allocationType].justInTimeAllocationCount + businessUnit[allocationType].overdeliveryAllocationCount
        }
        const lbCompleted = lbCount + lbLimit !== 0 && lbCount >= lbLimit

        const ppsCount = businessUnit[allocationType].payPerSpotAllocationCount
        const ppsLimit = businessUnit[allocationType].payPerSpotAllocationLimit
        const ppsCompleted = ppsCount + ppsLimit !== 0 && ppsCount >= ppsLimit

        const remainder = lbLimit - lbCount - overdeliveryCount
        const deliveryString = `${lbCount}${overdeliveryCount ? ` + ${overdeliveryCount}` : ''} / ${lbLimit}${remainder !== 0 ? ` (${remainder} left)` : ''}`

        return <Fragment>
            <tr onClick={this.showDetail}>
                <Cell>
                    <b>
                        #
                        {businessUnit.id}
                        {' '}
                    </b>
                    {businessUnit.displayName}
                </Cell>
                <Cell>{businessUnit.isActive ? <Completion completed={true}>Active</Completion> : <Completion completed={false}>Inactive</Completion>}</Cell>
            </tr>
            {this.state.detailDisplayed && <tr>
                <td colSpan={100}>
                    <NestedCard>
                        <Flex modifiers={[ 'justifySpaceBetween', 'mT_1' ]}>
                            <Card.Label>LB delivery</Card.Label>
                            <Completion completed={lbCompleted}>{deliveryString}</Completion>
                        </Flex>
                        <DeliveryProgressBarDiv>
                            <ProgressBar>
                                <ProgressLine
                                    bsStyle="info"
                                    now={lbCount}
                                    max={lbLimit + overdeliveryCount === 0 ? 1 : lbLimit + overdeliveryCount}
                                    key={1}
                                />
                                {overdeliveryCount > 0 && <ProgressLine
                                    now={overdeliveryCount}
                                    max={lbLimit + overdeliveryCount === 0 ? 1 : lbLimit + overdeliveryCount}
                                    key={2}
                                />}
                            </ProgressBar>
                        </DeliveryProgressBarDiv>
                        <Card.Line modifiers={[ 'mT_2' ]}/>
                        <Flex modifiers={[ 'justifySpaceBetween', 'mT_1' ]}>
                            <Card.Label>PPS delivery</Card.Label>
                            <Completion completed={ppsCompleted}>
                                {ppsCount}
                                {' '}
                                /
                                {ppsLimit}
                            </Completion>
                        </Flex>
                        <DeliveryProgressBarDiv>
                            <ProgressBar>
                                <ProgressLine
                                    bsStyle="warning"
                                    now={ppsCount}
                                    max={ppsLimit === 0 ? 1 : ppsLimit}
                                    key={1}
                                />
                            </ProgressBar>
                        </DeliveryProgressBarDiv>
                    </NestedCard>
                    <Card.Line />
                </td>
            </tr>}
        </Fragment>
    }
}

const tooltips = {
    publishingRatio: 'Publishing Ratio, shows how many of the leads that are sent to the partner that the partner sends an offer to.',
    successRatio: 'Success Ratio defines how many percent of cases that a partner quotes on a lead that the partner wins. This is Calculated as the number of matches divided with number of quoted leads.',
    wonCases: 'The Value of won cases, is the amount of money, that the partner has quoted on cases that the partner has won.',
    lastQuote: 'The last time that the partner quoted on a lead.',
    deliveryTrend: 'The delivery trend, shows how many leads that the partner has received according to how far into the contract period the partner is. 100% Means that the partner has received exactly the amount of leads according to how far into the contract period we are.',
    lbSinceStart: 'The LB Delivery is the amount of leads we have delivered compared to the total of leads that we owe to the partner.',
    lbCurrentPeriod: 'The LB Delivery is the amount of leads we have delivered compared to the total of leads that we owe to the partner. This is also shown for individual business units. The green line is a trend line showing how much we should have delivered at current time in the contract period.',
    lbCurrentMonth: 'This number represents the number of leads that are suggested to be delivered to the partner in order to keep up with the delivery trend',
    pps: 'PPS Delivery is not a guaranteed amount of leads to deliver, however it is a maximum that the partner will accept for a given period.',
}

interface StatisticsCardModel {
    geoCode: string
    businessUnits: BusinessUnit[]
    lastGivenQuote: Quote
    partnerKpi: Partner['newKpi']
    showModal: typeof showModal
    partnerId: number
    activePartnerContracts?: PartnerContract[]
}

export default class StatisticsCard extends PureComponent<StatisticsCardModel> {
    state = {
        activeTab: 'sinceStart',
        selectionId: null,
        allocationQuota: 0,
        quotaAllocationCount: 0,
        overdeliveryAllocationCount: 0,
        payPerSpotAllocationCount: 0,
        payPerSpotAllocationLimit: 0,
        matchingPublishingRatio: 0,
        matchingSuccessRatio: 0,
        matchingValueOfWonCases: 0,
        lbToggled: false,
        ppsToggled: false,
    }

    TABS = [
        { tabId: 'sinceStart', tabName: 'Since start' },
        { tabId: 'thisPeriod', tabName: 'This period' },
        { tabId: 'thisMonth', tabName: 'This month' },
    ]

    componentDidMount() {
        this.calculateMatchingAllocations(this.state.selectionId)
    }

    componentDidUpdate(prevProps, prevState): void {
        if (prevState.activeTab !== this.state.activeTab || prevState.selectionId !== this.state.selectionId || (
            !prevProps.businessUnits.length &&
            this.props.businessUnits.length ||
            prevProps.businessUnits.length !== this.props.businessUnits.length
        )) {
            this.calculateMatchingAllocations(this.state.selectionId)
        }
    }

    changeTab = activeTab => {
        this.setState({ activeTab })
    }

    changeSelectedId = selectionId => {
        this.setState({ selectionId })
    }

    returnAllocationType = tabName => {
        if (tabName === 'thisPeriod') {
            return 'currentAllocation'
        }
        if (tabName === 'thisMonth') {
            return 'currentMonthAllocation'
        }
        if (tabName === 'sinceStart') {
            return 'accumulatedAllocation'
        }
    }

    returnMatchingBusinessUnits = selectionId => {
        if (selectionId) {
            return this.props.businessUnits.filter(bu => bu.partnerContract && bu.partnerContract.id === selectionId)
        }

        return this.props.businessUnits
    }

    returnAllocationTypeBusinessUnitKpiPrefix = allocationType => {
        if (allocationType === 'currentMonthAllocation') {
            return 'currentMonth'
        }
        if (allocationType === 'currentAllocation') {
            return 'currentPeriod'
        }
        if (allocationType === 'accumulatedAllocation') {
            return 'allTime'
        }
    }

    calculateMatchingAllocations = (selectionId = null) => {
        const matchingBusinessUnits = this.returnMatchingBusinessUnits(selectionId)

        const defaultAcc = {
            quotaAllocationCount: 0,
            overdeliveryAllocationCount: 0,
            allocationQuota: 0,
            payPerSpotAllocationCount: 0,
            payPerSpotAllocationLimit: 0,
        }
        const allocationType = this.returnAllocationType(this.state.activeTab)

        const allocations = matchingBusinessUnits.reduce((acc, cur: BusinessUnit) => {
            if (!cur.isActive && this.state.activeTab !== 'sinceStart') {
                return acc
            }

            if (allocationType === 'currentMonthAllocation') {
                acc.allocationQuota = acc.allocationQuota + cur[allocationType].suggestedAllocationQuota
                acc.quotaAllocationCount = acc.quotaAllocationCount + cur[allocationType].currentMonthAllocationCount
                acc.overdeliveryAllocationCount = acc.overdeliveryAllocationCount + cur[allocationType].overdeliveryAllocationCount
            } else {
                acc.allocationQuota = acc.allocationQuota + cur[allocationType].normalAllocationQuota
                acc.quotaAllocationCount = acc.quotaAllocationCount + cur[allocationType].normalQuotaAllocationCount
                acc.overdeliveryAllocationCount = acc.overdeliveryAllocationCount + cur[allocationType].overdeliveryAllocationCount + cur[allocationType].justInTimeAllocationCount
            }
            acc.payPerSpotAllocationCount = acc.payPerSpotAllocationCount + cur[allocationType].payPerSpotAllocationCount
            acc.payPerSpotAllocationLimit = acc.payPerSpotAllocationLimit + cur[allocationType].payPerSpotAllocationLimit
            return acc
        }, defaultAcc)

        const KpiPrefix = this.returnAllocationTypeBusinessUnitKpiPrefix(allocationType)

        let matchingPublishingRatio
        let matchingSuccessRatio
        if (!this.state.selectionId && this.state.activeTab === 'sinceStart') {
            matchingPublishingRatio = get(this.props.partnerKpi, 'publishingRatio', 0) * 100
            matchingSuccessRatio = get(this.props.partnerKpi, 'successRatio', 0) * 100
        } else {
            matchingPublishingRatio = this.returnWeightedRatio(matchingBusinessUnits, allocationType, 'PublishingRatio')
            matchingSuccessRatio = this.returnWeightedRatio(matchingBusinessUnits, allocationType, 'SuccessRatio')
        }

        const matchingValueOfWonCases = matchingBusinessUnits.reduce((acc, cur: BusinessUnit) => {
            acc = acc + get(cur.kpi, `${KpiPrefix}ValueOfWonCasesExclVat.amount`, 0)
            return acc
        }, 0)

        this.setState({ ...allocations, matchingPublishingRatio, matchingSuccessRatio, matchingValueOfWonCases })
    }

    returnWeightedRatio = (units, allocationType, kpiSuffix) => {
        const kpiName = this.returnAllocationTypeBusinessUnitKpiPrefix(allocationType) + kpiSuffix
        const [ valueTotal, weightTotal ] = units.filter(bu => get(bu.kpi, kpiName)).map(bu => {
            let weight = 0
            if (allocationType === 'currentMonthAllocation') {
                weight = bu[allocationType].suggestedAllocationQuota
            } else {
                weight = bu[allocationType].normalAllocationQuota !== 0 ?
                    bu[allocationType].normalAllocationQuota : bu[allocationType].payPerSpotAllocationLimit
            }

            return [ get(bu.kpi, kpiName) , weight ]
        }).reduce(([ valueSum, weightSum ], [ value, weight ]) =>
            ([ valueSum + value * weight, weightSum + weight ]), [ 0, 0 ])

        return weightTotal > 0 ? valueTotal / weightTotal : 0
    }

    returnDeliveryTrend = contractId => {
        const contract = this.props.activePartnerContracts.find(c => c.id === contractId)
        return get(contract, 'kpi.weightedDeliveryTrend')
    }

    formatPercentage = (float, decimal = false) => {
        if (!float || Number.isNaN(float)) {
            return '--'
        }
        const power = decimal ? 100 : 1
        return Number(float * power).toFixed(2) + '%'
    }

    showDeliveriesInfo = () => {
        const { businessUnits, partnerId } = this.props
        this.props.showModal({
            name: 'businessUnitsDeliveriesModal',
            partnerId,
            businessUnits,
        })
    }

    returnBusinessModels = (bu: BusinessUnit) => {
        const businessModels = []

        if (bu.accumulatedAllocation.allocationQuota > 0) {
            businessModels.push({
                id: 1,
                abbreviation: 'lb',
            })
        }
        if (bu.accumulatedAllocation.payPerSpotAllocationLimit > 0) {
            businessModels.push({
                id: 2,
                abbreviation: 'pps',
            })
        }
        if (bu.wantsExclusiveLeads) {
            businessModels.push({
                id: 3,
                abbreviation: 'excl',
            })
        }

        return businessModels
    }

    periodMonthsDiff = period => {
        const startDate = period.startsAt
        const endDate = period.endsAt

        const periodLength = moment(endDate).diff(moment(startDate), 'days', true)
        const current = moment().diff(moment(startDate), 'days', true)

        return (current / periodLength) * 100
    }

    render() {
        const {
            geoCode,
            lastGivenQuote,
            businessUnits,
            activePartnerContracts,
        } = this.props

        const {
            activeTab,
            selectionId,
            allocationQuota,
            quotaAllocationCount,
            overdeliveryAllocationCount,
            payPerSpotAllocationCount,
            payPerSpotAllocationLimit,
            matchingPublishingRatio,
            matchingSuccessRatio,
            matchingValueOfWonCases,
        } = this.state

        const allocationType = this.returnAllocationType(activeTab)
        const activeContract = selectionId ? activePartnerContracts.find(contract => contract.id === selectionId) : null
        const matchingBusinessUnits = this.returnMatchingBusinessUnits(selectionId)

        return <Card className="statisticsCard">
            <Card.Header padding="0 20px">
                <Tabs
                    tabs={this.TABS}
                    selectedTab={activeTab}
                    changeTab={this.changeTab}
                />
            </Card.Header>
            <Card.Content padding="20px 20px 0">
                <Flex modifiers={[ 'grow' ]}>
                    <div>
                        <Flex modifiers="alignCenter">
                            <Card.Text style={{ marginRight: '16px' }}>{this.formatPercentage(matchingPublishingRatio)}</Card.Text>
                            <OverlayTrigger
                                delay={0}
                                placement="right"
                                overlay={<Tooltip id="tooltipPublishingRatio" placement="right" className="in">{tooltips.publishingRatio}</Tooltip>}
                                animation={false}
                                delayHide={0}
                            >
                                <TooltipIcon>
                                    <span style={{ fontSize: '10px' }}>?</span>
                                </TooltipIcon>
                            </OverlayTrigger>
                        </Flex>
                        <Card.Label modifiers="mB_0">Publishing ratio</Card.Label>
                    </div>

                    <div>
                        <Flex modifiers="alignCenter">
                            <Card.Text style={{ marginRight: '16px' }}>
                                {this.formatPercentage(matchingSuccessRatio)}
                            </Card.Text>
                            <OverlayTrigger
                                delay={0}
                                placement="right"
                                overlay={<Tooltip id="tooltipSuccessRatio" placement="right" className="in">{tooltips.successRatio}</Tooltip>}
                                animation={false}
                                delayHide={0}
                            >
                                <TooltipIcon>
                                    <span style={{ fontSize: '10px' }}>?</span>
                                </TooltipIcon>
                            </OverlayTrigger>
                        </Flex>
                        <Card.Label modifiers="mB_0">Success ratio</Card.Label>
                    </div>
                    <div>
                        <Flex modifiers="alignCenter">
                            <Card.Text style={{ marginRight: '16px' }}>
                                <FormattedAmount
                                    amount={{
                                        amount: Number(matchingValueOfWonCases.toFixed(2)),
                                        currency: config.currency[geoCode],
                                    }}
                                />
                            </Card.Text>
                            <OverlayTrigger
                                delay={0}
                                placement="right"
                                overlay={<Tooltip id="tooltipWonCases" placement="right" className="in">{tooltips.wonCases}</Tooltip>}
                                animation={false}
                                delayHide={0}
                            >
                                <TooltipIcon>
                                    <span style={{ fontSize: '10px' }}>?</span>
                                </TooltipIcon>
                            </OverlayTrigger>
                        </Flex>
                        <Card.Label modifiers="mB_0">Value of won cases</Card.Label>
                    </div>
                    <div>
                        <Flex modifiers="alignCenter">
                            <Card.Text style={{ marginRight: '16px' }}>
                                {(lastGivenQuote && lastGivenQuote)
                                    ? <span style={{ marginRight: '16px' }}>{moment(lastGivenQuote.createdAt).format('D. MMM YYYY')}</span>
                                    : <span style={{ marginRight: '16px' }}>--</span>}
                            </Card.Text>
                            <OverlayTrigger
                                delay={0}
                                placement="left"
                                overlay={<Tooltip id="tooltipLastQuote" placement="left" className="in">{tooltips.lastQuote}</Tooltip>}
                                animation={false}
                                delayHide={0}
                            >
                                <TooltipIcon>
                                    <span style={{ fontSize: '10px' }}>?</span>
                                </TooltipIcon>
                            </OverlayTrigger>
                        </Flex>
                        <Card.Label modifiers="mB_0">Last quote</Card.Label>
                    </div>
                </Flex>
                <Card.Line modifiers={[ 'mT_2', 'mB_1', 'cardWidth' ]}/>
                <div style={{ overflowX: 'auto', width: '100%' }}>
                    <Flex
                        modifiers={[ 'alignCenter' ]}
                        style={{ width: 'max-content' }}
                    >
                        <NestedTab
                            active={!selectionId}
                            onClick={() => this.changeSelectedId(null)}
                        >
                            All
                        </NestedTab>
                        {Boolean(activePartnerContracts.length) && activePartnerContracts.map(contract => <NestedTab
                            key={contract.id}
                            active={contract.id === selectionId}
                            onClick={() => this.changeSelectedId(contract.id)}
                            style={{ width: 'max-content' }}
                        >
                            <span>
                                contract #
                                {contract.id}
                            </span>
                        </NestedTab>)}
                    </Flex>
                </div>
                <Card.Line modifiers={[ 'mY_1', 'cardWidth' ]}/>
                {activeTab === 'thisPeriod' && <Fragment>
                    <Card.Content modifiers="p_3">
                        <Flex modifiers={[ 'mB_1', 'justifySpaceBetween' ]}>
                            <Card.Label>Current period</Card.Label>
                            {selectionId && <span>
                                <FormattedDate
                                    date={activeContract
                                        ? activeContract.currentPartnerContractPeriod.startsAt
                                        : moment().startOf('month')}
                                    format="date"
                                />
                                {' - '}
                                <FormattedDate
                                    date={activeContract
                                        ? activeContract.currentPartnerContractPeriod.endsAt
                                        : moment().endOf('month')}
                                    format="date"
                                />
                            </span>}
                            {!selectionId && <span>
                                <Label bsStyle="warning">Aggregated</Label>
                            </span>}
                        </Flex>
                        <Flex modifiers={[ 'mB_1', 'justifySpaceBetween' ]}>
                            <Card.Label>Delivery trend</Card.Label>
                            {selectionId && <span>
                                {this.formatPercentage(this.returnDeliveryTrend(selectionId), true)}
                            </span>}
                            {!selectionId && <span>
                                <Label bsStyle="warning">Aggregated</Label>
                            </span>}
                        </Flex>
                        <Card.Line modifiers={[ 'mB_2', 'pX_3' ]} />
                        <DeliveryProgressBar
                            deliveryCount={quotaAllocationCount}
                            deliveryQuota={allocationQuota}
                            overDeliveryCount={overdeliveryAllocationCount}
                            tooltipId="tooltipLbCurrent"
                            tooltipText={tooltips.lbCurrentPeriod}
                            deliveryPeriod={activeContract ? activeContract.currentPartnerContractPeriod : selectionId ? {
                                startsAt: moment().startOf('month').toLocaleString(),
                                endsAt: moment().endOf('month').toLocaleString(),
                            } : null}
                            deliveryType="lb"
                            periodDeliveryProgress={this.periodMonthsDiff}
                            businessUnits={matchingBusinessUnits}
                            allocationType={allocationType}
                        />
                        <Card.Line modifiers={[ 'mB_2', 'pX_3' ]} />
                        <DeliveryProgressBar
                            deliveryCount={payPerSpotAllocationCount}
                            deliveryQuota={payPerSpotAllocationLimit}
                            tooltipId="tooltipPps"
                            tooltipText={tooltips.pps}
                            deliveryPeriod={activeContract ? activeContract.currentPartnerContractPeriod : selectionId ? {
                                startsAt: moment().startOf('month').toLocaleString(),
                                endsAt: moment().endOf('month').toLocaleString(),
                            } : null}
                            deliveryType="pps"
                            periodDeliveryProgress={this.periodMonthsDiff}
                            businessUnits={matchingBusinessUnits}
                            allocationType={allocationType}
                        />
                    </Card.Content>
                </Fragment>}

                {activeTab === 'thisMonth' && <Fragment>
                    <Card.Content modifiers="p_3">
                        <Flex modifiers={[ 'mB_1', 'justifySpaceBetween' ]}>
                            <Card.Label>Current month</Card.Label>
                            <span>
                                <FormattedDate
                                    date={moment().startOf('month')}
                                    format="date"
                                />
                                {' - '}
                                <FormattedDate
                                    date={moment().endOf('month')}
                                    format="date"
                                />
                            </span>
                        </Flex>
                        <Card.Line modifiers={[ 'mB_2', 'pX_3' ]} />
                        <DeliveryProgressBar
                            deliveryCount={quotaAllocationCount}
                            deliveryQuota={allocationQuota}
                            overDeliveryCount={overdeliveryAllocationCount}
                            tooltipId="tooltipLbCurrentMonth"
                            tooltipText={tooltips.lbCurrentMonth}
                            deliveryPeriod={{
                                startsAt: moment().startOf('month').toLocaleString(),
                                endsAt: moment().endOf('month').toLocaleString(),
                            }}
                            deliveryType="lb"
                            periodDeliveryProgress={this.periodMonthsDiff}
                            businessUnits={matchingBusinessUnits}
                            allocationType={allocationType}
                        />
                        <Card.Line modifiers={[ 'mB_2', 'pX_3' ]} />
                        <DeliveryProgressBar
                            deliveryCount={payPerSpotAllocationCount}
                            deliveryQuota={payPerSpotAllocationLimit}
                            tooltipId="tooltipPps"
                            tooltipText={tooltips.pps}
                            deliveryPeriod={{
                                startsAt: moment().startOf('month').toLocaleString(),
                                endsAt: moment().endOf('month').toLocaleString(),
                            }}
                            deliveryType="pps"
                            periodDeliveryProgress={this.periodMonthsDiff}
                            businessUnits={matchingBusinessUnits}
                            allocationType={allocationType}
                        />
                    </Card.Content>
                </Fragment>}

                {activeTab === 'sinceStart' && <Card.Content>
                    <DeliveryProgressBar
                        deliveryCount={quotaAllocationCount}
                        deliveryQuota={allocationQuota}
                        overDeliveryCount={overdeliveryAllocationCount}
                        tooltipId="tooltipLbSinceStart"
                        tooltipText={tooltips.lbSinceStart}
                        deliveryType="lb"
                        businessUnits={matchingBusinessUnits}
                        allocationType={allocationType}
                    />
                    <Card.Line modifiers={[ 'mB_2', 'pX_3' ]}/>
                    <DeliveryProgressBar
                        deliveryCount={payPerSpotAllocationCount}
                        deliveryQuota={payPerSpotAllocationLimit}
                        tooltipId="tooltipPps"
                        tooltipText={tooltips.pps}
                        deliveryType="pps"
                        businessUnits={matchingBusinessUnits}
                        allocationType={allocationType}
                    />
                </Card.Content>}
                <Card.Line modifiers="cardWidth"/>
            </Card.Content>
            <Card.Content>
                <Card.Label modifiers="mB_0">Business units</Card.Label>
            </Card.Content>
            <Card.Content modifiers={[ 'maxHeight', 'scroll', 'p_0' ]} scrollHeight="calc(100% - 360px);">
                <Table>
                    <Header>
                        <Column>Segmentation</Column>
                        <Column>Status</Column>
                    </Header>
                    <tbody>
                        {businessUnits.sort((a, b) => b.isActive ? 1 : -1).map(bu => <BusinessUnitRow
                            key={'statistics_ ' + bu.id}
                            businessUnit={bu}
                            formatPeriodToProgress={this.periodMonthsDiff}
                            returnBusinessModels={this.returnBusinessModels(bu)}
                            allocationType={allocationType}
                        />)}
                    </tbody>
                </Table>
            </Card.Content>
            <Flex modifiers={[ 'justifyCenter', 'p_1' ]}>
                <Button
                    onClick={this.showDeliveriesInfo}
                    modifiers="primary"
                >
                    Deliveries info
                </Button>
            </Flex>
        </Card>
    }
}
