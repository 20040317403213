import React, { memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import { Flex } from '../../Flex'
import { RadioBox } from '../../../blocks/RadioBox'
import ControlLabel from '../../text/MetaLabel'
import Error from '../Error'
import { WrappedFieldInputProps, WrappedFieldProps } from 'redux-form'

interface RadioOptionModel {
    name?: string
    title?: string
    id: any
}

interface RadioInputFieldModel {
    option: RadioOptionModel
    input: WrappedFieldInputProps
}
export const RadioInputField = memo(({
    option,
    input,
}: RadioInputFieldModel) =>
    <RadioBox
        {...input}
        id={input.name + '_' + option.id}
        value={option.id}
        checked={input.value == option.id}
    />
)

interface RadioGroupModel extends WrappedFieldProps {
    removeClassFormGroup?: boolean
    shouldMatch?: string
    className?: string
    label?: string
    hideLabel?: boolean
    options: RadioOptionModel[]
}
export default memo(({
    removeClassFormGroup,
    className,
    hideLabel,
    options,
    label,
    input,
    meta,
}: RadioGroupModel) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={meta.touched && (meta.error || meta.warning) ? 'error' : null }
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        {options.map(option =>
            <Flex
                key={input.name + '_' + option.id}
                modifiers={[ 'alignCenter' ]}
                onClick={() => input.onChange(option.id)}
            >
                <RadioInputField
                    option={option}
                    input={input}
                />
                {hideLabel || <RadioBox.Label>{option.name || option.title}</RadioBox.Label> }
            </Flex>
        )}
        <Error meta={meta}/>
    </FormGroup>
)
