import React, { memo, PureComponent } from 'react'
import { DropdownButton } from 'react-bootstrap'
import { Button } from '../../Button'
import { Aggregation } from '../../../../reducers/types/entities/Aggregation'

interface FilterItemModel {
    hideZero?: boolean
    onChange
    checked
    facets
    item
    labelPropName?:string
    type?
}

const FilterItem = memo(({
    labelPropName,
    hideZero,
    onChange,
    checked,
    facets,
    item,
    type,
}: FilterItemModel) => {
    let facetCount =  []

    if(facets) facetCount = facets.items ? facets.items.filter(fa => fa.key == item.id) : []

    if (facetCount.length > 0 && facetCount[0].hits === 0 || (hideZero && !facetCount.length)) return null

    return <div className="select-item">
        <label className="item">
            <input onChange={onChange}
                checked={checked}
                type={type || 'checkbox'}
                value={item.id}
            />
            { item.image && <img src={item.image} className="icon" /> }
            { item.cssClass && <span className={'smile ' + item.cssClass}><i/></span> }
            { item[labelPropName]}
            {' '}
            { Boolean(facetCount.length) &&<span className="text-faded">
                (
                { facetCount[0].hits }
                )
            </span> }
        </label>
    </div>
})

interface DropdownFilterModel {
    type?: 'radio' | 'checkbox' | 'text' | undefined
    selectedIds: string[]
    labelPropName?: string
    hideZero?: boolean
    search?: boolean
    clear?: boolean
    facets?: Aggregation
    label: string
    name: string
    items: any[]
    onChange?
    onItemChange?
}

class DropdownFilter extends PureComponent<DropdownFilterModel, {filterText: string, open: boolean}> {
    state = {
        open: false,
        filterText: '',
    }

    onCheckboxChange = event => {
        const { selectedIds, type } = this.props

        const target = event.target

        let selected = [ ...selectedIds ]

        if (type === 'radio') selected = target.value
        else if (target.checked) selected.push(target.value)
        else selected = selected.filter(val => val != target.value)

        if (this.props.onChange) {
            this.props.onChange(selected)
        }

        if (this.props.onItemChange) {
            this.props.onItemChange(target.value)
        }
    }

    updateFilterText = event => {
        this.setState({ filterText: event.target.value })
    }

    render() {
        const {
            facets,
            label,
            search,
            clear,
            type,
            onChange,
            hideZero,
            selectedIds,
            items,
            labelPropName,
        } = this.props

        const { filterText } = this.state
        let selectedItems = items.filter(item => selectedIds.includes(String(item.id)))
        let notSelectedItems = items.filter(item => !selectedIds.includes(String(item.id)))
        const itemNameProp = labelPropName || 'name'
        if (filterText) {
            selectedItems = selectedItems.filter(item =>
                item[itemNameProp].toLowerCase().includes(filterText.toLowerCase())
            )
            notSelectedItems = notSelectedItems.filter(item =>
                item[itemNameProp].toLowerCase().includes(filterText.toLowerCase())
            )
        }

        let actualLabel
        if (selectedItems.length) {
            actualLabel = selectedItems.map(item => item[itemNameProp]).join(', ')
            if (actualLabel.length > 30) {
                actualLabel = actualLabel.substr(0, 40) + '...'
            }
        } else {
            actualLabel = label
        }

        return <DropdownButton
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            bsStyle="default"
            title={actualLabel}
            key="employee"
            id="dropdown-1"
        >
            {selectedItems.length > 1 && clear
                ? <div className="clear-selection">
                    <Button
                        modifiers={[ 'action', 'fullWidth' ]}
                        onClick={onChange.bind(undefined, [])}
                    >
                        Clear selected
                    </Button>
                </div>
                : null
            }
            {search && <div className="search">
                <input type="text"
                    className="form-control partner-list-filter"
                    value={filterText}
                    onChange={this.updateFilterText}
                    placeholder="Filter..."
                />
            </div>}
            {selectedItems.length > 0 && <div className="itemlist selected-items clearfix">
                {selectedItems.map(item =>
                    <FilterItem
                        labelPropName={itemNameProp}
                        key={item.id}
                        type={type}
                        facets={facets}
                        item={item}
                        checked={true}
                        onChange={this.onCheckboxChange}
                    />
                )}
            </div>}
            {notSelectedItems && <div className="itemlist available-items">
                {notSelectedItems.map(item =>
                    <FilterItem
                        labelPropName={itemNameProp}
                        key={item.id}
                        type={type}
                        facets={facets}
                        item={item}
                        checked={false}
                        hideZero={hideZero}
                        onChange={this.onCheckboxChange}
                    />
                )}
            </div>}
        </DropdownButton>
    }
}

export default DropdownFilter
