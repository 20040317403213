import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { PROFICIENCY } from '../../utils/sharedProps'
const Item = styled.div`
    border-left: 7px solid #E1E7E9;
    padding: 0 20px;
    margin-bottom: 40px;
`

const Edit = styled.span`
    color: #7E7E7E;
    font-size: 16px;
    cursor: pointer;
    float: right;
    &:hover {
        color: red;
    }
`
const OBJECT_PROFICIENCY = PROFICIENCY.reduce((cur, next) => ({ ...cur, [next.value]: next.title }), {})

interface OwnPropsModel {
    lang: {
        id: string
        name: string
        proficiency: string
    }
    onDelete(id: string)
}

export default class LanguageItem extends PureComponent<OwnPropsModel> {
    deleteLanguage = () => this.props.onDelete(this.props.lang.id)

    render() {
        const { lang } = this.props
        return (
            <Item>
                <Edit onClick={this.deleteLanguage}>
                    <i className="mdi mdi-trash-can-outline"/>
                </Edit>
                <h4>{lang.name}</h4>
                <span>{OBJECT_PROFICIENCY[lang.proficiency]}</span>
                <br/>
            </Item>
        )
    }
}

