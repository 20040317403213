import React, { memo, PureComponent } from 'react'
import moment from 'moment'
import { replace } from 'connected-react-router'
import config from '../../config'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { Card } from '../blocks/Card'
import { businessUnitListSelector } from '../../selectors/businessUnits'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import ProviderTable from '../blocks/ProviderTable'
import {
    Column,
    LinkCell,
    Cell,
    EmployeeCell,
    LocationFilter,
    DropdownFilter,
    SortColumn, TextFilter, AmountCell,
} from '../elements/table'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { connect } from '../../utils/connect'
import { Button } from '../elements/Button'
import { showModal } from '../../actions/modal'
import { Employee } from '../../reducers/types/entities/Employee'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { LeadType } from '../../reducers/types/entities/LeadType'
import { Sector } from '../../reducers/types/entities/Sector'
import { Industry } from '../../reducers/types/entities/Industry'
import { getIndustries } from '../../state/industries/actions'
import { getLeadTypes } from '../../state/leadTypes/actions'
import { getSectors } from '../../state/sectors/actions'
import { allSectorsSelector } from '../../state/sectors/selectors'
import { allIndustriesSelector } from '../../state/industries/selectors'
import { allLeadTypesSelector } from '../../state/leadTypes/selectors'
import BusinessModelLabel from '../elements/BusinessModelLabel'

interface BusinessUnitRowModel {
    rowData?: BusinessUnit
    showActionModal
}

const BusinessUnitRow = memo((props: BusinessUnitRowModel) => {
    const businessUnit = props.rowData

    const { showActionModal, ...pathProps } = props

    const getForecast = (quota, count) =>
        (Math.round(((count / quota) / (moment().date() / moment().daysInMonth())) * 100) + '%')

    const hasPpsModel = (bu: BusinessUnit): boolean => {
        return Boolean(bu.businessModels.find(el => el.abbreviation === 'pps'))
    }

    const calculateData = (activePeriod: string, bu: BusinessUnit) => {
        const allocationType = activePeriod === 'currentMonth'
            ? 'currentAllocation'
            : 'accumulatedAllocation'

        let lbLimit = bu.currentAllocation.allocationQuotaPerRefill
        if (activePeriod === 'sinceStart') lbLimit = bu.accumulatedAllocation.allocationQuota
        const lbCount = bu[allocationType].normalQuotaAllocationCount
        const overdeliveryCount = bu[allocationType].justInTimeAllocationCount + bu[allocationType].overdeliveryAllocationCount

        const ppsCount = bu[allocationType].payPerSpotAllocationCount
        const ppsLimit = bu[allocationType].payPerSpotAllocationLimit

        return { lbLimit, lbCount, overdeliveryCount, ppsCount, ppsLimit }
    }

    const fadeOutLabel = (abbreviation, currentMonth, sinceStart) => {
        switch (abbreviation) {
            case 'lb':
                return sinceStart.lbCount + sinceStart.lbLimit === 0 ||
                    !(sinceStart.lbCount / sinceStart.lbLimit < 1 &&
                        (currentMonth.ppsCount + currentMonth.ppsLimit === 0 ||
                            currentMonth.ppsCount / currentMonth.ppsLimit <= 1))
            case 'pps':
                return currentMonth.ppsCount + currentMonth.ppsLimit === 0 ||
                    !(sinceStart.lbCount / sinceStart.lbLimit >= 1 &&
                        (currentMonth.ppsCount + currentMonth.ppsLimit === 0 ||
                            currentMonth.ppsCount / currentMonth.ppsLimit < 1))
            default:
                return true
        }
    }

    const currentMonth = calculateData('currentMonth', businessUnit)

    const sinceStart = calculateData('sinceStart', businessUnit)

    return <ProviderTable.Row {...pathProps}>
        <LinkCell
            to={`/partners/${businessUnit.partnerId}`}
            onClick={e => e.stopPropagation()}
        >
            {businessUnit.partnerId}
        </LinkCell>
        <Cell>{businessUnit.partner.companyName}</Cell>
        <Cell>{businessUnit.id}</Cell>
        <Cell>{businessUnit.displayName}</Cell>
        <Cell>
            {businessUnit.geo.zipCode} 
            {' '}
            {businessUnit.geo.cityName}
        </Cell>
        <EmployeeCell portrait={false} employee={businessUnit.employee} />
        <Cell>
            {businessUnit.businessModels.map(el =>
                <BusinessModelLabel
                    key={'businessModels_' + el.id}
                    abbreviation={el.abbreviation}
                    fadeOut={fadeOutLabel(el.abbreviation, currentMonth, sinceStart)}
                >
                    {el.abbreviation}
                </BusinessModelLabel>
            )}
        </Cell>
        <Cell>
            {businessUnit.currentAllocation.normalQuotaAllocationCount + ' / ' +
            businessUnit.currentAllocation.allocationQuotaPerRefill}
        </Cell>
        <Cell>{businessUnit.accumulatedAllocation.normalQuotaAllocationCount + ' / ' + businessUnit.accumulatedAllocation.allocationQuota}</Cell>
        {currentMonth.overdeliveryCount ? <Cell>{currentMonth.overdeliveryCount}</Cell> : <Cell/>}
        <Cell>
            {businessUnit.currentAllocation.allocationQuotaPerRefill && getForecast(
                businessUnit.currentAllocation.allocationQuotaPerRefill,
                businessUnit.currentAllocation.normalQuotaAllocationCount
            )}
        </Cell>
        {hasPpsModel ? <Cell>
            {currentMonth.ppsCount}
            {' '}
            / 
            {' '}
            {currentMonth.ppsLimit}
        </Cell> : <Cell/>}
        {hasPpsModel ? <AmountCell amount={businessUnit.currentAllocation.payPerSpotAllocationPrice}/> : <Cell/>}
        <Cell>{businessUnit.geoRange ? businessUnit.geoRange : (businessUnit.geoRange === 0 ? 'Unlimited' : '')}</Cell>
        <Cell>
            <ul>
                {businessUnit.desiredTypes.map(el => <li key={'desired_types_' + el.id}>{el.title}</li>)}
            </ul>
        </Cell>
        <Cell>{businessUnit.desiredSectors.map(el =>  el.displayName).join(', ')}</Cell>
        <Cell>{businessUnit.desiredIndustries.map(el =>  el.name).join(', ')}</Cell>
        <Cell>
            {businessUnit.desiredRevenueRange && businessUnit.desiredRevenueRange.minimumRevenue  + ' - ' +
            businessUnit.desiredRevenueRange.maximumRevenue}
        </Cell>
        <Cell>
            <Button
                onClick={() => showActionModal(businessUnit)}
                modifiers={[ 'primary', 'btnIcon' ]}
                className="mdi mdi-wrench"
            />
        </Cell>
    </ProviderTable.Row>
})

interface Criteria {
    visibleColumns: string[]
    assignedEmployeeId: string[]
    sectorId: string[]
    clientTypeId: string[]
    industryId: string[]
    geoDistance: string
    locationId: string
    geoPoint: string
    geoRect: string
    sort: string
    revenueAmount: string
}

interface BusinessUnitListStateProps {
    units: BusinessUnit[]
    employees: Employee[]
    leadTypes: LeadType[]
    sectors: Sector[]
    industries: Industry[]
    totalItems: number
    criteria: Criteria
}
interface BusinessUnitListDispatchProps {
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getIndustries: typeof getIndustries
    getLeadTypes: typeof getLeadTypes
    getSectors: typeof getSectors
    showModal: typeof showModal
    replace: typeof replace
}

interface BusinessUnitPathProps {
    location: {
        query: Criteria
    }
}

type BusinessUnitListModel = BusinessUnitListStateProps & BusinessUnitListDispatchProps & BusinessUnitPathProps

@connect<BusinessUnitListStateProps, BusinessUnitListDispatchProps, BusinessUnitPathProps>(
    (state, { location: { query } }) => ({
        units: businessUnitListSelector(state),
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        totalItems: state.pages.businessUnits.pagination.total,
        sectors: allSectorsSelector(state),
        leadTypes: allLeadTypesSelector(state),
        industries: allIndustriesSelector(state),
        criteria: {
            visibleColumns: splitFilterString(
                query.visibleColumns || [ 'id', 'company_name', 'partner_id', 'business_unit_name', 'location',
                    'employee_name', 'allocation_fulfillment_percentage', 'allocation_fulfillment_forecast_percentage',
                    'pay_per_spot_allocation_fulfillment_percentage', 'pay_per_spot_allocation_fee', 'action' ]
            ),
            assignedEmployeeId: splitFilterString(query.assignedEmployeeId),
            sectorId: splitFilterString(query.sectorId),
            clientTypeId: splitFilterString(query.clientTypeId),
            industryId: splitFilterString(query.industryId),
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
            geoPoint: query.geoPoint || '',
            geoRect: query.geoRect || '',
            sort: query.sort || '',
            revenueAmount: query.revenueAmount || '',
        },
    }),
    {
        getPartnerBusinessUnits,
        getIndustries,
        getLeadTypes,
        getSectors,
        showModal,
        replace,
    }
)
export default class BusinessUnitListPage extends PureComponent<BusinessUnitListModel> {
    constructor(props) {
        super(props)
        this.showActionModal = this.showActionModal.bind(this)
    }
    componentDidMount() {
        this.props.getIndustries({ geoCode: config.geoCode })
        this.props.getLeadTypes(config.geoCode)
        this.props.getSectors({ geoCode: config.geoCode })
    }

    retrieveData = (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
            limit: 50,
        })

        return this.props.getPartnerBusinessUnits(criteria, append)
    }

    showActionModal(businessUnit) {
        this.props.showModal({
            name: 'businessUnitActionModal',
            businessUnit,
        })
    }

    render() {
        const {
            units,
            criteria,
            totalItems,
            employees,
            sectors,
            leadTypes,
            industries,
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    Business Units 
                    {' '}
                    <span className="text-muted">
                        (
                        {units.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="businessUnits"
                    retrieveData={this.retrieveData}
                    criteria={this.props.criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.assignedEmployeeId}
                            items={employees}
                            name="assignedEmployeeId"
                            label="Employee"
                        />
                        <DropdownFilter
                            selectedIds={criteria.sectorId}
                            items={sectors}
                            labelPropName="displayName"
                            name="sectorId"
                            label="Sectors"
                        />
                        <DropdownFilter
                            selectedIds={criteria.clientTypeId}
                            items={leadTypes}
                            labelPropName="title"
                            name="clientTypeId"
                            label="Lead types"
                        />
                        <DropdownFilter
                            selectedIds={criteria.industryId}
                            items={industries}
                            name="industryId"
                            label="Industries"
                        />
                        <LocationFilter
                            label="Location"
                            name="location"
                            locationId={criteria.locationId}
                            geoDistance={criteria.geoDistance}
                        />
                        <TextFilter
                            value={criteria.revenueAmount}
                            name="revenueAmount"
                            label="Revenue amount ..."
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="partner_id">Partner ID</Column>
                            <Column name="company_name">Company name</Column>
                            <Column name="id">ID</Column>
                            <Column name="business_unit_name">Business unit name</Column>
                            <Column name="location">Location</Column>
                            <Column name="employee_name">Employee</Column>
                            <Column name="business_model">Models</Column>
                            <SortColumn name="allocation_fulfillment_percentage">LB fulfilment this month</SortColumn>
                            <SortColumn name="allocation_fulfillment_since_start_percentage">LB fulfilment since start</SortColumn>
                            <Column name="overdelivery_allocation_count">Overdelivery count</Column>
                            <SortColumn name="allocation_fulfillment_forecast_percentage">LB Forecast this month</SortColumn>
                            <SortColumn name="pay_per_spot_allocation_fulfillment_percentage">PPS delivery this month</SortColumn>
                            <Column name="pay_per_spot_allocation_fee">PPS fee</Column>
                            <Column name="desired_geo">Desired geo</Column>
                            <Column name="desired_lead_types">Desired lead types</Column>
                            <Column name="desired_sectors">Desired sectors</Column>
                            <Column name="desired_industries">Desired industries</Column>
                            <Column name="desired_revenues">Desired revenue</Column>
                            <Column name="action">Action</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={units}
                            noDataText="We couldn't find any business units matching your selection."
                        >
                            <BusinessUnitRow showActionModal={this.showActionModal}/>
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
