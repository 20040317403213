import React, { PureComponent, memo, ComponentType } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { push } from 'connected-react-router'
import CardText from '../../elements/text/CardText'
import ButtonWithIcon from '../../blocks/ButtonWithIcon'
import {
    ActionButtonBlock,
    Button, ButtonContainer,
    ButtonGrid,
    ButtonGroup,
    ButtonModifiersModel,
    ButtonPopover
} from '../../elements/Button'
import LeadCallButton from '../../elements/LeadCallButton'
import LoadingBar from '../../elements/LoadingBar'
import { leadClientSelector } from '../../../selectors/client'
import { leadQuotesSelector } from '../../../selectors/lead'
import { getClient } from '../../../actions/clients'
import { leadActionDiscard } from '../../../actions/leads'
import { showModal } from '../../../actions/modal'
import { createCallLeadAction, createLeadNoAnswerAction } from '../../../actions/actions'
import { connect } from '../../../utils/connect'
import { Lead } from '../../../reducers/types/entities/Lead'
import { Client } from '../../../reducers/types/entities/Client'
import { Quote } from '../../../reducers/types/entities/Quote'
import { returnLocalTimezone } from '../../../utils'
import LoaderComponent from '../../elements/LoaderComponent'

const MARK_AS_MODIFIERS: ButtonModifiersModel = [ 'danger', 'btnBlock', 'fullWidth' ]

interface MakeAsOverlayContentModel {
    markAsAction(action: string): void
}

const MakeAsOverlayContent = memo(({
    markAsAction
}: MakeAsOverlayContentModel) =>
    <div>
        <Button
            onClick={() => markAsAction('test')}
            modifiers={MARK_AS_MODIFIERS}
        >
            Test
        </Button>
        <br />
        <Button
            onClick={() => markAsAction('duplicate')}
            modifiers={MARK_AS_MODIFIERS}
        >
            Duplicate
        </Button>
        <br />
        <Button
            onClick={() => markAsAction('spam')}
            modifiers={MARK_AS_MODIFIERS}
        >
            Spam
        </Button>
        <br />
        <Button
            onClick={() => markAsAction('not_serious')}
            modifiers={MARK_AS_MODIFIERS}
        >
            Not serious
        </Button>
        <br />
        <Button
            onClick={() => markAsAction('untreated')}
            modifiers={MARK_AS_MODIFIERS}
        >
            Untreated
        </Button>
        <br />
        <Button
            onClick={() => markAsAction('send_to_advisor')}
            modifiers={MARK_AS_MODIFIERS}
        >
            Send to advisor
        </Button>
    </div>
)

interface TriggerModel {
    children
    overlayContent
    overlayTitle: string
}

const Trigger = memo(({
    children,
    overlayContent,
    overlayTitle,
}: TriggerModel) => {
    let overlayTrigger
    return <OverlayTrigger
        trigger="click"
        placement="top"
        ref={ref => {
            overlayTrigger = ref
        }}
        overlay={
            <ButtonPopover id="popover">
                <CardText modifiers="m_0">
                    <strong>{overlayTitle}</strong>
                    <button
                        type="button"
                        className="close"
                        onClick={() => overlayTrigger.hide()}
                    >
                        <span>×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </CardText>
                <br />
                <div onClick={() => overlayTrigger.hide()}>
                    {overlayContent}
                </div>
            </ButtonPopover>
        }
    >
        {children}
    </OverlayTrigger>
})

interface LeadActionBarStateProps {
    isServiceFee: boolean
    lead: Lead
    client: Client
    isLoading: boolean
    leadQuotes: Quote[]
    leadsUnmatchPermission
    acceptQuotePermission
    leadsAdjustServiceFeePermission
    unrejectAllQuotesPermission
    leadsQuotesCreatePermission
    leadsQuotesSetReservationExpiryDatePermission
}
interface LeadActionBarDispatchProps {
    createLeadNoAnswerAction: typeof createLeadNoAnswerAction
    createCallLeadAction: typeof createCallLeadAction
    leadActionDiscard: typeof leadActionDiscard
    getClient: typeof getClient
    showModal: typeof showModal
    push: typeof push
}
interface LeadActionBarPathProps {
    leadId: number
    showPhoneNumber: boolean
    saveAction
    numberOfBusinessUnitsForLead: number
    isLoadingBusinessUnits: boolean
    isAutoAllocationEnabled: boolean
    showCallNumberAction(): void
    handleAdjustAmount(): void
}

type LeadActionBarModel = LeadActionBarStateProps & LeadActionBarDispatchProps & LeadActionBarPathProps

@connect<LeadActionBarStateProps, LeadActionBarDispatchProps, LeadActionBarPathProps>(
    (state, { leadId }) => ({
        isServiceFee: state.entities.leads[leadId].isServiceFee,
        lead: state.entities.leads[leadId],
        client: leadClientSelector(state, leadId),
        isLoading: state.pages.leadValidation.isLoading ||
            state.pages.leadValidation.isValidating,
        leadQuotes: leadQuotesSelector(state, leadId),
        leadsUnmatchPermission: state.entities.permissions.leads_action_unmatch,
        acceptQuotePermission: state.entities.permissions.leads_action_accept_quote,
        leadsAdjustServiceFeePermission: state.entities.permissions.leads_adjust_service_fee,
        unrejectAllQuotesPermission: state.entities.permissions.leads_action_unreject_all_quotes,
        leadsQuotesCreatePermission: state.entities.permissions.leads_quotes_create,
        leadsQuotesSetReservationExpiryDatePermission: state.entities.permissions.leads_quotes_set_reservation_expiry_date,
    }),
    {
        createLeadNoAnswerAction,
        createCallLeadAction,
        leadActionDiscard,
        getClient,
        push,
        showModal,
    }
)
class LeadActionBarCard extends PureComponent<LeadActionBarModel> {
    actionModifier: ButtonModifiersModel = [ 'action', 'fullWidth' ]
    dangerModifier: ButtonModifiersModel = [ 'danger', 'fullWidth' ]
    secondaryModifier: ButtonModifiersModel = [ 'secondary', 'fullWidth' ]

    componentDidMount() {
        this.props.getClient(this.props.lead.client.id)
    }

    setModalProps = data => {
        this.props.showModal({
            ...data,
            leadId: this.props.lead.id,
        })
    }

    closeLeadAsCompleted = () => this.setModalProps({ name: 'leadMarkAsCompleteModal' })

    unrejectAllQuotes = () => this.setModalProps({ name: 'unrejectQuotesModal' })

    postponeLead = () => this.setModalProps({ name: 'postponeModal' })

    // TODO: Doesn't support exclusive leads
    goToAllocationPage = async (e) => {
        await this.props.saveAction(e, 'save')
        await this.props.push(`/leads/${this.props.leadId}/send-email-regions`)
    }

    rejectAllQuotes = () => this.setModalProps({
        name: 'rejectQuotesModal',
        quotes: this.props.leadQuotes,
    })

    acceptQuote= () => this.setModalProps({
        name: 'acceptQuoteModal',
        quotes: this.props.leadQuotes.filter(el => el.publishedAt),
    })

    unmatchLead = () => this.setModalProps({
        name: 'leadUnmatchConfirm',
        quoteId: this.props.leadQuotes.find(quote => quote.status === 'accepted').id,
    })

    queueLeadFeedbackEmail = () => this.setModalProps({
        name: 'leadActionQueueFeedbackEmail',
        leadId: this.props.lead.id,
    })

    markAsAction = async discardStatus => {
        const modalText = {
            duplicate: {
                headerText: 'Mark as duplicate',
                bodyText: 'Please provide a reason for why you mark this lead as duplicate:',
            },
            not_serious: {
                headerText: 'Mark as not serious',
                bodyText: 'Please provide a reason for why you consider this lead not serious:',
            },
            send_to_advisor: {
                headerText: 'Mark as send to advisor',
                bodyText: 'Please provide a reason for why you send this lead to an advisor instead of validating it:',
            },
        }
        if([ 'send_to_advisor', 'not_serious', 'duplicate' ].includes(discardStatus)) {
            this.setModalProps({
                discardStatus,
                name: 'leadDiscardModal',
                ...modalText[discardStatus],
            })
        } else {
            await this.props.leadActionDiscard(this.props.leadId, { discardStatus })
            if([ 'test', 'span' ].includes(discardStatus)) window.location.href = `/leads/validation?timezone=${returnLocalTimezone()}`
        }
    }

    noAnswerCall = async () => {
        await this.props.createLeadNoAnswerAction(this.props.lead.id)
        window.location.href = `/leads/validation?timezone=${returnLocalTimezone()}`
    }

    callAction = event => {
        this.props.showCallNumberAction()
        if(event !== 'notValid') {
            this.props.createCallLeadAction(this.props.lead.id)
        }
    }

    showPostponeValidationModal = () => {
        this.props.showModal({
            name: 'postponeValidation',
            lead: this.props.lead,
        })
    }

    render() {
        const {
            leadsQuotesCreatePermission,
            leadsQuotesSetReservationExpiryDatePermission,
            leadsAdjustServiceFeePermission,
            unrejectAllQuotesPermission,
            leadsUnmatchPermission,
            acceptQuotePermission,
            showPhoneNumber,
            isServiceFee,
            handleAdjustAmount,
            leadQuotes,
            saveAction,
            isLoading,
            client,
            lead,
            numberOfBusinessUnitsForLead,
            isLoadingBusinessUnits,
        } = this.props
        const { max, count } = lead.quotesInfo

        const leadValidated = lead.status === 'validated'

        const leadUnknown = lead.status === 'unknown'

        const untreadLead = [ 'spam', 'duplicate', 'test', 'send_to_advisor', 'not_serious' ].includes(lead.status)

        const leadValidatedButNotFull = leadValidated && max !== count

        const leadValidatedWithoutQuote = leadValidated && !count

        const quotesFullNoAcceptReject = max === count && max !== 0 && !leadQuotes.some(quote => [ 'rejected', 'accepted' ].includes(quote.status))

        const leadWithAcceptedQuote = leadQuotes.some(quote => quote.status === 'accepted')

        const existPublishedQuote = leadQuotes.some(quote => Boolean(quote.publishedAt))

        const allQuoteRejected = leadQuotes.filter(quote => quote.status === 'rejected').length === count && Boolean(count)

        const untreadValidateReject = untreadLead || leadValidatedButNotFull || allQuoteRejected

        const acceptOrReject = leadWithAcceptedQuote || allQuoteRejected

        const fullAcceptReject = quotesFullNoAcceptReject || acceptOrReject

        const showReserveSpotsButton = leadsQuotesCreatePermission && leadsQuotesSetReservationExpiryDatePermission && leadValidatedButNotFull

        const showAdjustButton = isServiceFee && leadsAdjustServiceFeePermission && leadValidated

        return <ActionButtonBlock>
            <LoadingBar />
            <ButtonGrid>
                <ButtonContainer>
                    <ButtonGroup>
                        <Button
                            onClick={e => saveAction(e, 'save')}
                            modifiers={this.actionModifier}
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                    </ButtonGroup>
                </ButtonContainer>
                <ButtonContainer divider>
                    {untreadLead || (fullAcceptReject && !leadUnknown) || (leadValidatedButNotFull || leadUnknown) &&
                    <Button
                        disabled={isLoading}
                        onClick={e => saveAction(e, leadValidatedButNotFull ? 'save_and_resend' : 'save_and_validate')}
                        modifiers={this.actionModifier}
                    >
                        {leadValidatedButNotFull ? 'Save and resend' : 'Save and validate'}
                    </Button>
                    }
                </ButtonContainer>
                {leadUnknown && this.props.isAutoAllocationEnabled &&
                <ButtonContainer>
                    <Button
                        onClick={this.goToAllocationPage}
                        modifiers={this.actionModifier}
                    >
                        Save and add allocations
                    </Button>
                </ButtonContainer>
                }
                <ButtonContainer>
                    {untreadLead || fullAcceptReject || (leadValidatedButNotFull || leadUnknown) &&
                    <Button
                        disabled={isLoading}
                        onClick={this.showPostponeValidationModal}
                        modifiers={this.secondaryModifier}
                    >
                        Postpone validation
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {untreadValidateReject || fullAcceptReject || leadValidatedWithoutQuote ||
                    <Trigger
                        overlayTitle="Mark as.."
                        overlayContent={
                            <MakeAsOverlayContent
                                markAsAction={this.markAsAction}
                            />
                        }
                    >
                        <ButtonWithIcon
                            disabled={isLoading}
                            modifiers={this.dangerModifier}
                        >
                            <ButtonWithIcon.Text>Mark as..</ButtonWithIcon.Text>
                            <ButtonWithIcon.Icon className="mdi mdi-chevron-down" />
                        </ButtonWithIcon>
                    </Trigger>}
                    {untreadLead || fullAcceptReject || leadValidatedButNotFull && <Button
                        onClick={this.closeLeadAsCompleted}
                        modifiers={this.actionModifier}
                        disabled={isLoading}
                    >
                        Mark as complete
                    </Button>}
                    {leadWithAcceptedQuote && <Button
                        onClick={this.queueLeadFeedbackEmail}
                        modifiers={this.actionModifier}
                        disabled={isLoading}
                    >
                        Request feedback email
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {untreadLead || acceptOrReject || leadValidatedWithoutQuote ||
                    (quotesFullNoAcceptReject || leadValidatedButNotFull) &&
                    existPublishedQuote && acceptQuotePermission && <Button
                        onClick={this.acceptQuote}
                        modifiers={this.actionModifier}
                        disabled={isLoading}
                    >
                        Accept quote
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    { showAdjustButton && <Button
                        onClick={handleAdjustAmount}
                        modifiers={this.dangerModifier}
                        disabled={isLoading}
                    >
                        Adjust service fee
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {acceptOrReject || leadValidatedWithoutQuote ||
                    (quotesFullNoAcceptReject || leadValidatedButNotFull) && <Button
                        onClick={this.rejectAllQuotes}
                        modifiers={this.dangerModifier}
                        disabled={isLoading}
                    >
                        Reject all Quotes
                    </Button>}
                    {allQuoteRejected && unrejectAllQuotesPermission && <Button
                        onClick={this.unrejectAllQuotes}
                        modifiers={this.actionModifier}
                        disabled={isLoading}
                    >
                        Unreject all Quotes
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {leadValidated && quotesFullNoAcceptReject && <Button
                        onClick={() => this.setModalProps({ name: 'leadGivenUpConfirm' })}
                        modifiers={this.dangerModifier}
                        disabled={isLoading}
                    >
                        Give up
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {acceptOrReject || (untreadLead || leadValidatedWithoutQuote) && <Button
                        onClick={() => this.setModalProps({ name: 'copyToNewLead', leadId: lead.id })}
                        modifiers={this.actionModifier}
                        disabled={isLoading}
                    >
                        Copy to new lead
                    </Button>}
                    {untreadValidateReject || leadWithAcceptedQuote || quotesFullNoAcceptReject && <Button
                        onClick={this.postponeLead}
                        modifiers={this.secondaryModifier}
                        disabled={isLoading}
                    >
                        Postpone
                    </Button>}
                    {leadWithAcceptedQuote && leadsUnmatchPermission && <Button
                        onClick={this.unmatchLead}
                        modifiers={this.dangerModifier}
                        disabled={isLoading}
                    >
                        Unmatch
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {showReserveSpotsButton && <Button
                        onClick={() => this.setModalProps({ name: 'reserveLeadQuoteForPartners', leadId: lead.id })}
                        modifiers={this.actionModifier}
                        disabled={isLoading}
                    >
                        Reserve spots
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {client && <Button
                        onClick={() => this.setModalProps({ name: 'showBusinessUnitsForLead', lead })}
                        modifiers={this.secondaryModifier}
                        disabled={isLoading}
                    >
                        See partners (
                        {isLoadingBusinessUnits ? <LoaderComponent size={14} /> : numberOfBusinessUnitsForLead}
                        )
                    </Button>}
                </ButtonContainer>
                <ButtonContainer>
                    {client && <ButtonWithIcon
                        modifiers={this.secondaryModifier}
                        disabled={!client.id || isLoading}
                    >
                        <ButtonWithIcon.Icon className="mdi mdi-reply flipped" />
                        <ButtonWithIcon.Href
                            disabled={!client.id || isLoading}
                            href={`/clients/${client.id}/login`}
                            target="_blank"
                        >
                            Client portal
                        </ButtonWithIcon.Href>
                    </ButtonWithIcon>}
                </ButtonContainer>
                <ButtonContainer divider>
                    <LeadCallButton
                        onCall={this.callAction}
                        phoneNumber={lead.customerInfo.phone}
                        geoCode={lead.geo.code}
                        ignoreAction="noAnswer"
                    />
                </ButtonContainer>
                <ButtonContainer>
                    {showPhoneNumber && <LeadCallButton
                        onCall={this.noAnswerCall}
                        callStatus="noAnswer"
                        phoneNumber={lead.customerInfo.phone}
                        geoCode={lead.geo.code}
                    />}
                </ButtonContainer>
            </ButtonGrid>
        </ActionButtonBlock>
    }
}


export default LeadActionBarCard as unknown as ComponentType<LeadActionBarPathProps>
