import React, { ComponentType, memo, PureComponent } from 'react'
import Placeholder from '../../elements/Placeholder'
import moment from 'moment'
import { FormattedAmount } from '../../elements/FormattedAmount'
import { Card } from '../../blocks/Card'
import Tabs from '../../elements/Tabs'
import { Col, Row } from 'react-bootstrap'
import { Quote } from '../../../reducers/types/entities/Quote'
import { getPartnerSubscriptions } from '../../../actions/subscriptions'
import { Partner } from '../../../reducers/types/entities/Partner'
import { connect } from '../../../utils/connect'
import { getPartnerQuotes } from '../../../actions/quotes'
import InfiniteScroll from 'react-infinite-scroller'
import { Pagination } from '../../../reducers/types/Pagination'

interface StatePropsLeadList {
    isLoadingPartnerQuotes: boolean
    partnerQuotesPagination: Pagination

}
interface DispatchPropsLeadList {
    getPartnerSubscriptions: typeof getPartnerSubscriptions
    getPartnerQuotes: typeof getPartnerQuotes
}
interface PathPropsLeadList {
    givenQuotes: Quote[]
    partner: Partner
}

type LeadListModel = StatePropsLeadList & DispatchPropsLeadList & PathPropsLeadList

const TABS = [
    { tabId: 'quotes', tabName: 'Quotes given' },
]

const QuoteItem = memo(({ quote }: { quote: Quote}) => <div key={'partner_quote_' + quote.id}>
    <Card.Text>
        <strong>
            <a href={`/leads/${quote.leadId}/validation`}>
                #
                {quote.leadId}
                :
                {quote.lead.title}
            </a>
        </strong>
    </Card.Text>
    <Row>
        <Col md={6}>
            <Card.Label>Created at:</Card.Label>
            <Card.Text>{moment(quote.createdAt).format('D. MMMM YYYY')}</Card.Text>
        </Col>
        <Col md={6}>
            <Card.Label>Total amount incl VAT:</Card.Label>
            <Card.Text><FormattedAmount amount={quote.totalAmountInclVat}/></Card.Text>
        </Col>
    </Row>
    <Card.Line modifiers={[ 'mY_1.5' ]}/>
</div>,
)


@connect<StatePropsLeadList, DispatchPropsLeadList, PathPropsLeadList>(
    state => ({
        isLoadingPartnerQuotes: state.pages.partnerQuotes.isLoading,
        partnerQuotesPagination: state.pages.partnerQuotes.pagination,
    }),
    {
        getPartnerSubscriptions,
        getPartnerQuotes,
    }
)
class LeadListCard extends PureComponent<LeadListModel, {activeTab: string}> {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'quotes',
        }
        this.changeTab.bind(this)
    }

    componentDidMount() {
        const partnerId = this.props.partner.id
        this.props.getPartnerSubscriptions(partnerId)
    }

    changeTab = newTab => {
        this.setState({ activeTab: newTab })
    }

    loadMore = (page) => {
        this.props.getPartnerQuotes({
            partnerId: this.props.partner.id,
            sort: '-created_at',
            limit: 100,
            page,
        }, true)
    }

    render() {
        const {
            isLoadingPartnerQuotes,
            givenQuotes,
            partnerQuotesPagination: {
                pages,
                page,
            },
        } = this.props

        const { activeTab } = this.state

        const hasMore = isLoadingPartnerQuotes ? false : page < pages

        return (
            <Card className="leadListCard">
                <Card.Header padding="0 20px">
                    <Tabs
                        tabs={TABS}
                        selectedTab={activeTab}
                        changeTab={this.changeTab}
                    />
                </Card.Header>
                <Card.Content
                    modifiers="scrollContent"
                    isLoading={Boolean(givenQuotes.length) && isLoadingPartnerQuotes}
                >
                    <InfiniteScroll
                        loadMore={this.loadMore}
                        hasMore={hasMore}
                        useWindow={false}
                        initialLoad={false}
                    >
                        {Boolean(givenQuotes.length)
                            ? givenQuotes.map(quote => <QuoteItem key={quote.id} quote={quote}/>)
                            : isLoadingPartnerQuotes
                                ? <Placeholder counter={5} portrait={false} />
                                : <Card.Text>No quotes have been given by this partner yet.</Card.Text>
                        }
                    </InfiniteScroll>
                </Card.Content>

            </Card>
        )
    }
}

export default LeadListCard as unknown as ComponentType<PathPropsLeadList>
