import { createSelector } from 'reselect'
import moment from 'moment'

const entitiesPauseSubscriptions = state => state.entities.pauseSubscriptions

const partnerPauseSubscriptionsId = state => state.subjects.pauseSubscriptions.partner.ids

export const partnerPauseSubscriptionsSelect = createSelector(
    partnerPauseSubscriptionsId, entitiesPauseSubscriptions,
    (ids, entities) => {
        const pauses = ids.map(id => entities[id])

        const activePause = pauses.find(pause =>
            moment().isSameOrAfter(pause.startsAt) && moment().isBefore(pause.endsAt)
        )

        return {
            isActivePause: Boolean(activePause),
            pauses,
        }
    }
)

