import React, { PureComponent, ComponentType } from 'react'
import { Card } from '../../blocks/Card/index'
import Tabs from '../../elements/Tabs'
import { getQuotes } from '../../../actions/quotes'
import { getAllocationSuggestions } from '../../../actions/clients'
import Placeholder from '../../elements/Placeholder'
import { submit } from 'redux-form'
import { leadQuotesSelector } from '../../../selectors/lead'
import { QuotesOverview } from './products-card-components/QuoteOverview'
import { QuoteDetails } from './products-card-components/QuoteDetails'
import { CreateProductTab } from './products-card-components/CreateProductTab'
import { showModal } from '../../../actions/modal'
import { connect } from '../../../utils/connect'
import { Quote } from '../../../reducers/types/entities/Quote'
import { Auth } from '../../../reducers/auth'
import { AllocationSuggestion } from '../../../reducers/types/entities/AllocationSuggestion'
import { AllocationSuggestionsTab } from './products-card-components/AllocationSuggestionsTab'
import { deleteAllocationSuggestion } from '../../../actions/leads'

interface LeadProductsStateProps {
    isLoadingAllocationSuggestions: boolean
    allocationSuggestions: AllocationSuggestion[]
    isServiceFee: boolean
    leadStatus: string
    isLoadingQuotes: boolean
    quotes: Quote[]
    deleteQuotePermission
    createQuoteFeePermission
    wantedGeoCode: Auth['wantedGeoCode']
}
interface LeadProductsDispatchProps {
    showModal: typeof showModal
    getQuotes: typeof getQuotes
    getAllocationSuggestions: typeof getAllocationSuggestions
    submit: typeof submit
    deleteAllocationSuggestion: typeof deleteAllocationSuggestion
}
interface LeadProductsPathProps {
    leadId
    clientId
    adjustAmount
    handleMarketplaceToggle
    showForMarketplace
}

type LeadProductsModel = LeadProductsStateProps & LeadProductsDispatchProps & LeadProductsPathProps

interface LeadProductsState {
    selectedTab: string
}

@connect<LeadProductsStateProps, LeadProductsDispatchProps, LeadProductsPathProps>(
    (state, { leadId, clientId }) => ({
        quotes: leadQuotesSelector(state, leadId),
        allocationSuggestions: state.pages.clients.allocationSuggestions,
        isLoadingAllocationSuggestions: state.pages.clients.isLoading,
        isServiceFee: state.entities.leads[leadId].isServiceFee,
        leadStatus: state.entities.leads[leadId].status,
        isLoadingQuotes: state.pages.quotes.isLoading,
        deleteQuotePermission: state.entities.permissions.leads_quotes_delete,
        createQuoteFeePermission: state.entities.permissions.lead_quote_fee_create,
        wantedGeoCode: state.auth.wantedGeoCode,
    }),
    {
        showModal,
        getQuotes,
        getAllocationSuggestions,
        submit,
        deleteAllocationSuggestion,
    }
)
class LeadProductsCard extends PureComponent<LeadProductsModel, LeadProductsState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: props.leadStatus === 'validated' ? 'overview' : 'createProduct',
        }
    }

    componentDidMount() {
        this.props.getQuotes({
            criteria: {
                leadId: this.props.leadId,
                isPublished: true,
            },
        })
        this.props.getAllocationSuggestions({
            leadId: this.props.leadId,
            clientId: this.props.clientId
        })
    }

    closeReservation = (quoteId, leadId) => {
        this.props.showModal({
            name: 'closeReservationQuoteModal',
            quoteId,
            leadId,
            actionName: 'quote',
            headerText: 'Close quote reservation',
            bodyText: <h4>
                You are about to close quote reservation.
                This action cannot be undone, would you like to proceed?
            </h4>,
            btnApproveText: 'Yes',
            btnDenyText: 'No',
            btnApproveModifiers: [ 'danger' ],
        })
    }

    deleteSuggestion = async (leadId, suggestionId) => {
        await this.props.deleteAllocationSuggestion(leadId, suggestionId)
        this.props.getAllocationSuggestions({
            leadId: this.props.leadId,
            clientId: this.props.clientId,
        })
    }

    render() {
        const {
            deleteQuotePermission,
            createQuoteFeePermission,
            isLoadingQuotes,
            wantedGeoCode,
            adjustAmount,
            isServiceFee,
            leadStatus,
            quotes,
            leadId,
            isLoadingAllocationSuggestions,
            allocationSuggestions,
            handleMarketplaceToggle,
            showForMarketplace,
        } = this.props

        const { selectedTab } = this.state

        if(isLoadingQuotes || isLoadingAllocationSuggestions)
            return <Card>
                <Card.Header>
                    <strong>Products</strong>
                </Card.Header>
                <Card.Content>
                    <Placeholder />
                </Card.Content>
            </Card>

        const tabs = [
            { tabName: 'Products', tabId: 'createProduct' },
            { tabName: 'Allocation suggestions', tabId: 'allocationSuggestions' },
            { tabName: 'Overview', tabId: 'overview' },
            ...quotes.map((el, index) => ({ tabId: index.toString(), tabName: 'Quote ' + (1 + index) })),
        ]
        const leadWithAcceptedQuote = quotes.some(quote => quote.status === 'accepted')

        const leadWithRejectedQuote = quotes.some(quote => quote.status === 'rejected')

        const existPublishedQuote = quotes.some(quote => Boolean(quote.publishedAt))

        return <Card>
            <Card.Header padding="0 20px">
                <Tabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    changeTab={tabIndex => this.setState({ selectedTab: tabIndex })}
                />
            </Card.Header>

            {selectedTab === 'createProduct' ?
                <CreateProductTab
                    adjustAmount={adjustAmount}
                    disableQuoteAmount={leadWithAcceptedQuote || leadWithRejectedQuote}
                    createQuoteFeePermission={createQuoteFeePermission}
                    disableItems={existPublishedQuote || (leadStatus === 'validated' && existPublishedQuote)}
                    isServiceFee={isServiceFee}
                    leadId={leadId}
                    wantedGeoCode={wantedGeoCode}
                    handleMarketplaceToggle={handleMarketplaceToggle}
                    showForMarketplace={showForMarketplace}
                /> :
                selectedTab === 'overview' ?
                    <QuotesOverview
                        disableQuoteAmount={leadWithAcceptedQuote || leadWithRejectedQuote}
                        createQuoteFeePermission={createQuoteFeePermission}
                        adjustAmount={adjustAmount}
                        isServiceFee={isServiceFee}
                        quotes={quotes}
                        wantedGeoCode={wantedGeoCode}
                        handleMarketplaceToggle={handleMarketplaceToggle}
                        showForMarketplace={showForMarketplace}
                    /> :
                    selectedTab === 'allocationSuggestions' ?
                        <AllocationSuggestionsTab
                            allocationSuggestions={allocationSuggestions}
                            deleteSuggestion={this.deleteSuggestion}
                        /> :
                        <QuoteDetails
                            deleteQuotePermission={deleteQuotePermission}
                            showDeleteQuoteModal={quoteId =>
                                this.props.showModal({
                                    quoteId,
                                    leadId,
                                    name: 'deleteQuoteModal',
                                })
                            }
                            closeReservationModal={(quoteId) => this.closeReservation(quoteId, leadId)}
                            quote={quotes[selectedTab]}
                            leadWithRejectedQuote={leadWithRejectedQuote}
                            leadWithAcceptedQuote={leadWithAcceptedQuote}
                        />
            }
        </Card>
    }
}

export default LeadProductsCard as unknown as ComponentType<LeadProductsPathProps>
