import styled from 'styled-components'

export default styled.label`
    cursor: pointer;
    &:before {
        content: '';
        border-radius: 100%;
        border: 1px solid #ccc;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
    }
`
