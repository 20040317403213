import {
    GET_CURRENT_INTERVAL_LEADS,
    GET_CURRENT_INTERVAL_LEADS_SUCCESS,
    GET_CURRENT_INTERVAL_LEADS_FAIL,
} from '../../actions/leads'

import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'
import {
    GET_TOTAL_CALL_ACTIVITIES,
    GET_TOTAL_CALL_ACTIVITIES_FAIL,
    GET_TOTAL_CALL_ACTIVITIES_SUCCESS
} from '../../actions/newsfeed'

export interface IntervalLeadsState extends InitialState {
    leadIds: number[]
}
const initialState: IntervalLeadsState = {
    isLoading: false,
    leadIds: [],
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_TOTAL_CALL_ACTIVITIES:
        case GET_CURRENT_INTERVAL_LEADS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CURRENT_INTERVAL_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: action.append ? state.leadIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_TOTAL_CALL_ACTIVITIES_FAIL:
        case GET_TOTAL_CALL_ACTIVITIES_SUCCESS:
        case GET_CURRENT_INTERVAL_LEADS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
