import React, { memo } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../elements/Button'
import { hideModal } from '../../actions/modal'

interface ConfirmModel {
    modalProps: {
        btnApproveModifiers: any
        btnApproveText: string
        btnDenyText: string
        headerText: string
        bodyText: JSX.Element | string
    }
    disabled: boolean
    submit: () => void
    onHide: typeof hideModal
}
const ConfirmModal = memo(({
    modalProps,
    submit,
    onHide,
    disabled,
}: ConfirmModel) =>
    <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
    >
        <Modal.Header>{modalProps.headerText}</Modal.Header>
        <Modal.Body>
            <h4>{modalProps.bodyText}</h4>
        </Modal.Body>
        <Modal.Footer className="text-right">
            <Button
                style={{ display: 'inline-block' }}
                modifiers={[ 'secondary', 'mR_2' ]}
                onClick={onHide}
                disabled={disabled}
            >
                {modalProps.btnDenyText}
            </Button>
            <Button
                style={{ display: 'inline-block' }}
                modifiers={modalProps.btnApproveModifiers}
                onClick={submit}
                disabled={disabled}
            >
                {modalProps.btnApproveText}
            </Button>
        </Modal.Footer>
    </Modal>
)

export default ConfirmModal
