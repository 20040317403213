import React, { memo, PureComponent } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import config from '../../config'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { Card } from '../blocks/Card'
import { partnerListSelector } from '../../selectors/partner'
import { getPartnerAggregations, getPartners } from '../../actions/partners'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { Employee } from '../../reducers/types/entities/Employee'
import { Certification } from '../../reducers/types/entities/Certification'
import ProviderTable from '../blocks/ProviderTable'
import PartnerPreviewSidebar from './PartnerPreviewSidebar'
import MapSidebar from '../elements/MapSidebar'
import { Aggregation } from '../../reducers/types/entities/Aggregation'
import {
    Cell,
    LinkCell,
    EmployeeCell,
    SatisfactionCell,
    DateCell,
    DropdownFilter,
    LocationFilter,
    TextFilter, SortColumn, Column, Sidebar,
} from '../elements/table'
import { allCertificationsSelector } from '../../state/certifications/selectors'
import { getCertifications } from '../../state/certifications/actions'

const SATISFACTIONS = [
    { id: 4, name: 'Very happy', cssClass: 'label satisfaction satisfaction-4' },
    { id: 3, name: 'Mostly happy', cssClass: 'label satisfaction satisfaction-3' },
    { id: 2, name: 'Somewhat unhappy', cssClass: 'label satisfaction satisfaction-2' },
    { id: 1, name: 'Very unhappy', cssClass: 'label satisfaction satisfaction-1' },
]

const STATES = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'ex_partner', name: 'Ex Partner' },
    { id: 'canvas', name: 'Canvas' },
    { id: 'demo', name: 'Demo' },
]

const CANVAS_STATUSES = [
    { id: 'untreated', name: 'Untreated' },
    { id: 'postponed', name: 'Postponed' },
    { id: 'not_relevant', name: 'Not Relevant' },
    { id: 'not_interested', name: 'Not Interested' },
    { id: 'already_partner', name: 'Already Partner' },
    { id: 'x_partner', name: 'Ex Partner' },
]

const DIGITAL_LEADS = [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
]

const PartnerRow = memo((props: {rowData?: Partner}) => {
    const { rowData } = props

    const partner = rowData

    let tooltip = null

    if (partner.alerts && partner.alerts.length > 0)
        tooltip = <Tooltip placement="right" className="in" id={'tooltip-' + partner.id}>
            {partner.alerts.map((alert, i) => <span key={i}>
                {alert.title}
                <br />
            </span>)}
        </Tooltip>

    return <ProviderTable.Row {...props}>
        <LinkCell to={`/partners/${partner.id}`} onClick={e => e.stopPropagation()}>{partner.id}</LinkCell>
        <Cell>
            {tooltip &&
            <OverlayTrigger delay={0} placement="right" overlay={tooltip} animation={false} delayHide={0}>
                <span className="dot dot-danger pull-right"/>
            </OverlayTrigger>}
            {partner.companyName}
        </Cell>
        <Cell>
            {partner.geo.zipCode}
            {' '}
            {partner.geo.cityName}
        </Cell>
        <EmployeeCell portrait={false} employee={partner.employee} />
        <SatisfactionCell satisfaction={partner.satisfaction} />
        <DateCell date={partner.kpi.lastLogin} format="datetime" />
        <DateCell date={partner.kpi.lastPhoneCall} format="datetime" />
        <Cell>{partner.subscription ? partner.subscription.map(el => el.packageType).join(', ') : ''}</Cell>
        <Cell>{partner.hasActiveContract ? 'yes' : 'no'}</Cell>
        <DateCell date={partner.kpi.lastSubscriptionStartDate} format="datetime" />
        <DateCell date={partner.kpi.lastPublishedQuote} format="datetime" />
        <Cell>{partner.kpi.publishedQuotesCount}</Cell>
        <Cell>{partner.kpi.winRatio}</Cell>
        <Cell>{partner.kpi.unmatchedCount}</Cell>
        <Cell>{partner.kpi.unmatchedPercent}</Cell>
        <Cell>{partner.preferences.digitalLeads ? 'yes' :'no'}</Cell>
        <Cell>{partner.canvas ? partner.canvas.status : ''}</Cell>
    </ProviderTable.Row>
})

interface Criteria {
    state: string[]
    visibleColumns: string[]
    satisfaction: string[]
    digitalLeads: string[]
    canvasStatus: string[]
    packageType: string[]
    employeeId: string[]
    badgeId: string[]
    sort: string
    sidebarName: string
    activeRowId: string
    geoDistance: string
    locationId: string
    geoPoint: string
    geoRect: string
    query: string
    industryId: string
}

interface PartnersListProps {
    certifications: Certification[]
    employees: Employee[]
    partners: Partner[]
    totalItems: number
    aggregations: Aggregation[]
    criteria: Criteria
}
interface PartnersDispatchProps {
    getPartnerAggregations: typeof getPartnerAggregations
    getCertifications: typeof getCertifications
    getPartners: typeof getPartners
}
interface PartnersListPathProps {
    location: {
        query: Criteria & {
            state: string[]
        }
    }
}
type PartnersListModel = PartnersListProps & PartnersDispatchProps & PartnersListPathProps

@connect<PartnersListProps, PartnersDispatchProps, PartnersListPathProps>(
    (state, { location: { query } }) => ({
        partners: partnerListSelector(state),
        aggregations: state.pages.partners.aggregations,
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        totalItems: state.pages.partners.pagination.total,
        certifications: allCertificationsSelector(state),
        criteria: {
            state: query.state ? splitFilterString(query.state) : [ 'active', 'inactive' ],
            visibleColumns: splitFilterString(query.visibleColumns),
            satisfaction: splitFilterString(query.satisfaction),
            digitalLeads: splitFilterString(query.digitalLeads),
            canvasStatus: splitFilterString(query.canvasStatus),
            packageType: splitFilterString(query.packageType),
            employeeId: splitFilterString(query.employeeId),
            badgeId: splitFilterString(query.badgeId),
            sort: query.sort || 'company_name',
            sidebarName: query.sidebarName || '',
            activeRowId: query.activeRowId || '',
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
            geoPoint: query.geoPoint || '',
            geoRect: query.geoRect || '',
            query: query.query || '',
            industryId: config.industryId,
        },
    }),
    {
        getPartnerAggregations,
        getPartners,
        getCertifications,
    }
)
export default class OldPartnersListPage extends PureComponent<PartnersListModel> {
    componentDidMount() {
        this.props.getCertifications({
            geoCode: config.geoCode,
        })
    }

    retrieveData = (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            state: filterCriteria.state ? filterCriteria.state : this.props.criteria.state,
            geoCode: config.geoCode,
        })

        this.props.getPartnerAggregations(criteria)
        return this.props.getPartners(criteria, append)
    }

    render() {
        const {
            partners,
            criteria,
            employees,
            totalItems,
            aggregations,
            certifications,
        } = this.props
        const satisfactionAggregation = aggregations.find(aggregation => aggregation.name === 'satisfaction')
        const canvasStatusAggregation = aggregations.find(aggregation => aggregation.name === 'canvas_status')
        const partnerStateAggregation = aggregations.find(aggregation => aggregation.name === 'state')
        const employeePartnerAggregation = aggregations.find(aggregation => aggregation.name === 'employee_id')
        const packageTypeAggregation = aggregations.find(aggregation => aggregation.name === 'package_type')
        const badgePartnerAggregation = aggregations.find(aggregation => aggregation.name === 'badge_id')
        const digitalLeadsAggregation = aggregations.find(aggregation => aggregation.name === 'digital_leads')

        const packageTypeItems = packageTypeAggregation && packageTypeAggregation.items ?
            packageTypeAggregation.items.map(el => ({ id: el.key, name: el.key })) :
            []

        return (
            <Card margin="0">
                <Card.Header>
                    Partners
                    {' '}
                    <span className="text-muted">
                        (
                        {partners.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="partners"
                    retrieveData={this.retrieveData}
                    criteria={this.props.criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.employeeId}
                            items={employees}
                            facets={employeePartnerAggregation}
                            name="employeeId"
                            label="Employee"
                            hideZero={true}
                        />
                        <DropdownFilter
                            selectedIds={criteria.state}
                            items={STATES}
                            name="state"
                            label="State"
                            facets={partnerStateAggregation}
                        />
                        {Boolean(packageTypeItems.length) && <DropdownFilter
                            selectedIds={criteria.packageType}
                            items={packageTypeItems}
                            name="packageType"
                            label="Subscription"
                            facets={packageTypeAggregation}
                        />}
                        {criteria.state.length === 1 &&
                        criteria.state[0] === 'canvas' &&
                        criteria.satisfaction.length === 0 || <DropdownFilter
                            selectedIds={criteria.satisfaction}
                            facets={satisfactionAggregation}
                            items={SATISFACTIONS}
                            name="satisfaction"
                            label="Satisfaction"
                        />}
                        {!criteria.state.includes('canvas') &&
                        criteria.canvasStatus.length === 0 || <DropdownFilter
                            selectedIds={criteria.canvasStatus}
                            facets={canvasStatusAggregation}
                            items={CANVAS_STATUSES}
                            name="canvasStatus"
                            label="Canvas Status"
                        />}
                        <DropdownFilter
                            selectedIds={criteria.badgeId}
                            items={certifications}
                            name="badgeId"
                            label="Certifications"
                            facets={badgePartnerAggregation}
                        />
                        <DropdownFilter
                            selectedIds={criteria.digitalLeads}
                            items={DIGITAL_LEADS}
                            name="digitalLeads"
                            label="Digital partner"
                            facets={digitalLeadsAggregation}
                        />
                        <LocationFilter
                            label="Location"
                            name="location"
                            locationId={criteria.locationId}
                            geoDistance={criteria.geoDistance}
                        />
                        <TextFilter
                            value={criteria.query}
                            name="query"
                            label="Search for ..."
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <SortColumn name="id">ID</SortColumn>
                            <SortColumn name="company_name">Company name</SortColumn>
                            <Column name="location">Location</Column>
                            <SortColumn name="employee_name">Employee</SortColumn>
                            <SortColumn name="satisfaction">Satisfaction</SortColumn>
                            <SortColumn name="last_login">Last Login</SortColumn>
                            <SortColumn name="last_phone_call">Last Phone Call</SortColumn>
                            <Column name="package_type">Subscription</Column>
                            <Column name="contract">Contract</Column>
                            <SortColumn name="last_subscription_start_date">Subscription start date</SortColumn>
                            <SortColumn name="last_published_quote">Last published quote</SortColumn>
                            <Column name="total_quotes">Total quotes</Column>
                            <SortColumn name="win_ratio">Success ratio</SortColumn>
                            <SortColumn name="unmatched">Refunds</SortColumn>
                            <SortColumn name="unmatched_percent">Refund ratio</SortColumn>
                            <SortColumn name="digital_leads">Digital leads</SortColumn>
                            <SortColumn name="canvas_status">Canvas Status</SortColumn>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={partners}
                            noDataText="We couldn't find any partners matching your selection."
                        >
                            <PartnerRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                    <Sidebar>
                        <MapSidebar
                            list={partners}
                            header="Map"
                            viewKey="map"
                            pinLink="partners"
                        />
                        <PartnerPreviewSidebar
                            header="Preview"
                            viewKey="preview"
                        />
                    </Sidebar>
                </ProviderTable>
            </Card>
        )
    }
}
