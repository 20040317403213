import React, { memo } from 'react'


interface ToggleFilterModel {
    selected: string
    onClick,
    name: string
    tabs: Array<{
        viewKey: string
        header: string
    }>
}
const ToggleFilter = memo(({
    selected,
    onClick,
    name,
    tabs,
}: ToggleFilterModel) =>
    <div className="btn-group toggle-filter pull-right">
        {tabs.map((tab, i) =>
            <button
                key={'ToggleFilter_' + i}
                type="button"
                onClick={() => onClick({ [name]: selected === tab.viewKey ? '' : tab.viewKey })}
                className={`btn btn-default ${selected === tab.viewKey ? 'active' : ''}`}
            >
                {tab.header}
            </button>
        )}
    </div>
)

export default ToggleFilter
