import {
    GET_EMPLOYEES,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_FAIL,
} from '../../actions/employees'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface EmployeesModel extends InitialState {
    items: number[]
}
const initialState: EmployeesModel = {
    items: [],
    isLoading: null,
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}


export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_EMPLOYEES:
            return {
                ...state,
                isLoading: true,
            }
        case GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                items: action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_EMPLOYEES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
