import React, { ComponentType, PureComponent } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connect } from '../../../utils/connect'
import { Table } from './Table'
import { Pagination } from '../../../reducers/types/Pagination'
import { Card } from '../../blocks/Card'

interface LazyTableProps {
    retrieveData: (page: number) => void
    heightCorrection?: number
    heightDivider?: number
    scrollHeight?
    modifiers?
    isLoading: boolean
    pagination: Pagination
    pagesStateName: string

}
type LazyTablePathProps = {
    retrieveData: (page: number) => void
    heightCorrection?: number
    heightDivider?: number
    scrollHeight?
    modifiers?
} & ({
    isLoading: boolean
    pagination: Pagination
} | {
    pagesStateName: string
})

@connect<any, any, LazyTableProps>(
    (state, props) => ({
        pagination: props.pagination || state.pages[props.pagesStateName].pagination,
        isLoading: props.isLoading !== undefined
            ? props.isLoading
            : props.pagesStateName
                ? state.pages[props.pagesStateName].isLoading
                : false,
    })
)
class LazyTable extends PureComponent<LazyTableProps> {
    render() {
        const {
            retrieveData,
            scrollHeight,
            children,
            isLoading,
            modifiers = [],
            pagination: {
                pages,
                page,
            },
        } = this.props

        const hasMore = isLoading ? false : page < pages

        return <Card.Content
            modifiers={[ 'scroll', 'maxHeight', ...modifiers ]}
            scrollHeight={scrollHeight}
            isLoading={isLoading}
        >
            <InfiniteScroll
                pageStart={1}
                loadMore={retrieveData}
                hasMore={hasMore}
                useWindow={false}
            >
                <Table>
                    {children}
                </Table>
            </InfiniteScroll>
        </Card.Content>
    }
}

export default LazyTable as unknown as ComponentType<LazyTablePathProps>
