import React, { PureComponent, ComponentType } from 'react'
import config from '../../config'
import { getTasks, completeTask } from '../../actions/tasks'
import { currentEmployeeSelector } from '../../selectors/employees'
import CheckBox from '../elements/CheckBox'
import { Card } from '../blocks/Card/index'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { connect } from '../../utils/connect'
import { Task } from '../../reducers/types/entities/Task'
import { Taskable } from '../../reducers/types/entities/Taskable'
import { Employee } from '../../reducers/types/entities/Employee'
import { tasksSelector } from '../../selectors/tasks'
import { Cell, LinkCell, DateCell, DropdownFilter, Column, SortColumn, OverdueCell } from '../elements/table'
import ProviderTable from '../blocks/ProviderTable'

const DUE_BEFORE_FILTER_ITEMS = [
    {
        id: 'tomorrow',
        name: 'Today and overdue',
    }, {
        id: 'next week',
        name: 'This week and overdue',
    }, {
        id: 'next month',
        name: 'This month and overdue',
    },
]

interface TaskRowStateProps {
    lead: Taskable
    partner: Taskable
}
interface TaskRowDispatchProps {
    completeTask: typeof completeTask
}
interface TaskRowHOCProps {
    visibleCells: string[]
    rowData: Task
}

type TaskRowModel =  TaskRowStateProps & TaskRowDispatchProps & TaskRowHOCProps

@connect<TaskRowStateProps, TaskRowDispatchProps, TaskRowHOCProps>(
    (state, { rowData }) => ({
        lead: rowData.taskables.find(taskable => taskable.type === 'lead' && !!taskable.lead),
        partner: rowData.taskables.find(taskable => taskable.type === 'partner' && !!taskable.partner),
    }),
    {
        completeTask,
    }
)
class TaskRow extends PureComponent<TaskRowModel> {
    constructor(props) {
        super(props)
        this.complete = this.complete.bind(this)
    }

    isCompleted(task) {
        return task.status && task.status.id === config.DONE_TASK
    }

    complete() {
        const task = this.props.rowData
        if(!this.isCompleted(task)) this.props.completeTask(task)
    }

    render() {
        const { lead, partner, ...props } = this.props

        const task = this.props.rowData

        return <ProviderTable.Row {...props}>
            <Cell className="text-center">
                <CheckBox
                    onClick={this.complete}
                    loading={false}
                    checked={this.isCompleted(task)}
                />
            </Cell>
            <OverdueCell date={task.deadlineAt} />
            <Cell>{ task.title }</Cell>
            <Cell>{ task.description }</Cell>
            {partner
                ? <LinkCell to={`/partners/${partner.partner.id}`}>{partner.partner.companyName}</LinkCell>
                : <Cell>-</Cell>
            }
            {lead
                ? <LinkCell to={`/leads/${lead.lead.id}/validation`}>
                    #
                    { lead.lead.id }
                </LinkCell>
                : <Cell>-</Cell>
            }
            <DateCell date={task.createdAt} format="datetime" />
        </ProviderTable.Row>
    }
}

const TaskTableRow = TaskRow as ComponentType

interface Criteria {
    visibleColumns: string[]
    dueBefore: string[]
    sort: string
}

interface TaskListStateProps {
    employee: Employee
    tasks: Task[]
    totalItems: number
    criteria: Criteria
}

interface TaskListDispatchProps {
    getTasks: typeof getTasks
}

interface TaskListPathProps {
    location: {
        query: Criteria
    }
}

type TaskListModel =  TaskListStateProps & TaskListDispatchProps & TaskListPathProps

@connect<TaskListStateProps, TaskListDispatchProps, TaskListPathProps>(
    (state, { location: { query } }) => ({
        employee: currentEmployeeSelector(state),
        tasks: tasksSelector(state),
        totalItems: state.pages.taskList.pagination.total,
        criteria: {
            visibleColumns: splitFilterString(query.visibleColumns),
            dueBefore: splitFilterString(query.dueBefore),
            sort: query.sort || 'deadline_at',
        },
    }),
    {
        getTasks,
    }
)

export default class TaskListPage extends PureComponent<TaskListModel> {
    retrieveData = (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            assignedEmployeeId: this.props.employee.id,
        })
        return this.props.getTasks(criteria, null, append)
    }

    render() {
        const {
            tasks,
            criteria,
            totalItems,
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    My Tasks 
                    {' '}
                    <span className="text-muted">
                        (
                        {tasks.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="taskList"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.dueBefore}
                            items={DUE_BEFORE_FILTER_ITEMS}
                            name="dueBefore"
                            label="Due"
                            search={false}
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <colgroup>
                            <col width="50px"/>
                        </colgroup>
                        <ProviderTable.Header>
                            <Column name="complete"/>
                            <SortColumn name="deadline_at">Due</SortColumn>
                            <SortColumn name="title">Title</SortColumn>
                            <Column name="description">Description</Column>
                            <Column name="partner">Partner</Column>
                            <Column name="lead">Lead</Column>
                            <SortColumn name="created_at">Created</SortColumn>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={tasks}
                            noDataText="No tasks match the selected criteria."
                        >
                            <TaskTableRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
