import React, { PureComponent } from 'react'
import LoaderComponent from '../elements/LoaderComponent'
import { getEmployees } from '../../actions/employees'
import { connect } from '../../utils/connect'
import { employeeSelector } from '../../selectors/employees'
import { Employee } from '../../reducers/types/entities/Employee'
import config from '../../config'

interface EmployeesPageModel {
    isLoading: boolean
    employees: Employee[]
    getEmployees: typeof getEmployees
}

@connect(
    state => ({
        isLoading: state.pages.employees.isLoading,
        employees: employeeSelector(state),
    }),
    {
        getEmployees,
    }
)
export default class EmployeesPage extends PureComponent<EmployeesPageModel> {
    componentDidMount() {
        this.props.getEmployees({ sort: 'first_name', isActive: '1', geoCode: '' })
    }

    render() {
        const {
            isLoading,
            employees,
        } = this.props

        if (isLoading || !employees) return <LoaderComponent/>

        return (
            <div className="container-fluid">
                <h1>Employees</h1>
                <div className="row employees-page row-float-block">
                    {employees.map(employee => {
                        return <div className="col-xs-6 col-md-3 col-lg-2" key={'employee-hr-' + employee.id}>
                            <div className="employee-card">
                                <img className="portrait"
                                    src={employee.image ? employee.image : '/assets/images/no-picture.jpg'}
                                />

                                {!!employee.roles.filter(rl => (
                                    rl.title === 'validator' ||
                                        rl.title === 'canvas' ||
                                        rl.title === 'sales'
                                )).length &&
                                    <img className="flag" src={`${config.bladeFrontendUrl}/assets/images/flags/${employee.geoCode}-32.png`}/>
                                }
                                <span className="name">{employee.name}</span>
                                <span className="employee-title">{employee.title}</span>
                                <span className="employee-email">
                                    <a href={'mailto:' + employee.email}>{employee.email}</a>
                                </span>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}
