import React, { PureComponent } from 'react'

interface TextFilterModel {
    onChange?: (value: string) => void
    name: string
    label: string
    value: string
}

interface TextFilterStateModel {
    value: string
}

export default class TextFilter extends PureComponent<TextFilterModel, TextFilterStateModel> {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
        }
    }

    onKeyDown = e => {
        if (13 === e.keyCode) {
            this.props.onChange(this.state.value)
        }
    }

    onChange = e => {
        this.setState({ value: e.target.value })
    }

    render() {
        const {
            name,
            label,
        } = this.props

        return (
            <input
                type="text"
                name={name}
                className="input-default"
                value={this.state.value}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                placeholder={label}
            />
        )
    }
}
