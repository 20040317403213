import React, { ComponentType, PureComponent } from 'react'

import { getCompletedCallFlowLeads } from '../../actions/leads'
import { successColor } from '../../utils/variables'
import config from '../../config'
import { createLeadNoteActivity, getActivities } from '../../actions/newsfeed'
import { completedCallFlowLeadsSelector, leadActivitiesSelector } from '../../selectors/lead'
import { Card } from '../blocks/Card/index'
import { Alert } from 'react-bootstrap'
import { removeEmptyProperties, splitFilterString } from '../../utils/index'
import { connect } from '../../utils/connect'
import { Lead } from '../../reducers/types/entities/Lead'
import { Activity } from '../../reducers/types/entities/Activities'
import { Employee } from '../../reducers/types/entities/Employee'
import { employeeSelector } from '../../selectors/employees'
import ProviderTable from '../blocks/ProviderTable'
import { DateCell, LinkCell, Cell, EditCell, DropdownFilter, LocationFilter, Column } from '../elements/table'

interface LeadRowStateProps {
    leadActivity: Activity
    employeeId: number
}
interface LeadRowDispatchProps {
    createLeadNoteActivity: typeof createLeadNoteActivity
    getActivities: typeof getActivities
}

interface LeadRowHOCProps {
    rowData: Lead
    visibleCells: string[]
}

type LeadRowModel = LeadRowStateProps & LeadRowDispatchProps & LeadRowHOCProps

@connect<LeadRowStateProps, LeadRowDispatchProps, LeadRowHOCProps>(
    (state, props) => ({
        leadActivity: leadActivitiesSelector(state, props.rowData.id),
        employeeId: state.auth.employeeId,
    }),
    {
        createLeadNoteActivity,
        getActivities,
    }
)
class LeadRow extends PureComponent<LeadRowModel> {
    handleChangeNoteCell = async noteText => {
        const leadId = this.props.rowData.id
        const noteData = {
            note: noteText,
            employeeId: this.props.employeeId,
        }
        await this.props.createLeadNoteActivity(leadId, noteData)
        return this.props.getActivities({ leadId })
    }

    render() {
        const { leadActivity } = this.props

        const lead = this.props.rowData

        return <ProviderTable.Row {...this.props}>
            <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
            <Cell>{lead.title}</Cell>
            <DateCell date={lead.validatedAt} format="datetime" />
            <Cell>{lead.geo.cityName}</Cell>
            <Cell>{lead.industries.primary && lead.industries.primary.identifier}</Cell>
            <Cell>{lead.industries.secondary && lead.industries.secondary.name}</Cell>
            <Cell style={{ color: successColor }}>
                {lead.quotesInfo.count}
                {' '}
                /
                {lead.quotesInfo.max}
            </Cell>
            <EditCell
                value={leadActivity && leadActivity.note ? leadActivity.note.text : ''}
                onBlur={this.handleChangeNoteCell}
            />
        </ProviderTable.Row>
    }
}

const LeadTableRow = LeadRow as ComponentType

interface Criteria {
    visibleColumns: string[]
    employeeId: string[]
    geoRect: string
    geoPoint: string
    geoDistance: string
    locationId: string
}
interface SurveillanceStateProps {
    leads: Lead[]
    totalItems: number
    employees: Employee[]
    employeeId: number
    criteria: Criteria
}

interface SurveillanceDispatchProps {
    getCompletedCallFlowLeads: typeof getCompletedCallFlowLeads
    getActivities: typeof getActivities
}

interface SurveillancePathProps {
    location: {
        query: Criteria
    }
}

type CompletedCallSurveillanceModel = SurveillanceStateProps & SurveillanceDispatchProps & SurveillancePathProps

@connect<SurveillanceStateProps, SurveillanceDispatchProps, SurveillancePathProps>(
    (state, { location: { query } }) => ({
        leads: completedCallFlowLeadsSelector(state),
        totalItems: state.pages.completedCallFlow.pagination.total,
        employees: employeeSelector(state),
        employeeId: state.auth.employeeId,
        criteria: {
            visibleColumns: splitFilterString(query.visibleColumns),
            employeeId: splitFilterString(query.employeeId || ''),
            geoRect: query.geoRect || '',
            geoPoint: query.geoPoint || '',
            geoDistance: query.geoDistance || '',
            locationId: query.locationId || '',
        },
    }),
    {
        getCompletedCallFlowLeads,
        getActivities,
    }
)
export default class CompletedCallSurveillancePage extends PureComponent<CompletedCallSurveillanceModel> {
    retrieveData = async (filterCriteria, append?) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
        })
        const res: any = await this.props.getCompletedCallFlowLeads(criteria, append)
        if (res.result.length) this.props.getActivities({ leadId: res.result })
        return res
    }

    render() {
        const {
            leads,
            criteria,
            employees,
            totalItems,
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    Completed call surveillance 
                    {' '}
                    <span className="text-muted">
                        (
                        {leads.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <Card.Content modifiers="pB_0">
                    <Alert bsStyle="warning" style={{ fontSize: '14px' }}>
                        Here in the “Completed call” list you can see 
                        {' '}
                        <strong>all leads that have just gotten all quotes</strong>
                        , which means the lead is completed (the lead’s current quotes equal the max quotes that are set). Please be aware here that this does not apply for leads where no quotes could be found, because there would be nothing to match here.
                        <br/>
                        This list shows all leads that are in the current “Completed call” interval and the criteria for these leads are as follows:
                        <ol>
                            <li>the lead is not an exclusive lead</li>
                            <li>one or more of the lead’s quotes has a value over 1000 euros</li>
                        </ol>
                    </Alert>
                </Card.Content>
                <ProviderTable
                    pageName="completedCallFlow"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <ProviderTable.FilterContainer>
                        <DropdownFilter
                            selectedIds={criteria.employeeId}
                            items={Object.values(employees)}
                            name="employeeId"
                            label="Employee"
                            search={false}
                        />
                        <LocationFilter
                            label="Location"
                            name="location"
                            locationId={criteria.locationId}
                            geoDistance={criteria.geoDistance}
                        />
                    </ProviderTable.FilterContainer>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="id">ID</Column>
                            <Column name="lead_title">Lead title</Column>
                            <Column name="validated_at">Validated&nbsp;at</Column>
                            <Column name="zip_code_city">Location</Column>
                            <Column name="industry">Industry</Column>
                            <Column name="sub_industry">Sub industry</Column>
                            <Column name="quotes">Quotes</Column>
                            <Column name="notes">Notes</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={leads}
                            noDataText="We couldn't find any leads matching your selection."
                        >
                            <LeadTableRow />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>
            </Card>
        )
    }
}
