import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const REJECT_QUOTES = 'leads/REJECT_QUOTES'
export const REJECT_QUOTES_SUCCESS = 'leads/REJECT_QUOTES_SUCCESS'
export const REJECT_QUOTES_FAIL = 'leads/REJECT_QUOTES_FAIL'

export const GET_QUOTE = 'quotes/GET_QUOTE'
export const GET_QUOTE_SUCCESS = 'quotes/GET_QUOTE_SUCCESS'
export const GET_QUOTE_FAIL = 'quotes/GET_QUOTES_FAIL'

export const DELETE_QUOTE = 'quotes/DELETE_QUOTE'
export const DELETE_QUOTE_SUCCESS = 'quotes/DELETE_QUOTE_SUCCESS'
export const DELETE_QUOTE_FAIL = 'quotes/DELETE_QUOTE_FAIL'

export const GET_QUOTES = 'quotes/GET_QUOTES'
export const GET_QUOTES_SUCCESS = 'quotes/GET_QUOTES_SUCCESS'
export const GET_QUOTES_FAIL = 'quotes/GET_QUOTES_FAIL'

export const GET_PARTNER_QUOTES = 'quotes/GET_PARTNER_QUOTES'
export const GET_PARTNER_QUOTES_SUCCESS = 'quotes/GET_PARTNER_QUOTES_SUCCESS'
export const GET_PARTNER_QUOTES_FAIL = 'quotes/GET_PARTNER_QUOTES_FAIL'

export const DELETE_QUOTE_ITEM = 'quotes/DELETE_QUOTE_ITEM'
export const DELETE_QUOTE_ITEM_SUCCESS = 'quotes/DELETE_QUOTE_ITEM_SUCCESS'
export const DELETE_QUOTE_ITEM_FAIL = 'quotes/DELETE_QUOTE_ITEM_FAIL'

export const ADD_QUOTE_ITEM = 'quotes/ADD_QUOTE_ITEM'
export const ADD_QUOTE_ITEM_SUCCESS = 'quotes/ADD_QUOTE_ITEM_SUCCESS'
export const ADD_QUOTE_ITEM_FAIL = 'quotes/ADD_QUOTE_ITEM_FAIL'

export const UPDATE_QUOTE = 'quotes/UPDATE_QUOTE'
export const UPDATE_QUOTE_SUCCESS = 'quotes/UPDATE_QUOTE_SUCCESS'
export const UPDATE_QUOTE_FAIL = 'quotes/UPDATE_QUOTE_FAIL'

export const CREATE_QUOTE_FEE = 'quotes/CREATE_QUOTE_FEE_QUOTE'
export const CREATE_QUOTE_FEE_SUCCESS = 'quotes/CREATE_QUOTE_FEE_SUCCESS'
export const CREATE_QUOTE_FEE_FAIL = 'quotes/CREATE_QUOTE_FEE_FAIL'

export const DELETE_QUOTE_FEE = 'quotes/DELETE_QUOTE_FEE_QUOTE'
export const DELETE_QUOTE_FEE_SUCCESS = 'quotes/DELETE_QUOTE_FEE_SUCCESS'
export const DELETE_QUOTE_FEE_FAIL = 'quotes/DELETE_QUOTE_FEE_FAIL'

export const CREATE_QUOTE = 'quotes/CREATE_QUOTE_QUOTE'
export const CREATE_QUOTE_SUCCESS = 'quotes/CREATE_QUOTE_SUCCESS'
export const CREATE_QUOTE_FAIL = 'quotes/CREATE_QUOTE_FAIL'

export const CLOSE_RESERVATION_LEAD_QUOTE = 'quotes/CLOSE_RESERVATION_LEAD_QUOTE'
export const CLOSE_RESERVATION_LEAD_QUOTE_SUCCESS = 'quotes/CLOSE_RESERVATION_LEAD_QUOTE_SUCCESS'
export const CLOSE_RESERVATION_LEAD_QUOTE_FAIL = 'quotes/CLOSE_RESERVATION_LEAD_QUOTE_FAIL'

export const rejectQuotes = (
    leadId: number
) => ({
    [CALL_API]: {
        types: [ REJECT_QUOTES, REJECT_QUOTES_SUCCESS, REJECT_QUOTES_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        options: {
            method: 'post',
            body: {
                action: 'reject_all_quotes',
            },
        },
    },
    leadId,
})

export const deleteQuote = (
    leadId: number,
    quoteId: number
) => ({
    [CALL_API]: {
        types: [ DELETE_QUOTE, DELETE_QUOTE_SUCCESS, DELETE_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}`,
        options: {
            method: 'delete',
        },
    },
    quoteId,
})

export const getQuote = (
    leadId: number,
    quoteId: number
) => ({
    [CALL_API]: {
        types: [ GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}`,
        schema: schemas.quote,
    },
})

interface GetQuotesModel {
    criteria: ({
        partnerId: number
    } | {
        leadId: number
        isPublished: boolean
    } | {
        column: string
        direction: string
        isAccepted: string
        isPublished: string
        sort: string
        geoCode: string
    }) & {
        limit?: number
    }
    types?: string[]
    append?: boolean
}
export const getQuotes = (
    { criteria, types, append }: GetQuotesModel
) => ({
    [CALL_API]: {
        types: types || [ GET_QUOTES, GET_QUOTES_SUCCESS, GET_QUOTES_FAIL ],
        endpoint: '/leads/quotes',
        schema: schemas.quotes,
        options: {
            body: criteria,
        },
    },
    append,
})

export const getPartnerQuotes = (criteria, append?) => getQuotes({
    types: [ GET_PARTNER_QUOTES, GET_PARTNER_QUOTES_SUCCESS, GET_PARTNER_QUOTES_FAIL ],
    criteria,
    append,
})

interface AddQuoteItemModel {
    title: string
    description: string
    type: string
    quantity: number
    amountExclVat: { amount: number }
}
export const addQuoteItem = (
    leadId: number,
    quoteId: number,
    body: AddQuoteItemModel
) => ({
    [CALL_API]: {
        types: [ ADD_QUOTE_ITEM, ADD_QUOTE_ITEM_SUCCESS, ADD_QUOTE_ITEM_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/items`,
        schema: schemas.quoteItem,
        options: {
            method: 'post',
            body,
        },
    },
    quoteId,
})

export const deleteQuoteItem = (
    leadId: number,
    quoteId: number,
    itemId: number
) => ({
    [CALL_API]: {
        types: [ DELETE_QUOTE_ITEM, DELETE_QUOTE_ITEM_SUCCESS, DELETE_QUOTE_ITEM_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/items/${itemId}`,
        options: {
            method: 'delete',
        },
    },
    quoteId,
    itemId,
})

interface UpdateQuoteModel {
    title: string
    description: string
    partnerTerms: string
}
export const updateQuote = (
    leadId: number,
    quoteId: number,
    body: UpdateQuoteModel
) => ({
    [CALL_API]: {
        types: [ UPDATE_QUOTE, UPDATE_QUOTE_SUCCESS, UPDATE_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}`,
        schema: schemas.quote,
        options: {
            method: 'put',
            body,
        },
    },
})

interface QuoteFeeModel {
    leadId: number,
    employeeId: number,
    quoteFee: {
        amount: number,
        currency: string,
    },
}
export const createQuoteFee = (
    body: QuoteFeeModel
) => ({
    [CALL_API]: {
        types: [ CREATE_QUOTE_FEE, CREATE_QUOTE_FEE_SUCCESS, CREATE_QUOTE_FEE_FAIL ],
        endpoint: `/leads/${body.leadId}/quotefees`,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deleteQuoteFee = (leadId: number) => ({
    [CALL_API]: {
        types: [ DELETE_QUOTE_FEE, DELETE_QUOTE_FEE_SUCCESS, DELETE_QUOTE_FEE_FAIL ],
        endpoint: `/leads/${leadId}/quotefees`,
        options: {
            method: 'delete',
        },
    },
})


interface CreateQuoteModel {
    partner: {
        id: number
    }
    reservationExpiresAt: string
}
export const createQuote = (
    leadId,
    body: CreateQuoteModel
) => ({
    [CALL_API]: {
        types: [ CREATE_QUOTE, CREATE_QUOTE_SUCCESS, CREATE_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body,
        },
    },
})

export const closeReservationLeadQuote = (
    leadId: number,
    leadQuoteId: number
) => ({
    [CALL_API]: {
        types: [ CLOSE_RESERVATION_LEAD_QUOTE, CLOSE_RESERVATION_LEAD_QUOTE_SUCCESS, CLOSE_RESERVATION_LEAD_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${leadQuoteId}/actions`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body: {
                action: 'close_reservation',
            },
        },
    },
    leadId,
})
