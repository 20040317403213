import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_ACTIVITIES = 'newsFeed/GET_ACTIVITIES'
export const GET_ACTIVITIES_SUCCESS = 'newsFeed/GET_ACTIVITIES_SUCCESS'
export const GET_ACTIVITIES_FAIL = 'newsFeed/GET_ACTIVITIES_FAIL'

export const CREATE_LEAD_NOTE_ACTIVITY = 'newsFeed/CREATE_LEAD_NOTE_ACTIVITY'
export const CREATE_LEAD_NOTE_ACTIVITY_SUCCESS = 'newsFeed/CREATE_LEAD_NOTE_ACTIVITY_SUCCESS'
export const CREATE_LEAD_NOTE_ACTIVITY_FAIL = 'newsFeed/CREATE_LEAD_NOTE_ACTIVITY_FAIL'

export const CREATE_PARTNER_NOTE_ACTIVITY = 'newsFeed/CREATE_PARTNER_NOTE_ACTIVITY'
export const CREATE_PARTNER_NOTE_ACTIVITY_SUCCESS = 'newsFeed/CREATE_PARTNER_NOTE_ACTIVITY_SUCCESS'
export const CREATE_PARTNER_NOTE_ACTIVITY_FAIL = 'newsFeed/CREATE_PARTNER_NOTE_ACTIVITY_FAIL'

export const CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY = 'newsFeed/CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY'
export const CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_SUCCESS = 'newsFeed/CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_SUCCESS'
export const CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_FAIL = 'newsFeed/CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_FAIL'

export const DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY = 'newsFeed/DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY'
export const DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_SUCCESS = 'newsFeed/DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_SUCCESS'
export const DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_FAIL = 'newsFeed/DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_FAIL'

export const GET_NEWS_FEED = 'newsFeed/GET_NEWS_FEED'
export const GET_NEWS_FEED_SUCCESS = 'newsFeed/GET_NEWS_FEED_SUCCESS'
export const GET_NEWS_FEED_FAIL = 'newsFeed/GET_NEWS_FEED_FAIL'

export const GET_TOTAL_CALL_ACTIVITIES = 'newsFeed/GET_TOTAL_CALL_ACTIVITIES'
export const GET_TOTAL_CALL_ACTIVITIES_SUCCESS = 'newsFeed/GET_TOTAL_CALL_ACTIVITIES_SUCCESS'
export const GET_TOTAL_CALL_ACTIVITIES_FAIL = 'newsFeed/GET_TOTAL_CALL_ACTIVITIES_FAIL'

interface CreateNoteActivityModel {
    employeeId: number
    note: string
    isPinned?: boolean
    satisfaction?: {
        satisfactionLevelId: number,
    }
}

interface CreatePartnerEmployeeNoteActivityModel {
    content: string
    lead_id: number
}

export const createPartnerNoteActivity = (
    partnerId: number,
    body: CreateNoteActivityModel
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_NOTE_ACTIVITY, CREATE_PARTNER_NOTE_ACTIVITY_SUCCESS, CREATE_PARTNER_NOTE_ACTIVITY_FAIL ],
        endpoint: `/partners/${partnerId}/notes`,
        schema: schemas.activities,
        options: {
            method: 'post',
            body,
        },
    },
})

interface UpdateNoteActivity {
    isPinned: boolean
}

export const updatePartnerNoteActivity = (
    partnerId: number,
    noteId: number,
    body: UpdateNoteActivity
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_NOTE_ACTIVITY, CREATE_PARTNER_NOTE_ACTIVITY_SUCCESS, CREATE_PARTNER_NOTE_ACTIVITY_FAIL ],
        endpoint: `/partners/${partnerId}/notes/${noteId}`,
        options: {
            method: 'put',
            body,
        },
    },
})

export const createPartnerEmployeeNoteActivity = (
    partnerId: number,
    body: CreatePartnerEmployeeNoteActivityModel
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY, CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_SUCCESS, CREATE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_FAIL ],
        endpoint: `/partners/${partnerId}/employeenotes`,
        schema: schemas.activities,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerEmployeeNoteActivity = (
    partnerId: number,
    partnerEmployeeNoteId: number,
) => ({
    [CALL_API]: {
        types: [ DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY, DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_SUCCESS, DELETE_PARTNER_EMPLOYEE_NOTE_ACTIVITY_FAIL ],
        endpoint: `/partners/${partnerId}/employeenotes/${partnerEmployeeNoteId}`,
        schema: schemas.activities,
        options: {
            method: 'delete',
        },
    },
})

interface GetActivitiesModel {
    partnerUserId?: number | number[]
    activityId?: number | number[]
    employeeId?: number | number[]
    partnerId?: number | number[]
    clientId?: number | number[]
    leadId?: number | number[]
    geoCode?: string
}
export const getActivities = (
    criteria: GetActivitiesModel
) => ({
    [CALL_API]: {
        types: [ GET_ACTIVITIES, GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_FAIL ],
        endpoint: '/activities',
        schema: schemas.activities,
        options: {
            body: {
                ...criteria,
                limit: 10000,
            },
        },
    },
})

export const createLeadNoteActivity = (
    leadId: number,
    body: CreateNoteActivityModel
) => ({
    [CALL_API]: {
        types: [ CREATE_LEAD_NOTE_ACTIVITY, CREATE_LEAD_NOTE_ACTIVITY_SUCCESS, CREATE_LEAD_NOTE_ACTIVITY_FAIL ],
        endpoint: `/leads/${leadId}/notes`,
        options: {
            method: 'post',
            body,
        },
    },
})

interface GetNewsFeedModel {
    partnerId?: number
    leadId?: number
    type: string
    page?: number
    sort?: string
}
export const getNewsFeed = (
    criteria: GetNewsFeedModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [ GET_NEWS_FEED, GET_NEWS_FEED_SUCCESS, GET_NEWS_FEED_FAIL ],
        endpoint: '/activities',
        schema: schemas.activities,
        options: {
            body: {
                ...criteria,
                limit: 20,
            },
        },
    },
    append,
})

interface GetTotalActivitiesCallModel {
    partnerId?: number
    leadId?: number
}
export const getTotalActivitiesCall = (
    criteria: GetTotalActivitiesCallModel
) => ({
    [CALL_API]: {
        types: [ GET_TOTAL_CALL_ACTIVITIES, GET_TOTAL_CALL_ACTIVITIES_SUCCESS, GET_TOTAL_CALL_ACTIVITIES_FAIL ],
        endpoint: '/activities',
        schema: schemas.activities,
        options: {
            body: {
                ...criteria,
                type: 'phonecalls_create,lead_call',
                limit: 2000,
            },
        },
    },
})
