import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'
import { AllocationLimit } from '../../reducers/types/entities/AllocationLimit'

export const GET_BUSINESS_UNITS_ALLOCATION_LIMITS = actionTypesCreator(
    'allocationLimits',
    'GET_BUSINESS_UNITS_ALLOCATION_LIMITS'
)

export const CREATE_BUSINESS_UNITS_ALLOCATION_LIMITS = actionTypesCreator(
    'allocationLimits',
    'CREATE_BUSINESS_UNITS_ALLOCATION_LIMITS'
)

export const UPDATE_BUSINESS_UNITS_ALLOCATION_LIMITS = actionTypesCreator(
    'allocationLimits',
    'UPDATE_BUSINESS_UNITS_ALLOCATION_LIMITS'
)

interface GetAllocationsModel {
    effectiveAtGte?: string
    effectiveAtLte?: string
    partnerBusinessUnitId: number[] | number
}

export const getBusinessUnitsAllocationLimits = (
    partnerId: number,
    criteria: GetAllocationsModel
) => ({
    [CALL_API]: {
        types: GET_BUSINESS_UNITS_ALLOCATION_LIMITS,
        endpoint: `/partners/${partnerId}/businessunits/allocationlimits`,
        schema: schemas.allocationLimits,
        options: {
            body: {
                limit: 100,
                ...criteria,
            },
        },
    },
})

export const createBusinessUnitsAllocationLimits = (
    partnerId: number,
    businessUnitId: number,
    body: Partial<AllocationLimit>
) => ({
    [CALL_API]: {
        types: CREATE_BUSINESS_UNITS_ALLOCATION_LIMITS,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/allocationlimits`,
        schema: schemas.allocationLimit,
        options: {
            method: 'post',
            body,
        },
    },
})

export const updateBusinessUnitsAllocationLimits = (
    partnerId: number,
    businessUnitId: number,
    body: Partial<AllocationLimit>
) => ({
    [CALL_API]: {
        types: UPDATE_BUSINESS_UNITS_ALLOCATION_LIMITS,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/allocationlimits/${body.id}`,
        schema: schemas.allocationLimit,
        options: {
            method: 'put',
            body,
        },
    },
})
