import React, { PureComponent, ComponentType } from 'react'
import { connect } from '../../../utils/connect'
import { toggleHideFromWebsite } from '../../../actions/partners'
import { Card } from '../../blocks/Card'
import { Col, Row } from 'react-bootstrap'
import ActionCheckBox from '../../elements/ActionCheckBox'
import { toastr } from 'react-redux-toastr'
// import { firstLetterUpperCase } from '../../../utils'

interface StateProps {
    hideFromWebsite: boolean,
}

interface DispatchProps {
    toggleHideFromWebsite: typeof toggleHideFromWebsite
}

interface PathProps {
    partnerId: number
}

type PublicProfileModel = StateProps & DispatchProps & PathProps

@connect<StateProps, DispatchProps, PathProps>(
    (state, props) => ({
        hideFromWebsite: state.entities.partners[props.partnerId].hideFromWebsite,
    }),
    {
        toggleHideFromWebsite,
    }
)
class PublicProfile extends PureComponent<PublicProfileModel> {
    handleHideFromWebsiteChange = async (checked) => {
        const { partnerId } = this.props
        try {
            if (checked) {
                await this.props.toggleHideFromWebsite(partnerId, false)
                toastr.success('Partner will be shown on public website')
            } else {
                await this.props.toggleHideFromWebsite(partnerId, true)
                toastr.success('Partner hidden from public website')
            }
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        const {
            hideFromWebsite,
        } = this.props
        return <Card>
            <Card.Header>Public profile</Card.Header>
            <Card.Content>
                <Row>
                    <Col md={4}>
                        <ActionCheckBox
                            onClick={this.handleHideFromWebsiteChange}
                            value={hideFromWebsite}
                            checked={hideFromWebsite}
                            text={'Hide from public website'}
                        />
                    </Col>
                </Row>
            </Card.Content>
        </Card>
    }
}

export default PublicProfile as unknown as ComponentType<PathProps>
