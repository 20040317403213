import { CALL_API } from '../middleware/api'
import { Amount } from '../reducers/types/entities/Amount'
import * as schemas from '../schemas'

export const GET_PARTNER_CONTRACT_OFFER_GROUPS = 'partnerContractOfferGroups/GET_PARTNER_CONTRACT_OFFER_GROUPS'
export const GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS = 'partnerContractOfferGroups/GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS'
export const GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL = 'partnerContractOfferGroups/GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL'

export const CREATE_PARTNER_CONTRACT_OFFER_GROUP = 'partnerContractOfferGroups/CREATE_PARTNER_CONTRACT_OFFER_GROUP'
export const CREATE_PARTNER_CONTRACT_OFFER_GROUP_SUCCESS = 'partnerContractOfferGroups/CREATE_PARTNER_CONTRACT_OFFER_GROUP_SUCCESS'
export const CREATE_PARTNER_CONTRACT_OFFER_GROUP_FAIL = 'partnerContractOfferGroups/CREATE_PARTNER_CONTRACT_OFFER_GROUP_FAIL'

export const ARCHIVE_PARTNER_CONTRACT = 'partnerContractOfferGroups/ARCHIVE_PARTNER_CONTRACT'
export const ARCHIVE_PARTNER_CONTRACT_SUCCESS = 'partnerContractOfferGroups/ARCHIVE_PARTNER_CONTRACT_SUCCESS'
export const ARCHIVE_PARTNER_CONTRACT_FAIL = 'partnerContractOfferGroups/ARCHIVE_PARTNER_CONTRACT_FAIL'

export const getPartnerContractOfferGroups = (
    partnerId: number | string
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_CONTRACT_OFFER_GROUPS,
            GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS,
            GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contractoffergroups`,
        schema: schemas.partnerContractOfferGroups,
    },
})

interface CreatePartnerContractOfferGroupModel {
    offers: Array<{
        offerItems: Array<{
            leadQuota: number | string
            revenueSegment: {
                id: number | string
            }
        }>
        sort: number
        priceExclVat: Amount
    }>
}

export const createPartnerContractOfferGroup = (
    partnerId: number | string,
    body: CreatePartnerContractOfferGroupModel
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT_OFFER_GROUP,
            CREATE_PARTNER_CONTRACT_OFFER_GROUP_SUCCESS,
            CREATE_PARTNER_CONTRACT_OFFER_GROUP_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contractoffergroups`,
        schema: schemas.partnerContractOfferGroup,
        options: {
            method: 'post',
            body,
        },
    },
})

export const archivePartnerContractOfferGroup = (
    partnerId: number | string,
    partnerContractOfferGroupId: number | string
) => ({
    [CALL_API]: {
        types: [
            ARCHIVE_PARTNER_CONTRACT,
            ARCHIVE_PARTNER_CONTRACT_SUCCESS,
            ARCHIVE_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contractoffergroups/${partnerContractOfferGroupId}/actions`,
        schema: schemas.partnerContractOfferGroup,
        options: {
            method: 'post',
            body: {
                action: 'archive',
            },
        },
    },
})
