import React, { ComponentType, PureComponent } from 'react'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { Button } from '../elements/Button'
import {
    createPartnerUniqueSellingPoint, deletePartnerUniqueSellingPoint,
    getPartnerSellingPoints,
    updatePartnerUniqueSellingPoint
} from '../../actions/partners'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { PartnerUniqueSellingPoint } from '../../reducers/types/entities/PartnerUniqueSellingPoint'
import styled from 'styled-components'
import { Flex } from '../elements/Flex'

const maxLength100 = [ value => value && value.length > 100 ? 'Must be 100 characters or less' : undefined ]
const maxSellingPoints = 6

const StyledSpan= styled.span`
    text-align: left;
    font-style: italic;
    padding-right: 170px;
`

interface EditPartnerUniqueSellingPointsModel {
    modalProps: {
        partnerId: number
        sellingPoints: PartnerUniqueSellingPoint[]
        disabled: boolean
    },
    createPartnerUniqueSellingPoint: typeof createPartnerUniqueSellingPoint
    updatePartnerUniqueSellingPoint: typeof updatePartnerUniqueSellingPoint
    deletePartnerUniqueSellingPoint: typeof deletePartnerUniqueSellingPoint
    getPartnerSellingPoints: typeof getPartnerSellingPoints
    isLoadingSellingPoints: boolean
    hideModal: typeof hideModal
    submit: typeof submit
}

@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        createPartnerUniqueSellingPoint,
        updatePartnerUniqueSellingPoint,
        deletePartnerUniqueSellingPoint,
        getPartnerSellingPoints,
        hideModal,
        submit,
    }
)
class EditPartnerUniqueSellingPointsModal extends PureComponent<EditPartnerUniqueSellingPointsModel> {
    hideModal = () => this.props.hideModal()

    savePartnerUniqueSellingPoint = async values => {
        const self = this.props
        for (let index = 0; index< values.points.length; ++index) {
            const value = values.points[index]
            if (index !== value.id) {
                if (value.text.length === 0) {
                    await self.deletePartnerUniqueSellingPoint(self.modalProps.partnerId, value.id)
                } else {
                    await self.updatePartnerUniqueSellingPoint(self.modalProps.partnerId, value.id, value)
                }
            } else {
                if (value.text.length !== 0) {
                    await self.createPartnerUniqueSellingPoint(self.modalProps.partnerId, value)
                }
            }
        }

        await self.getPartnerSellingPoints(self.modalProps.partnerId)
        this.hideModal()
    }

    save = () => {
        this.props.submit('editPartnerUniqueSellingPointsForm')
    }

    buildSellingPointsList = values => {
        const newPoints = {}
        newPoints['points'] = []
        values.map((n, i) => {
            newPoints['points'][i] = n

        })
        for (let i = newPoints['points'].length; i < maxSellingPoints; i++) {
            let point = { text: '', id: i }
            newPoints['points'].push(point)
        }

        return newPoints
    }

    render() {
        const newPoints = this.buildSellingPointsList(this.props.modalProps.sellingPoints)

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header>Edit Partner Unique Selling Points</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="editPartnerUniqueSellingPointsForm"
                    onSubmit={this.savePartnerUniqueSellingPoint}
                    initialValues={newPoints}
                >
                    {newPoints['points'].map((point, i) =>
                        <Field
                            name={'points['+i+'].text'}
                            label=""
                            component={FieldGroup}
                            type="string"
                            validate={maxLength100}
                        />
                    )}
                </ReduxForm>
            </Modal.Body>
            <Modal.Footer>
                <StyledSpan>Maximum 100 characters per field</StyledSpan>
                <Flex>
                    <Button
                        modifiers={[ 'secondary', 'mR_1' ]}
                        onClick={this.hideModal}
                    >
                        Go Back
                    </Button>
                    <Button
                        modifiers={[ 'action' ]}
                        onClick={this.save}
                    >
                        Save
                    </Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    }
}

export default (EditPartnerUniqueSellingPointsModal as any) as ComponentType
