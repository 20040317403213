import { createSelector } from 'reselect'

export const certificationsEntities = state => state.entities.certifications

export const allCertificationsId = state => state.subjects.certifications.all.ids

export const partnerCertificationsId = state => state.subjects.certifications.partner.ids

export const leadCertificationsId = state => state.subjects.certifications.lead.ids

export const allCertificationsSelector = createSelector(
    allCertificationsId, certificationsEntities,
    (ids, entities) => ids.map(id => ({ ...entities[id], name: entities[id].title })
    )
)

export const partnerCertificationsSelector = createSelector(
    partnerCertificationsId, certificationsEntities,
    (ids, entities) => ids.map(id => entities[id])
)

const industryId = (_, id) => id

export const leadIndustryCertificationsSelector = createSelector(
    allCertificationsSelector, industryId,
    (certifications, filterId) => certifications.filter(cer => cer.industryId === Number(filterId))
)
