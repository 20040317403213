import React, { memo } from 'react'
import { HelpBlock } from 'react-bootstrap'
import { WrappedFieldMetaProps } from 'redux-form'

interface ErrorModel {
    meta: Partial<WrappedFieldMetaProps>
}
const Error = memo(({ meta: { touched, error, warning } }: ErrorModel) =>
    (touched && (error || warning) ?
        <div className="has-error"><HelpBlock>{error || warning}</HelpBlock></div> :
        null
    )
)
export default Error
