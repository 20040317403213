import React, { PureComponent, ComponentType, Fragment } from 'react'
import { DropdownButton, Modal } from 'react-bootstrap'
import { connect } from '../../utils/connect'
import { hideModal } from '../../actions/modal'
import { Table, Column, Rows, Cell, DateCell } from '../elements/table'
import Header from '../elements/table/Header'
import styled from 'styled-components'
import { Flex } from '../elements/Flex'
import CardText from '../elements/text/CardText'
import { Card } from '../blocks/Card'
import Datetime from 'react-datetime'
import { CheckboxInputField } from '../elements/forms/inputs/CheckboxGroup'
import { BusinessUnits } from '../../reducers/types/entities/BusinessUnit'
import { Allocation } from '../../reducers/types/entities/Allocation'
import moment, { Moment } from 'moment'
import { getAllocations } from '../../state/allocations/actions'
import { getBusinessUnitAllocationsQuotas } from '../../state/allocationsQuotas/actions'
import { allocationsSelector } from '../../state/allocations/selectors'
import { replace } from 'connected-react-router'
import queryString from 'query-string'
import { Employees } from '../../reducers/types/entities/Employee'
import { Button } from '../elements/Button'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 80vw;
        max-width: 1300px;
    }
`

const ModalBody = styled(Card.Content)`
  max-height: 80vh;
  overflow: auto;
`

const DateFilterDropdownButton = styled(DropdownButton)`
    padding: 7px 14px;
    font-size: 15px;
    margin-right: 20px;
`
const BUFilterDropdownButton = styled(DropdownButton)`
    padding: 7px 14px;
    font-size: 15px;
    + ul {
      padding: 10px;

      & > div {
        width: max-content;
      }
    }
`

const RowData = ({ rowData, businessUnits, employees }) => {
    const allocation = rowData
    const bu = businessUnits[allocation.partnerBusinessUnitId]
    const deallocationEmployee = employees[allocation.deallocatedByEmployeeId]
    return <tr>
        <Cell>{bu && bu.displayName}</Cell>
        <Cell>{allocation.leadId}</Cell>
        <DateCell date={allocation.processedAt || allocation.createdAt} format="datetime"/>
        {(allocation.quote && allocation.quote.publishedAt)
            ? <DateCell date={allocation.quote.publishedAt} format="datetime"/> : <Cell>-</Cell>}
        {(allocation.quote && allocation.quote.acceptedAt)
            ? <DateCell date={allocation.quote.acceptedAt} format="datetime"/> : <Cell>-</Cell>}
        {allocation.deallocatedAt ? <DateCell date={allocation.deallocatedAt} format="datetime" /> : <Cell>-</Cell>}
        {deallocationEmployee ? <Cell>{deallocationEmployee.name}</Cell> : <Cell>-</Cell>}
    </tr>
}

const DeliveriesRow = RowData as ComponentType<{businessUnits: BusinessUnits, employees: Employees}>

interface BusinessUnitsDeliveriesModel {
    allocations: Allocation[]
    entitiesBusinessUnits: BusinessUnits
    entitiesEmployees: Employees
    modalProps
    isLoading: boolean
    getBusinessUnitAllocationsQuotas: typeof getBusinessUnitAllocationsQuotas
    getAllocations: typeof getAllocations
    hideModal: typeof hideModal
    replace: typeof replace
}

interface ClassState {
    startsAt: Moment
    endsAt: Moment
    checkedBu: {[key: number]: boolean}
    totalTarget: number
}

@connect(
    state => ({
        allocations: allocationsSelector(state),
        entitiesBusinessUnits: state.entities.businessUnits,
        entitiesEmployees: state.entities.employees,
        modalProps: state.modal,
        isLoading: state.subjects.allocations.all.isLoading,
    }),
    {
        getBusinessUnitAllocationsQuotas,
        getAllocations,
        hideModal,
        replace,
    }
)
class BusinessUnitsDeliveriesModal extends PureComponent<BusinessUnitsDeliveriesModel, ClassState> {
    constructor(props) {
        super(props)

        const checkedBu = props.modalProps.businessUnits.reduce((acc, el) => ({ ...acc, [el.id]: true }), {})

        this.state = {
            totalTarget: 0,
            startsAt: null,
            endsAt: null,
            checkedBu,
        }
    }

    componentDidMount() {
        this.allocationRequest()
    }

    hideModal = () => this.props.hideModal()

    allocationRequest = () => {
        const { startsAt, endsAt, checkedBu } = this.state
        const partnerBusinessUnitId = []
        for (const id in checkedBu) if (checkedBu.hasOwnProperty(id), checkedBu[id]) partnerBusinessUnitId.push(id)
        const query: any = {}
        if (endsAt) query.endsAt = endsAt.format('YYYY-MM-DD')
        if (startsAt) query.startsAt = startsAt.format('YYYY-MM-DD')
        this.props.replace({
            pathname: window.location.pathname,
            search: queryString.stringify(query),
        })

        this.props.getAllocations({
            partnerId: this.props.modalProps.partnerId,
            partnerBusinessUnitId,
            startsAt: startsAt && startsAt.format('YYYY-MM-DD'),
            endsAt: endsAt && endsAt.format('YYYY-MM-DD'),
            isUsed: 1,
            isProcessed: 1,
            limit: 1000,
        })
        const resAllocationQuotas: any = this.props.getBusinessUnitAllocationsQuotas(this.props.modalProps.partnerId, {
            partnerBusinessUnitId,
            effectiveAtGte: startsAt && startsAt.format('YYYY-MM-DD'),
            effectiveAtLte: endsAt && endsAt.format('YYYY-MM-DD'),
        })
        resAllocationQuotas.then(res => {
            if (!res.result.length) this.props.getBusinessUnitAllocationsQuotas(this.props.modalProps.partnerId, {
                partnerBusinessUnitId,
                effectiveAtLte: endsAt && endsAt.format('YYYY-MM-DD'),
                limit: 1,
            })
        })
    }

    onSelectDeliveriesDate = (period: 'startsAt' | 'endsAt', date) => {
        const dateTime = period === 'endsAt' ? moment(date).endOf('month') : date
        const periodDate: any = { [period]: dateTime }
        this.setState({ ...periodDate })
    }

    changeBU = (selectedId, e) => {
        const  isChecked = e.target.checked
        this.setState((state, props) => {
            const checkedBu = {
                ...state.checkedBu,
                [selectedId]: isChecked,
            }

            const selectedBu = []

            for (const id in checkedBu) if (checkedBu.hasOwnProperty(id) && checkedBu[id])
                selectedBu.push(Number(id))
            const allocations = props.allocations.filter(allocation =>
                selectedBu.includes(allocation.partnerBusinessUnitId)
            )

            const totalTarget = selectedBu.reduce((acc, id) =>
                acc + this.props.entitiesBusinessUnits[id].currentAllocation.allocationQuotaPerRefill
            , 0)

            return ({ ...state, checkedBu, allocations, totalTarget })
        })
    }

    render() {
        const {
            allocations,
            entitiesBusinessUnits,
            entitiesEmployees,
            modalProps,
            isLoading,
        } = this.props

        const { startsAt, endsAt, checkedBu } = this.state

        const dateTitle =  (startsAt ? startsAt.format('YYYY-MM-DD') : 'Date') +
            (endsAt ? ' - ' + endsAt.format('YYYY-MM-DD') : '')

        const deallocated = allocations.filter(allocation => allocation.deallocatedAt)

        return <LgModal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton>Deliveries</Modal.Header>
            <Modal.Body>
                <Flex modifiers="justifySpaceBetween">
                    <Flex modifiers="alignCenter">
                        <CardText as="span" modifiers="mR_5">
                            Total delivered for period:
                            {allocations.length}
                        </CardText>
                        <CardText as="span">
                            Total deallocated:
                            {deallocated.length}
                        </CardText>
                    </Flex>
                    <DateFilterDropdownButton
                        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        title={dateTitle}
                        id="dropdown-no-caret"
                    >
                        <Flex>
                            <div className="text-center">
                                <Card.Label>From</Card.Label>
                                <Datetime
                                    onChange={date => this.onSelectDeliveriesDate('startsAt', date)}
                                    dateFormat="YYYY-M"
                                    input={false}
                                    open
                                />
                            </div>
                            <div className="text-center">
                                <Card.Label>to</Card.Label>
                                <Datetime
                                    onChange={date => this.onSelectDeliveriesDate('endsAt', date)}
                                    dateFormat="YYYY-M"
                                    input={false}
                                    open
                                />
                            </div>
                        </Flex>
                    </DateFilterDropdownButton>
                    <BUFilterDropdownButton
                        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        title="Business units"
                        id="dropdown-no-caret"
                    >
                        {modalProps.businessUnits.map(el =>
                            <CheckboxInputField
                                key={el.id}
                                text={<div>
                                    #
                                    {el.id}
                                    {' '}
                                    -
                                    {el.displayName}
                                    {' '}
                                    {el.partnerContract && <Fragment>
                                        <br/>
                                        <span>
                                            contract #
                                            {el.partnerContract.id}
                                        </span>
                                    </Fragment>}
                                </div>}
                                input={{
                                    value: checkedBu[el.id],
                                    onChange: e => this.changeBU(el.id, e),
                                }}
                                modifiers={[ 'pY_1' ]}
                            />
                        )}
                    </BUFilterDropdownButton>
                    <Button modifiers={[ 'action' ]} onClick={this.allocationRequest}>Apply filters</Button>
                </Flex>
            </Modal.Body>
            <ModalBody isLoading={isLoading} modifiers="p_0">
                <Table>
                    <Header>
                        <Column>Segmentation</Column>
                        <Column>Lead id</Column>
                        <Column>Allocated at</Column>
                        <Column>Quote published at</Column>
                        <Column>Quote accepted at</Column>
                        <Column>Deallocated at</Column>
                        <Column>Deallocated by</Column>
                    </Header>
                    <Rows
                        noDataText="No information."
                        items={allocations}
                    >
                        <DeliveriesRow businessUnits={entitiesBusinessUnits} employees={entitiesEmployees}/>
                    </Rows>
                </Table>
            </ModalBody>
        </LgModal>
    }
}

export default BusinessUnitsDeliveriesModal as unknown as ComponentType
