import React, { memo, ComponentType, PureComponent } from 'react'
import config from '../../config'
import { getIntervalLeads } from '../../actions/leads'
import { removeEmptyProperties, splitFilterString, determineDayPartName, determineDayPart } from '../../utils'
import { Card } from '../blocks/Card'
import { LeadStatusColor } from '../elements/LeadStatusColor'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { intervalLeadsSelector } from '../../selectors/lead'
import { connect } from '../../utils/connect'
import { Lead } from '../../reducers/types/entities/Lead'
import { getTotalActivitiesCall } from '../../actions/newsfeed'
import ProviderTable from '../blocks/ProviderTable'
import { Cell, LinkCell, DateCell, Column } from '../elements/table'
import Select from 'components/src/inputs/Select'
import Flex from 'components/src/layout/Flex'
import Checkbox from '../blocks/Checkbox'
import { showModal } from '../../actions/modal'
import { canChangeLeadStatus, bulkActionLeadStatuses, AVAILABLE_STATUS_FOR_BULK_ACTION } from './LeadListPage'

interface IntervalRowHOCProps {
    rowData: Lead
}

interface IntervalRowProps {
    addBulkItem: (id: number) => void
    selectedBulkItems: number[]
    canChangeLeadStatus: boolean
}

type IntervalRowPropsModel = IntervalRowProps & IntervalRowHOCProps;

const IntervalRow = memo((props: IntervalRowPropsModel) => {

    const { rowData: lead, addBulkItem, selectedBulkItems, canChangeLeadStatus } = props

    const disabled = AVAILABLE_STATUS_FOR_BULK_ACTION !== lead.status

    return <ProviderTable.Row {...props}>
        {canChangeLeadStatus && <Cell>
            <Checkbox
                disabled={disabled}
                checked={selectedBulkItems.includes(lead.id)}
                onChange={disabled ? (() => {}) : (() => addBulkItem(lead.id))}
            />
        </Cell>}
        <LinkCell to={`/leads/${lead.id}/validation`}>{lead.id}</LinkCell>
        <Cell>{lead.category && lead.category.title}</Cell>
        <Cell>
            {lead.industries.primary && lead.industries.primary.name}
            {lead.industries.primary && lead.industries.secondary && ' - '}
            {lead.industries.secondary && lead.industries.secondary.name}
        </Cell>
        <Cell>{lead.description}</Cell>
        <Cell>{lead.customerInfo.firstName + ' ' + lead.customerInfo.lastName}</Cell>
        <Cell>{lead.callCountBeforeValidation}</Cell>
        <DateCell date={lead.createdAt} format="datetime"/>
        <Cell>
            <LeadStatusColor leadStatus={lead.status} />
        </Cell>
    </ProviderTable.Row>
})

const IntervalTableRow = IntervalRow as unknown as ComponentType<IntervalRowProps>

interface CurrentIntervalLeadsListPropsModel {
    leads: Lead[]
    employees: object
    totalItems: number
    criteria: {
        visibleColumns: string[]
    }
    industryId?: string
    isLoading: boolean
    getIntervalLeads: typeof getIntervalLeads
    getTotalActivitiesCall: (body: object) => any
    showModal: typeof showModal
    canChangeLeadStatus: boolean
}

interface PropsModel {
    location: {
        query: {
            visibleColumns: string[]
        },
    }
}

@connect<any, any, PropsModel>(
    (state, { location: { query } } ) => ({
        leads: intervalLeadsSelector(state),
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        totalItems: state.pages.intervalLeads.pagination.total,
        canChangeLeadStatus: canChangeLeadStatus(state),
        criteria: {
            visibleColumns: splitFilterString(query.visibleColumns),
        },
        industryId: state.auth.wantedIndustryId ? String(state.auth.wantedIndustryId) : undefined,
    }),
    {
        getIntervalLeads,
        getTotalActivitiesCall,
        showModal,
    }
)
export default class CurrentIntervalLeadsList extends PureComponent<CurrentIntervalLeadsListPropsModel> {
    state = {
        bulkItems: []
    }

    retrieveData = async (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            latestCalledAtBefore: determineDayPart(),
            limit: 50,
            industryId: this.props.industryId,
        })
        const res: any = await this.props.getIntervalLeads(criteria, append)
        if (res.result.length) this.props.getTotalActivitiesCall({ leadId: res.result })
        return res
    }

    toggleArrayItem = (state, id) => {
        return  state.includes(id)
            ? state.filter(i => i !== id)
            : [ ...state, id ]
    }

    addBulkItem = (id) => {
        this.setState({
            bulkItems: this.toggleArrayItem(this.state.bulkItems, id)
        })
    }

    itemToLabel = (item) => item.name

    bulkActionChange = (item) => {
        this.state.bulkItems.length && this.props.showModal({
            name: 'bulkStatusLeadAction',
            statusId: item.id,
            statusName: item.name,
            leadIds: this.state.bulkItems,
        })
    }

    render() {
        const {
            leads,
            criteria,
            totalItems,
            canChangeLeadStatus
        } = this.props

        return (
            <Card margin="0">
                <Card.Header>
                    Leads in the
                    {' '}
                    {determineDayPartName()}
                    {' '}
                    interval
                    {' '}
                    <span className="text-muted">
                        (
                        {leads.length}
                        {' '}
                        /
                        {totalItems || 0}
                        )
                    </span>
                </Card.Header>
                <ProviderTable
                    pageName="intervalLeads"
                    retrieveData={this.retrieveData}
                    criteria={criteria}
                >
                    <Flex alignItems="center" justifyContent="flex-start" zIndex={10} gridGap={15}>
                        {canChangeLeadStatus && <Select
                            items={bulkActionLeadStatuses}
                            placeholder="Bulk Action"
                            onChange={this.bulkActionChange}
                            itemToLabel={this.itemToLabel}
                            disabled={!Boolean(this.state.bulkItems.length)}
                        />}
                        <ProviderTable.FilterContainer/>
                    </Flex>
                    <ProviderTable.LazyTable>
                        <ProviderTable.Header>
                            <Column name="id">ID</Column>
                            <Column name="lead_category">Lead category</Column>
                            <Column name="industry">Industry</Column>
                            <Column name="description">Description</Column>
                            <Column name="name">Name</Column>
                            <Column name="calls">Calls</Column>
                            <Column name="created_at">Created at</Column>
                            <Column name="status">Status</Column>
                        </ProviderTable.Header>
                        <ProviderTable.Rows
                            items={leads}
                            noDataText="We couldn't find any leads matching your selection."
                        >
                            <IntervalTableRow addBulkItem={this.addBulkItem} selectedBulkItems={this.state.bulkItems} canChangeLeadStatus={canChangeLeadStatus} />
                        </ProviderTable.Rows>
                    </ProviderTable.LazyTable>
                </ProviderTable>

            </Card>
        )
    }
}
