import React, { memo, PureComponent } from 'react'
import { Label } from 'react-bootstrap'
import config from '../../config'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { Card } from '../blocks/Card'
import ProviderTable from '../blocks/ProviderTable'
import {
    Cell,
    DateCell,
    DropdownFilter, Column,
} from '../elements/table'
import { connect } from '../../utils/connect'
import { getPartnerSignups } from '../../actions/partnerSignups'
import { PartnerSignup, PartnerSignups } from '../../reducers/types/entities/PartnerSignup'
import { partnerSignupListSelector } from '../../selectors/partnerSignups'
import { getIndustries } from '../../state/industries/actions'
import { allIndustriesSelector } from '../../state/industries/selectors'
import { ApiActionCreatorConvertedResponse, convertApiActionCreatorResponse } from '../../middleware/api'
import { Industries } from '../../reducers/types/entities/Industry'

const IS_PROCESSED = [
    { id: '1', name: 'Processed', abbreviation: 'yes' },
    { id: '0', name: 'Not processed', abbreviation: 'no' },
]

const SignupRow = memo((props: { rowData?: PartnerSignup, industries }) => {
    const signup = props.rowData

    if (!signup) {
        return null
    }

    const getStatus = () => {
        if (signup.processedAt) {
            return <Label bsStyle="success">Processed</Label>
        }
        return <Label bsStyle="info">Unprocessed</Label>
    }

    const getIndustry = () => {
        const industry = props.industries.find(ind => ind.id = signup.industryId)

        if (industry) {
            return industry.name
        }

        return ''
    }

    return <ProviderTable.Row {...props}>
        <Cell>{getStatus()}</Cell>
        <Cell>{getIndustry()}</Cell>
        <Cell>{signup.email}</Cell>
        <Cell>{`${signup.firstName} ${signup.lastName}`}</Cell>
        <Cell>{signup.signupToken}</Cell>
        <DateCell date={signup.expiresAt} format="datetime"/>
    </ProviderTable.Row>
})

interface Criteria {
    industryId: string[]
    isProcessed: '0' | '1'
    processedAtGte: string
    processedAtLte: string
    expiresAtGte: string
    expiresAtLte: string
    visibleColumns: string[]
}

interface PartnerSignupsListStateProps {
    partnerSignups: PartnerSignups
    industries: Industries
    criteria: Criteria
}

interface PartnerSignupsListDispatchProps {
    getPartnerSignups: typeof getPartnerSignups
    getIndustries: ApiActionCreatorConvertedResponse
}

interface PartnerSignupsListPathProps {
    location: {
        query: Criteria
    }
}

type PartnerSignupListModel = PartnerSignupsListStateProps &
    PartnerSignupsListDispatchProps &
    PartnerSignupsListPathProps

@connect<PartnerSignupsListStateProps, PartnerSignupsListDispatchProps, PartnerSignupsListPathProps>(
    (state, { location: { query } }) => ({
        partnerSignups: partnerSignupListSelector(state),
        industries: allIndustriesSelector(state),
        criteria: {
            visibleColumns: splitFilterString(
                query.visibleColumns || [ 'status', 'industry', 'partner_name', 'email',
                    'token', 'expires_at' ]
            ),
            industryId: splitFilterString(query.industryId),
            isProcessed: query.isProcessed || '0',
            processedAtGte: query.processedAtGte || '',
            processedAtLte: query.processedAtLte || '',
            expiresAtGte: query.expiresAtGte || '',
            expiresAtLte: query.expiresAtLte || '',
        },
    }),
    {
        getPartnerSignups,
        getIndustries: convertApiActionCreatorResponse(getIndustries),
    }
)
export default class PartnerSignupsPage extends PureComponent<PartnerSignupListModel> {
    state = {
        isLoadingSignups: false,
        industryIds: [],
    }

    async componentDidMount() {
        const response = await this.props.getIndustries({ geoCode: config.geoCode })
        this.setState({ industryIds: response.result })
    }

    retrieveData = async (filterCriteria, append = false) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            geoCode: config.geoCode,
        })

        await this.setState({ isLoadingSignups: true, })

        try {
            const signups: any = await this.props.getPartnerSignups(criteria, append)

            await this.setState({ isLoadingSignups: false, })

            return signups
        } catch (e) {
            await this.setState({ isLoadingSignups: false, })
        }
    }

    render() {
        const { criteria, partnerSignups, industries } = this.props

        return (
            <Card margin="0">
                <Card.Header>Partner signups</Card.Header>
                <Card.Content modifiers={[ 'p_0' ]}>
                    <ProviderTable
                        pageName="partnerSignups"
                        retrieveData={this.retrieveData}
                        criteria={this.props.criteria}
                        loading={this.state.isLoadingSignups}
                    >
                        <ProviderTable.FilterContainer>
                            <DropdownFilter
                                selectedIds={[ criteria.isProcessed ]}
                                type="radio"
                                items={IS_PROCESSED}
                                name="isProcessed"
                                label="Processed"
                            />
                        </ProviderTable.FilterContainer>
                        <ProviderTable.LazyTable>
                            <ProviderTable.Header>
                                <Column name="status">Status</Column>
                                <Column name="industry">Industry</Column>
                                <Column name="email">Email</Column>
                                <Column name="partner_name">Partner name</Column>
                                <Column name="token">Signup token</Column>
                                <Column name="expires_at">Expires at</Column>
                            </ProviderTable.Header>
                            <ProviderTable.Rows
                                items={Object.values(partnerSignups)}
                                noDataText="There aren't any partner signups matching your criteria."
                            >
                                <SignupRow
                                    industries={industries}
                                />
                            </ProviderTable.Rows>
                        </ProviderTable.LazyTable>
                    </ProviderTable>
                </Card.Content>
            </Card>
        )
    }
}
