import React, { PureComponent } from 'react'
import { Field, reset, SubmissionError, submit, change } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import { Button } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import { TextareaGroup } from '../elements/forms/inputs/TextareaGroup'
import { Card } from '../blocks/Card'
import PageTitle from '../elements/PageTitle'
import { Affiliates } from '../../reducers/types/entities/Affiliates'
import { Campaigns } from '../../reducers/types/entities/Campaigns'
import { connect } from '../../utils/connect'
import { getAffiliateCampaigns, getAffiliates } from '../../actions/affiliates'
import { createLead } from '../../actions/leads'
import { Col, Row } from 'react-bootstrap'
import { set, get } from 'lodash'
import { push } from 'connected-react-router'
import { returnLocalTimezone } from '../../utils'

interface AddLeadDispatchProps {
    submit: typeof submit
    reset: typeof reset
    change: typeof change,
    getAffiliates: typeof getAffiliates,
    getAffiliateCampaigns: typeof getAffiliateCampaigns
    createLead: typeof createLead
    push: typeof push
}

interface StatePropsLeadCreatePage {
    affiliates: Affiliates
    campaigns: Campaigns
}

type LeadCreateModel = StatePropsLeadCreatePage & AddLeadDispatchProps

@connect<StatePropsLeadCreatePage, AddLeadDispatchProps>(
    (state, props) => ({
        affiliates: state.entities.affiliates,
        campaigns: state.entities.campaigns,
    }),
    {
        submit,
        reset,
        getAffiliates,
        getAffiliateCampaigns,
        createLead,
        push,
        change,
    }
)

export default class LeadCreatePage extends PureComponent<LeadCreateModel> {
    state = {
        campaignIds: [],
    }

    save = async values => {
        const createLeadValationErrors = {}
        if (!get(values, 'client.fullName'))
            set(createLeadValationErrors, 'client.fullName', 'Client name is required!')
        if (!get(values, 'client.email'))
            set(createLeadValationErrors, 'client.email', 'Client email is required!')
        if (!get(values, 'client.phone'))
            set(createLeadValationErrors, 'client.phone', 'Client phone is required!')
        if (!get(values, 'affiliate'))
            set(createLeadValationErrors, 'affiliate', 'Affiliate is required!')
        if (!get(values, 'affiliateCampaign.key'))
            set(createLeadValationErrors, 'affiliateCampaign.key', 'Affiliate campaign is required!')
        if (Object.keys(createLeadValationErrors).length)
            throw new SubmissionError(createLeadValationErrors)
        const res: any = await this.props.createLead(values)
        const leadId = res.result
        await this.props.reset('createLeadForm')
        await toastr.success('Success!', 'Lead has been created')
        if (leadId) this.props.push(`/leads/${leadId}/validation`)
        else window.location.href = `/leads/validation?timezone=${returnLocalTimezone()}`

    }
    submitLeadCreateForm = () => {
        this.props.submit('createLeadForm')
    }
    handleChange = async (value) => {
        this.props.change('createLeadForm', 'affiliate', value.id)
        const affiliateCampaignsResponse: any = await this.props.getAffiliateCampaigns({ isActive: 1 }, value.id)
        const affiliateCampaignsIds = affiliateCampaignsResponse.result
        this.setState({ campaignIds: affiliateCampaignsIds })
    }
    componentDidMount(): void {
        this.props.getAffiliates({
            isActive: 1,
            hasActiveCampaigns: 1,
        })
    }

    render() {

        return (
            <Row>
                <Col md={6} mdOffset={3}>
                    <Card margin="0">
                        <PageTitle title="Create lead"/>
                        <Card.Header>
                            Create lead
                        </Card.Header>
                        <Card.Content>

                            <ReduxForm
                                form="createLeadForm"
                                onSubmit={this.save}
                            >
                                <Field
                                    label="Full name"
                                    name="client[fullName]"
                                    component={FieldGroup}
                                    placeholder="Full name..."
                                />
                                <Field
                                    label="Affiliate"
                                    name="affiliate"
                                    component={SelectInputGroup}
                                    placeholder="Affiliate..."
                                    onChange={this.handleChange}
                                    options={Object.values(this.props.affiliates).map(affiliate => ({
                                        id: affiliate.id,
                                        name: affiliate.companyName,
                                    })
                                    )}
                                />
                                <Field
                                    label="Campaign"
                                    name="affiliateCampaign[key]"
                                    component={SelectInputGroup}
                                    placeholder="Affiliate..."
                                    returnId={true}
                                    options={this.state.campaignIds.map(id => ({
                                        id: this.props.campaigns[id].key,
                                        name: this.props.campaigns[id].name,
                                    })
                                    )}
                                />
                                <Field
                                    label="Company name"
                                    name="client[companyName]"
                                    component={FieldGroup}
                                    placeholder="Company name..."
                                />
                                <Field
                                    label="City name"
                                    name="geo[cityName]"
                                    component={FieldGroup}
                                    placeholder="City name..."
                                />
                                <Field
                                    label="Zip code"
                                    name="geo[zipCode]"
                                    component={FieldGroup}
                                    placeholder="Zip Zode..."
                                />
                                <Field
                                    label="Email"
                                    name="client[email]"
                                    component={FieldGroup}
                                    placeholder="Email..."
                                />
                                <Field
                                    label="Phone number"
                                    name="client[phone]"
                                    component={FieldGroup}
                                    placeholder="Phone number..."
                                />
                                <Field
                                    label="Description"
                                    name="description"
                                    component={TextareaGroup}
                                    placeholder="Description..."
                                />
                                <div>
                                    <Button
                                        onClick={this.submitLeadCreateForm}
                                        modifiers={[ 'primary', 'p_1', 'nowrap', 'mL_1' ]}
                                    >
                                        Create lead
                                    </Button>
                                </div>
                            </ReduxForm>

                        </Card.Content>
                    </Card>
                </Col>
            </Row>
        )
    }
}
