import React, { ComponentType, PureComponent } from 'react'
import { createKPITargets } from '../../../actions/kpi'
import { Flex } from '../../elements/Flex'
import { Card } from '../../blocks/Card'
import { FormControl } from 'react-bootstrap'
import { Button } from '../../elements/Button'
import config from '../../../config'
import styled from 'styled-components'
import { PERCENT_COLUMNS, sumPerColumn } from '../KPITargetsPage'
import { connect } from '../../../utils/connect'
import { Employee } from '../../../reducers/types/entities/Employee'
import { KPITargets } from '../../../reducers/types/entities/KPITarget'
import CellKPI from './CellKPI'

export const RowElement = styled.div`
    margin: 10px;
    height: 30px;
    line-height: 30px;
`

interface DelegatedPropsModel {
    key: string
    col: {
        identifier: string
        sumValue?: number
        name: string
    }
    params: {
        employees: Employee[]
        date: string
        geoCode: string
    }
}

interface ColumnKPIStateModel {
    divideValue: number
    valuePerEmployee: number
}

interface ColumnKPIModel extends DelegatedPropsModel {
    kpiTargets: KPITargets
    createKPITargets: typeof createKPITargets
}

@connect<any, any, any>(
    state => ({
        kpiTargets: state.entities.kpiTargets,
    }),
    {
        createKPITargets,
    }
)
class ColumnKPI extends PureComponent<ColumnKPIModel, ColumnKPIStateModel> {
    constructor(props) {
        super(props)
        this.state = {
            divideValue: props.col.sumValue,
            valuePerEmployee: undefined,
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.col.sumValue !== prevProps.col.sumValue) {
            this.setState({ divideValue: this.props.col.sumValue })
        }
    }

    updateColumnState() {
        const { col, kpiTargets, params: { employees, date } } = this.props
        const divideValue = sumPerColumn(col.identifier, employees, date, kpiTargets)
        this.setState({ divideValue })
    }

    handleCellChange = async (value, employeeId, calculate?) => {
        const splitDate = this.props.params.date.split('-')
        const year = splitDate[0]
        const month = splitDate[1]
        await this.props.createKPITargets({
            kpiIdentifier: this.props.col.identifier,
            year,
            month,
            employeeId,
            value,
        })
        if(calculate) await this.updateColumnState()
    }

    dividePerEmployee = () => {
        const valuePerEmployee = +(this.state.divideValue / this.props.params.employees.length).toFixed(2)
        this.setState({ valuePerEmployee })
        this.props.params.employees.forEach(el => {
            this.handleCellChange(valuePerEmployee, el.id)
        })
    }

    applyToAll = () => {
        const valuePerEmployee = this.state.divideValue
        this.setState({ valuePerEmployee })
        this.props.params.employees.forEach(el =>
            this.handleCellChange(valuePerEmployee, el.id)
        )
    }

    formControlOnChange = e => {
        this.setState({ divideValue: e.target.value })
    }

    render() {
        const { col, params } = this.props
        return col.name
            ? <Flex modifiers={[ 'directionColumn' ]}>
                <RowElement className="text-center">
                    <Card.Label>{col.name}</Card.Label>
                </RowElement>
                <RowElement>
                    <FormControl
                        key={col.identifier}
                        type="number"
                        onChange={this.formControlOnChange}
                        value={this.state.divideValue}
                    />
                </RowElement>
                <br />
                <RowElement>
                    {PERCENT_COLUMNS.includes(col.identifier)
                        ? <Button
                            onClick={this.applyToAll}
                            modifiers={[ 'primary', 'fullWidth' ]}
                        >
                            Apply to all
                        </Button>
                        : <Button
                            onClick={this.dividePerEmployee}
                            modifiers={[ 'primary', 'fullWidth' ]}
                        >
                            Divide equally
                        </Button>
                    }
                </RowElement>
                <br />
                {params.employees.map(el =>
                    <CellKPI
                        key={`${el.id}-${col.identifier}-${params.date}`}
                        cellChange={this.handleCellChange}
                        employeeId={el.id}
                        dividedValue={this.state.valuePerEmployee}
                        cellIdentifier={`${el.id}-${col.identifier}-${params.date}`}
                    />

                )}
            </Flex>
            : <Flex modifiers={[ 'directionColumn' ]}>
                <RowElement>{col.name}</RowElement>
                <RowElement>&nbsp;</RowElement>
                <br />
                <RowElement>
                    <Button disabled={true}>
                        {config.countries.find(country => country.id === params.geoCode).name}
                    </Button>
                </RowElement>
                <br />
                {params.employees.map((el, i) => <RowElement key={el.name + '_' + i}>{el.name}</RowElement>)}
            </Flex>
    }
}

export default ColumnKPI as unknown as ComponentType<DelegatedPropsModel>
