import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_INDUSTRIES = actionTypesCreator('industries', 'GET_INDUSTRIES')

export const GET_PARTNER_INDUSTRIES = actionTypesCreator('industries', 'GET_PARTNER_INDUSTRIES')

export const REMOVE_INDUSTRY_FROM_PARTNER = actionTypesCreator('industries', 'REMOVE_INDUSTRY_FROM_PARTNER')

export const UPDATE_PARTNER_INDUSTRY = actionTypesCreator('industries', 'UPDATE_PARTNER_INDUSTRY')

export const GET_BUSINESS_UNIT_INDUSTRIES = actionTypesCreator('industries', 'GET_BUSINESS_UNIT_INDUSTRIES')

interface GetIndustriesModel {
    geoCode?: string
    parentIndustryId?: number
}
export const getIndustries = (
    criteria: GetIndustriesModel,
    append?
) => ({
    [CALL_API]: {
        types: GET_INDUSTRIES,
        endpoint: '/industries',
        schema: schemas.industries,
        options: {
            body: {
                ...criteria,
                limit: 100,
                isActive: 1,
            },
        },
    },
    append,
})

export const getPartnerIndustries = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: GET_PARTNER_INDUSTRIES,
        endpoint: `/partners/${partnerId}/industries`,
        schema: schemas.industries,
        options: {
            body: {
                limit: 100,
                isActive: 1,
            },
        },
    },
})

export const addIndustryToPartner = (
    partnerId: number,
    industryId: number
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_INDUSTRY,
        endpoint: `/partners/${partnerId}/industries`,
        schema: schemas.industry,
        options: {
            method: 'post',
            body: {
                id: industryId,
            },
        },
    },
})

export const removeIndustryFromPartner = (
    partnerId: number,
    industryId: number
) => ({
    [CALL_API]: {
        types: REMOVE_INDUSTRY_FROM_PARTNER,
        endpoint: `/partners/${partnerId}/industries/${industryId}`,
        options: {
            method: 'delete',
        },
    },
    id: industryId,
})

export const updatePartnerIndustry = (
    partnerId: number,
    industryIds: number | number[]
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_INDUSTRY,
        endpoint: `/partners/${partnerId}/industries`,
        schema: schemas.industry,
        options: {
            method: 'put',
            body: {
                industryIds,
            },
        },
    },
})



export const getPartnerBusinessUnitIndustries = (
    partnerId: number,
    businessUnitId: number
) => ({
    [CALL_API]: {
        types: GET_BUSINESS_UNIT_INDUSTRIES,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/industries`,
        schema: schemas.industries,
        options: {
            body: {
                limit: 100,
                isActive: 1,
            },
        },
    },
})
