
const LOCALES = {
    dk: 'da',
    de: 'de',
    nl: 'nl',
    no: 'no',
    se: 'sv',
    uk: 'en',
    us: 'en',
}

export const sortObjectWithLanguageSensitivity = (obj: object, sortProperty: string, geoCode: string): any => {
    const locale = LOCALES[geoCode] !== undefined ? LOCALES[geoCode] : 'en'

    function compare( a: object, b: object ) {
        const collator = new Intl.Collator(locale)
        return collator.compare(a[sortProperty], b[sortProperty])
    }

    return Object.values(obj).sort( compare )
}
