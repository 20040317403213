import { createSelector } from 'reselect'
import { forIn, groupBy, maxBy, values } from 'lodash'
import { entitiesPartners } from './partner'

export const leadSelector = (state, leadId) => state.entities.leads[leadId]
const entitiesActivities = state => state.entities.activities
const entitiesLeads = state => state.entities.leads
export const entitiesQuotes = state => state.entities.quotes
const pagesLeadsIds = state => state.pages.leads.leadIds
const pageQuotesSurveillanceIds = state => state.pages.quotesSurveillance.leadIds
const pageMatchSurveillance = state => state.pages.matchSurveillance.leadIds
const intervalLeads = state => state.pages.intervalLeads.leadIds
const pageNewsFeedCalls = state => state.pages.newsfeed.totalLeadCallActivities
const pageExcludedPartnersId = state => state.pages.leadValidation.excludedPartnersId
const entitiesDataFields = state => state.entities.dataFields
const leadValidationDataFieldsId = state => state.pages.leadValidation.dataFieldsId
const pageCompletedCallFlowLeadsId = state => state.pages.completedCallFlow.leadIds
const segmentedLeadsId = state => state.pages.partnerDetail.segmentedLeads.leadIds

export const activitiesIdToLeadIdSelector = createSelector(
    entitiesActivities,
    activity => {
        const activities = values(activity)
        const groupedBy = {}
        activities.forEach(el => {
            el.createdAt = new Date(el.createdAt).getTime()
        })
        forIn(
            groupBy(activities, el => el.lead && el.lead.id),
            (value, key) => {
                groupedBy[key] = maxBy(value, 'createdAt')
            }
        )
        return activities.length ? groupedBy : {}
    }
)

export const leadActivitiesSelector = createSelector(
    (state, leadId) => activitiesIdToLeadIdSelector(state)[leadId],
    leadActivity => leadActivity
)

export const excludedLeadPartnersSelector = createSelector(
    pageExcludedPartnersId, entitiesPartners,
    (excludedPartnersId, partners) => excludedPartnersId.map(id => partners[id])
)

export const leadValidationDataFieldsSelector = createSelector(
    leadValidationDataFieldsId, entitiesDataFields,
    (dataFieldsId, dataFields) => dataFieldsId.map(id => dataFields[id])
)

export const leadQuotesSelector = createSelector(
    leadSelector, entitiesQuotes,
    (lead, quotes) => lead ? lead.quotesInfo.quotes.map(id => quotes[ id ]) : []
)

export const leadsSelector = createSelector(
    pagesLeadsIds, entitiesLeads,
    (leadsIds, leads) => leadsIds.map(id => leads[id])
)

export const leadsQuotesSurveillanceSelector = createSelector(
    pageQuotesSurveillanceIds, entitiesLeads,
    (leadsIds, leads) => leadsIds.map(id => leads[id])
)

export const matchSurveillanceLeadSelector = createSelector(
    entitiesLeads, pageMatchSurveillance,
    (leads, leadIds) => leadIds.map(id => leads[id])
)

export const intervalLeadsSelector = createSelector(
    intervalLeads, entitiesLeads, pageNewsFeedCalls,
    (leadsIds, leads, calls) => leadsIds.map(id => ({ ...leads[id], callCountBeforeValidation: calls[id] }))
)

export const completedCallFlowLeadsSelector = createSelector(
    pageCompletedCallFlowLeadsId, entitiesLeads,
    (leadsIds, leads) => leadsIds.map(id => leads[id])
)

export const segmentedLeadsSelector = createSelector(
    segmentedLeadsId, entitiesLeads,
    (leadsIds, leads) => leadsIds.map(id => leads[id])
)
