import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PARTNER_CONTACT_ARCHIVES = 'archives/GET_PARTNER_CONTACT_ARCHIVES'
export const GET_PARTNER_CONTACT_ARCHIVES_SUCCESS = 'archives/GET_PARTNER_CONTACT_ARCHIVES_SUCCESS'
export const GET_PARTNER_CONTACT_ARCHIVES_FAIL = 'archives/GET_PARTNER_CONTACT_ARCHIVES_FAIL'

export const GET_LEAD_CONTACT_ARCHIVES = 'archives/GET_LEAD_CONTACT_ARCHIVES'
export const GET_LEAD_CONTACT_ARCHIVES_SUCCESS = 'archives/GET_LEAD_CONTACT_ARCHIVES_SUCCESS'
export const GET_LEAD_CONTACT_ARCHIVES_FAIL = 'archives/GET_LEAD_CONTACT_ARCHIVES_FAIL'

export const RESEND_CONTACT_ARCHIVE_ACTION = 'archive/RESEND_CONTACT_ARCHIVE_ACTION'
export const RESEND_CONTACT_ARCHIVE_ACTION_SUCCESS = 'archive/RESEND_CONTACT_ARCHIVE_ACTION_SUCCESS'
export const RESEND_CONTACT_ARCHIVE_ACTION_FAIL = 'archive/RESEND_CONTACT_ARCHIVE_ACTION_FAIL'

export const GET_TOTAL_CONTACT_ARCHIVES = 'archive/GET_TOTAL_CONTACT_ARCHIVES'
export const GET_TOTAL_CONTACT_ARCHIVES_SUCCESS = 'archive/GET_TOTAL_CONTACT_ARCHIVES_SUCCESS'
export const GET_TOTAL_CONTACT_ARCHIVES_FAIL = 'archive/GET_TOTAL_CONTACT_ARCHIVES_FAIL'


interface GetArchivesModel {
    receiverLeadId?: number,
    receiverPartnerId?: number
    limit?: number
    page?: number
}
const getArchives = (
    types: string[],
    criteria: GetArchivesModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types,
        endpoint: '/contactarchives',
        schema: schemas.contactArchives,
        options: {
            body: {
                limit: 50,
                sort: '-sent_at',
                ...criteria,
            },
        },
    },
    append,
})

export const resendContactArchive = (
    contactArchiveId: number
) => ({
    [CALL_API]: {
        types: [
            RESEND_CONTACT_ARCHIVE_ACTION,
            RESEND_CONTACT_ARCHIVE_ACTION_SUCCESS,
            RESEND_CONTACT_ARCHIVE_ACTION_FAIL,
        ],
        endpoint: `/contactarchives/${contactArchiveId}/actions`,
        schema: schemas.contactArchive,
        options: {
            method: 'post',
            body: {
                action: 'resend',
            },
        },
    },
})

export const getLeadContactArchives = (
    receiverLeadId: number,
    page: number = 1,
    append?: boolean
) => getArchives(
    [ GET_LEAD_CONTACT_ARCHIVES, GET_LEAD_CONTACT_ARCHIVES_SUCCESS, GET_LEAD_CONTACT_ARCHIVES_FAIL ],
    { receiverLeadId, page },
    append
)

export const getPartnerContactArchives = (
    receiverPartnerId: number,
    page: number = 1,
    append?: boolean
) => getArchives(
    [ GET_PARTNER_CONTACT_ARCHIVES, GET_PARTNER_CONTACT_ARCHIVES_SUCCESS, GET_PARTNER_CONTACT_ARCHIVES_FAIL ],
    { receiverPartnerId, page },
    append
)


export const getTotalContactArchives = (
    criteria
) => getArchives(
    [ GET_TOTAL_CONTACT_ARCHIVES, GET_TOTAL_CONTACT_ARCHIVES_SUCCESS, GET_TOTAL_CONTACT_ARCHIVES_FAIL ],
    { ...criteria, limit: 1 }
)
