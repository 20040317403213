import React, { memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import ControlLabel from '../../text/MetaLabel'

import { AsyncTypeahead, AsyncTypeaheadProps } from 'react-bootstrap-typeahead'
import styled from 'styled-components'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { FieldInputProps, FieldMetaProps, FormikHelpers } from 'formik'
import get from 'lodash/get'


interface AsyncTypeaheadGroupModel<Value> extends Partial<AsyncTypeaheadProps<any>> {
    removeClassFormGroup?: boolean
    returnOptionKey?: string
    shouldMatch?: string
    className?: string
    extraData?: object
    label?: string
    field?: FieldInputProps<Value> | any
    form?: FormikHelpers<Value>
    input?: WrappedFieldInputProps
    meta: FieldMetaProps<Value> | WrappedFieldMetaProps | any
    typeaheadRef: React.Ref<typeof AsyncTypeahead>
}

const SearchInput = styled(AsyncTypeahead)<any>`
  input {
    height: 40px;
  }
`

export const AsyncTypeaheadGroup = memo(({
    renderMenuItemChildren,
    removeClassFormGroup,
    defaultSelected,
    onSearch,
    shouldMatch,
    extraData = {},
    className,
    isLoading,
    labelKey,
    disabled,
    options,
    input,
    field,
    form,
    label,
    meta,
    placeholder,
    typeaheadRef,
}: AsyncTypeaheadGroupModel<any>) => {
    return (
        <FormGroup
            bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
            validationState={
                meta && meta.touched && (meta.error || meta.warning) ||
                get(form, `errors.${get(field, 'name')}`)
                    ? 'error'
                    : null
            }
        >
            {label && <ControlLabel>{label}</ControlLabel>}
            <SearchInput
                {...(input || field)}
                id={(input || field).name}
                ref={typeaheadRef}
                renderMenuItemChildren={renderMenuItemChildren}
                isLoading={Boolean(meta) && meta.active && isLoading}
                placeholder={placeholder || ((input || field).value instanceof Object ? (input || field).value.name : (input || field).value)}
                onChange={selected => {                
                    if (Array.isArray(selected) && selected.length === 0) { // Disable because AsyncTypeahead is weird.
                        return
                    }
                    if (input) {
                        input.onChange({ ...selected[0], ...extraData })
                    }
                    if (field && form) {
                        (value => form.setFieldValue(field.name, value))({ ...selected[0], ...extraData })
                    }
                }}
                onBlur={e => {
                    if (!input) { // Don't support this functionality for Formik, since I don't know what's going on.
                        return
                    }
                    if (shouldMatch && (e.target as any).value !== input.value.name) {
                        input.onChange('shouldMatch') // This solution is weird. Don't know how it works.
                    }
                }}
                onSearch={onSearch}
                disabled={disabled}
                labelKey={labelKey}
                options={options}
                minLength={2}
                useCache={false}
                defaultSelected={defaultSelected}
            />
        </FormGroup>
    )
}
)
