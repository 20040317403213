import React, { useState, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../elements/Button'
import { hideModal } from '../../actions/modal'
import Flex from 'components/src/layout/Flex'

interface ModalProps {
    partnerId: number | string
    partnerContractId: number | string
}

interface TerminatePartnerContractModalProps {
    onHide: typeof hideModal
    terminatePartnerContract: (deleteUnusedAllocations: boolean) => void
    modalProps: ModalProps
}

function TerminatePartnerContractModal({ onHide, terminatePartnerContract, modalProps }: TerminatePartnerContractModalProps) {
    const [ deleteUnusedAllocations, setDeleteUnusedAllocations ] = useState<boolean>(false)

    const onCheckboxChange = useCallback(() => {
        setDeleteUnusedAllocations(!deleteUnusedAllocations)
    }, [ deleteUnusedAllocations ])

    const handleSubmit = useCallback(() => {
        terminatePartnerContract(deleteUnusedAllocations)
    }, [ terminatePartnerContract, deleteUnusedAllocations ])

    return (
        <Modal
            show={true}
            onHide={onHide}
            backdrop="static"
        >
            <Modal.Header>Terminate contract</Modal.Header>
            <Modal.Body>
                You are about to terminate contract #{modalProps.partnerContractId}. This action is irreversible. Are you sure?
            </Modal.Body>
            <Modal.Footer>
                <Flex justifyContent="space-between">
                    <Flex alignItems="center">
                        <input type="checkbox" checked={deleteUnusedAllocations} onClick={onCheckboxChange} />
                        <Flex ml={16}>
                            Delete all unused allocations
                        </Flex>
                    </Flex>
                    <Button modifiers={[ 'danger' ]} onClick={onHide}>Cancel</Button>
                    <Button modifiers={[ 'action', 'mL_2' ]} onClick={handleSubmit}>Confirm</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    )
}

export default TerminatePartnerContractModal
