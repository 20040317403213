import { GoogleMap, InfoWindow, Polygon, withGoogleMap } from 'react-google-maps'
import React, { useState } from 'react'
import PartnerMarker from './PartnerMarker'
import { Card } from '../blocks/Card'
import { SegmentationSubGeoRegion } from '../../reducers/types/entities/SegmentationSubGeoRegion'
import { GeoRegions } from '../../reducers/types/entities/GeoRegion'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import ColorHash from 'color-hash'

const regionColor = (id) => new ColorHash().hex(id)

const polygonOptions = (id) => ({
    strokeColor: regionColor(id),
    strokeOpacity: 0.5,
    strokeWeight: 1,
    fillColor: regionColor(id),
    fillOpacity: 0.35,
})

interface MapModel {
    subGeoRegions: SegmentationSubGeoRegion[]
    geoRegions: GeoRegions
    businessUnits?: BusinessUnit[]
    onPinClick?: (id: number) => void
    showBusinessUnits?: boolean
    selectedId?: number
    zoomPoint?: {
        lat: number
        lon: number
    }
    singleMarker?
    zoom?
    center?
}

const getDefaultCenter = (props) => {
    if (!props.center) {
        return {
            lat: props.zoomPoint ? props.zoomPoint.lat : 55.0607,
            lng: props.zoomPoint ? props.zoomPoint.lon : 15.132874
        }
    } else {
        return {
            lat: props.zoomPoint ? props.zoomPoint.lat : props.center.lat,
            lng: props.zoomPoint ? props.zoomPoint.lon : props.center.lon
        }
    }
}

const GeoRegionsMap = withGoogleMap((props: MapModel) => {
    const [ polygon, onPinClick ] = useState(null)
    const onPolygonClick = (e, data) => {
        const subRegion = { ...data, regionName: props.geoRegions[data.segmentationGeoRegionId].regionName }
        onPinClick({ subRegion, position: { lat: e.latLng.lat(), lng: e.latLng.lng() } })
    }

    return <GoogleMap
        defaultZoom={props.zoom || 8}
        defaultCenter={getDefaultCenter(props)}
        options={{
            scaleControl: false,
            mapTypeControl: false,
            streetViewControl: false,
        }}
    >

        {props.showBusinessUnits && props.businessUnits.map(businessUnit =>
            <PartnerMarker
                key={'partner_' + businessUnit.id}
                item={businessUnit}
                pinLink="partners"
                selectedId={props.selectedId}
                onPinClick={props.onPinClick}
            />
        )}

        {/*{props.singleMarker && <PartnerMarker*/}
        {/*    item={props.singleMarker}*/}
        {/*    pinLink="partners"*/}
        {/*    isSingle={true}*/}
        {/*                       />}*/}

        {props.subGeoRegions.map(subGeoRegion => subGeoRegion.points &&
        <Polygon
            key={'Polygon_' + subGeoRegion.id}
            path={subGeoRegion.points.map(el => ({ ...el, lng: el.lon }))}
            onClick={e => onPolygonClick(e, subGeoRegion)}
            options={polygonOptions(subGeoRegion.segmentationGeoRegionId)}
        />
        )}

        {polygon && <InfoWindow onCloseClick={() => onPinClick(null)} position={polygon.position}>
            <div>
                <Card.Text>
                    <b>Region:</b>
                    {' '}
                    {polygon.subRegion.regionName}
                </Card.Text>
                <Card.Text as="div">
                    <b>Sub region:</b>
                    {' '}
                    {polygon.subRegion.subRegionName}
                </Card.Text>
            </div>
        </InfoWindow>}

    </GoogleMap>
})

export default GeoRegionsMap
