import React, { PureComponent, ComponentType } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { MetaText } from '../../elements/text/MetaText'
import { ProfilePicture } from '../../elements/ProfilePicture'
import CardText from '../../elements/text/CardText'
import { Flex } from '../../elements/Flex'
import { connect } from '../../../utils/connect'
import { savePartner } from '../../../actions/partners'
import { employeesByGeoCodeSelector } from '../../../selectors/employees'
import config from '../../../config'
import { Employee } from '../../../reducers/types/entities/Employee'
import { Partner } from '../../../reducers/types/entities/Partner'

interface ConsultantListStateProps {
    employees: Employee[]
}

interface ConsultantListDispatchProps {
    savePartner: typeof savePartner
}
interface ConsultantListPathProps {
    partner: Partner
}

type ConsultantListModel = ConsultantListStateProps & ConsultantListDispatchProps & ConsultantListPathProps

@connect<ConsultantListStateProps, ConsultantListDispatchProps, ConsultantListPathProps>(
    state => ({
        employees: employeesByGeoCodeSelector(state, config.geoCode),
    }),
    {
        savePartner,
    }
)
class ConsultantList extends PureComponent<ConsultantListModel> {
    updateEmployee = (e) => {
        this.props.savePartner({
            id: this.props.partner.id,
            employee: {
                id: e.target.value,
            },
        })
    }

    render() {
        const {
            partner,
            employees,
        } = this.props

        const employeeForm = <Popover id="partner-employee-form" title="Employee">
            <select value={partner.employee.id} className="form-control" onChange={this.updateEmployee}>
                {employees.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
            </select>
        </Popover>

        return <Flex modifiers={[ 'alignCenter' ]}>
            <ProfilePicture src={partner.employee.profilePicture} />
            <div>
                <CardText modifiers={[ 'm_0' ]}>
                    {partner.employee.name}
&nbsp;
                    <OverlayTrigger
                        animation={false}
                        rootClose
                        trigger="click"
                        placement="bottom"
                        overlay={employeeForm}
                    >
                        <a>(edit)</a>
                    </OverlayTrigger>
                </CardText>
                <MetaText>
                    {partner.employee.title}
                </MetaText>
            </div>
        </Flex>
    }
}

export default ConsultantList as unknown as ComponentType<ConsultantListPathProps>
