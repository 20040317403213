import { createSelector } from 'reselect'

export const companySizeEntities = state => state.entities.companySizes

export const companySizeIds = state => state.pages.companySizes.companySizeIds

export const allCompanySizesSelector = createSelector(
    companySizeIds, companySizeEntities,
    (ids, entities) => ids.map(id => entities[id])
)
