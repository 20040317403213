import queryString from 'query-string'
import config from '../config'

export default store => next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
        const loc = action.payload.location
        const query = queryString.parse(loc.search)
        loc.query = query
        if (!store.getState().router.location.key && loc.key && !config.isReactPage)
            window.location.href = action.payload.location.pathname + loc.search
    }
    return next(action)
}
