import React, { ComponentType, PureComponent } from 'react'
import { DropdownButton, FormControl } from 'react-bootstrap'
import { getLocations, getLocation } from '../../../../actions/locations'
import { Flex } from '../../Flex'
import { connect } from '../../../../utils/connect'
import styled from 'styled-components'
import { locationSelector } from '../../../../selectors/locations'

const SearchInput = styled(FormControl)`
    margin-right: 5px;
`

const DistanceSelect = styled(FormControl)`
  padding: 6px;
  width: 75px;
`

interface LocationFilterProps {
    locations: any
    selectedLocation
    isLoading: boolean
    _inputWasClicked?: boolean
}
interface LocationFilterDispatchProps {
    getLocations: typeof getLocations
    getLocation: typeof getLocation
}
interface LocationFilterPathProps {
    geoDistance: string
    locationId: string
    label: string
    name: string
    location?
    onChange?
}

type LocationFilterModel = LocationFilterProps & LocationFilterDispatchProps & LocationFilterPathProps

@connect<LocationFilterProps, LocationFilterDispatchProps, LocationFilterPathProps>(
    (state, { location, locationId }) => ({
        locations: locationSelector(state),
        selectedLocation: state.entities.locations[locationId],
        isLoading: state.pages.locationFilter.isLoading,
    }),
    {
        getLocations,
        getLocation,
    },
)
class LocationFilter extends PureComponent<LocationFilterModel> {
    state = { resultsShown: false, open: false }
    _inputWasClicked = false
    searchTimeout = null

    componentDidMount() {
        const { locationId } = this.props
        if (locationId) this.props.getLocation(locationId)
    }

    locationSearch = event => {
        let value = event.target.value
        clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(() => {
            this.props.getLocations({ query: value })
            this.setState({ resultsShown: true })
        }, 250)
    }

    selectLocation = location => {
        this.props.onChange({
            locationId: location.id,
            geoPoint: location.point.lat + ',' + location.point.lon,
            geoDistance: this.props.geoDistance || 30,
            geoRect: null,
        })
        this.setState({ resultsShown: false, open: false })
    }

    onDistanceChange = e => {
        this.props.onChange({
            geoDistance: e.target.value,
        })
        this.setState({ open: false })
    }

    onToggle = open => {
        if (this._inputWasClicked) {
            this._inputWasClicked = false
            return
        }
        this.setState({ open: open })
    }

    inputWasClicked = () => {
        this._inputWasClicked = true
    }

    render() {
        const {
            label,
            locations,
            isLoading,
            geoDistance,
            selectedLocation,
        } = this.props

        const { resultsShown } = this.state

        const distances = [ 5, 10, 15, 20, 30, 40, 50, 75, 100 ]

        const distance = geoDistance || 30

        const actualLabel = selectedLocation
            ? geoDistance + ' km from ' + selectedLocation.zipCode + ' ' + selectedLocation.cityName.substr(0, 20)
            : label

        return <DropdownButton
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            open={this.state.open}
            onToggle={this.onToggle}
            bsStyle="default"
            title={actualLabel}
            key="location"
            id="location-dropdown"
        >
            <div style={{ padding: '10px' }}>
                <Flex modifiers="mB_1">
                    <SearchInput
                        type="text"
                        placeholder="Location search..."
                        onChange={this.locationSearch}
                        onSelect={this.inputWasClicked}
                    />
                    <DistanceSelect
                        onChange={this.onDistanceChange}
                        value={distance}
                        componentClass="select"
                    >
                        {distances.map(d => <option key={d} value={d}>
                            {d}
                            {' '}
                            km
                        </option>)}
                    </DistanceSelect>
                </Flex>

                {isLoading && <div> Loading...</div>}

                {resultsShown && !isLoading && <ul style={{ padding: 0 }}>
                    {locations.map((location, i) => <li
                        key={'locationFilter' + i}
                        style={{ listStyleType: 'none', paddingTop: 2 }}
                    >
                        <a onClick={() => this.selectLocation(location)}>
                            {location.zipCode}
                            {' '}
                            {location.cityName}
                        </a>
                    </li>)}
                </ul>}
            </div>
        </DropdownButton>
    }
}

export default LocationFilter as unknown as ComponentType<LocationFilterPathProps>
