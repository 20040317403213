import React, { ComponentType, PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field } from 'redux-form'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Flex } from '../elements/Flex'
import { Button } from '../elements/Button'
import { segmentedPartnerListSelector } from '../../selectors/partner'
import { getPartners } from '../../actions/partners'
import config from '../../config'
import { hideModal } from '../../actions/modal'
import { createQuote } from '../../actions/quotes'
import { getLeadUpdateQuotes } from '../../actions/leads'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { Header, Table, Column, Rows, Cell } from '../elements/table'
import styled from 'styled-components'

const ModalFooter = styled(Modal.Footer)`
  max-height: 50vh;
  padding: 0 20px;
  overflow: auto;
  border: 0;
`

interface ReserveLeadQuoteStateProps {
    partners: Partner[]
    modalProps
}
interface ReserveLeadQuoteDispatchProps {
    getLeadUpdateQuotes: typeof getLeadUpdateQuotes
    getPartners: typeof getPartners
    createQuote: typeof createQuote
    hideModal: typeof hideModal
}

type ReserveLeadQuoteModel = ReserveLeadQuoteStateProps & ReserveLeadQuoteDispatchProps

const DataRow = ({ rowData, leadId }) => {
    const partner = rowData
    return <tr key={`partner_${partner.id}`}>
        <Cell>{partner.id}</Cell>
        <Cell>{partner.companyName}</Cell>
        <Cell className="text-right">
            <Button
                modifiers={[ 'action', 'nowrap' ]}
                onClick={() => this.reserveQuoteForPartner(leadId, partner.id)}
            >
                Reserve quote
            </Button>
        </Cell>
    </tr>
}

const PartnerRow = DataRow as ComponentType<{leadId}>

@connect<ReserveLeadQuoteStateProps, ReserveLeadQuoteDispatchProps>(
    state => ({
        partners: segmentedPartnerListSelector(state),
        modalProps: state.modal,
    }),
    {
        getLeadUpdateQuotes,
        getPartners,
        createQuote,
        hideModal,
    }
)
class ReserveLeadQuoteForPartnersModal extends PureComponent<ReserveLeadQuoteModel> {
    componentDidMount() {
        const leadId = this.props.modalProps.leadId
        this.props.getPartners({
            geoCode: config.geoCode,
            segmentedForLeadId: leadId,
        })
    }

    searchPartners = ({ query }) => {
        const leadId = this.props.modalProps.leadId
        this.props.getPartners({
            query,
            geoCode: config.geoCode,
            segmentedForLeadId: leadId,
        })
    }

    reserveQuoteForPartner = async (leadId, partnerId) => {
        await this.props.createQuote(
            leadId,
            {
                partner: {
                    id: partnerId,
                },
                reservationExpiresAt: '2038-01-19T02:14:07+01:00',
            }
        )
        toastr.success('Reserved quote for partner')
        this.props.getLeadUpdateQuotes(leadId)
    }

    render() {
        const {
            modalProps,
            partners,
        } = this.props
        return <Modal
            show={true}
            onHide={this.props.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton>Reserve quotes for segmented partners</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="partnerSearch"
                    onSubmit={this.searchPartners}
                >
                    <Flex>
                        <Field
                            removeClassFormGroup={true}
                            name="query"
                            component={FieldGroup}
                        />
                        {' '}
&nbsp;
                        <Button
                            modifiers={[ 'action' ]}
                            type="submit"
                        >
                            Search
                        </Button>
                    </Flex>
                </ReduxForm>
            </Modal.Body>
            <hr />
            <ModalFooter>
                <Table modifiers="splitByRow">
                    <Header>
                        <Column>#</Column>
                        <Column>Partner</Column>
                        <Column className="text-right">Action</Column>
                    </Header>
                    <Rows
                        items={partners}
                        noDataText="No partner is matching your current search query."
                    >
                        <PartnerRow leadId={modalProps.leadId}/>
                    </Rows>
                </Table>
            </ModalFooter>
        </Modal>
    }
}

export default ReserveLeadQuoteForPartnersModal as unknown as ComponentType
