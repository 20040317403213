import config from '../config'
import { CALL_API } from '../middleware/api'

export const SEARCH_TYPEAHEAD = 'search/SEARCH_TYPEAHEAD'
export const SEARCH_TYPEAHEAD_SUCCESS = 'search/SEARCH_TYPEAHEAD_SUCCESS'
export const SEARCH_TYPEAHEAD_FAIL = 'search/SEARCH_TYPEAHEAD_FAIL'

const partnerStates = [ 'active', 'demo', 'inactive', 'ex_partner', 'canvas' ]

export const getSearchTypeahead = (
    criteria,
    limited?: boolean
) => ({
    [CALL_API]: {
        types: [ SEARCH_TYPEAHEAD, SEARCH_TYPEAHEAD_SUCCESS, SEARCH_TYPEAHEAD_FAIL ],
        endpoint: '/search/typeahead',
        options: {
            body: {
                geoCode: config.geoCode,
                partnerState: limited ? partnerStates.toString() : '',
                ...criteria,
            },
        },
    },
})
