import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_REVENUE_SEGMENTS = 'revenueSegments/GET_REVENUE_SEGMENTS'
export const GET_REVENUE_SEGMENTS_SUCCESS = 'revenueSegments/GET_REVENUE_SEGMENTS_SUCCESS'
export const GET_REVENUE_SEGMENTS_FAIL = 'revenueSegments/GET_REVENUE_SEGMENTS_FAIL'

export const getRevenueSegments = (
    geoCode: string,
    primaryIndustryId?: number
) => ({
    [CALL_API]: {
        types: [
            GET_REVENUE_SEGMENTS,
            GET_REVENUE_SEGMENTS_SUCCESS,
            GET_REVENUE_SEGMENTS_FAIL,
        ],
        endpoint: '/revenuesegments',
        schema: schemas.revenueSegments,
        options: {
            body: {
                geoCode,
                primaryIndustryId,
                limit: 100,
            },
        },
    },
})
