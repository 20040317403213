import React, { PureComponent, memo } from 'react'
import { Col, FormControl, Row } from 'react-bootstrap'
import { Card } from '../blocks/Card'
import { Table } from '../elements/table/Table'
import Header from '../elements/table/Header'
import Column from '../elements/table/columns/Column'
import { Button } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { Flex } from '../elements/Flex'
import { Field, submit } from 'redux-form'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import config from '../../config'
import { connect } from '../../utils/connect'
import { getPartners } from '../../actions/partners'
import { partnerListSelector } from '../../selectors/partner'
import Cell from '../elements/table/cells/Cell'
import DateCell from '../elements/table/cells/DateCell'
import Checkbox from '../blocks/Checkbox'
import { Partner } from '../../reducers/types/entities/Partner'
import { createLeadAction } from '../../actions/actions'
import { getLead } from '../../actions/leads'
import { Lead } from '../../reducers/types/entities/Lead'
import { Alert } from '../elements/Alerts'
import { toastr } from 'react-redux-toastr'

interface PartnerRowModel {
    partner: Partner,
    checked: boolean
    selectPartner: (partner: Partner, event: Event) => void
}

const PartnerRow = memo(({
    checked,
    partner,
    selectPartner,
}: PartnerRowModel) =>
    <tr>
        <Cell>
            <Checkbox
                checked={checked}
                onChange={e => selectPartner(partner, e.target.checked)}
            />
        </Cell>
        <Cell>{partner.companyName}</Cell>
        <Cell>{partner.subscription.map(el => el.packageType).join(', ')}</Cell>
        <DateCell date={partner.kpi.lastPublishedQuote} format="datetime" />
    </tr>
)

interface SendEmailStateProps {
    partners: Partner[]
    lead: Lead
    isLoading: boolean
    isLoadingAction: boolean
}
interface SendEmailDispatchProps {
    createLeadAction: typeof createLeadAction
    getPartners: typeof getPartners
    getLead: typeof getLead
    submit: typeof submit
}

interface SendEmailPathProps {
    match: {
        params: {
            leadId: number
        }
    }
}

type SendEmailModel = SendEmailStateProps & SendEmailDispatchProps & SendEmailPathProps

interface SendEmailStateModel {
    selectedPartners
    customFees
}

@connect<SendEmailStateProps, SendEmailDispatchProps, SendEmailPathProps>(
    (state, props) => ({
        partners: partnerListSelector(state),
        lead: state.entities.leads[props.match.params.leadId],
        isLoading: state.pages.partners.isLoading,
        isLoadingAction: state.pages.leadValidation.isLoading,
    }),
    {
        createLeadAction,
        getPartners,
        getLead,
        submit,
    }
)
export default class SendEmailToExclusiveLeadPage extends PureComponent<SendEmailModel, SendEmailStateModel> {
    state = {
        selectedPartners: [],
        customFees: {},
    }

    checkedPartners = new Set()

    componentDidMount(): void {
        this.props.getLead(this.props.match.params.leadId)
    }

    searchExclusivePartners = ({ query }) => {
        this.props.getPartners({
            geoCode: config.geoCode,
            wantsExclusiveLeads: '1',
            query,
        })
    }

    sendInvoices = async () => {
        for (let i = 0; i <= this.state.selectedPartners.length - 1; i++) {
            const partner = this.state.selectedPartners[i]
            await this.props.createLeadAction(this.props.match.params.leadId, {
                action: 'reserve_quote',
                partnerId: partner.id,
                customFee: {
                    amount: this.state.customFees[partner.id],
                    currency: config.currency[partner.geo.code],
                },
            })
        }
        toastr.success('Success!', 'The invoices have been created and spots are reserved for the partners on the lead')
    }

    selectPartner = (partner, checked) => {
        if (checked) this.checkedPartners.add(partner)
        else this.checkedPartners.delete(partner)
        this.setState(state => {
            const newState = {
                selectedPartners: Array.from(this.checkedPartners),
            }
            if (!checked) {
                const customFees = { ...state.customFees }
                delete customFees[partner.id]
                Object.assign(newState, { customFees })
            }
            return newState
        })
    }

    addFee = (amount, partnerId) => {
        this.setState(state => ({
            customFees: {
                ...state.customFees,
                [partnerId]: amount,
            },
        }))
    }

    render() {
        const {
            isLoadingAction,
            isLoading,
            partners,
            lead,
        } = this.props
        const availableQuotes = lead && (lead.quotesInfo.max - lead.quotesInfo.reserved - lead.quotesInfo.count)
        return <Row>
            <Col md={7}>
                <Card>
                    <Card.Header>Search partners</Card.Header>
                    <Card.Content>
                        <ReduxForm
                            form="exclusivePartnersSearch"
                            onSubmit={this.searchExclusivePartners}
                        >
                            <Flex>
                                <Field
                                    removeClassFormGroup={true}
                                    name="query"
                                    component={FieldGroup}
                                />
                                {' '}
&nbsp;
                                <Button
                                    modifiers={[ 'action' ]}
                                    type="submit"
                                >
                                    Search
                                </Button>
                            </Flex>
                        </ReduxForm>
                    </Card.Content>
                    <Card.Content isLoading={isLoading} modifiers="p_0">
                        <Table>
                            <colgroup>
                                <col width="50px"/>
                            </colgroup>
                            <Header>
                                <Column/>
                                <Column>Name</Column>
                                <Column>Subscription</Column>
                                <Column>Last quote</Column>
                            </Header>
                            <tbody>
                                {partners.length
                                    ? partners.map(partner =>
                                        <PartnerRow
                                            key={partner.id}
                                            partner={partner}
                                            selectPartner={this.selectPartner}
                                            checked={this.checkedPartners.has(partner)}
                                        />
                                    )
                                    : <td
                                        colSpan={4}
                                        className="text-center"
                                    >
                                        We couldn't find any partners
                                    </td>
                                }
                            </tbody>
                        </Table>
                    </Card.Content>
                </Card>
            </Col>
            <Col md={5}>
                <Card>
                    <Card.Header>Chosen partners</Card.Header>
                    <Card.Content isLoading={isLoadingAction}>
                        <Alert>
                            Lead has 
                            {' '}
                            <b>{availableQuotes}</b>
                            {' '}
                            available quote
                        </Alert>
                        {Boolean(this.state.selectedPartners.length) && <Card.Line modifiers="mT_2"/>}
                        <Table modifiers="splitByRow">
                            <colgroup>
                                <col />
                                <col width="120px"/>
                                <col width="55px"/>
                            </colgroup>
                            <tbody>
                                {this.state.selectedPartners.map(partner =>
                                    <tr key={'chosen_partners_' + partner.id}>
                                        <td>{partner.companyName}</td>
                                        <td>
                                            <FormControl
                                                onChange={(e: any) => this.addFee(e.target.value, partner.id)}
                                                type="number"
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                onClick={() => this.selectPartner(partner, false)}
                                                modifiers={[ 'danger', 'btnIcon', 'mL_1' ]}
                                            >
                                                <i className="mdi mdi-close" />
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Button
                            modifiers={[ 'primary', 'mT_2' ]}
                            onClick={this.sendInvoices}
                            disabled={!availableQuotes}
                        >
                            Save & send invoices
                        </Button>
                    </Card.Content>
                </Card>
            </Col>
        </Row>
    }
}
