import React, { memo } from 'react'
import { FormGroup } from 'react-bootstrap'
import ControlLabel from '../../text/MetaLabel'
import styled from 'styled-components'
import { Typeahead, TypeaheadProps } from 'react-bootstrap-typeahead'
import { WrappedFieldProps } from 'redux-form'

const SearchInput = styled(Typeahead)`
  ${props => props.multiple && '.form-control { height: auto }'}
  input {
    height: 40px;
  }
  .rbt-aux {
    top: 4px;
  }
`

interface TypeaheadGroupModel extends TypeaheadProps<any>, WrappedFieldProps {
    removeClassFormGroup?: boolean
    className?: string
    label: string
    labelKey: any
}

export const TypeaheadGroup = memo(({
    renderMenuItemChildren,
    removeClassFormGroup,
    placeholder,
    clearButton,
    className,
    labelKey,
    disabled,
    multiple,
    options,
    input,
    label,
    meta: {
        touched,
        error,
        warning,
    },
}: TypeaheadGroupModel) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={touched && (error || warning) ? 'error' : null}
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        <SearchInput
            id={input.name}
            onChange={input.onChange}
            placeholder={placeholder}
            disabled={disabled}
            labelKey={labelKey}
            options={options}
            multiple={multiple}
            clearButton={clearButton}
            defaultSelected={[ ...(input.value || []) ]}
            renderMenuItemChildren={renderMenuItemChildren}
        />
    </FormGroup>
)
