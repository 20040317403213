import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card } from '../blocks/Card'
import { reset } from 'redux-form'
import { Field, FormikProps, Form } from 'formik'
import { withFormik } from '../../utils/withFormik'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import Checkbox from '../blocks/Checkbox'
import { Flex } from '../elements/Flex'
import { Button } from '../elements/Button'
import PartnerHeader from './detailPage/PartnerHeader'
import { connect } from '../../utils/connect'
import { getPartner } from '../../actions/partners'
import moment from 'moment'
import { Partner } from '../../reducers/types/entities/Partner'
import LanguageItem from '../elements/LanguageItem'
import { createPartnerUserLanguage, deletePartnerUserLanguage, getPartnerUserCv } from '../../actions/cv'
import LanguageForm from './LanguageForm'
import { showModal } from '../../actions/modal'
import { PartnerUser } from '../../reducers/types/entities/PartnerUser'
import { replace, push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { changePassword } from '../../actions/auth'
import { createPartnerUser, getPartnerUser, updatePartnerUser } from '../../state/partnerUsers/actions'
import View from '../theme/View'
const required = [ value => value ? undefined : 'Required!' ]

interface StatePropsPartnerUser {
    partner: Partner
    partnerUser: PartnerUser
    userCv
}

interface DispatchPropsPartnerUser {
    deletePartnerUserLanguage: typeof deletePartnerUserLanguage
    createPartnerUserLanguage: typeof createPartnerUserLanguage
    createPartnerUser: typeof createPartnerUser
    updatePartnerUser: typeof updatePartnerUser
    changePassword: typeof changePassword
    getPartnerUserCv: typeof getPartnerUserCv
    getPartnerUser: typeof getPartnerUser
    getPartner: typeof getPartner
    showModal: typeof showModal
    replace: typeof replace
    reset: typeof reset
    push: typeof push
}

interface PathPropsPartnerUser {
    match: {
        params: {
            partnerId: number
            partnerUserId: number
        }
    }
}

type PartnerUsersModel = StatePropsPartnerUser & DispatchPropsPartnerUser & PathPropsPartnerUser

interface FormValues {
    email,
    firstName: string,
    isFinanceUser: boolean,
    lastName: string,
    mobile: string,
    password: string,
    phone: string,
    wantsDailyEmail: boolean,
    wantsEmails: boolean,
    wantsTextMessages: boolean,
    isVisible: boolean,
}

@connect<StatePropsPartnerUser, DispatchPropsPartnerUser, PathPropsPartnerUser>(
    (state, { match: { params } }) => ({
        partner: state.entities.partners[params.partnerId],
        partnerUser: state.entities.partnerUsers[params.partnerUserId],
        userCv: state.entities.cvs[params.partnerUserId],
    }),
    {
        createPartnerUserLanguage,
        deletePartnerUserLanguage,
        updatePartnerUser,
        changePassword,
        createPartnerUser,
        getPartnerUserCv,
        getPartnerUser,
        getPartner,
        showModal,
        replace,
        reset,
        push,
    }
)
@withFormik<PartnerUsersModel, FormValues>({
    enableReinitialize: true,
    handleSubmit: async (values, bag) => {
        const { partnerId, partnerUserId } = bag.props.match.params
        if (isNaN(Number(partnerUserId))) {
            const resPartnerUser: any = await bag.props.createPartnerUser(partnerId, values)
            bag.props.push(`/partners/${partnerId}/users/${resPartnerUser.result}`)
            toastr.success('Success!', 'Partner user created')
        } else {
            await bag.props.updatePartnerUser(partnerId, values)
            if (values.password) {
                await bag.props.changePassword(values.email, values.password, 'partner_user')
            }
            toastr.success('Success!', 'Partner user updated')
        }
        bag.resetForm({})
    },
    mapPropsToValues: props => (({
        ...props.partnerUser
    }))
})
export class PartnerUserPage extends PureComponent<PartnerUsersModel & FormikProps<FormValues>> {
    componentDidMount() {
        const { partnerId, partnerUserId } = this.props.match.params
        this.props.getPartner(partnerId)

        if (!isNaN(Number(partnerUserId))) {
            const resPartnerUser: any = this.props.getPartnerUser(partnerUserId)
            resPartnerUser
                .catch(() => this.props.replace('/partners/' + partnerId))
                .then(() => this.props.getPartnerUserCv(partnerUserId))
        }
    }

    deleteUser = () => {
        this.props.showModal({
            partnerUserId: this.props.match.params.partnerUserId,
            partnerId: this.props.match.params.partnerId,
            confirmData: {
                headerText: 'Confirm deletion of partner user',
                bodyText: <span>
                    Are you sure you want to delete
                    <b>{this.props.partnerUser.email}</b>
                    ?
                </span>,
                btnApproveText: 'Delete',
                btnDenyText: 'Cancel',
                btnApproveModifiers: [ 'danger' ],
            },
            name: 'deletePartnerUserConfirm',
        })
    }

    handleRemoveLanguageClick = async (languageId) => {
        const { partnerUserId } = this.props.match.params
        await this.props.deletePartnerUserLanguage(partnerUserId, languageId)
        this.props.getPartnerUserCv(partnerUserId)
    }

    onLanguageSubmit = async data => {
        const { partnerUserId } = this.props.match.params

        const body = {
            partnerUserId,
            identifier: data.identifier[0].identifier,
            proficiency: data.proficiency[0].value,
        }
        await this.props.createPartnerUserLanguage(partnerUserId, body)
        this.props.getPartnerUserCv(partnerUserId)
        this.props.reset('languageCv')
    }

    makeMasterUser = () => {
        const { partnerUserId, partnerId } = this.props.match.params

        const resupdatePartnerUser: any = this.props.updatePartnerUser(partnerId, {
            accessType: 'master',
            id: partnerUserId,
        })
        resupdatePartnerUser.then(res => {
            toastr.success('Success!', 'Partner user updated')
        })
    }

    render() {
        const {
            partnerUser,
            partner,
            userCv,
            values,
        } = this.props

        const createdSubscription = partner && partner.subscription
            .find(sub => sub.startsAt && moment().isBefore(sub.expiresAt))

        return <div>
            {partner && <PartnerHeader
                partner={partner}
                subscription={createdSubscription}
            />}
            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Header>Edit partner user details</Card.Header>
                        <Card.Content>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <Field
                                            label="First name"
                                            name="firstName"
                                            component={FieldGroup}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            label="Last name"
                                            name="lastName"
                                            component={FieldGroup}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            label="Email"
                                            name="email"
                                            type="email"
                                            validate={required}
                                            component={FieldGroup}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            label="Password"
                                            name="password"
                                            component={FieldGroup}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            label="Phone number"
                                            name="phone"
                                            component={FieldGroup}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            label="Mobile number"
                                            name="mobile"
                                            component={FieldGroup}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <View alignItems="center" flexDirection="row">
                                            <Field
                                                name="wantsEmails"
                                                component={Checkbox}
                                                checked={values.wantsEmails}
                                                value={true}
                                                uncheckedValue={false}
                                            />
                                            Wants segmentation emails
                                        </View>
                                    </Col>
                                    <Col md={6}>
                                        <View alignItems="center" flexDirection="row">
                                            <Field
                                                name="wantsDailyEmail"
                                                component={Checkbox}
                                                checked={values.wantsDailyEmail}
                                                value={true}
                                                uncheckedValue={false}
                                            />
                                            Wants daily emails
                                        </View>
                                    </Col>
                                    <Col md={6}>
                                        <View alignItems="center" flexDirection="row">
                                            <Field
                                                name="wantsTextMessages"
                                                component={Checkbox}
                                                checked={values.wantsTextMessages}
                                                value={true}
                                                uncheckedValue={false}
                                            />
                                            Wants text messages
                                        </View>
                                    </Col>
                                    <Col md={6}>
                                        <View alignItems="center" flexDirection="row">
                                            <Field
                                                name="isFinanceUser"
                                                component={Checkbox}
                                                checked={values.isFinanceUser}
                                                value={true}
                                                uncheckedValue={false}
                                                disabled={partnerUser && partnerUser.isFinanceUser}
                                            />
                                            Responsible for finance
                                        </View>
                                    </Col>
                                    <Col md={6}>
                                        <View alignItems="center" flexDirection="row">
                                            <Field
                                                name="isVisible"
                                                component={Checkbox}
                                                checked={values.isVisible}
                                                value={true}
                                                uncheckedValue={false}
                                            />
                                            Is publicly visible
                                        </View>
                                    </Col>
                                </Row>
                                <Card.Line modifiers="mY_2" />
                                <Flex modifiers={[ 'mT_1', 'justifySpaceBetween' ]}>
                                    {partnerUser && <div>
                                        <Button
                                            modifiers={[ 'danger', 'mR_2' ]}
                                            onClick={this.deleteUser}
                                        >
                                            Delete partner user
                                        </Button>
                                        {partnerUser.accessType !== 'master' && <Button
                                            modifiers="action"
                                            onClick={this.makeMasterUser}
                                        >
                                            Make as master
                                        </Button>}
                                    </div>}
                                    <div>
                                        <Button type="submit" modifiers={[ 'action', 'mR_2' ]}>
                                            Save changes
                                        </Button>
                                    </div>
                                </Flex>
                            </Form>
                        </Card.Content>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Header>Partner user languages</Card.Header>
                        <Card.Content
                            modifiers={[ 'maxHeight', 'scroll' ]}
                            scrollHeight="400px"
                            disabled={!partnerUser}
                        >
                            <LanguageForm handleSubmit={this.onLanguageSubmit}/>
                            <br />
                            <Row>
                                {userCv && userCv.languages.map(language =>
                                    <Col md={6} lg={4} key={'language_' + language.id}>
                                        <LanguageItem
                                            onDelete={() => this.handleRemoveLanguageClick(language.id)}
                                            key={language.id}
                                            lang={language}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Card.Content>
                    </Card>
                </Col>
            </Row>
        </div>
    }
}
