import { createSelector } from 'reselect'

const entitiesAllocations = state => state.entities.allocations

const subjectAllocationsId = state => state.subjects.allocations.all.ids

export const allocationsSelector = createSelector(
    subjectAllocationsId, entitiesAllocations,
    (ids, allocations) => ids.map(id => allocations[id])
)
