import React, { PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import Tabs from '../../elements/Tabs'
import LeadCustomVariablesCard from './LeadCustomVariablesCard'
import LeadBasicInformationCard from './LeadBasicInformationCard'
import { Lead } from '../../../reducers/types/entities/Lead'

export class LeadInformationCard extends PureComponent<{lead: Lead}> {
    state = {
        selectedTab: 'basic',
    }

    render() {
        const { lead } = this.props

        const { selectedTab } = this.state

        return <Card>
            <Card.Header padding="0 20px">
                <Tabs
                    tabs={[
                        { tabName: 'Basic', tabId: 'basic' },
                        { tabName: 'Marketing data', tabId: 'customVariables' },
                    ]}
                    selectedTab={selectedTab}
                    changeTab={tabIndex => this.setState({ selectedTab: tabIndex })}
                />
            </Card.Header>
            {selectedTab === 'basic'
                ? <LeadBasicInformationCard lead={lead} />
                : <LeadCustomVariablesCard lead={lead} />
            }
        </Card>
    }
}
