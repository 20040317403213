import styled from 'styled-components'
import { tableBorderColor, cardPadding } from '../../../utils/variables'
import { Flex } from '../../elements/Flex'

export default styled(Flex)`
    padding: ${(props:any) => props.padding || cardPadding};
    border-bottom: 1px solid ${tableBorderColor};
    overflow: auto;
    height: ${(props:any) => props.height || '64px'};
    min-height: 64px;
    box-sizing: border-box;
    font-size: 16px;
`
