import {
    GET_INVOICE_REFUND_REQUESTS,
    GET_INVOICE_REFUND_REQUESTS_SUCCESS,
    GET_INVOICE_REFUND_REQUESTS_FAIL,

} from '../../actions/invoices'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface InvoiceRefundsModel extends InitialState {
    refunds: number[]
}
const initialState: InvoiceRefundsModel = {
    isLoading: null,
    refunds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction,
) {
    switch (action.type) {
        case GET_INVOICE_REFUND_REQUESTS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_INVOICE_REFUND_REQUESTS_SUCCESS:
            return {
                ...state,
                refunds: action.append ? state.refunds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_INVOICE_REFUND_REQUESTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
