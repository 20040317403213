import { CALL_API } from '../middleware/api'

export const PIN_ACTIVITY = 'leads/PIN_ACTIVITY'
export const PIN_ACTIVITY_SUCCESS = 'leads/PIN_ACTIVITY_SUCCESS'
export const PIN_ACTIVITY_FAIL = 'leads/PIN_ACTIVITY_FAIL'

export const UNPIN_ACTIVITY = 'leads/UNPIN_ACTIVITY'
export const UNPIN_ACTIVITY_SUCCESS = 'leads/UNPIN_ACTIVITY_SUCCESS'
export const UNPIN_ACTIVITY_FAIL = 'leads/UNPIN_ACTIVITY_FAIL'

export const createActivityAction = (
    action: string,
    activityId: number,
    types: string[],
) => ({
    [CALL_API]: {
        types,
        endpoint: `/activities/${activityId}/actions`,
        options: {
            method: 'post',
            body: {
                action,
            },
        },
    },
})

export const pinActivity = (activityId: number) => createActivityAction(
    'pin',
    activityId,
    [
        PIN_ACTIVITY,
        PIN_ACTIVITY_SUCCESS,
        PIN_ACTIVITY_FAIL,
    ]
)

export const unpinActivity = (activityId: number) => createActivityAction(
    'unpin',
    activityId,
    [
        UNPIN_ACTIVITY,
        UNPIN_ACTIVITY_SUCCESS,
        UNPIN_ACTIVITY_FAIL,
    ]
)
