import { createSelector } from 'reselect'

export const sectorsEntities = state => state.entities.sectors

export const allSectorsId = state => state.subjects.sectors.all.ids

export const partnerSectorsId = state => state.subjects.sectors.partner.ids

export const businessUnitSectorsId = state => state.subjects.sectors.businessUnit.ids

export const allSectorsSelector = createSelector(
    allSectorsId, sectorsEntities,
    (ids, entities) => ids.map(id => entities[id])
)

export const businessUnitSectorsSelector = createSelector(
    businessUnitSectorsId, sectorsEntities,
    (ids, entities) => ids.map(id => entities[id])
)
