import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG } from '../modifiers/spaceModifiers'

type MarginModifierModel = keyof typeof MODIFIER_MARGIN_CONFIG

export default styled.p<{modifiers?: MarginModifierModel[] | MarginModifierModel}>`
    font-size: 15px;
    line-height: 20px;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
