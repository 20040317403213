import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_PARTNER_SIGNUPS = 'partnersignups/GET_PARTNER_SIGNUPS'
export const GET_PARTNER_SIGNUPS_SUCCESS = 'partnersignups/GET_PARTNER_SIGNUPS_SUCCESS'
export const GET_PARTNER_SIGNUPS_FAIL = 'partnersignups/GET_PARTNER_SIGNUPS_FAIL'

interface GetPartnerSignupsPropsModel {
    industryId?: number[]
    isProcessed?: '0' | '1'
    processedAtGte?: string
    processedAtLte?: string
    expiresAtGte?: string
    expiresAtLte?: string
}
export const getPartnerSignups = (
    criteria: GetPartnerSignupsPropsModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_SIGNUPS,
            GET_PARTNER_SIGNUPS_SUCCESS,
            GET_PARTNER_SIGNUPS_FAIL,
        ],
        endpoint: '/partnersignups',
        schema: schemas.partnerSignups,
        options: {
            body: {
                limit: 200,
                ...criteria,
            },
        },
    },
    append,
})
