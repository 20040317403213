import { combineReducers } from 'redux'
import csmTaskList from './csmTaskList'
import taskList from './taskList'
import employees from './employees'
import matchSurveillance from './matchSurveillance'
import partnerDetail from './partnerDetail'
import partners from './partners'
import leads from './leads'
import locationFilter from './locationFilter'
import quotes from './quotes'
import terms from './terms'
import partnerQuotes from './partnerQuotes'
import leadDetail from './leadDetail'
import invoiceRefunds from './invoiceRefunds'
import leadValidation from './leadValidation'
import completedCallFlow from './completedCallFlow'
import kpiTarget from './kpiTarget'
import quotesSurveillance from './quotesSurveillance'
import openCasesLeads from './openCasesLeads'
import newsfeed from './newsfeed'
import matchReport from './matchReport'
import intervalLeads from './intervalLeads'
import subscription from './subscription'
import businessUnits from './businessUnits'
import invoicings from './invoicings'
import companySizes from './companySizes'
import partnerSignups from './partnerSignups'
import clients from './clients'
import customers from './customers'

const pagesReducer = combineReducers({
    csmTaskList,
    taskList,
    employees,
    matchSurveillance,
    partnerDetail,
    partners,
    leads,
    locationFilter,
    quotes,
    partnerQuotes,
    terms,
    leadDetail,
    leadValidation,
    invoiceRefunds,
    completedCallFlow,
    kpiTarget,
    quotesSurveillance,
    openCasesLeads,
    newsfeed,
    matchReport,
    intervalLeads,
    subscription,
    businessUnits,
    invoicings,
    companySizes,
    partnerSignups,
    clients,
    customers,
})

export default pagesReducer
