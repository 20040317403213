import { createElement } from 'react'

const newlineRegex = /(\r\n|\n\r|\r|\n)/g

export default function nl2br(str) {
    if (typeof str === 'number')
        return str
    else if (typeof str !== 'string')
        return ''

    return str.split(newlineRegex).map((line, index) =>
        line.match(newlineRegex) ? createElement('br', { key: index }) : line
    )
}
