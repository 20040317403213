let apiUrl
let bladeFrontendUrl

if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.ageras.com/v2'
    bladeFrontendUrl = 'https://intranet.ageras.com'
}
if (process.env.NODE_ENV !== 'production') {
    apiUrl = 'https://api.staging.ageras.com/v2'
    bladeFrontendUrl = 'https://intranet.staging.ageras.com'
}

if (typeof process.env.REACT_APP_API_URL !== 'undefined') {
    apiUrl = process.env.REACT_APP_API_URL
}
if (typeof process.env.REACT_APP_BLADE_FRONTEND_URL !== 'undefined') {
    bladeFrontendUrl = process.env.REACT_APP_BLADE_FRONTEND_URL
}
// @ts-ignore
if (typeof window.apiUrl !== 'undefined') {
    // @ts-ignore
    apiUrl = window.apiUrl
}
// @ts-ignore
if (typeof window.bladeFrontendUrl !== 'undefined') {
    // @ts-ignore
    bladeFrontendUrl = window.bladeFrontendUrl
}

export default {
    isReactPage: !document.getElementById('react-topbar'),
    apiUrl,
    bladeFrontendUrl,
    serviceUrl: apiUrl,
    pageConfig: {},
    geoCode: '', // deprecated: New: Auth.wantedGeoCode
    industryId: window.localStorage.getItem('industry_id'),
    DONE_TASK: 2,
    PENDING_TASK: 1,
    TOP_BAR_TASK_LIMIT: 5,
    countries: [
        { id: 'dk', name: 'Danmark' },
        { id: 'se', name: 'Sverige' },
        { id: 'no', name: 'Norge' },
        { id: 'nl', name: 'Nederland' },
        { id: 'de', name: 'Deutschland' },
        { id: 'uk', name: 'United Kingdom' },
        { id: 'us', name: 'United States' },
    ],
    currency: {
        dk: 'DKK',
        se: 'SEK',
        no: 'NOK',
        de: 'EUR',
        uk: 'GBP',
        nl: 'EUR',
        us: 'USD',
    },
    centers: {
        dk: { lat: 55.9396, lon: 9.5155848 },
        se: { lat: 62.1983, lon: 17.567198 },
        no: { lat: 64.5783, lon: 17.888237 },
        de: { lat: 51.1642, lon: 10.454119 },
        uk: { lat: 55.3617, lon: -3.443323 },
        nl: { lat: 52.2129, lon: 5.279370 },
        us: { lat: 37.6000, lon: -95.665000 },
    },
    intervals: {
        morning: 0,
        afternoon: 13,
        evening: 17,
    },
    marketplaceCountries: [ 'us', 'dk', 'no' ],
}
