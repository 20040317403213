import React, { PureComponent, memo } from 'react'
import { getLeadContactArchives, getPartnerContactArchives, resendContactArchive } from '../../actions/contact'
import { ContactArchive } from '../../reducers/types/entities/ContactArchives'
import { connect } from '../../utils/connect'
import { LazyTable, Table, Column, Header, Rows, Cell, DateCell } from '../elements/table'
import { Pagination } from '../../reducers/types/Pagination'
import { Card } from '../blocks/Card'
import PageTitle from '../elements/PageTitle'
import { getPartner } from '../../actions/partners'
import { Partner } from '../../reducers/types/entities/Partner'
import get from 'lodash/get'

interface ContactFlowRowModel {
    rowData?: ContactArchive
}

const ContactFlowRow = memo(({ rowData }: ContactFlowRowModel) => {
    const contact = rowData

    const email = contact.channels.find(el => el.type === 'email')

    const emailIcon = (status) => {
        switch (status) {
            case 'open':
                return 'fa fa-envelope-o text-primary'
            case 'delivered':
                return 'fa fa-envelope text-success'
            case 'processed':
                return 'fa fa-envelope text-info'
            case 'deferred':
                return 'fa fa-envelope text-warning'
            case 'click':
                return 'fa fa-envelope-o text-success'
            default:
                return 'entypo-mail'
        }
    }

    return <tr key={contact.id}>
        <Cell>
            {contact.channels.map(el => el.type === 'email'
                ? <div className={emailIcon(el.status)} />
                : <div className="fa fa-comment-dots"/>
            )}
        </Cell>
        <Cell>{contact.channels.map(el => <p key={el.title}>{el.title}</p>)}</Cell>
        <Cell>{get(contact, 'employee.displayName')}</Cell>
        <Cell>{get(contact, 'partnerUser.displayName')}</Cell>
        <Cell>{email.status || 'No status available'}</Cell>
        {(email && email.statusUpdateAt)
            ? <DateCell date={email.statusUpdateAt} format="datetime" />
            : <Cell>-</Cell>
        }
        <Cell>
            {contact.channels.map(el => el.type === 'email'
                ? <div>{el.receiverEmailAddress}</div>
                : <div>{el.receiverPhoneNumber}</div>
            )}
        </Cell>
        <DateCell date={contact.sentAt} format="datetime" />
    </tr>
})

interface StatePropsContactArchives {
    contactArchives: ContactArchive[]
    isLoading: boolean
    pagination: Pagination
    partner: Partner
}
interface DispatchPropsContactArchives {
    getPartnerContactArchives: typeof getPartnerContactArchives
    getLeadContactArchives: typeof getLeadContactArchives
    resendContactArchive: typeof resendContactArchive
    getPartner: typeof getPartner
}
interface PathPropsContactArchives {
    match: {
        params: {
            partnerId: number
        }
    }
}

type ContactArchivesModel = StatePropsContactArchives & DispatchPropsContactArchives & PathPropsContactArchives

@connect<StatePropsContactArchives,  DispatchPropsContactArchives, PathPropsContactArchives>(
    (state, props) => ({
        contactArchives: state.pages.partnerDetail.archivesIds.map(id => state.entities.contactArchives[id]),
        isLoading: state.pages.partnerDetail.loadingArchives,
        pagination: state.pages.partnerDetail.archivesPagination,
        partner: state.entities.partners[props.match.params.partnerId],
    }),
    {
        getPartnerContactArchives,
        getLeadContactArchives,
        resendContactArchive,
        getPartner,
    }
)
export default class PartnerContactArchivesPage extends PureComponent<ContactArchivesModel> {
    componentDidMount() {
        const { partnerId } = this.props.match.params
        this.retrieveData(1)
        this.props.getPartner(partnerId)
    }

    retrieveData = page => {
        return this.props.getPartnerContactArchives(this.props.match.params.partnerId, page, true)
    }

    render() {
        const {
            contactArchives,
            isLoading,
            partner,
            pagination,
        } = this.props

        return (
            <Card margin="0">
                <PageTitle title="Contact archive" />
                <Card.Header>
                    Contact archive for 
                    {' '}
                    {partner && partner.companyName}
                    <span className="text-muted">
                        {' '}
                        (
                        {contactArchives.length}
                        {' '}
                        /
                        {pagination.total || 0}
                        )
                    </span>
                </Card.Header>
                <Card.Content>
                    <LazyTable
                        isLoading={isLoading}
                        pagination={pagination}
                        modifiers={[ 'smMaxHeight', 'p_0' ]}
                        retrieveData={this.retrieveData}
                        scrollHeight="calc(100vh - 240px)"
                    >
                        <Table>
                            <Header>
                                <Column/>
                                <Column>Subject</Column>
                                <Column>Employee</Column>
                                <Column>Partner user</Column>
                                <Column>Status</Column>
                                <Column>Last update</Column>
                                <Column>Receiver</Column>
                                <Column>Sent at</Column>
                            </Header>
                            <Rows
                                items={contactArchives}
                                noDataText="No e-mails"
                            >
                                <ContactFlowRow />
                            </Rows>
                        </Table>
                    </LazyTable>
                </Card.Content>
            </Card>
        )
    }
}
