import React, { PureComponent } from 'react'
import { Card } from '../blocks/Card/index'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, reset, submit } from 'redux-form'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Button } from '../elements/Button'
import { Alert, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { leadActionForgotLead } from '../../actions/leads'
import { Flex } from '../elements/Flex'
import { partnerActionForgotPartner } from '../../actions/partners'
import { showModal } from '../../actions/modal'
import { connect } from '../../utils/connect'

const AlertText = styled.div`
  font-size: 16px;
`

const H2 = styled.p`
  font-size: 22px;
`

interface GDPRPageModel {
    invokeRightToBeForgottenPermission: any
    partnerActionForgotPartner: typeof partnerActionForgotPartner
    leadActionForgotLead: typeof leadActionForgotLead
    showModal: typeof showModal
    submit: typeof submit
    reset: typeof reset
}
@connect(
    state => ({
        invokeRightToBeForgottenPermission: state.entities.permissions.leads_action_invoke_right_to_be_forgotten,
    }),
    {
        partnerActionForgotPartner,
        leadActionForgotLead,
        showModal,
        reset,
        submit,
    }
)
export default class GDPRPage extends PureComponent<GDPRPageModel> {
    constructor(props) {
        super(props)
        this.state = {
            modalProps: {},
        }
    }

    removePartner = ({ partnerId }) => {
        this.props.showModal({
            name: 'GDPRconfirm',
            partnerId,
            actionName: 'partner',
            headerText: 'Delete all personal data for this Partner',
            bodyText: <h4>
                You are about to delete all data related to the partner
                <strong>
                    ID
                    {partnerId}
                    .
                    {' '}
                </strong>
                This action cannot be undone, would you like to proceed?
            </h4>,
            btnApproveText: 'Yes',
            btnDenyText: 'No',
            btnApproveModifiers: [ 'danger' ],
        })
    }

    removeLead = ({ leadId }) => {
        this.props.showModal({
            name: 'GDPRconfirm',
            leadId,
            headerText: 'Delete all personal data for this Lead',
            bodyText: <h4>
                You are about to delete all data related to the lead
                <strong>
                    ID
                    {leadId}
                    .
                    {' '}
                </strong>
                This action cannot be reverted, so are you sure?
            </h4>,
            btnApproveText: 'Yes',
            btnDenyText: 'No',
            btnApproveModifiers: [ 'danger' ],
        })
    }

    resetModalProps = () => {
        this.setState({ modalProps: {} })
    }

    render() {
        const { invokeRightToBeForgottenPermission } = this.props

        return (
            <Card>
                <Card.Header>
                    <strong>Handling of Data Requests (GDPR)</strong>
                </Card.Header>
                <Row>
                    <Col sm={6}>
                        <Card.Content>
                            <H2>
                                Right to be Forgotten
                                <i>(Lead)</i>
                            </H2>
                            <Alert bsStyle="warning">
                                <AlertText>
                                    Enter a 
                                    {' '}
                                    <strong>lead ID</strong>
                                    {' '}
                                    and all personal data connected with this lead will be permanently deleted. All personal data from all leads with the same email address will also be deleted. It cannot be undone.
                                </AlertText>
                            </Alert>
                            {invokeRightToBeForgottenPermission ?
                                <ReduxForm
                                    form="gdprLeadId"
                                    onSubmit={this.removeLead}
                                >
                                    <Flex>
                                        <Field
                                            name="leadId"
                                            component={FieldGroup}
                                            removeClassFormGroup={true}
                                            placeholder="Lead id ..."
                                            required
                                        />

                                        <Button
                                            type="submit"
                                            modifiers={[ 'primary', 'mL_2' ]}
                                        >
                                            Delete lead data
                                        </Button>
                                    </Flex>
                                </ReduxForm> :
                                <h4>You haven't permission</h4>
                            }
                        </Card.Content>
                    </Col>
                    <Col sm={6}>
                        <Card.Content>
                            <H2>
                                Right to be Forgotten
                                <i>(Partner)</i>
                            </H2>
                            <Alert bsStyle="warning">
                                <AlertText>
                                    Enter a 
                                    {' '}
                                    <strong>partner ID</strong>
                                    {' '}
                                    to forget this partner in the system. Only sole proprietorships are eligible to be forgotten. By forgetting the partner he/she will be hidden from the website, anonymised in the database and unsubscribed from any e-mails. This action cannot be undone. Note that if the partners has any invoices the past 5 years some data will be kept for legal purposes.
                                </AlertText>
                            </Alert>
                            {invokeRightToBeForgottenPermission ?
                                <ReduxForm
                                    form="gdprPartnerId"
                                    onSubmit={this.removePartner}
                                >
                                    <Flex>
                                        <Field
                                            name="partnerId"
                                            placeholder="Partner id ..."
                                            component={FieldGroup}
                                            removeClassFormGroup={true}
                                            required
                                        />

                                        <Button
                                            type="submit"
                                            modifiers={[ 'primary', 'mL_2' ]}
                                        >
                                            Delete partner data
                                        </Button>
                                    </Flex>
                                </ReduxForm> :
                                <h4>You haven't permission</h4>
                            }
                        </Card.Content>
                    </Col>
                </Row>
            </Card>
        )
    }
}
