import styled from 'styled-components'

export default styled.a`
    display: inline-block;
    line-height: 33px;
    color: #fff;
    padding: 0!important;
    width: 100%;
    &:hover {
      color: #fff;      
    }
    &[disabled] {
      pointer-events: none;
    }    
`
