import React, { PureComponent } from 'react'
import { Card } from '../blocks/Card'
import PageTitle from '../elements/PageTitle'
import { Tab, Tabs } from 'react-bootstrap'
import PartnersTable from './serch-list-tables/PartnersTable'
import { removeEmptyProperties } from '../../utils'
import config from '../../config'
import { connect } from '../../utils/connect'
import LeadsTable from './serch-list-tables/LeadsTable'
import InvoicesTable from './serch-list-tables/InvoicesTable'
import { Invoice } from '../../reducers/types/entities/Invoice'
import { getSearchTypeahead } from '../../actions/search'
import { getInvoices } from '../../state/invoices/actions'
import { allInvoicesSelector } from '../../state/invoices/selectors'

interface SearchListProps {
    invoices: Invoice[]
    totalInvoices: number
}

interface SearchListDispatch {
    getSearchTypeahead: typeof getSearchTypeahead
    getInvoices: typeof getInvoices
}

interface SearchListPathProps {
    location: {
        query: string
    }
}

type SearchListPageModel = SearchListProps & SearchListDispatch & SearchListPathProps

@connect<SearchListProps, SearchListDispatch, SearchListPathProps>(
    state => ({
        invoices: allInvoicesSelector(state),
        totalInvoices: state.subjects.invoices.all.pagination.total,
    }),
    {
        getSearchTypeahead,
        getInvoices,
    }
)
export default class SearchListPage extends PureComponent<SearchListPageModel> {
    state = {
        leads: [],
        partners: [],
    }

    componentDidMount(): void {
        this.retrievePartnerData(this.props.location.query)
        this.retrieveLeadData(this.props.location.query)
        this.retrieveInvoicesData(this.props.location.query)
    }

    retrievePartnerData = async (filterCriteria) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            type: 'partner',
            limit: 1000,
        })
        const resGetSearchTypeahead: any = await this.props.getSearchTypeahead(criteria, true)
        this.setState({ partners: resGetSearchTypeahead.data })
    }

    retrieveLeadData = async (filterCriteria) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            type: 'lead',
            limit: 1000,
        })
        const resGetSearchTypeahead: any = await this.props.getSearchTypeahead(criteria, true)
        this.setState({ leads: resGetSearchTypeahead.data })
    }

    retrieveInvoicesData = (filterCriteria) => {
        const criteria = removeEmptyProperties({
            ...filterCriteria,
            limit: 1000,
            geoCode: config.geoCode,
        })
        this.props.getInvoices(criteria)
    }

    render() {
        const {
            totalInvoices,
            invoices,
        } = this.props

        const { leads, partners } = this.state

        return <Card margin="0">
            <PageTitle title="Search" />
            <Card.Header>Search Results</Card.Header>
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                <Tab eventKey={1} title={`Partners (${partners.length})`}>
                    <PartnersTable partners={partners}/>
                </Tab>
                <Tab eventKey={2} title={`Leads (${leads.length})`}>
                    <LeadsTable leads={leads}/>
                </Tab>
                <Tab eventKey={3} title={`Vouchers (${totalInvoices || 0})`}>
                    <InvoicesTable invoices={invoices}/>
                </Tab>
            </Tabs>
        </Card>
    }
}
