import styled from 'styled-components'
import Content from './_Content'
import { applyStyleModifiers } from 'styled-components-modifiers'
const MODIFIER_SCROLLABLE_CONTENT_CONFIG = {
    smMaxHeight: () => `
        max-height: 550px;
    `,
    noSpace: () => `
        padding: 0;
    `,
}
export default styled(Content)`
    overflow-y: auto;
    ${applyStyleModifiers(MODIFIER_SCROLLABLE_CONTENT_CONFIG)}
`
