import React, { ComponentType, PureComponent } from 'react'
import { hide } from '../../actions/loader'
import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { primaryColor } from '../../utils/variables'
import { connect } from '../../utils/connect'

const Progress = styled(ProgressBar)<{transitOpacity :boolean}>`
  background-color: transparent;
  border-radius: 0;
  height: 5px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  .progress-bar {
    transition: width 800ms ease;
    background-color: ${primaryColor}
  }
  ${props => props.transitOpacity && `
    opacity: 0;
    transition: opacity 100ms ease;
  `};
`

interface LoadingBarModel {
    state: string
    status: string
    hideLoading: () => any
}
@connect(
    ({ loader }) => ({
        state: loader.state,
        status: loader.status,
    }),
    {
        hideLoading: hide,
    },
)
class LoadingBar extends PureComponent<LoadingBarModel> {
    render() {
        const {
            state, status,
        } = this.props
        let progress = 0
        if (state === 'show_status') {
            setTimeout(() => {
                this.props.hideLoading()
            }, (status === 'failed' ? 5000 : 1500))
        }

        if (state === 'loading') {
            progress = 70
        } else if (state === 'show_status') {
            progress = 100
        }

        return <Progress
            active={true}
            now={progress}
            transitOpacity={state === 'hidden'}
            bsStyle={status === 'failed' ? 'warning' : null}
        />
    }
}

export default LoadingBar as unknown as ComponentType
