import { createSelector } from 'reselect'

const pagesQuotesId = state => state.pages.quotes.quotesIds

const entitiesQuotes = state => state.entities.quotes

const pagePartnerQuotesId = state => state.pages.partnerQuotes.quotesIds

export const quotesSelector = createSelector(
    pagesQuotesId, entitiesQuotes,
    (quotesIds, quotes) => quotesIds.map(id => quotes[id])
)

const partnerIdGetter = (state, partnerId) => partnerId

export const publishedAcceptedPartnerQuotesSelector = createSelector(
    pagePartnerQuotesId, entitiesQuotes, partnerIdGetter,
    (ids, quotesEntity, partnerId) => ids.reduce((acc, id) =>
        (quotesEntity[id].partner.id === partnerId && [ 'published', 'accepted' ].includes(quotesEntity[id].status)) ? acc.concat(quotesEntity[id]) : acc, []
    )
)
