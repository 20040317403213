import config from '../config'
import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'
import { Amount } from '../reducers/types/entities/Amount'



export const GET_PARTNER = 'partners/GET_PARTNER'
export const GET_PARTNER_SUCCESS = 'partners/GET_PARTNER_SUCCESS'
export const GET_PARTNER_FAIL = 'partners/GET_PARTNER_FAIL'

export const GET_PARTNERS = 'partners/GET_PARTNERS'
export const GET_PARTNERS_SUCCESS = 'partners/GET_PARTNERS_SUCCESS'
export const GET_PARTNERS_FAIL = 'partners/GET_PARTNERS_FAIL'

export const GET_PARTNER_AGGREGATIONS = 'partners/GET_PARTNER_AGGREGATIONS'
export const GET_PARTNER_AGGREGATIONS_SUCCESS = 'partners/GET_PARTNER_AGGREGATIONS_SUCCESS'
export const GET_PARTNER_AGGREGATIONS_FAIL = 'partners/GET_PARTNER_AGGREGATIONS_FAIL'

export const SAVE_PARTNER = 'partners/SAVE_PARTNER'
export const SAVE_PARTNER_SUCCESS = 'partners/SAVE_PARTNER_SUCCESS'
export const SAVE_PARTNER_FAIL = 'partners/SAVE_PARTNER_FAIL'

export const CREATE_PARTNER_ACTION = 'partners/CREATE_PARTNER_ACTION'
export const CREATE_PARTNER_ACTION_SUCCESS = 'partners/CREATE_PARTNER_ACTION_SUCCESS'
export const CREATE_PARTNER_ACTION_FAIL = 'partners/CREATE_PARTNER_ACTION_FAIL'

export const CREATE_PARTNER_USER = 'partners/CREATE_PARTNER_USER'
export const CREATE_PARTNER_USER_SUCCESS = 'partners/CREATE_PARTNER_USER_SUCCESS'
export const CREATE_PARTNER_USER_FAIL = 'partners/CREATE_PARTNER_USER_FAIL'

export const GET_PARTNER_FEES = 'partners/GET_PARTNER_FEES'
export const GET_PARTNER_FEES_SUCCESS = 'partners/GET_PARTNER_FEES_SUCCESS'
export const GET_PARTNER_FEES_FAIL = 'partners/GET_PARTNER_FEES_FAIL'

export const CREATE_PARTNER_FEE = 'partner/CREATE_PARTNER_FEE'
export const CREATE_PARTNER_FEE_SUCCESS = 'partner/CREATE_PARTNER_FEE_SUCCESS'
export const CREATE_PARTNER_FEE_FAIL = 'partner/CREATE_PARTNER_FEE_FAIL'

export const PARTNER_ACTION_FORGOT_PARTNER = 'partner/PARTNER_ACTION_FORGOT_PARTNER'
export const PARTNER_ACTION_FORGOT_PARTNER_SUCCESS = 'partner/PARTNER_ACTION_FORGOT_PARTNER_SUCCESS'
export const PARTNER_ACTION_FORGOT_PARTNER_FAIL = 'partner/PARTNER_ACTION_FORGOT_PARTNER_FAIL'

export const PARTNER_CREATE_REVENUE = 'partner/PARTNER_CREATE_REVENUE'
export const PARTNER_CREATE_REVENUE_SUCCESS = 'partner/PARTNER_CREATE_REVENUE_SUCCESS'
export const PARTNER_CREATE_REVENUE_FAIL = 'partner/PARTNER_CREATE_REVENUE_FAIL'

export const CREATE_PARTNER_CALL_ACTION = 'partner/CREATE_PARTNER_CALL_ACTION'
export const CREATE_PARTNER_CALL_ACTION_SUCCESS = 'partner/CREATE_PARTNER_CALL_ACTION_SUCCESS'
export const CREATE_PARTNER_CALL_ACTION_FAIL = 'partner/CREATE_PARTNER_CALL_ACTION_FAIL'

export const CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS = 'partner/CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS'
export const CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS = 'partner/CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS'
export const CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL = 'partner/CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL'

export const GET_PARTNER_SUBSCRIPTION_AGREEMENTS = 'partner/GET_PARTNER_SUBSCRIPTION_AGREEMENTS'
export const GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS = 'partner/GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS'
export const GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL = 'partner/GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL'

export const GET_PARTNER_UNIQUE_SELLING_POINTS = 'partner/GET_PARTNER_UNIQUE_SELLING_POINTS'
export const GET_PARTNER_UNIQUE_SELLING_POINTS_SUCCESS = 'partner/GET_PARTNER_UNIQUE_SELLING_POINTS_SUCCESS'
export const GET_PARTNER_UNIQUE_SELLING_POINTS_FAIL = 'partner/GET_PARTNER_UNIQUE_SELLING_POINTS_FAIL'

export const CREATE_PARTNER_UNIQUE_SELLING_POINT = 'partner/CREATE_PARTNER_UNIQUE_SELLING_POINT'
export const CREATE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS = 'partner/CREATE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS'
export const CREATE_PARTNER_UNIQUE_SELLING_POINT_FAIL = 'partner/CREATE_PARTNER_UNIQUE_SELLING_POINT_FAIL'

export const UPDATE_PARTNER_UNIQUE_SELLING_POINT = 'partner/UPDATE_PARTNER_UNIQUE_SELLING_POINT'
export const UPDATE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS = 'partner/UPDATE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS'
export const UPDATE_PARTNER_UNIQUE_SELLING_POINT_FAIL = 'partner/UPDATE_PARTNER_UNIQUE_SELLING_POINT_FAIL'

export const DELETE_PARTNER_UNIQUE_SELLING_POINT = 'partner/DELETE_PARTNER_UNIQUE_SELLING_POINT'
export const DELETE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS = 'partner/DELETE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS'
export const DELETE_PARTNER_UNIQUE_SELLING_POINT_FAIL = 'partner/DELETE_PARTNER_UNIQUE_SELLING_POINT_FAIL'

export const TOGGLE_HIDE_FROM_WEBSITE = 'partner/TOGGLE_HIDE_FROM_WEBSITE'
export const TOGGLE_HIDE_FROM_WEBSITE_SUCCESS = 'partner/TOGGLE_HIDE_FROM_WEBSITE_SUCCESS'
export const TOGGLE_HIDE_FROM_WEBSITE_FAIL = 'partner/TOGGLE_HIDE_FROM_WEBSITE_FAIL'

export const TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS = 'partner/TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS'
export const TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS_SUCCESS = 'partner/TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS_SUCCESS'
export const TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS_FAIL = 'partner/TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS_FAIL'

interface GetPartnerModel {
    partnerId?: number | number[]
    employeeId?: number
    state?: string | string[]
    packageType?: string
    satisfaction?: number
    canvasStatus?: string
    column?: string
    direction?: string
    visibleColumns?: string
    sidebarName?: string
    activeRowId?: number
    query?: string
    geoRect?: string
    geoPoint?: string
    geoDistance?: string
    locationId?: string
    segmentedForLeadId?: number
    geoCode?: string
    industryId?: string
    sort?: string
    limit?: number
    wantsExclusiveLeads?: '1' | '0'
    hasActiveContract?: boolean
    hasUnusedAllocations?: boolean
}

export const getPartnerAggregations = (
    criteria: GetPartnerModel
) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_AGGREGATIONS, GET_PARTNER_AGGREGATIONS_SUCCESS, GET_PARTNER_AGGREGATIONS_FAIL ],
        endpoint: `${config.apiUrl}/partners/aggregations`,
        options: {
            body: {
                geoCode: config.geoCode,
                limit: 250,
                ...criteria,
            },
        },
    },
})

export const getPartners = (
    criteria: GetPartnerModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [ GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_FAIL ],
        endpoint: '/partners',
        schema: schemas.partners,
        options: {
            body: {
                limit: 100,
                ...criteria,
            },
        },
    },
    append,
})

export const getPartner = (
    partnerId: number | string
) => ({
    [CALL_API]: {
        types: [ GET_PARTNER, GET_PARTNER_SUCCESS, GET_PARTNER_FAIL ],
        endpoint: `/partners/${partnerId}`,
        schema: schemas.partner,
    },
})

interface PartnersModel {
    id?: number
    employee?: {
        id: number
    }
    demo?: {
        hasLeadAccess: boolean
        leadExtensionPeriod: number
    }
    preferredPaymentSolution?: {
        id: number
    }
    preferences?: {
        leadDistance: number
        digitalLeads?: boolean
    }
    invoicing?: {
        daysGrace: number
    }
    companyName?: string
    isPayToQuote?: boolean
    isPayToMatch?: boolean
    paymentSchedule?: string
    action?: string
    nextActionAt?: string
    note?: string
}
export const savePartner = (
    body: PartnersModel
) =>({
    [CALL_API]: {
        types: [ SAVE_PARTNER, SAVE_PARTNER_SUCCESS, SAVE_PARTNER_FAIL ],
        endpoint: `/partners/${body.id}`,
        schema: schemas.partner,
        options: {
            method: 'put',
            body,
        },
    },
})

export const createPartnerAction = (
    partnerId: number,
    body: PartnersModel,
    type?: string[]
) => dispatch => dispatch({
    [CALL_API]: {
        types: type || [ CREATE_PARTNER_ACTION, CREATE_PARTNER_ACTION_SUCCESS, CREATE_PARTNER_ACTION_FAIL ],
        endpoint: `/partners/${partnerId}/actions`,
        options: {
            method: 'post',
            body,
        },
    },
}).then(() => dispatch(getPartner(partnerId)))

export const createCallPartnerAction = (
    partnerId: number,
    partnerPhone: string
) => createPartnerAction(partnerId, {
    action: 'employee-calls-partner',
    note: partnerPhone,
}, [ CREATE_PARTNER_CALL_ACTION, CREATE_PARTNER_CALL_ACTION_SUCCESS, CREATE_PARTNER_CALL_ACTION_FAIL ])

export const getPartnerFees = (
    partnerId: number
)=> ({
    [CALL_API]: {
        types: [ GET_PARTNER_FEES, GET_PARTNER_FEES_SUCCESS, GET_PARTNER_FEES_FAIL ],
        endpoint: '/partners/fees',
        schema: schemas.partnersFees,
        options: {
            body: {
                partnerId,
            },
        },
    },
})

interface PartnerFeeModel {
    revenueSharePercentage: number
    revenueShareSubsequentYearsPercentage: number
    effectiveAt: string
}
export const createPartnerFee = (
    partnerId: number,
    fee: PartnerFeeModel
) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_FEE, CREATE_PARTNER_FEE_SUCCESS, CREATE_PARTNER_FEE_FAIL ],
        endpoint: `/partners/${partnerId}/fees`,
        schema: schemas.partnerFees,
        options: {
            method: 'post',
            body: {
                ...fee,
                partnerId,
            },
        },
    },
})

export const partnerActionForgotPartner = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: [
            PARTNER_ACTION_FORGOT_PARTNER,
            PARTNER_ACTION_FORGOT_PARTNER_SUCCESS,
            PARTNER_ACTION_FORGOT_PARTNER_FAIL,
        ],
        endpoint: `/partners/${partnerId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'invoke_right_to_be_forgotten',
            },
        },
    },
})

interface PartnerRevenueModel {
    minimumRevenue: number
    maximumRevenue: number
    revenueCurrency: string
}
export const createPartnerRevenueRange = (
    partnerId: number,
    body: PartnerRevenueModel
) => ({
    [CALL_API]: {
        types: [ PARTNER_CREATE_REVENUE, PARTNER_CREATE_REVENUE_SUCCESS, PARTNER_CREATE_REVENUE_FAIL ],
        endpoint: `/partners/${partnerId}/revenueranges`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body,
        },
    },
})

interface SubscriptionAgreementsModel {
    createdByEmployeeId: number
    subscriptionTypeId: number
    subjectToTermsId: number
    monthlyPrice: Amount
    agreementTerms: string
    isAvailableForAgreement?: boolean
}
export const createPartnerSubscriptionAgreements = (
    partnerId: number,
    body: SubscriptionAgreementsModel
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS,
            CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS,
            CREATE_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/subscriptionagreements`,
        schema: schemas.subscriptionAgreement,
        options: {
            method: 'post',
            body,
        },
    },
})

interface SubscriptionAgreementsGetModel {
    isAvailableForAgreement?: boolean
}
export const getPartnerSubscriptionAgreements = (
    partnerId: number,
    body: SubscriptionAgreementsGetModel,
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_SUBSCRIPTION_AGREEMENTS,
            GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS,
            GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/subscriptionagreements`,
        schema: schemas.subscriptionAgreements,
        options: {
            body,
        },
    },
})

export const getPartnerSellingPoints = (
    partnerId: number
)=> ({
    [CALL_API]: {
        types: [ GET_PARTNER_UNIQUE_SELLING_POINTS, GET_PARTNER_UNIQUE_SELLING_POINTS_SUCCESS, GET_PARTNER_UNIQUE_SELLING_POINTS_FAIL ],
        endpoint: `/partners/${partnerId}/sellingpoints`,
        schema: schemas.partnerUniqueSellingPoints,
    },
})

interface PartnerUniqueSellingPointModel {
    text: string
}
export const createPartnerUniqueSellingPoint = (
    partnerId: number,
    point: PartnerUniqueSellingPointModel
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_UNIQUE_SELLING_POINT,
            CREATE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS,
            CREATE_PARTNER_UNIQUE_SELLING_POINT_FAIL
        ],
        endpoint: `/partners/${partnerId}/sellingpoints`,
        options: {
            method: 'post',
            body: {
                ...point,
                partnerId,
            },
        },
    },
})

export const updatePartnerUniqueSellingPoint = (
    partnerId: number,
    pointId: number,
    point: PartnerUniqueSellingPointModel
) => ({
    [CALL_API]: {
        types: [
            UPDATE_PARTNER_UNIQUE_SELLING_POINT,
            UPDATE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS,
            UPDATE_PARTNER_UNIQUE_SELLING_POINT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/sellingpoints/${pointId}`,
        options: {
            method: 'put',
            body: {
                ...point,
                partnerId,
            },
        },
    },
})

export const deletePartnerUniqueSellingPoint = (
    partnerId: number,
    pointId: number
) => ({
    [CALL_API]: {
        types: [
            DELETE_PARTNER_UNIQUE_SELLING_POINT,
            DELETE_PARTNER_UNIQUE_SELLING_POINT_SUCCESS,
            DELETE_PARTNER_UNIQUE_SELLING_POINT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/sellingpoints/${pointId}`,
        options: {
            method: 'delete',
        },
    },
    pointId,
})

export const toggleHideFromWebsite = (
    partnerId: number,
    hideFromWebsite: boolean,
) => ({
    [CALL_API]: {
        types: [
            TOGGLE_HIDE_FROM_WEBSITE,
            TOGGLE_HIDE_FROM_WEBSITE_SUCCESS,
            TOGGLE_HIDE_FROM_WEBSITE_FAIL,
        ],
        endpoint: `/partners/${partnerId}`,
        schema: schemas.partner,
        options: {
            method: 'put',
            body: {
                hideFromWebsite,
            },
        },
    },
})

export const toggleWantsOverdueInvoiceReminders = (
    partnerId: number,
    wantsOverdueInvoiceReminders: boolean,
) => ({
    [CALL_API]: {
        types: [
            TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS,
            TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS_SUCCESS,
            TOGGLE_WANTS_OVERDUE_INVOICE_REMINDERS_FAIL,
        ],
        endpoint: `/partners/${partnerId}`,
        schema: schemas.partner,
        options: {
            method: 'put',
            body: {
                wantsOverdueInvoiceReminders,
            },
        },
    },
})
