import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_PARTNER_TITLES = actionTypesCreator('partnerTitles', 'GET_PARTNER_TITLES')

export const getPartnerTitles = (
    body
) => ({
    [CALL_API]: {
        types: GET_PARTNER_TITLES,
        endpoint: '/partners/titles',
        schema: schemas.partnerTitles,
        options: {
            body,
        },
    },
})
