import styled from 'styled-components'
import { messageIconColor } from '../../../utils/variables'

const Icon = styled.i`
  font-size: 26px;
  margin-right: 10px;
  color: ${messageIconColor};
`

export default Icon
