import React, { memo, Fragment } from 'react'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { Employee } from '../../../reducers/types/entities/Employee'

const TimeText = styled.span`
  font-size: 13px;
`
const DropDown = styled(NavDropdown)`
  > a {
    line-height: 0!important;
  }
`

const timeHeader = s => {
    const minutes = Math.floor(s / 60)
    const seconds = s - minutes * 60
    return <Fragment>
        <i className="mdi mdi-phone mdi-18px"/>
        <TimeText> 
            {' '}
            {minutes + 'm ' + seconds + 's'}
        </TimeText>
    </Fragment>
}

const PhoneDropdown = memo(({ callActivity }: {callActivity: Employee['currentCallActivity']}) =>
    <DropDown
        noCaret
        eventKey={6}
        title={timeHeader(callActivity.secondsOnPhone)}
        id="basic-nav-dropdown-Phone"
    >
        <MenuItem header>Your calls today</MenuItem>
        <MenuItem>
            Incoming:
            {callActivity.incomingCallCount}
        </MenuItem>
        <MenuItem>
            Outgoing:
            {callActivity.outgoingCallCount}
        </MenuItem>
    </DropDown>
)


export default PhoneDropdown
