import styled from 'styled-components'
import PinnedNotesTop from './_Top'
import PinnedNotesBottom from './_Bottom'

const PinnedNotes: any = styled.div`
    position: relative;
    padding-top: 34px;
    padding-bottom: 34px;
`

PinnedNotes.Top = PinnedNotesTop
PinnedNotes.Bottom = PinnedNotesBottom

export default PinnedNotes
