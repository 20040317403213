import { GET_COMPANY_SIZES_SUCCESS } from '../../state/companysizes/actions'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface CompanySizesModel extends InitialState {
    companySizeIds: number[];
}
const initialState: CompanySizesModel = {
    isLoading: null,
    companySizeIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_COMPANY_SIZES_SUCCESS:
            return {
                ...state,
                companySizeIds: action.append ? state.companySizeIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        default:
            return state
    }
}
