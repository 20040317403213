import React, { ComponentType, memo, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import moment from 'moment'
import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import { KPIValues } from '../../../reducers/types/entities/KPIValue'
import { KPITargets } from '../../../reducers/types/entities/KPITarget'
import { connect } from '../../../utils/connect'
import { Cell, Column, Header, Rows, Table } from '../../elements/table'

const Progress = styled.div`
  span {
    float: left;
    margin-right: 10px;
    line-height: 10px;
  }
  .progress {
    height: 10px;
    margin: 0;
  }
`

const KPI = [
    { kpiIdentifier: 'validation_efficiency', name: 'Validation efficiency' },
    { kpiIdentifier: 'completion_ratio', name: 'Completion ratio' },
    { kpiIdentifier: 'match_ratio', name: 'Match ratio' },
]

interface PerformanceRowModel {
    kpiValues: KPIValues
    kpiTargets: KPITargets
    employeeId: number
}

interface RowsHocModel {
    rowData: typeof KPI[0]
}
@connect(
    state => ({
        kpiValues: state.entities.kpiValues,
        kpiTargets: state.entities.kpiTargets,
        employeeId: state.auth.employeeId,
    })
)
class PerformanceRow extends PureComponent<PerformanceRowModel & RowsHocModel> {
    date = moment().format('YYYY-M')

    progressColor(kpiValue, targetValue) {
        return kpiValue >= targetValue
            ? 'success'
            : kpiValue >= (targetValue - 5)
                ? 'warning'
                : 'danger'
    }

    render() {
        const { rowData, kpiTargets, kpiValues, employeeId } = this.props

        const kpiId = `${employeeId}-${rowData.kpiIdentifier}-${this.date}`

        return <tr key={kpiId}>
            <Cell>{rowData.name}</Cell>
            <Cell>
                {kpiValues[kpiId]
                    ? <Progress>
                        <span>{kpiValues[kpiId].value + ' %'}</span>
                        <ProgressBar
                            now={kpiValues[kpiId].value}
                            bsStyle={this.progressColor(kpiValues[kpiId].value, (kpiTargets[kpiId] || { value: 0 }).value)}
                        />
                    </Progress>
                    : '-'
                }
            </Cell>
            <Cell>{kpiTargets[kpiId] ? kpiTargets[kpiId].value + ' %' : '-'}</Cell>
        </tr>
    }
}

const PerformanceTableRow = PerformanceRow as ComponentType

const MyPerformanceTableCard = memo(()=>
    <Card>
        <Card.Header>My performance</Card.Header>
        <Table>
            <Header>
                <Column>KPI</Column>
                <Column>Actual</Column>
                <Column>Target</Column>
            </Header>
            <Rows items={KPI}>
                <PerformanceTableRow/>
            </Rows>
        </Table>
    </Card>
)

export default MyPerformanceTableCard
