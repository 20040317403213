import React, { PureComponent, Fragment, ComponentType } from 'react'
import Meetings from '../../activities/Meetings'
import { Card } from '../../blocks/Card'
import AddNoteForm from '../../activities/AddNoteForm'
import { SeparatorLine } from '../../elements/Separators'
import NewsFeed from '../../activities/NewsFeed'
import Tabs from '../../elements/Tabs'
import ContactFlow from '../../activities/ContactFlow'
import { getTotalActivitiesCall } from '../../../actions/newsfeed'
import { getTotalContactArchives } from '../../../actions/contact'
import { Flex } from '../../elements/Flex'
import styled from 'styled-components'
import { connect } from '../../../utils/connect'

const TABS = [
    { tabId: 'feed', tabName: 'Feed' },
    { tabId: 'lead_note_created', tabName: 'Notes' },
    { tabId: 'phonecalls_create,lead_call', tabName: 'Calls' },
    { tabId: 'email', tabName: 'Email' },
    { tabId: 'meetings', tabName: 'Meetings' },
]

const TotalActivities = styled.div`
    padding-top: 5px;
    p:last-child {
        margin: 0;
    }
`

interface StatePropsFeed {
    totalCallActivities: number
    totalContactArchives: number
}
interface DispatchPropsFeed {
    getTotalActivitiesCall: typeof getTotalActivitiesCall
    getTotalContactArchives: typeof getTotalContactArchives
}
interface PathPropsFeed {
    leadId: number
}

type LeadNewsFeedCardModel = StatePropsFeed & DispatchPropsFeed & PathPropsFeed

interface LeadNewsFeedCardState {
    selectedTab: string,
    subject: {
        leadId: number
    }
}

@connect<StatePropsFeed, DispatchPropsFeed, PathPropsFeed>(
    (state, props) => ({
        totalCallActivities: state.pages.newsfeed.totalLeadCallActivities[props.leadId],
        totalContactArchives: state.pages.newsfeed.totalContactArchives,
    }),
    {
        getTotalActivitiesCall,
        getTotalContactArchives,
    },
)
class LeadNewsFeedCard extends PureComponent<LeadNewsFeedCardModel, LeadNewsFeedCardState> {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 'feed',
            subject: {
                leadId: props.leadId,
            },
        }
    }

    componentDidMount() {
        this.props.getTotalActivitiesCall(this.state.subject)
        this.props.getTotalContactArchives({
            receiverLeadId: this.props.leadId,
        })
    }

    changeTab = tabIndex => {
        this.setState({ selectedTab: tabIndex })
    }

    render() {
        const {
            totalCallActivities,
            totalContactArchives,
            leadId,
        } = this.props

        const { selectedTab } = this.state

        return (
            <Card>
                <Card.Header padding="0 20px">
                    <Flex modifiers={[ 'justifySpaceBetween', 'fullWidth' ]}>
                        <Tabs
                            tabs={TABS}
                            selectedTab={selectedTab}
                            changeTab={this.changeTab}
                        />
                        <TotalActivities>
                            <Card.Text>
                                <i className="mdi mdi-phone"/>
                                {' '}
                                {totalCallActivities || 0}
                            </Card.Text>
                            <Card.Text>
                                <i className="mdi mdi-email-outline"/>
                                {' '}
                                {totalContactArchives}
                            </Card.Text>
                        </TotalActivities>
                    </Flex>
                </Card.Header>
                {selectedTab === 'email'
                    ? <Card.ScrollableContent modifiers={[ 'smMaxHeight', 'noSpace' ]}>
                        <ContactFlow
                            entityId={leadId}
                            stateName="leadDetail"
                        />
                    </Card.ScrollableContent>
                    : selectedTab === 'meetings' ?
                        <Card.ScrollableContent modifiers={[ 'smMaxHeight', 'noSpace' ]}>
                            <Meetings
                                leadId={leadId}
                            />
                        </Card.ScrollableContent> :
                        <Fragment>
                            {selectedTab !== 'phonecalls_create,lead_call' &&
                                <Card.Content>
                                    <AddNoteForm
                                        entityId={leadId}
                                        stateName="leadDetail"
                                        selectedTab={selectedTab}
                                    />
                                </Card.Content>}
                            <SeparatorLine modifiers="mY_0"/>
                            <NewsFeed
                                selectedTab={selectedTab}
                                subject={this.state.subject}
                                scrollHeight="700px"
                            />
                        </Fragment>
                }
            </Card>
        )
    }
}

export default LeadNewsFeedCard as unknown as ComponentType<PathPropsFeed>
