import {
    GET_LEADS,
    GET_LEADS_SUCCESS,
    GET_LEADS_FAIL,
    GET_DEMO_LEADS,
    GET_DEMO_LEADS_SUCCESS,
    GET_DEMO_LEADS_FAIL,
    POSTPONE_CLIENT_MATCH_DECISION_SUCCESS,
    ACCEPT_LEAD_QUOTE_SUCCESS, LEAD_ACTION_GIVEN_UP_SUCCESS,
} from '../../actions/leads'

import {
    REJECT_QUOTES_SUCCESS,
} from '../../actions/quotes'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface LeadsAction extends InitialAction{
    leadId: number
    append: boolean
    removeFromList: boolean
}

export interface LeadsState extends InitialState{
    leadIds: number[]
}
const leadInitialState: LeadsState = {
    isLoading: null,
    leadIds: [],
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}

export default function reducer(
    state = leadInitialState,
    action: LeadsAction
) {
    switch (action.type) {
        case GET_LEADS:
        case GET_DEMO_LEADS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_LEADS_SUCCESS:
        case GET_DEMO_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: action.append ? state.leadIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case REJECT_QUOTES_SUCCESS:
        case LEAD_ACTION_GIVEN_UP_SUCCESS:
        case POSTPONE_CLIENT_MATCH_DECISION_SUCCESS:
            return {
                ...state,
                leadIds: state.leadIds.filter(item => item !== action.leadId),
                isLoading: false,
            }
        case GET_LEADS_FAIL:
        case GET_DEMO_LEADS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case ACCEPT_LEAD_QUOTE_SUCCESS:
            return {
                ...state,
                leadIds: state.leadIds.filter(item => item !== action.leadId),
                pagination: action.response.pagination,
                isLoading: false,
            }
        default:
            return state
    }
}
