import React, { ComponentType, PureComponent } from 'react'
import { toastr } from 'react-redux-toastr'
import { connect } from '../../utils/connect'
import {
    unrejectQuotesAction,
    leadActionGivenUp,
    acceptLeadQuote, leadActionForgotLead,
    leadActionQueueFeedbackEmail, leadCopyToNewLead,
    leadActionMarkAsUntreated,
    createLeadDiscards
} from '../../actions/leads'

import { getQuotes, deleteQuote, closeReservationLeadQuote } from '../../actions/quotes'
import { hideModal } from '../../actions/modal'
import { unmatchLeadAction } from '../../actions/actions'

import LeadPostponeModal from './LeadPostponeModal'
import LeadMarkAsCompleteModal from './LeadMarkAsCompleteModal'
import QuotesRejectModal from './QuotesRejectModal'
import ConfirmModal from './ConfirmModal'
import LeadQuoteAcceptModal from './LeadQuoteAcceptModal'
import LeadDiscardModal from './LeadDiscardModal'
import LeadValidationConfirmModal from './LeadValidationConfirmModal'
import ExcludePartnersModal from './ExcludePartnersModal'
import SegmentedPartnersModal from './SegmentedPartnersModal'
import EditPartnerFeeModal from './EditPartnerFeeModal'
import RefundRequestAcceptModal from './RefundRequestAcceptModal'
import RefundRequestRejectModal from './RefundRequestRejectModal'
import { reset } from 'redux-form'
import { partnerActionForgotPartner } from '../../actions/partners'
import ReserveLeadQuoteForPartnersModal from './ReserveLeadQuoteForPartnersModal'
import BusinessUnitModal from './BusinessUnitModal'
import SubscriptionCreateModal from './SubscriptionCreateModal'
import SubscriptionUpdateModal from './SubscriptionUpdateModal'
import PartnerDiscountCreateModal from './PartnerDiscountCreateModal'
import { partnerSubscriptionAction } from '../../actions/subscriptions'
import CardText from '../elements/text/CardText'
import SubscriptionChangePriceModal from './SubscriptionChangePriceModal'
import PreviewModal from './PreviewModal'
import BusinessUnitActionModal from './BusinessUnitActionModal'
import moment from 'moment'
import { replace } from 'connected-react-router'
import EditPartnerUniqueSellingPointsModal from './EditPartnerUniqueSellingPointsModal'
import { deleteBusinessUnitAllocationQuota } from '../../state/allocationsQuotas/actions'
import BusinessUnitsDeliveriesModal from './BusinessUnitsDeliveriesModal'
import { createPausePartnerSubscription } from '../../state/pauseSubscriptions/actions'
import { deletePartnerUser } from '../../state/partnerUsers/actions'
import ContractOfferGroupCreationModal from './ContractOfferGroupCreationModal'
import CreateDemoPartnerModal from './CreateDemoPartnerModal'
import {
    acceptContractForPartner,
    cancelPartnerContractNormally,
    cancelPartnerContractImmediately,
    createPartnerContractPause,
    getPartnerContract,
} from '../../actions/partnerContracts'
import ShowBusinessUnitsForLeadModal from './ShowBusinessUnitsForLeadModal'
import ContractPauseModal from './ContractPauseModal'
import ContractAllocationBoostModal from './ContractAllocationBoostModal'
import PostponeValidationModal from './PostponeValidationModal'
import TerminatePartnerContractModal from './TerminatePartnerContractModal'

interface ModalsModel {
    modalProps: any
    deleteBusinessUnitAllocationQuota: typeof deleteBusinessUnitAllocationQuota
    partnerActionForgotPartner: typeof partnerActionForgotPartner
    partnerSubscriptionAction: typeof partnerSubscriptionAction
    createPausePartnerSubscription: typeof createPausePartnerSubscription
    leadActionQueueFeedbackEmail: typeof leadActionQueueFeedbackEmail
    leadActionForgotLead: typeof leadActionForgotLead
    unrejectQuotesAction: typeof unrejectQuotesAction
    unmatchLeadAction: typeof unmatchLeadAction
    leadActionGivenUp: typeof leadActionGivenUp
    deletePartnerUser: typeof deletePartnerUser
    acceptLeadQuote: typeof acceptLeadQuote
    deleteQuote: typeof deleteQuote
    getQuotes: typeof getQuotes
    closeReservationLeadQuote: typeof closeReservationLeadQuote
    hideModal: typeof hideModal
    replace: typeof replace
    reset: typeof reset
    acceptContractForPartner: typeof acceptContractForPartner
    cancelPartnerContractNormally: typeof cancelPartnerContractNormally
    cancelPartnerContractImmediately: typeof cancelPartnerContractImmediately
    pausePartnerContract: typeof createPartnerContractPause
    getPartnerContract: typeof getPartnerContract
    leadCopyToNewLead: typeof leadCopyToNewLead
    leadActionMarkAsUntreated: typeof leadActionMarkAsUntreated
    createLeadDiscards: typeof createLeadDiscards
}

interface ModalsState {
    submitting: boolean
}

@connect(
    state => ({
        modalProps: state.modal,
    }),
    {
        deleteBusinessUnitAllocationQuota,
        partnerActionForgotPartner,
        partnerSubscriptionAction,
        createPausePartnerSubscription,
        leadActionQueueFeedbackEmail,
        leadActionForgotLead,
        unrejectQuotesAction,
        deletePartnerUser,
        unmatchLeadAction,
        leadActionGivenUp,
        acceptLeadQuote,
        deleteQuote,
        getQuotes,
        closeReservationLeadQuote,
        hideModal,
        replace,
        reset,
        acceptContractForPartner,
        cancelPartnerContractNormally,
        cancelPartnerContractImmediately,
        pausePartnerContract: createPartnerContractPause,
        getPartnerContract,
        leadCopyToNewLead,
        leadActionMarkAsUntreated,
        createLeadDiscards,
    }
)
class ModalsModule extends PureComponent<ModalsModel, ModalsState> {
    componentDidMount(): void {
        this.setState({
            submitting: false,
        })
    }

    confirmProps = (confirmName) => {
        switch (confirmName) {
            case 'leadUnmatchConfirm':
                return {
                    headerText: 'Unmatch lead',
                    bodyText: 'Are you sure, that you want unmatch lead ?',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'danger' ],
                }
            case 'leadActionQueueFeedbackEmail':
                return {
                    headerText: 'Queue TrustPilot feedback email to lead',
                    bodyText: 'Are you sure that you want to send a TrustPilot feedback email to this lead?',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'action' ],
                }
            case 'unrejectQuotesConfirm':
                return {
                    headerText: 'Unreject all quotes',
                    bodyText: 'Are you sure, that you want unreject all quotes ?',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'action' ],
                }
            case 'leadGivenUpConfirm':
                return {
                    headerText: 'Given Up lead',
                    bodyText: 'Are you sure, that you want mark lead "Given up" ?',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'action' ],
                }
            case 'deleteQuoteConfirm':
                return {
                    headerText: 'Delete quote',
                    bodyText: 'Are you sure, that you want delete quote ?',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'danger' ],
                }
            case 'deleteTargetBusinessUnitConfirm':
                return {
                    headerText: 'Delete target',
                    bodyText: 'Do you want to delete this target?',
                    btnApproveText: 'Yes',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'danger' ],
                }

            case 'subscriptionCancelConfirm':
                return {
                    headerText: 'Subscription cancellation',
                    bodyText: <div>
                        <CardText>
                            You are about to
                            <b>cancel</b>
                            {' '}
                            a subscription.
                        </CardText>
                        <CardText>
                            Partner will lose access on:&nbsp;
                            <b>
                                {this.props.modalProps.cancelSubscriptionDate ||
                                    (this.props.modalProps.subscription.packageType === 'Ageras Marketing Package'
                                        ? moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
                                        : moment(this.props.modalProps.subscription.expiresAt).add(1, 'month').format('YYYY-MM-DD')
                                    )
                                }
                            </b>
                        </CardText>
                    </div>,
                    btnApproveText: 'Cancel subscription',
                    btnDenyText: 'No',
                    btnApproveModifiers: [ 'action' ],
                }
            default:
                return {
                    headerText: '',
                    bodyText: '',
                    btnApproveText: '',
                    btnDenyText: '',
                    btnApproveModifiers: '',
                }
        }}

    hideModal = () => this.props.hideModal()

    acceptQuote = async quote => {
        const leadId = this.props.modalProps.leadId

        await this.props.acceptLeadQuote(leadId, quote.id)

        await this.props.getQuotes({
            criteria: {
                leadId,
                isPublished: true,
            },
        })
    }

    leadActionGivenUp = () => {
        this.props.leadActionGivenUp(this.props.modalProps.leadId)
    }

    unrejectAllQuotesAction = () => {
        this.props.unrejectQuotesAction(this.props.modalProps.leadId)
    }

    unmatchLeadAction = () => {
        this.props.unmatchLeadAction(this.props.modalProps.leadId)
    }

    deleteQuote = async () => {
        const leadId = this.props.modalProps.leadId

        const quoteId = this.props.modalProps.quoteId

        await this.props.deleteQuote(leadId, quoteId)

        toastr.success('Quotes were deleted')
    }

    deleteGDPRData = async () => {
        if (this.props.modalProps.actionName === 'partner') {
            await this.props.partnerActionForgotPartner(this.props.modalProps.partnerId)
            this.props.reset('gdprPartnerId')
        } else {
            await this.props.leadActionForgotLead(this.props.modalProps.leadId)
            this.props.reset('gdprLeadId')
        }
    }

    subscriptionCancelWithDate = () => {
        this.props.partnerSubscriptionAction(
            this.props.modalProps.partnerId,
            this.props.modalProps.subscription.id,
            {
                action: 'cancel',
                cancelAt: this.props.modalProps.cancelSubscriptionDate,
            }
        )
    }

    subscriptionCancelWithCancellationPeriod = () => {
        this.props.partnerSubscriptionAction(
            this.props.modalProps.partnerId,
            this.props.modalProps.subscription.id,
            {
                action: 'cancel_with_cancellation_period',
            }
        )
    }

    deleteTargetBusinessUnitConfirm = () => {
        this.props.deleteBusinessUnitAllocationQuota(
            this.props.modalProps.partnerId,
            this.props.modalProps.businessUnitId,
            this.props.modalProps.quotaId
        )
    }

    deletePartnerUser = async () => {
        const { partnerId, partnerUserId } = this.props.modalProps
        await this.props.deletePartnerUser(partnerId, partnerUserId)
        this.props.replace('/partners/' + partnerId)
    }

    onSelectSubscriptionPauseDate = async () => {
        const { startsAt, endsAt } = this.props.modalProps.body
        await this.props.createPausePartnerSubscription(
            this.props.modalProps.partnerId,
            this.props.modalProps.subscriptionId,
            this.props.modalProps.body
        )
        toastr.success('Success', `Pause between ${startsAt} and ${endsAt} was create`)
    }

    queueLeadFeedbackEmailAction = async () => {
        await this.props.leadActionQueueFeedbackEmail(this.props.modalProps.leadId)
        this.hideModal()
    }

    closeReservationQuote = async () => {
        const leadId = this.props.modalProps.leadId
        await this.props.closeReservationLeadQuote(leadId, this.props.modalProps.quoteId)
        this.hideModal()

        this.props.getQuotes({
            criteria: {
                leadId,
                isPublished: false,
            },
        })
    }

    acceptContractForPartner = async () => {
        this.setState({ submitting: true })

        const partnerId = this.props.modalProps.partnerId
        const partnerContractId = this.props.modalProps.partnerContractId

        await this.props.acceptContractForPartner(
            partnerId,
            partnerContractId
        )

        toastr.success('Contract has been accepted')

        this.setState({ submitting: false })
        this.props.hideModal()

        this.props.replace(`/partners/${partnerId}/contracts/${partnerContractId}`)
    }

    cancelPartnerContract = async () => {
        this.setState({ submitting: true })

        const partnerId = this.props.modalProps.partnerId
        const partnerContractId = this.props.modalProps.partnerContractId

        await this.props.cancelPartnerContractNormally(
            partnerId,
            partnerContractId
        )

        toastr.success('Contract has been cancelled')

        this.setState({ submitting: false })
        this.props.hideModal()

        this.props.replace(`/partners/${partnerId}`)
    }

    terminatePartnerContract = async (deleteUnusedAllocations: boolean) => {
        this.setState({ submitting: true })

        const partnerId = this.props.modalProps.partnerId
        const partnerContractId = this.props.modalProps.partnerContractId

        await this.props.cancelPartnerContractImmediately(
            partnerId,
            partnerContractId,
            deleteUnusedAllocations
        )

        toastr.success('Contract has been terminated')

        this.setState({ submitting: false })
        this.props.hideModal()

        this.props.replace(`/partners/${partnerId}`)
    }

    copyLeadToNewLead = async () => {
        this.setState({ submitting: true })

        try {
            const res: any = await this.props.leadCopyToNewLead(this.props.modalProps.leadId)

            toastr.success('Lead has been recreated')
            this.props.hideModal()

            this.props.replace(`/leads/${res.result}/validation`)
        } catch (e) {
            toastr.error('There was a problem recreating lead', e)
        }

        this.setState({ submitting: false })
    }

    markAsUntreated = async () => {
        this.setState({ submitting: true })
        const { leadId } = this.props.modalProps
        try {
            await this.props.leadActionMarkAsUntreated(leadId)
            toastr.success('Lead has been marked as untreated')
            this.props.hideModal()
            window.location.reload()
        } catch (e) {
            toastr.error('There was a problem to change status of lead', e)
        }

        this.setState({ submitting: false })
    }

    bulkStatusLeadAction = async () => {
        this.setState({ submitting: true })
        const { leadIds, statusName, statusId } = this.props.modalProps

        try {
            await this.props.createLeadDiscards(leadIds, statusId)
            toastr.success(`Leads has been marked as ${statusName}`)
            this.props.hideModal()
            window.location.reload()
        } catch (e) {
            toastr.error('There was a problem to change status of leads', e)
        }

        this.setState({ submitting: false })
    }

    render() {
        const {
            modalProps,
        } = this.props

        switch (modalProps.name) {
            case 'postponeModal':
                return <LeadPostponeModal />
            case 'rejectQuotesModal':
                return <QuotesRejectModal />
            case 'acceptQuoteModal':
                return <LeadQuoteAcceptModal />
            case 'leadMarkAsCompleteModal':
                return <LeadMarkAsCompleteModal />
            case 'leadDiscardModal':
                return <LeadDiscardModal />
            case 'segmentedPartnersModal':
                return <SegmentedPartnersModal />
            case 'leadValidateConfirm':
                return <LeadValidationConfirmModal />
            case 'excludePartners':
                return <ExcludePartnersModal />
            case 'editPartnerFeeModel':
                return <EditPartnerFeeModal />
            case 'editPartnerUniqueSellingPointsModel':
                return <EditPartnerUniqueSellingPointsModal />
            case 'acceptRefundRequestModel':
                return <RefundRequestAcceptModal />
            case 'rejectRefundRequestModel':
                return <RefundRequestRejectModal />
            case 'reserveLeadQuoteForPartners':
                return <ReserveLeadQuoteForPartnersModal />
            case 'businessUnitModal':
                return <BusinessUnitModal/>
            case 'contractOfferGroupCreationModal':
                return <ContractOfferGroupCreationModal/>
            case 'subscriptionCreateModal':
                return <SubscriptionCreateModal/>
            case 'subscriptionUpdateModal':
                return <SubscriptionUpdateModal/>
            case 'partnerDiscountCreateModal':
                return <PartnerDiscountCreateModal/>
            case 'subscriptionChangePriceModal':
                return <SubscriptionChangePriceModal/>
            case 'businessUnitActionModal':
                return <BusinessUnitActionModal/>
            case 'businessUnitsDeliveriesModal':
                return <BusinessUnitsDeliveriesModal/>
            case 'createDemoPartnerModal':
                return <CreateDemoPartnerModal />
            case 'showBusinessUnitsForLead':
                return <ShowBusinessUnitsForLeadModal />
            case 'cancelPartnerContractImmediately':
                return <TerminatePartnerContractModal
                    onHide={this.hideModal}
                    modalProps={this.props.modalProps}
                    terminatePartnerContract={this.terminatePartnerContract}
                />
            case 'previewModal':
                return <PreviewModal
                    onHide={this.hideModal}
                    modalProps={this.props.modalProps}
                />
            case 'leadGivenUpConfirm':
                return <ConfirmModal
                    submit={this.leadActionGivenUp}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('leadGivenUpConfirm')}
                />
            case 'unrejectQuotesModal':
                return <ConfirmModal
                    submit={this.unrejectAllQuotesAction}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('unrejectQuotesConfirm')}
                />
            case 'leadUnmatchConfirm':
                return <ConfirmModal
                    submit={this.unmatchLeadAction}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('leadUnmatchConfirm')}
                />
            case 'leadActionQueueFeedbackEmail':
                return <ConfirmModal
                    submit={this.queueLeadFeedbackEmailAction}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('leadActionQueueFeedbackEmail')}
                />
            case 'deleteQuoteModal':
                return <ConfirmModal
                    submit={this.deleteQuote}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('deleteQuoteConfirm')}
                />
            case 'GDPRconfirm':
                return <ConfirmModal
                    submit={this.deleteGDPRData}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={modalProps}
                />
            case 'overdeliveryConfirm':
                return <ConfirmModal
                    submit={modalProps.onConfirm}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={modalProps}
                />
            case 'subscriptionCancelWithDateConfirm':
                return <ConfirmModal
                    submit={this.subscriptionCancelWithDate}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('subscriptionCancelConfirm')}
                />
            case 'subscriptionCancelWithCancellationPeriodConfirm':
                return <ConfirmModal
                    submit={this.subscriptionCancelWithCancellationPeriod}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('subscriptionCancelConfirm')}
                />
            case 'deleteTargetBusinessUnitConfirm':
                return <ConfirmModal
                    submit={this.deleteTargetBusinessUnitConfirm}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={this.confirmProps('deleteTargetBusinessUnitConfirm')}
                />
            case 'deletePartnerUserConfirm':
                return <ConfirmModal
                    submit={this.deletePartnerUser}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={modalProps.confirmData}
                />
            case 'subscriptionPauseConfirm':
                return <ConfirmModal
                    submit={this.onSelectSubscriptionPauseDate}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={modalProps}
                />
            case 'closeReservationQuoteModal':
                return <ConfirmModal
                    submit={this.closeReservationQuote}
                    onHide={this.hideModal}
                    disabled={modalProps.disabled}
                    modalProps={modalProps}
                />
            case 'acceptContractForPartner':
                return <ConfirmModal
                    submit={this.acceptContractForPartner}
                    onHide={this.hideModal}
                    disabled={this.state.submitting}
                    modalProps={modalProps}
                />
            case 'cancelPartnerContractNormally':
                return <ConfirmModal
                    submit={this.cancelPartnerContract}
                    onHide={this.hideModal}
                    disabled={this.state.submitting}
                    modalProps={modalProps}
                />
            case 'pausePartnerContract':
                return <ContractPauseModal />
            case 'boostPartnerContract':
                return <ContractAllocationBoostModal />
            case 'copyToNewLead':
                return <ConfirmModal
                    submit={this.copyLeadToNewLead}
                    onHide={this.hideModal}
                    disabled={this.state.submitting}
                    modalProps={{
                        ...modalProps,
                        headerText: 'Copy to new lead',
                        bodyText: `This action will take current lead #${modalProps.leadId} and will create a new matching one`,
                        btnDenyText: 'Cancel',
                        btnApproveText: 'Copy lead',
                        btnApproveModifiers: [ 'action' ],
                    }}
                />
            case 'markLeadAsUntreated':
                return <ConfirmModal
                    disabled={this.state.submitting}
                    submit={this.markAsUntreated}
                    onHide={this.hideModal}
                    modalProps={{
                        ...modalProps,
                        headerText: 'Mark as untreated',
                        bodyText: `This action will take current lead #${modalProps.leadId} and will set status as untreated`,
                        btnDenyText: 'Cancel',
                        btnApproveText: 'Mark as untreated',
                        btnApproveModifiers: [ 'action' ],
                    }}
                />
            case 'postponeValidation':
                return <PostponeValidationModal />
            case 'leadMarketplaceDescription':
                return <ConfirmModal
                    submit={modalProps.onConfirm}
                    onHide={modalProps.onHide}
                    disabled={modalProps.disabled}
                    modalProps={modalProps}
                />
            case 'bulkStatusLeadAction':
                return <ConfirmModal
                    disabled={this.state.submitting}
                    submit={this.bulkStatusLeadAction}
                    onHide={this.hideModal}
                    modalProps={{
                        ...modalProps,
                        headerText: `Mark as ${modalProps.statusName} `,
                        bodyText: `This action will take selected leads(${modalProps.leadIds.join(',')}) and will set status - ${modalProps.statusName}`,
                        btnDenyText: 'Cancel',
                        btnApproveText: 'Confirm',
                        btnApproveModifiers: [ 'action' ],
                    }}
                />
            default:
                return null
        }
    }
}

export default ModalsModule as unknown as ComponentType
