import * as schemas from '../../schemas'
import { CALL_API } from '../../middleware/api'
import { actionTypesCreator } from '../../utils'

export const GET_SECTORS = actionTypesCreator('sectors', 'GET_SECTORS')

export const GET_PARTNERS_SECTOR = actionTypesCreator('sectors', 'GET_PARTNERS_SECTOR')

export const UPDATE_PARTNER_SECTOR = actionTypesCreator('sectors', 'UPDATE_PARTNER_SECTOR')

export const GET_BUSINESS_UNITS_SECTOR = actionTypesCreator('sectors', 'GET_BUSINESS_UNITS_SECTOR')

export const UPDATE_BUSINESS_UNIT_SECTOR = actionTypesCreator('sectors', 'UPDATE_BUSINESS_UNIT_SECTOR')

export const getSectors = (
    criteria
) => ({
    [CALL_API]: {
        types: GET_SECTORS,
        endpoint: '/sectors',
        schema: schemas.sectors,
        options: {
            body: {
                limit: 1000,
                ...criteria,
            },
        },
    },
})

export const getPartnerSectors = (
    partnerId: number
) => ({
    [CALL_API]: {
        types: GET_PARTNERS_SECTOR,
        endpoint: `/partners/${partnerId}/sectors`,
        schema: schemas.sectors,
        options: {
            body: {
                limit: 1000,
            },
        },
    },
})

export const updatePartnerSectors = (
    partnerId: number,
    sectorIds: number[]
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_SECTOR,
        endpoint: `/partners/${partnerId}/sectors`,
        options: {
            method: 'put',
            body: {
                sectorIds,
            },
        },
    },
})

export const getPartnerBusinessUnitsSectors = (
    partnerId: number,
    businessUnitId: number
) => ({
    [CALL_API]: {
        types: GET_BUSINESS_UNITS_SECTOR,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/sectors`,
        schema: schemas.sectors,
        options: {
            body: {
                limit: 1000,
            },
        },
    },
})

export const updatePartnerBusinessUnitsSectors = (
    partnerId: number,
    businessUnitId: number,
    sectorIds: number[]
) => ({
    [CALL_API]: {
        types: UPDATE_BUSINESS_UNIT_SECTOR,
        endpoint: `/partners/${partnerId}/businessunits/${businessUnitId}/sectors`,
        options: {
            method: 'put',
            body: {
                sectorIds,
            },
        },
    },
})
