import React, { PureComponent } from 'react'

interface TimerModel {
    calculate: () => string | number
    color?: (timedCallback: string) => string
}
export default class Timer extends PureComponent<TimerModel> {
    timerID = null
    state = {
        timer: '',
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        )
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    tick() {
        this.setState({
            timer: this.props.calculate(),
        })
    }

    render() {
        const color = this.props.color && this.props.color(this.state.timer)
        return <span style={{ color }}>{color === '-' ? '-' : this.state.timer}</span>
    }
}
