import React, { memo } from 'react'
import { TableProviderContext } from './_Provider'

interface RowsModel {
    noDataText: string
    children
    items: Array<{
        id: number | string
    }>
}

const _Rows = memo(({
    noDataText,
    children,
    items,
}: RowsModel) =>
    <tbody>
        {items.length
            ? items.map(rowData =>
                <TableProviderContext.Consumer
                    key={'Consumer_' + rowData.id}
                >
                    {({ onRowClick, visibleColumns, activeRowId, columnNames }) =>
                        React.cloneElement(children, {
                            rowData,
                            onRowClick,
                            visibleColumns,
                            columnNames,
                            active: Number(activeRowId) === rowData.id,
                        })
                    }
                </TableProviderContext.Consumer>
            )
            : <tr>
                <td colSpan={100} className="text-center">{noDataText}</td>
            </tr>
        }
    </tbody>
)

export default _Rows

