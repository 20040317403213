import React, { ComponentType, PureComponent, ReactChild } from 'react'
import { getLanguages } from '../../actions/languages'
import { Field } from 'redux-form'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { TypeaheadGroup } from '../elements/forms/inputs/TypeaheadGroup'
import { connect } from '../../utils/connect'

const required = [ value => value && value[0] ? undefined : 'Required!' ]

const PROFICIENCY = [
    { value: 'noProficiency', title: 'No proficiency' },
    { value: 'elementaryProficiency', title: 'Elementary proficiency' },
    { value: 'limitedWorkingProficiency', title: 'Limited working proficiency' },
    { value: 'professionalProficiency', title: 'Professional proficiency' },
    { value: 'fullProficiency', title: 'Full proficiency' },
    { value: 'nativeProficiency', title: 'Native proficiency' },
]

interface PathPropsLanguageForm {
    extraOption?: ReactChild
    handleSubmit: (data: any) => Promise<void>
}

interface LanguageFormModel extends PathPropsLanguageForm {
    languages: []
    getLanguages: typeof getLanguages
}

@connect(
    state => ({
        languages: Object.values(state.entities.languages),
    }),
    {
        getLanguages,
    }
)
class LanguageForm extends PureComponent<LanguageFormModel> {

    componentDidMount() {
        this.props.getLanguages()
    }

    render() {
        const {
            handleSubmit,
            extraOption,
            languages,
        } = this.props

        return (
            <ReduxForm form="languageCv" onSubmit={handleSubmit}>
                <Row>
                    {extraOption}
                    <Col md={extraOption ? 4 : 6}>
                        <Field
                            placeholder="Select language"
                            name="identifier"
                            labelKey="name"
                            component={TypeaheadGroup}
                            options={languages}
                            validate={required}
                            clearButton={true}
                        />
                    </Col>
                    <Col md={extraOption ? 4 : 6}>
                        <Field
                            placeholder="Proficiency"
                            name="proficiency"
                            labelKey="title"
                            component={TypeaheadGroup}
                            options={PROFICIENCY}
                            validate={required}
                            clearButton={true}
                        />
                    </Col>
                </Row>
                <Button modifiers={[ 'action', 'fullWidth' ]} type="submit">
                    Add language
                </Button>
            </ReduxForm>
        )
    }
}

export default LanguageForm as unknown as ComponentType<PathPropsLanguageForm>
