import React, { memo } from 'react'
import CheckboxGroup, { SingleCheckboxDataField } from './forms/inputs/CheckboxGroup'
import { SelectInputGroup } from './forms/inputs/SelectInputGroup'
import { FieldGroup } from './forms/inputs/FieldGroup'
import { Field, WrappedFieldArrayProps } from 'redux-form'
import { Col, Row } from 'react-bootstrap'
import { DataField } from '../../reducers/types/entities/DataField'

const switchFields = (dataField, member, onChangeCallback) => {
    switch (dataField.type) {
        case 'checkbox':
            return (
                dataField.isMultiple ?
                    <Field
                        label={dataField.label}
                        name={`${member}.selected`}
                        component={CheckboxGroup}
                        options={dataField.values}
                        onChange={onChangeCallback}
                    /> :
                    <Field
                        text={dataField.label}
                        name={`${member}.selected`}
                        component={SingleCheckboxDataField}
                        modifiers={[ 'mY_2', 'pY_1' ]}
                        options={dataField.values}
                        onChange={onChangeCallback}
                    />
            )
        case 'number':
            return (
                <Field
                    label={dataField.label}
                    name={`${member}.selected[0].title`}
                    component={FieldGroup}
                    type="number"
                    onBlur={onChangeCallback}
                />
            )
        case 'dropdown':
            return (
                <Field
                    label={dataField.label}
                    name={`${member}.selected[0]`}
                    component={SelectInputGroup}
                    options={dataField.values}
                    onChange={onChangeCallback}
                />
            )
        case 'text':
        case 'textbox':
            return (
                <Field
                    label={dataField.label}
                    name={`${member}.selected[0].title`}
                    component={FieldGroup}
                    onBlur={onChangeCallback}
                />
            )
        default:
            return <div>
                No data field !!
                <br />
                id:
                {dataField.id}
                <br />
                {' '}
                label: "
                {dataField.label}
                "
            </div>
    }
}

interface DynamicDataFieldsModel extends WrappedFieldArrayProps<DataField> {
    category: string
    onChangeCallback?
}

export const DynamicDataFields = memo(({
    fields,
    category,
    onChangeCallback,
}: Partial<DynamicDataFieldsModel>) => {
    if (!fields) return null

    const dataFields = fields.getAll()

    if (dataFields && dataFields.length) {
        dataFields.sort((a, b) => {
            if (a.priority !== b.priority) {
                return a.priority - b.priority
            }
            return a.identifier.localeCompare(b.identifier)
        })
    }

    return <Row className="row-float-block">
        {fields.map((member, index) => {
            const dataField = dataFields[index]

            if (dataField.category !== category) return null
            return (
                <Col
                    key={dataField.label}
                    sm={dataField.isMultiple ? 12 : 6}
                    md={12}
                    lg={dataField.isMultiple ? 12 : 6}
                    xs={12}
                >
                    {switchFields(dataField, member, onChangeCallback ? onChangeCallback : () => {})}
                </Col>
            )
        })}
    </Row>
})
