import React, { ComponentType, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { getMeetings } from '../../actions/meetings'
import {
    LeadPartnerMeetingRequests, LeadPartnerMeetingRequestTimeslot, LeadPartnerMeetingRequest
} from '../../reducers/types/entities/LeadPartnerMeetingRequests'
import { connect } from '../../utils/connect'
import FormattedDate from '../elements/FormattedDate'
import { Column, Header, Table, Rows, Cell } from '../elements/table'

interface TimeslotRowProps {
    rowData?: LeadPartnerMeetingRequestTimeslot
}

class TimeslotRow extends PureComponent<TimeslotRowProps> {
    render() {
        const timeslot = this.props.rowData
        return (
            <tr>
                <Cell><FormattedDate date={timeslot?.from} format="date" /></Cell>
                <Cell><FormattedDate date={timeslot?.from} format="time" /></Cell>
                <Cell><FormattedDate date={timeslot?.to} format="time" /></Cell>
            </tr>
        )
    }
}

interface MeetingRowProps {
    rowData?: LeadPartnerMeetingRequest
}

interface MeetingRowState {
    showNestedRow: boolean;
}

class MeetingRow extends PureComponent<MeetingRowProps, MeetingRowState> {

    constructor(props) {
        super(props)
        this.state = {
            showNestedRow: false
        }
    }

    handleRowClick = () => {
        this.setState((prevState) => ({
            showNestedRow: !prevState.showNestedRow,
        }))
    }

    render() {
        const meeting = this.props.rowData

        return <>
            <tr onClick={this.handleRowClick}>
                <Cell>
                    <Link to={'/partners/' + meeting?.partnerId} target="_blank">
                        <strong>{meeting?.partnerId}</strong>
                    </Link>
                </Cell>
                <Cell>{meeting?.leadId}</Cell>
                <Cell><FormattedDate date={meeting?.createdAt} format='datetime' /></Cell>
            </tr>
            {this.state.showNestedRow && meeting?.leadPartnerMeetingRequestTimeslots && <tr>
                <td colSpan={100}>
                    <Table style={{ padding: '20px', width: '100%' }}>
                        <Header>
                            <Column>Date</Column>
                            <Column>From</Column>
                            <Column>To</Column>
                        </Header>
                        <Rows items={meeting.leadPartnerMeetingRequestTimeslots}>
                            <TimeslotRow />
                        </Rows>
                    </Table>
                </td>
            </tr>}
        </>
    }
}

interface MeetingsDispatchProps {
    getMeetings: typeof getMeetings
    meetings: LeadPartnerMeetingRequests[]
}

interface MeetingsProps {
    leadId: number
}

type MeetingModel = MeetingsDispatchProps & MeetingsProps

@connect<any,  any,  MeetingModel>(
    (state, props) => (
        {
            meetings: Object.values(state.entities.leadPartnerMeetingRequests)
        }
    ),
    {
        getMeetings,
    }
)
class Meetings extends PureComponent<MeetingModel> {

    componentDidMount() {
        this.props.getMeetings(this.props.leadId)
    }

    render() {

        return (
            <Table modifiers="splitByRow">
                <Header>
                    <Column>Partner id</Column>
                    <Column>Lead id</Column>
                    <Column>Created at</Column>
                </Header>
                <Rows
                    items={this.props.meetings}
                    noDataText="No meetings was scheduled"
                >
                    <MeetingRow />
                </Rows>
            </Table>
        )
    }
}

export default Meetings as unknown as ComponentType<MeetingsProps>
