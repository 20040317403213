import React, { Fragment, memo } from 'react'
import moment from 'moment'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Subscription } from '../../../reducers/types/entities/Subscription'
import { Card } from '../../blocks/Card'
import { BtnLink } from '../../elements/Links'
import PartnerPaymentOptions from './PartnerPaymentOptions'
import { Button } from '../../elements/Button'
import { Col, Row } from 'react-bootstrap'

interface PartnerSubscriptionsModel {
    partner: Partner
    subscription: Subscription
    isLoadingSubscriptions: boolean
    onConvertClick?: (partnerId: number) => Promise<void>
}

const SubscriptionsPartnerCard = memo(({
    partner,
    subscription,
    isLoadingSubscriptions,
    onConvertClick,
}: PartnerSubscriptionsModel) => {
    const activeSubscription = subscription && moment().isSameOrAfter(subscription.startsAt)

    return <Card className="subscriptionsPartnerCard">
        <Card.Header>Subscription</Card.Header>
        <Card.Content padding="20px 20px 0 20px">
            <PartnerPaymentOptions partner={partner}/>
        </Card.Content>
        <Card.Content
            isLoading={isLoadingSubscriptions}
            modifiers={[ 'maxHeight', 'pT_1.5' ]}
            scrollHeight="calc(100% - 260px)"
        >
            {subscription && <Fragment>
                <Card.Text>
                    Partner is subscribed to the
                    {' '}
                    <strong>{subscription.packageType}</strong>
                    .
                </Card.Text>
                {subscription.currentMonthlyPrice && <Card.Text>
                    Current price per period (including discounts):
                    {' '}
                    {subscription.currentMonthlyPrice.amount}
                    {' '}
                    {subscription.currentMonthlyPrice.currency}
                    .
                </Card.Text>}
                {activeSubscription && <Fragment>
                    {subscription.canceledAt &&
                        <Card.Text>
                            Subscription has been cancelled and will not be renewed. The subscription will expire
                            {' '}
                            {moment(subscription.expiresAt).calendar()}
                        </Card.Text>
                    }
                    <Card.Text modifiers="m_0">
                        {subscription.paymentSolution
                            ? <span>
                                Payment method is
                                {subscription.paymentSolution.name}
                                .
                            </span>
                            : 'No payment solution selected for subscription'
                        }
                    </Card.Text>
                </Fragment>}
            </Fragment>}
        </Card.Content>
        <Card.Content padding="0 20px">
            <Row>
                {onConvertClick &&
                <Col xs={6}>
                    <Button
                        modifiers={[ 'fullWidth', 'primary', 'btnBlock', 'p_1' ]}
                        onClick={() => onConvertClick(partner.id)}
                    >
                        Convert to draft contract
                    </Button>
                </Col>
                }
                <Col xs={onConvertClick ? 6 : 12}>
                    <BtnLink
                        modifiers={[ 'fullWidth', 'secondary', 'btnBlock', 'p_1' ]}
                        to={'/partners/' + partner.id + '/subscription-time-line'}
                    >
                        View subscription details
                    </BtnLink>
                </Col>
            </Row>
        </Card.Content>
    </Card>
})

export default SubscriptionsPartnerCard
