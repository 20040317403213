import React, { memo } from 'react'
import moment from 'moment'
import { Field } from 'redux-form'
import { Button } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { Col, Row } from 'react-bootstrap'
import { TextareaGroup } from '../elements/forms/inputs/TextareaGroup'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import { DateTimePickerGroup } from '../elements/forms/inputs/DateTimePickerGroup'


const required = [ value => value ? undefined : 'Required' ]
interface PostponeCanvasFormModel {
    onSubmit(data:any):void
    formName: string
    formValues
    submit
}

const PostponeCanvasForm = memo(({
    formValues,
    formName,
    onSubmit,
    submit,
}: PostponeCanvasFormModel) => {
    const duration = (formValues && formValues.values) ? formValues.values.duration : ''

    const followupDate = moment().startOf('day').add(parseInt(duration), 'days').format('D MMM')

    return (
        <ReduxForm
            form={formName}
            onSubmit={onSubmit}
        >
            <Row>
                <Col md={12}>
                    <Field
                        name="note"
                        label="Why do you postpone contacting this partner?"
                        component={TextareaGroup}
                        validate={required}
                    />
                    <Row>
                        <Col md={6}>
                            <Field
                                label="Follow-up time"
                                name="duration"
                                returnId={true}
                                component={SelectInputGroup}
                                validate={required}
                            >
                                <option value="7">In 1 week</option>
                                <option value="30">In 1 month</option>
                                <option value="180">In 6 months</option>
                                <option value="custom">Specific date</option>
                            </Field>
                        </Col>
                        <Col md={6}>
                            {!isNaN(duration) && <div>
                                Follow-up
                                {followupDate}
                            </div>}
                            {duration === 'custom' && <Field
                                label="Follow-up"
                                component={DateTimePickerGroup}
                                name="date"
                                dateFormat="YYYY-MM-DD"
                            />}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Button
                modifiers={[ 'action', 'mT_2' ]}
                onClick={() => submit(formName)}
            >
                Postpone
            </Button>
        </ReduxForm>
    )}
)

export default PostponeCanvasForm
