import styled from 'styled-components'
import { tableBorderColor } from '../../../utils/variables'

const Caption = styled.caption`
    font-weight: 500;
    background-color: #F8FAFC;
    color: #6F8289;
    text-transform: uppercase;
    font-size: 11px;
    text-align: left;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    border: 1px solid ${tableBorderColor};
    position: sticky;
    top: 0px;
    z-index: 5;
    & + colgroup + thead tr th, & + thead tr th {
      top: 39px;
    }
`

export default Caption
