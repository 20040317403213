import { CALL_API } from '../../middleware/api'
import * as schemas from '../../schemas'
import { actionTypesCreator } from '../../utils'

export const GET_SEGMENTATION_SUB_GEO_REGIONS = actionTypesCreator('segmentationSubGeoRegions', 'GET_SEGMENTATION_SUB_GEO_REGIONS')

export const GET_LEAD_SEGMENTATION_SUB_GEO_REGIONS = actionTypesCreator('segmentationSubGeoRegions', 'GET_LEAD_SEGMENTATION_SUB_GEO_REGIONS')

export const UPDATE_LEAD_SEGMENTATION_SUB_GEO_REGIONS = actionTypesCreator('segmentationSubGeoRegions', 'UPDATE_LEAD_SEGMENTATION_SUB_GEO_REGIONS')


export const getSegmentationSubGeoRegions = (criteria) => ({
    [CALL_API]: {
        types: GET_SEGMENTATION_SUB_GEO_REGIONS,
        endpoint: '/locations/segmentationgeoregions/segmentationsubgeoregions',
        schema: schemas.segmentationSubGeoRegions,
        options: {
            body: {
                ...criteria,
                limit: 1000,
            },
        },
    },
})

export const getLeadSegmentationSubGeoRegions = (leadId: number) => ({
    [CALL_API]: {
        types: GET_LEAD_SEGMENTATION_SUB_GEO_REGIONS,
        endpoint: `/leads/${leadId}/segmentationsubgeoregions`,
        schema: schemas.segmentationSubGeoRegions,
        options: {
            body: {
                limit: 100,
            },
        },
    },
})

export const updateLeadSegmentationSubGeoRegions = (
    leadId: number,
    segmentationSubGeoRegionId: number[]
) => ({
    [CALL_API]: {
        types: UPDATE_LEAD_SEGMENTATION_SUB_GEO_REGIONS,
        endpoint: `/leads/${leadId}/segmentationsubgeoregions`,
        schema: schemas.segmentationSubGeoRegions,
        options: {
            method: 'put',
            body: {
                segmentationSubGeoRegionId,
            },
        },
    },
})
