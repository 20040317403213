import React, { PureComponent } from 'react'
import { connect } from '../../utils/connect'
import styled from 'styled-components'
import config from '../../config'

const globalTopBarHeight = 50

const Iframe = styled.iframe`
    position: fixed;
    height: calc(100vh - ${globalTopBarHeight}px);
    width: 100vw;
    top: ${globalTopBarHeight}px;
    left: 0;
    border: 0;
`

interface IframePageStateProps {
    iframeSource: string
    accessToken: string|null
}

interface IframePagePathProps {
    location: {
        pathname: string
        search: string
    }
}

@connect<IframePageStateProps, {}, IframePagePathProps>(
    (state, props) => ({
        accessToken: state.auth.token,
        iframeSource: config.bladeFrontendUrl + props.location.pathname + props.location.search,
    })
)
export default class IframePage extends PureComponent<IframePageStateProps & IframePagePathProps> {

    render() {
        let {
            accessToken,
            iframeSource,
        } = this.props

        iframeSource += iframeSource.includes('?') ? '&token=' : '?token='
        iframeSource += accessToken

        return (
            <Iframe src={iframeSource}/>
        )
    }
}
