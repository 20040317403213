import { createSelector } from 'reselect'
import { values } from 'lodash'

const employeeIdSelector = state => state.auth.employeeId

const employeesSelector = state => state.entities.employees

export const currentEmployeeSelector = createSelector(
    employeeIdSelector, employeesSelector,
    (employeeId, employees) => employees[employeeId]
)

export const employeeSelector = createSelector(
    employeesSelector,
    employees => values(employees)
)

const geoCodeSelector = (_, geoCode) => geoCode

export const employeesByGeoCodeSelector = createSelector(
    employeeSelector, geoCodeSelector,
    (employees, geoCode) => employees.filter(employee => employee.geoCode === geoCode)
)
