import * as schemas from '../../schemas'
import { CALL_API } from '../../middleware/api'
import { actionTypesCreator } from '../../utils'

export const GET_EMPLOYEE_NOTIFICATION = actionTypesCreator('notifications', 'GET_EMPLOYEE_NOTIFICATION')

export const getNotification = (employeeId, body) => ({
    [CALL_API]: {
        suppressLoader: true,
        types: GET_EMPLOYEE_NOTIFICATION,
        endpoint: `/organisation/employees/${employeeId}/notifications`,
        schema: schemas.notifications,
        options: {
            body,
        },
    },
})
