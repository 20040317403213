import React, { memo } from 'react'
import Cell from './Cell'

interface StateCellModel {
    danger, warning, success, state, title, style, children
}

const StateCell = memo(({
    danger, warning, success, state, title, style, children,
}: Partial<StateCellModel>) => {
    state = danger ? 'danger' : (warning ? 'warning' : (success ? 'success' : state))
    return (
        <Cell title={title} style={style}>
            <span className={'dot dot-' + state}/>
            &nbsp;
            {children}
        </Cell>
    )
})

export default StateCell
