import { combineReducers } from 'redux'
import generalSubjectReducer from '../../utils/generalSubjectReducer'
import {
    GET_BUSINESS_UNITS_SECTOR,
    GET_PARTNERS_SECTOR,
    GET_SECTORS,
} from './actions'

const sectors = combineReducers({
    all: generalSubjectReducer(GET_SECTORS),
    partner: generalSubjectReducer(GET_PARTNERS_SECTOR),
    businessUnit: generalSubjectReducer(GET_BUSINESS_UNITS_SECTOR),
})

export default sectors
