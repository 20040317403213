import { ADD_QUOTE_ITEM_SUCCESS, DELETE_QUOTE_ITEM_SUCCESS } from '../actions/quotes'
import { GET_LEAD_SUCCESS, SAVE_LEAD_SUCCESS } from '../actions/leads'
import { Entities } from './types/entities'
import merge from 'lodash/merge'
import { InitialAction } from './types/InitialAction'
import { Quotes } from './types/entities/Quote'
import { GET_PARTNER_UNIQUE_SELLING_POINTS_SUCCESS } from '../actions/partners'

const initialState: Entities = {
    activities: {},
    affiliates: {},
    allocationLimits: {},
    allocations: {},
    allocationsQuotas: {},
    businessModels: {},
    businessUnits: {},
    campaigns: {},
    certifications: {},
    clients: {},
    cMatches: {},
    contactArchives: {},
    coupons: {},
    customers: {},
    cvs: {},
    dataFields: {},
    employees: {},
    generalQuotes: {},
    geoRegions: {},
    industries: {},
    invoiceRefundRequests: {},
    invoices: {},
    invoicings: {},
    kpiTargets: {},
    kpiValues: {},
    languages: {},
    leadAggregations: {},
    leadCategories: {},
    leads: {},
    leadTypes: {},
    locations: {},
    notifications: {},
    packages: {},
    packageTypes: {},
    partnerDiscounts: {},
    partners: {},
    partnerCategories: {},
    leadPartnerMeetingRequests: {},
    partnerContracts: {},
    partnerContractOfferGroups: {},
    partnersFees: {},
    partnerTitles: {},
    partnerUniqueSellingPoints: {},
    partnerUsers: {},
    pauseSubscriptions: {},
    paymentCards: {},
    paymentMethods: {},
    paymentSolutions: {},
    payments: {},
    paymentSchedules: {},
    permissions: {},
    products: {},
    quoteItems: {},
    quotes: {},
    ratings: {},
    revenueSegments: {},
    sectors: {},
    segmentationSubGeoRegions: {},
    subscriptionAgreements: {},
    subscriptionPackages: {},
    subscriptionPrices: {},
    subscriptions: {},
    tasks: {},
    terms: {},
    companySizes: {},
    partnerAssignees: {},
    deliveries: {},
    partnerSignups: {},
    allocationSuggestions: {},
}

interface EntitiesActionModel extends InitialAction {
    quoteId: number
    itemId: number
}

export default function reducer(
    state = initialState,
    action: EntitiesActionModel
) {
    if (action.type === ADD_QUOTE_ITEM_SUCCESS) {
        const quotes = { ...state.quotes } as Quotes
        quotes[action.quoteId].items = [
            ...quotes[action.quoteId].items,
            action.response.entities.quoteItems[action.response.result],
        ]
        return merge({}, state, quotes)
    }

    if (action.type === DELETE_QUOTE_ITEM_SUCCESS) {
        const quotes = { ...state.quotes } as Quotes
        quotes[action.quoteId].items = quotes[action.quoteId].items.filter(item => item.id !== action.itemId)
        return merge({}, state, quotes)
    }

    if (action.type === SAVE_LEAD_SUCCESS) {
        if (!action.response.entities.leads[action.response.result].quotesInfo?.quoteInfo) {
            delete state.leads[action.response.result]
        }
    }

    if (action.type === GET_LEAD_SUCCESS) {
        delete state.leads[action.response.result]
    }

    // TODO: need investigation why it is here !!!
    if (action.type === GET_PARTNER_UNIQUE_SELLING_POINTS_SUCCESS) {
        return {
            ...merge({}, state, action.response.entities),
            partnerUniqueSellingPoints: action.response.entities.partnerUniqueSellingPoints,
        }
    }

    if (action.response && action.response.entities) {
        return merge({}, state, action.response.entities)
    }

    return state
}
