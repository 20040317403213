import {
    GET_NEWS_FEED,
    GET_NEWS_FEED_SUCCESS,
    GET_NEWS_FEED_FAIL,
    CREATE_LEAD_NOTE_ACTIVITY,
    CREATE_PARTNER_NOTE_ACTIVITY,
    CREATE_LEAD_NOTE_ACTIVITY_FAIL,
    CREATE_PARTNER_NOTE_ACTIVITY_FAIL,
    GET_TOTAL_CALL_ACTIVITIES,
    GET_TOTAL_CALL_ACTIVITIES_SUCCESS,
    GET_TOTAL_CALL_ACTIVITIES_FAIL,
} from '../../actions/newsfeed'
import {
    GET_TOTAL_CONTACT_ARCHIVES,
    GET_TOTAL_CONTACT_ARCHIVES_FAIL,
    GET_TOTAL_CONTACT_ARCHIVES_SUCCESS,
} from '../../actions/contact'

import { groupBy, mapValues, values } from 'lodash'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'
import { Activity } from '../types/entities/Activities'

export interface NewsFeedModel extends InitialState {
    itemsIds: number[]
    totalContactArchives: number
    totalLeadCallActivities: { [id: number]: number }
    totalPartnerCallActivities: { [id: number]: number }
}

const initialState: NewsFeedModel = {
    itemsIds: [],
    isLoading: false,
    totalContactArchives: 0,
    totalLeadCallActivities: {},
    totalPartnerCallActivities: {},
    pagination: {
        pages: null,
        page: null,
        total: null,
    },
}



export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_NEWS_FEED:
        case CREATE_LEAD_NOTE_ACTIVITY:
        case CREATE_PARTNER_NOTE_ACTIVITY:
            return {
                ...state,
                isLoading: true,
            }
        case GET_NEWS_FEED_SUCCESS:
            return {
                ...state,
                itemsIds: action.append ? state.itemsIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_NEWS_FEED_FAIL:
        case CREATE_LEAD_NOTE_ACTIVITY_FAIL:
        case CREATE_PARTNER_NOTE_ACTIVITY_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_TOTAL_CALL_ACTIVITIES_SUCCESS:
            return {
                ...state,
                totalLeadCallActivities: {
                    ...state.totalLeadCallActivities,
                    ...mapValues(
                        groupBy(
                            values(action.response.entities.activities),
                            (el: Activity) => el.lead && el.lead.id ),
                        'length'
                    ),
                },
                totalPartnerCallActivities: {
                    ...state.totalPartnerCallActivities,
                    ...mapValues(
                        groupBy(
                            values(action.response.entities.activities),
                            (el: Activity) => el.partner && el.partner.id ),
                        'length'
                    ),
                },
            }
        case GET_TOTAL_CALL_ACTIVITIES:
        case GET_TOTAL_CALL_ACTIVITIES_FAIL:
            return {
                ...state,
                totalCallActivities: 0,
            }
        case GET_TOTAL_CONTACT_ARCHIVES_SUCCESS:
            return {
                ...state,
                totalContactArchives: action.response.pagination.total,
            }
        case GET_TOTAL_CONTACT_ARCHIVES:
        case GET_TOTAL_CONTACT_ARCHIVES_FAIL:
            return {
                ...state,
                totalContactArchives: 0,
            }
        default:
            return state
    }
}
