import React, { ComponentType, PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../elements/Button'
import ReduxForm from '../elements/forms/ReduxForm'
import { Field, submit } from 'redux-form'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Flex } from '../elements/Flex'
import { excludedLeadPartnersSelector } from '../../selectors/lead'
import { partnerListSelector } from '../../selectors/partner'
import { createExcludedPartner, deleteExcludedPartner, getExcludedPartners } from '../../actions/leads'
import { getPartners } from '../../actions/partners'
import config from '../../config'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import { Partner } from '../../reducers/types/entities/Partner'
import { Column, Header, Rows, Table, Cell } from '../elements/table'
import styled from 'styled-components'
import { Card } from '../blocks/Card'

const ModalFooter = styled(Modal.Footer)`
  max-height: 350px;
  padding: 0;
  overflow: auto;
  border: 0;
`

interface ExcludePartnersModel {
    excludedPartners: Partner[]
    partners: Partner[]
    modalProps: {
        isLoadingContact: boolean
        leadId: number
    }
    createExcludedPartner: typeof createExcludedPartner
    deleteExcludedPartner: typeof deleteExcludedPartner
    getExcludedPartners: typeof getExcludedPartners
    getPartners: typeof getPartners
    hideModal: typeof hideModal
    submit: typeof submit
}

const RowData = ({ rowData, leadId, isExcluded, action }) => {
    const partner = rowData
    return <tr key={`excludedPartner_${partner.id}`}>
        <Cell>{partner.id}</Cell>
        <Cell>{partner.companyName}</Cell>
        <Cell className="text-right">
            {isExcluded
                ? <Button
                    modifiers={[ 'danger', 'btnIcon' ]}
                    onClick={() => action(leadId, partner.id)}
                >
                    <i className="mdi mdi-close" />
                </Button>
                : <Button
                    modifiers={[ 'action' ]}
                    onClick={() => action(leadId, partner.id)}
                >
                    Exclude
                </Button>
            }
        </Cell>
    </tr>
}

const ExcludedRow = RowData as ComponentType<{leadId, action, isExcluded?}>

@connect(
    state => ({
        excludedPartners: excludedLeadPartnersSelector(state),
        partners: partnerListSelector(state),
        modalProps: state.modal,
    }),
    {
        createExcludedPartner,
        deleteExcludedPartner,
        getExcludedPartners,
        getPartners,
        hideModal,
        submit,
    }
)
class ExcludePartnersModal extends PureComponent<ExcludePartnersModel> {
    hideModal = () => this.props.hideModal()

    save = () => this.props.submit('excludePartnersSearch')

    searchExcludePartners = ({ query }) => {
        this.props.getPartners({
            geoCode: config.geoCode,
            query,
        })
    }

    deleteExcludePartner = async (leadId, partnerId) => {
        await this.props.deleteExcludedPartner(leadId, partnerId)
        this.props.getExcludedPartners(leadId)
    }

    createExcludedPartner = async (leadId, partnerId) => {
        await this.props.createExcludedPartner(leadId, partnerId)
        this.props.getExcludedPartners(leadId)
    }

    render() {
        const {
            excludedPartners,
            modalProps,
            partners,
        } = this.props
        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header closeButton={true}>Exclude lead for partners</Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="excludePartnersSearch"
                    onSubmit={this.searchExcludePartners}
                >
                    <Flex>
                        <Field
                            removeClassFormGroup={true}
                            name="query"
                            component={FieldGroup}
                        />
                        {' '}
&nbsp;
                        <Button
                            modifiers={[ 'action' ]}
                            onClick={this.save}
                        >
                            Search
                        </Button>
                    </Flex>
                </ReduxForm>
            </Modal.Body>
            <Card.Line modifiers="mY_2"/>
            <Card.Content isLoading={modalProps.isLoadingContact}>
                <ModalFooter>
                    <Table modifiers="splitByRow">
                        <Header>
                            <Column>#</Column>
                            <Column>Partner</Column>
                            <Column className="text-right">Action</Column>
                        </Header>
                        <Rows
                            items={partners}
                            noDataText="No partner is matching your current search query."
                        >
                            <ExcludedRow leadId={modalProps.leadId} action={this.createExcludedPartner}/>
                        </Rows>
                    </Table>
                </ModalFooter>
                <Card.Line modifiers="mY_2"/>
                <ModalFooter>
                    <Table modifiers="splitByRow">
                        <Header>
                            <Column>#</Column>
                            <Column>Partner</Column>
                            <Column className="text-right">Remove from lead</Column>
                        </Header>
                        <Rows
                            noDataText="No excluded partner for this lead."
                            items={excludedPartners}
                        >
                            <ExcludedRow
                                leadId={modalProps.leadId}
                                isExcluded={true}
                                action={this.deleteExcludePartner}
                            />
                        </Rows>
                    </Table>
                </ModalFooter>
            </Card.Content>
        </Modal>
    }
}


export default ExcludePartnersModal as unknown as ComponentType
