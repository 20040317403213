import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_CLIENT = 'clients/GET_CLIENT'
export const GET_CLIENT_SUCCESS = 'clients/GET_CLIENT_SUCCESS'
export const GET_CLIENT_FAIL = 'clients/GET_CLIENT_FAIL'

export const GET_ALLOCATION_SUGGESTIONS = 'clients/GET_ALLOCATION_SUGGESTIONS'
export const GET_ALLOCATION_SUGGESTIONS_SUCCESS = 'clients/GET_ALLOCATION_SUGGESTIONS_SUCCESS'
export const GET_ALLOCATION_SUGGESTIONS_FAIL = 'clients/GET_ALLOCATION_SUGGESTIONS_FAIL'

export const getClient = (
    clientId: number
) => ({
    [CALL_API]: {
        types: [ GET_CLIENT, GET_CLIENT_SUCCESS, GET_CLIENT_FAIL ],
        endpoint: `/clients/${clientId}`,
        schema: schemas.client,
    },
})

interface GetAllocationSuggestionsModel {
    clientId: number,
    leadId: number,
    isAccepted?: string
}
export const getAllocationSuggestions = (
    { clientId, leadId, isAccepted }: GetAllocationSuggestionsModel
) => ({
    [CALL_API]: {
        types: [ GET_ALLOCATION_SUGGESTIONS, GET_ALLOCATION_SUGGESTIONS_SUCCESS, GET_ALLOCATION_SUGGESTIONS_FAIL ],
        endpoint: `/clients/${clientId}/allocationsuggestions`,
        options: {
            body: {
                leadId,
                isAccepted,
            },
        }
    }
})
