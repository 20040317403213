import * as schemas from '../../schemas'
import { CALL_API } from '../../middleware/api'
import { actionTypesCreator } from '../../utils'

export const GET_PARTNER_USERS = actionTypesCreator('partnerUsers', 'GET_PARTNER_USERS')

export const UPDATE_PARTNER_USER = actionTypesCreator('partnerUsers', 'UPDATE_PARTNER_USER')

export const DELETE_PARTNER_USER = actionTypesCreator('partnerUsers', 'DELETE_PARTNER_USER')

interface GetPartnerUsersModel {
    partnerId: number | string
    partnerUserId: number | number[]
    limit: number,
}
export const getPartnerUsers = (
    body: Partial<GetPartnerUsersModel>
) => ({
    [CALL_API]: {
        types: GET_PARTNER_USERS,
        endpoint: '/partners/users',
        schema: schemas.partnerUsers,
        options: {
            body,
        },
    },
})

export const getPartnerUser = userId => ({
    [CALL_API]: {
        types: GET_PARTNER_USERS,
        endpoint: `/partners/users/${userId}`,
        schema: schemas.partnerUser,
    },
})

interface PartnerUser {
    email: string
    firstName: string
    lastName: string
    password: string
}
export const createPartnerUser = (
    partnerId: number,
    body: PartnerUser
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_USER,
        endpoint: `/partners/${partnerId}/users`,
        schema: schemas.partnerUser,
        options: {
            method: 'post',
            body,
        },
    },
})

export const updatePartnerUser = (
    partnerId: number,
    body
) => ({
    [CALL_API]: {
        types: UPDATE_PARTNER_USER,
        endpoint: `/partners/${partnerId}/users/${body.id}`,
        schema: schemas.partnerUser,
        options: {
            method: 'put',
            body,
        },
    },
})

export const deletePartnerUser = (partnerId, userId) => ({
    [CALL_API]: {
        types: DELETE_PARTNER_USER,
        endpoint: `/partners/${partnerId}/users/${userId}`,
        options: {
            method: 'delete',
        },
    },
})
