import React, { Children, ComponentType, PureComponent, ReactElement } from 'react'
import styled from 'styled-components'
import { bodyBackgroundColor } from '../../../utils/variables'
import { connect } from '../../../utils/connect'

const Container = styled.div`
  height: calc(100vh - 275px);
  overflow-y: auto;
  background-color: ${bodyBackgroundColor};
`
@connect(
    state => ({
        sidebarName: state.router.location.query.sidebarName,
    })
)
class Sidebar extends PureComponent<{sidebarName: string}> {
    render() {
        const { sidebarName, children } = this.props

        const activeView = Children.toArray(children)
            .find((child: ReactElement<any>) => child.props.viewKey === sidebarName)

        return activeView ? <Container>{activeView}</Container> : null
    }
}

export default Sidebar as unknown as ComponentType
