import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_PARTNER_CONTRACTS = 'partnerContracts/GET_PARTNER_CONTRACTS'
export const GET_PARTNER_CONTRACTS_SUCCESS = 'partnerContracts/GET_PARTNER_CONTRACTS_SUCCESS'
export const GET_PARTNER_CONTRACTS_FAIL = 'partnerContracts/GET_PARTNER_CONTRACTS_FAIL'

export const GET_PARTNER_CONTRACT = 'partnerContracts/GET_PARTNER_CONTRACT'
export const GET_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/GET_PARTNER_CONTRACT_SUCCESS'
export const GET_PARTNER_CONTRACT_FAIL = 'partnerContracts/GET_PARTNER_CONTRACT_FAIL'

export const DOWNLOAD_PARTNER_CONTRACT = 'partnerContracts/DOWNLOAD_PARTNER_CONTRACT'
export const DOWNLOAD_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/DOWNLOAD_PARTNER_CONTRACT_SUCCESS'
export const DOWNLOAD_PARTNER_CONTRACT_FAIL = 'partnerContracts/DOWNLOAD_PARTNER_CONTRACT_FAIL'

export const UPLOAD_SIGNED_PARTNER_CONTRACT = 'partnerContracts/UPLOAD_SIGNED_PARTNER_CONTRACT'
export const UPLOAD_SIGNED_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/UPLOAD_SIGNED_PARTNER_CONTRACT_SUCCESS'
export const UPLOAD_SIGNED_PARTNER_CONTRACT_FAIL = 'partnerContracts/UPLOAD_SIGNED_PARTNER_CONTRACT_FAIL'

export const DOWNLOAD_SIGNED_PARTNER_CONTRACT = 'partnerContracts/DOWNLOAD_SIGNED_PARTNER_CONTRACT'
export const DOWNLOAD_SIGNED_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/DOWNLOAD_SIGNED_PARTNER_CONTRACT_SUCCESS'
export const DOWNLOAD_SIGNED_PARTNER_CONTRACT_FAIL = 'partnerContracts/DOWNLOAD_SIGNED_PARTNER_CONTRACT_FAIL'

export const CREATE_PARTNER_CONTRACT = 'partnerContracts/CREATE_PARTNER_CONTRACT'
export const CREATE_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/CREATE_PARTNER_CONTRACT_SUCCESS'
export const CREATE_PARTNER_CONTRACT_FAIL = 'partnerContracts/CREATE_PARTNER_CONTRACT_FAIL'

export const CREATE_PARTNER_CONTRACT_PAUSE = 'partnerContracts/CREATE_PARTNER_CONTRACT_PAUSE'
export const CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS = 'partnerContracts/CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS'
export const CREATE_PARTNER_CONTRACT_PAUSE_FAIL = 'partnerContracts/CREATE_PARTNER_CONTRACT_PAUSE_FAIL'

export const UPDATE_PARTNER_CONTRACT_PAUSE = 'partnerContracts/UPDATE_PARTNER_CONTRACT_PAUSE'
export const UPDATE_PARTNER_CONTRACT_PAUSE_SUCCESS = 'partnerContracts/UPDATE_PARTNER_CONTRACT_PAUSE_SUCCESS'
export const UPDATE_PARTNER_CONTRACT_PAUSE_FAIL = 'partnerContracts/UPDATE_PARTNER_CONTRACT_PAUSE_FAIL'

export const DELETE_PARTNER_CONTRACT_PAUSE = 'partnerContracts/DELETE_PARTNER_CONTRACT_PAUSE'
export const DELETE_PARTNER_CONTRACT_PAUSE_SUCCESS = 'partnerContracts/DELETE_PARTNER_CONTRACT_PAUSE_SUCCESS'
export const DELETE_PARTNER_CONTRACT_PAUSE_FAIL = 'partnerContracts/DELETE_PARTNER_CONTRACT_PAUSE_FAIL'

export const CREATE_PARTNER_CONTRACT_BOOST = 'partnerContracts/CREATE_PARTNER_CONTRACT_BOOST'
export const CREATE_PARTNER_CONTRACT_BOOST_SUCCESS = 'partnerContracts/CREATE_PARTNER_CONTRACT_BOOST_SUCCESS'
export const CREATE_PARTNER_CONTRACT_BOOST_FAIL = 'partnerContracts/CREATE_PARTNER_CONTRACT_BOOST_FAIL'

export const UPDATE_PARTNER_CONTRACT_BOOST = 'partnerContracts/UPDATE_PARTNER_CONTRACT_BOOST'
export const UPDATE_PARTNER_CONTRACT_BOOST_SUCCESS = 'partnerContracts/UPDATE_PARTNER_CONTRACT_BOOST_SUCCESS'
export const UPDATE_PARTNER_CONTRACT_BOOST_FAIL = 'partnerContracts/UPDATE_PARTNER_CONTRACT_BOOST_FAIL'

export const DELETE_PARTNER_CONTRACT_BOOST = 'partnerContracts/DELETE_PARTNER_CONTRACT_BOOST'
export const DELETE_PARTNER_CONTRACT_BOOST_SUCCESS = 'partnerContracts/DELETE_PARTNER_CONTRACT_BOOST_SUCCESS'
export const DELETE_PARTNER_CONTRACT_BOOST_FAIL = 'partnerContracts/DELETE_PARTNER_CONTRACT_BOOST_FAIL'

export const CANCEL_PARTNER_CONTRACT = 'partnerContracts/CANCEL_PARTNER_CONTRACT'
export const CANCEL_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/CANCEL_PARTNER_CONTRACT_SUCCESS'
export const CANCEL_PARTNER_CONTRACT_FAIL = 'partnerContracts/CANCEL_PARTNER_CONTRACT_FAIL'

export const TERMINATE_PARTNER_CONTRACT = 'partnerContracts/TERMINATE_PARTNER_CONTRACT'
export const TERMINATE_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/TERMINATE_PARTNER_CONTRACT_SUCCESS'
export const TERMINATE_PARTNER_CONTRACT_FAIL = 'partnerContracts/TERMINATE_PARTNER_CONTRACT_FAIL'

export const ARCHIVE_PARTNER_CONTRACT = 'partnerContracts/ARCHIVE_PARTNER_CONTRACT'
export const ARCHIVE_PARTNER_CONTRACT_SUCCESS = 'partnerContracts/ARCHIVE_PARTNER_CONTRACT_SUCCESS'
export const ARCHIVE_PARTNER_CONTRACT_FAIL = 'partnerContracts/ARCHIVE_PARTNER_CONTRACT_FAIL'

export const ACCEPT_CONTRACT_FOR_PARTNER = 'partnerContracts/ACCEPT_CONTRACT_FOR_PARTNER'
export const ACCEPT_CONTRACT_FOR_PARTNER_SUCCESS = 'partnerContracts/ACCEPT_CONTRACT_FOR_PARTNER_SUCCESS'
export const ACCEPT_CONTRACT_FOR_PARTNER_FAIL = 'partnerContracts/ACCEPT_CONTRACT_FOR_PARTNER_FAIL'

export const getPartnerContracts = (
    partnerId: number | string,
    criteria?: {
        isArchived?: number | string
    }
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_CONTRACTS,
            GET_PARTNER_CONTRACTS_SUCCESS,
            GET_PARTNER_CONTRACTS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts`,
        schema: schemas.partnerContracts,
        options: {
            body: {
                limit: 500,
                ...criteria,
            },
        },
    },
})

export const getPartnerContract = (
    partnerId: number | string,
    partnerContractId: number | string
) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_CONTRACT,
            GET_PARTNER_CONTRACT_SUCCESS,
            GET_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}`,
        schema: schemas.partnerContract,
    },
})

interface CreatePartnerContractModel {
    allocationFrequencyMonths: number | string
    billingFrequencyMonths: number | string
    bindingPeriodMonths: number | string
    businessUnits: {
        clientTypes: {
            id: number
        }[]
        displayName: string
        geo: {
            zipCode: string
            cityName: string
            point?: object
            address: string
        }
        geoRegions: {
            id: number
        }[]
        id?: number
        industries: {
            id: number
        }[]
        partnerUserId?: number
        sectors: {
            id: number
        }[]
    }[]
    cancellationPeriodMonths: number | string
    replacesPartnerContractId?: number | string
    partnerBusinessUnitGroupingId?: number,
    partner: {
        id: number | string
    }
    startsAt: string
    category?: {
        id: number | string
    }
}

export const createPartnerContract = (
    partnerId: number | string,
    body: CreatePartnerContractModel
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT,
            CREATE_PARTNER_CONTRACT_SUCCESS,
            CREATE_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body,
        },
    },
})

interface CreatePartnerContractPause {
    partnerContractId: Array<number | string>,
    startsAt: string,
    endsAt: string,
}

export const createPartnerContractPause = (
    partnerId: number | string,
    body: CreatePartnerContractPause
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT_PAUSE,
            CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS,
            CREATE_PARTNER_CONTRACT_PAUSE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/allocationpauses`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'post',
            body,
        },
    },
})

interface UpdatePartnerContractPause {
    startsAt?: string,
    endsAt?: string,
}

export const updatePartnerContractPause = (
    partnerId: number | string,
    partnerContractId: number | string,
    pauseId: number | string,
    body: UpdatePartnerContractPause
) => ({
    [CALL_API]: {
        types: [
            UPDATE_PARTNER_CONTRACT_PAUSE,
            UPDATE_PARTNER_CONTRACT_PAUSE_SUCCESS,
            UPDATE_PARTNER_CONTRACT_PAUSE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/allocationpauses/${pauseId}`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'put',
            body,
        },
    },
})

export const deletePartnerContractPause = (
    partnerId: number | string,
    partnerContractId: number | string,
    pauseId: number | string
) => ({
    [CALL_API]: {
        types: [
            DELETE_PARTNER_CONTRACT_PAUSE,
            DELETE_PARTNER_CONTRACT_PAUSE_SUCCESS,
            DELETE_PARTNER_CONTRACT_PAUSE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/allocationpauses/${pauseId}`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'delete',
        },
    },
})

interface CreatePartnerContractAllocationBoostPeriod {
    partnerContractId: Array<number | string>,
    startsAt: string,
    endsAt: string,
}

export const createPartnerContractAllocationBoostPeriod = (
    partnerId: number | string,
    body: CreatePartnerContractAllocationBoostPeriod
) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT_BOOST,
            CREATE_PARTNER_CONTRACT_BOOST_SUCCESS,
            CREATE_PARTNER_CONTRACT_BOOST_FAIL,
        ],
        endpoint: `/partners/${partnerId}/allocationboosts`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'post',
            body,
        },
    },
})

interface UpdatePartnerContractPause {
    startsAt?: string,
    endsAt?: string,
}

export const updatePartnerContractAllocationBoostPeriod = (
    partnerId: number | string,
    partnerContractId: number | string,
    pauseId: number | string,
    body: UpdatePartnerContractPause
) => ({
    [CALL_API]: {
        types: [
            UPDATE_PARTNER_CONTRACT_BOOST,
            UPDATE_PARTNER_CONTRACT_BOOST_SUCCESS,
            UPDATE_PARTNER_CONTRACT_BOOST_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/allocationboosts/${pauseId}`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'put',
            body,
        },
    },
})

export const deletePartnerContractAllocationBoostPeriod = (
    partnerId: number | string,
    partnerContractId: number | string,
    pauseId: number | string
) => ({
    [CALL_API]: {
        types: [
            DELETE_PARTNER_CONTRACT_BOOST,
            DELETE_PARTNER_CONTRACT_BOOST_SUCCESS,
            DELETE_PARTNER_CONTRACT_BOOST_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/allocationboosts/${pauseId}`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'delete',
        },
    },
})

export const cancelPartnerContractNormally = (
    partnerId: number | string,
    partnerContractId: number | string
) => ({
    [CALL_API]: {
        types: [
            CANCEL_PARTNER_CONTRACT,
            CANCEL_PARTNER_CONTRACT_SUCCESS,
            CANCEL_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/actions`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body: {
                action: 'cancel_normally',
            },
        },
    },
})

export const cancelPartnerContractImmediately = (
    partnerId: number | string,
    partnerContractId: number | string,
    deleteUnusedAllocations?: boolean
) => ({
    [CALL_API]: {
        types: [
            TERMINATE_PARTNER_CONTRACT,
            TERMINATE_PARTNER_CONTRACT_SUCCESS,
            TERMINATE_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/actions`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body: {
                action: 'cancel_immediately',
                action_data: {
                    deleteUnusedAllocations,
                },
            },
        },
    },
})

export const archivePartnerContract = (
    partnerId: number | string,
    partnerContractId: number | string
) => ({
    [CALL_API]: {
        types: [
            ARCHIVE_PARTNER_CONTRACT,
            ARCHIVE_PARTNER_CONTRACT_SUCCESS,
            ARCHIVE_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/actions`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body: {
                action: 'archive',
            },
        },
    },
})

export const downloadPartnerContract = (
    partnerId: number | string,
    partnerContractId: number | string
) => ({
    [CALL_API]: {
        types: [
            DOWNLOAD_PARTNER_CONTRACT,
            DOWNLOAD_PARTNER_CONTRACT_SUCCESS,
            DOWNLOAD_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/pdf`,
        options: {
            method: 'get',
        },
    },
})

export const uploadSignedPartnerContract = (
    partnerId: number | string,
    partnerContractId: number | string,
    pdfContent: any
) => ({
    [CALL_API]: {
        types: [
            UPLOAD_SIGNED_PARTNER_CONTRACT,
            UPLOAD_SIGNED_PARTNER_CONTRACT_SUCCESS,
            UPLOAD_SIGNED_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/signedpdf`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body: {
                signed_contract: pdfContent,
            },
        },
    },
})

export const downloadSignedPartnerContract = (
    partnerId: number | string,
    partnerContractId: number | string
) => ({
    [CALL_API]: {
        types: [
            DOWNLOAD_SIGNED_PARTNER_CONTRACT,
            DOWNLOAD_SIGNED_PARTNER_CONTRACT_SUCCESS,
            DOWNLOAD_SIGNED_PARTNER_CONTRACT_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/signedpdf`,
        options: {
            method: 'get',
        },
    },
})

export const acceptContractForPartner = (
    partnerId: number | string,
    partnerContractId: number | string
) => ({
    [CALL_API]: {
        types: [
            ACCEPT_CONTRACT_FOR_PARTNER,
            ACCEPT_CONTRACT_FOR_PARTNER_SUCCESS,
            ACCEPT_CONTRACT_FOR_PARTNER_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${partnerContractId}/actions`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body: {
                action: 'accept_for_partner',
            },
        },
    },
})
