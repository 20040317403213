import {
    GET_CSM_TASKS, GET_CSM_TASKS_FAIL,
    GET_CSM_TASKS_SUCCESS
} from '../../actions/tasks'
import { InitialAction } from '../types/InitialAction'
import { InitialState } from '../types/InitialState'

export interface CsmTaskListModel extends InitialState {
    taskIds: number[]
}
const initialState: CsmTaskListModel = {
    isLoading: true,
    taskIds: [],
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

export default function reducer(
    state = initialState,
    action: InitialAction
) {
    switch (action.type) {
        case GET_CSM_TASKS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CSM_TASKS_SUCCESS:
            return {
                ...state,
                taskIds: action.append ? state.taskIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_CSM_TASKS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
