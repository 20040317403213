import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card/index'
import moment from 'moment'
import { pickBy, values } from 'lodash'
import { TargetsBarChart } from './TargetsBarChart'
import { KPIValues } from '../../../reducers/types/entities/KPIValue'
import { KPITargets } from '../../../reducers/types/entities/KPITarget'
import { connect } from '../../../utils/connect'
import { AmountCell, Cell, Table, Header, Column, Rows } from '../../elements/table'

const KPI = [
    { kpiIdentifier: 'total_fee', name: 'Total fee' },
    { kpiIdentifier: 'average_fee', name: 'Average fee' },
]

interface FeesRowModel {
    kpiValues: KPIValues
    kpiTargets: KPITargets
    employeeId: number
}

interface RowsHocModel {
    rowData: typeof KPI[0]
}

@connect(
    state => ({
        kpiValues: state.entities.kpiValues,
        kpiTargets: state.entities.kpiTargets,
        employeeId: state.auth.employeeId,
    })
)
class FeesRow extends PureComponent<FeesRowModel & RowsHocModel> {

    render() {
        const { rowData, kpiTargets, kpiValues, employeeId } = this.props

        const kpiId = `${employeeId}-${rowData.kpiIdentifier}-${moment().format('YYYY-M')}`

        const previousFourthMonth = moment().add(-4, 'months')

        const monthValues = values(
            pickBy(kpiValues, el =>
                (el.kpiIdentifier === 'average_fee' && rowData.kpiIdentifier === 'average_fee' && moment({ year: el.year, month: (el.month - 1) }).isAfter(previousFourthMonth, 'month')) ||
                (el.kpiIdentifier === rowData.kpiIdentifier && moment({ year: el.year, month: (el.month - 1) }).isBetween(previousFourthMonth, moment(), 'month')) ||
                (el.kpiIdentifier === `${rowData.kpiIdentifier}_forecast` && moment({ year: el.year, month: (el.month - 1) }).isSame(moment(), 'month'))
            )
        ).sort((a, b) => moment({ year: a.year, month: a.month }).isAfter(moment({ year: b.year, month: b.month }), 'month') ? 1 : -1)

        const amountValue = { amount: kpiValues[kpiId] ? kpiValues[kpiId].value : 0, currency: 'EUR' }

        const amountTarget = { amount: kpiTargets[kpiId] ? kpiTargets[kpiId].value : 0, currency: 'EUR' }

        return <tr key={kpiId}>
            <Cell>{rowData.name}</Cell>
            <AmountCell amount={amountValue} />
            <AmountCell amount={amountTarget} />
            <Cell><TargetsBarChart data={monthValues} lineBar={rowData.kpiIdentifier === 'average_fee'}/></Cell>
        </tr>
    }
}

const PerformanceTableRow = FeesRow as ComponentType

export default class MyFeesBoxCard extends PureComponent {
    render() {
        return <Card>
            <Card.Header>My fees</Card.Header>
            <Table>
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                    <col width="250px"/>
                </colgroup>
                <Header>
                    <Column>KPI</Column>
                    <Column>Month</Column>
                    <Column>Target</Column>
                    <Column>Forecast</Column>
                </Header>
                <Rows items={KPI}>
                    <PerformanceTableRow/>
                </Rows>
            </Table>
        </Card>
    }
}
