import React, { ComponentType, Fragment, PureComponent } from 'react'
import { FieldGroup } from '../elements/forms/inputs/FieldGroup'
import { Modal } from 'react-bootstrap'
import ReduxForm from '../elements/forms/ReduxForm'
import { change, Field, SubmissionError, submit } from 'redux-form'
import { Button } from '../elements/Button'
import { hideModal } from '../../actions/modal'
import { connect } from '../../utils/connect'
import styled from 'styled-components'
import {
    createPartnerBusinessUnits,
    updatePartnerBusinessUnits,
    deletePartnerBusinessUnits,
} from '../../actions/businessUnit'
import { AsyncTypeaheadGroup } from '../elements/forms/inputs/AsyncTypeaheadGroup'
import { getLocations } from '../../actions/locations'
import { locationSelector } from '../../selectors/locations'
import { SelectInputGroup } from '../elements/forms/inputs/SelectInputGroup'
import { PartnerUser } from '../../reducers/types/entities/PartnerUser'
import { toastr } from 'react-redux-toastr'
import { set, get } from 'lodash'
import { partnerUsersByPartnerSelector } from '../../state/partnerUsers/selectors'
import ToggleCheckboxGroup from '../elements/forms/inputs/ToggleCheckboxGroup'
import { getBusinessModels } from '../../state/businessModels/actions'
import { allBusinessModelsSelector } from '../../state/businessModels/selectors'
import config from '../../config'
import Placeholder from '../elements/Placeholder'
import { Flex } from '../elements/Flex'

const ModalGrid = styled.div`
  display: grid;
  gap: 20px;
  > * {
    &:nth-child(1) { grid-area: a;}
    &:nth-child(2) { grid-area: b;}
    &:nth-child(3) { grid-area: c;}
    &:nth-child(4) { grid-area: d;}
    &:nth-child(5) { grid-area: e;}
    &:nth-child(6) { grid-area: f;}
  }
  grid-template-areas: "a a b" "c c c" "d e f";
`

interface PropsEditPartnerFeeModel {
    modalProps: {
        unit
        partnerId: number
        disabled: boolean
    }
    partnerBusinessUnitsDeletePermission: object
    isLoadingLocations: boolean
    partnerUsers: PartnerUser[]
    isLoadingBusinessModels: boolean
    businessModels
    locations
    deletePartnerBusinessUnits: typeof deletePartnerBusinessUnits
    updatePartnerBusinessUnits: typeof updatePartnerBusinessUnits
    createPartnerBusinessUnits: typeof createPartnerBusinessUnits
    getBusinessModels: typeof getBusinessModels
    getLocations: typeof getLocations
    hideModal: typeof hideModal
    submit: typeof submit
    change: typeof change
}

interface StateEditPartnerFeeModel {
    initZipCode
    address
}

@connect(
    state => ({
        partnerBusinessUnitsDeletePermission: state.entities.permissions.partner_business_units_delete,
        partnerUsers: partnerUsersByPartnerSelector(state, state.modal.partnerId),
        isLoadingLocations: state.pages.locationFilter.isLoading,
        modalProps: state.modal,
        locations: locationSelector(state),
        businessModels: allBusinessModelsSelector(state),
        isLoadingBusinessModels: state.subjects.businessModels.all.isLoading,
    }),
    {
        deletePartnerBusinessUnits,
        updatePartnerBusinessUnits,
        createPartnerBusinessUnits,
        getBusinessModels,
        getLocations,
        hideModal,
        submit,
        change,
    }
)
class BusinessUnitModal extends PureComponent<PropsEditPartnerFeeModel, StateEditPartnerFeeModel> {
    constructor(props) {
        super(props)
        const unit = props.modalProps.unit
        if (unit) {
            this.props.getLocations({ query: unit.geo.zipCode })
            this.state = {
                address: unit.geo.address,
                initZipCode: [ {
                    zipCode: unit.geo.zipCode,
                    cityName: unit.geo.cityName,
                } ],
            }
        } else {
            this.state = {
                address: '',
                initZipCode: [ {
                    zipCode: '',
                    cityName: '',
                } ],
            }
        }
    }

    componentDidMount() {
        this.props.getBusinessModels({ geoCode: config.geoCode })
    }

    hideModal = () => this.props.hideModal()

    saveBusinessUnit = unit => {
        const zipMatched = this.props.locations.find(loc => loc.zipCode == unit.geo.zipCode)

        const error = {}

        if (!get(unit, 'partnerUserId'))
            set(error, 'partnerUserId', 'Partner user is required!')
        if (!get(unit, 'geo.zipCode') || !zipMatched)
            set(error, 'geo.zipCode', zipMatched ? 'Zip code is required!' : 'Zip code is not match!')
        if (!get(unit, 'geo.cityName'))
            set(error, 'geo.cityName', 'City name is required!')

        if (Object.keys(error).length) throw new SubmissionError(error)

        const partnerId = this.props.modalProps.partnerId

        const unitBody = {
            ...unit,
            disableDigitalLeads: !unit.disableDigitalLeads,
            businessModels: unit.businessModels,
        }

        if (this.props.modalProps.unit) this.props.updatePartnerBusinessUnits(partnerId, unitBody)
        else this.props.createPartnerBusinessUnits(partnerId, unitBody)
    }

    save = () => {
        this.props.submit('businessUnitForm')
    }

    delete = () => {
        const { modalProps } = this.props
        if (this.props.partnerBusinessUnitsDeletePermission)
            this.props.deletePartnerBusinessUnits(modalProps.partnerId, modalProps.unit.id)
        else
            toastr.error('Error!', 'Permission Required: "partner_business_units_delete')
    }

    getLocations = zipCode => {
        this.props.change('leadValidation', 'geo.zipCode', zipCode)
        this.props.getLocations({ zipCode })
    }

    addressChanged = (e) => {
        this.setState({ address: e.target.value })
    }

    renderZipCodeMenuItem = option => `${option.zipCode}, ${option.cityName}`

    render() {
        const {
            isLoadingLocations,
            isLoadingBusinessModels,
            partnerUsers,
            businessModels,
            modalProps,
            locations,
        } = this.props

        const businessUnit = { ...modalProps.unit }
        if (modalProps.unit) businessUnit.disableDigitalLeads = !modalProps.unit.disableDigitalLeads

        return <Modal
            show={true}
            onHide={this.hideModal}
            backdrop="static"
        >
            <Modal.Header>
                Business unit
                {modalProps.unit && <span>
                    #
                    {modalProps.unit.id}
                </span>}
            </Modal.Header>
            <Modal.Body>
                <ReduxForm
                    form="businessUnitForm"
                    onSubmit={this.saveBusinessUnit}
                    initialValues={businessUnit}
                >
                    <ModalGrid>
                        <Field
                            name="displayName"
                            label="Name of unit"
                            component={FieldGroup}
                            removeClassFormGroup={true}
                        />
                        <Field
                            label="Assigned partner user"
                            name="partnerUserId"
                            returnId={true}
                            component={SelectInputGroup}
                            removeClassFormGroup={true}
                        >
                            {partnerUsers.map(user => <option
                                key={user.id}
                                value={user.id}
                            >
                                {user.firstName} 
                                {' '}
                                {user.lastName}
                            </option>)}
                        </Field>
                        <Field
                            name="geo.address"
                            label="Address"
                            component={FieldGroup}
                            type="text"
                            onChange={this.addressChanged}
                            removeClassFormGroup={true}
                        />

                        <Field
                            component={AsyncTypeaheadGroup}
                            name="geo"
                            label="Zip code"
                            labelKey="zipCode"
                            extraData={{ address: this.state.address }}
                            isLoading={isLoadingLocations}
                            options={locations}
                            renderMenuItemChildren={this.renderZipCodeMenuItem}
                            onSearch={this.getLocations}
                            defaultSelected={this.state.initZipCode}
                        />
                        <Field
                            name="geo.cityName"
                            label="City"
                            component={FieldGroup}
                            disabled={true}
                            removeClassFormGroup
                        />
                        <Field
                            name="geo.regionName"
                            label="Region"
                            component={FieldGroup}
                            disabled={true}
                            removeClassFormGroup
                        />
                    </ModalGrid>
                    <hr />
                    {isLoadingBusinessModels
                        ? <Placeholder />
                        : <Field
                            name="businessModels"
                            component={ToggleCheckboxGroup}
                            options={businessModels}
                            titleName="abbreviation"
                        />
                    }

                </ReduxForm>
            </Modal.Body>
            <Modal.Footer>
                <Flex>
                    <Button
                        disabled={modalProps.disabled}
                        modifiers={[ 'secondary', 'mR_1' ]}
                        onClick={this.hideModal}
                    >
                        Cancel
                    </Button>
                    {modalProps.unit
                        ? <Fragment>
                            <Button
                                disabled={modalProps.disabled}
                                modifiers={[ 'action', 'mR_1' ]}
                                onClick={this.save}
                            >
                                Update
                            </Button>
                            <Button
                                disabled={modalProps.disabled}
                                modifiers={[ 'danger' ]}
                                onClick={this.delete}
                            >
                                Delete
                            </Button>
                        </Fragment>
                        : <Button
                            disabled={modalProps.disabled}
                            modifiers={[ 'action' ]}
                            onClick={this.save}
                        >
                            Create
                        </Button>
                    }
                </Flex>
            </Modal.Footer>
        </Modal>
    }
}

export default BusinessUnitModal as unknown as ComponentType
