import React, { Fragment, memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import { SelectInputGroup } from '../../elements/forms/inputs/SelectInputGroup'
import { AsyncTypeaheadGroup } from '../../elements/forms/inputs/AsyncTypeaheadGroup'
import { FieldGroup } from '../../elements/forms/inputs/FieldGroup'
import { TextareaGroup } from '../../elements/forms/inputs/TextareaGroup'
import { PartnerCategory } from '../../../reducers/types/entities/PartnerCategory'
import { CompanySize } from '../../../reducers/types/entities/CompanySize'

const CompanyDetailFields = ({
    renderZipCodeMenuItem,
    isLoadingLocations,
    partnerTitles,
    partnerCategories,
    selectZipCode,
    getLocations,
    industries,
    locations,
    employees,
    companySizes,
}) => <Fragment>
    <Row>
        <Col md={6}>
            <Field
                label="Partner consultant"
                name="employee.id"
                returnId={true}
                component={SelectInputGroup}
            >
                {employees.map(employee =>
                    <option value={employee.id}>{employee.name}</option>
                )}
            </Field>
        </Col>
        <Col md={6}>
            <Field
                label="Industry"
                name="industry.id"
                returnId={true}
                component={SelectInputGroup}
                options={industries}
            />
        </Col>
        <Col md={6}>
            {Boolean(partnerTitles.length) && <Field
                label="Title"
                name="title"
                component={SelectInputGroup}
                options={partnerTitles}
            />}
        </Col>
        <Col md={6}>
            <Field
                label="Partner category"
                component={SelectInputGroup}
                name="category.id"
                returnId={true}
            >
                <option value={0}>None</option>
                {Object.values(partnerCategories).map((cat: PartnerCategory) => <option key={cat.id} value={cat.id}>{cat.title}</option>)}
            </Field>
        </Col>
    </Row>
    <Row>
        <Col md={6}>
            <Field
                label="Company name"
                name="companyName"
                component={FieldGroup}
            />
        </Col>
        <Col md={6}>
            <Field
                label="Company size"
                name="companySize.id"
                component={SelectInputGroup}
                returnId={true}
            >
                {Object.values(companySizes).map((companySize: CompanySize) => <option key={companySize.id} value={companySize.id}>{companySize.title}</option>)}
            </Field>
        </Col>
    </Row>
    <Row>
        <Col md={6}>
            <Field
                label="Vat number"
                name="vatNumber"
                component={FieldGroup}
            />
        </Col>
        <Col md={6}>
            <Field
                label="Phone"
                name="phone"
                component={FieldGroup}
            />
        </Col>
    </Row>
    <Field
        label="Website url"
        name="website"
        component={FieldGroup}
    />
    <Field
        label="Address"
        name="geo.address"
        component={FieldGroup}
    />
    <Row>
        <Col md={6}>
            <Field
                label="Zip code"
                name="geo.zipCode"
                component={AsyncTypeaheadGroup}
                labelKey="zipCode"
                isLoading={isLoadingLocations}
                options={locations}
                renderMenuItemChildren={renderZipCodeMenuItem}
                onSearch={getLocations}
                onChange={selectZipCode}
                removeClassFormGroup
            />
        </Col>
        <Col md={6}>
            <Field
                label="City"
                name="geo.cityName"
                component={FieldGroup}
                readOnly
            />
        </Col>
    </Row>
    <Field
        label="Company information"
        name="companyInformation"
        component={TextareaGroup}
        removeClassFormGroup
    />
</Fragment>

export default memo(CompanyDetailFields)
