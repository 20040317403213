import React, { PureComponent } from 'react'
import PageTitle from '../elements/PageTitle'
import PartnerHeader from './detailPage/PartnerHeader'
import { connect } from '../../utils/connect'
import { subscriptionsSelector } from '../../selectors/subscription'
import { getPartner, savePartner } from '../../actions/partners'
import { Col, Row } from 'react-bootstrap'
import { Card } from '../blocks/Card'
import ReduxForm from '../elements/forms/ReduxForm'
import CompanyDetailFields from './companyDetailsPage/CompanyDetailFields'
import { locationSelector } from '../../selectors/locations'
import { getLocations } from '../../actions/locations'
import VoucherFields from './companyDetailsPage/VoucherFields'
import PublicProfile from './companyDetailsPage/PublicProfile'
import LoaderComponent from '../elements/LoaderComponent'
import { Button } from '../elements/Button'
import { submit, change } from 'redux-form'
import config from '../../config'
import { Subscription } from '../../reducers/types/entities/Subscription'
import { PartnerTitle } from '../../reducers/types/entities/PartnerTitles'
import { Industry } from '../../reducers/types/entities/Industry'
import { Location } from '../../reducers/types/entities/Location'
import { Partner } from '../../reducers/types/entities/Partner'
import { getEmployees } from '../../actions/employees'
import { employeesByGeoCodeSelector } from '../../selectors/employees'
import { Employee } from '../../reducers/types/entities/Employee'
import PartnerCertifications from './companyDetailsPage/PartnerCertifications'
import PartnerFeatures from './companyDetailsPage/PartnerFeatures'
import moment from 'moment'
import { getPartnerSubscriptions } from '../../actions/subscriptions'
import { getIndustries } from '../../state/industries/actions'
import { allIndustriesSelector } from '../../state/industries/selectors'
import { getPartnerTitles } from '../../state/partnerTitles/actions'
import { allPartnerTitlesSelector } from '../../state/partnerTitles/selectors'
import { getPartnerCategories } from '../../actions/partnerCategories'
import { PartnerCategories } from '../../reducers/types/entities/PartnerCategory'
import { getCompanySizes } from '../../state/companysizes/actions'
import { CompanySize } from '../../reducers/types/entities/CompanySize'
import { allCompanySizesSelector } from '../../state/companysizes/selectors'
import PartnerAssignees from './companyDetailsPage/PartnerAssignees'

interface CompanyDetailsStateProps {
    subscriptions: Subscription[]
    partnerCategories: PartnerCategories
    partnerTitles: PartnerTitle[]
    industries: Industry[]
    locations: Location[]
    employees: Employee[]
    partner: Partner
    isLoading: boolean
    isLoadingLocations: boolean
    companySizes: CompanySize[]
}
interface CompanyDetailsDispatchProps {
    getPartnerSubscriptions: typeof getPartnerSubscriptions
    getPartnerCategories: typeof getPartnerCategories
    getPartnerTitles: typeof getPartnerTitles
    getIndustries: typeof getIndustries
    getEmployees: typeof getEmployees
    getLocations: typeof getLocations
    savePartner: typeof savePartner
    getPartner: typeof getPartner
    submit: typeof submit
    change: typeof change
    getCompanySizes: typeof getCompanySizes
}
interface CompanyDetailsPathProps {
    match: {
        params: {
            partnerId
        }
    }
}

type CompanyDetailsPageModel = CompanyDetailsStateProps & CompanyDetailsDispatchProps & CompanyDetailsPathProps

@connect<CompanyDetailsStateProps, CompanyDetailsDispatchProps, CompanyDetailsPathProps>(
    (state, { match: { params: { partnerId } } }) => ({
        subscriptions: subscriptionsSelector(state, partnerId),
        partnerCategories: Object.values(state.entities.partnerCategories),
        partnerTitles: allPartnerTitlesSelector(state),
        industries: allIndustriesSelector(state),
        locations: locationSelector(state),
        employees: employeesByGeoCodeSelector(state, config.geoCode),
        partner: state.entities.partners[partnerId],
        isLoading: state.pages.partnerDetail.isLoading,
        isLoadingLocations: state.pages.locationFilter.isLoading,
        companySizes: allCompanySizesSelector(state),
    }),
    {
        getPartnerSubscriptions,
        getPartnerCategories,
        getPartnerTitles,
        getIndustries,
        getEmployees,
        getLocations,
        savePartner,
        getPartner,
        submit,
        change,
        getCompanySizes
    }
)
export default class CompanyDetailsPage extends PureComponent<CompanyDetailsPageModel> {
    state = {
        company: null,
    }
    componentDidMount() {
        const { partnerId } = this.props.match.params

        const resPartner: any = this.props.getPartner(partnerId)

        this.props.getPartnerSubscriptions(partnerId, { isActive: 1 })
        resPartner.then(() => {
            const partner = this.props.partner
            const geoCode = partner.geo.code
            this.props.getIndustries({ geoCode })
            this.props.getEmployees({
                sort: 'first_name',
                isActive: '1',
                geoCode,
            })

            this.props.getPartnerCategories(geoCode)

            this.props.getPartnerTitles({
                industryId: this.props.partner.industry.id,
                geoCode,
            })

            this.props.getCompanySizes(geoCode)

            this.setState({ company: {
                id: partner.id,
                type: partner.state,
                employee: { id: partner.employee && partner.employee.id },
                category: { id: partner.category ? partner.category.id : 0 },
                industry: { id: partner.industry.id },
                title: partner.title,
                companyName: partner.companyName,
                vatNumber: partner.vatNumber,
                phone: partner.phone,
                website: partner.website,
                geo: partner.geo,
                companyInformation: partner.companyInformation,
                invoicing: partner.invoicing,
                companySize: partner.companySize,
            } })
        })
    }

    renderZipCodeMenuItem = option => `${option.zipCode}, ${option.cityName}`

    getLocations = zipCode => {
        this.props.getLocations({ zipCode })
    }

    getVoucherLocations = zipCode => {
        this.props.getLocations({ zipCode })
    }

    selectZipCode = (location) => {
        if (location) setTimeout(() => {
            this.props.change('companyDetailsForm', 'geo.cityName', location.cityName)
            this.props.change('companyDetailsForm', 'geo.zipCode', location.zipCode)
            this.props.change('companyDetailsForm', 'geo.point', location.point)
        })
    }

    selectVoucherZipCode = location => {
        if (location) setTimeout(() => {
            this.props.change('companyDetailsForm', 'invoicing.cityName', location.cityName)
            this.props.change('companyDetailsForm', 'invoicing.zipCode', location.zipCode)
            this.props.change('companyDetailsForm', 'invoicing.point', location.point)
        })
    }

    saveCompany = data => {
        this.props.savePartner(data)
    }

    submit = () => this.props.submit('companyDetailsForm')

    render() {
        const {
            subscriptions,
            partnerCategories,
            isLoadingLocations,
            partnerTitles,
            industries,
            employees,
            locations,
            partner,
            companySizes,
        } = this.props

        if (!this.state.company) return <LoaderComponent type="logo" />
        const createdSubscription = subscriptions.find(sub => sub.startsAt && moment().isBefore(sub.expiresAt))

        return <div>
            <PageTitle title={partner.companyName} />
            <PartnerHeader partner={partner} subscription={createdSubscription}/>
            <Row>
                <Col md={6}>
                    <Card>
                        <ReduxForm
                            form="companyDetailsForm"
                            initialValues={this.state.company}
                            onSubmit={this.saveCompany}
                        >
                            <Card.Header>Company details</Card.Header>
                            <Card.Content>
                                <CompanyDetailFields
                                    isLoadingLocations={isLoadingLocations}
                                    partnerTitles={partnerTitles}
                                    partnerCategories={partnerCategories}
                                    locations={locations}
                                    employees={employees}
                                    industries={industries}
                                    renderZipCodeMenuItem={this.renderZipCodeMenuItem}
                                    getLocations={this.getLocations}
                                    selectZipCode={this.selectZipCode}
                                    companySizes={companySizes}
                                />
                            </Card.Content>
                            <Card.Line/>
                            <Card.Header>Voucher address</Card.Header>
                            <Card.Content>
                                <VoucherFields
                                    isLoadingLocations={isLoadingLocations}
                                    locations={locations}
                                    renderZipCodeMenuItem={this.renderZipCodeMenuItem}
                                    getLocations={this.getVoucherLocations}
                                    selectZipCode={this.selectVoucherZipCode}
                                />
                            </Card.Content>
                        </ReduxForm>
                        <Card.Content>
                            <Button modifiers={[ 'action' ]} onClick={this.submit}>Save</Button>
                        </Card.Content>
                    </Card>
                </Col>
                <Col md={6}>
                    <PartnerCertifications
                        partnerId={partner.id}
                        industryId={partner.industry.id}
                        geoCode={partner.geo.code}
                    />
                    {partner.geo.code === 'dk' && <PartnerFeatures
                        partnerId={partner.id}
                        partnerFeatures={partner.features}
                    />}
                    <PartnerAssignees
                        partnerId={partner.id}
                        geoCode={partner.geo.code}
                    />
                    <PublicProfile
                        partnerId={partner.id}
                    />
                </Col>
            </Row>

        </div>
    }
}
