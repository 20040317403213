import { combineReducers } from 'redux'

import allocationLimits from './allocationsLimits/reducer'
import allocations from './allocations/reducer'
import allocationsQuotas from './allocationsQuotas/reducer'
import businessModels from './businessModels/reducer'
import certifications from './certifications/reducer'
import cMatches from './cMatches/reducer'
import geoRegions from './geoRegions/reducer'
import industries from './industries/reducer'
import invoices from './invoices/reducer'
import leadTypes from './leadTypes/reducer'
import notifications from './notifications/reducer'
import partnerTitles from './partnerTitles/reducer'
import partnerUsers from './partnerUsers/reducer'
import pauseSubscriptions from './pauseSubscriptions/reducer'
import sectors from './sectors/reducer'
import segmentationSubGeoRegions from './segmentationSubGeoRegions/reducer'

const subjects = combineReducers({
    allocationLimits,
    allocations,
    allocationsQuotas,
    businessModels,
    certifications,
    cMatches,
    geoRegions,
    industries,
    invoices,
    leadTypes,
    notifications,
    partnerTitles,
    partnerUsers,
    pauseSubscriptions,
    sectors,
    segmentationSubGeoRegions,
})

export default subjects
