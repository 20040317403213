import React, { PureComponent } from 'react'
import { Card } from '../blocks/Card'
import { toastr } from 'react-redux-toastr'
import BusinessUnitsTable from './send-email-to-partners-regions-components/BusinessUnitsTable'
import SegmentationRegionsMap from './send-email-to-partners-regions-components/SegmentationRegionsMap'
import ProviderTable from '../blocks/ProviderTable'
import { connect } from '../../utils/connect'
import { getLead, saveLead, getIntervalLeads } from '../../actions/leads'
import { getPartnerQuotes } from '../../actions/quotes'
import { Lead } from '../../reducers/types/entities/Lead'
import { BusinessUnit } from '../../reducers/types/entities/BusinessUnit'
import { updateLeadSegmentationSubGeoRegions } from '../../state/segmentationSubGeoRegions/actions'
import LoaderComponent from '../elements/LoaderComponent'
import { getPartnerBusinessUnits } from '../../actions/businessUnit'
import { Flex } from '../elements/Flex'
import { Button } from '../elements/Button'
import { push } from 'connected-react-router'
import { leadAllocationCreate } from '../../state/allocations/actions'
import { hideModal, showModal } from '../../actions/modal'
import { determineDayPart, returnLocalTimezone } from '../../utils'
import requireOverdelivery from '../business-unit/requireOverdelivery'
import moment from 'moment'

interface Criteria {
    sort: string
}

export interface FormattedBusinessUnit extends BusinessUnit {
    requiresOverdelivery: boolean
    isOnPause: boolean
    isBoosted: boolean
    isPartOfSelectedRegions?: boolean
    index: number
}

export interface Props {
    lead: Lead
    criteria: Criteria
    hasPermissionToOverdeliver: boolean
}

export interface State {
    businessUnits: BusinessUnit[]
    isLoadingBusinessUnits: boolean
    selectedBusinessUnits: number[]
    selectedPartners: number[]
    selectedSubGeoRegions: number[]
    disableCheckActions: boolean
    submitting: boolean
    showMap: boolean
}

export interface Actions {
    getIntervalLeads: typeof getIntervalLeads
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    leadAllocationCreate: typeof leadAllocationCreate
    push: typeof push
    updateLeadSegmentationSubGeoRegions: typeof updateLeadSegmentationSubGeoRegions
    saveLead: typeof saveLead
    getLead: typeof getLead
    getPartnerQuotes: any
    hideModal: typeof hideModal
    showModal: typeof showModal
}

export interface PathProps {
    match: {
        params: {
            leadId: number
        }
    }
    location: {
        query: {
            sort?: string
        }
    }
}

export type Model = Props & Actions & PathProps

@connect<
    Props,
    Actions,
    PathProps
>(
    (state, props) => ({
        lead: state.entities.leads[props.match.params.leadId],
        criteria: {
            sort: props.location.query.sort || '',
        },
        hasPermissionToOverdeliver: state.auth.permissions.includes('leads_allocate_with_overdelivery'),
    }),
    {
        getIntervalLeads,
        getPartnerBusinessUnits,
        leadAllocationCreate,
        push,
        updateLeadSegmentationSubGeoRegions,
        saveLead,
        getLead,
        getPartnerQuotes,
        hideModal,
        showModal,
    }
)
export default class SendEmailToPartnersRegionsPage extends PureComponent<Model, State> {
    state = {
        selectedSubGeoRegions: [],
        businessUnits: [],
        selectedBusinessUnits: [],
        selectedPartners: [],
        isLoadingBusinessUnits: false,
        disableCheckActions: false,
        submitting: false,
        showMap: true,
    } as State

    async componentDidMount() {
        this.props.getLead(this.props.match.params.leadId)
        await this.getBusinessUnits()
    }

    async getBusinessUnits() {
        await this.setState({ isLoadingBusinessUnits: true })
        const businessUnitResponse: any = await this.props.getPartnerBusinessUnits({
            isActive: '1',
            hasBusinessModel: '1',
            segmentationCriteria: [
                'sectors',
                'industries',
                'certifications',
                'languages',
                'client_types',
                'digital_work_preferences',
                'revenues',
                'fees',
                'exclusions',
                'primary_industries',
                'locations',
                'company_sizes',
                'accounting_software',
                'not_already_allocated',
                'is_start_up',
            ].join(','),
            segmentedForLeadId: this.props.match.params.leadId,
            limit: 5000,
            sort: 'auto_allocation',
        })
        this.setState({
            isLoadingBusinessUnits: false,
            businessUnits: Object.values(businessUnitResponse.result.map(id => businessUnitResponse.entities.businessUnits[id]) || {}),
        })
    }

    saveLead = () => {
        this.props.updateLeadSegmentationSubGeoRegions(
            this.props.match.params.leadId,
            this.state.selectedSubGeoRegions
        )
    }

    selectSubGeoRegions = selectedSubGeoRegions => {
        this.setState({ selectedSubGeoRegions })
    }

    redirectToValidationPage = async () => {
        if (this.props.lead.status !== 'validated') {
            // In case this page was visited through auto allocation
            this.props.push(`/leads/${this.props.lead.id}/validation`)
            return
        }
        const res: any = await this.props.getIntervalLeads({ limit: '1', latestCalledAtBefore: determineDayPart() })
        const leadId = res.result[0]
        if (leadId) this.props.push(`/leads/${leadId}/validation`)
        else window.location.href = `/leads/validation?timezone=${returnLocalTimezone()}`
    }

    sendEmail = async () => {
        const { selectedBusinessUnits } = this.state
        try {
            await this.setState({ submitting: true })
            await Promise.all([
                this.saveLead(),
                this.props.leadAllocationCreate(this.props.lead.id, {
                    leadAllocations: Object.values(selectedBusinessUnits),
                }),
            ])
            this.redirectToValidationPage()
        } catch (e) {
            toastr.error('Error!', e)
            return
        } finally {
            this.setState({ submitting: false })
        }
    }

    selectBusinessUnit = async item => {
        const { max, count, reserved } = this.props.lead.quotesInfo
        // @ts-ignore
        this.setState(prevState => ({
            selectedBusinessUnits: {
                ...prevState.selectedBusinessUnits,
                [item.id]: {
                    partnerBusinessUnitId: item.id,
                    allocationType: item.overdelivery ? 'overdelivery' : 'any',
                },
            },
            selectedPartners: [ ...new Set([ ...prevState.selectedPartners, item.partnerId ]) ],
        }), () => {
            this.setState({
                disableCheckActions: Object.values(this.state.selectedBusinessUnits).length >= max - count - reserved,
            })
        })
    }

    isBusinessUnitOnPause = bu => {
        return bu.partnerContract &&
            bu.partnerContract.allocationPauses &&
            bu.partnerContract.allocationPauses
                .some(pause => moment(pause.startsAt).isBefore(moment()) && moment(pause.endsAt).isAfter(moment()))
    }

    isBusinessUnitInPeriod = bu => {
        return bu.partnerContract &&
            bu.partnerContract.allocationBoosts &&
            bu.partnerContract.allocationBoosts
                .some(boost => moment(boost.startsAt).isBefore(moment()) && moment(boost.endsAt).isAfter(moment()))
    }

    deselectBusinessUnit = async item => {
        const selectedBusinessUnits = { ...this.state.selectedBusinessUnits }
        const selectedPartners: Set<number> = new Set(this.state.selectedPartners)

        selectedPartners.delete(item.partnerId)

        delete selectedBusinessUnits[item.id]

        const { max, count, reserved } = this.props.lead.quotesInfo
        return this.setState({
            selectedBusinessUnits,
            selectedPartners: [ ...selectedPartners ],
            disableCheckActions: Object.values(selectedBusinessUnits).length >= max - count - reserved
        })
    }

    toggleMap = () => this.setState({ showMap: !this.state.showMap })

    render() {
        const {
            hasPermissionToOverdeliver,
            lead,
        } = this.props

        const { max, count, reserved } = lead ? lead.quotesInfo : { max: 0, count: 0, reserved: 0 }

        const {
            businessUnits,
            isLoadingBusinessUnits,
            disableCheckActions,
            selectedBusinessUnits,
            selectedPartners,
            selectedSubGeoRegions,
            submitting,
            showMap,
        } = this.state

        if (!lead) {
            return <LoaderComponent type="logo"/>
        }

        const formattedBusinessUnits = businessUnits
            .map((bu: BusinessUnit, index) => {
                const businessUnit: any = { ...bu }
                businessUnit.requiresOverdelivery = requireOverdelivery(bu)
                businessUnit.isOnPause = this.isBusinessUnitOnPause(bu)
                businessUnit.isBoosted = this.isBusinessUnitInPeriod(bu)
                businessUnit.isPartOfSelectedRegions = selectedSubGeoRegions.includes(bu.geo?.homeSubGeoRegion?.id as number)
                businessUnit.index = index
                return businessUnit as FormattedBusinessUnit
            })

        return (
            <Card margin="0">
                <Card.Header modifiers={[ 'justifySpaceBetween', 'alignCenter' ]} height="auto">
                    <span>
                        {lead.status === 'validated' ? 'Send lead to partners' : 'Allocate to partners'}
                        {` (${this.state.selectedPartners.length} / ${max - count - reserved})`}
                    </span>
                    <Button
                        modifiers="primary"
                        onClick={this.toggleMap}
                    >
                        {showMap ? 'Hide' : 'Show'}
                        {' '}
                        regions selection map
                    </Button>
                </Card.Header>
                <ProviderTable pageName={showMap && 'send-email'} loading={isLoadingBusinessUnits} criteria={this.props.criteria}>
                    <BusinessUnitsTable
                        sort={this.props.criteria.sort}
                        lead={lead}
                        businessUnits={formattedBusinessUnits}
                        numberOfSelectedRegions={selectedSubGeoRegions.length}
                        disableCheckActions={disableCheckActions}
                        selectedBusinessUnits={selectedBusinessUnits}
                        selectedPartners={selectedPartners}
                        submitting={submitting}
                        selectBusinessUnit={this.selectBusinessUnit}
                        deselectBusinessUnit={this.deselectBusinessUnit}
                        hideModal={this.props.hideModal}
                        showModal={this.props.showModal}
                        hasPermissionToOverdeliver={hasPermissionToOverdeliver}
                    />
                    <SegmentationRegionsMap
                        showMap={showMap}
                        lead={lead}
                        businessUnits={businessUnits}
                        selectSubGeoRegions={this.selectSubGeoRegions}
                        selectedSubGeoRegions={selectedSubGeoRegions}
                    />
                </ProviderTable>
                <Card.Content>
                    <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                        <Button
                            modifiers={[ 'action', 'pullRight', 'mT_2', 'mR_2' ]}
                            onClick={this.sendEmail}
                            disabled={this.state.submitting || !this.state.selectedPartners.length}
                        >
                            {lead.status === 'validated' ? 'Send lead to partners' : 'Allocate to partners'}
                        </Button>
                    </Flex>
                </Card.Content>
            </Card>
        )
    }
}
