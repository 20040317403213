import { createSelector } from 'reselect'

const entitiesPartnerTitles = state => state.entities.partnerTitles

const allPartnerTitlesId = state => state.subjects.partnerTitles.all.ids

export const allPartnerTitlesSelector = createSelector(
    allPartnerTitlesId, entitiesPartnerTitles,
    (ids, partnerTitles) => ids.map(id => partnerTitles[id])
)
