import styled from 'styled-components'
import { borderColor } from '../../../utils/variables'

const PinnedNotesBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 34px;

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        background-color: ${borderColor};
    }
`

export default PinnedNotesBottom
