import React, { ComponentType, PureComponent } from 'react'
import { Card } from '../../blocks/Card'
import { connect } from '../../../utils/connect'

import Placeholder from '../../elements/Placeholder'
import { values } from 'lodash'
import { PartnerUniqueSellingPoint } from '../../../reducers/types/entities/PartnerUniqueSellingPoint'
import { getPartnerSellingPoints } from '../../../actions/partners'
import { RoundCheckBox } from '../../elements/RoundCheckBox'
import { Button } from '../../elements/Button'
import styled from 'styled-components'

const StyledPoint = styled.div`
    display: flex;
    padding-bottom: 10px;
`
const StyledPointText = styled.div`
    padding-top: 5px;
`

interface StatePropsPoint {
    isLoadingSellingPoints: boolean
    points: PartnerUniqueSellingPoint[]
}
interface DispatchPropsPoint {
    getPartnerSellingPoints: typeof getPartnerSellingPoints
}
interface PathPropsPoint {
    editPartnerUniqueSellingPoints
    partnerId: number
}

type SellingPointListCardModel = StatePropsPoint & DispatchPropsPoint & PathPropsPoint

@connect<StatePropsPoint, DispatchPropsPoint, PathPropsPoint>(
    state => ({
        isLoadingSellingPoints: state.pages.partnerDetail.isLoadingSellingPoints,
        points: values(state.entities.partnerUniqueSellingPoints),
    }),
    {
        getPartnerSellingPoints,
    },
)
class SellingPointListPartnerCard extends PureComponent<SellingPointListCardModel> {
    render() {
        const {
            isLoadingSellingPoints,
            partnerId,
            points,
            editPartnerUniqueSellingPoints
        } = this.props

        return (
            <Card className="sellingPointList">
                <Card.Header>Unique Selling Points</Card.Header>
                <Card.Content
                    flex={1}
                    justifyContent="space-between"
                >
                    {isLoadingSellingPoints
                        ? <Placeholder counter={6} portrait={false} />
                        : points.map(point => <StyledPoint>
                            <RoundCheckBox type="circle" modifiers="mR_1"/>
                            <StyledPointText key={point.id}>
                                <Card.Text modifiers={[ 'noMargin' ]}>
                                    {point.text}
                                </Card.Text>
                            </StyledPointText>
                        </StyledPoint>)
                    }
                    {!points.length  && <span>No Selling Points of this partner.</span>
                    }
                    <Button
                        modifiers={[ 'secondary', 'p_1', 'fullWidth', 'btnBlock' ]}
                        onClick={editPartnerUniqueSellingPoints}
                    >
                        Edit unique selling points
                    </Button>
                </Card.Content>
            </Card>
        )
    }
}

export default SellingPointListPartnerCard as unknown as ComponentType<PathPropsPoint>
