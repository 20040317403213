import React, { memo } from 'react'
import styled from 'styled-components'

export const Star = styled.div`
    width: 18px;
    height: 18px;
    display: inline-block;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+DQo8c3ZnIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KIDxnPg0KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+DQogIDxwYXRoIGZpbGw9IiMwMGE0ZWIiIGlkPSJzdmdfMSIgZD0ibTI2LjQzOSw5NS42MDFjLTUuNjA4LDIuOTQ5IC05LjI4NiwwLjI3NiAtOC4yMTYsLTUuOTY4bDQuNSwtMjYuMjM3bC0xOS4wNjEsLTE4LjU4Yy00LjUzNywtNC40MjMgLTMuMTMyLC04Ljc0NiAzLjEzNywtOS42NTdsMjYuMzQzLC0zLjgyOWwxMS43ODEsLTIzLjg3YzIuODA0LC01LjY4MiA3LjM1LC01LjY4MiAxMC4xNTQsMGwxMS43OCwyMy44N2wyNi4zNDMsMy44MjljNi4yNywwLjkxMSA3LjY3NCw1LjIzNCAzLjEzOCw5LjY1N2wtMTkuMDYxLDE4LjU4MWw0LjUwMSwyNi4yMzdjMS4wNyw2LjI0NCAtMi42MDgsOC45MTYgLTguMjE2LDUuOTY4bC0yMy41NjIsLTEyLjM4N2wtMjMuNTYxLDEyLjM4NnoiLz4NCiA8L2c+DQo8L3N2Zz4=);
    background-size: contain;
    margin-right: 4px;
`

export const EmptyStar = styled(Star)`
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPHBhdGggZmlsbD0iI2Q1ZDVkNSIgaWQ9InN2Z18xIiBkPSJtMjYuNDM5LDk1LjYwMWMtNS42MDgsMi45NDkgLTkuMjg2LDAuMjc2IC04LjIxNiwtNS45NjhsNC41LC0yNi4yMzdsLTE5LjA2MSwtMTguNThjLTQuNTM3LC00LjQyMyAtMy4xMzIsLTguNzQ2IDMuMTM3LC05LjY1N2wyNi4zNDMsLTMuODI5bDExLjc4MSwtMjMuODdjMi44MDQsLTUuNjgyIDcuMzUsLTUuNjgyIDEwLjE1NCwwbDExLjc4LDIzLjg3bDI2LjM0MywzLjgyOWM2LjI3LDAuOTExIDcuNjc0LDUuMjM0IDMuMTM4LDkuNjU3bC0xOS4wNjEsMTguNTgxbDQuNTAxLDI2LjIzN2MxLjA3LDYuMjQ0IC0yLjYwOCw4LjkxNiAtOC4yMTYsNS45NjhsLTIzLjU2MiwtMTIuMzg3bC0yMy41NjEsMTIuMzg2eiIvPgogPC9nPgo8L3N2Zz4=);
`

export const Stars = memo(({ filled = 0, total = 0 }: {filled:number, total: number}) => {
    const result = []
    for (let i = 0; i < filled; i++) {
        result.push(<Star key={i} />)
    }
    for (let i = filled; i < total; i++) {
        result.push(<EmptyStar key={i} />)
    }

    return <div>{ result }</div>
})
