import React, { ComponentType, memo, PureComponent } from 'react'
import { Card } from '../../blocks/Card'
import GeoRegionsMap from '../../elements/GeoRegionsMap'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { connect } from '../../../utils/connect'
import {
    allSegmentationSubGeoRegionsSelector,
    leadSegmentationSubGeoRegionsSelector,
} from '../../../state/segmentationSubGeoRegions/selectors'
import {
    getLeadSegmentationSubGeoRegions,
    getSegmentationSubGeoRegions,
} from '../../../state/segmentationSubGeoRegions/actions'
import { getPartnerBusinessUnits } from '../../../actions/businessUnit'
import { getGeoRegions } from '../../../state/geoRegions/actions'
import { SegmentationSubGeoRegion } from '../../../reducers/types/entities/SegmentationSubGeoRegion'
import { GeoRegions } from '../../../reducers/types/entities/GeoRegion'
import { Lead } from '../../../reducers/types/entities/Lead'
import config from '../../../config'
import { CheckboxInputField } from '../../elements/forms/inputs/CheckboxGroup'
const MapElement = styled.div`
  height: 400px;
`

const RegionsUl = styled.ul`
  list-style: none;
  padding: 10px 0;
  max-height: 400px;
  overflow-y: auto;
  li {
    margin: 5px 0;
  }
`

const SubRegion = memo((props: any) =>
    <li>
        <CheckboxInputField
            text={props.subGeoRegion.subRegionName}
            input={{
                disabled: true,
                value: props.leadSubGeoRegionsIds.includes(props.subGeoRegion.id),
            }}
        />
    </li>
)

export interface Props {
    leadSubGeoRegions: SegmentationSubGeoRegion[]
    leadSubGeoRegionsIds: number[]
    subGeoRegions: SegmentationSubGeoRegion[]
    geoRegions: GeoRegions
    leadSubGeoRegionsIsLoading: boolean
}

export interface Actions {
    getLeadSegmentationSubGeoRegions: typeof getLeadSegmentationSubGeoRegions
    getSegmentationSubGeoRegions: typeof getSegmentationSubGeoRegions
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getGeoRegions: typeof getGeoRegions
}

export interface PathProps {
    lead: Lead
}
export type Model = Props & Actions & PathProps

@connect<Props, Actions, PathProps>(
    state => ({
        leadSubGeoRegions: leadSegmentationSubGeoRegionsSelector(state),
        leadSubGeoRegionsIds: state.subjects.segmentationSubGeoRegions.lead.ids,
        leadSubGeoRegionsIsLoading: state.subjects.segmentationSubGeoRegions.all.isLoading,
        subGeoRegions: allSegmentationSubGeoRegionsSelector(state),
        geoRegions: state.entities.geoRegions,
    }),
    {
        getLeadSegmentationSubGeoRegions,
        getSegmentationSubGeoRegions,
        getPartnerBusinessUnits,
        getGeoRegions,
    }
)
class GeographyCard extends PureComponent<Model> {
    componentDidMount(): void {
        this.props.getLeadSegmentationSubGeoRegions(this.props.lead.id)
        const resGeoRegions: any  = this.props.getGeoRegions({ geoCode: config.geoCode })
        resGeoRegions.then(res =>
            this.props.getSegmentationSubGeoRegions({ segmentationGeoRegionId: res.result })
        )
    }

    render() {
        const {
            leadSubGeoRegionsIsLoading,
            leadSubGeoRegionsIds,
            leadSubGeoRegions,
            subGeoRegions,
            geoRegions,
            lead,
        } = this.props

        return <Card>
            <Card.Header>Geography</Card.Header>
            <Card.Content isLoading={leadSubGeoRegionsIsLoading}>
                <Row>
                    <Col md={8}>
                        <GeoRegionsMap
                            subGeoRegions={leadSubGeoRegions}
                            geoRegions={geoRegions}
                            containerElement={<MapElement/>}
                            mapElement={<MapElement/>}
                            zoomPoint={lead.geo.point}
                            singleMarker={lead}
                        />
                    </Col>
                    <Col md={4}>
                        <RegionsUl>
                            {subGeoRegions.map(subGeoRegion =>
                                <SubRegion
                                    key={subGeoRegion.id}
                                    subGeoRegion={subGeoRegion}
                                    leadSubGeoRegionsIds={leadSubGeoRegionsIds}
                                />
                            )}
                        </RegionsUl>
                    </Col>
                </Row>
            </Card.Content>
        </Card>
    }
}

export default GeographyCard as unknown as ComponentType<PathProps>
