import React, { memo } from 'react'
import { FormGroup, InputGroup } from 'react-bootstrap'
import ControlLabel from '../../text/MetaLabel'
import FormControl from '../../FormControl'
import Addon from '../../Addon'
import { WrappedFieldProps } from 'redux-form'
import Error from '../Error'
import { FieldInputProps } from 'formik'
import get from 'lodash/get'

interface FieldGroupModel<Value> extends WrappedFieldProps {
    removeClassFormGroup?: boolean
    shouldMatch?: string
    className?: string
    label?: string
    addon?: string
    field?: FieldInputProps<Value> | any
    form?: any
    showErrorInfo?: boolean
}

export const FieldGroup = memo(({
    removeClassFormGroup,
    className,
    input,
    field,
    form,
    label,
    addon,
    showErrorInfo,
    meta,
    ...props
}: FieldGroupModel<any>) =>
    <FormGroup
        bsClass={(removeClassFormGroup ? '' : 'form-group ') + (className || '')}
        validationState={
            meta && meta.touched && (meta.error || meta.warning) ||
            get(form, `errors.${get(field, 'name')}`)
                ? 'error'
                : null
        }
    >
        {label && <ControlLabel>{label}</ControlLabel>}
        {addon
            ? <InputGroup>
                <FormControl {...input} {...props} {...field} />
                <Addon>{addon}</Addon>
            </InputGroup>
            : <FormControl {...input} {...props} {...field}/>
        }
        {showErrorInfo && <Error meta={meta}/>}
    </FormGroup>
)
