import { Col, Row } from 'react-bootstrap'
import GeoRegionsMap from '../../elements/GeoRegionsMap'
import { Card } from '../../blocks/Card'
import { CheckboxInputField } from '../../elements/forms/inputs/CheckboxGroup'
import React, { ComponentType, PureComponent } from 'react'
import config from '../../../config'
import { connect } from '../../../utils/connect'
import {
    getLeadSegmentationSubGeoRegions,
    getSegmentationSubGeoRegions,
} from '../../../state/segmentationSubGeoRegions/actions'
import { getPartnerBusinessUnits, resetPartnerBusinessUnits } from '../../../actions/businessUnit'
import { getGeoRegions } from '../../../state/geoRegions/actions'
import styled from 'styled-components'
import { SegmentationSubGeoRegions } from '../../../reducers/types/entities/SegmentationSubGeoRegion'
import { GeoRegions } from '../../../reducers/types/entities/GeoRegion'
import { BusinessUnit } from '../../../reducers/types/entities/BusinessUnit'
import ColorHash from 'color-hash'
import { Flex } from '../../elements/Flex'
import queryString from 'query-string'
import { replace } from 'connected-react-router'
import { Lead } from '../../../reducers/types/entities/Lead'
import { borderColor } from '../../../utils/variables'
import get from 'lodash/get'

const regionColor = (id) => new ColorHash().hex(id)

const MapElement = styled.div`
  height: 30vh;
`

const RegionLabel = styled.div<{regionId: number}>`
  height: 20px;
  width: 20px;
  padding: 2px 9px;
  border-radius: 5px;
  margin-right: 1em;
  background-color: ${({ regionId }) => regionColor(regionId)}80;
  border: 1px solid ${({ regionId }) => regionColor(regionId)}A6;
`

const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
  max-height: 40vh;
  overflow-y: scroll;

  li {
    margin: 5px 0;
  }
`

const CheckboxText = styled(Flex)<{active: boolean}>`
  cursor: pointer;
  ${props => props.active && 'background-color:  #d4ecff'};
  &:hover {
    background-color:  #edf7ff;
  }
`

const MapContent = styled.div`
  border-left: 1px solid ${borderColor};
`

export interface Props {
    subGeoRegions: SegmentationSubGeoRegions
    geoRegions: GeoRegions
    geoRegionsId: number[]
    segmentationSubGeoRegionsId: number[]
    activeRowId: number
}

export interface Actions {
    getLeadSegmentationSubGeoRegions: typeof getLeadSegmentationSubGeoRegions
    getSegmentationSubGeoRegions: typeof getSegmentationSubGeoRegions
    resetPartnerBusinessUnits: typeof resetPartnerBusinessUnits
    getPartnerBusinessUnits: typeof getPartnerBusinessUnits
    getGeoRegions: typeof getGeoRegions
    replace: typeof replace
}

export interface PathProps {
    showMap?: boolean
    lead: Lead
    businessUnits: BusinessUnit[]
    selectSubGeoRegions: (any) => void
    selectedSubGeoRegions: number[]
}

export interface State {
    selectedRegionId: number
}

export type Model = Props & Actions & PathProps

@connect<Props, Actions, PathProps>(
    state => ({
        subGeoRegions: state.entities.segmentationSubGeoRegions,
        geoRegions: state.entities.geoRegions,
        geoRegionsId: state.subjects.geoRegions.all.ids,
        segmentationSubGeoRegionsId: state.subjects.segmentationSubGeoRegions.all.ids,
        activeRowId: state.router.location.query.activeRowId,
    }),
    {
        getLeadSegmentationSubGeoRegions,
        getSegmentationSubGeoRegions,
        resetPartnerBusinessUnits,
        getPartnerBusinessUnits,
        getGeoRegions,
        replace,
    }
)
class SegmentationRegionsMap extends PureComponent<
    Model,
    State
> {
    state = {
        selectedRegionId: null,
    }

    componentDidMount() {
        this.getData()
    }

    selectedPolygons = (subGeoRegions, checked) => {
        return this.props.geoRegionsId
            .reduce((acc, id) => {
                const reduceCheckedSubRegions = (regionId) => subGeoRegions
                    .reduce((acc2, subGeoRegion) =>
                        subGeoRegion.segmentationGeoRegionId === regionId
                            ? ({ ...acc2, [subGeoRegion.id]: checked })
                            : acc2,
                    {})
                return ({
                    ...acc,
                    [id]: {
                        subRegions: {
                            ...reduceCheckedSubRegions(id),
                        },
                    },
                })
            }, {})
    }

    async getData() {
        await this.props.getGeoRegions({ geoCode: config.geoCode })

        const leadSubGeoRegionsResponse: any = await this.props.getLeadSegmentationSubGeoRegions(this.props.lead.id)
        const leadSubGeoRegionIds = leadSubGeoRegionsResponse.result
        const leadSubGeoRegions = leadSubGeoRegionsResponse.entities.segmentationSubGeoRegions

        await this.props.getSegmentationSubGeoRegions({
            segmentationGeoRegionId: this.props.geoRegionsId,
        })

        if (leadSubGeoRegionIds.length) {
            leadSubGeoRegionIds.forEach(id => {
                this.onChangeRegions(true, leadSubGeoRegions[id].segmentationGeoRegionId, id)
            })
        } else {
            await this.selectAll(true)
        }
    }

    onChangeRegions = async (checked: boolean, regionId: number, subRegionId?: number) => {
        await this.props.selectSubGeoRegions(
            Object.values(this.props.subGeoRegions)
                .filter(r => {
                    if (subRegionId) {
                        return r.id === subRegionId ? checked : this.props.selectedSubGeoRegions.includes(r.id)
                    }
                    if (r.segmentationGeoRegionId === regionId) {
                        return checked
                    }
                    return this.props.selectedSubGeoRegions.includes(r.id)
                })
                .map(r => r.id)
        )
    }

    selectAll = async (checked: boolean) => {
        await this.props.selectSubGeoRegions(
            checked ? Object.keys(this.props.subGeoRegions).map(id => parseInt(id)) : []
        )
    }

    showSubRegions = selectedRegionId => {
        this.setState({ selectedRegionId })
    }

    onPinClick = activeRowId => {
        this.props.replace({
            pathname: window.location.pathname,
            search: queryString.stringify({ activeRowId }),
        })
    }

    render() {
        const {
            showMap,
            businessUnits,
            activeRowId,
            geoRegions,
            subGeoRegions,
            geoRegionsId,
            lead,
            selectedSubGeoRegions,
        } = this.props

        const { selectedRegionId } = this.state

        let geoPoint = get(lead, 'geo.point', { lat: 0, lon: 0 })
        if (geoPoint.lat === 0 && geoPoint.lon === 0) {
            geoPoint = config.centers[config.geoCode]
        }

        if (!showMap) {
            return null
        }

        return <MapContent>
            <GeoRegionsMap
                subGeoRegions={selectedSubGeoRegions.map(id => subGeoRegions[id])}
                geoRegions={geoRegions}
                containerElement={<MapElement/>}
                mapElement={<MapElement/>}
                showBusinessUnits={true}
                businessUnits={businessUnits}
                selectedId={activeRowId}
                onPinClick={this.onPinClick}
                zoomPoint={geoPoint}
                zoom={6}
            />
            <Card.Header>Segmentation regions</Card.Header>
            <Card.Content>
                <Row>
                    <Col md={6}>
                        <Ul>
                            <li>
                                <CheckboxInputField
                                    modifiers="alignCenter"
                                    text={<Card.Text modifiers={[ 'm_0' ]}><b>Select all</b></Card.Text>}
                                    input={{
                                        value: selectedSubGeoRegions.length === Object.keys(subGeoRegions).length ? true
                                            : (selectedSubGeoRegions.length > 0 ? 'partially' : false),
                                        onChange: e => this.selectAll(e.target.checked),
                                    }}
                                />
                            </li>
                            <li><Card.Label>Main regions</Card.Label></li>
                            {geoRegionsId.map(regionId =>
                                <li
                                    key={'regionId_' + regionId}
                                    onClick={() => this.showSubRegions(regionId)}
                                >
                                    <CheckboxInputField
                                        modifiers="alignCenter"
                                        text={<CheckboxText
                                            active={regionId === selectedRegionId}
                                            modifiers={[ 'fullWidth', 'alignCenter' ]}
                                        >
                                            <RegionLabel regionId={regionId}/> 
                                            {' '}
                                            <b>{geoRegions[regionId].regionName}</b>
                                        </CheckboxText>}
                                        input={{
                                            value: selectedSubGeoRegions
                                                .map(id => subGeoRegions[id])
                                                .filter(r => r.segmentationGeoRegionId === regionId).length === 0
                                                ? false
                                                : Boolean(Object.values(subGeoRegions).filter(r => r.segmentationGeoRegionId === regionId).length === selectedSubGeoRegions.map(id => subGeoRegions[id]).filter(r => r.segmentationGeoRegionId === regionId).length) || 'partially',
                                            onChange: e => this.onChangeRegions(e.target.checked, regionId),
                                        }}
                                    />
                                </li>
                            )}
                        </Ul>
                    </Col>
                    <Col md={6}>
                        <Ul>
                            <li>&nbsp;</li>
                            <li><Card.Label>Sub regions</Card.Label></li>
                            {Object.values(this.props.subGeoRegions).map(subGeoRegion =>
                                subGeoRegion.segmentationGeoRegionId === selectedRegionId &&
                                <li key={'subGeoRegion_' + subGeoRegion.id}>
                                    <CheckboxInputField
                                        text={subGeoRegion.subRegionName}
                                        input={{
                                            value: selectedSubGeoRegions.includes(subGeoRegion.id),
                                            onChange: e => {
                                                this.onChangeRegions(e.target.checked, selectedRegionId, subGeoRegion.id)
                                            },
                                        }}
                                    />
                                </li>
                            )}
                        </Ul>
                    </Col>
                </Row>
            </Card.Content>
        </MapContent>
    }
}

export default SegmentationRegionsMap as unknown as ComponentType<PathProps>
