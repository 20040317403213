import React, { Component, ComponentType, PureComponent, Fragment } from 'react'
import { Label, Modal } from 'react-bootstrap'
import { uniqBy } from 'lodash'
import { Tool } from 'react-feather'
import { Button } from '../../elements/Button'
import { hideModal } from '../../../actions/modal'
import moment from 'moment'
import { connect } from '../../../utils/connect'
import {
    getPartnerContract,
    createPartnerContractPause,
    updatePartnerContractPause,
    deletePartnerContractPause
} from '../../../actions/partnerContracts'
import styled from 'styled-components'
import { PartnerContract } from '../../../reducers/types/entities/PartnerContract'
import { Table } from '../../elements/table'
import Column from '../../elements/table/columns/Column'
import Header from '../../elements/table/Header'
import Rows from '../../elements/table/Rows'
import Cell from '../../elements/table/cells/Cell'
import { Flex } from '../../elements/Flex'
import PauseForm from './PauseForm'
import { fadedTextColor, tableBorderColor } from '../../../utils/variables'
import DateCell from '../../elements/table/cells/DateCell'
import { Partner } from '../../../reducers/types/entities/Partner'
import { Card } from '../../blocks/Card'
import Tabs from '../../elements/Tabs'
import { removeEmptyProperties } from '../../../utils'

const LgModal = styled(Modal)`
    .modal-dialog {
        width: 820px;
    }
`

const FormContainer = styled.div`
    padding: 24px 24px 40px;
    border-bottom: 1px solid ${tableBorderColor};
`

const CloseBtn = styled.button`
    display: inline-block;
    border: none;
    background-color: transparent;
    margin-left: auto;
    margin-right: 0:
`

const Row = styled.tr<{fadeOut: boolean}>`
    ${props =>  props.fadeOut
        ? `pointer-events: none; color: ${fadedTextColor};`
        : ''}
    ${Button} {
        visibility: hidden;
    }

    &:hover ${Button} {
        visibility: visible;
    }
`

const StatusLabel = styled(Label)`
    padding: 5px 10px;
    border-radius: 12px;
`

const Views = {
    list: 'list',
    create: 'create',
}

interface PauseRowModel {
    rowData?: any
    partnerContract: PartnerContract
    pauseUpdate: any
    pauseEnd: any
    pauseDelete: any
}

class PauseRow extends PureComponent<PauseRowModel> {
    state = {
        toggled: false,
    }

    toggleDropdown = () => {
        this.setState({
            toggled: !this.state.toggled,
        })
    }

    onPauseUpdate = async (startsAt, endsAt) => {
        const pause = this.props.rowData

        await this.props.pauseUpdate(pause.id, {
            startsAt: pause.startsAt !== startsAt ? startsAt : '',
            endsAt: pause.endsAt !== endsAt ? endsAt : '',
        })

        this.toggleDropdown()
    }

    onPauseEnd = async () => {
        const pause = this.props.rowData

        await this.props.pauseEnd(pause.id)
    }

    onPauseDelete = async () => {
        const pause = this.props.rowData

        await this.props.pauseDelete(pause.id)
    }

    render() {
        const pause = this.props.rowData
        const now = moment()

        const isActive = moment(pause.startsAt).isBefore(now) && moment(pause.endsAt).isAfter(now)
        const isExpired = moment(pause.startsAt).isBefore(now) && moment(pause.endsAt).isBefore(now)
        const isUpcoming = moment(pause.startsAt).isAfter(now) && moment(pause.endsAt).isAfter(now)

        return <Fragment>
            <Row fadeOut={isExpired}>
                <Cell>
                    {isActive && <StatusLabel bsStyle="success">Active</StatusLabel>}
                    {isExpired && <StatusLabel bsStyle="default">Expired</StatusLabel>}
                    {isUpcoming && <StatusLabel bsStyle="info">Planned</StatusLabel>}
                </Cell>
                <DateCell date={pause.startsAt} format="date" />
                <DateCell date={pause.endsAt} format="date" />
                <Cell>
                    <Flex>
                        {!isExpired && <Button modifiers={[ 'cellBtn', 'secondary' ]} onClick={this.toggleDropdown}><Tool size={12} /></Button>}
                        {isActive && <Button modifiers={[ 'cellBtn', 'warning', 'mL_1' ]} onClick={this.onPauseEnd}>End</Button>}
                        {isUpcoming && <Button modifiers={[ 'cellBtn', 'danger', 'mL_1' ]} onClick={this.onPauseDelete}>Delete</Button>}
                    </Flex>
                </Cell>
            </Row>
            {this.state.toggled && <tr>
                <td colSpan={10}>
                    <FormContainer>
                        <PauseForm
                            submit={this.onPauseUpdate}
                            startsAt={pause.startsAt}
                            endsAt={pause.endsAt}
                        />
                    </FormContainer>
                </td>
            </tr>}
        </Fragment>
    }
}

interface Props {
    partner: Partner
    partnerContract: PartnerContract
}

interface Actions {
    createPartnerContractPause: typeof createPartnerContractPause
    updatePartnerContractPause: typeof updatePartnerContractPause
    deletePartnerContractPause: typeof deletePartnerContractPause
    getPartnerContract: typeof getPartnerContract
    hideModal: typeof hideModal
}

interface State {
    viewName: string
    pauseIds: number[]
}

type ContractPausesModel = Props & Actions & State

@connect(
    state => ({
        partnerContract: state.entities.partnerContracts[state.modal.partnerContractId],
        partner: state.entities.partners[state.modal.partnerId],
    }), {
        createPartnerContractPause,
        updatePartnerContractPause,
        deletePartnerContractPause,
        getPartnerContract,
        hideModal,
    }
)
class ContractPauseModal extends Component<ContractPausesModel> {
    state = {
        viewName: Views.list,
        pauseIds: [],
    }

    async componentDidMount() {
        const pauses = this.props.partnerContract.allocationPauses

        if (!pauses || !Boolean(pauses.length)) {
            await this.changeView(Views.create)
        } else {
            const pauseIds = pauses.map(pause => pause.id)
            this.setState({
                pauseIds: [ ...pauseIds ],
            })
        }
    }

    updatePauses = async () => {
        const contractResponse: any = await this.props.getPartnerContract(this.props.partner.id, this.props.partnerContract.id)

        const pauses = contractResponse.entities.partnerContracts[contractResponse.result].allocationPauses

        if (pauses) {
            const pauseIds = pauses.map(pause => pause.id)
            this.setState({
                pauseIds: [ ...pauseIds ],
            })
        }
    }

    changeView = viewName => this.setState({ viewName })

    onPauseCreate = async (startsAt, endsAt) => {
        const partnerId = this.props.partner.id
        const partnerContractId = this.props.partnerContract.id

        await this.props.createPartnerContractPause(
            partnerId,
            {
                partnerContractId: [ partnerContractId ],
                startsAt,
                endsAt,
            }
        )

        await this.updatePauses()

        this.changeView(Views.list)
    }

    onPauseUpdate = async (pauseId, pauseData) => {
        const { partnerContract, partner } = this.props

        const filteredData = removeEmptyProperties({ ...pauseData })

        await this.props.updatePartnerContractPause(partner.id, partnerContract.id, pauseId, filteredData)

        await this.updatePauses()
    }

    onPauseEnd = async pauseId => {
        const { partnerContract, partner } = this.props

        await this.props.updatePartnerContractPause(partner.id, partnerContract.id, pauseId, {
            endsAt: moment().format(),
        })

        await this.updatePauses()
    }

    onPauseDelete = async pauseId => {
        const { partnerContract, partner } = this.props

        await this.props.deletePartnerContractPause(partner.id, partnerContract.id, pauseId)

        await this.updatePauses()
    }

    render() {
        const { partnerContract } = this.props
        const { pauseIds } = this.state

        const tabs = [
            { tabName: 'Pauses', tabId: Views.list },
            { tabName: 'Create', tabId: Views.create },
        ]

        const pauseItems = partnerContract.allocationPauses
            ? uniqBy(partnerContract.allocationPauses.filter(pause => pauseIds.includes(pause.id)), 'id')
            : []

        return <LgModal
            show={true}
            onHide={this.props.hideModal}
            backdrop="static"
        >
            <Card.Header>
                <Tabs
                    tabs={tabs}
                    selectedTab={this.state.viewName}
                    changeTab={tabId => this.changeView(tabId)}
                />
                <CloseBtn onClick={this.props.hideModal}>×</CloseBtn>
            </Card.Header>
            <Modal.Body>
                {this.state.viewName === Views.list && <Table>
                    <Header>
                        <Column name="pause_status">Status</Column>
                        <Column name="start_date">Start date</Column>
                        <Column name="end_date">End date</Column>
                        <Column name="actions">Actions</Column>
                    </Header>
                    <Rows
                        items={pauseItems}
                    >
                        <PauseRow
                            partnerContract={partnerContract}
                            pauseUpdate={this.onPauseUpdate}
                            pauseEnd={this.onPauseEnd}
                            pauseDelete={this.onPauseDelete}
                        />
                    </Rows>
                </Table>}
                {this.state.viewName === Views.create && <Card.Content>
                    <PauseForm
                        submit={this.onPauseCreate}
                    />
                </Card.Content>}
            </Modal.Body>
        </LgModal>
    }
}

export default ContractPauseModal as unknown as ComponentType
