import styled from 'styled-components'
import ColorHash from 'color-hash'

const colorHash = new ColorHash()

const COLORS = {
    lb: '#1976d2',
    pps: '#ffa000',
    excl: '#64dd17',
}

const BusinessModelLabel = styled.span<{
    abbreviation: string
    fadeOut?: boolean
}>`
    ${({ abbreviation, fadeOut }) => `
        background-color: ${COLORS[abbreviation] || colorHash.hex(abbreviation)};
        ${fadeOut && 'opacity: 0.4'};
    `};
    text-transform: uppercase;
    margin-right: 5px;
    display: inline;
    padding: 7px 10px 5px;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
`

export default BusinessModelLabel
