import {
    GET_TASKS,
    GET_TASKS_SUCCESS,
    GET_TASKS_FAIL,
    COMPLETE_TASK_SUCCESS,
    COMPLETE_TASK,
    COMPLETE_TASK_FAIL,
    GET_PARTNER_TASKS,
    GET_PARTNER_TASKS_SUCCESS,
    GET_PARTNER_TASKS_FAIL,
    CREATE_TASK_SUCCESS, CREATE_TASK,
} from '../../actions/tasks'
import { InitialState } from '../types/InitialState'
import { InitialAction } from '../types/InitialAction'

export interface TaskListModel extends InitialState {
    taskIds: number[]
    partnerTaskId: number[]
}
const initialState: TaskListModel = {
    isLoading: false,
    taskIds: [],
    partnerTaskId: [],
    pagination: {
        pages: 0,
        page: 0,
        total: 0,
    },
}

interface TaskListActionModel extends InitialAction {
    taskId: number
}

export default function reducer(
    state = initialState,
    action: TaskListActionModel,
) {
    switch (action.type) {
        case GET_TASKS:
        case COMPLETE_TASK:
        case CREATE_TASK:
        case GET_PARTNER_TASKS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_TASKS_SUCCESS:
            return {
                ...state,
                taskIds: action.append ? state.taskIds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case COMPLETE_TASK_SUCCESS:
            return {
                ...state,
                taskIds: state.taskIds.filter(item => item !== action.taskId),
                isLoading: false,
            }
        case GET_TASKS_FAIL:
        case COMPLETE_TASK_FAIL:
        case GET_PARTNER_TASKS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_PARTNER_TASKS_SUCCESS:
            return {
                ...state,
                partnerTaskId: action.response.result,
                isLoading: false,
            }
        case CREATE_TASK_SUCCESS:
            const tasks = new Set(state.partnerTaskId)
            tasks.add(action.response.result)
            return {
                ...state,
                partnerTaskId: Array.from(tasks),
                isLoading: false,
            }

        default:
            return state
    }
}
