import React, { PureComponent, ComponentType } from 'react'
import moment from 'moment'
import { Col, Row, OverlayTrigger, Popover } from 'react-bootstrap'
import { Card } from '../../blocks/Card/index'
import CardText from '../../elements/text/CardText'
import { Button } from '../../elements/Button'
import MetaLabel from '../../elements/text/MetaLabel'
import { ProfilePicture } from '../../elements/ProfilePicture'
import { Field } from 'redux-form'
import { DateTimePickerGroup } from '../../elements/forms/inputs/DateTimePickerGroup'
import { connect } from '../../../utils/connect'
import { Employee } from '../../../reducers/types/entities/Employee'
import { Lead } from '../../../reducers/types/entities/Lead'

interface LeadBasicInformationStateProps {
    employees: Employee[]
}

interface LeadBasicInformationPathProps {
    lead: Lead
}

type LeadBasicInformationModel = LeadBasicInformationStateProps & LeadBasicInformationPathProps

@connect(
    state => ({
        employees: state.entities.employees,
    }),
)
class LeadBasicInformationCard extends PureComponent<LeadBasicInformationModel> {
    render() {
        const {
            lead,
            employees,
        } = this.props

        let overlayTrigger

        return <Card.Content>
            <Row>
                <Col md={6}>
                    <div>
                        <MetaLabel>Channel</MetaLabel>
                        {lead.affiliateCampaign && <p>
                            {lead.affiliateCampaign.name}
                            {' '}
                            — 
                            {' '}
                            {lead.affiliateCampaign.affiliate.companyName}
                        </p>}
                    </div>
                    <div>
                        <MetaLabel>Created</MetaLabel>
                        <p>
                            {moment(lead.createdAt).format('DD/M-YYYY — H:mm')}
                            {' '}
                            (
                            {moment(lead.createdAt).fromNow()}
                            )
                        </p>
                    </div>
                    <div>
                        <MetaLabel>Validated</MetaLabel>
                        <p>{lead.validatedAt && moment(lead.validatedAt).format('DD/M-YYYY — H:mm') + ' (' + moment(lead.validatedAt).fromNow() + ')' || '—'}</p>
                    </div>
                </Col>
                {employees[lead.validator.id] && <Col md={6}>
                    <div className="pull-right">
                        <ProfilePicture square size={100} src={employees[lead.validator.id].image} />
                        <Card.Text>
                            {lead.validator.firstName} 
                            {' '}
                            {lead.validator.lastName}
                        </Card.Text>
                    </div>
                </Col>}
            </Row>
            <Row>
                <Col md={6}>
                    <MetaLabel>Expires</MetaLabel>
                    <p>{lead.expiresAt && moment(lead.expiresAt).format('DD/M-YYYY — H:mm') + ' (' + moment(lead.expiresAt).fromNow() + ')' || '—'}</p>
                </Col>
                <Col md={6}>
                    {lead.expiresAt &&
                        <OverlayTrigger
                            trigger="click"
                            placement="top"
                            ref={ref => {
                                overlayTrigger = ref
                            }}
                            overlay={
                                <Popover id="popover-positioned-scrolling-top" style={{ minWidth: '300px' }}>
                                    <CardText>
                                        <strong>Extend expiration</strong>
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={() => overlayTrigger.hide()}
                                        >
                                            <span aria-hidden="true">×</span>
                                            <span className="sr-only">Close</span>
                                        </button>
                                    </CardText>
                                    <br />
                                    <Field
                                        label="Expires at"
                                        name="expiresAt"
                                        component={DateTimePickerGroup}
                                    />
                                </Popover>
                            }
                        >
                            <Button
                                modifiers={[ 'btnBlock', 'fullWidth', 'secondary' ]}
                            >
                                Extend expiration
                            </Button>
                        </OverlayTrigger>}
                </Col>
            </Row>
        </Card.Content>
    }
}

export default LeadBasicInformationCard as unknown as ComponentType<LeadBasicInformationPathProps>
