import styled from 'styled-components'
import Satisfaction from './_Satisfaction'
import Portrait from './_Portrait'
import Meta from './_Meta'
import Note from './_Note'
import Icon from './_Icon'

const EventMessage: any = styled.div`
  margin: 10px 0;
`
EventMessage.Icon = Icon
EventMessage.Meta = Meta
EventMessage.Note = Note
EventMessage.Portrait = Portrait
EventMessage.Satisfaction = Satisfaction

export default EventMessage
