import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'
import config from '../config'

export const GET_ALLOCATION_DELIVERIES = 'deliveries/GET_ALLOCATION_DELIVERIES'
export const GET_ALLOCATION_DELIVERIES_SUCCESS = 'deliveries/GET_ALLOCATION_DELIVERIES_SUCCESS'
export const GET_ALLOCATION_DELIVERIES_FAIL = 'deliveries/GET_ALLOCATION_DELIVERIES_FAIL'

interface GetDeliveriesModel {
    revenueSegmentId?: string | number
    partnerId?: number[]
    employeeId?: number[]
    processAtGte?: string
    processAtLte?: string
    geoCode?: string
    sort?: string
}

export const getDeliveries = (
    criteria: GetDeliveriesModel,
    append?: boolean
) => ({
    [CALL_API]: {
        types: [ GET_ALLOCATION_DELIVERIES, GET_ALLOCATION_DELIVERIES_SUCCESS, GET_ALLOCATION_DELIVERIES_FAIL ],
        endpoint: '/partners/allocationdeliveries',
        schema: schemas.deliveries,
        options: {
            body: {
                geoCode: config.geoCode,
                limit: 50,
                ...criteria,
            },
        },
    },
    append,
})
