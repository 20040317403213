import { SHOW_LOADING, SHOW_STATUS, HIDE_LOADING } from '../actions/loader'
import { InitialAction } from './types/InitialAction'


export interface Loader {
    state: string
    status: string
}
const initialState: Loader = {
    state: 'hidden',
    status: '',
}

interface LoaderActionModel extends InitialAction, Loader{
    status: string
}

export default function reducer(
    state = initialState,
    action: LoaderActionModel
) {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                state: 'loading',
            }
        case SHOW_STATUS:
            return {
                ...state,
                state: 'show_status',
                status: action.status,
            }
        case HIDE_LOADING:
            return {
                ...state,
                state: 'hidden',
                status: '',
            }
        default:
            return state
    }
}
